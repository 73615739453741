import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';
import PrescriptionSupportTable from './PrescriptionSupportTable';

function PrescriptionSupportModal({ onClose, data, memberId, testId, schedulesFlag, timeZone }) {
  return (
    <Modal onClose={onClose} testId={`${testId}-modal`}>
      <h1 className="modal-header" data-testid={`${testId}-modal-header`}>
        Prescription Decision Support
      </h1>
      <div className="prescription-information" data-testid={`${testId}-prescription-information`}>
        <p>
          <strong>Member: </strong>
          {data.memberName}
        </p>
        <p>
          <strong>MRN: </strong>
          {data.mrn}
        </p>
        <p>
          <strong>DOB: </strong>
          {data.dob}
        </p>
      </div>
      <PrescriptionSupportTable
        memberId={memberId}
        testId={`${testId}-modal`}
        schedulesFlag={schedulesFlag}
        timeZone={timeZone}
      />
    </Modal>
  );
}

PrescriptionSupportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    memberName: PropTypes.string.isRequired,
    mrn: PropTypes.string.isRequired,
    dob: PropTypes.string,
  }),
  memberId: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
  schedulesFlag: PropTypes.bool.isRequired,
  timeZone: PropTypes.string.isRequired,
};

PrescriptionSupportModal.defaultProps = {
  data: {
    dob: null,
  },
};

export default PrescriptionSupportModal;
