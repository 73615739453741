function convertDate(dateTime) {
  return new Date(dateTime);
}

function getTimeZone(dateTime) {
  const date = convertDate(dateTime);
  const zone = date.toLocaleTimeString('en-us', { timeZoneName: 'shortGeneric' }).split(' ')[2];

  return zone;
}

function getTimeZoneLocalized(dateTime) {
  const date = convertDate(dateTime);
  const zone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

  return zone;
}

// Convert the 'short' timeZoneName which includes the D and S
// for daylight/standard to the generic or 'shortGeneric' timeZoneName
function shortGenericTimeZoneName(shortTimeZoneName) {
  let generic;
  switch (shortTimeZoneName) {
    case 'PDT':
    case 'PST':
      generic = 'PT';
      break;
    case 'MDT':
    case 'MST':
      generic = 'MT';
      break;
    case 'CDT':
    case 'CST':
      generic = 'CT';
      break;
    case 'EDT':
    case 'EST':
      generic = 'ET';
      break;
    default:
      generic = shortTimeZoneName;
  }
  return generic;
}

function formatDayOfWeek(dateTime) {
  const date = convertDate(dateTime);
  return date.toLocaleString('en-us', { weekday: 'long' });
}

function formatShortDayOfWeek(dateTime) {
  const date = convertDate(dateTime);
  return date.toLocaleString('en-us', { weekday: 'short' });
}

function formatTime(dateTime) {
  const date = convertDate(dateTime);
  return `${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} (${getTimeZone(dateTime)})`;
}

function formatTimeLocalized(dateTime) {
  const date = convertDate(dateTime);
  return `${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} ${getTimeZoneLocalized(dateTime)}`;
}

function formatTimeWithoutTimezone(dateTime) {
  const date = convertDate(dateTime);
  return `${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}

function formatMonth(dateTime) {
  const date = convertDate(dateTime);
  return date.toLocaleString('en-us', { month: 'long' });
}

function formatShortMonth(dateTime) {
  const date = convertDate(dateTime);
  return date.toLocaleString('en-us', { month: 'short' });
}

function formatDate(dateTime, showFullDate) {
  const date = convertDate(dateTime);
  if (showFullDate) {
    return `${formatDayOfWeek(dateTime)} ${formatMonth(dateTime)} ${date.getDay()}, ${date.getFullYear()}`;
  }
  return `${formatDayOfWeek(dateTime)}s`;
}

function formatDateTime(dateTime) {
  return `${formatDayOfWeek(dateTime)}'s ${formatTime(dateTime)}`;
}

function formatTimeStamp(dateTime) {
  const date = convertDate(dateTime);

  return `${formatMonth(dateTime)} ${date.getDay()}, ${date.getFullYear()} at ${formatTime(dateTime)}`;
}

function formatShortDate(dateTime) {
  const date = convertDate(dateTime);

  return `${formatDayOfWeek(dateTime)}, ${date.getMonth() + 1}/${date.getDate()}`;
}

function formatShortDateTime(dateTime) {
  const date = convertDate(dateTime);

  return `${formatShortDayOfWeek(dateTime)}, ${formatShortMonth(dateTime)} ${date.getDate()}, ${date.getFullYear()} ${formatTimeLocalized(dateTime)}`;
}

function formatSubmissionTimestamp(dateTime) {
  const date = convertDate(dateTime);

  return `${date.toLocaleDateString()} ${formatTimeLocalized(dateTime)}`;
}

function isTodaysDate(dateTime) {
  const date = convertDate(dateTime);
  const today = new Date();

  return today.toDateString() === date.toDateString();
}

function formatTodayOrDOW(dateTime) {
  const isToday = isTodaysDate(dateTime);
  if (isToday) {
    return 'Today';
  }

  return formatDayOfWeek(dateTime);
}

function isSameDay(date1, date2) {
  return date1.toDateString() === date2.toDateString();
}

function formatLastModifiedTime(dateTime) {
  const daysInMilliseconds = 1000 * 60 * 60 * 24;
  const lastModifiedDateTime = new Date(dateTime);
  const currentTime = new Date();

  if (isSameDay(lastModifiedDateTime, currentTime)) {
    return `today, ${formatTimeLocalized(lastModifiedDateTime)}`;
  }

  if (isSameDay(lastModifiedDateTime, new Date(currentTime - daysInMilliseconds))) {
    return `yesterday, ${formatTimeLocalized(lastModifiedDateTime)}`;
  }

  return `${lastModifiedDateTime.toLocaleDateString()}, ${formatTimeLocalized(lastModifiedDateTime)}`;
}

function formatShortDayTime(dateTime) {
  const date = convertDate(dateTime);

  return date.toLocaleString('en-US', {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'shortGeneric',
  });
}

// eslint-disable-next-line import/prefer-default-export
export {
  convertDate,
  formatDayOfWeek,
  formatTime,
  formatTimeWithoutTimezone,
  formatMonth,
  formatDate,
  formatDateTime,
  formatTimeStamp,
  getTimeZone,
  shortGenericTimeZoneName,
  formatShortDate,
  formatShortDateTime,
  formatTimeLocalized,
  formatSubmissionTimestamp,
  formatTodayOrDOW,
  formatLastModifiedTime,
  formatShortDayOfWeek,
  formatShortDayTime,
};
