import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import cloneDeep from 'lodash/cloneDeep';

// COMPONENTS
import Filters from '../../Filters';
import EditContents from './EditContents';
import TextArea from '../../shared/TextArea';
import GenericModal from '../../shared/GenericModal';
import MuiDatePicker from '../../shared/MuiDatePicker';

// HELPERS
import { labelStyle } from './styles';

function EditPickupModal(props) {
  const { member_name, member_mrn, latest_uds_supply } = props.rowData || {};
  const {
    pickup_facility_id,
    uds_cup_quantity,
    fentanyl_strip_quantity,
    pregnancy_test_quantity,
    oral_swab_quantity,
    pre_paid_envelope_quantity,
  } = latest_uds_supply || {};

  // DATA
  const [editedData, setEditedData] = useState(cloneDeep(props.rowData));
  useEffect(() => setEditedData(cloneDeep(props.rowData)), [props.rowData]);

  const [selectedLocation, setSelectedLocation] = useState({});
  useEffect(() => {
    const loc = props.locations?.find((obj) => obj.value === pickup_facility_id);
    setSelectedLocation(loc);
  }, [props.locations, pickup_facility_id]);

  return (
    <GenericModal
      isLargeModal
      isOpen={props.isOpen}
      headerText="Edit Pickup"
      primaryButtonLabel="Confirm"
      secondaryButtonLabel="Back"
      secondaryButtonClick={props.onBack}
      primaryButtonClick={() => props.onConfirm(editedData)}
    >
      <div className="font-bold border-b-2 border-gray-300 text-lg p-2">
        {member_name} ({member_mrn})
      </div>

      <div className="flex">
        {/* LEFT BOX */}
        <div className="flex-1 border-r-2 border-gray-300 pr-6">
          <div className="p-4">
            {/* FACILITY */}
            <Filters
              label="Facility*"
              data={props.locations}
              placeholder="Search Locations"
              value={selectedLocation}
              stateChanger={(selected) => {
                setSelectedLocation(selected);

                editedData.latest_uds_supply.pickup_facility_id = selected?.value;
                setEditedData({ ...editedData });
              }}
              labelStyle={labelStyle}
              isMulti={false}
              removeAllSelection
              style={{ marginBottom: '20px' }}
            />

            {/* PICKUP DATE */}
            <MuiDatePicker
              label="Pickup Date*"
              value={latest_uds_supply?.date}
              onChange={(selected) => {
                editedData.latest_uds_supply.date = selected;
                setEditedData({ ...editedData });
              }}
              labelStyle={labelStyle}
              style={{ marginBottom: '20px' }}
            />

            {/* NOTES */}
            <TextArea
              label="Pickup Instructions"
              value={editedData.latest_uds_supply?.notes}
              subtext="This note will only be seen in the digital platform. All clinical notes must go in eCW."
              onChange={(value) => {
                editedData.latest_uds_supply.notes = value;
                setEditedData({ ...editedData });
              }}
              labelStyle={labelStyle}
            />
          </div>
        </div>

        {/* RIGHT BOX */}
        <div className="flex-1 p-4">
          <div className="flex mb-4">
            <div className="flex-1" style={labelStyle}>
              Test Type
            </div>

            <div className="flex-1" style={labelStyle}>
              Quantity
            </div>
          </div>

          <EditContents
            label="UDS Cups"
            count={uds_cup_quantity}
            setQuantity={(selected) => {
              editedData.latest_uds_supply.uds_cup_quantity = selected.value;
              setEditedData({ ...editedData });
            }}
          />

          <EditContents
            label="Fentanyl Strips"
            count={fentanyl_strip_quantity}
            setQuantity={(selected) => {
              editedData.latest_uds_supply.fentanyl_strip_quantity = selected.value;
              setEditedData({ ...editedData });
            }}
          />

          <EditContents
            label="Pregnancy Test"
            count={pregnancy_test_quantity}
            setQuantity={(selected) => {
              editedData.latest_uds_supply.pregnancy_test_quantity = selected.value;
              setEditedData({ ...editedData });
            }}
          />

          <EditContents
            label="Oral Swabs"
            count={oral_swab_quantity}
            setQuantity={(selected) => {
              editedData.latest_uds_supply.oral_swab_quantity = selected.value;
              setEditedData({ ...editedData });
            }}
          />

          <EditContents
            label="Pre-Paid Envelopes"
            count={pre_paid_envelope_quantity}
            setQuantity={(selected) => {
              editedData.latest_uds_supply.pre_paid_envelope_quantity = selected.value;
              setEditedData({ ...editedData });
            }}
          />
        </div>
      </div>
    </GenericModal>
  );
}

EditPickupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default EditPickupModal;
