import React from 'react';
import PropTypes from 'prop-types';

import { BiCheck } from 'react-icons/bi';

function CompletedStatusIcon({ testId }) {
  return (
    <div className="status-icon completed-status-icon" data-testid={testId}>
      <BiCheck />
    </div>
  );
}

CompletedStatusIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default CompletedStatusIcon;
