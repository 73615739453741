import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DetailsRow from '../DetailsRow';
import Button from '../../../shared/Button';

function UsernameRow({ username }) {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <DetailsRow label="Username">
      {username ? (
        <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
          <span>{isShowing ? username : '**********'}</span>
          <Button
            onClick={() => setIsShowing((value) => !value)}
            classes={classNames('border-none -my-2', { invisible: isShowing })}
          >
            Show username
          </Button>
        </div>
      ) : (
        <span className="italic">No Username set</span>
      )}
    </DetailsRow>
  );
}

UsernameRow.propTypes = {
  username: PropTypes.string.isRequired,
};

export default UsernameRow;
