import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GroupsModal from '../../../GroupsModal';
import SelectInput from '../../../shared/SelectInput';
import useHttp from '../../../shared/hooks/use-http';
import Toasts from '../../../shared/Toasts';
import useToasts from '../../../shared/hooks/use-toasts';
import {
  DEFAULT_OPTION,
  DEFAULT_OPTION_VALUE,
  findDefaultOption,
  FORM_ID,
  getDefaultValue,
  getRequestData,
  SCHEMA,
} from './helpers/staffMemberFacilitiesHelper';

function AddEditStaffMemberFacilityModal({
  isOpen,
  onClose,
  staffMemberFacility,
  onSave,
  providersData,
  facilitiesData,
  appointmentTypesData,
  authenticityToken,
}) {
  const prevAppointmentTypesInputValue = useRef();
  const { isLoading, sendRequest } = useHttp();
  const { toasts, addToast, removeToast } = useToasts();

  const header = `${staffMemberFacility ? 'Edit' : 'Create new'} record`;
  const appointmentTypesOptions = [...appointmentTypesData, DEFAULT_OPTION];
  const values = {
    user: getDefaultValue(staffMemberFacility?.staffMember),
    locations: getDefaultValue(staffMemberFacility?.locations) || [],
    appointmentTypes: staffMemberFacility
      ? getDefaultValue(staffMemberFacility?.appointmentTypes) || []
      : [DEFAULT_OPTION],
  };

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    values,
    resolver: yupResolver(SCHEMA),
  });

  const appointmentTypesInputValue = watch('appointmentTypes');

  useEffect(() => {
    if (findDefaultOption(appointmentTypesInputValue) && appointmentTypesInputValue.length > 1) {
      if (findDefaultOption(prevAppointmentTypesInputValue.current)) {
        const updatedAppointmentTypes = appointmentTypesInputValue.filter(
          ({ value }) => value !== DEFAULT_OPTION_VALUE
        );

        setValue('appointmentTypes', updatedAppointmentTypes);
        return;
      }

      setValue('appointmentTypes', [DEFAULT_OPTION]);
    }

    prevAppointmentTypesInputValue.current = appointmentTypesInputValue;
  }, [appointmentTypesInputValue, setValue]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data) => {
    try {
      const requestUrl = `/staff/staff_member_facilities${staffMemberFacility ? `/${staffMemberFacility.id}` : ''}`;

      await sendRequest(requestUrl, {
        method: staffMemberFacility ? 'PUT' : 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: getRequestData(data),
      });

      addToast({
        header: `${staffMemberFacility ? 'R' : 'New r'}ecord has been successfully ${staffMemberFacility ? 'updated' : 'added'}.`,
      });
      handleClose();
      await onSave();
    } catch (error) {
      window.Sentry.captureException(error);
      addToast({
        header: `Something went wrong. ${staffMemberFacility ? 'R' : 'New r'}ecord has not been ${staffMemberFacility ? 'updated' : 'added'}.`,
        type: 'error',
      });
      handleClose();
    }
  };

  return (
    <>
      <Toasts toasts={toasts} removeToast={removeToast} isSecondaryVariant />
      <GroupsModal
        testID="add-edit-staff-member-facility-modal"
        isOpen={isOpen}
        header={header}
        footerButtons={[
          {
            label: 'Back',
            isSecondary: true,
            onClick: handleClose,
          },
          {
            type: 'submit',
            label: staffMemberFacility ? 'Update' : 'Create',
            isPrimary: true,
            isLoading,
            form: FORM_ID,
          },
        ]}
        size="medium"
        isLoading={isLoading}
      >
        <p className="mb-0">
          <span className="text-error-700 mr-2">*</span>
          Required field
        </p>
        <div className="bg-white rounded-lg shadow-sm">
          <div className="py-6 px-4">
            <form id={FORM_ID} className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="user"
                render={({ field, fieldState: { error } }) => (
                  <SelectInput
                    {...field}
                    label="Staff Member"
                    placeholder="Provide user's full name"
                    required
                    error={error?.message}
                    options={providersData}
                  />
                )}
              />
              <Controller
                control={control}
                name="locations"
                render={({ field, fieldState: { error } }) => (
                  <SelectInput
                    {...field}
                    label="Facilities"
                    placeholder="Select facilities name"
                    required
                    error={error?.message}
                    options={facilitiesData}
                    isMulti
                  />
                )}
              />
              <Controller
                control={control}
                name="appointmentTypes"
                render={({ field, fieldState: { error } }) => (
                  <SelectInput
                    {...field}
                    label="Appointment Type"
                    placeholder="Select appointment type"
                    required
                    error={error?.message}
                    options={appointmentTypesOptions}
                    isMulti
                  />
                )}
              />
            </form>
          </div>
        </div>
      </GroupsModal>
    </>
  );
}

AddEditStaffMemberFacilityModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffMemberFacility: PropTypes.shape({
    id: PropTypes.number,
    staffMember: PropTypes.shape({ id: PropTypes.number, fullName: PropTypes.string }),
    locations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    appointmentTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  onSave: PropTypes.func.isRequired,
  providersData: PropTypes.array,
  facilitiesData: PropTypes.array,
  appointmentTypesData: PropTypes.array,
  authenticityToken: PropTypes.string.isRequired,
};

AddEditStaffMemberFacilityModal.defaultProps = {
  staffMemberFacility: null,
  providersData: [],
  facilitiesData: [],
  appointmentTypesData: [],
};

export default AddEditStaffMemberFacilityModal;
