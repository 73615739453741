import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

function FormTemplateNameFilter({ onSearch }) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
    },
  });
  return (
    <form
      data-testid="form-template-name-filter"
      id="template-name-filter-form"
      onSubmit={handleSubmit(onSearch)}
      className="flex items-end gap-2"
    >
      <div className="flex-1">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              placeholder="Form template name"
            />
          )}
        />
      </div>
      <Button type="submit" form="template-name-filter-form" isPrimary isPrimaryMarginBottom={false}>
        Search
      </Button>
    </form>
  );
}

FormTemplateNameFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default FormTemplateNameFilter;
