import * as Yup from 'yup';

const addAddendumFormValidationSchema = Yup.object()
  .shape({
    members: Yup.array()
      .of(Yup.object().shape({ label: Yup.string().required(), value: Yup.string().required() }))
      .min(1, 'At least one member must be selected')
      .required('Members are required'),
    templates: Yup.array()
      .of(Yup.object().shape({ label: Yup.string().required(), value: Yup.string().required() }))
      .min(1, 'At least one template must be selected')
      .required('Templates are required'),
    addendum: Yup.string().required('Addendum is required'),
  })
  .required();

export default addAddendumFormValidationSchema;
