import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowForward as NextIcon, IoIosArrowBack as PreviousIcon } from 'react-icons/io';
import classNames from 'classnames';
import GroupsModal from './GroupsModal';

function PhotosCarouselModal({ testID, isOpen, onClose, photos, header }) {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const handleNextPhoto = () => {
    if (currentPhotoIndex < photos.length - 1) {
      setCurrentPhotoIndex((currentIndex) => currentIndex + 1);
    }
  };

  const handlePreviousPhoto = () => {
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex((currentIndex) => currentIndex - 1);
    }
  };

  const isPreviousButtonDisabled = currentPhotoIndex === 0;
  const isNextButtonDisabled = currentPhotoIndex === photos.length - 1;

  const isSinglePhoto = photos.length === 1;

  return (
    <GroupsModal
      testID={testID}
      isOpen={isOpen}
      header={header}
      onClose={onClose}
      footerButtons={[{ label: 'Close', onClick: onClose, isPrimary: true }]}
    >
      <div
        className={classNames('flex  bg-white py-6 gap-2 rounded-lg shadow-md max-h-full mx-auto', {
          'justify-between': !isSinglePhoto,
          'justify-center': isSinglePhoto,
        })}
      >
        {!isSinglePhoto && (
          <button
            type="button"
            onClick={handlePreviousPhoto}
            disabled={isPreviousButtonDisabled}
            className="self-center p-2"
          >
            <PreviousIcon className={classNames('w-6 h-6', { 'text-gray-500': isPreviousButtonDisabled })} />
          </button>
        )}
        <img
          className="object-contain w-3/4 max-h-full rounded-lg"
          src={photos[currentPhotoIndex]?.src}
          alt={photos[currentPhotoIndex]?.alt}
        />
        {!isSinglePhoto && (
          <button type="button" onClick={handleNextPhoto} disabled={isNextButtonDisabled} className="self-center p-2">
            <NextIcon className={classNames('w-6 h-6', { 'text-gray-500': isNextButtonDisabled })} />
          </button>
        )}
      </div>
    </GroupsModal>
  );
}

PhotosCarouselModal.propTypes = {
  testID: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

PhotosCarouselModal.defaultProps = {
  testID: 'photos-carousel-modal',
  header: undefined,
  isOpen: false,
};

export default PhotosCarouselModal;
