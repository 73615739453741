import { useCallback } from 'react';
import useHttp from '../../shared/hooks/use-http';
import { appendReferrerParams } from '../../../helpers/ReferrerParams';

const usePrescriptionTaskActions = ({
  prescriptionTaskDetails,
  authenticityToken,
  addToast,
  redirectUrl,
  prescriptionReasons,
}) => {
  const { sendRequest, isLoading } = useHttp();

  const onSuccess = useCallback(() => {
    window.top.location.href = appendReferrerParams(redirectUrl, { show_success_toast: true });
  }, [redirectUrl]);

  const updatePrescriptionTaskStatus = useCallback(
    async (status) => {
      try {
        const currentPrescriptionReason = prescriptionReasons.find(
          (reason) => reason.label === prescriptionTaskDetails.reason
        );
        await sendRequest(`/staff/prescription_tasks/${prescriptionTaskDetails.id}`, {
          method: 'PUT',
          headers: { common: { 'X-CSRF-Token': authenticityToken } },
          data: {
            due_date: prescriptionTaskDetails.dueDate,
            prescription_reason_id: currentPrescriptionReason?.value,
            note: prescriptionTaskDetails.note,
            status,
          },
        });

        onSuccess();
      } catch (error) {
        addToast?.({
          header: 'Something went wrong',
          message: error?.parsedMessage ?? 'Task status could not be changed at this time. Please try again.',
          type: 'error',
        });
      }
    },
    [prescriptionTaskDetails, onSuccess, authenticityToken, addToast, sendRequest, prescriptionReasons]
  );

  const rxSentAction = useCallback(() => {
    updatePrescriptionTaskStatus('rx_sent');
  }, [updatePrescriptionTaskStatus]);

  const rxNotSentAction = useCallback(() => {
    updatePrescriptionTaskStatus('rx_not_sent');
  }, [updatePrescriptionTaskStatus]);

  return {
    isLoading,
    rxSentAction,
    rxNotSentAction,
  };
};

export default usePrescriptionTaskActions;
