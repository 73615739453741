import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../GroupsModal';

function AddendumDetails({ isOpen, setIsOpen, member, createdAt, createdBy, content }) {
  return (
    <GroupsModal
      testID="addendum-details-modal"
      isOpen={isOpen}
      header="Addendum"
      footerButtons={[
        {
          type: 'button',
          label: 'Close',
          isPrimary: true,
          onClick: () => setIsOpen(false),
        },
      ]}
      size="medium"
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4 border-b-2">
          <div className="mb-2">
            <strong>Member: </strong>
            <span>{member || '-'}</span>
          </div>
          <div className="mb-2">
            <strong>Author: </strong>
            <span>{createdBy || '-'}</span>
          </div>
          <div className="mb-2">
            <strong>Date/Time: </strong>
            <span>{createdAt || '-'}</span>
          </div>
        </div>
        <div className="p-4 flex gap-1">
          <div className="font-semibold">Content: </div>
          <div>{content}</div>
        </div>
      </div>
    </GroupsModal>
  );
}

AddendumDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  member: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default AddendumDetails;
