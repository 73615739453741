import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Filters from '../../Filters';

// HOOKS
import useSharedFilters from '../../shared/hooks/use-shared-filters';

function EditContents(props) {
  // FETCH DATA
  const { numericalOptions, fetchNumericalOptions } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchNumericalOptions();
  }, []);

  const [selectedCount, setSelected] = useState({ label: props.count, value: props.count });
  useEffect(() => setSelected({ label: props.count, value: props.count }), [props.count]);

  return (
    <div className="flex mb-6">
      <div className="flex-1">{props.label}</div>

      <div className="flex-1">
        <Filters
          data={numericalOptions}
          value={selectedCount}
          placeholder="-"
          stateChanger={(selected) => {
            setSelected(selected);
            props.setQuantity(selected);
          }}
          labelStyle={{ display: 'none' }}
          isMulti={false}
        />
      </div>
    </div>
  );
}

EditContents.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.object.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default EditContents;
