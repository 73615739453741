const setGroupsData = ({ row }) => {
  const {
    counselor: { counselor_id, counselor_name },

    empanelment_group: { date_time, group_type, location, modality_name },

    office_manager: { office_manager_id, office_manager_name },

    provider: { provider_id, provider_name },

    default_counselor,
    default_facility,
    default_group_type,
    default_modality,
    default_office_manager,
    default_provider,
    default_secondary_group_types,
    default_secondary_group_type_names,
    default_state,
    member_count,
    status,
  } = row;

  return {
    row,
    status,
    date_time,
    location,
    modality_name,
    group_type,
    member_count,
    office_manager_id,
    office_manager_name,
    counselor_id,
    counselor_name,
    provider_id,
    provider_name,
    default_state,
    default_facility,
    default_modality,
    default_group_type,
    default_secondary_group_types,
    default_secondary_group_type_names,
    default_office_manager,
    default_counselor,
    default_provider,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { setGroupsData };
