import React from 'react';
import PropTypes from 'prop-types';

import ScheduleRow from './ScheduleRow';

function NextAvailableTimeCell({ todaysSchedule, nextDaysSchedule, jobType }) {
  return jobType === 'Contractor - 1099' ? (
    <div>
      <p className="px-2 py-0 mb-0.5">1099 Provider</p>
      <em className="px-2 py-0 mb-0.5 text-gray-700">Hours not available</em>
    </div>
  ) : (
    <div>
      <ScheduleRow
        dayOfWeek={todaysSchedule.day_of_week}
        startTime={todaysSchedule.start_time}
        endTime={todaysSchedule.end_time}
        locations={todaysSchedule.locations}
      />
      <ScheduleRow
        dayOfWeek={nextDaysSchedule.day_of_week}
        startTime={nextDaysSchedule.start_time}
        endTime={nextDaysSchedule.end_time}
        locations={nextDaysSchedule.locations}
      />
    </div>
  );
}

const scheduleShape = {
  day_of_week: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  location: PropTypes.string,
};

NextAvailableTimeCell.propTypes = {
  todaysSchedule: PropTypes.objectOf(PropTypes.shape(scheduleShape)).isRequired,
  nextDaysSchedule: PropTypes.objectOf(PropTypes.shape(scheduleShape)).isRequired,
  jobType: PropTypes.string,
};

NextAvailableTimeCell.defaultProps = {
  jobType: 'Full Time',
};

export default NextAvailableTimeCell;
