import React from 'react';
import PropTypes from 'prop-types';
import GeneralCell from './GeneralCell';

function MemberCell({ testID, member, openLinkInNewTab, classes }) {
  const memberName = `${member.firstName} ${member.lastName}`;

  const data = member.summaryUrl ? (
    <a
      href={member.summaryUrl}
      target={openLinkInNewTab ? '_blank' : '_top'}
      className="text-teal-700 font-semibold underline"
      rel="noreferrer"
    >
      {memberName}
    </a>
  ) : (
    <span className="text-teal-700 font-semibold">{memberName}</span>
  );

  return <GeneralCell testID={`member-${testID}`} data={data} subText={member.mrn} containerClasses={classes} />;
}

MemberCell.propTypes = {
  testID: PropTypes.string,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    mrn: PropTypes.string,
    summaryUrl: PropTypes.string,
  }).isRequired,
  openLinkInNewTab: PropTypes.bool,
  classes: PropTypes.string,
};

MemberCell.defaultProps = {
  testID: 'memberCell',
  openLinkInNewTab: false,
  classes: undefined,
};

export default MemberCell;
