import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import StatusTag from './StatusTag';

function StatusTagsContainer(props) {
  return (
    <div className="flex flex-wrap gap-y-1">
      {props?.statuses?.map((statusObj) => (
        <StatusTag
          key={statusObj?.label}
          label={statusObj?.label}
          status={statusObj?.status}
          fullHeight={props.fullHeightTags}
        />
      ))}
    </div>
  );
}

StatusTagsContainer.propTypes = {
  statuses: PropTypes.array.isRequired,
  fullHeightTags: PropTypes.bool,
};

StatusTagsContainer.defaultProps = {
  fullHeightTags: true,
};

export default StatusTagsContainer;
