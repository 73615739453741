import { useState } from 'react';

// HOOKS
import useCachedShipmentData from './use-cached-shipment-data';

const useFormatSaveShipmentsData = () => {
  // CACHED DATA
  const { fetchCacheContentsByMember } = useCachedShipmentData();

  // DATA
  const [saveData, setSaveData] = useState({});

  const fetchSaveData = (name, labelAddress = {}, labelContents = {}) => {
    const data = {
      package_rate_id: labelContents?.service?.object_id,
      members_summary: [],
      staff: {
        email: labelAddress?.staffEmail,
        phone: labelAddress?.staffPhone,
        send_staff_shipment_notifications: labelAddress?.toEmailShipmentNotifications,
      },
      shipped_from_facility_id: labelAddress?.location?.value,
      shipped_from_staff_id: labelAddress?.selectedStaff?.value,
      // pickup_quantities: data.members_summary[0],
      date: labelContents?.date, // received on
      address_from: {
        name: labelAddress?.selectedStaff?.label,
        phone: labelAddress?.staffPhone,
        email: labelAddress?.staffEmail,
        ...labelAddress?.location?.address_information,
      },
      address_to: {
        name: labelAddress?.members[0]?.name,
        phone: labelAddress?.memberPhone,
        email: labelAddress?.memberEmail,
        ...labelAddress?.memberLocation?.address_information,
      },
      parcels: {
        length: labelContents?.length || labelContents?.packaging?.length,
        width: labelContents?.width || labelContents?.packaging?.width,
        height: labelContents?.height || labelContents?.packaging?.height,
        distance_unit: labelContents?.distanceUnit || 'in',
        weight: labelContents?.weight,
        mass_unit: labelContents?.massUnit || 'lb',
      },
    };

    const pickupFacilityId = labelAddress?.location?.value;
    const notes = labelAddress?.notes;

    labelAddress?.members.forEach((member) => {
      // eslint-disable-next-line object-curly-newline
      const { uds, fent, preg, oral, env } = fetchCacheContentsByMember(name, member);

      const memberSum = {
        member_id: member?.value,
        pickup_facility_id: pickupFacilityId,
        uds_cup_quantity: uds,
        fentanyl_strip_quantity: fent,
        pregnancy_test_quantity: preg,
        oral_swab_quantity: oral,
        pre_paid_envelope_quantity: env,
        notes,
        email: labelAddress?.memberEmail,
        phone: labelAddress?.memberPhone,
        send_member_shipment_notifications:
          labelAddress?.toEmailMemberNotifications === null ? true : labelAddress?.toEmailMemberNotifications,
      };

      data.members_summary.push(memberSum);
    });

    setSaveData(data);
    return data;
  };

  return {
    saveData,
    fetchSaveData,
  };
};

export default useFormatSaveShipmentsData;
