import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import useConvertObjKeysToCamelCase from '../../shared/hooks/use-convert-keys-to-camelCase';
import Button from '../../shared/Button';
import Table from '../../Table';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from '../../shared/AppliedFilters';
import useFilters from '../../shared/hooks/use-filters';
import useFilterData from './useFilterData';
import { DEFAULT_FILTERS, TABLE_HEADERS } from './constants';
import useMembers from './useMembers';
import useSelect from '../../shared/hooks/use-select';
import { MemberURLsController } from './useMemberURLs';
import SelectedPill from '../../SelectedPill';

const testID = 'memberListMainEmp';
function MembersList({ memberSummaryUrl, canUserManageChartingNotes, groupsManagementUrl, addGroupDocumentationUrl }) {
  const { filters, setFilter, filtersArray } = useFilters(DEFAULT_FILTERS);
  const {
    daysData,
    statesData,
    staffData,
    facilitiesData,
    officeManagerData,
    dosagesData,
    statusesData,
    txWeeksData,
    payorsData,
    isLoading: isFilterDataLoading,
  } = useFilterData();
  const [sessionIdentifier] = useState(crypto.randomUUID());
  const { currentData, currentPage, fetchData, sortedBy, handleSort, isLoading } = useMembers(filters);
  const camelCaseMembers = useConvertObjKeysToCamelCase(currentData?.json_data);
  const { selected, setSelected, toggle: toggleMemberSelection } = useSelect();
  const [showFilters, setShowFilters] = useState(false);
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;

  const toggleFilters = useCallback(() => setShowFilters((prev) => !prev), []);

  const selectedMemberIds = selected.map((s) => s.id);
  const allMembersSelected = selected.length === camelCaseMembers?.length;
  const formattedAddGroupDocumentationUrl = addGroupDocumentationUrl?.replaceAll(
    '{{json_params}}',
    encodeURI(JSON.stringify({ member_ids: selectedMemberIds.join(','), session_identifier: sessionIdentifier }))
  );

  const handleCheckboxChange = useCallback(
    (checked) => {
      setSelected(checked ? camelCaseMembers : []);
    },
    [camelCaseMembers, setSelected]
  );

  const renderAddDocumentationButton = () => {
    if (!canUserManageChartingNotes) {
      return null;
    }

    if (selected.length > 0) {
      return (
        <a href={formattedAddGroupDocumentationUrl} target="_top" className="btn btn--primary mb-1">
          Add documentation
        </a>
      );
    }

    return (
      <Button isPrimary disabled={!selected.length}>
        Add documentation
      </Button>
    );
  };

  return (
    <MemberURLsController memberSummaryUrl={memberSummaryUrl} groupsManagementUrl={groupsManagementUrl}>
      <div data-testid={testID} className="flex flex-col flex-1 gap-6 overflow-auto mt-3">
        <div>
          <div className="flex justify-between items-end gap-6 border-gray-300 border-b pb-2">
            <h2 data-testid={`title-${testID}`} className="font-body font-semibold mb-1">
              Members
            </h2>
            <Button
              data-testid={`toggle-filters-button-${testID}`}
              onClick={toggleFilters}
              isPrimary
              classes="max-h-12"
            >
              <div className="flex items-center">
                Apply Filters
                <FilterBtnIcon className="text-2xl ml-2" />
              </div>
            </Button>
          </div>
          <AppliedFilters currentlyAppliedFilters={filtersArray} addTopMargin={false} />
        </div>
        {showFilters && !isFilterDataLoading && (
          <AvailableFilters
            currentlyAppliedFilters={filters}
            setFilter={setFilter}
            testID={testID}
            stateData={statesData}
            facilityData={facilitiesData}
            groupDayData={daysData}
            memberData={[]}
            dosageData={dosagesData}
            statusData={statusesData}
            txData={txWeeksData}
            payerData={payorsData}
            officeManagerData={officeManagerData}
            counselorData={staffData}
            providerData={staffData}
          />
        )}
        <div className="mt-4">
          {isLoading && !currentData?.json_data ? (
            <div data-testid={`noData-${testID}`} className="no-data-loader">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex flex-col gap-2.5">
              <div className="w-full flex justify-between items-center">
                <span className="text-gray-600 font-semibold">{`${selected.length} members selected`}</span>
                {renderAddDocumentationButton()}
              </div>
              {selected.length > 0 && (
                <div className="flex gap-2 flex-wrap">
                  {selected.map((selectedMember) => (
                    <SelectedPill
                      key={selectedMember.id}
                      label={selectedMember.memberName}
                      onRemove={() => toggleMemberSelection(selectedMember)}
                    />
                  ))}
                </div>
              )}
              <div className="border border-blue-300 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
                <Table
                  tableType="membersTableDP2"
                  showShadow={false}
                  stickyHeader
                  enableActionsColumn
                  stickyActionColumn
                  enableHidingShadowInStickyColumn
                  enableCheckboxColumn
                  onCheckboxChange={handleCheckboxChange}
                  checkboxAllSelected={allMembersSelected}
                  onRowCheckboxChange={toggleMemberSelection}
                  displayPaginationStartingElementIndex={false}
                  selectedKeys={selectedMemberIds}
                  currentPage={currentPage}
                  tableColumnHeaders={TABLE_HEADERS}
                  tableRows={camelCaseMembers}
                  pageChange={fetchData}
                  isLastPage={currentData.is_last_page}
                  totalCountRecords={currentData.total_count}
                  headerButtonClick={handleSort}
                  currentSort={sortedBy}
                  loading={isLoading}
                  testID={testID}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </MemberURLsController>
  );
}

MembersList.propTypes = {
  memberSummaryUrl: PropTypes.string.isRequired,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  groupsManagementUrl: PropTypes.string.isRequired,
  addGroupDocumentationUrl: PropTypes.string.isRequired,
};

export default MembersList;
