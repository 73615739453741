import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../GroupsModal';

function DeleteNoteConfirmationModal({ isOpen, onClose, onDelete, isLoading, templateName }) {
  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Delete',
      isWarning: true,
      onClick: onDelete,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      testID="delete-note-confirmation-modal"
      isOpen={isOpen}
      header="Delete Note"
      footerButtons={footerButtons}
      isLoading={isLoading}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="px-6 py-4 text-lg border-b-2 border-gray-300">
          <p>
            Are you sure you want to
            <span className="font-bold"> delete </span>
            this template and all of its data?
          </p>
          <p className="font-semibold text-error-700 mb-0"> This action cannot be undone.</p>
        </div>
        <div className="px-6 py-4">
          <div>
            <p className="text-base font-semibold mb-2">Template</p>
            <p className="mb-0">{templateName}</p>
          </div>
        </div>
      </div>
    </GroupsModal>
  );
}

DeleteNoteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  templateName: PropTypes.string.isRequired,
};

DeleteNoteConfirmationModal.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default DeleteNoteConfirmationModal;
