import PropTypes from 'prop-types';

export const signer = PropTypes.shape({
  signedAt: PropTypes.string,
  signedBy: PropTypes.string,
});

export const FormAnswerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  answer: PropTypes.PropTypes.string,
  valueToFilter: PropTypes.string,
  filterType: PropTypes.string,
  conditionalCustomModuleId: PropTypes.string,
  customModule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    modType: PropTypes.string.isRequired,
    optionsArray: PropTypes.arrayOf(PropTypes.string),
  }),
});

export const FormAnswerGroupPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  hasPermissionToEdit: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  filler: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  lockedAt: PropTypes.string,
  lockedBy: PropTypes.shape({
    name: PropTypes.string,
  }),
  formAnswers: PropTypes.arrayOf(FormAnswerPropType),
  addendums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
    })
  ),
  coSigners: PropTypes.arrayOf(signer),
  signer,
});

export const MemberNotesFormAnswerGroupPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  ids: PropTypes.objectOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  filler: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  lockedAt: PropTypes.string,
  lockedBy: PropTypes.shape({
    name: PropTypes.string,
  }),
  lockedNoteIds: PropTypes.arrayOf(PropTypes.string),
  coSigners: PropTypes.arrayOf(
    PropTypes.shape({
      signedAt: signer.signedAt,
      signedBy: signer.signedBy,
      coSignedFor: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  addendums: PropTypes.arrayOf(
    PropTypes.shape({
      addedFor: PropTypes.arrayOf(PropTypes.string).isRequired,
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
    })
  ),
  formAnswers: PropTypes.objectOf(FormAnswerPropType),
  hasPermissionToEdit: PropTypes.bool.isRequired,
});

export const MemberPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  mrn: PropTypes.string.isRequired,
  healthieMrn: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const SmartPhrasesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phrase: PropTypes.string.isRequired,
  })
);
