import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { AiOutlineClose } from 'react-icons/ai';

function Modal({ children, onClose, testId }) {
  return createPortal(
    <div className="fixed inset-0 overflow-y-auto z-20" data-testid={`${testId}-modal`}>
      <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
        <div className="default-modal align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
          <div className="p-6 w-full whitespace-normal z-30">
            {!!onClose && (
              <button className="modal-close-button" onClick={onClose} aria-label="Close Modal" type="button">
                <AiOutlineClose />
              </button>
            )}
            {children}
          </div>
        </div>
        <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
      </div>
    </div>,
    document.body
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  testId: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  children: [],
  onClose: null,
};

export default Modal;
