import React from 'react';
import { PropTypes } from 'prop-types';

import LongTermGoalBadge from './LongTermGoalBadge';
import RsaLockBadge from './RsaLockBadge';
import ShortTermGoalBadge from './ShortTermGoalBadge';

function HistoricalGoalHeader({ goal, testId }) {
  const deactivatedText = () => {
    if (goal.status === 'completed') {
      return 'completed a';
    }
    return 'removed a';
  };

  const goalBadge = () => {
    if (goal.type === 'LongTermGoal') {
      return <LongTermGoalBadge testId={`ltg-badge-${testId}`} />;
    }
    if (goal.pinned) {
      return <RsaLockBadge testId={`rsa-badge-${testId}`} />;
    }
    return <ShortTermGoalBadge testId={`stg-badge-${testId}`} />;
  };

  return (
    <div className="historical-goal-header" data-testid={testId}>
      <p>
        <strong>{goal.deactivated_by}</strong> {deactivatedText()}
      </p>
      {goalBadge()}
    </div>
  );
}

HistoricalGoalHeader.propTypes = {
  goal: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
};

export default HistoricalGoalHeader;
