const tabs = [
  {
    id: 1,
    name: 'Active Panels',
    key: 'active',
  },
  {
    id: 2,
    name: 'Pending Panels',
    key: 'pending',
  },
  {
    id: 3,
    name: 'Archived Panels',
    key: 'archived',
  },
];

export default tabs;
