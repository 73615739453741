import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonPopup from '../../../shared/ButtonPopup';
import Button from '../../../shared/Button';
import { IndividualAppointmentCamelCasePropTypes } from '../shared/types';
import CancelAppointmentConfirmationModal from '../shared/CancelAppointmentConfirmationModal';
import CopyMeetingLinkButton from '../shared/CopyMeetingLinkButton';
import AddSelfAsZoomHostConfirmationModal from '../shared/AddSelfAsHostConfirmationModal';
import RemoveMembersConfirmationModal from './RemoveMemberConfirmationModal';
import { navigateTopToLink } from '../../../../helpers/utils';
import { SESSION_STORAGE_KEYS } from '../../../../helpers/constants';

function AppointmentActions({
  appointment,
  canUserManageAppointment,
  updateAppointment,
  authenticityToken,
  addToast,
  isDisabled,
  appointmentFinderUrl,
}) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showAddSelfAsZoomHostModal, setShowAddSelfAsZoomHostModal] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);

  const onCancel = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SHOW_APPOINTMENT_CANCELLED_TOAST, 'true');
    navigateTopToLink(appointmentFinderUrl);
  };

  const redirectToFinder = () => {
    navigateTopToLink(appointmentFinderUrl);
  };

  if (!appointment) return null;

  const appointmentActions = [
    {
      component: (
        <Button classes="w-max" onClick={() => setShowAddSelfAsZoomHostModal(true)} isDefaultButton={false}>
          Add Self as Zoom Host
        </Button>
      ),
      doesRequirePermission: true,
    },
    {
      component: <CopyMeetingLinkButton meetingLink={appointment.videoMeetingClientInvitation} addToast={addToast} />,
      doesRequirePermission: false,
    },
    {
      component: (
        <Button classes="w-max" onClick={() => setShowRemoveMemberModal(true)} isDefaultButton={false}>
          Remove from Appointment
        </Button>
      ),
      doesRequirePermission: true,
    },
    {
      component: (
        <Button
          classes="w-max text-error-700 font-bold"
          onClick={() => setShowCancelModal(true)}
          isDefaultButton={false}
        >
          Cancel Appointment
        </Button>
      ),
      doesRequirePermission: true,
    },
  ];

  return (
    <>
      <AddSelfAsZoomHostConfirmationModal
        isOpen={showAddSelfAsZoomHostModal}
        onClose={() => setShowAddSelfAsZoomHostModal(false)}
        appointmentId={appointment.id}
        authenticityToken={authenticityToken}
        addToast={addToast}
      />
      <RemoveMembersConfirmationModal
        isOpen={showRemoveMemberModal}
        onClose={() => setShowRemoveMemberModal(false)}
        redirectToFinder={redirectToFinder}
        updateAppointment={updateAppointment}
        appointmentId={appointment.id}
        members={appointment.members}
        authenticityToken={authenticityToken}
        addToast={addToast}
      />
      <CancelAppointmentConfirmationModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onSuccess={onCancel}
        appointmentId={appointment.id}
        authenticityToken={authenticityToken}
        addToast={addToast}
      />
      <ButtonPopup buttonLabel="Other actions" isDisabled={isDisabled}>
        {appointmentActions.map((action) =>
          !action.doesRequirePermission || canUserManageAppointment ? action.component : undefined
        )}
      </ButtonPopup>
    </>
  );
}

AppointmentActions.propTypes = {
  appointment: IndividualAppointmentCamelCasePropTypes.isRequired,
  canUserManageAppointment: PropTypes.bool.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  addToast: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  appointmentFinderUrl: PropTypes.string.isRequired,
};

AppointmentActions.defaultProps = {
  isDisabled: false,
};

export default AppointmentActions;
