export const TABLE_HEADERS = [
  {
    name: 'Member',
    showColumn: true,
    isClickable: true,
    actionName: 'member',
  },
  {
    name: 'Status',
    showColumn: true,
  },
  {
    name: 'Reason',
    showColumn: true,
  },
  {
    name: 'Appointment',
    showColumn: true,
    isClickable: true,
    actionName: 'appointment_date',
  },
  {
    name: 'Due Date',
    showColumn: true,
    isClickable: true,
    actionName: 'due_date',
  },
  {
    name: 'Assignee',
    showColumn: true,
  },
  {
    name: 'Empanelment',
    showColumn: true,
  },
  {
    name: 'Actions',
    showColumn: true,
    isClickable: false,
    actionColumn: true,
  },
];

export const FILTER_TYPES = {
  status: 'status',
  assignee: 'assignee',
  reason: 'reason',
  member: 'member',
  state: 'state',
  providers: 'providers',
  locations: 'locations',
  dueDate: 'due_date',
  fromTime: 'from_time',
  toTime: 'to_time',
  fromDate: 'from_date',
  toDate: 'to_date',
};

const DEFAULT_STATUS_FILTER = { value: 'ready_for_review', label: 'Ready for Review' };

export const DEFAULT_FILTERS = {
  page: 1,
  [FILTER_TYPES.status]: [DEFAULT_STATUS_FILTER],
  [FILTER_TYPES.assignee]: [],
  [FILTER_TYPES.reason]: [],
  [FILTER_TYPES.member]: null,
  [FILTER_TYPES.state]: [],
  [FILTER_TYPES.providers]: [],
  [FILTER_TYPES.locations]: [],
  [FILTER_TYPES.dueDate]: null,
  [FILTER_TYPES.fromTime]: null,
  [FILTER_TYPES.toTime]: null,
  [FILTER_TYPES.fromDate]: null,
  [FILTER_TYPES.toDate]: null,
};
