/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputWrapper from './InputWrapper';

// eslint-disable-next-line react/display-name
const TextareaInput = forwardRef(
  ({ label, name, value, onBlur, onChange, placeholder, rows, error, disabled, required, isVertical }, ref) => (
    <InputWrapper label={label} name={name} required={required} error={error} isVertical={isVertical}>
      <textarea
        data-testid="textarea"
        ref={ref}
        id={name}
        name={name}
        rows={rows}
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames('shared-input', { 'shared-input-error': !!error })}
      />
    </InputWrapper>
  )
);

TextareaInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
};

TextareaInput.defaultProps = {
  label: null,
  placeholder: null,
  rows: undefined,
  disabled: false,
  required: false,
  isVertical: true,
};

export default TextareaInput;
