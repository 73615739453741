/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Filters from '../Filters';

// HOOKS
import useUdsSupplyTaskFilters from './hooks/use-uds-supply-task-filters';
import SearchFilter from '../shared/SearchFilter';
import Button from '../shared/Button';

function UdsSupplyFilters(props) {
  const { staff, states, locations, dayOptions, timeOptions, preferenceOptions, weekRangeOptions } =
    useUdsSupplyTaskFilters();

  /** Filter States */
  const [updatedFilters, setUpdatedFilters] = useState({});

  // EMPANELMENT FILTERS
  const [empaneledStaff, setEmpaneledStaff] = useState(null);
  const [empaneledFacility, setEmpaneledFacility] = useState(null);
  const [empaneledState, setEmpaneledState] = useState(null);
  const [empaneledGroupDay, setEmpaneledGroupDay] = useState(null);
  const [empaneledTime, setEmpaneledTime] = useState(null);
  const [preference, setPreference] = useState(null);
  const [member, setMember] = useState(null);
  const [treatmentWeekRange, setTreatmentWeekRange] = useState(null);

  const filters = () => {
    const setFilters = {
      empaneledStaff,
      empaneledFacility,
      empaneledState,
      empaneledGroupDay,
      empaneledTime,
      preference,
      member,
      treatmentWeekRange,
    };

    setUpdatedFilters(setFilters);
  };

  useEffect(() => {
    filters();
  }, [
    empaneledStaff,
    empaneledFacility,
    empaneledState,
    empaneledGroupDay,
    empaneledTime,
    preference,
    member,
    treatmentWeekRange,
  ]);

  useEffect(() => {
    props.onFilterUpdate(updatedFilters);
  }, [updatedFilters]);

  const clearFilters = () => {
    setEmpaneledStaff(null);
    setEmpaneledFacility(null);
    setEmpaneledState(null);
    setEmpaneledGroupDay(null);
    setEmpaneledTime(null);
    setPreference(null);
    setMember(null);
    setTreatmentWeekRange(null);
  };

  return (
    <div className="flex-column">
      <div className="flex">
        <SearchFilter
          label="Member"
          value={member}
          placeholder={member ? null : 'Search'}
          onChange={(searchInput) => {
            setMember(searchInput);
          }}
          style={{ marginRight: '20px' }}
        />
        <Filters
          label="Staff"
          isMulti
          removeAllSelection
          data={staff}
          value={empaneledStaff}
          placeholder={empaneledStaff ? null : 'Search'}
          stateChanger={(updatedState) => {
            setEmpaneledStaff(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />

        <Filters
          label="Facility"
          isMulti
          removeAllSelection
          data={locations}
          value={empaneledFacility}
          placeholder={empaneledFacility ? null : 'Search'}
          stateChanger={(updatedState) => {
            setEmpaneledFacility(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />
        <Filters
          isMulti={false}
          label="Preference"
          data={preferenceOptions}
          value={preference}
          placeholder={preference ? null : 'Search'}
          stateChanger={(updatedState) => {
            setPreference(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />
      </div>
      <div className="flex mt-5">
        <Filters
          isMulti={false}
          label="Tx (Weeks)"
          data={weekRangeOptions}
          value={treatmentWeekRange}
          placeholder={treatmentWeekRange ? null : 'Search'}
          stateChanger={(updatedState) => {
            setTreatmentWeekRange(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />

        <Filters
          label="State"
          isMulti
          removeAllSelection
          data={states}
          value={empaneledState}
          placeholder={empaneledState ? null : 'Search'}
          stateChanger={(updatedState) => {
            setEmpaneledState(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />

        <Filters
          label="Group Day"
          isMulti
          removeAllSelection
          data={dayOptions}
          value={empaneledGroupDay}
          placeholder={empaneledGroupDay ? null : 'Search'}
          stateChanger={(updatedState) => {
            setEmpaneledGroupDay(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />

        <Filters
          isMulti={false}
          label="Group Time"
          data={timeOptions}
          value={empaneledTime}
          placeholder={empaneledTime ? null : 'Search'}
          stateChanger={(updatedState) => {
            setEmpaneledTime(updatedState);
          }}
          style={{ marginRight: '20px' }}
        />
      </div>
      <Button isTertiary onClick={() => clearFilters({})} style={{ marginTop: '10px' }}>
        Clear Filters
      </Button>
    </div>
  );
}

UdsSupplyFilters.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
};

export default UdsSupplyFilters;
