import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../shared/Header';
import Filters from '../Filters';

function TaskModal(props) {
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [note, setNote] = useState('');

  function renderRecipientField() {
    if (props?.modalType === 'QUESTION') {
      return (
        <>
          <div className="flex">
            <div className="flex-grow ml-4 mr-4 mt-4">
              <p className="font-bold">Recipient</p>
              <p>{props?.data?.recipient_name}</p>
            </div>
          </div>

          <hr className="w-full mt-1" />
        </>
      );
    }

    if (props?.modalType === 'REASSIGN') {
      return (
        <>
          <div className="flex">
            <div className="flex-grow ml-4 mr-4 mt-4 mb-2">
              <p className="font-bold">New Assignee*</p>
              <Filters
                isMulti={false}
                removeAllSelection
                type="staff"
                data={props.staff}
                stateChanger={setSelectedStaff}
                placeholder="Select"
              />
            </div>
          </div>

          <hr className="w-full mt-1" />
        </>
      );
    }

    return <></>;
  }

  function renderInputTextLabel() {
    if (props?.modalType === 'RESOLVE' || props?.modalType === 'UNRESOLVE') {
      return <div className="font-bold">Optional Note</div>;
    }

    if (props?.modalType === 'QUESTION') {
      return <div className="font-bold">Question*</div>;
    }

    if (props?.modalType === 'REASSIGN') {
      return <div className="font-bold">Note*</div>;
    }

    return <></>;
  }

  const handleCancel = () => {
    setNote('');
    props.onCancel();
  };

  const handleContinue = () => {
    const isTypeQuestion = props?.modalType === 'QUESTION';
    const isTypeReassign = props?.modalType === 'REASSIGN';

    const isNoteInvalid = (isTypeQuestion || isTypeReassign) && note === '';
    const isInvalidReassign = isTypeReassign && !selectedStaff;
    const isInvalidQuestion = isTypeQuestion && !props?.data?.recipient_id;

    if (isNoteInvalid) {
      const inputType = props?.modalType === 'QUESTION' ? 'question' : 'note';
      alert(`The ${inputType} requires a value`);
      return;
    }

    if (isInvalidReassign) {
      alert('New Assignee not found.');
      return;
    }
    if (isInvalidQuestion) {
      alert('Recipient not found.');
      return;
    }

    setNote('');
    props.onContinue(note, selectedStaff);
  };

  return (
    <>
      {props.isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-2xl sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                <Header text={props?.headerText} />

                <form>
                  <dl className="bg-white flex flex-col rounded-b-lg mt-4">
                    <div className="flex">
                      <div className="flex-grow ml-4 mr-4 mt-2 mt-4">
                        <p className="font-bold">Member</p>
                        <p>{props?.data?.member_name}</p>
                      </div>

                      <div className="flex-grow ml-4 mr-4 mt-4">
                        <p className="font-bold">Task</p>
                        <p>{props?.data?.task_type}</p>
                      </div>
                    </div>

                    <hr className="w-full" />

                    {renderRecipientField()}

                    <div className="ml-4 mr-5 mt-4">
                      {renderInputTextLabel()}

                      <textarea
                        type="text"
                        name="name"
                        style={{ width: '100%', marginTop: '5px' }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />

                      <div className="text-sm mb-2 text-center text-gray-700">
                        This note will only be seen in the digital platform. All clinical notes must go in eCW.
                      </div>
                    </div>
                  </dl>
                </form>

                {/* BUTTONS */}
                <div className="flex mt-4">
                  <button
                    className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                    onClick={handleCancel}
                    type="button"
                  >
                    Back
                  </button>

                  <button className="btn btn--rounded btn--primary flex-1 ml-2" type="button" onClick={handleContinue}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

TaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default TaskModal;
