import React from 'react';
import PropTypes from 'prop-types';

function ManualUploadWarningModal({ confirmData, isOpen, member, onCancel, onContinue }) {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Are you sure?</h2>
                <p>
                  You are confirming the following information about a Care Plan for
                  <strong>{` ${member.name} (${member.external_id})`}</strong>
                </p>

                <li>
                  <strong>Staff Signer: </strong>
                  {confirmData?.provider?.label}
                </li>

                <li>
                  <strong>Date of Staff Signature: </strong>
                  {confirmData?.providerSignatureDate?.toDateString()}
                </li>

                {confirmData?.cosignerSignaturePresent && (
                  <>
                    <li>
                      <strong>Staff Co-signer: </strong>
                      {confirmData?.cosigner?.label}
                    </li>

                    <li>
                      <strong>Date of Co-signer Signature: </strong>
                      {confirmData?.cosignerSignatureDate?.toDateString()}
                    </li>
                  </>
                )}

                <li>
                  <strong>Member: </strong>
                  {member?.name}
                </li>

                <li>
                  <strong>Date of Member Signature: </strong>
                  {confirmData?.memberSignatureDate?.toDateString()}
                </li>

                <li>
                  <strong>File Name: </strong>
                  {confirmData?.fileToUpload?.name}
                </li>

                <div className="flex mt-4">
                  <button
                    className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                    onClick={onCancel}
                    type="button"
                  >
                    Back
                  </button>

                  <button className="btn btn--rounded btn--primary flex-1 ml-2" type="button" onClick={onContinue}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

ManualUploadWarningModal.propTypes = {
  confirmData: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    external_id: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

ManualUploadWarningModal.defaultProps = {};

export default ManualUploadWarningModal;
