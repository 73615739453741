import React from 'react';
import PropTypes from 'prop-types';

import GroupsModal from './GroupsModal';

function ManagementGroupDeleteModal({ isOpen, groupToDelete, isHomeGroup, onClose, onDelete, isLoading }) {
  const groupDetails = groupToDelete
    ? [
        {
          name: 'Day/Time',
          value: groupToDelete.date_time,
          isVisible: true,
        },
        {
          name: 'Group Meeting Facility',
          value: groupToDelete.location,
          isVisible: true,
        },
        {
          name: 'Next Group Occurrence',
          value: groupToDelete.next_group_occurence,
          isVisible: !isHomeGroup,
        },
        {
          name: isHomeGroup ? 'Clinical Model' : 'Type',
          value: groupToDelete.clinical_model,
          isVisible: true,
        },
        {
          name: 'Other Information',
          value: groupToDelete.group_labels,
          isVisible: isHomeGroup,
        },
        {
          name: 'Modality',
          value: groupToDelete.modality_name,
          isVisible: isHomeGroup,
        },
        {
          name: 'Counselor',
          value: groupToDelete.counselor_name,
          isVisible: true,
        },
        {
          name: 'Provider',
          value: groupToDelete.provider_name,
          isVisible: true,
        },
        {
          name: 'Office Manager',
          value: groupToDelete.office_manager_name,
          isVisible: true,
        },
        {
          name: 'Next Counselor Group Occurrence',
          value: groupToDelete.next_counselor_group,
          isVisible: isHomeGroup,
        },
        {
          name: 'Next Provider Group Occurrence',
          value: groupToDelete.next_provider_group,
          isVisible: isHomeGroup,
        },
        {
          name: 'Seat Limit',
          value: groupToDelete.limit,
          isVisible: isHomeGroup,
        },
      ]
    : [];
  const isScheduledMembers = !isHomeGroup && !!groupToDelete?.scheduled_members;
  const subheader = isScheduledMembers && `This group has ${groupToDelete.scheduled_members} scheduled members.`;
  const removeText = ` remove all${isScheduledMembers ? ' members and' : ''} occurrences `;
  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Delete',
      isWarning: true,
      onClick: onDelete,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      data-testid="management-group-delete-modal"
      isOpen={isOpen}
      header="Are you sure?"
      footerButtons={footerButtons}
      size="small"
      isLoading={isLoading}
    >
      {subheader && (
        <p data-testid="subheader" className="mb-0 text-2xl font-bold text-error-700">
          {subheader}
        </p>
      )}
      <p className="mb-0">
        Clicking "delete" will
        <span className="font-bold">{removeText}</span>
        of this group on the schedule, and members can no longer be added to it.
        <span className="font-semibold text-error-700"> This action cannot be undone. </span>
      </p>
      <ul>
        {groupDetails.map(
          ({ name, value, isVisible }) =>
            isVisible && (
              <li key={name}>
                <span className="font-bold">{`${name}: `}</span>
                {value}
              </li>
            )
        )}
      </ul>
    </GroupsModal>
  );
}

ManagementGroupDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  groupToDelete: {
    id: PropTypes.number,
    date_time: PropTypes.string,
    location: PropTypes.string,
    next_group_occurence: PropTypes.string,
    makeup_type: PropTypes.string,
    clinical_model: PropTypes.string,
    group_labels: PropTypes.string,
    modality_name: PropTypes.string,
    counselor_name: PropTypes.string,
    provider_name: PropTypes.string,
    office_manager_name: PropTypes.string,
    next_counselor_group: PropTypes.string,
    next_provider_group: PropTypes.string,
    limit: PropTypes.string,
  }.isRequired,
  isHomeGroup: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ManagementGroupDeleteModal.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default ManagementGroupDeleteModal;
