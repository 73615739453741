import React from 'react';
import PropTypes from 'prop-types';
import { FaRegCheckCircle as CheckIcon } from 'react-icons/fa';
import { FaCircleExclamation } from 'react-icons/fa6';
import classNames from 'classnames';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';
import GroupsModal from './GroupsModal';
import { DEFAULT_DATETIME_FORMAT } from '../helpers/DateFormatter';

function DocumentationActionPerformedSummaryModal({ status, header, message, isOpen, setIsOpen, content }) {
  const hasValidValues = content.some((item) => item.value);
  return (
    <GroupsModal
      testID="documentation-action-performed-summary-modal"
      isOpen={isOpen}
      header={header}
      footerButtons={[
        {
          type: 'button',
          label: 'Close',
          isPrimary: true,
          onClick: () => setIsOpen(false),
        },
      ]}
      size="medium"
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4 border-b-2">
          <div
            className={classNames('flex gap-2 items-center font-semibold', {
              'text-error-700': status === 'error',
              'text-positive-700': status === 'success',
            })}
          >
            <div data-testid={`documentation-action-performed-summary-modal-status-${status}`} className="w-4 h-4">
              {status === 'success' ? <CheckIcon /> : <FaCircleExclamation />}
            </div>
            <p data-testid="documentation-action-performed-summary-modal-status-message" className="m-0">
              {message}
            </p>
          </div>
        </div>
        {hasValidValues && (
          <div className="p-4 border-b">
            {content.map(({ label, value, type }) => {
              let formattedValue = value;

              if (type === 'date') {
                const [dateWithOffset, dateTimeZone] = convertToSupportedTimeZone(value);
                formattedValue = `${dateWithOffset.format(DEFAULT_DATETIME_FORMAT)} ${dateTimeZone.abbrev}`;
              }

              return (
                <div className="mb-2" key={label}>
                  <span className="font-semibold">{label}: </span>
                  <span data-testid={`documentation-action-performed-summary-modal-${label}`}>
                    {formattedValue || '-'}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </GroupsModal>
  );
}

DocumentationActionPerformedSummaryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['error', 'success']).isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default DocumentationActionPerformedSummaryModal;
