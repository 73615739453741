import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../shared/Header';

function ExampleBlock(props) {
  return (
    <div className="mb-10">
      <Header text={props.headerText} withBottomMargin />
      {props.children}
    </div>
  );
}

ExampleBlock.propTypes = {
  children: PropTypes.node.isRequired,
  headerText: PropTypes.string,
};

ExampleBlock.defaultProps = {
  headerText: 'Header',
};

export default ExampleBlock;
