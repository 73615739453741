import { DEFAULT_DATE_FORMAT } from '../../../helpers/DateFormatter';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';

const prepareDateFormat = (date) => {
  if (!date) {
    return 'N/A';
  }

  const [dateTimeWithOffset] = convertToSupportedTimeZone(date);
  return dateTimeWithOffset.format(DEFAULT_DATE_FORMAT);
};

const setGroupsData = (row) => {
  const {
    id,
    active_members,
    available_seats,
    counselor: { counselor_name },
    empaneled_members,
    empanelment_group: { date_time, location, modality_name, distance, clinical_model_name },
    limit,
    max_capacity,
    next_counselor_group,
    next_provider_group,
    next_group_occurence,
    office_manager: { office_manager_name },
    provider: { provider_name },
    scheduled_members,
    default_secondary_group_type_names,
  } = row;

  return {
    id,
    active_members,
    available_seats,
    counselor_name,
    date_time,
    distance,
    empaneled_members,
    clinical_model: clinical_model_name,
    group_labels: default_secondary_group_type_names?.join(', '),
    limit,
    max_capacity,
    location,
    modality_name,
    next_counselor_group: prepareDateFormat(next_counselor_group),
    next_provider_group: prepareDateFormat(next_provider_group),
    next_group_occurence: prepareDateFormat(next_group_occurence),
    office_manager_name,
    provider_name,
    row,
    scheduled_members,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { setGroupsData };
