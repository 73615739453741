import React from 'react';
import PropTypes from 'prop-types';

import LongTermGoalIcon from './LongTermGoalIcon';
import GoalDetails from './GoalDetails';
import GoalActionsButton from './GoalActionsButton';

function LongTermGoal({ goal, updateCallback, editCallback, testId }) {
  return (
    <div className="long-term-goal-container goal-container" data-testid={`container-${testId}`}>
      <h4 className="goal-type" data-testid={`type-${testId}`}>
        LONG-TERM GOAL
      </h4>
      <div className="goal-row" data-testid={`row-${testId}`}>
        <LongTermGoalIcon testId={`icon-${testId}`} />
        <GoalDetails goal={goal} testId={`details-${testId}`} />
        <GoalActionsButton
          goalId={goal.id}
          testId={`actions-btn-${testId}`}
          updateCallback={updateCallback}
          editCallback={editCallback}
        />
      </div>
    </div>
  );
}

LongTermGoal.propTypes = {
  goal: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
  updateCallback: PropTypes.func,
  editCallback: PropTypes.func,
};

LongTermGoal.defaultProps = {
  updateCallback: () => {},
  editCallback: () => {},
};

export default LongTermGoal;
