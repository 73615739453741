import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import MemberInformation from '../../member_summary/MemberInformation';
import GroupInformation from '../../member_summary/GroupInformation';
import Notes from '../../member_summary/Notes';
import OneMonthStats from '../../member_summary/OneMonthStats';
import MemberDetailsBox from '../../member_summary/MemberDetailsBox';
import HistoricalDetailsSection from '../../member_summary/HistoricalDetailsSection';
import MedicalSection from '../../member_summary/MedicalSection';
import useConvertObjKeysToCamelCase from '../../shared/hooks/use-convert-keys-to-camelCase';

export const MEMBER_STATUSES = [
  'Lead',
  'Qualified Lead',
  'Potential New Member',
  'Potential Readmit',
  'Intake In Progress',
  'Intake Complete Pending First Group',
  'In Treatment',
  'Member',
  'Discharged',
];
export const MEMBER_SUMMARY_INCLUDED_STATUSES = ['Discharged', 'In Treatment'];

function Main({ member, is_embedded }) {
  const testID = 'memberSummaryMainEmp';

  const {
    groupInformation,
    memberSummaries: {
      clinical,
      medical,
      demographics,
      historicalDetails,
      historicalStats,
      monthToDate,
      healthieProfile,
    } = {},
    ...memberData
  } = useConvertObjKeysToCamelCase(member, ['variables']) || {};

  const healthieProfileNote = get(healthieProfile, 'componentData.note', '');

  const shouldDisplayMemberDetails = medical || clinical || demographics;
  const shouldDisplayHistoricalDetails = historicalStats || historicalDetails;

  const canShowMemberSummary = MEMBER_SUMMARY_INCLUDED_STATUSES.includes(memberData.status);
  const noDataHeading = canShowMemberSummary ? 'No data to display' : 'Waiting for first Home Group appointment.';
  const noDataText = canShowMemberSummary ? 'This member has no member summary.' : 'Episode of Care not started yet.';

  const shouldDisplayMemberSummary =
    canShowMemberSummary && (shouldDisplayMemberDetails || shouldDisplayHistoricalDetails);

  return (
    <section data-testid={testID} className="flex flex-col text-black">
      <div
        className={classnames('bg-white pb-6 px-10', {
          'pt-10': is_embedded,
          'pt-24': !is_embedded,
        })}
      >
        <div className="flex flex-col flex-1">
          <div className="flex flex-col md:flex-row justify-between gap-10">
            <div className="flex-1">
              <MemberInformation member={memberData} statuses={get(healthieProfile, 'componentData.tags', [])} />
            </div>
            <div className="flex-1">{monthToDate && <OneMonthStats monthData={monthToDate.componentData} />}</div>
          </div>
          <div className="flex flex-col md:flex-row justify-between mt-8 gap-10">
            {groupInformation && (
              <div className="flex-1">
                <GroupInformation {...groupInformation} />
              </div>
            )}
            {healthieProfileNote && (
              <div className="flex-1 md:w-1/2">
                <Notes notes={healthieProfileNote} />
              </div>
            )}
          </div>
        </div>
      </div>

      {shouldDisplayMemberSummary ? (
        <div className="flex flex-col md:flex-row flex-1 gap-10 m-8 bg-white rounded shadow p-5">
          {shouldDisplayMemberDetails && (
            <div className="flex-1 flex flex-col gap-3">
              {medical && <MedicalSection member={memberData} medical={medical} />}
              {clinical && <MemberDetailsBox {...clinical} />}
              {demographics && <MemberDetailsBox {...demographics} />}
            </div>
          )}
          {shouldDisplayHistoricalDetails && (
            <div className="flex-1">
              <HistoricalDetailsSection
                historicalStatsProps={{
                  data: historicalStats?.componentData,
                  lastSyncedDate: historicalStats?.updatedAt,
                }}
                attendanceWeeksProps={{
                  data: historicalDetails?.componentData,
                  lastSyncedDate: historicalDetails?.updatedAt,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center flex-1 gap-4 m-8 bg-white rounded shadow p-10">
          <span className="text-gray-700 font-bold text-lg">{noDataHeading}</span>
          <span className="text-gray-700">{noDataText}</span>
        </div>
      )}
    </section>
  );
}

Main.propTypes = {
  is_embedded: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    mrn: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.oneOf(MEMBER_STATUSES),
    current_status_from: PropTypes.string,
    avatar: PropTypes.string,
    id: PropTypes.number,
    group_information: PropTypes.shape({
      group_type: PropTypes.oneOf(['home', 'makeup']).isRequired,
      modality: PropTypes.string,
      clinical_model: PropTypes.string,
      location: PropTypes.string,
      date_time: PropTypes.string,
      office_manager_name: PropTypes.string,
      counselor_name: PropTypes.string,
      provider_name: PropTypes.string,
    }),
    member_summaries: PropTypes.objectOf(
      PropTypes.shape({
        component_data: PropTypes.arrayOf(PropTypes.any).isRequired,
        component_type: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        episode_of_care_id: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        updated_at: PropTypes.string.isRequired,
      })
    ),
    dob: PropTypes.string.isRequired,
  }).isRequired,
};

export default Main;
