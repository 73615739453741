import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import TaskTableActions from './TaskTableActions';

function TaskTableActionsCell(props) {
  return (
    <>
      {props.show && (
        <td>
          <TaskTableActions
            authenticityToken={props.authenticityToken}
            data={props.data}
            staff={props.staff}
            onActionSubmit={props.onActionSubmit}
          />
        </td>
      )}
    </>
  );
}

TaskTableActionsCell.propTypes = {
  show: PropTypes.bool,
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  onActionSubmit: PropTypes.func.isRequired,
};

TaskTableActionsCell.defaultProps = {
  show: true,
};

export default TaskTableActionsCell;
