import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Filters from '../../Filters';
import GenericModal from '../../shared/GenericModal';
import FedexMethod from '../shipments/FedexMethod';
import PickupMethod from '../shipments/PickupMethod';

// HELPERS
import { labelStyle } from './styles';
import { errorsExist, isEmptyObject } from '../../../helpers/AssortedHelpers';

function PickupShipmentModal(props) {
  const back = () => {
    props.onBack();
  };
  const confirm = (method) => {
    props.onConfirm(method);
  };

  // SET DATA
  const [method, setMethod] = useState({});
  const [methodSelected, setMethodSelected] = useState(null);

  // ERROR HANDLING
  const requiredText = 'This field is required';
  const [formErrors, setFormErrors] = useState({});
  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateObj = (obj, key) => {
    if (isEmptyObject(obj)) {
      setError(key, requiredText);
    } else {
      setError(key, null);
    }
  };

  const validateData = () => {
    validateObj(method?.selectedMethod, 'selectedMethod');

    if (methodSelected?.value === 'pickup') {
      validateObj(method?.pickupDate, 'pickupDate');
      validateObj(method?.readyTime, 'readyTime');
      validateObj(method?.latestTime, 'latestTime');
      validateObj(method?.location, 'location');
    }
  };

  // SET CACHING DATA
  const setData = (data) => {
    setMethod({ ...data });

    // TODO - ADD CACHING .. ? MIGHT NOT NEED
    // window.localStorage.setItem(`shipment-method-${props.selectedLabel?.labelName}`, JSON.stringify(data));
  };

  return (
    <GenericModal
      isOpen={props.isOpen}
      headerText="Pickup / Drop Off"
      primaryButtonLabel="Confirm and Print"
      secondaryButtonLabel="Back"
      secondaryButtonClick={() => back()}
      primaryButtonClick={() => {
        validateData();
        if (!errorsExist(formErrors)) {
          confirm(method);
        }
      }}
      primaryButtonLoading={props.printLabelLoading}
      primaryButtonDisabled={props.printLabelLoading}
    >
      {props?.fedexPickupError && (
        <p className="modal-error text-error-700 font-bold text-md error mt-4 ml-4">{props.fedexPickupErrorMessage}</p>
      )}

      <div className="m-4">
        <div className="mb-4">
          The following selection will apply to <span className="font-semibold">all</span> of your purchased labels.
        </div>

        <Filters
          style={{ marginBottom: '20px' }}
          label="Method*"
          value={methodSelected}
          data={[
            { label: 'Schedule a pickup', value: 'pickup' },
            { label: 'Drop off at a FedEx Location', value: 'fedex' },
          ]}
          placeholder="Select a method"
          stateChanger={(selected) => {
            method.selectedMethod = selected;
            setMethodSelected(selected);

            setData({ ...method });
          }}
          labelStyle={labelStyle}
          isMulti={false}
          removeAllSelection
          error={formErrors.selectedMethod}
        />

        {methodSelected?.value === 'fedex' && <FedexMethod />}
        {methodSelected?.value === 'pickup' && (
          <PickupMethod
            labelMethod={method || {}}
            locations={props.locations}
            formErrors={formErrors}
            onSetData={setData}
          />
        )}
      </div>
    </GenericModal>
  );
}

PickupShipmentModal.propTypes = {
  locations: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  fedexPickupError: PropTypes.bool.isRequired,
  fedexPickupErrorMessage: PropTypes.string.isRequired,
  printLabelLoading: PropTypes.bool.isRequired,
};

export default PickupShipmentModal;
