import React from 'react';
import PropTypes from 'prop-types';

import { formatShortDateTime, formatShortDate } from '../../DateFormatHelper';

function GoalDetails({ goal, shortTermGoal, testId }) {
  const dueDateText = () => {
    if (shortTermGoal) {
      return `Due ${formatShortDate(goal.due_date)}`;
    }
    return `Check-in ${formatShortDate(goal.due_date)}`;
  };

  return (
    <div className="goal-details" data-testid={testId}>
      <h4 className="active-goal-title" data-testid={`header-${testId}`}>
        {goal.title}
      </h4>
      <p data-testid={`due-date-${testId}`}>{dueDateText()}</p>
      <p data-testid={`created-at-${testId}`}>
        {`Created ${formatShortDateTime(goal.created_at)}
         by ${goal.created_by}`}
      </p>
    </div>
  );
}

GoalDetails.propTypes = {
  goal: PropTypes.object.isRequired,
  shortTermGoal: PropTypes.bool,
  testId: PropTypes.string.isRequired,
};

GoalDetails.defaultProps = {
  shortTermGoal: false,
};

export default GoalDetails;
