/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import LocationTag from './LocationTag';

import { formatDayOfWeek, formatTimeLocalized } from '../DateFormatHelper';

function EmpanelmentCell(props) {
  return (
    <>
      {props.show && (
        <td>
          {props?.hasEmpanelment && (
            <div className="m-4 leading-tight">
              <div className="my-1">{formatDayOfWeek(props?.dateTime)}</div>

              <p className="my-1">
                <span className="text-sm text-gray-700">{formatTimeLocalized(props?.dateTime)}</span>

                <LocationTag preloadedData={props?.locationName} />
              </p>

              {props?.showModality && (
                <p className="my-1">
                  <span className="text-sm text-gray-700">{props.modality}</span>
                </p>
              )}
            </div>
          )}
        </td>
      )}
    </>
  );
}

EmpanelmentCell.propTypes = {
  show: PropTypes.bool,
  hasEmpanelment: PropTypes.bool.isRequired,
  dateTime: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  showModality: PropTypes.bool,
  modality: PropTypes.string,
};

EmpanelmentCell.defaultProps = {
  show: true,
  showModality: false,
  modality: '',
};

export default EmpanelmentCell;
