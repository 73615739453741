/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// IMPORTS
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TooltipMui, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// COMPONENTS
import { PiUsersThreeBold } from 'react-icons/pi';
import { FiEdit } from 'react-icons/fi';
import { mdyyyy } from '../../helpers/DateFormatter';

const StyledTooltip = styled(({ className, ...props }) => <TooltipMui {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2c7a7b',
      flexGrow: 1,
    },
  })
);

function SupplyCell(props) {
  const [isHovering, setIsHovering] = useState(false);
  const { shipmentObj } = props;

  const showEdit =
    props.isEditable && ['pick_up', 'group_pick_up'].includes(shipmentObj?.shipment_type) && props.featureFlagEdit;
  const needsSpacing = props.isEditable && !showEdit;

  const shipmentTypeString = () => {
    if (shipmentObj?.shipment_type === 'pick_up') {
      return 'PICKUP';
    }
    if (shipmentObj?.shipment_type === 'group_pick_up') {
      return 'GROUP PICKUP';
    }
    if (shipmentObj?.shipment_type === 'group_shipment') {
      return 'GROUP SHIP';
    }
    if (shipmentObj?.shipment_type === 'shipment') {
      return 'SHIP';
    }
    return '';
  };

  const amountsString = () => {
    const items = [
      { key: 'uds_cup_quantity', label: 'UDS' },
      { key: 'fentanyl_strip_quantity', label: 'Fentanyl' },
      { key: 'pregnancy_test_quantity', label: 'Pregnancy' },
      { key: 'oral_swab_quantity', label: 'Oral Swabs' },
      { key: 'pre_paid_envelope_quantity', label: 'Pre-Paid Envelopes' },
    ];

    const formattedString = items
      .filter((item) => shipmentObj?.[item.key] > 0)
      .map((item) => `${shipmentObj[item.key]} ${item.label}`)
      .join(', ');

    return formattedString;
  };

  const formatMembers = (otherMembers) => otherMembers.join('\n');

  const showNotGroupLabel = props.showNotGroupLabel && shipmentObj.other_members?.length === 0;

  const showTooltip = () => shipmentObj.other_members && shipmentObj.other_members.length > 0;

  return (
    <td>
      {shipmentObj && (
        <div className="m-4 flex" style={{ maxWidth: 230 }}>
          {showEdit && (
            <div className="flex items-center">
              <FiEdit
                className="empanelment-button fi-edit-button mt-4 cursor-pointer"
                sx={{ width: '1.25rem' }}
                onClick={props.onSupplyEdit}
              />
            </div>
          )}
          {needsSpacing && <div className="ml-10" />}
          <div className="flex-col">
            <div className="my-1 flex center">
              {props.showDate && <span className="mt-1 mr-1">{mdyyyy(shipmentObj.date)}</span>}

              {showTooltip() && (
                <StyledTooltip
                  open={isHovering}
                  title={
                    <Typography
                      sx={{
                        fontSize: '.875rem',
                        color: 'white',
                        fontWeight: 800,
                        fontFamily: 'Inter,sans-serif',
                        padding: '5px 10px 5px 10px',
                        marginBottom: '5px !important',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {formatMembers(shipmentObj.other_members)}
                    </Typography>
                  }
                  placement="top"
                >
                  <Button
                    sx={{
                      '&:hover': { backgroundColor: 'transparent' },
                      margin: 0,
                      padding: 0,
                      ...props.style,
                    }}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                  >
                    <span
                      className={`font-bold flex px-2 rounded-2xl border border-gray-300 font-semibold 
                      ${isHovering ? 'text-white bg-teal-700' : 'text-gray-700 bg-white'}`}
                    >
                      <PiUsersThreeBold className="mr-1" style={{ fontSize: '1.25rem' }} />
                      <span style={{ fontSize: '0.75rem', fontFamily: 'Brandon Grotesque' }}>
                        {shipmentTypeString()}
                      </span>
                    </span>
                  </Button>
                </StyledTooltip>
              )}

              {showNotGroupLabel && (
                <Button
                  disabled
                  sx={{
                    margin: 0,
                    padding: 0,
                    minWidth: 0,
                  }}
                >
                  <span className="font-bold flex px-2 rounded-2xl border border-gray-300 font-semibold text-gray-700 bg-white">
                    <span style={{ fontSize: '0.75rem', fontFamily: 'Brandon Grotesque' }}>{shipmentTypeString()}</span>
                  </span>
                </Button>
              )}
            </div>
            <div>
              <span className="text-gray-700 whitespace-pre-wrap">{amountsString()}</span>
            </div>
            {shipmentObj?.prev_uds_supply_count > 0 && (
              <div>
                <button
                  className="font-bold my-1 underline hover:no-underline cursor-pointer text-teal-700"
                  onClick={() => props.openSupplyHistoryModal()}
                  type="button"
                >
                  {shipmentObj.prev_uds_supply_count} more records
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </td>
  );
}

SupplyCell.propTypes = {
  style: PropTypes.object,
  shipmentObj: PropTypes.object,
  showDate: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  showNotGroupLabel: PropTypes.bool,
  featureFlagEdit: PropTypes.bool.isRequired,

  // FUNCTIONS
  openSupplyHistoryModal: PropTypes.func.isRequired,
  onSupplyEdit: PropTypes.func.isRequired,
};

SupplyCell.defaultProps = {
  style: {},
  shipmentObj: {},
  showNotGroupLabel: false,
};

export default SupplyCell;
