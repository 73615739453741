import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import SupplyCell from './SupplyCell';
import TrackingCell from './TrackingCell';
import DefaultCell from '../shared_dashboard/DefaultCell';
import { mdyyyy } from '../../helpers/DateFormatter';

function SupplyHistoryTable(props) {
  const [supplyHistories, setSupplyHistories] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const ref = useRef(null);

  useLayoutEffect(() => {
    ref.current.style.setProperty('max-height', '600px', 'important');
  }, []);

  const methodText = (method) => {
    if (method === 'pick_up' || method === 'group_pick_up') return 'Pickup';
    return 'Ship';
  };

  const sortSupplyHistories = (direction) => {
    const historiesWithDateObjects = supplyHistories.map((supplyHistory) => ({
      ...supplyHistory,
      dateObject: new Date(supplyHistory.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2')),
    }));

    if (direction === 'asc') {
      setSupplyHistories(historiesWithDateObjects.sort((a, b) => a.dateObject - b.dateObject));
    } else {
      setSupplyHistories(historiesWithDateObjects.sort((a, b) => b.dateObject - a.dateObject));
    }
  };

  const handleSort = () => {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(direction);
    sortSupplyHistories(direction);
  };

  useEffect(() => {
    const fetchSupplyHistories = async () => {
      axios.get(`/staff/uds_supply/uds_supply_histories?member_id=${props.memberId}`).then((response) => {
        setSupplyHistories(response.data);
      });
    };

    fetchSupplyHistories();
  }, [props.memberId]);

  const addressLineOne = (address) =>
    `${address?.street_address_one ?? ''} ${address?.street_address_two ?? ''}`.trim();

  const addressLineTwo = (address) =>
    `${address?.city ?? ''} ${address?.state ?? ''} ${address?.zip_code ?? ''}`.trim();

  return (
    <div>
      <TableContainer component={Paper} className="max-h-auto" style={{ height: '200px !important' }} ref={ref}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell onClick={() => handleSort()} sortKey="date">
                <button className="flex flex-row" type="button">
                  <span className="flex flex-col font-bold">
                    <span>Date Shipped/</span>
                    <span className="self-start">Picked Up</span>
                  </span>
                  <span className="flex self-center">
                    <UnfoldMoreIcon />
                  </span>
                </button>
              </TableCell>
              <TableCell>Tracking</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Supply</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Submitted By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplyHistories?.map((row) => (
              <TableRow key={row.id}>
                <DefaultCell preloadedData={methodText(row?.method)} />
                <DefaultCell preloadedData={mdyyyy(row?.date)} />
                <TrackingCell trackingObj={row?.latest_shipment_update} />
                <DefaultCell preloadedData={addressLineOne(row?.address)} lineTwo={addressLineTwo(row?.address)} />
                <SupplyCell
                  isEditable={false}
                  showDate={false}
                  shipmentObj={{
                    shipment_type: row?.method,
                    other_members: row?.other_members,
                    uds_cup_quantity: row?.uds_cup_quantity,
                    fentanyl_strip_quantity: row?.fentanyl_strip_quantity,
                    pregnancy_test_quantity: row?.pregnancy_test_quantity,
                    oral_swab_quantity: row?.oral_swab_quantity,
                    pre_paid_envelope_quantity: row?.pre_paid_envelope_quantity,
                  }}
                  showHistoryLink={false}
                />

                <DefaultCell preloadedData={row?.notes} customStyle={{ maxWidth: 230 }} />

                <DefaultCell preloadedData={row?.submitted_by} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

SupplyHistoryTable.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default SupplyHistoryTable;
