/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import enUS from 'rc-picker/lib/locale/en_US';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import TimePicker from 'rc-time-picker';
import CloseIcon from '@mui/icons-material/Close';
import { PiCalendarBlankBold, PiClockBold } from 'react-icons/pi';
import { FILTER_TYPES } from './constants';
import FiltersColumn from '../../FiltersColumn';
import Filters from '../../Filters';
import Button from '../../shared/Button';
import FilterTextField from '../../FilterTextField';

function PrescriptionTasksFilters({
  currentlyAppliedFilters,
  testID,
  setFilter,
  statusesData,
  assigneesData,
  locationsData,
  providersData,
  reasonsData,
  statesData,
  dueDatesData,
}) {
  const getStateChangeHandler = (type) => (selectedItem) => setFilter(type, selectedItem);
  const getTextChangeHandler = (type) => (value) => setFilter(type, value);
  const getSingleSelectChangeHandler = (type) => (selectedItem) => setFilter(type, [selectedItem]);

  return (
    <div className="border border-gray-300 rounded bg-white bg-opacity-50 grid grid-cols-3 divide-x divide-gray-300">
      <FiltersColumn>
        <Filters
          label="Status"
          type={FILTER_TYPES.status}
          data={statusesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.status)}
          testID={`status-${testID}`}
          placeholder="Search Status"
          value={currentlyAppliedFilters[FILTER_TYPES.status]}
        />
        <Filters
          label="Assignee"
          type={FILTER_TYPES.assignee}
          data={assigneesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.assignee)}
          testID={`assignee-${testID}`}
          placeholder="Search Assignee"
          value={currentlyAppliedFilters[FILTER_TYPES.assignee]}
        />
        <Filters
          label="Reason"
          type={FILTER_TYPES.reason}
          data={reasonsData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.reason)}
          testID={`reason-${testID}`}
          placeholder="Search Reason"
          value={currentlyAppliedFilters[FILTER_TYPES.reason]}
        />
        <FilterTextField
          label="Member"
          name="member-search-input"
          id="member-search-input"
          placeholder="Search Members by Name or MRN"
          value={currentlyAppliedFilters[FILTER_TYPES.member]}
          onChange={getTextChangeHandler(FILTER_TYPES.member)}
        />
      </FiltersColumn>
      <FiltersColumn>
        <Filters
          label="Empaneled State"
          type={FILTER_TYPES.state}
          data={statesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.state)}
          testID={`state-${testID}`}
          placeholder="Search States"
          value={currentlyAppliedFilters[FILTER_TYPES.state]}
        />
        <Filters
          label="Empaneled Staff"
          type={FILTER_TYPES.providers}
          data={providersData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.providers)}
          testID={`providers-${testID}`}
          placeholder="Search Staff"
          value={currentlyAppliedFilters[FILTER_TYPES.providers]}
        />
        <Filters
          label="Empaneled Facility"
          type={FILTER_TYPES.locations}
          data={locationsData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.locations)}
          testID={`locations-${testID}`}
          placeholder="Search Facilities"
          value={currentlyAppliedFilters[FILTER_TYPES.locations]}
        />
        <Filters
          label="Task Due Date"
          type={FILTER_TYPES.dueDate}
          data={dueDatesData}
          stateChanger={getSingleSelectChangeHandler(FILTER_TYPES.dueDate)}
          testID={`due-date-${testID}`}
          placeholder="Search Due Date"
          value={currentlyAppliedFilters[FILTER_TYPES.dueDate]}
          isMulti={false}
        />
      </FiltersColumn>
      <FiltersColumn>
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-gray-700">Appointment Time Range</span>
          <div className="flex flex-row picker-container">
            <label className="filter-label">
              From
              <TimePicker
                showSecond={false}
                placeholder="Select time"
                value={currentlyAppliedFilters[FILTER_TYPES.fromTime]}
                onChange={getStateChangeHandler(FILTER_TYPES.fromTime)}
                testID={`from-time-${testID}`}
                use12Hours
                inputReadOnly
                inputIcon={<PiClockBold />}
              />
            </label>
            <label className="filter-label">
              To
              <TimePicker
                showSecond={false}
                placeholder="Select time"
                value={currentlyAppliedFilters[FILTER_TYPES.toTime]}
                onChange={getStateChangeHandler(FILTER_TYPES.toTime)}
                testID={`to-time-${testID}`}
                use12Hours
                inputReadOnly
                inputIcon={<PiClockBold />}
              />
            </label>
            <Button
              type="button"
              aria-label="Clear Time"
              classes="flex w-7 h-7"
              isTertiary
              disabled={
                !currentlyAppliedFilters[FILTER_TYPES.fromTime] && !currentlyAppliedFilters[FILTER_TYPES.toTime]
              }
              onClick={(e) => {
                setFilter(FILTER_TYPES.fromTime, null);
                setFilter(FILTER_TYPES.toTime, null);
                e.target.blur();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-gray-700">Appointment Date Range</span>
          <div className="flex flex-row picker-container">
            <label className="filter-label">
              From
              <Picker
                type="date"
                locale={enUS}
                generateConfig={momentGenerateConfig}
                value={currentlyAppliedFilters[FILTER_TYPES.fromDate]}
                onChange={getStateChangeHandler(FILTER_TYPES.fromDate)}
                testID={`from-date-${testID}`}
                placeholder="Select date"
                suffixIcon={<PiCalendarBlankBold />}
              />
            </label>
            <label className="filter-label">
              To
              <Picker
                type="date"
                locale={enUS}
                generateConfig={momentGenerateConfig}
                value={currentlyAppliedFilters[FILTER_TYPES.toDate]}
                onChange={getStateChangeHandler(FILTER_TYPES.toDate)}
                testID={`to-date-${testID}`}
                placeholder="Select date"
                suffixIcon={<PiCalendarBlankBold />}
              />
            </label>
            <Button
              type="button"
              aria-label="Clear Date"
              classes="flex w-7 h-7"
              isTertiary
              disabled={
                !currentlyAppliedFilters[FILTER_TYPES.fromDate] && !currentlyAppliedFilters[FILTER_TYPES.toDate]
              }
              onClick={(e) => {
                setFilter(FILTER_TYPES.fromDate, null);
                setFilter(FILTER_TYPES.toDate, null);
                e.target.blur();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </FiltersColumn>
    </div>
  );
}

PrescriptionTasksFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
  statusesData: PropTypes.array,
  assigneesData: PropTypes.array,
  locationsData: PropTypes.array,
  providersData: PropTypes.array,
  reasonsData: PropTypes.array,
  statesData: PropTypes.array,
  dueDatesData: PropTypes.array,
};

PrescriptionTasksFilters.defaultProps = {
  statusesData: [],
  assigneesData: [],
  locationsData: [],
  providersData: [],
  reasonsData: [],
  statesData: [],
  dueDatesData: [],
};

export default PrescriptionTasksFilters;
