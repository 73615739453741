import React from 'react';
import PropTypes from 'prop-types';

import LongTermGoalIcon from './LongTermGoalIcon';

function LongTermGoalBadge({ testId }) {
  return (
    <div className="goal-badge long-term-goal-badge" data-testid={testId}>
      <LongTermGoalIcon testId={`icon-${testId}`} />
      <p data-testid={`text-${testId}`}>
        <strong>LONG-TERM GOAL</strong>
      </p>
    </div>
  );
}

LongTermGoalBadge.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default LongTermGoalBadge;
