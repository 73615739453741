import React, { useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import { PiCaretUpBold, PiCaretDownBold } from 'react-icons/pi';

// COMPONENTS
import Button from './Button';

const showText = 'SHOW DETAILS';
const hideText = 'HIDE DETAILS';

function ExpandButton(props) {
  const [showMore, setShowMore] = useState(false);

  function toggleShowMore() {
    const updatedValue = !showMore;

    setShowMore(updatedValue);
    props.onShowMore(updatedValue);
  }

  function renderText() {
    if (showMore) {
      return <>{hideText}</>;
    }
    return <>{showText}</>;
  }

  function renderArrowIcon() {
    if (showMore) {
      return <PiCaretUpBold className="my-auto ml-1" style={{ strokeWidth: '10px' }} />;
    }
    return <PiCaretDownBold className="my-auto ml-1" style={{ strokeWidth: '10px' }} />;
  }

  return (
    <Button
      show={props.show}
      isDefaultButton={false}
      classes={`my-1 text-sm underline no-underline ${props.class}`}
      style={{ color: '#217886', fontWeight: props.fontWeight, fontFamily: 'Brandon Grotesque' }}
      onClick={() => toggleShowMore()}
    >
      <div className="flex">
        {renderText()}
        {renderArrowIcon()}
      </div>
    </Button>
  );
}

ExpandButton.propTypes = {
  onShowMore: PropTypes.func.isRequired,

  class: PropTypes.object,
  show: PropTypes.bool,
  fontWeight: PropTypes.number,
};

ExpandButton.defaultProps = {
  class: {},
  show: true,
  fontWeight: 600,
};

export default ExpandButton;
