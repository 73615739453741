/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Filters from '../Filters';
import Header from '../shared/Header';
import SmallModal from '../shared/SmallModal';

import useUdsSupplyTaskFilters from './hooks/use-uds-supply-task-filters';

function UdsSupplyEditModal(props) {
  const preferences = [
    { label: 'Ship', value: 'ship' },
    { label: 'Pickup', value: 'pickup' },
  ];

  const { locations } = useUdsSupplyTaskFilters();

  const [pickupFacility, setPickupFacility] = useState();
  const [preference, setPreference] = useState('');

  useEffect(() => {
    if (props.data?.uds_supply_option === 'pickup') {
      const pref = preferences.find((opt) => opt.value === 'pickup');
      setPreference(pref);
    } else {
      const pref = preferences.find((opt) => opt.value !== 'pickup');
      setPreference(pref);
    }
  }, [props.data?.uds_supply_option]);

  useEffect(() => {
    const location = locations.find((opt) => opt.value === props.data?.uds_pickup_facility_id);
    setPickupFacility(location);
  }, [props.data?.uds_pickup_facility_id]);

  function renderMemberName() {
    return `${props?.data?.member_name} `;
  }

  function renderMemberMrn() {
    if (props?.data?.member_mrn) {
      return `(${props?.data?.member_mrn})`;
    }
    return '';
  }

  return (
    <>
      {props.isOpen && (
        <SmallModal>
          <Header text="Edit Preference" />

          {props?.errorMessage && (
            <p className="modal-error text-error-700 font-bold text-md error my-2">{props?.errorMessage}</p>
          )}

          <form>
            <dl className="bg-white flex-col rounded-b-lg mt-4">
              <div className="pt-4 pl-4">
                <p className="font-bold">
                  {renderMemberName()}
                  {renderMemberMrn()}
                </p>
              </div>

              <hr className="w-full" style={{ borderTopWidth: '2px' }} />

              <div className="p-4">
                <Filters
                  label="Preference"
                  isMulti={false}
                  data={preferences}
                  value={preference}
                  stateChanger={setPreference}
                />
              </div>
              {preference.value === 'pickup' && (
                <div>
                  <hr className="w-full" style={{ borderTopWidth: '2px' }} />
                  <div className="p-4">
                    <Filters
                      label="Preferred Pickup Facility"
                      isMulti={false}
                      data={locations}
                      value={pickupFacility}
                      placeholder={pickupFacility ? null : 'Search'}
                      stateChanger={(updatedState) => {
                        setPickupFacility(updatedState);
                      }}
                    />
                  </div>
                </div>
              )}
            </dl>
          </form>

          {/* BUTTONS */}
          <div className="flex mt-4">
            <button
              className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
              onClick={props.onCancel}
              type="button"
            >
              Back
            </button>

            <button
              className="btn btn--rounded btn--primary flex-1 ml-2"
              type="button"
              onClick={() => {
                props.onSave(props?.data?.id, preference, pickupFacility);
              }}
            >
              Continue
            </button>
          </div>
        </SmallModal>
      )}
    </>
  );
}

UdsSupplyEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  errorMessage: PropTypes.string,
};

UdsSupplyEditModal.defaultProps = {
  errorMessage: null,
};

export default UdsSupplyEditModal;
