import React, { useMemo } from 'react';
import MembersList from '../../../documentation/MembersList';
import { GroupAppointmentCamelCasePropTypes } from '../shared/types';
import { ATTENDED_STATUSES } from '../shared/constants';

function PanelSummaryTab({ appointment }) {
  const { scheduledMembers, absentMembers } = useMemo(() => {
    const mappedMembers = appointment.members?.map(
      ({ id, firstName, lastName, mrn, dateOfBirth, memberSummaries, attendance }) => ({
        id,
        firstName,
        lastName,
        mrn,
        dob: dateOfBirth,
        memberSummaries,
        attendance,
        groupInformation: {
          providerName: appointment.providers,
          counselorName: appointment.counselorName,
          officeManagerName: appointment.officeManagerName,
          modality: appointment.modality,
          clinicalModel: appointment.clinicalModel,
          location: appointment.locations,
          dateTime: appointment.scheduledAt,
          groupType: appointment.type,
          id: appointment.id,
        },
      })
    );

    const isMemberScheduled = (member) => ATTENDED_STATUSES.includes(member.attendance.status);

    return {
      scheduledMembers: mappedMembers.filter((member) => isMemberScheduled(member)),
      absentMembers: mappedMembers.filter((member) => !isMemberScheduled(member)),
    };
  }, [appointment]);

  return (
    <>
      <MembersList members={scheduledMembers} />
      {appointment.absentMembersCount > 0 && (
        <>
          <span className="font-semibold font-body text-lg mb-2">
            Rescheduled and No-Shows ({appointment.absentMembersCount})
          </span>
          <div className="opacity-30">
            <MembersList members={absentMembers} />
          </div>
        </>
      )}
    </>
  );
}

PanelSummaryTab.propTypes = {
  appointment: GroupAppointmentCamelCasePropTypes.isRequired,
};

export default PanelSummaryTab;
