import { useEffect, useState } from 'react';

// PACKAGES
import axios from 'axios';
import moment from 'moment/moment';

import { getTimeZone } from '../../../helpers/TimezoneHelper';

const taskStatusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'FLAGGED', label: 'Flagged' },
  { value: 'QUESTION', label: 'Question' },
  { value: 'REASSIGNED', label: 'Reassigned' },
  { value: 'OVERDUE', label: 'Overdue' },
];

const dayOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const dateOptions = [
  { value: '*', label: 'All Time' },
  { value: 'this_week', label: 'This Week' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'year_to_date', label: 'Year to Date' },
];

const dueDateOptions = [...dateOptions, { value: 'overdue', label: 'Overdue' }];

const taskPriorityOptions = [
  { value: 1, label: 'High' },
  { value: 2, label: 'Normal' },
];

const useDailyTaskFilters = (featureFlags) => {
  const { taskPriorityFlag } = featureFlags || {};

  const [managers, setManagerFilters] = useState([]);
  const [staff, setStaffFilters] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [locations, setLocationFilters] = useState([]);
  const [states, setStateFilters] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);

  const fetchSupervisorFilters = async () => {
    try {
      const { data: response } = await axios.get('/users/supervisor_filters');
      setManagerFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStaffFilters = async () => {
    try {
      const { data: response } = await axios.get('/users/staff_filters');
      if (Array.isArray(response)) {
        response?.unshift({ value: 'unassigned', label: 'Unassigned' });
        setStaffFilters(response);
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchTaskTypes = async () => {
    try {
      const { data: response } = await axios.get('/staff/daily_tasks/fetch_task_types');
      setTaskTypes(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchLocations = async () => {
    try {
      const { data: response } = await axios.get('/locations/fetch_filters');
      setLocationFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStates = async () => {
    try {
      const { data: response } = await axios.get('/staff/states/filters_only_with_locations');
      setStateFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchTimeOptions = () => {
    const currentTime = moment();
    const timezoneLabel = getTimeZone(currentTime);

    const hours = [];

    for (let hour = 0; hour < 24; hour += 1) {
      hours.push({
        value: `${moment({ hour, minute: 0 }).format('HH:mm')} ${moment({ hour, minute: 30 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 0 }).format('h:mm A')} ${timezoneLabel}`,
      });

      hours.push({
        value: `${moment({ hour, minute: 30 }).format('HH:mm')} ${moment({ hour: hour + 1, minute: 0 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 30 }).format('h:mm A')} ${timezoneLabel}`,
      });
    }

    setTimeOptions(hours);
  };

  const fetchTaskStatusOptions = () => {
    if (!taskPriorityFlag) {
      setTaskStatuses(taskStatusOptions);
      return;
    }

    taskStatusOptions.push({ value: 'HIGH_PRIORITY', label: 'High Priority' });
    setTaskStatuses(taskStatusOptions);
  };

  useEffect(() => {
    fetchSupervisorFilters();
    fetchStaffFilters();
    fetchTaskTypes();
    fetchLocations();
    fetchStates();
    fetchTimeOptions();
    fetchTaskStatusOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    taskStatuses,
    taskTypes,
    dayOptions,
    staff,
    locations,
    dueDateOptions,
    dateOptions,
    states,
    timeOptions,
    managers,
    taskPriorityOptions,
  };
};

export default useDailyTaskFilters;
