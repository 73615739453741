import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputLabel from './InputLabel';
import InputError from './InputError';

// eslint-disable-next-line react/display-name
function InputWrapper({
  label,
  name,
  required,
  error,
  isVertical,
  children,
  wrapperClassNames,
  isOverflowAuto,
  testId,
}) {
  return (
    <div
      data-testid={testId ? `input-wrapper-${testId}` : 'input-wrapper'}
      className={classNames('flex gap-2', {
        'flex-col': isVertical,
        [wrapperClassNames]: !!wrapperClassNames,
      })}
    >
      <InputLabel label={label} htmlFor={name} required={required} />
      <div
        className={classNames('flex-1', {
          'overflow-x-auto': isOverflowAuto,
        })}
      >
        {children}
        <InputError error={error} />
      </div>
    </div>
  );
}

InputWrapper.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  isVertical: PropTypes.bool,
  children: PropTypes.node.isRequired,
  wrapperClassNames: PropTypes.string,
  isOverflowAuto: PropTypes.bool,
  testId: PropTypes.string,
};

InputWrapper.defaultProps = {
  label: null,
  name: null,
  required: false,
  error: null,
  isVertical: true,
  wrapperClassNames: '',
  isOverflowAuto: false,
  testId: null,
};

export default InputWrapper;
