import React from 'react';
import PropTypes from 'prop-types';
import { PiPencilSimpleBold } from 'react-icons/pi';
import { TbTrash } from 'react-icons/tb';
import GeneralCell from '../../../GeneralCell';
import Button from '../../../shared/Button';
import StickyColumnCell from '../../../shared/StickyColumnCell';

function StaffMemberFacilitiesTableRow({ row, onEditButtonClick, onDeleteButtonClick }) {
  const { id, staffMember, locations, appointmentTypes } = row;

  return (
    <>
      <GeneralCell data={staffMember.fullName} containerClasses="py-5" />
      <GeneralCell data={locations.map((location) => location.name).join(', ')} />
      <GeneralCell data={appointmentTypes.join(', ')} />
      <StickyColumnCell containerClasses="flex gap-2 p-4">
        <Button onClick={() => onEditButtonClick(id)} testID="edit-button" isRounded>
          <PiPencilSimpleBold className="w-6 h-6 text-teal-800" />
        </Button>
        <Button onClick={() => onDeleteButtonClick(id)} testID="delete-button" isWarningOutline isRounded>
          <TbTrash className="w-6 h-6 text-error-700" />
        </Button>
      </StickyColumnCell>
    </>
  );
}

StaffMemberFacilitiesTableRow.propTypes = {
  row: {
    id: PropTypes.number.isRequired,
    staffMember: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.string).isRequired,
    appointmentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

export default StaffMemberFacilitiesTableRow;
