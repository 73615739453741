// eslint-disable-next-line import/no-cycle
import { getCurrentOffsets } from './TimezoneHelper';

export const DEFAULT_TIMEZONE = 'Us/Eastern';

export const SUPPORTED_TIMEZONES = [
  {
    value: 'Us/Eastern',
    abbrev: 'ET',
  },
  {
    value: 'Us/Central',
    abbrev: 'CT',
  },
  {
    value: 'Us/Mountain',
    abbrev: 'MT',
  },
  {
    value: 'Us/Arizona',
    abbrev: 'MT',
  },
  {
    value: 'Us/Pacific',
    abbrev: 'PT',
  },
  {
    value: 'Us/Hawaii',
    abbrev: 'HT',
  },
];
export const SUPPORTED_TIMEZONES_WITH_OFFSETS = getCurrentOffsets(SUPPORTED_TIMEZONES);

export const SUPPORTED_TIMEZONE_ABBREVS_MAP = {
  EST: 'ET',
  EDT: 'ET',
  ET: 'ET',
  CST: 'CT',
  CDT: 'CT',
  CT: 'CT',
  MST: 'MT',
  MDT: 'MT',
  MT: 'MT',
  PST: 'PT',
  PDT: 'PT',
  PT: 'PT',
  HST: 'HT',
  HDT: 'HT',
  HT: 'HT',
};

// external library requires us to convert
// [{ values: 'Us/Arizona' }]
// into
// { 'US/Arizona':  'US/Arizona' }
export const TIMEZONES = SUPPORTED_TIMEZONES_WITH_OFFSETS.map(({ value }) => {
  const [prefix, ...rest] = value.split('/');
  return [prefix.toUpperCase(), ...rest].join('/');
}).reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});

export const CLINICAL_MODEL_LABELS = {
  COUNSELOR_MAKEUP_GROUP: 'Counselor Makeup Group',
  PROVIDER_MAKEUP_GROUP: 'Provider Makeup Group',
  PROVIDER_ONLY_MAKEUP_GROUP: 'Provider-Only Makeup Group',
  RX_ASSISTANCE_GROUP: 'Rx Assistance Group',
  STANDARD_CARE_MODEL: 'Standard Care Model',
  STEPDOWN: 'Stepdown',
  WEEKLY_PROVIDER_GROUP: 'Weekly Provider Group',
};

export const SESSION_STORAGE_KEYS = {
  SHOW_APPOINTMENT_CANCELLED_TOAST: 'showAppointmentCancelledToast',
};
