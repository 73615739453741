import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import { HiSwitchHorizontal, HiChevronDown, HiChevronUp } from 'react-icons/hi';

// SERVICES
import { formatDateTime } from './DateFormatHelper';

function GroupCell(props) {
  const [officeManager, setOfficeManager] = useState('');
  const [groupType, setGroupType] = useState('');
  const [modality, setModality] = useState('');
  const [counselor, setCounselor] = useState('');
  const [provider, setProvider] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [location, setLocation] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [isEmpanelment, setIsEmpanelment] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const testID = `groupCell-${props.testID}`;

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  useMemo(() => {
    setCounselor(props.counselor);
    setProvider(props.provider);
    setDateTime(props.dateTime);
    setLocation(props.location);
    setModality(props.modality);
    setGroupType(props.groupType);
    setOfficeManager(props.officeManager);

    const isBlankStaff = isBlank(props.provider) || isBlank(props.counselor);
    setIsEmpty(isBlankStaff);

    setIsEmpanelment(props.isEmpanelment);
  }, [
    props.counselor,
    props.provider,
    props.dateTime,
    props.isEmpanelment,
    props.location,
    props.modality,
    props.groupType,
    props.officeManager,
  ]);

  if (isEmpty) {
    if (isEmpanelment) {
      return (
        <td className="p-6">
          <p className="text-error-700 font-semibold">Not Empaneled</p>
        </td>
      );
    }
    return <td />;
  }

  return (
    <td data-testid={testID} className="p-6">
      {/* MAKEUP GROUP TAG */}
      {props.isMakeupGroup && (
        <div data-testid={`makeUpIcon-${testID}`} className="makeup-group-tag">
          <HiSwitchHorizontal size={20} className="makeup-group-icon" />
          Make-Up Group
        </div>
      )}

      {/* Show Details */}
      {/* EPIC NOTE: OM EPIC FEATURE FLAG */}
      {props.isExpandable && (
        <>
          {!showDetails && (
            <div className="pb-2">
              <button
                data-testid={`showDetailsBtn-${testID}`}
                className="btn--details btn--show-details"
                onClick={() => setShowDetails(!showDetails)}
                type="button"
              >
                Show More Details
                <HiChevronDown />
              </button>
            </div>
          )}
          {/* Hide Details */}
          {showDetails && (
            <div className="pb-2">
              <button
                data-testid={`hideDetailsBtn-${testID}`}
                className="btn--details btn--show-details"
                onClick={() => setShowDetails(!showDetails)}
                type="button"
              >
                Hide Details
                <HiChevronUp />
              </button>
            </div>
          )}
        </>
      )}

      {/* Display based on if Modality and Phase available */}
      {/* EPIC NOTE: OM EPIC FEATURE FLAG */}
      {props.isExpandable && (
        <>
          {showDetails && (
            <div>
              <p data-testid={`modality-${testID}`} className="m-0 mb-1">
                <span className="font-semibold">Modality: </span>
                {modality}
              </p>

              {props.secondaryGroupTypeFeatureFlag ? (
                <>
                  <p data-testid={`groupType-${testID}`} className="m-0 mb-1">
                    <span className="font-semibold">Clinical Model: </span>
                    {groupType}
                  </p>
                  <p data-testid={`groupType-${testID}`} className="m-0 mb-1">
                    <span className="font-semibold">Other Information: </span>
                    {props.secondaryGroupType?.join(', ') || 'N/A'}
                  </p>
                </>
              ) : (
                <p data-testid={`groupType-${testID}`} className="m-0 mb-1">
                  <span className="font-semibold">Group Type: </span>
                  {groupType}
                </p>
              )}

              <p data-testid={`officeManager-${testID}`} className="m-0 mb-1">
                <span className="font-semibold">Office Manager: </span>
                {officeManager}
              </p>
            </div>
          )}
        </>
      )}

      <p data-testid={`counselor-${testID}`} className="m-0 mb-1">
        <span className="font-semibold">Counselor: </span>
        {counselor}
      </p>
      <p data-testid={`provider-${testID}`} className="m-0 mb-1">
        <span className="font-semibold">Provider: </span>
        {provider}
      </p>
      <p data-testid={`locationDateTime-${testID}`} className="m-0 mb-1">
        <time>{formatDateTime(dateTime)} </time>
        {location}
      </p>
    </td>
  );
}

GroupCell.propTypes = {
  testID: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  isExpandable: PropTypes.bool,

  counselor: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  isEmpanelment: PropTypes.bool,
  location: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  isMakeupGroup: PropTypes.bool,
  modality: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  officeManager: PropTypes.string.isRequired,
  secondaryGroupType: PropTypes.string,
};

GroupCell.defaultProps = {
  isEmpanelment: false,
  isMakeupGroup: false,
  isExpandable: true,
  secondaryGroupType: '',
};

export default GroupCell;
