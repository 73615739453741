/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RenderHTML from '../shared/RenderHTML';

// COMPONENTS
import ExpandButton from '../shared/ExpandButton';

function TaskTableCell(props) {
  const [showMore, setShowMore] = useState(false);
  const [customClass, setCustomClass] = useState('');

  function toggleShowMore() {
    setShowMore((value) => !value);
    if (showMore) {
      setCustomClass('');

      // TRACK show details
      window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'task-detail-open', props?.type);
    }
  }

  function renderDetails() {
    if (!showMore) {
      return <> </>;
    }
    return (
      <p className="text-sm mt-1" style={{ whiteSpace: 'pre-line', maxWidth: '230px' }}>
        <RenderHTML html={props?.description} allowedAttributes={['target']} />
      </p>
    );
  }

  return (
    <td>
      <div className={`flex mr-5 my-4 ${customClass}`}>
        <div>
          <div className="font-extralight">
            <p style={{ whiteSpace: 'pre-line', maxWidth: '230px' }}>{props?.type}</p>
          </div>

          {!props.hideTaskDetails && (
            <>
              <ExpandButton text="SHOW TASK DETAILS" onShowMore={toggleShowMore} />
              {renderDetails()}
            </>
          )}
        </div>
      </div>
    </td>
  );
}

TaskTableCell.propTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  hideTaskDetails: PropTypes.bool,
};

TaskTableCell.defaultProps = {
  description: '',
  hideTaskDetails: false,
};

export default TaskTableCell;
