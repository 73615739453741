import React from 'react';
import PropTypes from 'prop-types';
import classNamesPackage from 'classnames';

function NullishPlaceholder({ children, placeholder, classNames }) {
  return children ?? <span className={classNamesPackage('italic', classNames)}>{placeholder}</span>;
}

NullishPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  classNames: PropTypes.string,
};

NullishPlaceholder.defaultProps = {
  placeholder: 'None provided',
  classNames: undefined,
};

export default NullishPlaceholder;
