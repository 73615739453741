import React, { forwardRef } from 'react';
import { SmartPhrasesPropType } from '../documentation/helpers/types';

const RichtextInputToolbar = forwardRef(({ smartPhrases }, ref) => (
  <div ref={ref} className="custom-toolbar">
    <span className="ql-formats">
      <button type="button" className="ql-bold" aria-label="bold" />
      <button type="button" className="ql-italic" aria-label="italic" />
      <button type="button" className="ql-underline" aria-label="underline" />
      <select className="ql-color" aria-label="color" />
      <select className="ql-background" aria-label="background" />
    </span>
    <span className="ql-formats">
      <button type="button" className="ql-link" aria-label="link" />
      <button type="button" className="ql-image" aria-label="image" />
      <button type="button" className="ql-list" value="bullet" aria-label="list-bullet" />
      <button type="button" className="ql-list" value="ordered" aria-label="list-ordered" />
    </span>
    <span className="ql-formats">
      <select className="ql-addSmartPhrase">
        {smartPhrases?.map((phrase) => (
          <option key={phrase.id} value={phrase.phrase}>
            {phrase.name}
          </option>
        ))}
      </select>
    </span>
  </div>
));

RichtextInputToolbar.displayName = 'RichtextInputToolbar';

RichtextInputToolbar.propTypes = {
  smartPhrases: SmartPhrasesPropType,
};

RichtextInputToolbar.defaultProps = {
  smartPhrases: null,
};

export default RichtextInputToolbar;
