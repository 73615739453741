import React from 'react';
import PropTypes from 'prop-types';

function PageTableContainer(props) {
  return (
    <>
      {props.show && (
        <div
          className={`rounded-lg border border-gray-400 shadow-md overflow-auto ${props.classes}`}
          style={props.style}
        >
          <table
            className="bg-white rounded-lg overflow-auto text-black w-full table-auto whitespace-nowrap"
            style={props.tableStyle}
          >
            {props.children}
          </table>
        </div>
      )}
    </>
  );
}

PageTableContainer.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  tableStyle: PropTypes.object,
  show: PropTypes.bool,
};

PageTableContainer.defaultProps = {
  classes: '',
  children: null,
  style: {},
  tableStyle: {},
  show: true,
};

export default PageTableContainer;
