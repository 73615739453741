import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// COMPONENTS
import Button from '../shared/Button';
import PageHeader from '../shared/PageHeader';

import TaskTable from './TaskTable';
import ProgressToday from './ProgressToday';

// HOOKS
import useJobLastTimestamp from '../shared/hooks/use-job-last-timestamp';
import useResponse from '../shared/hooks/use-response';

function DailyTaskDashboard(props) {
  const { taskPriorityFlag } = props.featureFlags || {};
  const { lastJobTimestamp } = useJobLastTimestamp('/staff/daily_tasks/last_job_timestamp');

  const [showFilters, setShowFilters] = useState(false);
  function toggleShowMore() {
    setShowFilters((value) => !value);
  }

  function renderArrowIcon() {
    if (showFilters) {
      return <KeyboardArrowUp className="ml-2 mb-1" />;
    }
    return <KeyboardArrowDown className="ml-2 mb-1" />;
  }

  const clickedShowMore = () => {
    toggleShowMore();
  };

  // ***************
  // PROGRESS TODAY
  // ***************

  const { fetchResponse } = useResponse();

  const [openTasks, setOpenTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  function updateProgressContainer() {
    if (!taskPriorityFlag) {
      return;
    }

    fetchResponse('/staff/daily_tasks/fetch_open_high_priority_tasks_today').then((data) => {
      setOpenTasks(data);
    });

    fetchResponse('/staff/daily_tasks/fetch_completed_tasks_today').then((data) => {
      setCompletedTasks(data);
    });
  }
  useEffect(() => {
    updateProgressContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-10">
      <PageHeader text="Action List" subtext={lastJobTimestamp}>
        <Button isPrimary isFloatRight onClick={clickedShowMore}>
          Apply Filters
          {renderArrowIcon()}
        </Button>
      </PageHeader>

      {taskPriorityFlag && <ProgressToday openTasks={openTasks} completedTasks={completedTasks} />}

      <TaskTable
        authenticityToken={props.authenticityToken}
        showFilterExpandView={showFilters}
        currentUser={props.currentUser}
        currentStatus={props.currentStatus}
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        howToResolveUIFeatureFlag={props.howToResolveUIFeatureFlag}
        onSendUpdateToProgressContainer={() => updateProgressContainer()}
        featureFlags={props.featureFlags}
      />
    </div>
  );
}

DailyTaskDashboard.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentStatus: PropTypes.object.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  howToResolveUIFeatureFlag: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

export default DailyTaskDashboard;
