import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import CarePlanHeader from './CarePlanHeader';
import ActiveGoalsView from './ActiveGoalsView';
import GoalHistoryView from './GoalHistoryView';

function MemberGoalsView({ memberId }) {
  const [activeGoals, setActiveGoals] = useState({});
  const [historicalGoals, setHistoricalGoals] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const testId = 'memberGoalsView';

  useEffect(() => {
    axios.get(`/staff/members/${memberId}/goals`).then((response) => {
      setActiveGoals(response.data?.activeGoals);
      setHistoricalGoals(response.data?.historicalGoals);
      setIsLoaded(true);
    });
  }, [memberId]);

  const refreshGoals = () => {
    axios.get(`/staff/members/${memberId}/goals`).then((response) => {
      setActiveGoals(response.data?.activeGoals);
      setHistoricalGoals(response.data?.historicalGoals);
      setIsLoaded(true);
    });
  };

  return (
    <div data-testid={testId}>
      {isLoaded && (
        <div className="goals-section-container" data-testid={`goals-container-${testId}`}>
          <CarePlanHeader testId={testId} />
          <ActiveGoalsView
            memberId={memberId}
            longTermGoal={activeGoals?.longTermGoal}
            shortTermGoal={activeGoals?.shortTermGoal}
            updateCallback={refreshGoals}
            testId={testId}
          />
          <GoalHistoryView memberId={memberId} goals={historicalGoals} updateCallback={refreshGoals} testId={testId} />
        </div>
      )}
    </div>
  );
}

MemberGoalsView.propTypes = {
  memberId: PropTypes.number.isRequired,
};

export default MemberGoalsView;
