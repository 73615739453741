import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../shared/Header';
import OpenCloseIcon from '../shared/OpenCloseIcon';

function MemberSummaryHeaderNameIcon(props) {
  const flexClasses = 'flex mb-2';

  const { id, name } = props.memberInfo || {};

  return (
    <div className={flexClasses}>
      <OpenCloseIcon isOpen={props.isOpen} setOpenStatus={props.setOpenStatus} />
      <Header text={name} link={`/staff/members/${id}`} />
    </div>
  );
}

MemberSummaryHeaderNameIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  memberInfo: PropTypes.object.isRequired,

  setOpenStatus: PropTypes.func.isRequired,
};

export default MemberSummaryHeaderNameIcon;
