import React from 'react';
import PropTypes from 'prop-types';
import GroupAppointments from './GroupAppointments';
import IndividualAppointments from './IndividualAppointments';

function ScheduledTab({ filters }) {
  return (
    <div className="mt-5 gap-10" data-testid="scheduled-tab">
      <GroupAppointments filters={filters} />
      <IndividualAppointments filters={filters} />
    </div>
  );
}

ScheduledTab.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ScheduledTab;
