/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import LongTermGoalIcon from './LongTermGoalIcon';
import InlineGoalCreateForm from './InlineGoalCreateForm';
import InlineGoalEditForm from './InlineGoalEditForm';

function InlineLongTermGoalForm({ memberId, updateCallback, goal, testId }) {
  const goalNotEmpty = () => !!goal && 'title' in goal && !!goal?.title;

  const formProps = {
    goal,
    goalLabel: 'Long-term Goal',
    warning: 'Please enter a new long-term goal',
    memberId,
    updateCallback,
    testId: `form-${testId}`,
  };

  const form = () => (goalNotEmpty() ? <InlineGoalEditForm {...formProps} /> : <InlineGoalCreateForm {...formProps} />);

  return (
    <div className="short-term-goal-container goal-container" data-testid={testId}>
      <h4 className="goal-type" data-testid={`type-${testId}`}>
        LONG-TERM GOAL
      </h4>
      <div className="goal-row" data-testid={`row-${testId}`}>
        <LongTermGoalIcon testId={`icon-${testId}`} />
        {form()}
      </div>
    </div>
  );
}

InlineLongTermGoalForm.propTypes = {
  memberId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  goal: PropTypes.object,
  testId: PropTypes.string.isRequired,
};

InlineLongTermGoalForm.defaultProps = {
  goal: {},
};

export default InlineLongTermGoalForm;
