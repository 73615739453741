import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/api/pagination/
import Pagination from '@mui/material/Pagination';

function CustomPagination(props) {
  const handleChangePage = (event, newPage) => {
    props.onPageChange(event, newPage);
  };

  return (
    <>
      {props.show && (
        <Pagination
          className="pagination"
          shape="rounded"
          sx={{
            '.MuiButtonBase-root': {
              height: '60px',
              color: '#237786',
              fontSize: '1.125rem',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              paddingTop: '26px',
            },
            '.MuiButtonBase-root.Mui-selected ': {
              backgroundColor: 'inherit',
              borderTop: 'solid',
              borderRadius: 0,
              paddingTop: '23px !important',
            },
            '.MuiButtonBase-root.Mui-selected:hover': {
              backgroundColor: 'inherit',
            },
            '.MuiPaginationItem-ellipsis': {
              paddingTop: '17px',
            },
            '.MuiButtonBase-root:hover': {
              backgroundColor: 'inherit',
              borderTop: 'solid',
              borderRadius: 0,
            },
            '.MuiButtonBase-root:focus': {
              outline: 'none',
              backgroundColor: 'inherit',
            },
            ...(props.nextLabel && {
              '& .MuiPagination-ul li:last-child button::before': {
                content: '"Next"',
                marginRight: '8px',
              },
            }),
            ...(props.previousLabel && {
              '& .MuiPagination-ul li:first-child button::after': {
                content: '"Previous"',
                marginLeft: '8px',
              },
            }),
            ...props.additionalStyles,
          }}
          page={props.page}
          count={props.totalPages}
          onChange={handleChangePage}
        />
      )}
    </>
  );
}

CustomPagination.propTypes = {
  show: PropTypes.bool,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  additionalStyles: PropTypes.object,
  nextLabel: PropTypes.bool,
  previousLabel: PropTypes.bool,
};

CustomPagination.defaultProps = {
  show: true,
  page: 1,
  totalPages: 1,
  onPageChange: () => {},
  additionalStyles: {},
  nextLabel: true,
  previousLabel: false,
};

export default CustomPagination;
