import React from 'react';
import PropTypes from 'prop-types';

function PageHeader(props) {
  const withChildButton = props?.children ? 'flex' : '';
  const withBottomLineClass = props?.withBottomLine ? 'border-b-2 border-gray-300' : '';

  return (
    <div className={`mb-4 pb-2 ${withBottomLineClass} ${withChildButton}`} id="pageHeader">
      <div className="flex-1">
        <h2 className="font-body font-semibold mt-2 mb-1">
          {props.breadcrumbText && (
            <span>
              <a className=" hover:no-underline cursor-pointer" href={`/staff/${props.breadcrumbLink}`}>
                {props.breadcrumbText}
              </a>
              <span className="text-gray-700 text-lg relative px-2 -inset-y-1"> ❯</span>
            </span>
          )}
          {props.text}
        </h2>

        {props.subtext && <span className="text-xs font-light text-gray-700">{props.subtext}</span>}
      </div>

      {props.children}
    </div>
  );
}

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  withBottomLine: PropTypes.bool,
  children: PropTypes.node,
  breadcrumbText: PropTypes.string,
  breadcrumbLink: PropTypes.string,
};

PageHeader.defaultProps = {
  subtext: null,
  withBottomLine: true,
  children: null,
  breadcrumbText: null,
  breadcrumbLink: null,
};

export default PageHeader;
