import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../shared/Header';
import Contents from '../shared/Contents';
import TextArea from '../../shared/TextArea';

// HELPERS
import { labelStyle } from '../shared/styles';

function MemberPickupContainer(props) {
  // COUNTS
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const cachedNotes = window.localStorage.getItem(`${props.member}-${props.id}-notes`);
    const parsedNotes = JSON.parse(cachedNotes);
    setNotes(parsedNotes);
  }, [props.id, props.member]);

  return (
    <div
      className="p-3 mt-2 mb-6"
      style={{ backgroundColor: '#F5F8FA', borderRadius: '8px', border: 'solid #D1E0EA 1px' }}
    >
      <Header text={props.member} withBottomMargin withNoUnderline style={{ fontSize: '18px' }} />

      <Contents
        cacheKey={`${props.member}-${props.id}`}
        memberId={props.id}
        pickupQuantities={props.pickupQuantities}
        setPickupQuantities={props.setPickupQuantities}
      />

      <TextArea
        label="Notes"
        subtext="This note will only be seen in the Digital Platform. All clinical notes must go into eCW."
        value={notes}
        onChange={(value) => {
          const newCounts = props.pickupQuantities;
          newCounts[props.id].notes = value;
          props.setPickupQuantities(newCounts);
          setNotes(value);
          window.localStorage.setItem(`${props.member}-${props.id}-notes`, JSON.stringify(value));
        }}
        labelStyle={labelStyle}
      />
    </div>
  );
}

MemberPickupContainer.propTypes = {
  member: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  pickupQuantities: PropTypes.object.isRequired,
  setPickupQuantities: PropTypes.func.isRequired,
};

export default MemberPickupContainer;
