import React from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { parsePhoneString } from '../../helpers/AssortedHelpers';
import { formatDayOfWeek, formatTimeLocalized } from '../DateFormatHelper';

// COMPONENTS
import Header from '../shared/Header';
import ListItem from '../shared/ListItem';
import DetailsDiv from '../shared/DetailsDiv';
import LocationTag from '../shared_dashboard/LocationTag';

function ExpandViewMemberDetails({ data, memberGroup, memberEmpanelment, secondaryGroupTypeFeatureFlag }) {
  const phone = parsePhoneString(data?.member_phone);

  return (
    <div className="m-2">
      {/* HEADER - MEMBER NAME */}
      <Header text={data?.member_name} style={{ color: '#217886', fontWeight: 500 }} class={null} />

      {/* MRN and PHONE ROW */}
      <div className="flex">
        <div className="flex-1">
          <DetailsDiv header="MRN" subText={data?.member_mrn} />
        </div>

        <div className="flex-1">
          <DetailsDiv header="PHONE NUMBER" subText={phone} />
        </div>
      </div>

      {/* CURRENT GROUP */}
      <DetailsDiv
        header="NEXT SCHEDULED GROUP"
        customSubClass="flex"
        subText={
          memberGroup?.date_time
            ? `${formatDayOfWeek(memberGroup?.date_time)}, ${formatTimeLocalized(memberGroup?.date_time)}`
            : 'No Upcoming Group'
        }
      >
        {memberGroup?.location_name && <LocationTag preloadedData={memberGroup?.location_name} />}
      </DetailsDiv>

      {/* EMPANELMENT */}
      <DetailsDiv header="EMPANELMENT" customStyle={{ marginTop: '35px' }}>
        {memberEmpanelment?.id ? (
          <>
            <ListItem label="Modality" text={memberEmpanelment?.modality} />
            {secondaryGroupTypeFeatureFlag ? (
              <>
                <ListItem label="Clinical Model" text={memberEmpanelment?.group_type} />
                {memberEmpanelment?.secondary_group_types && (
                  <ListItem label="Other Information" text={memberEmpanelment?.secondary_group_types} />
                )}
              </>
            ) : (
              <ListItem label="Group Type" text={memberEmpanelment?.group_type} />
            )}
            <ListItem label="Office Manager" text={memberEmpanelment?.office_manager} />
            <ListItem label="Counselor" text={memberEmpanelment?.counselor} />
            <ListItem label="Provider" text={memberEmpanelment?.provider} />
            {memberEmpanelment?.date_time
              ? `${formatDayOfWeek(memberEmpanelment?.date_time)}, ${formatTimeLocalized(memberEmpanelment?.date_time)}`
              : 'No Upcoming Group'}
            {memberEmpanelment?.location_name && <LocationTag preloadedData={memberEmpanelment?.location_name} />}
          </>
        ) : (
          <>Not Empaneled</>
        )}
      </DetailsDiv>
    </div>
  );
}

ExpandViewMemberDetails.propTypes = {
  data: PropTypes.string.isRequired,
  memberGroup: PropTypes.object.isRequired,
  memberEmpanelment: PropTypes.object.isRequired,

  secondaryGroupTypeFeatureFlag: PropTypes.bool,
};

ExpandViewMemberDetails.defaultProps = {
  secondaryGroupTypeFeatureFlag: false,
};

export default ExpandViewMemberDetails;
