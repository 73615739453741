/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Bar from './Bar';

function MonthBar(props) {
  const fontClass = 'font-semibold text-xs flex-initial w-6';

  const { label, isPresent, count } = props?.data || {};

  return (
    <div className="flex my-2">
      {/* MONTH */}
      <div className={`${fontClass} mr-2`}>{label}</div>

      {/* BAR GRAPH */}
      <div className="flex-grow">
        <div className="flex">
          {isPresent?.map((isTrue) => (
            <Bar isFilled={isTrue} />
          ))}
        </div>
      </div>

      {/* STATS */}
      <div className={`${fontClass} text-right`}>{count}</div>
    </div>
  );
}

MonthBar.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MonthBar;
