import moment from 'moment-timezone';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_TIMEZONE, SUPPORTED_TIMEZONE_ABBREVS_MAP, SUPPORTED_TIMEZONES } from './constants';

function convertDate(dateTime) {
  return new Date(dateTime);
}

function getTimeZone(dateTime) {
  const date = convertDate(dateTime);
  const zone = date.toLocaleTimeString('en-us', { timeZoneName: 'shortGeneric' }).split(' ')[2];

  return zone;
}

function getSupportedTimeZone() {
  const currentTimeZone = moment.tz.guess();

  // comparing abbreviation instead of full name
  // because it's more inclusive
  // e.g. America/New_York and Us/Eastern both have the same abbreviation
  const currentTimeZoneAbbr = moment.tz(currentTimeZone).zoneAbbr();
  return (
    SUPPORTED_TIMEZONES.find((timezone) => timezone.abbrev === SUPPORTED_TIMEZONE_ABBREVS_MAP[currentTimeZoneAbbr])
      ?.value || DEFAULT_TIMEZONE
  );
}

function convertToUniversalAbbrev(abbrev) {
  return SUPPORTED_TIMEZONE_ABBREVS_MAP[abbrev] ?? abbrev;
}

function getUserTimeZoneAbbrev() {
  const currentTimeZone = getSupportedTimeZone();
  const momentObject = moment.tz(new Date(), currentTimeZone);

  return convertToUniversalAbbrev(momentObject.zoneAbbr());
}

function getSupportedTimeZoneObject(dateTime) {
  const timezone = getSupportedTimeZone();
  const momentObject = moment.tz(dateTime, timezone);

  return {
    value: timezone,

    // pass date time to account for daylight savings
    abbrev: convertToUniversalAbbrev(momentObject.zoneAbbr()),
    offset: momentObject.utcOffset() / -60,
  };
}

function convertToSupportedTimeZone(dateTime) {
  const supportedTimeZone = getSupportedTimeZoneObject(dateTime);
  const dateTimeWithOffset = moment(dateTime).tz(supportedTimeZone.value);

  return [dateTimeWithOffset, supportedTimeZone];
}

function getSupportedTimeZoneCity(dateTime) {
  const timeZone = getSupportedTimeZone(dateTime);
  return Intl.DateTimeFormat('en-US', { timeZone }).resolvedOptions().timeZone;
}

const getCurrentDateTimeByTimezone = (timezone) => moment().tz(timezone || getSupportedTimeZone());

function getCurrentOffsets(timezones) {
  return timezones.map((timezone) => {
    const timestamp = new Date() - 0;
    const offset = moment.tz.zone(timezone.value).utcOffset(timestamp) * -1;
    const offsetInHours = offset / 60;
    const label = `(GMT${offsetInHours > 0 ? '+' : ''}${offsetInHours}:00) ${timezone.value.split('/')[1]}`;

    return {
      ...timezone,
      offset: offsetInHours,
      label,
    };
  });
}

function formatWithTimezone(datetime) {
  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(datetime);
  return `${timeWithOffset.format('h:mm A')} ${supportedTimeZone.abbrev}`;
}

function formatWithDuration(startTime, duration) {
  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(startTime);
  const timeEnd = timeWithOffset.clone().add(duration, 'minutes');
  return `${timeWithOffset.format('h:mm A')} — ${timeEnd.format('h:mm A')} ${supportedTimeZone.abbrev}`;
}

const convertToSpecificFormat = (date, format) => {
  const [dateWithOffset, dateTimeZone] = convertToSupportedTimeZone(date);
  return `${dateWithOffset.format(format || 'M/D/YYYY, h:mm A')} ${dateTimeZone.abbrev}`;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getTimeZone,
  getSupportedTimeZone,
  getSupportedTimeZoneCity,
  getSupportedTimeZoneObject,
  getCurrentOffsets,
  getCurrentDateTimeByTimezone,
  convertToSupportedTimeZone,
  getUserTimeZoneAbbrev,
  formatWithTimezone,
  formatWithDuration,
  convertToSpecificFormat,
};
