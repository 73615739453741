/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import { sortEncounters, filterEncounters, addUniqueIds } from './helpers';

const useEncounters = (appointmentOccurrences, filters) => {
  const [filteredData, setFilteredData] = useState([]);
  const [sortedBy, setSortedBy] = useState({ id: 'scheduledAt', desc: true });
  const parsedAppointmentOccurrences = useMemo(
    () => addUniqueIds(convertObjKeysToCamelCase(appointmentOccurrences)),
    [appointmentOccurrences]
  );

  useEffect(() => {
    const filterData = filterEncounters(parsedAppointmentOccurrences, filters);
    const sortedData = sortEncounters(filterData, sortedBy.id, sortedBy.desc);

    setFilteredData(sortedData);
  }, [parsedAppointmentOccurrences, filters, sortedBy]);

  const handleSort = useCallback(
    (id) => {
      setSortedBy((prevSort) => ({ id, desc: prevSort && prevSort.id === id ? !prevSort.desc : true }));
    },
    [setSortedBy]
  );

  return {
    filteredData,
    parsedAppointmentOccurrences,
    handleSort,
    sortedBy,
  };
};

export default useEncounters;
