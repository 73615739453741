import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// IMPORTS
import { FormControl, TextField, InputAdornment, IconButton } from '@mui/material';

// ICONS
import { Search, Clear } from '@mui/icons-material';

const style = {
  '& label.Mui-focused': { display: 'none' },
  '& .MuiOutlinedInput-root': {
    borderColor: '#b3b3b3',

    '&.Mui-focused fieldset': {
      border: '2px solid #237786',
      outlineOffset: '1px',
      outline: '1px solid #237786',

      '& legend': {
        display: 'none',
      },
    },
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#b3b3b3',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#237786',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: ['Inter'].join(','),
  },
});
function SearchFilter(props) {
  const searchRef = useRef(null);
  const [searchText, setSearchText] = useState('');

  const handleClearInput = useCallback(
    (_ev) => {
      searchRef.current.value = '';

      setSearchText('');
      props.onChange();
    },
    [props]
  );

  useEffect(() => {
    setSearchText(props.value);
    if (props.value === null) {
      handleClearInput();
    }
  }, [props.value, handleClearInput]);

  const fetchSearchData = (ev) => {
    if (ev.key !== 'Enter' && ev.type !== 'click') {
      return;
    }

    const searchTextValue = ev.key === 'Enter' ? ev.target.value : searchRef.current.value;

    setSearchText(searchTextValue);
    props.onChange(searchTextValue);
  };

  return (
    <div className="react-inline" style={props.style}>
      <div className="filter-label">{props.label}</div>
      <ThemeProvider theme={theme}>
        <FormControl
          sx={{
            width: '100%',
            background: 'white',
            zIndex: 0,
          }}
        >
          <TextField
            id="member-search-input"
            value={searchRef?.current?.value || searchText}
            inputRef={searchRef}
            onKeyPress={fetchSearchData}
            onChange={(e) => setSearchText(e.target.value)}
            fullWidth
            label={props.placeholder}
            variant="outlined"
            size="small"
            color="info"
            sx={style}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchRef.current?.value && (
                    <IconButton id="member-search-clear-button" onClick={handleClearInput}>
                      <Clear />
                    </IconButton>
                  )}

                  <IconButton id="member-search-button" onClick={fetchSearchData}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

SearchFilter.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

SearchFilter.defaultProps = {
  placeholder: 'Search Members .. by Name or MRN',
  style: {},
};

export default SearchFilter;
