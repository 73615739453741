import React from 'react';
import PropTypes from 'prop-types';
import { MdClose as CloseIcon } from 'react-icons/md';

function SelectedPill({ label, onRemove }) {
  return (
    <div className="inline-flex items-center bg-gray-200 rounded-lg px-3 py-2 gap-1.5">
      <span className="text-xs text-gray-700 font-semibold">{label}</span>
      {onRemove && (
        <button type="button" onClick={onRemove} title="Remove from selected">
          <CloseIcon className="text-gray-700" data-testid="close-icon" />
        </button>
      )}
    </div>
  );
}

SelectedPill.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

SelectedPill.defaultProps = {
  onRemove: undefined,
};

export default SelectedPill;
