import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import OpenCloseIcon from '../shared/OpenCloseIcon';

function InformationHeader(props) {
  const labelClasses = 'flex-1 font-heading font-medium text-sm';

  return (
    <div className="flex py-2">
      <div className={labelClasses}>{props.label?.toUpperCase()}</div>

      {props.showOpenCloseIcon && <OpenCloseIcon isOpen={props.isOpen} setOpenStatus={props.setOpenStatus} />}

      {props.showEdit && (
        <div>
          <button className="text-teal-700 font-bold mr-4" onClick={props.onEditClick} type="button">
            EDIT
          </button>
        </div>
      )}
    </div>
  );
}

InformationHeader.propTypes = {
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,

  // OPTIONAL
  showOpenCloseIcon: PropTypes.bool,
  showEdit: PropTypes.bool,
  setOpenStatus: PropTypes.func,
  onEditClick: PropTypes.func,
};

InformationHeader.defaultProps = {
  showOpenCloseIcon: true,
  showEdit: false,
  setOpenStatus: () => {},
  onEditClick: () => {},
};

export default InformationHeader;
