import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InformationContainer from './InformationContainer';
import NumeratorDenominatorContainer from '../shared/NumeratorDenominatorContainer';

function OneMonthStatsView({ data }) {
  const flexClass = 'flex-1';

  const { counselor, uds, provider } = data || {};

  return (
    <InformationContainer style={{ marginLeft: '10px' }}>
      {/* TOP HEADER */}
      <div className="text-center font-semibold border-gray-300 border-b p-1 text-xs">
        February (this month to date)
      </div>

      {/* THREE CONTAINER */}
      <div className="flex">
        <div className={flexClass}>
          <NumeratorDenominatorContainer
            label="Counselor Visits"
            numerator={counselor?.numerator}
            denominator={counselor?.denominator}
            statusTag={counselor?.statusTag}
            noStatus={counselor?.noStatus}
          />
        </div>

        <div className={`${flexClass} border-gray-300 border-r border-l`}>
          <NumeratorDenominatorContainer
            label="UDS"
            numerator={uds?.numerator}
            denominator={uds?.denominator}
            statusTag={uds?.statusTag}
            noStatus={uds?.noStatus}
          />
        </div>

        <div className={flexClass}>
          <NumeratorDenominatorContainer
            label="Provider Visits"
            numerator={provider?.numerator}
            denominator={provider?.denominator}
            statusTag={provider?.statusTag}
            noStatus={provider?.noStatus}
          />
        </div>
      </div>
    </InformationContainer>
  );
}

OneMonthStatsView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OneMonthStatsView;
