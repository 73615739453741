import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultValues = {
  getMemberAttendance: () => {},
  getChangeHandler: () => {},
};

const AttendanceTrackerContext = createContext(defaultValues);

export function AttendanceTrackerController({
  getMemberAttendance,
  getChangeHandler,
  canUserManageAppointment,
  children,
}) {
  const memoizedValue = useMemo(
    () => ({ getMemberAttendance, getChangeHandler, canUserManageAppointment }),
    [getMemberAttendance, getChangeHandler, canUserManageAppointment]
  );

  return <AttendanceTrackerContext.Provider value={memoizedValue}>{children}</AttendanceTrackerContext.Provider>;
}

AttendanceTrackerController.propTypes = {
  getMemberAttendance: PropTypes.func.isRequired,
  getChangeHandler: PropTypes.func.isRequired,
  canUserManageAppointment: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const useAttendanceTrackerContext = (memberId) => {
  const { getMemberAttendance, getChangeHandler, canUserManageAppointment } = useContext(AttendanceTrackerContext);

  return {
    attendance: getMemberAttendance(memberId),
    getChangeHandler: (field) => getChangeHandler(memberId, field),
    canUserManageAppointment,
  };
};
