import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IoSettingsOutline } from 'react-icons/io5';
import { PiClipboardText } from 'react-icons/pi';
import { TbFileArrowLeft, TbTrash } from 'react-icons/tb';
import { AiOutlineEdit } from 'react-icons/ai';

function AnswerPill({ variant, date, isLabelVisible }) {
  const iconClassName = 'w-4 h-4';
  const variants = {
    defaultValue: {
      label: 'Default answer',
      className: 'bg-gray-200 text-gray-800',
      icon: <IoSettingsOutline data-testid="settings-outline-icon" className={iconClassName} />,
    },
    defaultMemberValue: {
      label: 'Chart data',
      className: 'bg-yellow-100 text-yellow-800',
      icon: <PiClipboardText data-testid="clipboard-text-icon" className={iconClassName} />,
    },
    isLastChartingNote: {
      label: `Last response from ${date}`,
      className: 'bg-positive-100 text-positive-800',
      icon: <TbFileArrowLeft data-testid="file-arrow-left-icon" className={iconClassName} />,
    },
    answerModified: {
      label: 'The answer to this question was modified',
      className: 'bg-purple-100 text-purple-700',
      icon: <AiOutlineEdit data-testid="outline-edit-icon" className={iconClassName} />,
    },
    isDeleted: {
      label: 'Answer deleted',
      className: 'bg-error-100 text-error-700',
      icon: <TbTrash data-testid="trash-icon" className={iconClassName} />,
    },
  };

  if (!variants[variant]) return null;

  return (
    <div
      data-testid="answer-pill"
      className={classNames('h-6 inline-flex p-1 rounded text-xs italic items-center gap-1', {
        [variants[variant].className]: variants[variant],
      })}
    >
      {variants[variant].icon}
      {isLabelVisible && variants[variant].label}
    </div>
  );
}

AnswerPill.propTypes = {
  variant: PropTypes.oneOf(['defaultValue', 'defaultMemberValue', 'isLastChartingNote', 'answerModified']).isRequired,
  date: PropTypes.string,
  isLabelVisible: PropTypes.bool,
};

AnswerPill.defaultProps = {
  date: '',
  isLabelVisible: true,
};

export default AnswerPill;
