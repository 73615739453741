import { useEffect, useState, useCallback } from 'react';
import { chunk } from 'lodash';
import useHttp from '../../shared/hooks/use-http';

// Chunking was introduced because Healthie is very slow when we try to fetch all charting notes at once
// (getting 100 charting notes takes over 30s). It caused RequestTimeoutException on our side.
const CHUNK_SIZE = 20;

const useChartingNote = (chartingNoteIds) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { sendRequest } = useHttp();
  const [requestResponses, setRequestResponses] = useState([]);

  useEffect(() => {
    const getChartingNotes = async () => {
      try {
        setIsLoading(true);
        const chunkedChartingNoteIds = chunk(chartingNoteIds, CHUNK_SIZE);

        const promises = chunkedChartingNoteIds.map((ids) =>
          sendRequest(`/staff/documentation/charting_notes`, {
            params: {
              charting_note_ids: ids,
            },
          })
        );

        const responses = await Promise.all(promises);
        setRequestResponses(responses.flatMap((response) => response?.data?.formAnswerGroups));
      } catch (e) {
        setError(e.parsedMessage ?? 'An error occurred while loading charting notes. \nTry again later.');
        window.Sentry?.captureException(e);
      }
      setIsLoading(false);
    };

    if (chartingNoteIds && chartingNoteIds.length > 0) {
      getChartingNotes();
    }
  }, [chartingNoteIds, sendRequest]);

  const clearError = useCallback(() => setError(null), []);

  return {
    chartingNotes: requestResponses,
    isLoading,
    error,
    clearError,
  };
};

export default useChartingNote;
