import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';

const useDocumentationTemplates = () => {
  const { isLoading, sendRequest, error } = useHttp();
  const [requestResponse, setRequestResponse] = useState({});

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await sendRequest('/staff/documentation/document_templates');
        const convertedResponse = convertObjKeysToCamelCase(response);

        setRequestResponse(convertedResponse);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchTemplates();
  }, [sendRequest]);

  return {
    ...requestResponse,
    templates: Array.isArray(requestResponse?.jsonData) ? requestResponse?.jsonData : [],
    isLoading,
    error,
  };
};

export default useDocumentationTemplates;
