import React from 'react';
import PropTypes from 'prop-types';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../helpers/DateFormatter';

function MemberAppointmentDetails({ title, appointmentType, scheduledAt, location, duration }) {
  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(scheduledAt);
  const timeEnd = timeWithOffset.clone().add(duration, 'minutes');
  const datetime = `${timeWithOffset.format(DEFAULT_DATE_FORMAT)}, ${timeWithOffset.format(DEFAULT_TIME_FORMAT)} — ${timeEnd.format(DEFAULT_TIME_FORMAT)} ${supportedTimeZone.abbrev}`;

  return (
    <>
      <p className="text-base font-semibold mb-2">{title}</p>
      <p data-testid="group-info" className="mb-0">
        {`${appointmentType}, `}
        {datetime}
        {location && <span className="mx-1 text-sm bg-gray-300 px-1.5 py-0.5 rounded text-gray-700">{location}</span>}
      </p>
    </>
  );
}

MemberAppointmentDetails.propTypes = {
  title: PropTypes.string.isRequired,
  appointmentType: PropTypes.string.isRequired,
  scheduledAt: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
};

export default MemberAppointmentDetails;
