import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const defaultValues = {
  selectedNotes: [],
  setSelectedNotes: () => {},
};

const SelectedNotesContext = createContext(defaultValues);

export function SelectedNotesController({ defaultSelectedNotes, children }) {
  const [selectedNotes, setSelectedNotes] = useState(defaultSelectedNotes);
  const memoizedValue = useMemo(() => ({ selectedNotes, setSelectedNotes }), [selectedNotes, setSelectedNotes]);

  return <SelectedNotesContext.Provider value={memoizedValue}>{children}</SelectedNotesContext.Provider>;
}

SelectedNotesController.propTypes = {
  defaultSelectedNotes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

SelectedNotesController.defaultProps = {
  defaultSelectedNotes: [],
};

export const useSelectedNotes = () => useContext(SelectedNotesContext);
