import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../shared/InputWrapper';
import Input from '../shared/Input';
import SelectInput from '../shared/SelectInput';

const ADDRESS_SEPARATOR = '\r\n';

const HealthieAddressInput = forwardRef(
  ({ label, name, value, onBlur, onChange, error, disabled, required, isVertical, states }, ref) => {
    const inputNames = [
      `${name}-line-1`,
      `${name}-line-2`,
      `${name}-state`,
      `${name}-city`,
      `${name}-country`,
      `${name}-zip-code`,
    ];
    const [line1Value, line2Value, stateValue, cityValue, countryValue, zipCodeValue] = value.split(ADDRESS_SEPARATOR);
    const stateDropdownValue = {
      label: states && states[stateValue],
      value: stateValue,
    };
    const stateOptions = states ? Object.entries(states).map((state) => ({ label: state[1], value: state[0] })) : [];

    const getInputData = (e, selectInputData) => {
      if (selectInputData) {
        return {
          inputName: selectInputData.name,
          inputValue: e.value,
        };
      }

      const { name: inputName, value: inputValue } = e.target;

      return { inputName, inputValue };
    };

    const handleInputChange = (e, selectInputData) => {
      const { inputName, inputValue } = getInputData(e, selectInputData);
      const updatedValue = value
        .split(ADDRESS_SEPARATOR)
        .map((item, i) => (inputNames.indexOf(inputName) === i ? inputValue : item))
        .join(ADDRESS_SEPARATOR);

      onChange(updatedValue);
    };

    return (
      <InputWrapper
        label={label}
        name={inputNames[0]}
        required={required}
        error={error}
        isVertical={isVertical}
        testId="healthie-address-input"
      >
        <div className="flex gap-4">
          <div className="flex-1 flex flex-col gap-4">
            <Input
              ref={ref}
              id={inputNames[0]}
              name={inputNames[0]}
              label="Line 1"
              type="text"
              value={line1Value}
              onBlur={onBlur}
              onChange={handleInputChange}
              disabled={disabled}
            />
            <Input
              id={inputNames[1]}
              name={inputNames[1]}
              label="Line 2"
              type="text"
              value={line2Value}
              onBlur={onBlur}
              onChange={handleInputChange}
              disabled={disabled}
            />
            <SelectInput
              id={inputNames[2]}
              name={inputNames[2]}
              label="State/Province"
              value={stateDropdownValue}
              options={stateOptions}
              onBlur={onBlur}
              onChange={handleInputChange}
              disabled={disabled}
            />
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <Input
              ref={ref}
              id={inputNames[3]}
              name={inputNames[3]}
              label="City"
              type="text"
              value={cityValue}
              onBlur={onBlur}
              onChange={handleInputChange}
              disabled={disabled}
            />
            <Input id={inputNames[4]} name={inputNames[4]} label="Country" value={countryValue} disabled />
            <Input
              id={inputNames[5]}
              name={inputNames[5]}
              label="Zip Code"
              type="text"
              value={zipCodeValue}
              onBlur={onBlur}
              onChange={handleInputChange}
              disabled={disabled}
            />
          </div>
        </div>
        <input name={name} type="hidden" value={value} />
      </InputWrapper>
    );
  }
);

HealthieAddressInput.displayName = 'HealthieAddressInput';

HealthieAddressInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  states: PropTypes.objectOf(PropTypes.string),
};

HealthieAddressInput.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  isVertical: true,
  error: null,
  states: null,
};

export default HealthieAddressInput;
