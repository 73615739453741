import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import useData from './hooks/use-data';
import IconRender from './IconRender';

function UdsCell(props) {
  const { isLoaded, data } = useData(props);
  const lastUdsSubmissionDate = data;
  const positiveUdsStatus = (
    <div>
      <p className="text-700 mb-0">Submitted</p>
      <p className="text-xs text-gray-700">{lastUdsSubmissionDate}</p>
    </div>
  );
  const negativeUdsStatus = <p className="text-700 mb-0">Not Submitted</p>;
  const udsStatus = lastUdsSubmissionDate ? positiveUdsStatus : negativeUdsStatus;
  return (
    <td>
      <div className="m-4">
        {isLoaded ? (
          <div>
            <div className="flex items-center">
              <div>
                <IconRender showCheckIcon={!!lastUdsSubmissionDate} />
              </div>
              {udsStatus}
            </div>
          </div>
        ) : (
          <Skeleton variant="text" width={50} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

export default UdsCell;
