import { useState } from 'react';

// HELPERS
import { mdyyyy } from '../../../helpers/DateFormatter';

// HOOKS
import useCachedShipmentData from './use-cached-shipment-data';

const useCachedSummaryData = () => {
  // CACHED DATA
  const { fetchCacheContentsData } = useCachedShipmentData();

  const [summary, setSummary] = useState({});

  const fetchCachedSummaryData = (name, labelAddress = {}, labelContents = {}) => {
    const address = labelAddress?.memberLocation?.address_information;
    const memberAddress =
      labelAddress?.memberLocation?.label === 'Other'
        ? `${address?.street1} ${address?.city}, ${address?.state}, ${address?.zip}`
        : labelAddress?.memberLocation?.label;

    const dimensions =
      labelContents?.packaging !== 'custom'
        ? `${labelContents?.packaging?.value}`
        : `${labelContents?.length}x${labelContents?.width}x${labelContents?.height} ${labelContents?.distanceUnit || 'in'}`;
    const { uds, fent, preg, oral, env } = fetchCacheContentsData(name, labelAddress?.members);

    const endDate = new Date(labelContents?.date);
    if (endDate) {
      endDate.setDate(endDate.getDate() + 2);
    }

    const summ = {
      labelAddressIsComplete: labelAddress?.isComplete,
      labelContentsIsComplete: labelContents?.isComplete,
      shipFrom: [
        {
          label: 'Staff Name',
          text: labelAddress?.selectedStaff?.label,
        },
        {
          label: 'Address',
          text: labelAddress?.location?.address,
        },
        {
          label: 'Staff Email',
          text: labelAddress?.staffEmail,
        },
        {
          label: 'Staff Phone',
          text: labelAddress?.staffPhone,
        },
      ],
      deliverTo: [
        {
          label: 'Member Name',
          text: labelAddress?.members?.map((m) => m.label)?.join(', '),
        },
        {
          label: 'Address',
          text: memberAddress,
        },
        {
          label: 'Member Email',
          text: labelAddress?.memberEmail,
        },
        {
          label: 'Member Phone',
          text: labelAddress?.memberPhone,
        },
      ],
      packageDetails: [
        {
          label: 'PACKAGING',
          text: labelContents?.packaging?.label,
        },
        {
          label: 'PACKAGE WEIGHT',
          text: `${labelContents?.weight} ${labelContents?.massUnit || 'lb'}`,
        },
        {
          label: 'DIMENSIONS',
          text: `${dimensions}`,
        },
        {
          label: 'UDS CUPS',
          text: uds,
        },
        {
          label: 'FENTANYL STRIPS',
          text: fent,
        },
        {
          label: 'PREGNANCY STRIPS',
          text: preg,
        },
        {
          label: 'ORAL SWABS',
          text: oral,
        },
        {
          label: 'PRE-PAID ENVELOPES',
          text: env,
        },
      ],
      service: [
        {
          label: 'CARRIER',
          text: labelContents?.service?.carrier,
        },
        {
          label: 'SERVICE',
          text: labelContents?.service?.label,
        },
        {
          label: 'SHIP DTE',
          text: mdyyyy(labelContents?.date?.toString()),
        },
        {
          label: 'DELIVERY DATE',
          text: `${mdyyyy(endDate.toString())}, end of day`,
        },
      ],
    };

    setSummary(summ);
    return summ;
  };

  return {
    summary,
    fetchCachedSummaryData,
  };
};

export default useCachedSummaryData;
