import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import GenericModal from '../../shared/GenericModal';
import LiListItem from '../../shared/LiListItem';

// HELPERS
import { mdyyyy, hourMinTimezone } from '../../../helpers/DateFormatter';

function PickupSummaryModal(props) {
  const confirm = () => {
    props.onConfirm();
  };
  const { pickupDate, readyTime, latestTime, pickupInstructions, location, isPreviouslyScheduled } =
    props.pickupSummary || {};

  return (
    <GenericModal
      isOpen={props.isOpen}
      showSecondary={false}
      headerText="Pickup / Drop Off"
      primaryButtonLabel="PRINT ALL"
      primaryButtonClick={() => {
        confirm();
      }}
    >
      <div className="m-4">
        <div className="font-semibold mb-4" style={{ color: '#237E3D' }}>
          {isPreviouslyScheduled ? (
            <>A pickup has already been scheduled for this date and location.</>
          ) : (
            <>Pickup has been successfully scheduled.</>
          )}
        </div>

        <LiListItem label="Pickup Date" text={mdyyyy(pickupDate?.$d?.toString())} />
        <LiListItem label="Ready Time" text={hourMinTimezone(readyTime?.$d?.toString())} />
        <LiListItem label="Latest Time Available" text={hourMinTimezone(latestTime?.$d?.toString())} />

        <LiListItem label="Pickup Instructions" text={pickupInstructions || ''} />
        <LiListItem label="Pickup Address" text={`${location?.label} (${location?.address})`} />
      </div>
    </GenericModal>
  );
}

PickupSummaryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  pickupSummary: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PickupSummaryModal;
