import React from 'react';
import PropTypes from 'prop-types';
import CustomPagination from './shared_dashboard/CustomPagination';

function TableNumberedPagination({ totalPages, currentPage, pageChange, totalRecordsCount }) {
  if (totalPages === 1) {
    return (
      <div className="text-base text-gray-700 text-center p-5">
        Showing <strong>{totalRecordsCount}</strong> of <strong>{totalRecordsCount}</strong>
      </div>
    );
  }

  return (
    <CustomPagination
      show={totalPages > 1}
      page={currentPage}
      totalPages={totalPages}
      onPageChange={(_e, page) => pageChange(page)}
      nextLabel
      previousLabel
      additionalStyles={{
        'li:first-of-type': {
          marginRight: 'auto',
        },
        'li:last-of-type': {
          marginLeft: 'auto',
        },
        '.MuiPagination-ul': {
          width: '100%',
          padding: '0 8px',
        },
      }}
    />
  );
}

TableNumberedPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalRecordsCount: PropTypes.number.isRequired,
};

export default TableNumberedPagination;
