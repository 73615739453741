import { formatLocationLabel } from '../shared/helpers';
import {
  ATTENDED_ATTENDANCE_STATUS,
  NO_SHOW_ATTENDANCE_STATUS,
  RESCHEDULED_ATTENDANCE_STATUS,
} from '../shared/constants';

export const getDefaultStatus = (attendance, { statuses, defaultStatus }) => {
  const foundStatus = statuses.find(({ value }) => value === attendance.status);
  if (!foundStatus || !attendance?.status) return defaultStatus;
  return foundStatus;
};

export const getDefaultLocation = (attendance, { locations }) => {
  const parsedLocations = Object.entries(locations ?? {}).map(([key, loc]) => ({
    value: loc.id,
    label: formatLocationLabel(key, loc.name),
    fullAddress: loc.fullAddress,
  }));

  if (attendance?.location) {
    return parsedLocations.find((loc) => loc.value === attendance.location?.id);
  }

  if (attendance?.customLocation) {
    const locationText = attendance.customLocation;
    return {
      value: locationText,
      label: locationText,
      wasCreated: true,
    };
  }

  return null;
};

export const prepareMemberAttendanceData = (member, { statuses, defaultStatus }) => ({
  name: `${member.firstName} ${member.lastName}`,
  status: getDefaultStatus(member.attendance, { statuses, defaultStatus }),
  location: getDefaultLocation(member.attendance, { locations: member.memberLocations }),
});

export const shouldDisableLocation = (status) =>
  [NO_SHOW_ATTENDANCE_STATUS.value, RESCHEDULED_ATTENDANCE_STATUS.value].includes(status?.value);

export const shouldRequireExtraColumns = (status) => status?.value === ATTENDED_ATTENDANCE_STATUS.value;

export const mapFormToAppointment = (appointment, formItems) => ({
  ...appointment,
  members: appointment.members.map((member) => {
    const { status, startTime, endTime, location } = formItems[member.id] || {};

    return {
      ...member,
      attendance: {
        ...member.attendance,
        ...(location
          ? {
              customLocation: location.wasCreated ? location.value : null,
              location: location.wasCreated
                ? null
                : {
                    fullAddress: location.fullAddress,
                    id: location.value,
                    name: location.label,
                  },
            }
          : {
              customLocation: null,
              location: null,
            }),
        status: status?.value || member.attendance.status,
        startDate: startTime?.toISOString() ?? null,
        endDate: endTime?.toISOString() ?? null,
      },
    };
  }),
});
