import React from 'react';
import PropTypes from 'prop-types';
import { formatShortDayTime } from './DateFormatHelper';

function MemberGroupDetails({ title, date, location, counselor, provider, officeManager, isSecondaryVariant }) {
  const getStaffMember = (member, type) => {
    if (!member) return null;
    const memberName = `${type}: ${member.charAt(0)}. ${member.split(' ')[1]}`;
    return isSecondaryVariant ? memberName : `(${memberName})`;
  };

  return (
    <>
      <p className="text-base font-semibold mb-2">{title}</p>
      <p data-testid="group-info" className="mb-0">
        {formatShortDayTime(date)}
        <span className="mx-1 text-sm bg-gray-300 px-1.5 py-0.5 rounded text-gray-700">{location}</span>
        {!isSecondaryVariant && getStaffMember(counselor, 'Counselor')}
      </p>
      {isSecondaryVariant && (
        <p className="m-0 mt-1">
          {getStaffMember(counselor, 'Counselor')}
          <br />
          {getStaffMember(provider, 'Provider')}
          <br />
          {getStaffMember(officeManager, 'Office Manager')}
        </p>
      )}
    </>
  );
}

MemberGroupDetails.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  counselor: PropTypes.string.isRequired,
  provider: PropTypes.string,
  officeManager: PropTypes.string,
  isSecondaryVariant: PropTypes.bool,
};

MemberGroupDetails.defaultProps = {
  provider: null,
  officeManager: null,
  isSecondaryVariant: false,
};

export default MemberGroupDetails;
