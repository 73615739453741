import React from 'react';
import PropTypes from 'prop-types';

function InviteHistoryMessage(props) {
  return (
    <div className="w-1/2">
      <p className="text-base text-black font-semibold leading-5">
        <span className={`inline-block w-2 h-2 rounded-full ${props.colorClassName} mb-0.5 mr-2`} />
        {props.primaryMessage}
      </p>
      <p className="text-gray-700 font-normal text-sm"> {props.secondaryMessage} </p>
    </div>
  );
}

InviteHistoryMessage.propTypes = {
  primaryMessage: PropTypes.string.isRequired,
  secondaryMessage: PropTypes.string.isRequired,
  colorClassName: PropTypes.string.isRequired,
};

export default InviteHistoryMessage;
