import React from 'react';
import PropTypes from 'prop-types';

function EmptyTableMessage(props) {
  return (
    <div className={props.classes}>
      <div className="relative overflow-visible">
        <div className="bg-white border-gray-400 shadow-md rounded-lg border overflow-auto">
          <div className="py-5 px-3 text-center">
            <p className="text-gray-700 font-semibold text-lg text-center mb-1">{props.text}</p>
            <p className="text-gray-700 text-center mb-0">{props.subText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

EmptyTableMessage.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  classes: PropTypes.string,
};

EmptyTableMessage.defaultProps = {
  text: 'Noting to show here',
  subText: 'Try filtering by a different value',
  classes: '',
};

export default EmptyTableMessage;
