import React from 'react';
import { PropTypes } from 'prop-types';
import { HiOutlinePencil } from 'react-icons/hi';

function GoalEditButton({ _goal, testId }) {
  return (
    <a href="/staff/members/">
      <div className="goal-edit-button" data-testid={testId}>
        <HiOutlinePencil />
      </div>
    </a>
  );
}

GoalEditButton.propTypes = {
  _goal: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
};

export default GoalEditButton;
