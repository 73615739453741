import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

/**
 * Look up information about something.
 */
function InfoLookup({ endpoint, description, idName }) {
  const [thingId, setThingId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (!thingId) {
      setLoading(false);
      setError(null);
      setInfo(null);
    }
  }, [thingId]);

  /**
   * Look up the given zoom id.
   */
  const lookupId = () => {
    setLoading(true);
    setError(null);
    setInfo(null);
    if (thingId) {
      axios
        .get(`${endpoint}?${idName}=${thingId}`)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            setInfo(response.data);
          } else {
            setError('Nothing was returned by the API, please talk to a developer about this ID.');
          }
        })
        .catch((_e) => {
          setLoading(false);
          setError('There was an issue looking up this ID, please talk to a developer about it.');
        });
    } else {
      setLoading(false);
    }
  };

  /**
   * Handle input value change.
   */
  const handleChange = (event) => {
    setThingId(event.target.value);
    setError(null);
  };

  return (
    <div className="overflow-hidden mt-4 border border-gray-400 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5">
        <h2 className="text-lg font-body font-semibold leading-6 text-gray-900">{description} Lookup</h2>
      </div>
      <div>
        <ul>
          <div className="px-4 py-5 bg-gray-50 border-t border-gray-300">
            <div className="text-left">
              {!!info && <pre className="mb-6 text-sm">{JSON.stringify(info, null, 2)}</pre>}
              {!info && (
                <div className="leading-5">
                  <p className="mb-6">
                    Enter the
                    {` ${description}`}, and this will query the Digital Platform for details about it.
                  </p>
                </div>
              )}
              {!!error && (
                <p className="mb-6">
                  Error:
                  {error}
                </p>
              )}
              <input
                className="btn btn--form-input text-black w-full rounded-lg"
                type="number"
                value={thingId}
                onChange={handleChange}
                placeholder={description}
              />
              <button
                disabled={!thingId}
                className="btn btn--primary block w-full mt-6"
                onClick={() => lookupId()}
                type="button"
              >
                {loading && (
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Loading...</span>
                  </div>
                )}
                {!loading && 'Query'}
              </button>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

InfoLookup.propTypes = {
  endpoint: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  idName: PropTypes.string.isRequired,
};

export default InfoLookup;
