import React from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { CopyBlock, github } from 'react-code-blocks';

function CodeBlock(props) {
  return <CopyBlock language="jsx" text={props.text} showLineNumbers theme={github} wrapLines codeBlock />;
}

CodeBlock.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CodeBlock;
