import React from 'react';
import PropTypes from 'prop-types';

function FormRow(props) {
  return <div className={`m-4 ${props.classes}`}>{props.children}</div>;
}

FormRow.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node,
};

FormRow.defaultProps = {
  classes: null,
  children: null,
};

export default FormRow;
