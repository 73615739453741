import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useHttp from '../../shared/hooks/use-http';
import GroupsModal from '../../GroupsModal';
import Input from '../../shared/Input';

const INPUT_ERROR = 'There was an error when saving expression. Please try again later';

function EditFormExtensionsModal({
  isOpen,
  onClose,
  currentFormTemplate,
  authenticityToken,
  updateFormTemplate,
  templateExtensionExpressions,
  memberContextExpressions,
}) {
  const { isLoading, sendRequest } = useHttp();

  const { id, name, custom_modules: customModules } = currentFormTemplate;

  const initialFormValues = customModules.reduce(
    (prevValue, customModule) => ({
      ...prevValue,
      [customModule.id]: customModule.expressions || '',
    }),
    {}
  );

  const schema = object(
    customModules.reduce(
      (prevValue, customModule) => ({
        ...prevValue,
        [customModule.id]: string(),
      }),
      {}
    )
  );

  const { handleSubmit, control, setError } = useForm({
    mode: 'onBlur',
    values: initialFormValues,
    resolver: yupResolver(schema),
  });

  const getTemplateId = (customModuleId) =>
    customModules.find((customModule) => customModule.id === customModuleId)?.extension_id;

  const updateFormTemplateRequest = async (data, inputName) => {
    const customExpression = data[inputName] && JSON.parse(`{${data[inputName]}}`);

    const unhandledContextExpressions =
      customExpression.default_value &&
      customExpression.default_value
        .match(/{(.*?)}/g)
        ?.filter((expression) => !memberContextExpressions.includes(expression.slice(1, -1)));

    if (unhandledContextExpressions?.length) throw new Error();

    const expressions =
      customExpression &&
      JSON.stringify(
        Object.entries(customExpression).map(([key, value]) => ({
          extension_type: key,
          custom_expression: value.toString(),
        }))
      );

    let method = 'POST';
    let url = `/staff/documentation/${id}/document_template_extensions`;
    let body = {
      question_id: inputName,
      expressions,
    };

    const templateId = getTemplateId(inputName);

    if (customExpression && initialFormValues[inputName]) {
      method = 'PUT';
      url = `/staff/documentation/${id}/document_template_extensions/${templateId}`;
    }

    if (!customExpression && initialFormValues[inputName]) {
      method = 'DELETE';
      url = `/staff/documentation/${id}/document_template_extensions/${templateId}`;
      body = null;
    }

    return sendRequest(url, {
      method,
      headers: {
        'X-CSRF-Token': authenticityToken,
      },
      data: body,
    });
  };

  const onSubmit = async (data, e) => {
    const inputName = e.target.name;
    if (data[inputName] === initialFormValues[inputName]) return;

    try {
      const response = await updateFormTemplateRequest(data, inputName);

      const newFormTemplateCustomModules = currentFormTemplate.custom_modules.map((customModule) =>
        customModule.id === inputName
          ? {
              ...customModule,
              expressions: data[inputName],
              extension_id: response.id,
            }
          : customModule
      );

      const newFormTemplate = {
        ...currentFormTemplate,
        custom_modules: newFormTemplateCustomModules,
      };

      updateFormTemplate(newFormTemplate);
    } catch (error) {
      setError(inputName, {
        message: INPUT_ERROR,
      });
    }
  };

  const footerButtons = [
    {
      label: 'Continue',
      isPrimary: true,
      onClick: onClose,
    },
  ];

  return (
    <GroupsModal
      isOpen={isOpen}
      header={`Edit ${name} extensions`}
      onClose={onClose}
      footerButtons={footerButtons}
      isLoading={isLoading}
      size="large"
      isScrollable={false}
    >
      <div className="flex flex-col gap-4 min-h-0 md:flex-row md:gap-6">
        <div className="px-6 md:px-0 md:py-4 overflow-y-auto">
          <p className="text-base font-semibold mb-2">Template extension expressions</p>
          <ul className="list-disc px-5">
            {templateExtensionExpressions?.map((expression) => (
              <li key={expression}>{expression}</li>
            ))}
          </ul>
          <p className="text-base font-semibold mt-2 mb-2">Member context expressions</p>
          <ul className="list-disc px-5">
            {memberContextExpressions?.map((expression) => (
              <li key={expression}>{expression}</li>
            ))}
          </ul>
        </div>
        <div className="bg-white border border-gray-400 rounded-lg flex-1 overflow-y-auto">
          <div className="px-6 py-4">
            <form onSubmit={(e) => e.preventDefault()} onBlur={handleSubmit(onSubmit)} className="flex flex-col gap-2">
              {customModules.map((customModule) => (
                <div key={customModule.id}>
                  <Controller
                    name={customModule.id}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        label={customModule.label}
                        placeholder="Enter Expression"
                        type="text"
                        error={error && INPUT_ERROR}
                        disabled={isLoading}
                      />
                    )}
                  />
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </GroupsModal>
  );
}

EditFormExtensionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentFormTemplate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    custom_modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  updateFormTemplate: PropTypes.func.isRequired,
  templateExtensionExpressions: PropTypes.arrayOf(PropTypes.string).isRequired,
  memberContextExpressions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditFormExtensionsModal;
