import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BiEdit as EditIcon } from 'react-icons/bi';
import { PiArrowCounterClockwiseBold as RevertIcon } from 'react-icons/pi';
import classNames from 'classnames';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

function EditableTextRowValue({
  name,
  value,
  setValue,
  isLoading,
  onSave,
  isEditing,
  setIsEditing,
  error,
  onBlur,
  hideValue,
  customHideMessage,
  customHideButtonValue,
}) {
  const [isShowing, setIsShowing] = useState(!hideValue);
  const revertToValueRef = useRef(value);

  const handleRevert = () => {
    setValue(revertToValueRef?.current);
    setIsEditing(false);
  };

  const handleSave = () => {
    onSave();
    revertToValueRef.current = value;
  };

  if (!isEditing) {
    return (
      <div className="flex justify-between items-center flex-col md:flex-row gap-2">
        <div className="flex items-center gap-2">
          <span>{isShowing ? value : customHideMessage}</span>
          <button
            type="button"
            aria-label="Edit"
            onClick={() => setIsEditing((v) => !v)}
            className="text-teal-700 focus:outline-none"
          >
            <EditIcon className="h-6 w-6 fill-current focus:outline-none" />
          </button>
        </div>
        {hideValue && !isEditing && (
          <Button
            onClick={() => setIsShowing((v) => !v)}
            classes={classNames('border-none -my-2', { invisible: isShowing })}
          >
            {`Show ${customHideButtonValue}`}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <div className="-my-2 w-80">
        <Input name={name} value={value} onChange={(e) => setValue(e.target?.value)} error={error} onBlur={onBlur} />
      </div>
      <button
        type="button"
        aria-label="Revert"
        className="btn--tertiary border-2 border-teal-700 rounded-full p-3 flex items-center justify-center text-teal-700"
        onClick={handleRevert}
      >
        <RevertIcon className="h-6 w-6 fill-current focus:outline-none" />
      </button>
      <Button isPrimary isLoading={isLoading} onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

EditableTextRowValue.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  error: PropTypes.string,
  hideValue: PropTypes.bool,
  customHideButtonValue: PropTypes.string,
  customHideMessage: PropTypes.string,
};

EditableTextRowValue.defaultProps = {
  isLoading: false,
  error: null,
  onBlur: () => {},
  hideValue: false,
  customHideButtonValue: 'Value',
  customHideMessage: '**********',
};

export default EditableTextRowValue;
