import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReassignPrescriptionsModal from './ReassignPrescriptionsModal';
import useToasts from '../../../shared/hooks/use-toasts';
import useHttp from '../../../shared/hooks/use-http';

function ReassignPrescriptions({
  authenticityToken,
  prescriptionTasks,
  assigneesList,
  onSuccess,
  isModalOpen,
  closeModal,
}) {
  const { toasts, removeToast, addToast } = useToasts();
  const { sendRequest, isLoading } = useHttp();

  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const reassignTask = async () => {
    try {
      const response = await sendRequest('/staff/prescription_tasks/reassign', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          prescription_task: {
            prescription_tasks_ids: prescriptionTasks.map((task) => task.id),
            user_id: selectedAssignee?.value,
          },
        },
      });

      onSuccess(response);
      closeModal();
    } catch (err) {
      addToast({ type: 'error', header: 'Something went wrong' });
    }
  };

  return (
    <ReassignPrescriptionsModal
      prescriptionTasks={prescriptionTasks}
      assigneesList={assigneesList}
      isOpen={isModalOpen}
      onClose={closeModal}
      onReassign={reassignTask}
      isReassignLoading={isLoading}
      toasts={toasts}
      removeToast={removeToast}
      selectedAssignee={selectedAssignee}
      setSelectedAssignee={setSelectedAssignee}
    />
  );
}

ReassignPrescriptions.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  prescriptionTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      member: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    })
  ).isRequired,
  assigneesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSuccess: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ReassignPrescriptions.defaultProps = {
  isModalOpen: false,
};

export default ReassignPrescriptions;
