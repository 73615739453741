import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { BiEdit as EditIcon } from 'react-icons/bi';
import { PiArrowCounterClockwiseBold as RevertIcon } from 'react-icons/pi';
import SelectInput from '../../shared/SelectInput';
import Button from '../../shared/Button';

function EditableSelectRowValue({
  name,
  selectedValue,
  setSelectedValue,
  valuesList,
  isLoading,
  onSave,
  isEditing,
  setIsEditing,
}) {
  const revertToValueRef = useRef(selectedValue);

  const handleRevert = () => {
    setSelectedValue(revertToValueRef?.current);
    setIsEditing(false);
  };

  const handleSave = () => {
    onSave();
    revertToValueRef.current = selectedValue;
  };

  if (!isEditing) {
    return (
      <div className="flex items-center gap-2">
        <span>{selectedValue?.label}</span>
        <button
          type="button"
          aria-label="Edit"
          onClick={() => setIsEditing((v) => !v)}
          className="text-teal-700 focus:outline-none"
        >
          <EditIcon className="h-6 w-6 fill-current focus:outline-none" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center -my-2">
        <SelectInput
          value={selectedValue}
          onChange={(v) => setSelectedValue(v)}
          name={name}
          options={valuesList}
          wrapperClassNames="w-80"
        />
      </div>
      <button
        type="button"
        aria-label="Revert"
        className="btn--tertiary border-2 border-teal-700 rounded-full p-3 flex items-center justify-center"
        onClick={handleRevert}
      >
        <RevertIcon className="h-6 w-6 fill-current focus:outline-none" />
      </button>
      <Button isPrimary isLoading={isLoading} onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

const ValuePropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

EditableSelectRowValue.propTypes = {
  name: PropTypes.string.isRequired,
  selectedValue: ValuePropTypes.isRequired,
  valuesList: PropTypes.arrayOf(ValuePropTypes).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
};

EditableSelectRowValue.defaultProps = {
  isLoading: false,
};

export default EditableSelectRowValue;
