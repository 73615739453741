import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import TimeCell from '../shared_dashboard/TimeCell';
import DefaultCell from '../shared_dashboard/DefaultCell';
import MemberCell from '../shared_dashboard/MemberCell';
import IconCell from '../shared_dashboard/IconCell';
import TreatmentConsentCell from '../shared_dashboard/TreatmentConsentCell';
import AccountStatusCell from '../shared_dashboard/AccountStatusCell';
import UdsCell from '../shared_dashboard/UdsCell';

function AppointmentsTableRow(props) {
  return (
    <tr key={props.data?.id}>
      {/* MEMBER CELL */}
      <MemberCell id={props.data?.member_id} type="memberType" endpoint="/staff/dashboard_v2/fetch_member" />

      {/* TIME CELL */}
      <TimeCell id={props.data?.id} data={props.data?.date_time} />

      {/* TYPE CELL */}
      <DefaultCell id={props.data?.id} preloadedData={props.data?.appointment_type} />

      {/* STATUS CELL */}
      <DefaultCell id={props.data?.id} preloadedData={props.data?.status} />

      {/* ACCOUNT CELL */}
      <AccountStatusCell
        id={props.data?.member_id}
        authenticityToken={props.authenticityToken}
        type="accountStatusType"
        endpoint="/staff/dashboard_v2/fetch_account_invite_status"
      />

      {/* APP STATUS CELL */}
      <IconCell id={props.data?.member_id} type="appStatusType" endpoint="/staff/dashboard_v2/fetch_app_status" />

      {/* CONSENT CELL */}
      <TreatmentConsentCell
        id={props.data?.member_id}
        type="treatmentConsentType"
        endpoint="/staff/dashboard_v2/fetch_treatment_consent"
      />

      {/* HIPAA CELL */}
      <IconCell id={props.data?.member_id} type="hipaaStatusType" endpoint="/staff/dashboard_v2/fetch_hippa_status" />

      {/* PHOTO ID CELL */}
      <IconCell
        id={props.data?.member_id}
        type="photoIdStatusType"
        endpoint="/staff/dashboard_v2/fetch_photo_id_status"
      />

      {/* INSURANCE CELL */}
      {/* UPDATE FOR ICON AND TEXT */}
      <IconCell id={props.data?.member_id} type="insuranceType" endpoint="/staff/dashboard_v2/fetch_has_insurance" />

      {/* PREGNANT CELL */}
      <DefaultCell id={props.data?.member_id} type="pregnantType" endpoint="/staff/dashboard_v2/fetch_is_pregnant" />

      {/* PROVIDER CELL */}
      <DefaultCell
        id={props.data?.provider_id}
        type="providerType"
        endpoint="/staff/dashboard_v2/fetch_appointment_provider"
      />

      {/* UDS CELL */}
      <UdsCell id={props.data?.member_id} type="udsType" endpoint="/staff/dashboard_v2/fetch_uds" />
    </tr>
  );
}

AppointmentsTableRow.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default AppointmentsTableRow;
