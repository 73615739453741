import React from 'react';
import PropTypes from 'prop-types';

function UploadIcon(props) {
  return (
    <svg
      className={props.className}
      style={props.style}
      width="61"
      height="50"
      viewBox="0 0 61 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick()}
    >
      <path
        d="M40.5217 35.0849L30.2609 24.9972L20 35.0849"
        stroke="#888888"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2607 24.9972V47.6946"
        stroke="#888888"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7828 41.1123C56.9297 38.3538 59.5192 32.5157 58.0733 26.9307C56.6274 21.3457 51.5139 17.4352 45.652 17.4314H42.4198C40.2934 9.34562 33.3195 3.38368 24.8794 2.43625C16.4392 1.48883 8.27777 5.75179 4.35217 13.1582C0.426566 20.5646 1.54838 29.5833 7.1737 35.8415"
        stroke="#888888"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5217 35.0849L30.2609 24.9972L20 35.0849"
        stroke="#888888"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

UploadIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.func,
};

UploadIcon.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
};

export default UploadIcon;
