import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = (id) => {
  const [member, setMember] = useState({});
  const [groups, setGroups] = useState({});

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members/show_member', {
          params: {
            id,
          },
        });

        setMember(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchGroupData = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members/fetch_edit_modal_filters');

        setGroups(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchGroupData();

    fetchMember();
  }, [id]);

  return {
    groups,
    member,
  };
};

export default useFetchData;
