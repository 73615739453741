import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import AppliedFilters from '../../shared/AppliedFilters';
import Button from '../../shared/Button';
import EncountersFilters from './EncountersFilters';
import { TABLE_HEADERS, DEFAULT_FILTERS, DOCUMENT_STATUS } from './constants';
import Table from '../../Table';
import useFilters from '../../shared/hooks/use-filters';
import useFilterData from './useFilterData';
import useEncounters from './useEncounters';
import { rowPropType } from './MemberEncounterTableCell';

const testID = 'memberEncounterHistoryTab';

function EncountersTab({
  memberTimeline,
  canUserManageChartingNotes,
  onAddDocumentationClick,
  onEditDocumentationClick,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const { filters, setFilter, filtersArray } = useFilters(DEFAULT_FILTERS);
  const { filteredData, parsedAppointmentOccurrences, isLoading, handleSort, sortedBy } = useEncounters(
    memberTimeline,
    filters
  );
  const {
    facilitiesData,
    staffData,
    appointmentTypesData,
    typesData,
    documentNamesData,
    statusData,
    isLoading: isFilterDataLoading,
  } = useFilterData(parsedAppointmentOccurrences);
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;
  const toggleFilters = () => setShowFilters((s) => !s);

  return (
    <div className="bg-white p-6 flex flex-col flex-1 gap-6 overflow-auto">
      <div>
        <div className="flex justify-between items-center gap-6 border-gray-300 border-b pb-2.5 mb-2.5">
          <h2 className="text-2xl font-bold font-inter">Timeline</h2>
          <div className="flex gap-2.5">
            <Button
              data-testid={`toggle-filters-button-${testID}`}
              onClick={toggleFilters}
              isSecondary
              classes="max-h-12"
            >
              <div className="flex items-center">
                Apply Filters
                <FilterBtnIcon className="text-2xl ml-2" />
              </div>
            </Button>
            {canUserManageChartingNotes && (
              <Button classes="btn--primary" onClick={onAddDocumentationClick}>
                Add Documentation
              </Button>
            )}
          </div>
        </div>
        <AppliedFilters currentlyAppliedFilters={filtersArray} />
      </div>
      {!isFilterDataLoading && showFilters && (
        <EncountersFilters
          currentlyAppliedFilters={filters}
          testID={testID}
          setFilter={setFilter}
          appointmentTypesData={appointmentTypesData}
          staffData={staffData}
          statusData={statusData}
          facilityData={facilitiesData}
          documentNameData={documentNamesData}
          documentStatusData={DOCUMENT_STATUS}
          typesData={typesData}
        />
      )}
      <div className="mt-4">
        {isLoading ? (
          <div data-testid={`noData-${testID}`} className="no-data-loader">
            <CircularProgress />
          </div>
        ) : (
          <div className="border border-gray-400 shadow-md rounded-lg pb-3 overflow-auto">
            <Table
              tableType="memberEncounter"
              showShadow={false}
              tableRows={filteredData}
              tableColumnHeaders={TABLE_HEADERS}
              totalCountRecords={parsedAppointmentOccurrences?.length}
              showPageNumbers={false}
              headerButtonClick={handleSort}
              currentSort={sortedBy}
              currentPage={1}
              onClickEditRow={onEditDocumentationClick}
              testID={testID}
              hideLoadMoreButton
              stickyHeader
              enableActionsColumn
              stickyActionColumn
              enableHidingShadowInStickyColumn
              actionColumnClasses="w-0"
            />
          </div>
        )}
      </div>
    </div>
  );
}

EncountersTab.propTypes = {
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  onAddDocumentationClick: PropTypes.func.isRequired,
  onEditDocumentationClick: PropTypes.func.isRequired,
  memberTimeline: PropTypes.arrayOf(rowPropType).isRequired,
};

export default EncountersTab;
