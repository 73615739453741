import React from 'react';
import PropTypes from 'prop-types';
import GeneralCell from '../../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT } from '../../../../helpers/DateFormatter';
import { appendReferrerParams } from '../../../../helpers/ReferrerParams';
import ActionColumnLink from '../../../shared/ActionColumnLink';
import { useAppointmentsURLs } from './useAppointmentsURLs';

function GroupAppointmentsTableRow({ row, testID, enableActionsColumn, containerElement }) {
  const { appointmentFinderUrl } = useAppointmentsURLs();
  const groupAppointmentDetailsUrl = appendReferrerParams(appointmentFinderUrl, {
    appointment_occurrence_id: row.id,
  });
  const { type, providers, otherInformation, memberCount, scheduledAt, locations } = row ?? {};
  const uniqueLocations = [...new Set(locations)];

  const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(scheduledAt);
  const day = dateTimeWithOffset.format('ddd, M/D/YY');
  const time = `${dateTimeWithOffset.format(DEFAULT_TIME_FORMAT)} ${timeZone.abbrev}`;

  return (
    <>
      <GeneralCell
        testID={`meeting-${testID}`}
        classes="mt-2 mb-2"
        data={
          scheduledAt ? (
            <div className="flex flex-col gap-1">
              <a className="font-semibold text-teal-700 underline" target="_top" href={groupAppointmentDetailsUrl}>
                {day}
              </a>
              <div className="flex gap-2 items-center flex-wrap" style={{ maxWidth: '192px' }}>
                <span className="text-sm text-gray-700">{time}</span>
                {uniqueLocations?.map((location) => (
                  <span key={location} className="py-1 px-1.5 bg-gray-300 rounded-md text-sm text-gray-900">
                    {location}
                  </span>
                ))}
              </div>
            </div>
          ) : null
        }
      />
      <GeneralCell testID={`type-${testID}`} data={type} subText={otherInformation?.join(', ')} />
      <GeneralCell testID={`staff-${testID}`} data={providers} />
      <GeneralCell testID={`members-${testID}`} data={memberCount} />
      <ActionColumnLink
        containerElement={containerElement}
        enableActionsColumn={enableActionsColumn}
        href={groupAppointmentDetailsUrl}
        testID={`details-url-${testID}`}
      />
    </>
  );
}

GroupAppointmentsTableRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    otherInformation: PropTypes.arrayOf(PropTypes.string),
    staff: PropTypes.arrayOf(PropTypes.string),
    memberCount: PropTypes.number,
    scheduled_at: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  enableActionsColumn: PropTypes.bool,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

GroupAppointmentsTableRow.defaultProps = {
  enableActionsColumn: true,
  containerElement: null,
};

export default GroupAppointmentsTableRow;
