import React from 'react';
import PropTypes from 'prop-types';
import { FiEdit } from 'react-icons/fi';
import GeneralCell from '../../GeneralCell';

function FormExtensionsTableRow({ enableActionsColumn, row, onEditButtonClick, testID }) {
  return (
    <>
      <GeneralCell testID={`name-${testID}`} data={row.name} containerClasses="w-full" classes="py-7" />
      {enableActionsColumn ? (
        <td data-testid={`action-buttons-${testID}`} className="flex ml-4 py-5">
          <button
            data-testid={`edit-button-${testID}`}
            type="button"
            className="table-action-button"
            onClick={() => onEditButtonClick(row.id)}
            disabled={!row.custom_modules.length}
          >
            <FiEdit />
          </button>
        </td>
      ) : null}
    </>
  );
}

FormExtensionsTableRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    custom_modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  enableActionsColumn: PropTypes.bool.isRequired,
};

export default FormExtensionsTableRow;
