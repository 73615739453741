import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { customStyles, dynamicMarginSubsection } from './CheckBoxListItemStyle';
import { mdyyyyTimeFormat } from '../../helpers/DateFormatter';

function CheckBoxListItem(props) {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  function updateChecked() {
    // useState does not update immediately so need to set it as const
    // for the props func passing to the parent component
    const updatedIsChecked = !isChecked;

    setIsChecked(updatedIsChecked);
    props.onSetChecked(updatedIsChecked);
  }

  return (
    // DENOTES each list item as a button with a checkbox
    <ListItem disablePadding sx={customStyles.listItem}>
      {/* BUTTON wrapper for checkbox, text and subtext */}
      <ListItemButton
        component="a"
        sx={customStyles.listItemButton}
        selected={props.isSelected}
        onClick={(event) => props.onClick(event)}
      >
        {/* Checkbox */}
        {props.hideCheckbox ? null : (
          <Checkbox
            sx={customStyles.checkBox}
            checked={isChecked}
            disabled={props.disable}
            onChange={() => updateChecked()}
          />
        )}

        {/* List Item Text with Text and Subtext */}
        <ListItemText
          primary={<Typography sx={customStyles.listItemText}>{props.title}</Typography>}
          secondary={mdyyyyTimeFormat(props.subHeader)}
          sx={{ marginLeft: dynamicMarginSubsection(props.isSubsection, props.hideCheckbox) }}
        />
      </ListItemButton>
    </ListItem>
  );
}

CheckBoxListItem.propTypes = {
  title: PropTypes.string.isRequired,
  isSubsection: PropTypes.bool,
  subHeader: PropTypes.string.isRequired,
  hideCheckbox: PropTypes.bool,
  isSelected: PropTypes.bool,
  isChecked: PropTypes.bool,
  disable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onSetChecked: PropTypes.func.isRequired,
};

CheckBoxListItem.defaultProps = {
  isSubsection: false,
  hideCheckbox: false,
  isSelected: false,
  isChecked: false,
};

export default CheckBoxListItem;
