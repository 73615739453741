import { FILTER_TYPES } from './constants';

const setRequestParams = (pageNumber, filters, sort) => {
  const sortDirection = sort?.desc ? 'desc' : 'asc';
  const sortedBy = sort ? `${sort.id} ${sortDirection}` : null;

  const reason_ids = filters[FILTER_TYPES.reason].map(({ value }) => value);
  const statuses = filters[FILTER_TYPES.status].map(({ value }) => value);
  const assignee_ids = filters[FILTER_TYPES.assignee].map(({ value }) => value);
  const location_ids = filters[FILTER_TYPES.locations].map(({ value }) => value);
  const provider_ids = filters[FILTER_TYPES.providers].map(({ value }) => value);
  const searchMemberText = filters[FILTER_TYPES.member];
  const state_ids = filters[FILTER_TYPES.state].map(({ value }) => value);
  const due_date = filters[FILTER_TYPES.dueDate] ? filters[FILTER_TYPES.dueDate][0]?.value : null;
  const time = {
    from_time: filters[FILTER_TYPES.fromTime]?.format?.('hh:mm A'),
    to_time: filters[FILTER_TYPES.toTime]?.format?.('hh:mm A'),
  };

  const dates = {
    from_date: filters[FILTER_TYPES.fromDate]?.format?.('YYYY-MM-DD'),
    to_date: filters[FILTER_TYPES.toDate]?.format?.('YYYY-MM-DD'),
  };

  return {
    page: pageNumber,
    sorted_by: sortedBy,
    reason_ids: reason_ids.includes('*') ? null : reason_ids,
    statuses: statuses.includes('*') ? null : statuses,
    assignee_ids: assignee_ids.includes('*') ? null : assignee_ids,
    location_ids: location_ids.includes('*') ? null : location_ids,
    provider_ids: provider_ids.includes('*') ? null : provider_ids,
    search_member_text: searchMemberText || null,
    state_ids: state_ids.includes('*') ? null : state_ids,
    due_date,
    time,
    dates,
  };
};
export default setRequestParams;
