import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GoalsSubheader from './GoalsSubHeader';
import LongTermGoal from './LongTermGoal';
import ShortTermGoal from './ShortTermGoal';
import ProgramExpectationGoal from './ProgramExpectationGoal';
import InlineShortTermGoalForm from './InlineShortTermGoalForm';
import InlineLongTermGoalForm from './InlineLongTermGoalForm';

function ActiveGoalsView(props) {
  const { memberId, shortTermGoal, longTermGoal, updateCallback, testId } = props;

  const [ltgEditOpen, setLtgEditOpen] = useState(false);
  const [stgEditOpen, setStgEditOpen] = useState(false);

  useEffect(() => {
    setLtgEditOpen(false);
    setStgEditOpen(false);
  }, [longTermGoal, shortTermGoal]);

  const goalNotEmpty = (goal) => !!goal && 'title' in goal && !!goal.title;

  const handleLtgEdit = () => {
    setLtgEditOpen(true);
  };

  const handleStgEdit = () => {
    setStgEditOpen(true);
  };

  const longTermGoalContent = () => {
    if (goalNotEmpty(longTermGoal) && !ltgEditOpen) {
      return (
        <LongTermGoal
          goal={longTermGoal}
          updateCallback={updateCallback}
          editCallback={handleLtgEdit}
          testId={`active-ltg-${testId}`}
        />
      );
    }
    return (
      <InlineLongTermGoalForm
        goal={longTermGoal}
        memberId={memberId}
        updateCallback={updateCallback}
        testId={`active-ltg-form-${testId}`}
      />
    );
  };

  const shortTermGoalContent = () => {
    if (goalNotEmpty(shortTermGoal) && !stgEditOpen) {
      return (
        <ShortTermGoal
          goal={shortTermGoal}
          updateCallback={updateCallback}
          editCallback={handleStgEdit}
          testId={`active-stg-${testId}`}
        />
      );
    }
    return (
      <InlineShortTermGoalForm
        goal={shortTermGoal}
        memberId={memberId}
        updateCallback={updateCallback}
        testId={`active-stg-form-${testId}`}
      />
    );
  };

  return (
    <div className="active-goals" data-testid={`active-goals-${testId}`}>
      <GoalsSubheader text="Active Goals" testId={`active-subheader-${testId}`} />
      {longTermGoalContent()}
      {shortTermGoalContent()}
      <ProgramExpectationGoal testId={`program-expectation-${testId}`} />
    </div>
  );
}

ActiveGoalsView.propTypes = {
  memberId: PropTypes.number.isRequired,
  shortTermGoal: PropTypes.object,
  longTermGoal: PropTypes.object,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

ActiveGoalsView.defaultProps = {
  shortTermGoal: {},
  longTermGoal: {},
};

export default ActiveGoalsView;
