import React from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';

function LastLoginRow({ lastLoginDate }) {
  const [lastLoginWithOffset, lastLoginTimeZone] = convertToSupportedTimeZone(lastLoginDate);
  const formattedLastLogin = `${lastLoginWithOffset.format('dddd, MMM. D, YYYY @ h:mm A')} ${lastLoginTimeZone.abbrev}`;

  return (
    <DetailsRow label="Last Login">
      {lastLoginDate ? formattedLastLogin : <span className="italic">No Last Login detected</span>}
    </DetailsRow>
  );
}

LastLoginRow.propTypes = {
  lastLoginDate: PropTypes.string,
};

LastLoginRow.defaultProps = {
  lastLoginDate: '',
};

export default LastLoginRow;
