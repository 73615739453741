import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from '../../shared/Button';

// PAGE COMPONENTS
import LabelContainer from '../shared/LabelContainer';

function ShipmentLabelSidebar(props) {
  const [shownLabels, setShownLabels] = useState(props.labels);
  useEffect(() => {
    setShownLabels(props.labels);
  }, [props.labels]);

  const addLabel = () => {
    const newName = props.labels[props.labels.length - 1].labelName + 1;
    const newLabelObj = { labelName: newName };

    props.labels.push(newLabelObj);
    props.onAddLabel(props.labels, newLabelObj);
  };

  return (
    <div className="flex-1">
      {/* LABELS */}
      <div>
        {shownLabels.map((label) => (
          <LabelContainer
            key={label?.labelName}
            labelName={label?.labelName}
            updateCache={props.updateCache}
            isActive={props.selectedLabel?.labelName === label?.labelName || props.labels.length <= 1}
            onSetLabelAsActive={() => props.onSelectLabel(label)}
          />
        ))}
      </div>

      {props.showNextButtons && (
        <div className="mr-8">
          <div className="mt-6">
            <Button classes="w-full" isPrimary onClick={props.onFinalizeLabel}>
              FINALIZE & PRINT
            </Button>
          </div>

          <div className="mt-2">
            <Button classes="w-full" style={{ borderColor: '#EDB021', color: 'black' }} onClick={addLabel}>
              ADD ANOTHER LABEL
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

ShipmentLabelSidebar.propTypes = {
  labels: PropTypes.array.isRequired,
  selectedLabel: PropTypes.object.isRequired,
  updateCache: PropTypes.bool.isRequired,
  showNextButtons: PropTypes.bool.isRequired,

  // FUNCTIONS
  onAddLabel: PropTypes.func.isRequired,
  onSelectLabel: PropTypes.func.isRequired,
  onFinalizeLabel: PropTypes.func.isRequired,
};

export default ShipmentLabelSidebar;
