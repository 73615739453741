import React, { useEffect } from 'react';

import { IoCheckmarkCircleSharp, IoWarning } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TOAST_TIMEOUT = 6000;

function Toast({ id, header, message, type, onClose, isFirst, hasCloseButton, classes, isSecondaryVariant }) {
  const infoIcon = {
    success: <IoCheckmarkCircleSharp className="w-8 h-8 text-green-600" />,
    warning: <IoWarning className="w-8 h-8 text-warning-500" />,
    error: <MdError className="w-8 h-8 text-error-700" />,
  };
  const toastClassNames = {
    success: classNames('bg-white border-teal-700', {
      'rounded-b-lg': isFirst && !isSecondaryVariant,
      'rounded-lg': !isFirst || isSecondaryVariant,
    }),
    warning: 'bg-yellow-100 rounded-lg border-yellow-400',
    error: 'bg-error-100 rounded-lg border-error-700',
  };
  const toastClassName = classNames('flex items-center gap-6 p-6 border w-full', toastClassNames[type], classes);
  const isSuccess = type === 'success';
  const closeIcon =
    !isSuccess && hasCloseButton && !isSecondaryVariant ? (
      <button type="button" className="flex justify-center items-center w-12 h-12" onClick={() => onClose(id)}>
        <AiOutlineClose className="w-6 h-6" />
      </button>
    ) : null;

  useEffect(() => {
    if (!isSuccess && !isSecondaryVariant) return;

    const timeout = setTimeout(() => {
      onClose(id);
    }, TOAST_TIMEOUT);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [id, onClose, isSuccess, isSecondaryVariant]);

  return (
    <div data-testid="toast" className={toastClassName}>
      {infoIcon[type]}
      <div className="flex flex-col gap-3 flex-1">
        <h5 data-testid="header" className="font-body font-semibold whitespace-pre-wrap">
          {header}
        </h5>
        {message && (
          <p data-testid="message" className="m-0 text-base font-normal">
            {message}
          </p>
        )}
      </div>
      {closeIcon}
    </div>
  );
}

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning']),
  onClose: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  classes: PropTypes.string,
  isSecondaryVariant: PropTypes.bool,
};

Toast.defaultProps = {
  message: null,
  type: 'success',
  isFirst: false,
  hasCloseButton: true,
  classes: null,
  isSecondaryVariant: false,
};

export default Toast;
