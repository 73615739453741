import React from 'react';
import PropTypes from 'prop-types';

function GoalsSubheader({ text, classes, testId }) {
  return (
    <h3 className={`goals-subheader ${classes}`} data-testid={testId}>
      {text}
    </h3>
  );
}

GoalsSubheader.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
  testId: PropTypes.string.isRequired,
};

GoalsSubheader.defaultProps = {
  classes: '',
};

export default GoalsSubheader;
