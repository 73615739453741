import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from '../../member_summary/InfoCard';
import DetailsRow from './DetailsRow';
import UsernameRow from './rows/UsernameRow';
import LastLoginRow from './rows/LastLoginRow';
import AppInfoRow from './rows/AppInfoRow';
import AccountActivationRow, { ACCOUNT_ACTIVATION_STATUS } from './rows/AccountActivationRow';
import { MemberPropTypes } from './types';
import NullishPlaceholder from '../../shared/NullishPlaceholder';

function AccountInformation({
  data: { username, deviceInfo, appInfo, lastLogin, accountActivation } = {},
  member,
  authenticityToken,
}) {
  return (
    <InfoCard
      heading="Account Information"
      classNames="bg-white border-none shadow-sm"
      childrenClassNames="divide-y divide-gray-300"
    >
      <UsernameRow username={username} />
      <DetailsRow label="Device Info">
        <NullishPlaceholder placeholder="No Device Info detected">{deviceInfo}</NullishPlaceholder>
      </DetailsRow>
      <AppInfoRow appInfo={appInfo} />
      <LastLoginRow lastLoginDate={lastLogin} />
      <AccountActivationRow
        accountActivation={accountActivation}
        member={member}
        authenticityToken={authenticityToken}
      />
    </InfoCard>
  );
}

AccountInformation.propTypes = {
  member: MemberPropTypes.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    deviceInfo: PropTypes.string.isRequired,
    appInfo: PropTypes.shape({
      version: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      isLatest: PropTypes.bool.isRequired,
    }).isRequired,
    lastLogin: PropTypes.string.isRequired,
    accountActivation: PropTypes.shape({
      status: PropTypes.oneOf(Object.values(ACCOUNT_ACTIVATION_STATUS)).isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

AccountInformation.defaultProps = {
  data: {
    username: undefined,
    deviceInfo: undefined,
    appInfo: undefined,
    lastLogin: undefined,
    accountActivation: undefined,
  },
};

export default AccountInformation;
