import React from 'react';
import PropTypes from 'prop-types';
import PDFFile from './shared/PDFFile';
import GroupsModal from './GroupsModal';

function PDFModal({ isOpen, onClose, file, header }) {
  if (!isOpen) {
    return null;
  }

  return (
    <GroupsModal testID="pdf-modal" isOpen={isOpen} header={header} onClose={onClose} footerButtons={[]}>
      <PDFFile file={file} />
    </GroupsModal>
  );
}

PDFModal.propTypes = {
  file: PropTypes.string.isRequired, // URL | Base64 string | Uint8Array
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

PDFModal.defaultProps = {
  header: undefined,
  isOpen: false,
};

export default PDFModal;
