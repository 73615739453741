const appointmentsHeaders = [
  {
    name: 'Member',
    accessorKey: 'member',
  },
  {
    name: 'Time',
    accessorKey: 'time',
  },
  {
    name: 'Type',
    accessorKey: 'type',
  },
  {
    name: 'Status',
    accessorKey: 'status',
  },
  {
    name: 'Account',
    accessorKey: 'account',
  },
  {
    name: 'App',
    accessorKey: 'app',
  },
  {
    name: 'Consent',
    accessorKey: 'consent',
  },
  {
    name: 'Hipaa',
    accessorKey: 'hipaa',
  },
  {
    name: 'ID',
    accessorKey: 'id',
  },
  {
    name: 'Insurance',
    accessorKey: 'insurance',
  },
  {
    name: 'Pregnant',
    accessorKey: 'pregnant',
  },
  {
    name: 'Provider',
    accessorKey: 'provider',
  },
  {
    name: 'UDS',
    accessorKey: 'uds',
  },
];

export default appointmentsHeaders;
