import { useEffect, useState } from 'react';
import axios from 'axios';

const useFilters = () => {
  const [accountStatuses, setAccountStatuses] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [statesFilters, setStatesFilters] = useState([]);
  const [staffFilters, setStaffFilters] = useState([]);

  const fetchAccountStatues = () => {
    const accountStatues = [
      { value: null, label: 'All Account Status' },
      { value: true, label: 'Account Created' },
      { value: false, label: 'No Account' },
    ];

    setAccountStatuses(accountStatues);
  };

  const fetchAppointmentTypes = async () => {
    try {
      const { data: response } = await axios.get('/staff/appointments/appointment_type_filters');
      setAppointmentTypes(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStateFilters = async () => {
    try {
      const { data: response } = await axios.get('/staff/states/filters');
      setStatesFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStaffFilters = async () => {
    try {
      const { data: response } = await axios.get('/users/staff_filters');
      setStaffFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchAccountStatues();
    fetchAppointmentTypes();
    fetchStateFilters();
    fetchStaffFilters();
  }, []);

  return {
    appointmentTypes,
    accountStatuses,
    statesFilters,
    staffFilters,
  };
};

export default useFilters;
