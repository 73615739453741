/* eslint-disable no-shadow */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { IconButton, TextField, InputAdornment, FormControl, CircularProgress } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

import axios from 'axios';
import MemberTabData from './MemberTabData';
import MemberTableHeaderData from './MemberTableHeaderData';

import Tabs from '../../Tabs';
import Table from '../../Table';
import Filters from '../../Filters';

import useFetchData from './fetch-member-data';
import useFilterData from './fetch-member-filter-data';

function Main(props) {
  const { authenticityToken } = props.authenticityToken;

  const [tabData, setTabData] = useState([]);
  const [activeTab, setTab] = useState(MemberTabData[0]);

  const memberSearchRef = useRef(null);

  // FILTER DATA
  const {
    statesData,
    facilitiesData,
    officeManagerData,
    staffData,
    daysData,
    empanelmentGroups,
    empanelmentGroupTypeData,
    empanelmentSecondaryGroupTypeData,
  } = useFilterData();
  const [currentStatesFilters, setCurrentStatesFilters] = useState([]);
  const [currentFacilitiesFilters, setCurrentFacilitiesFilters] = useState([]);
  const [currentOfficeManagerFilters, setCurrentOfficeManagerFilters] = useState([]);
  const [currentCounselorFilters, setCurrentCounselorFilters] = useState([]);
  const [currentProviderFilters, setCurrentProviderFilters] = useState([]);
  const [currentDaysFilters, setCurrentDaysFilters] = useState([]);
  const [currentEmpanelmentGroups, setEmpanelmentGroups] = useState([]);
  const [currentGroupTypeFilters, setCurrentGroupTypeFilters] = useState([]);
  const [currentSecondaryGroupTypeFilters, setCurrentSecondaryGroupTypeFilters] = useState([]);

  // TABLE DATA
  const { all, notEmpaneled, empaneled, flagged, isLoaded } = useFetchData();
  const [loaded, setLoaded] = useState(false);
  const [_currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState({});
  const [notEmpaneledData, setNotEmpaneledData] = useState({});
  const [empaneledData, setEmpaneledData] = useState({});
  const [flaggedData, setFlaggedData] = useState({});

  const [searchText, setSearchText] = useState('');

  useMemo(() => {
    setTabData(all);

    setAllData(all);
    setNotEmpaneledData(notEmpaneled);
    setEmpaneledData(empaneled);
    setFlaggedData(flagged);

    setEmpanelmentGroups(empanelmentGroups);

    setLoaded(isLoaded);
  }, [all, notEmpaneled, empaneled, flagged, isLoaded, empanelmentGroups]);

  // *********************************
  // TABLE UPDATE DATA
  // *********************************
  const setUpdatedTabData = (response) => {
    if (activeTab.key === 'allMembers') {
      setAllData(response);
      setTabData(response);
    } else if (activeTab.key === 'notEmpaneled') {
      setNotEmpaneledData(response);
      setTabData(response);
    } else if (activeTab.key === 'isEmpaneled') {
      setEmpaneledData(response);
      setTabData(response);
    } else if (activeTab.key === 'isFlagged') {
      setFlaggedData(response);
      setTabData(response);
    }
  };

  const tabChange = async (tab) => {
    if (tab.key === 'allMembers') {
      setTabData(allData);
    } else if (tab.key === 'notEmpaneled') {
      setTabData(notEmpaneledData);
    } else if (tab.key === 'isEmpaneled') {
      setTabData(empaneledData);
    } else if (tab.key === 'isFlagged') {
      setTabData(flaggedData);
    }

    setTab(tab);
  };

  // *********************************
  // FETCH FUNCTIONS FOR FILTER / PAGINATION
  // *********************************
  const fetchData = async (pageNumber) => {
    const state_ids = currentStatesFilters.map((state) => state.value);
    const location_ids = currentFacilitiesFilters.map((facility) => facility.value);
    const provider_ids = currentProviderFilters.map((provider) => provider.value);
    const counselor_ids = currentCounselorFilters.map((counselor) => counselor.value);
    const office_manager_ids = currentOfficeManagerFilters.map((om) => om.value);
    const days = currentDaysFilters.map((day) => day.value);
    const group_types = currentGroupTypeFilters.map((group) => group.value);
    const secondary_group_types = currentSecondaryGroupTypeFilters.map((group) => group.value);

    const updatedParams = {
      page: pageNumber,
      is_empaneled: activeTab.key === 'isEmpaneled' ? true : null,
      not_empaneled: activeTab.key === 'notEmpaneled' ? true : null,
      is_flagged: activeTab.key === 'isFlagged' ? true : null,
      search_text: searchText,
      state_ids: state_ids.includes('*') ? null : state_ids,
      location_ids: location_ids.includes('*') ? null : location_ids,
      provider_ids: provider_ids.includes('*') ? null : provider_ids,
      counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
      office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
      days: days.includes('*') ? null : days,
      group_type_ids: group_types,
      secondary_group_type_ids: secondary_group_types,
    };

    setCurrentPage(pageNumber);

    try {
      axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
      const { data: response } = await axios.get('/staff/empanelment_members', {
        params: updatedParams,
      });

      if (activeTab.key === 'allMembers') {
        response.json_data = allData.json_data.concat(response.json_data);

        setUpdatedTabData(response);
      } else if (activeTab.key === 'notEmpaneled') {
        response.json_data = notEmpaneledData.json_data.concat(response.json_data);

        setUpdatedTabData(response);
      } else if (activeTab.key === 'isEmpaneled') {
        response.json_data = empaneledData.json_data.concat(response.json_data);

        setUpdatedTabData(response);
      } else if (activeTab.key === 'isFlagged') {
        response.json_data = flaggedData.json_data.concat(response.json_data);

        setUpdatedTabData(response);
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchFilteredData = async (type, selectedFilters) => {
    setLoaded(false);
    const statesFilters = type === 'states' ? selectedFilters : currentStatesFilters;
    const facilitiesFilters = type === 'facilities' ? selectedFilters : currentFacilitiesFilters;
    const officeManagerFilters = type === 'office_manager' ? selectedFilters : currentOfficeManagerFilters;
    const counselorFilters = type === 'counselor' ? selectedFilters : currentCounselorFilters;
    const providerFilters = type === 'provider' ? selectedFilters : currentProviderFilters;
    const daysFilters = type === 'days' ? selectedFilters : currentDaysFilters;
    const groupTypeFilters = type === 'group_type' ? selectedFilters : currentGroupTypeFilters;
    const secondaryGroupTypeFilters =
      type === 'secondary_group_type' ? selectedFilters : currentSecondaryGroupTypeFilters;

    const state_ids = statesFilters.map((state) => state.value);
    const location_ids = facilitiesFilters.map((facility) => facility.value);
    const office_manager_ids = officeManagerFilters.map((om) => om.value);
    const counselor_ids = counselorFilters.map((counselor) => counselor.value);
    const provider_ids = providerFilters.map((provider) => provider.value);
    const days = daysFilters.map((day) => day.value);
    const group_types = groupTypeFilters.map((group) => group.value);
    const secondary_group_types = secondaryGroupTypeFilters.map((group) => group.value);

    const updatedParams = {
      page: 1,
      is_empaneled: activeTab.key === 'isEmpaneled' ? true : null,
      not_empaneled: activeTab.key === 'notEmpaneled' ? true : null,
      is_flagged: activeTab.key === 'isFlagged' ? true : null,
      search_text: searchText,
      state_ids: state_ids.includes('*') ? null : state_ids,
      location_ids: location_ids.includes('*') ? null : location_ids,
      office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
      counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
      provider_ids: provider_ids.includes('*') ? null : provider_ids,
      days: days.includes('*') ? null : days,
      group_type_ids: group_types,
      secondary_group_type_ids: secondary_group_types,
    };

    setCurrentPage(1);

    try {
      axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
      const { data: response } = await axios.get('/staff/empanelment_members', {
        params: updatedParams,
      });

      setUpdatedTabData(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
    setLoaded(true);
  };

  const fetchSearchData = async (ev) => {
    if (ev.key !== 'Enter' && ev.type !== 'click') {
      return;
    }

    setLoaded(false);

    const searchTextValue = ev.key === 'Enter' ? ev.target.value : memberSearchRef.current.value;

    const state_ids = currentStatesFilters.map((state) => state.value);
    const location_ids = currentFacilitiesFilters.map((facility) => facility.value);
    const office_manager_ids = currentOfficeManagerFilters.map((om) => om.value);
    const counselor_ids = currentCounselorFilters.map((counselor) => counselor.value);
    const provider_ids = currentProviderFilters.map((provider) => provider.value);
    const days = currentDaysFilters.map((day) => day.value);
    const group_types = currentGroupTypeFilters.map((group) => group.value);
    const secondary_group_types = currentSecondaryGroupTypeFilters.map((group) => group.value);

    if (activeTab.key === 'allMembers') {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            search_text: searchTextValue,
            state_ids: state_ids.includes('*') ? null : state_ids,
            location_ids: location_ids.includes('*') ? null : location_ids,
            office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
            counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
            provider_ids: provider_ids.includes('*') ? null : provider_ids,
            days: days.includes('*') ? null : days,
            group_type_ids: group_types,
            secondary_group_type_ids: secondary_group_types,
          },
        });

        setAllData(response);
        setTabData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    } else if (activeTab.key === 'notEmpaneled') {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            search_text: searchTextValue,
            not_empaneled: true,
            state_ids: state_ids.includes('*') ? null : state_ids,
            location_ids: location_ids.includes('*') ? null : location_ids,
            office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
            counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
            provider_ids: provider_ids.includes('*') ? null : provider_ids,
            days: days.includes('*') ? null : days,
            group_type_ids: group_types,
            secondary_group_type_ids: secondary_group_types,
          },
        });

        setNotEmpaneledData(response);
        setTabData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    } else if (activeTab.key === 'isEmpaneled') {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            search_text: searchTextValue,
            is_empaneled: true,
            state_ids: state_ids.includes('*') ? null : state_ids,
            location_ids: location_ids.includes('*') ? null : location_ids,
            office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
            counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
            provider_ids: provider_ids.includes('*') ? null : provider_ids,
            days: days.includes('*') ? null : days,
            group_type_ids: group_types,
            secondary_group_type_ids: secondary_group_types,
          },
        });

        setEmpaneledData(response);
        setTabData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    } else if (activeTab.key === 'isFlagged') {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            search_text: searchTextValue,
            is_flagged: true,
            state_ids: state_ids.includes('*') ? null : state_ids,
            location_ids: location_ids.includes('*') ? null : location_ids,
            office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
            counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
            provider_ids: provider_ids.includes('*') ? null : provider_ids,
            days: days.includes('*') ? null : days,
            group_type_ids: group_types,
            secondary_group_type_ids: secondary_group_types,
          },
        });

        setFlaggedData(response);
        setTabData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    }
    setSearchText(searchTextValue);
    setLoaded(true);
  };

  // *********************************
  // ACTIONS UPDATE
  // *********************************
  const updateRowAction = async (_updateType, newRow) => {
    if (_updateType === 'edit') {
      const updatedRows = empaneledData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      empaneledData.json_data = updatedRows;

      setEmpaneledData(empaneledData);
    }

    if (_updateType === 'approve') {
      if (!empaneledData.json_data.filter((r) => r.id === newRow.id).length > 0) {
        empaneledData.json_data.push(newRow);
      }

      setEmpaneledData(empaneledData);
    }

    if (_updateType === 'flag') {
      const updatedAll = allData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      allData.json_data = updatedAll;
      setAllData(allData);

      const updatedEmpaneled = empaneledData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      empaneledData.json_data = updatedEmpaneled;
      setEmpaneledData(empaneledData);

      if (!flaggedData.json_data.filter((r) => r.id === newRow.id).length > 0) {
        flaggedData.json_data.push(newRow);
      }

      setFlaggedData(flaggedData);
    }

    if (activeTab.key === 'allMembers') {
      const updatedRows = allData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      allData.json_data = updatedRows;
      tabData.json_data = updatedRows;

      setAllData(allData);
      setTabData(tabData);
    } else if (activeTab.key === 'notEmpaneled') {
      const updatedRows = notEmpaneledData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      notEmpaneledData.json_data = updatedRows;
      tabData.json_data = updatedRows;

      setNotEmpaneledData(notEmpaneledData);
      setTabData(tabData);
    } else if (activeTab.key === 'isEmpaneled') {
      const updatedRows = empaneledData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      empaneledData.json_data = updatedRows;
      tabData.json_data = updatedRows;

      setEmpaneledData(empaneledData);
      setTabData(tabData);
    } else if (activeTab.key === 'isFlagged') {
      const updatedRows = flaggedData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
      flaggedData.json_data = updatedRows;
      tabData.json_data = updatedRows;

      setFlaggedData(flaggedData);
      setTabData(tabData);
    }
  };

  const handleClearInput = (ev) => {
    memberSearchRef.current.value = '';
    setSearchText('');
    fetchSearchData(ev);
  };

  const testID = 'membersMainEmp';

  return (
    <div data-testid={testID}>
      <div className="flex">
        <h2 data-testid={`title-${testID}`} className="font-body font-semibold mt-2 mb-6 empanelment-members-title">
          Empanelment Members
        </h2>
      </div>

      <div className="react-filters flex">
        <Filters
          label="Office Managers"
          type="office_manager"
          data={officeManagerData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentOfficeManagerFilters}
          testID={`OMs-${testID}`}
        />

        <Filters
          label="Counselors"
          type="counselor"
          data={staffData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentCounselorFilters}
          testID={`counselors-${testID}`}
        />

        <Filters
          label="Providers"
          type="provider"
          data={staffData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentProviderFilters}
          testID={`providers-${testID}`}
        />

        <div className="react-inline">
          <FormControl
            sx={{
              width: '100%',
              background: 'white',
              marginTop: '25px',
            }}
          >
            <TextField
              id="member-search-input"
              inputRef={memberSearchRef}
              onKeyPress={fetchSearchData}
              fullWidth
              label="Search Members"
              variant="outlined"
              size="small"
              color="info"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {memberSearchRef.current?.value && (
                      <IconButton id="member-search-clear-button" onClick={handleClearInput}>
                        <Clear />
                      </IconButton>
                    )}
                    <IconButton id="member-search-button" onClick={fetchSearchData}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                'data-testid': `membersSearchBar-${testID}`,
              }}
            />
          </FormControl>
        </div>
      </div>

      <div className="react-filters flex">
        <Filters
          label="States"
          type="states"
          data={statesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentStatesFilters}
          testID={`states-${testID}`}
        />

        <Filters
          label="Groups Meeting Facilities"
          type="facilities"
          data={facilitiesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentFacilitiesFilters}
          testID={`facilities-${testID}`}
        />

        <Filters
          label="Days of the Week"
          type="days"
          data={daysData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentDaysFilters}
          testID={`days-${testID}`}
        />
      </div>

      {props.secondaryGroupTypeFeatureFlag && (
        <div className="react-filters flex">
          <Filters
            label="Clinical Model"
            type="group_type"
            data={empanelmentGroupTypeData}
            onFilter={fetchFilteredData}
            stateChanger={setCurrentGroupTypeFilters}
          />

          <Filters
            label="Other Information"
            type="secondary_group_type"
            data={empanelmentSecondaryGroupTypeData}
            onFilter={fetchFilteredData}
            stateChanger={setCurrentSecondaryGroupTypeFilters}
          />
        </div>
      )}

      <Tabs activeTab={activeTab} tabs={MemberTabData} onChange={tabChange} testID={testID} />

      <div id="empanelment_groups">
        {loaded ? (
          <Table
            activeTab={activeTab.key}
            authenticityToken={props.authenticityToken}
            secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
            currentPage={tabData.current_page}
            tableType="membersTable"
            tableColumnHeaders={MemberTableHeaderData}
            tableRows={tabData.json_data}
            pageChange={fetchData}
            totalCountRecords={tabData.total_count}
            editModalFilters={currentEmpanelmentGroups}
            updateRows={updateRowAction}
            isLastPage={tabData.is_last_page}
            testID={testID}
          />
        ) : (
          <div data-testid={`noData-${testID}`} className="no-data-loader">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
};

export default Main;
