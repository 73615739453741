import React from 'react';
import PropTypes from 'prop-types';

function CarePlanHeader({ testId }) {
  return (
    <div className="member-careplan-header" data-testid={`header-container-${testId}`}>
      <h1 data-testid={`header-${testId}`}>Care Plan</h1>
    </div>
  );
}

CarePlanHeader.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default CarePlanHeader;
