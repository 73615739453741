import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function StatusCell(props) {
  const [status, setStatusData] = useState(props.status);
  const testID = `statusCell-${props.testID}`;

  useEffect(() => {
    setStatusData(props.status);
  }, [props.status]);

  const ecwInactiveTag = () => (
    <p data-testid={`ecwInactive-${testID}`} className="status-cell m-4 capitalize font-semibold text-gray-700">
      Inactive
    </p>
  );

  const statusLabel = () =>
    status === 'pending' ? (
      <p
        data-testid={`pendingLabel-${testID}`}
        className={`status-cell m-4 capitalize font-semibold text-warning-500 ${status}`}
      >
        {status}
      </p>
    ) : (
      <p
        data-testid={`statusLabel-${testID}`}
        className={`status-cell m-4 capitalize font-semibold text-positive-700 ${status}`}
      >
        {status}
      </p>
    );

  return (
    <td>
      {!props.ecwActive && ecwInactiveTag()}
      {props.ecwActive && statusLabel()}
    </td>
  );
}

StatusCell.propTypes = {
  status: PropTypes.string,
  ecwActive: PropTypes.bool,
  testID: PropTypes.string.isRequired,
};

StatusCell.defaultProps = {
  status: '',
  ecwActive: true,
};

export default StatusCell;
