import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';

const useAssignees = () => {
  const { isLoading, sendRequest, error } = useHttp();
  const [assigneesData, setAssigneesData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await sendRequest('/staff/prescription_tasks/fetch_assignees');
        const { assignees } = response || {};

        setAssigneesData(assignees);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchFilters();
  }, [sendRequest]);

  return { assigneesData, isLoading, error };
};

export default useAssignees;
