import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const AppointmentsURLsContext = createContext({
  memberSummaryUrl: '',
  appointmentFinderUrl: '',
});

export function AppointmentsURLsController({ children, memberSummaryUrl, appointmentFinderUrl }) {
  const memoizedValue = useMemo(
    () => ({ memberSummaryUrl, appointmentFinderUrl }),
    [memberSummaryUrl, appointmentFinderUrl]
  );

  return <AppointmentsURLsContext.Provider value={memoizedValue}>{children}</AppointmentsURLsContext.Provider>;
}

AppointmentsURLsController.propTypes = {
  memberSummaryUrl: PropTypes.string.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useAppointmentsURLs = () => useContext(AppointmentsURLsContext);
