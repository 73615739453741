import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import TextRowLabel from './TextRowLabel';
import TextRowDescription from './TextRowDescription';

function TextRowContainer(props) {
  const borderClasses = props?.hasBorder ? 'border-dotted border-b border-gray-300' : '';

  return (
    <div className={`flex my-2 ${borderClasses}`}>
      <TextRowLabel label={props.label} secondaryLabel={props.secondaryLabel} />

      <TextRowDescription
        text={props.text}
        subtext={props.subtext}
        link={props.link}
        status={props.status}
        isNewlineSubtext={props.isNewlineSubtext}
      />
    </div>
  );
}

TextRowContainer.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,

  // OPTIONAL ATTRIBUTES
  secondaryLabel: PropTypes.string,
  subtext: PropTypes.string,
  link: PropTypes.string,
  status: PropTypes.string,

  // STYLE
  hasBorder: PropTypes.bool,
  isNewlineSubtext: PropTypes.bool,
};

TextRowContainer.defaultProps = {
  // OPTIONAL ATTRIBUTES
  secondaryLabel: null,
  subtext: null,
  link: null,
  status: null,

  // STYLE
  hasBorder: true,
  isNewlineSubtext: false,
};

export default TextRowContainer;
