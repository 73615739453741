export function boldMatch(text, query) {
  if (!query) return text;
  if (!text) return '';

  const regex = new RegExp(`(${query})`, 'gi');
  return text.replace(regex, '<b>$1</b>');
}

export const generateLabel = (option, query) => {
  const { member_name, mrn, healthie_mrn, ecw_id, email, phone_number } = option || {};
  const lowerCaseQuery = query?.trim()?.toLowerCase();
  let baseLabel = `${boldMatch(member_name, lowerCaseQuery)} (MRN: ${boldMatch(mrn, lowerCaseQuery)}`;

  if (healthie_mrn?.toLowerCase().includes(lowerCaseQuery) && healthie_mrn !== mrn) {
    baseLabel += `, HealthieID: ${boldMatch(healthie_mrn, lowerCaseQuery)}`;
  }
  if (ecw_id?.toLowerCase().includes(lowerCaseQuery) && ecw_id !== mrn) {
    baseLabel += `, ECWID: ${boldMatch(ecw_id, lowerCaseQuery)}`;
  }
  if (email?.toLowerCase().includes(lowerCaseQuery)) {
    baseLabel += `, Email: ${boldMatch(email, lowerCaseQuery)}`;
  }
  if (phone_number?.toLowerCase().includes(lowerCaseQuery)) {
    baseLabel += `, Phone: ${boldMatch(phone_number, lowerCaseQuery)}`;
  }

  return `${baseLabel})`;
};

export const filterOption = (option, inputValue) => {
  if (!option?.value) return false;

  const { first_name, last_name, mrn, healthie_mrn, ecw_id, email, phone_number } = option.value || {};

  const textValue = inputValue?.trim()?.toLowerCase();

  return (
    first_name?.toLowerCase().includes(textValue) ||
    last_name?.toLowerCase().includes(textValue) ||
    mrn?.toLowerCase().includes(textValue) ||
    healthie_mrn?.toLowerCase().includes(textValue) ||
    ecw_id?.toLowerCase().includes(textValue) ||
    email?.toLowerCase().includes(textValue) ||
    phone_number?.toLowerCase().includes(textValue)
  );
};
