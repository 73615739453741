import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../shared/Header';
import ProgressTodayContainer from './ProgressTodayContainer';

function ProgressToday(props) {
  return (
    <>
      <Header text="My Progress Today" class="mt-5 text-xl" withNoUnderline />

      <div className="flex mb-10 mt-2">
        <ProgressTodayContainer label="Open High Priority Tasks" data={props.openTasks} />

        <ProgressTodayContainer isCompletedContainer label="Completed Tasks" data={props.completedTasks} />
      </div>
    </>
  );
}

ProgressToday.propTypes = {
  openTasks: PropTypes.object.isRequired,
  completedTasks: PropTypes.object.isRequired,
};

export default ProgressToday;
