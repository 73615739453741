import React from 'react';
import PropTypes from 'prop-types';
import LastSynced from './LastSynced';
import MonthDetailedStats from './MonthDetailedStats';

function HistoricalStats({ data, lastSyncedDate }) {
  return (
    <div>
      <div className="flex gap-4 mb-2">
        {!data || data.length === 0 ? (
          <div className="flex-1">
            <p className="text-center">No data to display</p>
          </div>
        ) : (
          data.map((item) => (
            <div className="flex-1 px-4 py-2 bg-blue-100" key={item.label}>
              <p className="flex gap-1 items-center mb-4">
                <span className="text-sm font-semibold">{item.label}</span>
                <span className="text-xs">{`(last ${item.months?.length} months)`}</span>
              </p>

              <div className="flex flex-col gap-3">
                {item.months?.map((month) => (
                  <MonthDetailedStats key={month.monthLabel} month={month} />
                ))}
              </div>
            </div>
          ))
        )}
      </div>
      {lastSyncedDate && <LastSynced dateTime={lastSyncedDate} />}
    </div>
  );
}

export const historicalStatsPropTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      months: PropTypes.arrayOf(
        PropTypes.shape({
          monthLabel: PropTypes.string.isRequired,
          weeks: PropTypes.arrayOf(PropTypes.bool).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  lastSyncedDate: PropTypes.string,
};

HistoricalStats.propTypes = historicalStatsPropTypes;

HistoricalStats.defaultProps = {
  lastSyncedDate: null,
};

export default HistoricalStats;
