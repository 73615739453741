import PropTypes from 'prop-types';

export const AddressOptionSnakeCasePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  full_address: PropTypes.string.isRequired,
});

export const AddressOptionCamelCasePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fullAddress: PropTypes.string.isRequired,
});

export const MemberLocationsSnakeCasePropTypes = PropTypes.shape({
  location: AddressOptionSnakeCasePropTypes,
  default_facility: AddressOptionSnakeCasePropTypes,
  empanelment_group: AddressOptionSnakeCasePropTypes,
  uds_pickup_facility: AddressOptionSnakeCasePropTypes,
});

export const MemberLocationsCamelCasePropTypes = PropTypes.shape({
  location: AddressOptionCamelCasePropTypes,
  defaultFacility: AddressOptionCamelCasePropTypes,
  empanelmentGroup: AddressOptionCamelCasePropTypes,
  udsPickupFacility: AddressOptionCamelCasePropTypes,
});

export const AttendanceSnakeCasePropTypes = PropTypes.shape({
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
  location: AddressOptionSnakeCasePropTypes,
  custom_location: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
});

export const AttendanceCamelCasePropTypes = PropTypes.shape({
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
  location: AddressOptionCamelCasePropTypes,
  customLocation: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

const PhotoFieldSnakeCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  member_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  photo_sources: PropTypes.arrayOf(PropTypes.string),
});

const PhotoFieldCamelCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  memberId: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  photo_sources: PropTypes.arrayOf(PropTypes.string),
});

export const GroupMemberSnakeCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  mrn: PropTypes.string,
  healthie_mrn: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  date_of_birth: PropTypes.string,
  phone_number: PropTypes.string,
  group_information: PropTypes.shape({
    groupType: PropTypes.string,
    modality: PropTypes.string,
    clinicalModel: PropTypes.string,
    location: PropTypes.string,
    dateTime: PropTypes.string,
    providerName: PropTypes.string,
    counselorName: PropTypes.string,
    officeManagerName: PropTypes.string,
    otherInformation: PropTypes.arrayOf(PropTypes.string),
  }),
  member_summaries: PropTypes.objectOf(
    PropTypes.shape({
      component_data: PropTypes.arrayOf(PropTypes.any).isRequired,
      component_type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      episode_of_care_id: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ),
  member_locations: MemberLocationsSnakeCasePropTypes,
  attendance: AttendanceSnakeCasePropTypes,
  charting_notes: PropTypes.array,
  care_plan_signatures: PropTypes.shape({
    member: PropTypes.bool,
    staff: PropTypes.bool,
  }),
  uds: PropTypes.shape({
    status: PropTypes.bool,
    datetime: PropTypes.string,
  }),
  uds_record: PhotoFieldSnakeCasePropTypes,
  pregnancy_test: PhotoFieldSnakeCasePropTypes,
  fentanyl_test: PhotoFieldSnakeCasePropTypes,
  health_check_in: PropTypes.shape({
    status: PropTypes.bool,
    datetime: PropTypes.string,
    link: PropTypes.string,
  }),
});

export const GroupAppointmentSnakeCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  scheduled_at: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  other_information: PropTypes.array,
  providers: PropTypes.string,
  clinical_model: PropTypes.string,
  counselor_name: PropTypes.string,
  modality: PropTypes.string,
  office_manager_name: PropTypes.string,
  video_meeting_host_url: PropTypes.string,
  video_meeting_client_invitation: PropTypes.string,
  members: PropTypes.arrayOf(GroupMemberSnakeCasePropTypes),
  scheduled_members_count: PropTypes.number,
  absent_members_count: PropTypes.number,
});

export const GroupMemberCamelCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  mrn: PropTypes.string,
  healthieMrn: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  phoneNumber: PropTypes.string,
  groupInformation: PropTypes.shape({
    groupType: PropTypes.string,
    modality: PropTypes.string,
    clinicalModel: PropTypes.string,
    location: PropTypes.string,
    dateTime: PropTypes.string,
    providerName: PropTypes.string,
    counselorName: PropTypes.string,
    officeManagerName: PropTypes.string,
    otherInformation: PropTypes.arrayOf(PropTypes.string),
  }),
  memberSummaries: PropTypes.objectOf(
    PropTypes.shape({
      componentData: PropTypes.arrayOf(PropTypes.any).isRequired,
      componentType: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      episodeOfCareId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      updatedAt: PropTypes.string.isRequired,
    })
  ),
  memberLocations: MemberLocationsCamelCasePropTypes,
  attendance: AttendanceCamelCasePropTypes,
  chartingNotes: PropTypes.array,
  carePlanSignatures: PropTypes.shape({
    member: PropTypes.bool,
    staff: PropTypes.bool,
  }),
  uds: PropTypes.shape({
    status: PropTypes.bool,
    datetime: PropTypes.string,
  }),
  udsRecord: PhotoFieldCamelCasePropTypes,
  pregnancyTest: PhotoFieldCamelCasePropTypes,
  fentanylTest: PhotoFieldCamelCasePropTypes,
  healthCheckIn: PropTypes.shape({
    status: PropTypes.bool,
    datetime: PropTypes.string,
  }),
});

export const GroupAppointmentCamelCasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  scheduledAt: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  otherInformation: PropTypes.array,
  providers: PropTypes.string,
  clinicalModel: PropTypes.string,
  counselorName: PropTypes.string,
  modality: PropTypes.string,
  officeManagerName: PropTypes.string,
  videoMeetingHostUrl: PropTypes.string,
  videoMeetingClientInvitation: PropTypes.string,
  members: PropTypes.arrayOf(GroupMemberCamelCasePropTypes),
  scheduledMembersCount: PropTypes.number,
  absentMembersCount: PropTypes.number,
});

export const IndividualAppointmentSnakeCasePropTypes = PropTypes.shape({
  appointment_id: PropTypes.string.isRequired,
  admissions_packet: PropTypes.bool.isRequired,
  app: PropTypes.bool.isRequired,
  consent: PropTypes.bool.isRequired,
  health_check_in: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    datetime: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  hipaa: PropTypes.bool.isRequired,
  financial_agreement: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  tpo: PropTypes.bool.isRequired,
  id_submitted: PropTypes.bool.isRequired,
  is_intake: PropTypes.bool.isRequired,
  video_meeting_host_url: PropTypes.string,
  video_meeting_client_invitation: PropTypes.string,
  insurance: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    account: PropTypes.bool.isRequired,
    mrn: PropTypes.string,
  }).isRequired,
  other_information: PropTypes.arrayOf(PropTypes.string).isRequired,
  providers: PropTypes.string.isRequired,
  reason: PropTypes.string,
  scheduled_at: PropTypes.string.isRequired,
  status: PropTypes.string,
  type: PropTypes.string.isRequired,
  uds: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    datetime: PropTypes.string.isRequired,
  }).isRequired,
  attendance: AttendanceSnakeCasePropTypes.isRequired,
});

export const IndividualAppointmentCamelCasePropTypes = PropTypes.shape({
  appointmentId: PropTypes.string.isRequired,
  admissionsPacket: PropTypes.bool.isRequired,
  app: PropTypes.bool.isRequired,
  consent: PropTypes.bool.isRequired,
  healthCheckIn: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    datetime: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  hipaa: PropTypes.bool.isRequired,
  financialAgreement: PropTypes.bool.isRequired,
  tpo: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  idSubmitted: PropTypes.bool.isRequired,
  isIntake: PropTypes.bool.isRequired,
  videoMeetingHostUrl: PropTypes.string,
  videoMeetingClientInvitation: PropTypes.string,
  insurance: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    account: PropTypes.bool.isRequired,
    mrn: PropTypes.string,
  }).isRequired,
  otherInformation: PropTypes.arrayOf(PropTypes.string).isRequired,
  providers: PropTypes.string.isRequired,
  reason: PropTypes.string,
  scheduledAt: PropTypes.string.isRequired,
  status: PropTypes.string,
  type: PropTypes.string.isRequired,
  uds: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    datetime: PropTypes.string.isRequired,
  }).isRequired,
  attendance: AttendanceCamelCasePropTypes.isRequired,
});

export const OptionPropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const OptionsPropTypes = PropTypes.arrayOf(OptionPropType);
