import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HIPAA_NOTICE } from './helpers/constants';
import RenderHTML from '../shared/RenderHTML';

function HipaaNotice({ label, isVertical }) {
  return (
    <div
      data-testid="hipaa-notice"
      className={classNames('flex gap-2', {
        'flex-col': isVertical,
      })}
    >
      {label && (
        <p data-testid="hipaa-notice-label" className="text-base font-semibold flex-1">
          {label}
        </p>
      )}
      <RenderHTML html={HIPAA_NOTICE} classNames="flex-1" />
    </div>
  );
}

HipaaNotice.propTypes = {
  label: PropTypes.string,
  isVertical: PropTypes.bool,
};

HipaaNotice.defaultProps = {
  label: null,
  isVertical: true,
};

export default HipaaNotice;
