import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../../shared/Button';
import { copyToClipboard } from '../../../../helpers/utils';
import Tooltip from '../../../shared/Tooltip';

function CopyMeetingLinkButton({ meetingLink, addToast }) {
  const onCopyMeetingLink = async () => {
    if (!meetingLink) return;

    try {
      await copyToClipboard(meetingLink);
      addToast({
        header: 'Meeting link copied to the clipboard',
        type: 'success',
      });
    } catch {
      addToast({
        header: 'Error copying meeting link to clipboard',
        type: 'error',
      });
    }
  };

  const ButtonComponent = (
    <Button
      classes={classNames('w-max', { 'text-gray-500': !meetingLink })}
      onClick={onCopyMeetingLink}
      isDefaultButton={false}
      disabled={!meetingLink}
    >
      Copy Invite Link
    </Button>
  );

  if (!meetingLink) {
    return (
      <Tooltip text="No meeting link available" isSecondaryVariant>
        {ButtonComponent}
      </Tooltip>
    );
  }

  return ButtonComponent;
}

CopyMeetingLinkButton.propTypes = {
  meetingLink: PropTypes.string,
  addToast: PropTypes.func.isRequired,
};

CopyMeetingLinkButton.defaultProps = {
  meetingLink: undefined,
};

export default CopyMeetingLinkButton;
