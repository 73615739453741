import { useEffect } from 'react';
import { getReferrerParams } from '../../../helpers/ReferrerParams';

// eslint-disable-next-line import/prefer-default-export
export const useSuccessParamToast = (addToast) => {
  const { show_success_toast: showSuccessToast = false } = getReferrerParams();

  useEffect(() => {
    if (showSuccessToast) {
      addToast({ type: 'success', header: 'Task status successfully updated' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
