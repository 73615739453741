/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TooltipMui, { tooltipClasses } from '@mui/material/Tooltip';

// COMPONENTS
import InformationIcon from './InformationIcon';

const StyledTooltip = styled(({ className, ...props }) => <TooltipMui {...props} classes={{ popper: className }} />)(
  ({ isSecondaryVariant }) => ({
    [`& .${tooltipClasses.tooltip}`]: isSecondaryVariant
      ? {
          backgroundColor: 'var(--color-gray-900)',
          fontSize: '.75rem',
          color: 'var(--color-white)',
          fontWeight: 400,
          fontFamily: 'Inter,sans-serif',
          padding: '0.25rem 0.5rem',
          marginTop: '0.5rem !important',
        }
      : {
          backgroundColor: '#18535d',
          fontSize: '.875rem',
          color: 'white',
          fontWeight: 800,
          fontFamily: 'Inter,sans-serif',
          padding: '5px 10px 5px 10px',
          marginBottom: '5px !important',
        },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'var(--color-gray-900)',
    },
  })
);

function Tooltip(props) {
  const [isHovering, setIsHovering] = useState(false);

  const placement = props.isSecondaryVariant ? 'bottom' : 'top';

  if (props.children) {
    return (
      <StyledTooltip
        title={props.text}
        isSecondaryVariant={props.isSecondaryVariant}
        placement={placement}
        arrow={props.isSecondaryVariant}
      >
        <span>{props.children}</span>
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip
      open={isHovering}
      title={props.text}
      isSecondaryVariant={props.isSecondaryVariant}
      placement={placement}
      arrow={props.isSecondaryVariant}
    >
      <Button
        sx={{
          '&:hover': { backgroundColor: 'transparent' },
          margin: 0,
          padding: 0,
          ...props.style,
        }}
      >
        <InformationIcon onHover={(value) => setIsHovering(value)} />
      </Button>
    </StyledTooltip>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  isSecondaryVariant: PropTypes.bool,
};

Tooltip.defaultProps = {
  text: '',
  style: {},
  children: null,
  isSecondaryVariant: false,
};

export default Tooltip;
