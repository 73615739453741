import React from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../../shared/hooks/use-http';
import GroupsModal from '../../../GroupsModal';

function CancelAppointmentConfirmationModal({
  isOpen,
  onClose,
  onSuccess,
  appointmentId,
  authenticityToken,
  addToast,
}) {
  const { sendRequest, isLoading } = useHttp();

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(`/staff/appointment_occurrences/${appointmentId}/cancel`, {
        method: 'PATCH',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
      });

      onSuccess();
    } catch (err) {
      addToast({
        header: err.parsedMessage ?? 'Something went wrong. Try again later.',
        type: 'error',
        isError: true,
      });
      window.Sentry.captureException(err);
      onClose();
    }
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Cancel Appointment"
      footerButtons={[
        {
          label: 'No, go back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          label: 'Yes, cancel',
          isPrimary: true,
          onClick: onSubmit,
          isLoading,
        },
      ]}
      size="medium"
      isLoading={isLoading}
    >
      <div className="bg-white px-6 py-4 rounded-md shadow-md">
        <span>Are you sure you want to cancel this appointment? This action cannot be undone.</span>
      </div>
    </GroupsModal>
  );
}

CancelAppointmentConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  appointmentId: PropTypes.number.isRequired,
  addToast: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default CancelAppointmentConfirmationModal;
