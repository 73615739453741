import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { DEFAULT_TIME_FORMAT } from '../helpers/DateFormatter';

function GroupCell(props) {
  const [dateTime, setDateTime] = useState(null);
  const [location, setLocation] = useState('');
  const testID = `groupMeetingCell-${props.testID}`;

  useEffect(() => {
    setDateTime(props.dateTime);
    setLocation(props.location);
  }, [props.dateTime, props.location]);

  function formatDate(date, _showFullDate) {
    if (!date) {
      return null;
    }

    return moment(date).format('dddd, MMMM Do YYYY');
  }

  function formatTime(date) {
    if (!date) {
      return null;
    }

    return moment(date).format(DEFAULT_TIME_FORMAT);
  }

  return (
    <td data-testid={testID} className="p-6">
      <a className="inline-block font-semibold underline" href={`/staff/weekly_groups/${props.weeklyGroupID}`}>
        <div data-testid={`dateTime-${testID}`} className="format-date">
          {formatDate(dateTime)}
        </div>
      </a>
      <div className="flex">
        <div className="format-time">{formatTime(dateTime)}</div>

        {location && (
          <div data-testid={`location-${testID}`} className="format-location">
            {location}
          </div>
        )}
      </div>
    </td>
  );
}

GroupCell.propTypes = {
  testID: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  weeklyGroupID: PropTypes.string.isRequired,
};

export default GroupCell;
