import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import uniq from 'lodash/uniq';
import last from 'lodash/last';
import GroupsModal from './GroupsModal';
import SelectInput from './shared/SelectInput';
import { FormAnswerGroupPropType } from './documentation/helpers/types';
import coSignChartingNoteFormValidationSchema from '../schemas/coSignChartingNote';
import Input from './shared/Input';

function CoSignConfirmationModal({ chartingNotes, isOpen, onClose, onCoSign, isCoSignLoading, member, isCarePlan }) {
  const [isChecked, setIsChecked] = useState(false);

  const authors = useMemo(() => uniq(chartingNotes.map((chartingNote) => chartingNote.filler?.name)), [chartingNotes]);
  const { control, watch, setValue, handleSubmit, register } = useForm({
    defaultValues: {
      availableTemplatesToCoSign: [],
    },
    resolver: yupResolver(coSignChartingNoteFormValidationSchema),
  });

  const formValues = watch();

  const allOption = useMemo(() => ({ label: 'All', value: 'all' }), []);
  const authorOptions = authors.map((author) => ({ value: author, label: author }));
  const templateOptions = [allOption, ...formValues.availableTemplatesToCoSign];

  const prepareTemplateSelectOption = (chartingNote) => ({ label: chartingNote.name, value: chartingNote.id });

  useEffect(() => {
    if (chartingNotes.length) {
      // Set dropdown's default value if there is only one author
      setValue('author', authors.length === 1 ? { label: authors[0], value: authors[0] } : undefined);
    }
  }, [chartingNotes, authors, setValue]);

  useEffect(() => setIsChecked(false), [isOpen]);

  useEffect(() => {
    if (formValues.author) {
      const notes = chartingNotes.filter((chartingNote) => chartingNote.filler?.name === formValues.author?.value);
      const convertedTemplateOptions = notes.map(prepareTemplateSelectOption);
      // Set all templates created by the selected author
      setValue('templates', [allOption]);
      setValue('availableTemplatesToCoSign', convertedTemplateOptions);
    }
  }, [allOption, chartingNotes, formValues.author, setValue]);

  useEffect(() => {
    const moreThanOneTemplateSelected = formValues.templates?.length > 1;
    const isAllSelected = formValues.templates?.some((template) => template.value === allOption.value);
    const isLastEqualToAll = last(formValues.templates)?.value === allOption.value;

    if (moreThanOneTemplateSelected && isLastEqualToAll) {
      setValue('templates', [allOption]);
    } else if (moreThanOneTemplateSelected && isAllSelected) {
      setValue(
        'templates',
        formValues.templates.filter((template) => template.value !== allOption.value)
      );
    }
  }, [allOption, chartingNotes, formValues.templates, setValue]);

  return (
    <GroupsModal
      testID="co-sign-confirmation-needed-modal"
      isOpen={isOpen}
      header={`Co-Sign ${isCarePlan ? 'Care Plan' : 'Note'}`}
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          type: 'submit',
          label: 'Co-Sign',
          isWarning: true,
          isLoading: isCoSignLoading,
          disabled: !isChecked,
          form: isCarePlan ? '' : 'co-sign-form',
          onClick: isCarePlan ? onCoSign : null,
        },
      ]}
      size="medium"
      isLoading={isCoSignLoading}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="py-4 px-4 border-b-2">
          <p className="mb-2 text-lg">
            You are confirming to <span className="font-bold">co-sign</span> {isCarePlan ? 'care plan' : 'notes'} for
            the following {isCarePlan ? '' : 'staff'} member.
          </p>
          <p className="m-0 text-error-700 font-semibold text-lg">This action cannot be undone.</p>
        </div>
        <div className="p-4 border-b">
          {!isCarePlan && (
            <form id="co-sign-form" onSubmit={handleSubmit(onCoSign)}>
              {authors.length === 1 ? (
                <div className="mb-6">
                  <strong>Author: </strong>
                  <span data-testid="co-sign-author-name">{formValues.author?.label || '-'}</span>
                </div>
              ) : (
                <div data-testid="co-sign-author-name">
                  <Controller
                    name="author"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <SelectInput
                          {...field}
                          label="Author"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          placeholder="Select author"
                          options={authorOptions}
                          register={{ ...register(field.name) }}
                          required
                        />
                        {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                      </>
                    )}
                  />
                </div>
              )}
              <div className="mt-2">
                <Controller
                  name="templates"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <SelectInput
                        {...field}
                        isMulti
                        label="Templates"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Select templates"
                        options={templateOptions}
                        register={{ ...register(field.name) }}
                        required
                      />
                      {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error?.message}</p>}
                    </>
                  )}
                />
              </div>
            </form>
          )}
          {isCarePlan && member && (
            <>
              <p className="font-semibold mb-2">Member</p>
              {`${member.name} (${member.mrn})`}
            </>
          )}
        </div>
        <div className="p-4">
          <Input
            type="checkbox"
            name="confirmation-checkbox"
            checked={isChecked}
            value={isChecked.toString()}
            onChange={(e) => setIsChecked(e.target.checked)}
            option="I understand this is a legal representation of my signature."
          />
        </div>
      </div>
    </GroupsModal>
  );
}

CoSignConfirmationModal.propTypes = {
  chartingNotes: PropTypes.arrayOf(FormAnswerGroupPropType),
  isOpen: PropTypes.bool.isRequired,
  isCoSignLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCoSign: PropTypes.func.isRequired,
  member: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      mrn: PropTypes.string.isRequired,
    })
  ),
  isCarePlan: PropTypes.bool,
};

CoSignConfirmationModal.defaultProps = {
  chartingNotes: [],
  member: null,
  isCarePlan: false,
};

export default CoSignConfirmationModal;
