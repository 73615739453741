import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Filters from './Filters';
import { formatDayOfWeek, formatTime } from './DateFormatHelper';

function MembersEditModal(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const toggleOpen = () => setIsOpen((value) => !value);
  const testID = `membersEditModal-${props.testID}`;

  // EMPANELMENT GROUPS DATA
  const [empanelmentGroups, setEmpanelmentGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});

  useEffect(() => {
    setIsOpen(props.isOpen);

    if (
      !props.empanelmentGroups ||
      props.empanelmentGroups?.length === 0 ||
      Object?.keys(props.empanelmentGroups)?.length === 0
    ) {
      return;
    }

    // CREATE EMPANELMENT GROUPS FILTER
    const groupsFilter = props.empanelmentGroups?.map((group) => ({
      group_data: group,
      label: `${formatDayOfWeek(group?.group_date_time)} ${formatTime(group?.group_date_time)} ${group?.location_name} (Counselor: ${group?.counselor_name})`,
      value: group.id,
      provider_name: group?.provider_name,
      counselor_name: group?.counselor_name,
      office_manager_name: group?.office_manager_name,
      modality: group?.empanelment_group_modality_name,
      group_type: group?.empanelment_group_type_name,
      secondary_group_types: group?.empanelment_secondary_group_type_names?.join(', ') || 'N/A',
    }));

    // FIND DEFAULT VALUE
    const currentGroup = groupsFilter.find((group) => group.value === props?.currentGroup?.empanelment_group_id);

    setEmpanelmentGroups(groupsFilter);
    setSelectedGroup(currentGroup);
  }, [props.isOpen, props?.currentGroup?.empanelment_group_id, props.empanelmentGroups]);

  return (
    <>
      {isOpen && (
        <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-2xl sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                {/* HEADER */}
                <h2 data-testid={`title-${testID}`} className="font-bold mb-4">
                  Edit Member Empanelment
                </h2>
                <p>Select a group below to empanel the member with a Counselor and a Provider.</p>

                <form>
                  <dl className="bg-white flex flex-col rounded-b-lg">
                    <dt data-testid={`memberNameMRN-${testID}`} className="font-bold mx-6 pb-2 pt-5">
                      {`${props.currentGroup.member_name} (${props.currentGroup.mrn})`}
                    </dt>

                    <hr className="w-full" />

                    <dt className="font-bold mx-6 pb-2 pt-5">Empanelment Groups</dt>

                    <dd data-testid={`empanelmentGroupsFilter-${testID}`} className="mx-6 pb-3">
                      <Filters
                        isMulti={false}
                        type="empanelmentGroups"
                        defaultValue={selectedGroup || empanelmentGroups[0]}
                        data={empanelmentGroups}
                        stateChanger={(selected) => {
                          setSelectedGroup(selected);
                        }}
                        testID={`empanelmentGroups-${testID}`}
                      />
                    </dd>

                    {(selectedGroup || empanelmentGroups[0]) && (
                      <>
                        <>
                          {/* MODALITY  */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Modality</dt>

                          <dd data-testid={`groupModality-${testID}`} className="mx-6 pb-3">
                            {selectedGroup?.modality || empanelmentGroups[0]?.modality}
                          </dd>

                          <hr className="w-full" />

                          {props.secondaryGroupTypeFeatureFlag ? (
                            <>
                              {/* GROUP TYPE */}
                              <dt className="font-bold mx-6 pb-2 pt-5">Clinical Model</dt>

                              <dd data-testid={`groupType-${testID}`} className="mx-6 pb-3">
                                {selectedGroup?.group_type || empanelmentGroups[0]?.group_type}
                              </dd>

                              <hr className="w-full" />

                              <dt className="font-bold mx-6 pb-2 pt-5">Other Information</dt>

                              <dd data-testid={`secondaryGroupType-${testID}`} className="mx-6 pb-3">
                                {selectedGroup?.secondary_group_types}
                              </dd>

                              <hr className="w-full" />
                            </>
                          ) : (
                            <>
                              {/* GROUP TYPE */}
                              <dt className="font-bold mx-6 pb-2 pt-5">Group Type</dt>

                              <dd data-testid={`groupType-${testID}`} className="mx-6 pb-3">
                                {selectedGroup?.group_type || empanelmentGroups[0]?.group_type}
                              </dd>

                              <hr className="w-full" />
                            </>
                          )}

                          <dt className="font-bold mx-6 pb-2 pt-5">Office Manager</dt>

                          <dd data-testid={`OM-${testID}`} className="mx-6 pb-3">
                            {selectedGroup?.office_manager_name || empanelmentGroups[0]?.office_manager_name}
                          </dd>
                        </>

                        <hr className="w-full" />

                        <dt className="font-bold mx-6 pb-2 pt-5">Counselor</dt>

                        <dd data-testid={`counselor-${testID}`} className="mx-6 pb-3">
                          {selectedGroup?.counselor_name || empanelmentGroups[0]?.counselor_name}
                        </dd>

                        <dt className="font-bold mx-6 pb-2 pt-5">Provider</dt>

                        <dd data-testid={`provider-${testID}`} className="mx-6 pb-3">
                          {selectedGroup?.provider_name || empanelmentGroups[0]?.provider_name}
                        </dd>
                      </>
                    )}
                  </dl>

                  {/* BUTTONS */}
                  <div data-testid={`btns-${testID}`} className="flex mt-4">
                    <button
                      className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                      onClick={toggleOpen}
                      type="button"
                      data-testid={`backBtn-${testID}`}
                    >
                      Back
                    </button>

                    <button
                      className="btn btn--rounded btn--primary flex-1 ml-2"
                      type="button"
                      onClick={() => {
                        props.onContinue(selectedGroup || empanelmentGroups[0]);
                      }}
                      data-testid={`continueBtn-${testID}`}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

MembersEditModal.propTypes = {
  testID: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool,

  isOpen: PropTypes.bool.isRequired,
  currentGroup: PropTypes.object.isRequired,
  empanelmentGroups: PropTypes.array.isRequired,

  // functions
  onContinue: PropTypes.func.isRequired,
};

MembersEditModal.defaultProps = {
  secondaryGroupTypeFeatureFlag: false,
};

export default MembersEditModal;
