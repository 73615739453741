/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Filters from '../Filters';
import TaskFilterButton from './TaskFilterButton';
import PageTableContainer from '../shared/PageTableContainer';

// HOOKS
import useDailyTaskFilters from './hooks/use-daily-task-filters';
import SearchFilter from '../shared/SearchFilter';

import { hasArrayValues } from '../../helpers/DashboardFilterHelpers';
import { getReferrerParams } from '../../helpers/ReferrerParams';

function DailyTaskFilters(props) {
  const { taskPriorityFlag } = props.featureFlags || {};

  const {
    taskStatuses,
    taskTypes,
    dayOptions,
    staff,
    states,
    locations,
    managers,
    dateOptions,
    dueDateOptions,
    timeOptions,
    taskPriorityOptions,
  } = useDailyTaskFilters(props.featureFlags);

  /** Filter States */
  const [updatedFilters, setUpdatedFilters] = useState({});

  // TASK FILTERS
  const [taskStatus, setTaskStatus] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [taskAssignee, setTaskAssignee] = useState(null);
  const [member, setMember] = useState(() => {
    const { member_filter: memberFilter = null } = getReferrerParams();

    return memberFilter;
  });

  // EMPANELMENT FILTERS
  const [manager, setManager] = useState(null);
  const [empaneledStaff, setEmpaneledStaff] = useState(null);
  const [empaneledFacility, setEmpaneledFacility] = useState(null);
  const [empaneledGroupDay, setEmpaneledGroupDate] = useState(null);
  const [empaneledState, setEmpaneledState] = useState(null);
  const [empaneledTime, setEmpaneledTime] = useState(null);
  const [disableEmpaneledTimeFilter, setDisableEmpaneledTimeFilter] = useState(false);

  // TASK DATE FILTERS
  const [taskAssignmentDate, setTaskAssignmentDate] = useState(null);
  const [taskCreationDate, setTaskCreationDate] = useState(null);
  const [taskDueDate, setTaskDueDate] = useState(null);
  const [taskPriority, setTaskPriority] = useState(null);

  const filters = () => {
    const setFilters = {
      taskStatus,
      taskType,
      taskAssignee,
      member,
      manager,
      empaneledStaff,
      empaneledFacility,
      empaneledGroupDay,
      empaneledState,
      empaneledTime,
      taskAssignmentDate,
      taskCreationDate,
      taskDueDate,
      taskPriority,
    };

    setUpdatedFilters(setFilters);
  };

  const parsedUrlFilters = () => {
    const localStorageFilters = window.localStorage.getItem('daily-task-filters');
    const jsonFilters = JSON.parse(localStorageFilters);

    const params = new URLSearchParams(window?.location?.search);
    const urlParams = Object.fromEntries(params);

    const urlFilters = {};

    Object.entries(urlParams).forEach(([key, v]) => {
      if (key === 'taskStatus' && v === 'active') {
        urlFilters[key] = [{ label: 'Active', value: 'ACTIVE' }];
      } else if (key === 'member') {
        setMember(v);
      }
    });

    if (Object.values(urlFilters).every((v) => v === undefined)) {
      return { jsonFilters, isNullAssignee: false };
    }

    const isNullAssignee = !Object.values(urlFilters).every((v) => v === undefined);

    return { urlFilters, isNullAssignee };
  };

  useEffect(() => {
    const { jsonFilters, isNullAssignee } = parsedUrlFilters();

    if (isNullAssignee) {
      setTaskAssignee(null);
    } else {
      setTaskAssignee(jsonFilters?.taskAssignee || [props.currentUser] || null);
    }

    setTaskStatus(jsonFilters?.taskStatus || [props.currentStatus] || null);
    setTaskType(jsonFilters?.taskType);
    setManager(jsonFilters?.manager);

    setEmpaneledStaff(jsonFilters?.empaneledStaff);
    setEmpaneledFacility(jsonFilters?.empaneledFacility);
    setEmpaneledState(jsonFilters?.empaneledState);
    setEmpaneledGroupDate(jsonFilters?.empaneledGroupDay);
    setEmpaneledTime(jsonFilters?.empaneledTime);

    setTaskAssignmentDate(jsonFilters?.taskAssignmentDate);
    setTaskCreationDate(jsonFilters?.taskCreationDate);
    setTaskDueDate(jsonFilters?.taskDueDate);
    setTaskPriority(jsonFilters?.taskPriority);

    filters();
  }, []);

  useEffect(() => {
    filters();
  }, [
    taskStatus,
    taskType,
    taskAssignee,
    member,
    manager,
    empaneledStaff,
    empaneledFacility,
    empaneledState,
    empaneledGroupDay,
    empaneledTime,
    taskAssignmentDate,
    taskCreationDate,
    taskDueDate,
    taskPriority,
  ]);

  useEffect(() => {
    props.onFilterUpdate(updatedFilters);
    window.localStorage.setItem('daily-task-filters', JSON.stringify(updatedFilters));
  }, [updatedFilters]);

  useEffect(() => {
    const bothFiltersHaveValues = hasArrayValues(empaneledFacility) && hasArrayValues(empaneledGroupDay);
    setDisableEmpaneledTimeFilter(!bothFiltersHaveValues);
  }, [empaneledFacility, empaneledGroupDay]);

  const trackFilterClick = (type) => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'filter-selected', type);
  };

  return (
    <div>
      <div className="flex mb-2 flex-row flex-wrap">
        <TaskFilterButton data={taskStatus} label="Task Status" onClear={() => setTaskStatus(null)} />

        <TaskFilterButton data={taskType} label="Task Type" onClear={() => setTaskType(null)} />

        <TaskFilterButton data={taskAssignee} label="Task Assignee" onClear={() => setTaskAssignee(null)} />

        <TaskFilterButton
          isSingleValue
          data={member}
          label="Member"
          testID="member-filter"
          onClear={() => setMember(null)}
        />

        <TaskFilterButton data={manager} label="Manager" onClear={() => setManager(null)} />

        <TaskFilterButton data={empaneledStaff} label="Empaneled Staff" onClear={() => setEmpaneledStaff(null)} />

        <TaskFilterButton data={empaneledState} label="State" onClear={() => setEmpaneledState(null)} />

        <TaskFilterButton
          data={empaneledFacility}
          label="Empaneled Facility"
          onClear={() => {
            setEmpaneledFacility(null);
            setEmpaneledTime(null);
          }}
        />

        <TaskFilterButton
          data={empaneledGroupDay}
          label="Empaneled Group Day"
          onClear={() => {
            setEmpaneledGroupDate(null);
            setEmpaneledTime(null);
          }}
        />

        <TaskFilterButton
          isSingleValue
          data={empaneledTime}
          label="Empaneled Group Time"
          onClear={() => setEmpaneledTime(null)}
        />

        <TaskFilterButton
          isSingleValue
          data={taskAssignmentDate}
          label="Task Assignment Date"
          onClear={() => setTaskAssignmentDate(null)}
        />

        <TaskFilterButton
          isSingleValue
          data={taskCreationDate}
          label="Task Creation Date"
          onClear={() => setTaskCreationDate(null)}
        />

        <TaskFilterButton isSingleValue data={taskDueDate} label="Task Due Date" onClear={() => setTaskDueDate(null)} />

        {taskPriorityFlag && (
          <TaskFilterButton
            isSingleValue
            data={taskPriority}
            label="Task Priority"
            onClear={() => setTaskPriority(null)}
          />
        )}
      </div>

      <PageTableContainer
        show={props.show}
        classes="mb-5"
        style={{ overflow: 'visible', boxShadow: 'none' }}
        tableStyle={{ backgroundColor: 'rgba(255,255,255, 0.5)' }}
      >
        <div className="flex">
          {/* COL 1 */}
          <div className="flex-1 p-4" style={{ borderRight: 'solid 1px #c9cbcc' }}>
            <Filters
              label="Task Status"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              isMulti
              removeAllSelection
              data={taskStatuses?.sort((a, b) => a.label.localeCompare(b.label))}
              value={taskStatus}
              placeholder={taskStatus ? null : 'Search Task Statuses'}
              stateChanger={(updatedState) => {
                setTaskStatus(updatedState);
                trackFilterClick('Task Status');
              }}
            />

            <Filters
              label="Task Type"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={taskTypes?.sort((a, b) => a.label.localeCompare(b.label))}
              value={taskType}
              placeholder={taskType ? null : 'Search Task Types'}
              stateChanger={(updatedState) => {
                setTaskType(updatedState);
                trackFilterClick('Task Type');
              }}
            />

            <Filters
              label="Task Assignee"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={staff}
              value={taskAssignee}
              placeholder={taskAssignee ? null : 'Search Staff'}
              stateChanger={(updatedState) => {
                setTaskAssignee(updatedState);
                trackFilterClick('Task Assignee');
              }}
            />

            <Filters
              label="Manager"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={managers}
              value={manager}
              placeholder={manager ? null : 'Search Reports'}
              stateChanger={(updatedState) => {
                setManager(updatedState);
                trackFilterClick('Manager');
              }}
            />

            <SearchFilter
              label="Member"
              value={member}
              onChange={(searchInput) => {
                setMember(searchInput);
              }}
            />

            <div className="vl" />
          </div>

          {/* COL 2 */}
          <div className="flex-1 p-4" style={{ borderRight: 'solid 1px #c9cbcc' }}>
            <Filters
              label="Empaneled Staff"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={staff}
              value={empaneledStaff}
              placeholder={empaneledStaff ? null : 'Search Staff'}
              stateChanger={(updatedState) => {
                setEmpaneledStaff(updatedState);
                trackFilterClick('Empaneled Staff');
              }}
            />

            <Filters
              label="Empaneled State"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={states}
              value={empaneledState}
              placeholder={empaneledState ? null : 'Search State'}
              stateChanger={(updatedState) => {
                setEmpaneledState(updatedState);
                trackFilterClick('Empaneled State');
              }}
            />

            <Filters
              label="Empaneled Facility"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={locations}
              value={empaneledFacility}
              placeholder={empaneledFacility ? null : 'Search Locations'}
              stateChanger={(updatedState) => {
                setEmpaneledFacility(updatedState);
                trackFilterClick('Empaneled Facility');
              }}
            />

            <Filters
              label="Group Day"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={dayOptions}
              value={empaneledGroupDay}
              placeholder={empaneledGroupDay ? null : 'Search Days'}
              stateChanger={(updatedState) => {
                setEmpaneledGroupDate(updatedState);
                trackFilterClick('Group Day');
              }}
            />

            <Filters
              isMulti={false}
              disabled={disableEmpaneledTimeFilter}
              disabledMessage="Disabled: You need to choose a facility and day before choosing a time"
              label="Group Time"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              removeAllSelection
              data={timeOptions}
              value={empaneledTime}
              placeholder={empaneledTime ? null : 'Search Time'}
              defaultValue={empaneledTime || null}
              stateChanger={(updatedState) => {
                setEmpaneledTime(updatedState);
                trackFilterClick('Group Time');
              }}
            />
          </div>

          {/* COL 3 */}
          <div className="flex-1 m-4">
            <Filters
              label="Task Assignment Date"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              isMulti={false}
              data={dateOptions}
              defaultValue={taskAssignmentDate || (dateOptions ? dateOptions[0] : null)}
              stateChanger={(updatedState) => {
                setTaskAssignmentDate(updatedState);
                trackFilterClick('Task Assignment Date');
              }}
            />

            <Filters
              label="Task Creation Date"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              isMulti={false}
              data={dateOptions}
              defaultValue={taskCreationDate || (dateOptions ? dateOptions[0] : null)}
              stateChanger={(updatedState) => {
                setTaskCreationDate(updatedState);
                trackFilterClick('Task Creation Date');
              }}
            />

            <Filters
              label="Due Date"
              style={{ marginBottom: 20 }}
              classes="mb-2"
              isMulti={false}
              data={dueDateOptions}
              defaultValue={taskDueDate || (dueDateOptions ? dueDateOptions[0] : null)}
              stateChanger={(updatedState) => {
                setTaskDueDate(updatedState);
                trackFilterClick('Due Date');
              }}
            />

            {taskPriorityFlag && (
              <Filters
                label="Task Priority"
                style={{ marginBottom: 20 }}
                classes="mb-2"
                isMulti={false}
                data={taskPriorityOptions}
                placeholder={taskPriority ? null : 'Search Priority'}
                defaultValue={taskPriority || null}
                stateChanger={(updatedState) => {
                  setTaskPriority(updatedState);
                  trackFilterClick('Task Priority');
                }}
              />
            )}
          </div>
        </div>
      </PageTableContainer>
    </div>
  );
}

DailyTaskFilters.propTypes = {
  show: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  currentStatus: PropTypes.object.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

DailyTaskFilters.defaultProps = {
  show: false,
};

export default DailyTaskFilters;
