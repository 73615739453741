import moment from 'moment';
import { RX_STATUS } from './types';

export const formatRxStatus = (status) => {
  switch (status) {
    case RX_STATUS.READY_FOR_REVIEW: {
      return {
        status: 'Ready for Review',
        color: 'text-positive-700',
        weight: 'font-bold',
      };
    }

    case RX_STATUS.RX_SENT: {
      return {
        status: 'Rx Sent',
      };
    }

    case RX_STATUS.RX_NOT_SENT: {
      return {
        status: 'Rx Not Sent',
      };
    }

    case RX_STATUS.RX_CANCELLED: {
      return {
        status: 'Rx Cancelled',
      };
    }

    case RX_STATUS.RX_STAGING_ERROR: {
      return {
        status: 'Rx Staging Error',
      };
    }

    case RX_STATUS.RX_FAILED_TO_SEND: {
      return {
        status: 'Rx Failed to Send',
      };
    }

    default: {
      return {
        status,
      };
    }
  }
};

export const calculateOverdue = (dueDate) => {
  if (!dueDate) {
    return null;
  }

  const today = moment().startOf('day').utc(true);

  const dueDateMoment = moment.parseZone(dueDate).startOf('day').utc(true).startOf('day');

  const daysDifference = today.diff(dueDateMoment, 'days');

  if (daysDifference === 0) {
    return 'Due Today';
  }

  if (daysDifference > 0) {
    return `${Math.abs(daysDifference)}d overdue`;
  }

  return null;
};

export const formatMemberName = (member) => (member ? `${member?.firstName} ${member?.lastName}` : '');

export const generatePrescriptionDetailsUrl = (pageUrl, id) => pageUrl?.replaceAll('{{prescription_task_id}}', id);
