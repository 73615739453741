import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';

// COMPONENTS
import Filters from '../../Filters';
import Header from '../../shared/Header';
import PhoneInput from '../../shared/PhoneInput';

// UDS COMPONENTS
import InputCheckbox from '../shared/InputCheckbox';
import ShipmentInput from '../shared/ShipmentInput';

// HOOKS
import useDefaultShipments from '../hooks/use-default-shipments';
import useSharedFilters from '../../shared/hooks/use-shared-filters';

// HELPERS
import { labelStyle, addressSubtextStyle } from '../shared/styles';

function ShipFrom(props) {
  // CACHED DATA
  const { defaultShipment } = useDefaultShipments();
  const { selectedStaff, location, staffEmail, staffPhone, toEmailShipmentNotifications } = props.labelAddress || {};

  // FETCH DATA
  const { staff, fetchStaff } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchStaff(false);
  }, []);

  // SET DATA
  const [address, setAddress] = useState({});
  useEffect(() => setAddress(props.labelAddress), [props.labelAddress]);

  // SET STAFF DATA
  const [staffSelected, setStaffSelected] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [staffEm, setStaffEmail] = useState('');
  const [staffPh, setStaffPhone] = useState('');

  const [useSenderForFuture, setUseSenderForFuture] = useState(false);
  useEffect(
    () => setUseSenderForFuture(props.labelAddress?.toUseSenderFutureShipments),
    [props.labelAddress?.toUseSenderFutureShipments]
  );

  const setCachedShipmentData = () => {
    // IF CACHED DATA, USE CACHED DATA
    if (location || selectedStaff || staffPhone || staffEmail) {
      setSelectedLocation(location);
      setStaffSelected(selectedStaff);
      setStaffPhone(staffPhone);
      setStaffEmail(staffEmail);
    }
  };

  const setDefaultShipmentData = () => {
    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW SHIPMENT LABEL LOAD
    if (location || selectedStaff || staffPhone || staffEmail) {
      return;
    }
    if (!defaultShipment?.use_default) {
      return;
    }

    // SET CURRENT STATE VISUAL
    setSelectedLocation(defaultShipment?.location);
    setStaffSelected(defaultShipment?.staff);
    setStaffPhone(defaultShipment?.staff_phone);
    setStaffEmail(defaultShipment?.staff_email);

    // SETS CACHED STATE TO ALLOW FOR DATA LOAD DURING 'PURCHASE' FLOW
    address.location = defaultShipment?.location;
    address.selectedStaff = defaultShipment?.staff;
    address.staffPhone = defaultShipment?.staff_phone;
    address.staffEmail = defaultShipment?.staff_email;

    // SEND DATA TO BE SET BUT NOT CACHED
    props.onSetRawData(address);
  };
  useEffect(() => {
    // IF CACHED DATA, USE CACHED DATA
    setCachedShipmentData();

    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW SHIPMENT LABEL LOAD
    setDefaultShipmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    location,
    selectedStaff,
    staffEmail,
    staffPhone,
    defaultShipment.use_default,
    defaultShipment.location,
    defaultShipment.staff,
    defaultShipment.staff_phone,
    defaultShipment.staff_email,
  ]);

  // FETCH STAFF INFO
  const fetchStaffInfo = async (staffId) => {
    const params = { user: { id: staffId } };
    axios
      .get('/users/get_staff_info', { params })
      .then((response) => {
        const data = response?.data;

        setStaffEmail(data?.email);
        setStaffPhone(data?.phone);

        address.staffEmail = data?.email;
        address.staffPhone = data?.phone;

        props.onSetData(address);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  return (
    <>
      <Header text="Ship From" withBottomMargin withNoUnderline />

      <Filters
        style={{ marginBottom: '20px' }}
        label="Staff Name*"
        data={staff}
        placeholder="Search Staff"
        value={staffSelected}
        stateChanger={(selected) => {
          setStaffSelected(selected);

          address.selectedStaff = selected;
          props.onSetData(address);

          setUseSenderForFuture(false);
          fetchStaffInfo(selected?.value);
        }}
        labelStyle={labelStyle}
        isMulti={false}
        removeAllSelection
        error={props.formErrors.selectedStaff}
      />

      <Filters
        label="Address*"
        data={props.locations}
        placeholder="Search Locations"
        value={selectedLocation}
        stateChanger={(selected) => {
          setSelectedLocation(selected);

          address.location = selected;
          props.onSetData(address);

          setUseSenderForFuture(false);
        }}
        labelStyle={labelStyle}
        isMulti={false}
        removeAllSelection
        error={props.formErrors.location}
      />

      <div style={{ ...addressSubtextStyle, marginTop: '9px', marginBottom: '20px' }}>{location?.address}</div>

      <div className="flex">
        {/* STAFF EMAIL */}
        <ShipmentInput
          classes="flex-1"
          style={{ marginRight: '20px' }}
          label="Staff Email Address*"
          value={staffEm}
          stateChanger={(selected) => {
            setStaffEmail(selected);

            address.staffEmail = selected;
            props.onSetData(address);

            setUseSenderForFuture(false);
          }}
          error={props.formErrors.staffEmail}
        />

        {/* STAFF PHONE */}
        <PhoneInput
          classes="flex-1"
          label="Staff Phone Number*"
          value={staffPh}
          stateChanger={(selected) => {
            setStaffPhone(selected);

            address.staffPhone = selected;
            props.onSetData(address);

            setUseSenderForFuture(false);
          }}
          error={props.formErrors.staffPhone}
        />
      </div>

      <InputCheckbox
        show
        isChecked={toEmailShipmentNotifications}
        text="Email shipment notifications"
        onChange={(e) => {
          address.toEmailShipmentNotifications = e.target.checked;
          props.onSetData(address);
        }}
        showTooltip
        tooltipText="They will receive one email with the tracking link and a second with the final delivery status."
      />

      <InputCheckbox
        show
        isChecked={useSenderForFuture}
        text="Use this sender info for future shipments"
        onChange={(e) => {
          const isChecked = e.target.checked;

          address.toUseSenderFutureShipments = isChecked;
          setUseSenderForFuture(isChecked);

          props.onSetData(address);

          defaultShipment.use_default = isChecked;
          if (isChecked) {
            defaultShipment.location = selectedLocation;
            defaultShipment.staff = staffSelected;
            defaultShipment.staff_phone = staffPh;
            defaultShipment.staff_email = staffEm;
          }
          window.localStorage.setItem('default-uds-default-shipment-data', JSON.stringify(defaultShipment));
        }}
      />

      <hr className="w-full my-10" />
    </>
  );
}

ShipFrom.propTypes = {
  labelAddress: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSetData: PropTypes.func.isRequired,
  onSetRawData: PropTypes.func.isRequired,
};

export default ShipFrom;
