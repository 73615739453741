/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// HOOKS
import useData from './hooks/use-data';

// COMPONENT
import IconRender from './IconRender';

function TreatmentConsentCell(props) {
  /**
   * @param data - data is member information regarding treatment consent
   *  example: {
   *    is_treatment_signed: true,
   *    can_sign_treatment_consent: true,
   *    cannot_sign_treatment_message: "Message text here"
   *  }
   * OR
   *  example: {
   *    is_treatment_signed: false,
   *    can_sign_treatment_consent: true,
   *    cannot_sign_treatment_message: nil
   *  }
   *
   * if member CAN sign treatment consent, show icon, otherwise show error message
   */
  const { isLoaded, data } = useData(props);

  return (
    <td>
      <div className="m-4">
        {isLoaded ? (
          <>
            {data?.can_sign_treatment_consent ? (
              /**
               * @param {boolean} data?.is_treatment_signed
               * If true, show checkmark, if false show x
               */
              <IconRender showCheckIcon={data?.is_treatment_signed} />
            ) : (
              /**
               * @param {boolean} - member CANNOT sign treatment consent, show error
               * If true, show 'Error' text and corresponding message
               */
              <>
                <p className="font-semibold text-error-700 mb-0">Error</p>
                <p className="text-xs text-gray-700">{data?.cannot_sign_treatment_message}</p>
              </>
            )}
          </>
        ) : (
          <Skeleton variant="text" width={50} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

TreatmentConsentCell.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default TreatmentConsentCell;
