import React from 'react';
import PropTypes from 'prop-types';

function AttendanceStatus({ status, provider }) {
  const flexClass = 'flex-1';
  const fontClass = status === 'Ad Hoc UDS' ? 'italic text-gray-700' : null;
  const warningClass = status === 'Absent' ? 'text-warning-500 font-semibold' : '';

  return (
    <div className={`${flexClass}`}>
      <div className={`${warningClass} ${fontClass}`}>{status}</div>
      <div className="text-gray-700">{provider}</div>
    </div>
  );
}

AttendanceStatus.propTypes = {
  status: PropTypes.string,
  provider: PropTypes.string,
};

AttendanceStatus.defaultProps = {
  status: '',
  provider: '',
};

export default AttendanceStatus;
