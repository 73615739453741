import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaCircleChevronRight } from 'react-icons/fa6';
import {
  EVERY_STATE_TYPE_APPOINTMENT,
  CHARTING_NOTE_TYPE,
  APPOINTMENT_TYPE,
  PRESCRIPTION_TYPE,
  OUTBOUND_FORM_TYPE,
  CARE_PLAN_TYPE,
} from './constants';
import GeneralCell from '../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../../../helpers/DateFormatter';
import { noteType, NotesCell } from './NotesCell';
import { useTimelineURLs } from './useTimelineURLs';
import { appendReferrerParams } from '../../../helpers/ReferrerParams';
import { getStatusColor } from './helpers';
import './styles.css';
import StickyColumnCell from '../../shared/StickyColumnCell';

function MemberEncounterTableCell({ testID, onClickEdit, row, containerElement }) {
  const {
    id,
    locations,
    status,
    reason,
    staff,
    eventAt,
    type,
    subtype,
    chartingNotes,
    isIndividualAppointment,
    appointmentOccurrenceMemberId,
    buttonDisabled,
  } = row;
  const { appointmentFinderUrl, encounterHistoryUrl, prescriptionDetailsUrl, carePlansUrl } = useTimelineURLs();

  const [dateTimeWithOffset] = convertToSupportedTimeZone(eventAt);
  const date = eventAt ? dateTimeWithOffset.format(DEFAULT_DATE_FORMAT) : '-';
  const time = eventAt ? dateTimeWithOffset.format(DEFAULT_TIME_FORMAT) : '-';

  const linkClasses = buttonDisabled ? 'cursor-not-allowed pointer-events-none' : undefined;

  const buttonColor = buttonDisabled ? 'text-gray-400' : 'text-gray-800';

  const getTypeLabel = () => {
    switch (type) {
      case CHARTING_NOTE_TYPE:
        return 'Document';
      case APPOINTMENT_TYPE:
        return 'Appointment';
      case PRESCRIPTION_TYPE:
        return 'Prescription';
      case OUTBOUND_FORM_TYPE:
        return 'Forms';
      case CARE_PLAN_TYPE:
        return 'Care Plan';
      default:
    }
    return '';
  };

  const getLocationLabel = () => {
    if (locations && locations.length) return locations.map((loc) => loc.name).join(', ');
    if (subtype === EVERY_STATE_TYPE_APPOINTMENT) return 'All locations';
    return 'N/A';
  };
  const locationLabel = getLocationLabel();

  const getLinkUrl = () => {
    switch (type) {
      case APPOINTMENT_TYPE:
        return appendReferrerParams(
          appointmentFinderUrl,
          isIndividualAppointment
            ? {
                appointment_occurrence_member_id: appointmentOccurrenceMemberId,
              }
            : {
                appointment_occurrence_id: id,
              }
        );
      case CHARTING_NOTE_TYPE:
        return appendReferrerParams(encounterHistoryUrl, {
          json_params: JSON.stringify({
            selected_documentation_ids: chartingNotes?.map((note) => note.externalId),
            session_identifier: chartingNotes[0]?.sessionIdentifier || undefined,
            type: CHARTING_NOTE_TYPE,
          }),
        });
      case OUTBOUND_FORM_TYPE:
        return appendReferrerParams(encounterHistoryUrl, {
          json_params: JSON.stringify({
            selected_documentation_ids: chartingNotes?.map((note) => note.externalId),
            type: OUTBOUND_FORM_TYPE,
          }),
        });
      case CARE_PLAN_TYPE:
        return appendReferrerParams(carePlansUrl, {
          activeCarePlanId: id,
        });
      case PRESCRIPTION_TYPE:
        return prescriptionDetailsUrl?.replace('{{prescription_task_id}}', id);
      default:
    }
    return '';
  };

  return (
    <>
      <GeneralCell testID={`date-${testID}`} data={date} />
      <GeneralCell testID={`time-${testID}`} data={time} />
      <GeneralCell testID={`type-${testID}`} data={getTypeLabel()} subText={subtype} />
      <GeneralCell testID={`status-${testID}`} classes={getStatusColor(status, type)} data={status ?? 'N/A'} />
      <NotesCell testID={`notes-${testID}`} onClickEdit={onClickEdit} notes={chartingNotes} />
      <GeneralCell
        testID={`staff-${testID}`}
        containerClasses="fixed-width-cell"
        classes={classNames({ 'text-gray-600': !staff?.length })}
        data={Array.isArray(staff) && staff?.length ? staff.join(', ') : 'N/A'}
      />
      <GeneralCell
        testID={`facility-${testID}`}
        containerClasses="fixed-width-cell"
        classes={classNames({ 'text-gray-600': locationLabel === 'N/A' })}
        data={locationLabel}
      />
      <GeneralCell
        testID={`reason-${testID}`}
        containerClasses="fixed-width-cell"
        classes={classNames({ 'text-gray-600': !reason })}
        data={reason ?? 'N/A'}
      />
      <StickyColumnCell containerElement={containerElement}>
        <a className={linkClasses} href={getLinkUrl()} target="_top">
          <FaCircleChevronRight className={`w-9 h-9 ${buttonColor}`} />
        </a>
      </StickyColumnCell>
    </>
  );
}

export const rowPropType = PropTypes.shape({
  chartingNotes: PropTypes.arrayOf(noteType),
  eventAt: PropTypes.string,
  id: PropTypes.any,
  locations: PropTypes.any,
  reason: PropTypes.any,
  staff: PropTypes.arrayOf(PropTypes.string),
  subtype: PropTypes.any,
  type: PropTypes.string,
  isIndividualAppointment: PropTypes.bool,
  appointmentOccurrenceMemberId: PropTypes.number,
  externalId: PropTypes.string,
  status: PropTypes.string,
  buttonDisabled: PropTypes.bool,
}).isRequired;

MemberEncounterTableCell.propTypes = {
  testID: PropTypes.string,
  onClickEdit: PropTypes.func.isRequired,
  row: rowPropType.isRequired,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

MemberEncounterTableCell.defaultProps = {
  testID: '',
  containerElement: null,
};

export default MemberEncounterTableCell;
