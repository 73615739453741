import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { RiAttachmentLine as AttachmentIcon } from 'react-icons/ri';
import Button from '../../shared/Button';

function PhotoAttachments({ photos }) {
  const [currentlyExpandedPhoto, setCurrentlyExpandedPhoto] = useState(null);

  return (
    <>
      {currentlyExpandedPhoto && (
        <div className="p-2 fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center">
          <div className="flex flex-col max-full max-h-full overflow-auto relative z-50">
            <div className="flex flex-row-reverse z-50">
              <button
                type="button"
                className="close_modal p-2 outline-none focus:outline-none"
                onClick={() => setCurrentlyExpandedPhoto(null)}
              >
                <span className="sr-only">Close</span>
                <CloseIcon className="fill-current text-white" />
              </button>
            </div>
            <div className="flex flex-col gap-2 p-2 z-50">
              <img
                className="object-contain h-1/2-screen"
                src={currentlyExpandedPhoto?.src}
                alt={currentlyExpandedPhoto?.alt}
              />
              <span className="text-white text-center font-bold">{currentlyExpandedPhoto?.alt}</span>
            </div>
          </div>
          <div className="fixed inset-0 z-10 bg-teal-700 opacity-80" />
        </div>
      )}
      <ul className="rounded-md border border-gray-400 divide-y divide-gray-400">
        {photos?.map((photo) => (
          <li key={photo?.label} className="flex justify-between items-center py-3 pr-4 pl-3 gap-4">
            <div className="flex flex-1 gap-2 items-center text-gray-700">
              <AttachmentIcon className="stroke-05 w-4 h-4 fill-current" />
              <span className="text-black">{photo?.label}</span>
            </div>
            <Button onClick={() => setCurrentlyExpandedPhoto(photo)} classes="border-none -my-2">
              View
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
}

PhotoAttachments.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
};

export default PhotoAttachments;
