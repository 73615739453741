import React, { useCallback, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import SelectInput from '../shared/SelectInput';
import useDocumentationTemplates from '../empanelment/member_encounter_history/useDocumentationTemplates';

function ChartingNotesTemplateSelector({
  authenticityToken,
  memberIds,
  createChartingNote,
  onTemplateChange,
  prescriptionTaskId,
  isLoading,
  isDisabled,
  appointmentOccurrenceId,
  sessionIdentifier,
}) {
  const { control, watch, reset, setError } = useForm({
    defaultValues: {
      templates: null,
    },
  });
  const { templates } = useDocumentationTemplates();

  const templatesInput = watch('templates');

  const handleTemplateChange = useCallback(async () => {
    reset();
    const formId = templatesInput?.value;

    if (!formId) return;

    try {
      const response = await createChartingNote('/staff/documentation/charting_notes', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        params: {
          custom_module_form_id: formId,
          member_ids: memberIds,
          prescription_task_id: prescriptionTaskId,
          appointment_occurrence_id: appointmentOccurrenceId || undefined,
          session_identifier: sessionIdentifier,
        },
      });

      onTemplateChange(response);
    } catch (e) {
      setError('templates', { message: 'There was an error when creating note. Please try again later' });
    }
  }, [
    createChartingNote,
    onTemplateChange,
    authenticityToken,
    memberIds,
    templatesInput,
    setError,
    reset,
    prescriptionTaskId,
    appointmentOccurrenceId,
    sessionIdentifier,
  ]);

  useEffect(() => {
    handleTemplateChange();
  }, [handleTemplateChange]);

  const templateOptions = orderBy(templates, (template) => template.name?.toLowerCase(), 'asc').map((template) => ({
    label: template.name,
    value: template.id,
  }));

  return (
    <div data-testid="charting-notes-template-selector" className="flex items-end gap-3">
      <div className="flex-grow">
        <Controller
          name="templates"
          control={control}
          render={({ field, fieldState }) => (
            <SelectInput
              label="Add Templates"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              placeholder="Select a template"
              options={templateOptions}
              error={fieldState.error?.message}
              disabled={isDisabled || isLoading}
            />
          )}
        />
      </div>
    </div>
  );
}

ChartingNotesTemplateSelector.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  createChartingNote: PropTypes.func.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  prescriptionTaskId: PropTypes.number,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  appointmentOccurrenceId: PropTypes.number,
  sessionIdentifier: PropTypes.string,
};

ChartingNotesTemplateSelector.defaultProps = {
  prescriptionTaskId: null,
  isLoading: false,
  isDisabled: false,
  appointmentOccurrenceId: null,
  sessionIdentifier: null,
};

export default ChartingNotesTemplateSelector;
