import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../shared/DatePicker';
import Filters from '../Filters';

function ManualUploadModal({ isOpen, member, onCancel, onContinue, providerFilters }) {
  const [fileToUpload, setFileToUpload] = useState();
  const [memberSignatureDate, setMemberSignatureDate] = useState(new Date());
  const [provider, setProvider] = useState(null);
  const [providerSignatureDate, setProviderSignatureDate] = useState(new Date());
  const [cosignerSignaturePresent, setCosignerSignaturePresent] = useState(false);
  const [cosigner, setCosigner] = useState(null);
  const [cosignerSignatureDate, setCosignerSignatureDate] = useState(new Date());
  const TODAY = new Date();

  const fileChangeHandler = (event) => {
    setFileToUpload(event.target.files[0]);
  };

  const toggleCosignerFieldsVisibility = () => {
    setCosignerSignaturePresent(!cosignerSignaturePresent);
  };

  const handleClick = () => {
    onContinue({
      fileToUpload,
      memberSignatureDate,
      provider,
      providerSignatureDate,
      cosignerSignaturePresent,
      cosigner,
      cosignerSignatureDate,
    });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-2xl sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Upload Care Plan</h2>
                <p>Care Plan must be completed and signed by member and staff.</p>
                <p>*Required field</p>

                <form encType="multipart/form-data" id="care-plan-manual-upload-form">
                  <dl className="bg-white flex flex-col rounded-b-lg">
                    <div>
                      <div>
                        <dt className="font-bold mx-6 pb-2 pt-5">Select a PDF*</dt>
                        <dd className="mx-6 pb-5">
                          <input accept=".pdf" name="care-plan-pdf" onChange={fileChangeHandler} type="file" />
                        </dd>
                      </div>
                    </div>

                    <hr className="w-full" />

                    <div className="flex">
                      <div className="w-1/2">
                        <dt className="font-bold mx-6 pb-2 pt-5">Staff Signer*</dt>

                        <dd className="mx-6 pb-5">
                          <Filters
                            data={providerFilters}
                            defaultValue={provider}
                            isMulti={false}
                            placeholder={provider ? null : 'Select'}
                            removeAllSelection
                            stateChanger={setProvider}
                            type="providers"
                          />
                        </dd>
                      </div>

                      <div className="w-1/2">
                        <dt className="font-bold mx-6 pb-2 pt-5">Date of Staff Signature*</dt>
                        <dd className="mx-6 pb-5">
                          <DatePicker
                            onChange={setProviderSignatureDate}
                            maxDate={TODAY}
                            value={providerSignatureDate}
                          />
                        </dd>
                      </div>
                    </div>

                    <hr className="w-full" />

                    <div className="flex">
                      <div className="w-1/2">
                        <dt className="font-bold mx-6 pb-2 pt-5">Member</dt>

                        <dd className="mx-6 pl-2.5 py-2">{member.name}</dd>
                      </div>

                      <div className="w-1/2">
                        <dt className="font-bold mx-6 pb-2 pt-5">Date of Member Signature*</dt>
                        <dd className="mx-6 pb-5">
                          <DatePicker onChange={setMemberSignatureDate} maxDate={TODAY} value={memberSignatureDate} />
                        </dd>
                      </div>
                    </div>

                    <hr className="w-full" />

                    <div className="flex">
                      <div className="w-1/2">
                        <dt className="font-bold mx-6 pb-2 pt-5">Co-signer signature present?*</dt>
                        <dd className="flex justify-around mx-6 pb-5">
                          <label>
                            <input
                              checked={cosignerSignaturePresent}
                              className="mr-2"
                              name="cosigner-present"
                              onChange={toggleCosignerFieldsVisibility}
                              type="radio"
                              value="Yes"
                            />
                            Yes
                          </label>

                          <label>
                            <input
                              checked={!cosignerSignaturePresent}
                              className="mr-2"
                              name="cosigner-present"
                              onChange={toggleCosignerFieldsVisibility}
                              type="radio"
                              value="No"
                            />
                            No
                          </label>
                        </dd>
                      </div>
                    </div>

                    {cosignerSignaturePresent && (
                      <>
                        <hr className="w-full" />

                        <div className="flex">
                          <div className="w-1/2">
                            <dt className="font-bold mx-6 pb-2 pt-5">Co-signer*</dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                data={providerFilters}
                                defaultValue={cosigner}
                                isMulti={false}
                                placeholder={cosigner ? null : 'Select'}
                                removeAllSelection
                                stateChanger={setCosigner}
                                type="providers"
                              />
                            </dd>
                          </div>

                          <div className="w-1/2">
                            <dt className="font-bold mx-6 pb-2 pt-5">Date of Co-signer Signature*</dt>
                            <dd className="mx-6 pb-5">
                              <DatePicker
                                onChange={setCosignerSignatureDate}
                                maxDate={TODAY}
                                value={cosignerSignatureDate}
                              />
                            </dd>
                          </div>
                        </div>
                      </>
                    )}
                  </dl>

                  <div className="flex mt-4">
                    <button
                      className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                      onClick={onCancel}
                      type="button"
                    >
                      Back
                    </button>

                    <button className="btn btn--rounded btn--primary flex-1 ml-2" onClick={handleClick} type="button">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

ManualUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    external_id: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  providerFilters: PropTypes.array.isRequired,
};

export default ManualUploadModal;
