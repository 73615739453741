import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import enUS from 'rc-picker/lib/locale/en_US';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import CloseIcon from '@mui/icons-material/Close';
import { PiCalendarBlankBold } from 'react-icons/pi';
import { FILTER_TYPES, APPOINTMENT_FILTER_KEY } from './constants';
import Filters from '../../Filters';
import Button from '../../shared/Button';
import './custom-styles.scss';
import FiltersColumn from '../../FiltersColumn';

function EncountersFilters({
  currentlyAppliedFilters,
  testID,
  setFilter,
  typesData,
  appointmentTypesData,
  staffData,
  statusData,
  facilityData,
  documentNameData,
  documentStatusData,
}) {
  const getStateChangeHandler = (type) => (selectedItem) => setFilter(type, selectedItem);
  const showAppointments =
    currentlyAppliedFilters[FILTER_TYPES.type].length === 0 ||
    currentlyAppliedFilters[FILTER_TYPES.type].some((item) => item.value === APPOINTMENT_FILTER_KEY.value);

  const handleTypeChange = (state) => {
    setFilter(FILTER_TYPES.type, state);
    if (state.length === 1 && state?.[0]?.value !== APPOINTMENT_FILTER_KEY.value) {
      setFilter(FILTER_TYPES.facility, []);
      setFilter(FILTER_TYPES.appointmentType, []);
    }
  };

  return (
    <div
      className="border border-gray-300 rounded grid grid-cols-3 divide-x divide-gray-300"
      style={{ backgroundColor: 'rgba(245, 248, 250, 0.5)' }}
    >
      <FiltersColumn>
        <Filters
          label="Type"
          type={FILTER_TYPES.type}
          data={typesData}
          stateChanger={handleTypeChange}
          testID={`type-${testID}`}
          placeholder="Search Type"
          value={currentlyAppliedFilters[FILTER_TYPES.type]}
        />
        <Filters
          label="Appointment Type"
          type={FILTER_TYPES.appointmentType}
          data={appointmentTypesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.appointmentType)}
          testID={`appointment-type-${testID}`}
          placeholder="Search Appointment Type"
          value={currentlyAppliedFilters[FILTER_TYPES.appointmentType]}
          disabled={!showAppointments}
          disabledMessage={null}
        />
        <div className="flex flex-row picker-container">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="filter-label">
            From Date
            <Picker
              type="date"
              locale={enUS}
              generateConfig={momentGenerateConfig}
              value={currentlyAppliedFilters[FILTER_TYPES.fromDate]}
              onChange={getStateChangeHandler(FILTER_TYPES.fromDate)}
              testID={`from-date-${testID}`}
              placeholder="Select date"
              suffixIcon={<PiCalendarBlankBold />}
            />
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="filter-label">
            To Date
            <Picker
              type="date"
              locale={enUS}
              generateConfig={momentGenerateConfig}
              value={currentlyAppliedFilters[FILTER_TYPES.toDate]}
              onChange={getStateChangeHandler(FILTER_TYPES.toDate)}
              testID={`to-date-${testID}`}
              placeholder="Select date"
              suffixIcon={<PiCalendarBlankBold />}
            />
          </label>
          <Button
            type="button"
            aria-label="Clear Date"
            classes="flex w-7 h-7"
            isTertiary
            disabled={!currentlyAppliedFilters[FILTER_TYPES.fromDate] && !currentlyAppliedFilters[FILTER_TYPES.toDate]}
            onClick={(e) => {
              setFilter(FILTER_TYPES.fromDate, null);
              setFilter(FILTER_TYPES.toDate, null);
              e.target.blur();
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </FiltersColumn>
      <FiltersColumn>
        <Filters
          label="Status"
          type={FILTER_TYPES.status}
          data={statusData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.status)}
          testID={`status-${testID}`}
          placeholder="Search Status"
          value={currentlyAppliedFilters[FILTER_TYPES.status]}
        />
        <Filters
          label="Staff"
          type={FILTER_TYPES.staff}
          data={staffData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.staff)}
          testID={`staff-${testID}`}
          placeholder="Search Staff"
          value={currentlyAppliedFilters[FILTER_TYPES.staff]}
        />
        <Filters
          label="Facility"
          type={FILTER_TYPES.facility}
          data={facilityData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.facility)}
          testID={`facility-${testID}`}
          placeholder="Search Facility"
          value={currentlyAppliedFilters[FILTER_TYPES.facility]}
          disabled={!showAppointments}
          disabledMessage={null}
        />
      </FiltersColumn>
      <FiltersColumn>
        <Filters
          label="Document"
          type={FILTER_TYPES.document}
          data={documentNameData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.document)}
          testID={`note-status-${testID}`}
          placeholder="Search Documents"
          value={currentlyAppliedFilters[FILTER_TYPES.document]}
        />
        <Filters
          label="Document Status"
          type={FILTER_TYPES.documentStatus}
          data={documentStatusData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.documentStatus)}
          testID={`document-status-${testID}`}
          placeholder="Search Status"
          value={currentlyAppliedFilters[FILTER_TYPES.documentStatus]}
        />
      </FiltersColumn>
    </div>
  );
}

EncountersFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
  typesData: PropTypes.array.isRequired,
  appointmentTypesData: PropTypes.array.isRequired,
  staffData: PropTypes.array.isRequired,
  statusData: PropTypes.array.isRequired,
  facilityData: PropTypes.array.isRequired,
  documentNameData: PropTypes.array.isRequired,
  documentStatusData: PropTypes.array.isRequired,
};

export default EncountersFilters;
