import React from 'react';
import PropTypes from 'prop-types';

function InputField(props) {
  const { value, label, onChange, required, errorMessage } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const isInvalid = () => required && !value;

  const warningMessage = () => errorMessage || `Please enter a ${label.toLowerCase()}`;

  const inputClass = () => {
    if (isInvalid()) {
      return 'warning';
    }
    return '';
  };

  return (
    <div className="input-field">
      <label>{label}</label>
      <input type="text" value={value} onChange={handleChange} className={inputClass()} />
      {isInvalid() && <p className="warning">{warningMessage()}</p>}
    </div>
  );
}

InputField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

InputField.defaultProps = {
  value: '',
  label: '',
  required: false,
  errorMessage: '',
};

export default InputField;
