import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';

const useFilterData = () => {
  const { isLoading, sendRequest, error } = useHttp();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await sendRequest('/staff/members/fetch_members_list_filters');
        const { states, locations, days, dosages, statuses, tx_weeks, payors, office_manager, staff } = response;

        setFilterData({
          daysData: days,
          statesData: states,
          staffData: staff,
          facilitiesData: locations,
          officeManagerData: office_manager,
          dosagesData: dosages,
          statusesData: statuses,
          txWeeksData: tx_weeks,
          payorsData: payors,
        });
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchFilters();
  }, [sendRequest]);

  return { ...filterData, isLoading, error };
};

export default useFilterData;
