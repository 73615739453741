import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';

const useFilterData = () => {
  const { isLoading, sendRequest, error } = useHttp();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await sendRequest('/staff/prescription_tasks/fetch_filters');
        const { statuses, assignees, locations, providers, reasons, states, due_dates } = response || {};

        setFilterData({
          statusesData: statuses?.map(({ value, status }) => ({
            value,
            label: status,
          })),
          assigneesData: assignees,
          locationsData: locations,
          providersData: providers,
          reasonsData: reasons?.map(({ value, reason }) => ({
            value,
            label: reason,
          })),
          statesData: states,
          dueDatesData: due_dates,
        });
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchFilters();
  }, [sendRequest]);

  return { ...filterData, isLoading, error };
};

export default useFilterData;
