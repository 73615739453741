import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// PACKAGES
import axios from 'axios';

// COMPONENTS
import UdsSupplyFilters from './UdsSupplyFilters';
import TableHeader from '../shared_dashboard/TableHeader';
import TableFooter from '../shared_dashboard/TableFooter';
import PageContainer from '../shared/PageContainer';
import PageTableContainer from '../shared/PageTableContainer';
import CustomPagination from '../shared_dashboard/CustomPagination';
import UdsSupplyTableRow from './UdsSupplyTableRow';
import Button from '../shared/Button';

// HOOKS
import useTableHeaders from './hooks/use-table-headers';
import useUdsSupplies from './hooks/use-uds-supplies';
import UdsSupplyEditModal from './UdsSupplyEditModal';
import SupplyHistoryModal from './SupplyHistoryModal';

function UdsSuppliesTable(props) {
  const { tableHeaders, updateHeaders } = useTableHeaders(props?.featureFlagEdit);
  const { udsSupplies, fetchUdsSupplies } = useUdsSupplies();

  const [rows, setRows] = useState(udsSupplies);
  useEffect(() => {
    setRows(udsSupplies?.rows);
  }, [udsSupplies?.rows]);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState(null);
  const [currentFilters, setCurrentFilters] = useState({});

  const [currentRow, setCurrentRow] = useState({});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const resetTableView = () => {
    updateHeaders(currentSort);
  };

  const handleFilterUpdate = async (filters) => {
    resetTableView();

    setCurrentPage(1);
    setCurrentFilters(filters);

    fetchUdsSupplies(1, { ...filters, sort_by: currentSort });
  };

  const handleSort = (key, direction) => {
    if (!key || !direction) {
      return;
    }

    const sortString = `${key}, ${direction}`;

    setCurrentPage(1);
    setCurrentSort(sortString);
    updateHeaders(sortString);

    fetchUdsSupplies(1, { ...currentFilters, sort_by: sortString });
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    fetchUdsSupplies(newPage, { ...currentFilters, sort_by: currentSort });

    // TRACK page change
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-supplies-dashboard', 'pagination-click');
  };

  const openEditModal = (rowData) => {
    setIsOpenEdit(true);
    setCurrentRow(rowData);

    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-supplies-dashboard', 'edit-preference-open');
  };

  const openSupplyHistoryModal = (rowData) => {
    setIsOpenHistory(true);
    setCurrentRow(rowData);
  };

  const updateRows = (currentId, option, pickupFacility) => {
    const newState = rows?.map((obj) => {
      if (obj.id === currentId) {
        return {
          ...obj,
          uds_supply_option: option?.value,
          uds_pickup_facility_id: pickupFacility?.value,
          uds_pickup_facility_name: pickupFacility?.label,
        };
      }
      return obj;
    });

    setRows(newState);
  };

  const updateRowsPickupInformation = (currentId, formatted_row) => {
    const newState = rows?.map((obj) => {
      if (obj.id === currentId) {
        return {
          ...formatted_row,
        };
      }
      return obj;
    });

    setRows(newState);
  };

  const updateUdsSupplyOption = (currentId, option, pickupFacility) => {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    const params = {
      uds_supply: {
        id: currentId,
        option: option?.value,
        uds_pickup_facility_id: pickupFacility?.value,
      },
    };

    axios
      .put('/staff/uds_supply/update_uds_supply_option', params)
      .then((_response) => {
        updateRows(currentId, option, pickupFacility);
        setIsOpenEdit(false);
        setCurrentRow({});
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data);
        window.Sentry.captureException(error);
      });

    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-supplies-dashboard', 'edit-preference-saved');
  };

  const editPickupInformation = (editedRow) => {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    axios
      .put('/staff/uds_supply/edit_pickup_information', { edited_row: editedRow })
      .then((response) => {
        updateRowsPickupInformation(editedRow.id, response.data?.formatted_row);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data);
        window.Sentry.captureException(error);
      });

    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-supplies-dashboard', 'edit-pickup-information-saved');
  };

  return (
    <>
      <UdsSupplyFilters
        onFilterUpdate={(updatedFilters) => {
          handleFilterUpdate(updatedFilters);
        }}
      />

      <PageContainer style={{ marginTop: '40px' }}>
        {props.featureFlagEdit && (
          <div className="flex">
            <div className="flex mb-2 mr-4 ml-auto">
              <Button
                isPrimary
                classes="ml-auto"
                onClick={() => {
                  window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'open-pickup-form');
                  props.setShowPickupPage();
                }}
              >
                New Office Pickup
              </Button>
            </div>

            <div className="flex mb-2">
              <Button
                isPrimary
                classes="ml-auto"
                onClick={() => {
                  window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'open-shipment-form');
                  props.setShowShipPage();
                }}
              >
                New Shipment
              </Button>
            </div>
          </div>
        )}

        <PageTableContainer>
          <TableHeader headers={tableHeaders} onHeaderClick={(key, direction) => handleSort(key, direction)} />

          <tbody>
            {rows?.map((data) => (
              <UdsSupplyTableRow
                key={data.id}
                data={data}
                locations={props.locations}
                onEditRow={(row) => openEditModal(row)}
                onEditPickupData={editPickupInformation}
                featureFlagEdit={props.featureFlagEdit}
                openSupplyHistoryModal={(row) => openSupplyHistoryModal(row)}
              />
            ))}

            <TableFooter
              firstRangeValue={udsSupplies?.firstRangeValue}
              lastRangeValue={udsSupplies?.lastRangeValue}
              totalCount={udsSupplies?.totalCount}
            />
          </tbody>
        </PageTableContainer>

        <CustomPagination
          show={udsSupplies?.totalPages > 1}
          page={currentPage}
          totalPages={udsSupplies?.totalPages}
          onPageChange={(_event, newPage) => {
            resetTableView();
            handleChangePage(newPage);
          }}
        />
      </PageContainer>

      {isOpenEdit && (
        <UdsSupplyEditModal
          isOpen={isOpenEdit}
          data={currentRow}
          errorMessage={errorMessage}
          onSave={(currentId, option, pickupFacility) => {
            updateUdsSupplyOption(currentId, option, pickupFacility);
          }}
          onCancel={() => {
            setIsOpenEdit(false);
            setCurrentRow({});
            setErrorMessage(null);
            window?.Matomo?.getAsyncTracker()?.trackEvent('uds-supplies-dashboard', 'edit-preference-cancel');
          }}
        />
      )}

      <SupplyHistoryModal
        isOpen={isOpenHistory}
        data={currentRow}
        onClose={() => {
          setIsOpenHistory(false);
        }}
      />
    </>
  );
}

UdsSuppliesTable.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  featureFlagEdit: PropTypes.bool.isRequired,
  setShowPickupPage: PropTypes.func.isRequired,
  setShowShipPage: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
};

export default UdsSuppliesTable;
