import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import Button from './shared/Button';

function GroupsModal({
  isOpen,
  header,
  subHeader,
  children,
  onClose,
  footerButtons,
  size,
  isLoading,
  testID,
  isScrollable,
}) {
  const isSmall = size === 'small';

  const getFooterButton = ({ label, ...footerButton }) => (
    <Button
      key={label}
      classes="flex-1"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...footerButton}
      disabled={isLoading || footerButton.disabled}
      style={{ marginBottom: 0 }}
    >
      {label}
    </Button>
  );

  const modalClassName = classNames('relative flex items-center mx-auto my-2 groups-modal', { [size]: true });

  const modalContentClassName = classNames('relative flex-1 flex flex-col text-black bg-blue-100 p-6 rounded-3xl', {
    'groups-modal-content': !isScrollable,
    'gap-4': !isSmall,
    'gap-6': isSmall,
  });

  return (
    isOpen &&
    createPortal(
      <>
        <div data-testid={testID} className="fixed inset-x-2 inset-y-0 overflow-y-auto z-20">
          <div className={modalClassName}>
            <div className={modalContentClassName}>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <h2 data-testid="header" className="font-body font-bold">
                    {header}
                  </h2>
                  {onClose && (
                    <button
                      data-testid="close-button"
                      type="button"
                      className="flex justify-center items-center w-9 h-9"
                      onClick={onClose}
                    >
                      <AiOutlineClose className="w-5 h-5" />
                    </button>
                  )}
                </div>
                {subHeader && <span className="text-base">{subHeader}</span>}
              </div>
              {children}
              <div className="flex gap-5">{footerButtons.map(getFooterButton)}</div>
            </div>
          </div>
        </div>
        <div className="fixed inset-0 bg-teal-700 opacity-80 z-10" />
      </>,
      document.body
    )
  );
}

GroupsModal.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  footerButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isPrimary: PropTypes.bool,
      isSecondary: PropTypes.bool,
      isTertiary: PropTypes.bool,
      isWarning: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  testID: PropTypes.string,
  isLoading: PropTypes.bool,
  isScrollable: PropTypes.bool,
};

GroupsModal.defaultProps = {
  isOpen: false,
  onClose: null,
  size: 'medium',
  testID: 'groups-modal',
  isLoading: false,
  isScrollable: true,
  subHeader: null,
};

export default GroupsModal;
