import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from './Header';

// ICONS
import UploadIcon from './UploadIcon';

function UploadComponent(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
    setIsFilePicked(true);

    props.onChange(file);
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: '#E0E3E5',
        borderRadius: '20px',
        padding: '20px',
        marginLeft: '20px',
        marginBottom: '30px',
        display: 'flex',
        border: props.pdfErrorState ? '2px solid #CD5334' : null,
      }}
    >
      <div style={{ margin: 'auto' }}>
        <UploadIcon style={{ margin: 'auto' }} />

        <div style={{ textAlign: 'center' }}>
          <Header
            text="Upload a File"
            class="hover:none"
            style={{ color: '#888888', fontSize: '20px', marginBottom: '10px' }}
          />
          <div style={{ marginBottom: '30px' }}> Files Supported: PDF </div>

          {/* CHOOSE FILE */}
          <input
            type="file"
            id="files"
            name="file"
            className="hidden"
            accept="application/pdf"
            onChange={changeHandler}
          />

          <label htmlFor="files" className="btn" style={{ marginBottom: '20px' }}>
            Select file
          </label>

          {isFilePicked && <div>{selectedFile.name}</div>}

          {props.pdfErrorState && !isFilePicked && <div style={{ color: '#CD5334' }}>Document must be Selected</div>}
        </div>
      </div>
    </div>
  );
}

UploadComponent.propTypes = {
  pdfErrorState: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
};

UploadComponent.defaultProps = {
  pdfErrorState: false,
};

export default UploadComponent;
