import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultValues = {
  canEditLockedRx: false,
};

const PrescriptionsQueueContext = createContext(defaultValues);

export function PrescriptionsQueueController({ canEditLockedRx, children }) {
  const memoizedValue = useMemo(() => ({ canEditLockedRx }), [canEditLockedRx]);

  return <PrescriptionsQueueContext.Provider value={memoizedValue}>{children}</PrescriptionsQueueContext.Provider>;
}

PrescriptionsQueueController.propTypes = {
  canEditLockedRx: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const usePrescriptionsQueueContext = () => useContext(PrescriptionsQueueContext);
