import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../../shared/hooks/use-http';
import GroupsModal from '../../../GroupsModal';

const WAIT_TIME_IN_SECONDS = 3;

function RemoveMemberConfirmationModal({
  isOpen,
  onClose,
  onSuccess,
  appointmentId,
  memberId,
  authenticityToken,
  addToast: addExternalToast,
}) {
  const { sendRequest, isLoading } = useHttp();
  const [didRemoveSuccessfully, setDidRemoveSuccessfully] = useState(false);

  const timerRef = useRef(null);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  const onSubmit = async (event) => {
    event.preventDefault();

    setDidRemoveSuccessfully(false);

    try {
      await sendRequest(`/staff/appointment_occurrences/${appointmentId}/update_appointment_occurrence_members`, {
        method: 'PATCH',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: {
          appointment_occurrence_members: [{ member_id: memberId, status: 'deleted' }],
        },
      });

      setDidRemoveSuccessfully(true);

      timerRef.current = setTimeout(() => {
        onSuccess();
      }, WAIT_TIME_IN_SECONDS * 1000);
    } catch (err) {
      addExternalToast({
        header: err.parsedMessage ?? 'Something went wrong. Try again later.',
        type: 'error',
        isError: true,
      });
      window.Sentry.captureException(err);
      onClose();
    }
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Remove from Appointment"
      footerButtons={[
        {
          label: 'No, go back',
          isSecondary: true,
          onClick: onClose,
          disabled: didRemoveSuccessfully,
        },
        {
          label: !didRemoveSuccessfully ? 'Yes, remove' : 'Redirect now',
          isPrimary: true,
          onClick: !didRemoveSuccessfully ? onSubmit : onSuccess,
          isLoading,
        },
      ]}
      size="medium"
      isLoading={isLoading}
    >
      <div className="bg-white px-6 py-4 rounded-md shadow-md">
        {!didRemoveSuccessfully ? (
          <span>Are you sure you want to remove member from this appointment? This action cannot be undone.</span>
        ) : (
          <div className="flex flex-col">
            <span className="text-green-600 font-semibold">Member was successfully removed from the appointment.</span>
            <span>You will be redirected to appointment finder in {WAIT_TIME_IN_SECONDS} seconds...</span>
          </div>
        )}
      </div>
    </GroupsModal>
  );
}

RemoveMemberConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  appointmentId: PropTypes.number.isRequired,
  memberId: PropTypes.number.isRequired,
  addToast: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default RemoveMemberConfirmationModal;
