import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { PiCaretRightBold } from 'react-icons/pi';

// COMPONENTS
import PageContainer from '../../shared/PageContainer';

// HELPERS
import { activeLabelContainer, labelContainer, labelStyle } from './styles';

// HOOKS
import useCachedShipmentData from '../hooks/use-cached-shipment-data';

function LabelContainer(props) {
  // CACHED DATA
  const { labelAddress, labelCompleted, fetchCachedShipmentData } = useCachedShipmentData(props.labelName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchCachedShipmentData(props.labelName), [props.updateCache, props.labelName]);

  // SUB LABEL
  const [members, setMembers] = useState('');
  useEffect(() => {
    setMembers(labelAddress?.members?.map((m) => m.label));
  }, [labelAddress]);

  const [subLabel, setSubLabel] = useState('');
  useEffect(() => {
    if (labelCompleted) {
      setSubLabel(members);
    } else {
      setSubLabel('In Progress');
    }
  }, [labelCompleted, members]);

  const label = () => (
    <div>
      <div style={labelStyle}>Label {props.labelName}</div>

      <div>{subLabel}</div>
    </div>
  );

  return (
    <PageContainer style={props.isActive ? activeLabelContainer : labelContainer}>
      <div
        className="flex"
        onClick={() => {
          props.onSetLabelAsActive();
        }}
        aria-hidden="true"
      >
        {label()}

        {props.isActive && <PiCaretRightBold className="ml-auto" />}
      </div>
    </PageContainer>
  );
}

LabelContainer.propTypes = {
  labelName: PropTypes.string,
  isActive: PropTypes.bool,
  updateCache: PropTypes.bool.isRequired,
  onSetLabelAsActive: PropTypes.func.isRequired,
};

LabelContainer.defaultProps = {
  labelName: '',
  isActive: false,
};

export default LabelContainer;
