import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import useHttp from '../../shared/hooks/use-http';
import Table from '../../Table';
import { TABLE_HEADERS, TABLE_HEADERS_WITH_ACTIONS } from './helpers/constants';
import EditFormExtensionsModal from './EditFormExtensionsModal';
import FormTemplateNameFilter from './FormTemplateNameFilter';

const OFFSET = 10;

const parseExpressions = (expressions) =>
  JSON.stringify(
    expressions.reduce(
      (prev, current) => ({
        ...prev,
        [current.extension_type]: current.custom_expression,
      }),
      {}
    )
  ).slice(1, -1);

function Main({ authenticityToken, editPermission }) {
  const [formTemplates, setFormTemplates] = useState();
  const [isEditExtensionModalOpen, setIsEditExtensionModalOpen] = useState(false);
  const [currentFormTemplateId, setCurrentFormTemplateId] = useState();
  const [nameFilter, setNameFilter] = useState('');
  const { isLoading: isGetTemplatesLoading, sendRequest: getTemplates } = useHttp();

  const fetchFormTemplates = useCallback(
    async (page = 1, formNameFilter = '') => {
      const response = await getTemplates('/staff/documentation/document_templates', {
        params: {
          paginate: true,
          offset: (page - 1) * OFFSET,
          keywords: formNameFilter,
        },
      });

      const data = response.json_data.map((template) => ({
        ...template,
        custom_modules: template.custom_modules.map((customModule) => ({
          ...customModule,
          expressions: customModule.expressions ? parseExpressions(customModule.expressions) : null,
        })),
      }));

      setFormTemplates((prevState) => ({
        page,
        isLastPage: response.is_last_page,
        availableTemplateExtensionExpressions: response.available_template_extension_expressions,
        availableMemberContextExpressions: response.available_member_context_expressions,
        data: page !== 1 ? [...prevState.data, ...data] : data,
        totalCount: response.total_count,
      }));
    },
    [getTemplates]
  );

  useEffect(() => {
    fetchFormTemplates();
  }, [fetchFormTemplates]);

  const handleEditButtonClick = (id) => {
    setIsEditExtensionModalOpen(true);
    setCurrentFormTemplateId(id);
  };

  const handleModalClose = () => {
    setCurrentFormTemplateId(null);
    setIsEditExtensionModalOpen(false);
  };

  const handlePageChange = (page) => {
    fetchFormTemplates(page, nameFilter);
  };

  const handleSearch = (data) => {
    setNameFilter(data.name);
    fetchFormTemplates(1, data.name);
  };

  const updateFormTemplate = (newFormTemplate) => {
    setFormTemplates((prevState) => ({
      ...prevState,
      data: prevState.data.map((formTemplate) =>
        formTemplate.id === newFormTemplate.id ? newFormTemplate : formTemplate
      ),
    }));
  };

  const currentFormTemplate = formTemplates?.data.find((template) => template.id === currentFormTemplateId);
  const tableHeaders = editPermission ? TABLE_HEADERS_WITH_ACTIONS : TABLE_HEADERS;

  return (
    <>
      <div className="mt-3">
        <div className="border-b border-gray-400 pb-2">
          <h2 className="font-body font-semibold mb-1">Forms Templates Extensions</h2>
        </div>
        <div className="mt-4">
          <FormTemplateNameFilter onSearch={handleSearch} />
        </div>
        <div className="mt-4">
          {isGetTemplatesLoading && (
            <div className="no-data-loader">
              <CircularProgress />
            </div>
          )}
          {formTemplates && !isGetTemplatesLoading && (
            <Table
              tableType="healthieFormExtensionsTable"
              authenticityToken={authenticityToken}
              currentPage={formTemplates?.page}
              tableColumnHeaders={tableHeaders}
              tableRows={formTemplates?.data}
              pageChange={handlePageChange}
              isLastPage={formTemplates?.isLastPage}
              totalCountRecords={formTemplates?.totalCount}
              onClickEditRow={handleEditButtonClick}
              displayPaginationStartingElementIndex={false}
              testID="healthie-form-extensions"
              enableActionsColumn={editPermission}
            />
          )}
        </div>
      </div>
      {currentFormTemplate && (
        <EditFormExtensionsModal
          isOpen={isEditExtensionModalOpen}
          onClose={handleModalClose}
          currentFormTemplate={currentFormTemplate}
          authenticityToken={authenticityToken}
          updateFormTemplate={updateFormTemplate}
          templateExtensionExpressions={formTemplates.availableTemplateExtensionExpressions}
          memberContextExpressions={formTemplates.availableMemberContextExpressions}
        />
      )}
    </>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  editPermission: PropTypes.bool,
};

Main.defaultProps = {
  editPermission: false,
};

export default Main;
