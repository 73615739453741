import React from 'react';
import PropTypes from 'prop-types';

function DetailsDiv(props) {
  return (
    <div className={`mt-5 mb-5 ${props?.customClass}`} style={props?.customStyle}>
      {props?.icon}

      <div className="text-md text-gray-700 font-heading font-medium">{props?.header}</div>

      <div className={`${props?.customSubClass}`}>
        <div>{props?.subText}</div>
        <div>{props?.children}</div>
      </div>
    </div>
  );
}

DetailsDiv.propTypes = {
  customClass: PropTypes.string,
  customSubClass: PropTypes.string,
  header: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  customStyle: PropTypes.object,
};

DetailsDiv.defaultProps = {
  customClass: '',
  customSubClass: '',
  icon: null,
  children: null,
  customStyle: {},
};

export default DetailsDiv;
