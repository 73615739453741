import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../shared/Header';
import TaskTableRow from './TaskTableRow';
import DailyTaskFilters from './DailyTaskFilters';
import TableHeader from '../shared_dashboard/TableHeader';
import TableFooter from '../shared_dashboard/TableFooter';
import PageContainer from '../shared/PageContainer';
import PageTableContainer from '../shared/PageTableContainer';
import CustomPagination from '../shared_dashboard/CustomPagination';

// HOOKS
import useTableHeaders from './hooks/use-table-headers';
import useDailyActionLists from './hooks/use-daily-action-lists';

function TaskTable(props) {
  const { tableHeaders, updateHeaders } = useTableHeaders(props.featureFlags);
  const { dailyTasksResponse, staff, fetchDailyActionLists } = useDailyActionLists();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState('priority_rank');
  const [currentFilters, setCurrentFilters] = useState({});

  const [rowData, setRowData] = useState({});
  const [hideColumns, setHideColumns] = useState(false);
  const [hideClass, setHideClass] = useState({});

  const resetTableView = () => {
    setRowData({});
    setHideClass({});
    setHideColumns(false);
    updateHeaders(false, currentSort);
  };

  const handleFilterUpdate = async (filters) => {
    resetTableView();

    setCurrentPage(1);
    setCurrentFilters(filters);

    fetchDailyActionLists(1, { ...filters, sort_by: currentSort });
  };

  const handleSort = (key, direction) => {
    if (!key || !direction) {
      return;
    }

    const sortString = `${key}, ${direction}`;

    setCurrentPage(1);
    setCurrentSort(sortString);
    updateHeaders(false, sortString);

    fetchDailyActionLists(1, { ...currentFilters, sort_by: sortString });
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    fetchDailyActionLists(newPage, { ...currentFilters, sort_by: currentSort });

    // TRACK page change
    window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'pagination-click');
  };

  const updateTableIfReassigned = (isReassigned) => {
    if (currentFilters?.task_assignee && isReassigned) {
      const pageToFetch = dailyTasksResponse?.rows?.length === 1 ? currentPage - 1 : currentPage;
      fetchDailyActionLists(pageToFetch, { ...currentFilters, sort_by: currentSort });
    }

    props.onSendUpdateToProgressContainer();
  };

  return (
    <>
      <Header text="Tasks" class="mt-5" withNoUnderline />

      <DailyTaskFilters
        show={props.showFilterExpandView}
        currentUser={props.currentUser}
        currentStatus={props.currentStatus}
        onFilterUpdate={(updatedFilters) => {
          handleFilterUpdate(updatedFilters);
        }}
        featureFlags={props.featureFlags}
      />

      <PageContainer style={hideClass}>
        <PageTableContainer>
          <TableHeader headers={tableHeaders} onHeaderClick={(key, direction) => handleSort(key, direction)} />

          <tbody>
            {dailyTasksResponse?.rows?.map((data) => (
              <TaskTableRow
                secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
                isSelected={rowData?.id === data?.id}
                key={data.id}
                data={data}
                staff={staff}
                hideColumns={hideColumns}
                authenticityToken={props?.authenticityToken}
                onRowUpdate={updateTableIfReassigned}
                onExpandOpen={(expandOpen, row) => {
                  if (expandOpen) {
                    setHideClass({ maxWidth: '70%' });

                    // Track Expand View Open
                    window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'expand-view-open');
                  } else {
                    setHideClass({});
                  }

                  setRowData(row);
                  setHideColumns(expandOpen);
                  updateHeaders(expandOpen, currentSort, !expandOpen);
                }}
                howToResolveUIFeatureFlag={props.howToResolveUIFeatureFlag}
                featureFlags={props.featureFlags}
              />
            ))}

            <TableFooter
              firstRangeValue={dailyTasksResponse?.firstRangeValue}
              lastRangeValue={dailyTasksResponse?.lastRangeValue}
              totalCount={dailyTasksResponse?.totalCount}
            />
          </tbody>
        </PageTableContainer>

        <CustomPagination
          show={dailyTasksResponse?.totalPages > 1}
          page={currentPage}
          totalPages={dailyTasksResponse?.totalPages}
          onPageChange={(_event, newPage) => {
            resetTableView();
            handleChangePage(newPage);
          }}
        />
      </PageContainer>
    </>
  );
}

TaskTable.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentStatus: PropTypes.object.isRequired,
  showFilterExpandView: PropTypes.bool.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  howToResolveUIFeatureFlag: PropTypes.bool.isRequired,
  onSendUpdateToProgressContainer: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

export default TaskTable;
