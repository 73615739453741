import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IoIosArrowForward as DetailsIcon } from 'react-icons/io';
import Checkbox from '../../shared/CheckBox';
import GeneralCell from '../../GeneralCell';
import GroupMeetingCell from './GroupMeetingCell';
import EmpanelmentCell from './EmpanelmentCell';
import { useMemberURLs } from './useMemberURLs';
import { mdyyyyFromDate } from '../../../helpers/DateFormatter';
import { parsePhoneString } from '../../../helpers/AssortedHelpers';
import StickyColumnCell from '../../shared/StickyColumnCell';

function MembersListTableCell({ testID, row, selected, onRowCheckboxChange, containerElement }) {
  const {
    firstName,
    lastName,
    mrn,
    dateOfBirth,
    phoneNumber,
    email,
    groupAppointmentColumn,
    empanelmentColumn,
    status,
    payer,
    txWeeks,
    healthieMrn,
    rx,
  } = row || {};
  const { memberSummaryUrl } = useMemberURLs();
  const formattedUrl = !healthieMrn ? null : memberSummaryUrl?.replaceAll('{{user_id}}', healthieMrn);

  const formattedPhoneNumber = parsePhoneString(phoneNumber);

  const formattedDOP = mdyyyyFromDate(dateOfBirth);
  const getStatusColor = () => {
    switch (status) {
      case 'In Care':
        return 'text-positive-700';
      case 'Disengaged':
        return 'text-warning-500';
      default:
        return '';
    }
  };

  const onCheckboxToggle = () => onRowCheckboxChange(row);

  const renderMemberName = () => {
    const formattedName = `${lastName}, ${firstName}`;

    if (formattedUrl) {
      return (
        <a href={formattedUrl} target="_top" className="text-teal-700 underline font-semibold">
          {formattedName}
        </a>
      );
    }

    return formattedName;
  };

  return (
    <>
      <GeneralCell
        testID={`checkbox-${testID}`}
        classes="ml-3 mb-2 min-h-160 flex items-center"
        data={<Checkbox checked={selected} onChange={onCheckboxToggle} />}
      />
      <GeneralCell testID={`name-${testID}`} data={renderMemberName()} subText={mrn} classes="mb-2" />
      <GeneralCell testID={`dob-${testID}`} data={formattedDOP} classes="mt-2 mb-2" />
      <GeneralCell
        testID={`contact-info-${testID}`}
        classes="mt-2 mb-2"
        data={
          <div className="flex flex-col gap-3 items-start">
            <span>{email}</span>
            <span>{formattedPhoneNumber}</span>
          </div>
        }
      />
      <GeneralCell
        testID={`tx-${testID}`}
        classes={classNames('mt-2 mb-2', { 'text-gray-700': !txWeeks })}
        data={txWeeks ?? 'N/A'}
      />
      <GeneralCell
        testID={`rx-${testID}`}
        classes="mt-2 mb-2"
        data={
          rx ? (
            <>
              {rx.dosage}
              <br />
              {rx.medication}
              <br />
              {rx.formulation}
            </>
          ) : (
            <span className="text-gray-700">N/A</span>
          )
        }
      />
      <GeneralCell
        testID={`payer-${testID}`}
        classes="mt-2 mb-2"
        data={<span className={classNames({ italic: !payer, 'text-gray-700': !payer })}>{payer ?? 'None'}</span>}
      />
      <GeneralCell
        testID={`status-${testID}`}
        classes="mt-2 mb-2"
        data={<span className={classNames(getStatusColor(), { 'text-gray-700': !status })}>{status ?? 'N/A'}</span>}
      />
      <GroupMeetingCell testID={testID} groupAppointment={groupAppointmentColumn} />
      <EmpanelmentCell testID={testID} empanelment={empanelmentColumn} />
      <StickyColumnCell containerElement={containerElement}>
        <a
          href={formattedUrl}
          target="_top"
          title="Member summary"
          className="w-9 h-9 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 cursor-pointer"
          rel="noreferrer"
          data-testid={`details-url-${testID}`}
        >
          <DetailsIcon className="w-6 h-6 text-white" />
        </a>
      </StickyColumnCell>
    </>
  );
}

MembersListTableCell.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onRowCheckboxChange: PropTypes.func.isRequired,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

MembersListTableCell.defaultProps = {
  containerElement: null,
};

export default MembersListTableCell;
