import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// ICONS
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { expandDiv, leftArrow } from './constants/CustomStyle';

// COMPONENTS
import Tabs from '../Tabs';
import ExpandViewMemberDetails from './ExpandViewMemberDetails';
import ExpandViewTaskDetails from './ExpandViewTaskDetails';
import ExpandViewOtherTaskDetails from './ExpandViewOtherTaskDetails';
import TaskTableActions from './TaskTableActions';

// HOOKS
import useExpandData from './hooks/use-expand-data';

const updatedTabs = (histories = null) => [
  { id: 1, name: 'This Task', key: 'this_task' },
  {
    id: 2,
    name: 'Other Active Tasks',
    key: 'other_tasks',
    count: `${histories?.length || 0}`,
  },
];

function ExpandView(props) {
  const { memberGroup, memberEmpanelment, memberTasks, fetchMemberTasks } = useExpandData(props?.isOpen, props?.data);

  const [tabs, setTabs] = useState([]);
  const [activeTab, setTab] = useState({});
  const tabChange = async (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    const currentTabs = updatedTabs(memberTasks?.other_tasks);
    setTabs(currentTabs);
    setTab(currentTabs[0]);
  }, [memberTasks]);

  const onSubmit = (rowData, isReassigned) => {
    fetchMemberTasks();
    props.onActionChange(rowData, isReassigned);
  };

  return (
    <>
      {props.isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-20" style={{ left: '68%' }}>
          <div
            className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20"
            style={{ left: '1%' }}
          >
            <div
              style={expandDiv}
              className="shadow-md align-bottom bg-blue-100 flex flex-col inline-block relative rounded-lg sm:align-middle sm:max-w-2xl sm:w-full text-left z-20"
            >
              <div className="pb-6 pl-6 pr-6 w-full whitespace-normal">
                {/* BACK ARROW */}
                <ArrowBackIosIcon className="m-2 cursor-pointer" style={leftArrow} onClick={props.onCloseModal} />

                <div className="flex mt-4">
                  {/* ACTIONS ROW */}
                  <TaskTableActions
                    authenticityToken={props?.authenticityToken}
                    data={props?.data}
                    staff={props?.staff}
                    onActionSubmit={onSubmit}
                  />

                  <CloseIcon
                    className="mx-auto float-right flex cursor-pointer"
                    style={{ color: '#7c7c7c', height: 'inherit', marginRight: 0 }}
                    onClick={props.onCloseModal}
                  />
                </div>

                <hr className="w-full mb-5 mt-1" />

                {/*  MEMBER SECTION */}
                <ExpandViewMemberDetails
                  secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
                  data={props?.data}
                  memberGroup={memberGroup}
                  memberEmpanelment={memberEmpanelment}
                />

                <div className="mt-10" />

                {/*  TASK SECTION */}
                <Tabs activeTab={activeTab} tabs={tabs} onChange={tabChange} testID="expand-view-tabs" />

                {activeTab?.key === 'other_tasks' ? (
                  <>
                    {memberTasks?.other_tasks?.map((other_task) => (
                      <>
                        <ExpandViewOtherTaskDetails
                          key={other_task?.task?.id}
                          task={other_task?.task}
                          histories={other_task?.histories}
                          staffName={other_task?.staff_name}
                        />
                        <hr className="w-full mb-1 mt-1" />
                      </>
                    ))}
                  </>
                ) : (
                  <ExpandViewTaskDetails
                    task={memberTasks?.current_task?.task}
                    histories={memberTasks?.current_task?.histories}
                    howToResolveUIFeatureFlag={props.howToResolveUIFeatureFlag}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ExpandView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  onActionChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  howToResolveUIFeatureFlag: PropTypes.bool.isRequired,
};

export default ExpandView;
