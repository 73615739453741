import React from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { mdyyyy, hourMinTimezone } from '../../helpers/DateFormatter';

// COMPONENTS
import DetailsDiv from '../shared/DetailsDiv';

function ExpandViewTaskHistory({ header, history }) {
  return (
    <div className="flex" key={history?.id}>
      <DetailsDiv header={header} subText={history?.description} customClass="container p-0">
        <div className="text-sm font-light text-gray-700">
          {`${mdyyyy(history?.created_at)} ${hourMinTimezone(history?.created_at)}`}
        </div>

        {history?.details && (
          <div style={{ backgroundColor: '#f5f8fa', borderRadius: '5px' }} className="mt-3 p-2">
            {history?.details}
          </div>
        )}
      </DetailsDiv>
    </div>
  );
}

ExpandViewTaskHistory.propTypes = {
  header: PropTypes.string,
  history: PropTypes.object.isRequired,
};

ExpandViewTaskHistory.defaultProps = {
  header: 'TASK HISTORY',
};

export default ExpandViewTaskHistory;
