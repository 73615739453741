import React from 'react';
import PropTypes from 'prop-types';
import { formatShortDayTime } from './DateFormatHelper';
import GroupsModal from './GroupsModal';
import ConfirmationModalList from './ConfirmationModalList';

function AddMemberConfirmationModal({ isOpen, group, member, onClose, onConfirm, isLoading }) {
  const groupDetails = group
    ? [
        {
          label: 'Day/Time',
          value: formatShortDayTime(group.date_time),
        },
        {
          label: 'Next Counselor Group Occurrence',
          value: group.next_counselor_group,
        },
        {
          label: 'Next Provider Group Occurrence',
          value: group.next_provider_group,
        },
        {
          label: 'Group Meeting Facility',
          value: group.location,
        },
        {
          label: 'Clinical Model',
          value: group.clinical_model,
        },
        {
          label: 'Other Information',
          value: group.group_labels,
        },
        {
          label: 'Modality',
          value: group.modality_name,
        },
        {
          label: 'Counselor',
          value: group.counselor_name,
        },
        {
          label: 'Provider',
          value: group.provider_name,
        },
        {
          label: 'Office Manager',
          value: group.office_manager_name,
        },
        {
          label: 'Seat Limit',
          value: group.limit,
        },
      ]
    : [];

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Confirm',
      isPrimary: true,
      onClick: onConfirm,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Are you sure?"
      footerButtons={footerButtons}
      size="small"
      isLoading={isLoading}
    >
      <p className="mb-0">
        Clicking "confirm" will
        <span className="font-bold">{` add ${member} `}</span>
        to all occurrences of this group, and will remove them from all occurrences of their current home group (if
        applicable).
        <span className="font-semibold text-error-700"> This action cannot be undone.</span>
      </p>
      <ConfirmationModalList listItems={groupDetails} />
    </GroupsModal>
  );
}

AddMemberConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  group: {
    date_time: PropTypes.string,
    next_counselor_group: PropTypes.string,
    next_provider_group: PropTypes.string,
    location: PropTypes.string,
    clinical_model: PropTypes.string,
    group_labels: PropTypes.string,
    modality_name: PropTypes.string,
    counselor_name: PropTypes.string,
    provider_name: PropTypes.string,
    office_manager_name: PropTypes.string,
    limit: PropTypes.number,
  }.isRequired,
  member: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddMemberConfirmationModal.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default AddMemberConfirmationModal;
