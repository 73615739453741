import React from 'react';
import PropTypes from 'prop-types';

import { FiTarget } from 'react-icons/fi';

function LongTermGoalIcon({ testId }) {
  return (
    <div className="long-term-goal-icon goal-icon" data-testid={testId}>
      <FiTarget />
    </div>
  );
}

LongTermGoalIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default LongTermGoalIcon;
