import React from 'react';
import PropTypes from 'prop-types';

function NumeratorDenominator(props) {
  const successClass = props?.status === 'SUCCESS' ? 'text-positive-700' : null;
  const warningClass = props?.status === 'WARNING' ? 'text-warning-500' : null;
  const errorClass = props?.status === 'ERROR' ? 'text-error-700' : null;

  return (
    <div className={`mr-1 flex ${successClass} ${warningClass} ${errorClass} ${props.classes}`}>
      <div className="text-2xl font-bold">{props.numerator}</div>
      <div className="mt-auto">/</div>
      <div className="mt-auto">{props.denominator}</div>
    </div>
  );
}

NumeratorDenominator.propTypes = {
  numerator: PropTypes.number.isRequired,
  denominator: PropTypes.number.isRequired,

  // OPTIONAL
  status: PropTypes.string,
  classes: PropTypes.string,
};

NumeratorDenominator.defaultProps = {
  status: null,
  classes: '',
};

export default NumeratorDenominator;
