import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT } from '../../helpers/DateFormatter';

const getNextOccurrenceOfScheduledDate = (scheduleDateTime) => {
  const [currentDate] = convertToSupportedTimeZone();
  const daysToAdd = scheduleDateTime.day() < currentDate.day() ? 7 : 0;

  return currentDate.set({
    day: daysToAdd + scheduleDateTime.day(),
    hour: scheduleDateTime.hour(),
    minute: scheduleDateTime.minute(),
  });
};

const getAvailableTime = (scheduleDateTimes) => {
  if (!scheduleDateTimes?.startTime || !scheduleDateTimes?.endTime) return null;

  const [startDate] = convertToSupportedTimeZone(scheduleDateTimes.startTime);
  const [endDate] = convertToSupportedTimeZone(scheduleDateTimes.endTime);

  const startDateNextOccurrence = getNextOccurrenceOfScheduledDate(startDate).format('ddd: h:mm A');
  const endDateNextOccurrence = getNextOccurrenceOfScheduledDate(endDate).format(DEFAULT_TIME_FORMAT);

  return `${startDateNextOccurrence} - ${endDateNextOccurrence}`;
};

function PrescriberLookupRow({ row }) {
  const { providerFirstName, providerLastName, dateSeen, appointmentType, todaysSchedule, nextDaysSchedule } =
    row || {};

  const prescriber = `${providerFirstName} ${providerLastName}`;

  const todayScheduleTime = getAvailableTime(todaysSchedule);
  const nextDayScheduleTime = getAvailableTime(nextDaysSchedule);
  const formattedDateSeen = dateSeen ? convertToSupportedTimeZone(dateSeen)[0].format('M/D/YY') : null;

  return (
    <>
      <td className="px-3 py-4">{prescriber}</td>
      <td className="px-3 py-4">
        <p className="mb-0">{formattedDateSeen || 'No'}</p>
        {appointmentType && <p className="mb-0 text-sm text-gray-700 mt-0.5">{appointmentType}</p>}
      </td>
      <td className="px-3 py-4">
        <>
          {todayScheduleTime && (
            <>
              <p className="mb-0">{todayScheduleTime}</p>
              <p className={classNames('text-sm text-gray-700 mt-0.5', { 'mb-4': nextDaysSchedule })}>
                {todaysSchedule.locations}
              </p>
            </>
          )}
          {nextDayScheduleTime && (
            <>
              <p className="mb-0">{nextDayScheduleTime}</p>
              <p className="text-sm text-gray-700 mt-0.5 mb-0">{nextDaysSchedule.locations}</p>
            </>
          )}
        </>
        {!todayScheduleTime && !nextDayScheduleTime && (
          <>
            <p className="mb-0">1099 Provider</p>
            <p className="text-sm text-gray-700 italic mt-0.5 mb-0">Hours not available</p>
          </>
        )}
      </td>
    </>
  );
}

PrescriberLookupRow.propTypes = {
  row: PropTypes.shape({
    providerFirstName: PropTypes.string.isRequired,
    providerLastName: PropTypes.string.isRequired,
    dateSeen: PropTypes.string,
    appointmentType: PropTypes.string,
    todaysSchedule: PropTypes.shape({
      dayOfWeek: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      locations: PropTypes.string,
    }),
    nextDaysSchedule: PropTypes.shape({
      dayOfWeek: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      locations: PropTypes.string,
    }),
  }).isRequired,
};

export default PrescriberLookupRow;
