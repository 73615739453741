import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';

import SupplyHistoryTable from './SupplyHistoryTable';

function SupplyHistoryModal(props) {
  return (
    <>
      {props.isOpen && (
        <Modal>
          <h1 className="font-body font-semibold mb-4 text-black">Supply History</h1>
          <div className="bg-white w-full mt-1 py-4 border-b-2 rounded">
            <h5 className="pl-4 font-bold font-body text-black">
              {props.data?.member_name} ({props.data?.member_mrn})
            </h5>
          </div>
          <SupplyHistoryTable
            memberId={props.data?.member_id}
            memberName={props.data?.member_name}
            memberMrn={props.data?.member_mrn}
          />
          <div className="flex mt-4">
            <button
              className="justify-center btn btn--rounded btn--primary flex-1 mr-2"
              onClick={props.onClose}
              type="button"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

SupplyHistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

SupplyHistoryModal.defaultProps = {
  data: {},
};

export default SupplyHistoryModal;
