import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../../GroupsModal';

function PrescriptionsForReviewModal({ isOpen, onClose, onConfirm, isLoading, memberName, memberMrn }) {
  return (
    <GroupsModal
      testID="prescriptions-for-review-modal"
      isOpen={isOpen}
      header="Prescriptions Remaining for Review"
      footerButtons={[
        {
          label: 'Close',
          isSecondary: true,
          onClick: onClose,
        },
        {
          type: 'button',
          label: 'Confirm',
          isPrimary: true,
          onClick: onConfirm,
          isLoading,
        },
      ]}
      size="medium"
      isLoading={isLoading}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="py-6 px-4 border-b-2">
          <p className="mb-0">
            There are still outstanding prescriptions for this member for your review. Please check DoseSpot first
            before closing out this task.
          </p>
        </div>
        <div className="py-6 px-4">
          <p className="font-semibold mb-2">Member</p>
          <p className="mb-0">{`${memberName} (${memberMrn})`}</p>
        </div>
      </div>
    </GroupsModal>
  );
}

PrescriptionsForReviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  memberName: PropTypes.string.isRequired,
  memberMrn: PropTypes.string.isRequired,
};

PrescriptionsForReviewModal.defaultProps = {
  isLoading: false,
};

export default PrescriptionsForReviewModal;
