/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import useHttp from '../../shared/hooks/use-http';
import setRequestParams from './set-request-params';
import { FILTER_TYPES } from './constants';

const usePrescriptions = (filters) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState({});
  const [sortedBy, setSortedBy] = useState({ id: 'due_date', desc: false });
  const functionRef = useRef(() => {});

  const fetchData = useCallback(
    async (pageNumber = 1, overrideData = false) => {
      setCurrentPage(pageNumber);
      const updatedParams = setRequestParams(pageNumber, filters, sortedBy);

      try {
        const response = await sendRequest('/staff/prescription_tasks/get_prescription_tasks', {
          params: updatedParams,
        });

        if (!overrideData) {
          const existingJsonData = get(currentData, 'json_data', []);
          response.json_data = existingJsonData.concat(response.json_data);
        }

        setCurrentData(response);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    },
    [currentData, filters, sendRequest, sortedBy]
  );

  const reloadData = useCallback(() => {
    functionRef.current(1, true);
  }, [functionRef]);

  useEffect(() => {
    functionRef.current = fetchData;
  }, [fetchData]);

  useEffect(() => {
    setCurrentData({});
    functionRef.current(1, true);
  }, [
    setCurrentData,
    filters[FILTER_TYPES.status],
    filters[FILTER_TYPES.assignee],
    filters[FILTER_TYPES.reason],
    filters[FILTER_TYPES.member],
    filters[FILTER_TYPES.state],
    filters[FILTER_TYPES.providers],
    filters[FILTER_TYPES.locations],
    filters[FILTER_TYPES.dueDate],
    filters[FILTER_TYPES.fromTime],
    filters[FILTER_TYPES.toTime],
    filters[FILTER_TYPES.fromDate],
    filters[FILTER_TYPES.toDate],
    sortedBy,
  ]);

  const handleSort = useCallback(
    (id) => {
      setSortedBy((prevSort) => ({ id, desc: prevSort && prevSort.id === id ? !prevSort.desc : true }));
    },
    [setSortedBy]
  );

  return {
    isLoading,
    error,
    currentPage,
    currentData,
    fetchData,
    handleSort,
    sortedBy,
    reloadData,
  };
};

export default usePrescriptions;
