import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// COMPONENTS
import TextRowContainer from '../../shared/TextRowContainer';
import InformationHeader from '../../panel_summary/InformationHeader';
import InformationContainer from '../../panel_summary/InformationContainer';

function ShipmentSummary(props) {
  const [data, setData] = useState([]);
  const [showPrepaidEnvelopes, setShowPrepaidEnvelopes] = useState(null);
  const [showOralSwabs, setShowOralSwabs] = useState(null);

  useEffect(() => {
    setData(props.data || []);
  }, [props.data]);

  const findMemberInformation = () => {
    if (!props?.members) {
      return;
    }
    const params = { id: props?.members[0]?.value };

    axios
      .get('/staff/members/fetch_member_demographics_for_pickup', { params })
      .then((response) => {
        const state = response?.data?.empanelment_state;
        if (state === 'TN' || state === 'KY') {
          setShowPrepaidEnvelopes(true);
        }

        if (state === 'TN') {
          setShowOralSwabs(true);
        }
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.header === 'PACKAGE DETAILS') {
      findMemberInformation();
    }
  }, [props.members, props.header]);

  return (
    <>
      {props.show && (
        <InformationContainer addPadding>
          <InformationHeader
            label={props.header}
            isOpen
            showEdit={props.showEdit}
            showOpenCloseIcon={false}
            onEditClick={props.onEditClick}
          />

          {data.map((row, index) => {
            if (
              (row?.label === 'ORAL SWABS' && !showOralSwabs) ||
              (row?.label === 'PRE-PAID ENVELOPES' && !showPrepaidEnvelopes)
            ) {
              return null;
            }

            return (
              <TextRowContainer
                key={row?.label}
                label={row?.label}
                text={row?.text}
                hasBorder={index + 1 < props.data?.length}
              />
            );
          })}
        </InformationContainer>
      )}
    </>
  );
}

ShipmentSummary.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,

  show: PropTypes.bool,
  showEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
  members: PropTypes.array,
};

ShipmentSummary.defaultProps = {
  show: false,
  showEdit: true,
  onEditClick: () => {},
  members: null,
};

export default ShipmentSummary;
