import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getReferrerParams } from '../../helpers/ReferrerParams';
import CarePlanList from './CarePlanList';
import CarePlanView from './CarePlanView';
import SmallModal from '../shared/SmallModal';

/**
 * Main care plan component. Allows the user to create a new care plan and view all careplans
 * created for a member over time.
 */
function CarePlans({
  authenticityToken,
  carePlans,
  member,
  userId,
  userIsProvider,
  signButtonFlag,
  uploadButtonFlag,
  cosignerFlag,
}) {
  const { activeCarePlanId: initialActiveCarePlanId } = getReferrerParams();
  const [currentCarePlans, setCurrentCarePlans] = useState(carePlans);
  const [carePlanConfigs, setCarePlanConfigs] = useState({});
  const [activeCarePlan, setActiveCarePlan] = useState(
    initialActiveCarePlanId ? Number(initialActiveCarePlanId) : carePlans?.[0]?.id
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const toggleCreateModalOpen = () => setCreateModalOpen(() => !createModalOpen);
  const toggleUpdateModalOpen = () => setUpdateModalOpen(() => !updateModalOpen);

  /**
   * Request a care plan config.
   *
   * @param {*} digest - care plan config digest
   */
  const getCarePlanConfig = useCallback(
    (digest) => {
      axios.get(`/care_plan_configs/by_digest/${digest}`).then((response) => {
        setCarePlanConfigs({ ...carePlanConfigs, [digest]: response.data });
      });
    },
    [carePlanConfigs]
  );

  /**
   * If the care plan state was updated, grab any corresponding care plan configurations.
   */
  useEffect(() => {
    [...new Set(currentCarePlans.map((carePlan) => carePlan.config_digest))].forEach((digest) => {
      if (!Object.prototype.hasOwnProperty.call(carePlanConfigs, digest)) getCarePlanConfig(digest);
    });
  }, [activeCarePlan, carePlanConfigs, currentCarePlans, getCarePlanConfig]);

  /**
   * Create a new care plan for the given member.
   */
  const createCarePlan = (type) => {
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    axios.post(`/staff/members/${member.id}/care_plans?type=${type}`).then((response) => {
      setCurrentCarePlans([response.data, ...currentCarePlans]);
      setActiveCarePlan(response.data.id);
    });
  };

  const createNewCarePlan = () => {
    toggleCreateModalOpen();
    createCarePlan('create');
  };

  const createCarePlanUpdate = () => {
    toggleUpdateModalOpen();
    createCarePlan('update');
  };

  /**
   * Render a view for creating an initial care plan.
   */
  const createInitialCarePlan = () => (
    <div className="text-black w-full mt-5 mb-12 bg-white shadow-md rounded-lg border border-gray-400 p-6">
      <header className="flex-row w-full mb-5">
        <h3 className="font-body font-semibold inline-flex">Create a new, initial care plan</h3>
      </header>
      <button
        type="button"
        onClick={() => createCarePlan('create')}
        className="justify-center btn btn--rounded btn--primary"
      >
        Get Started
      </button>
    </div>
  );

  const createWarningModal = () => (
    <SmallModal>
      <div>
        <h2 className="font-bold mb-4">Are you sure you want to create a new care plan?</h2>
        <p>This will overwrite the member’s current plan, and they will lose access to it in their app.</p>
        <p>This action cannot be undone.</p>
        <button
          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
          onClick={() => toggleCreateModalOpen()}
          type="button"
        >
          Back
        </button>

        <button className="btn btn--rounded btn--primary flex-1 ml-2" type="button" onClick={() => createNewCarePlan()}>
          Continue
        </button>
      </div>
    </SmallModal>
  );

  const updateWarningModal = () => (
    <SmallModal>
      <div>
        <h2 className="font-bold mb-4">Ready to start a Care Plan update?</h2>
        <p>This member still needs an update. This will create a Care Plan for this update window.</p>
        <p>This action cannot be undone.</p>
        <button
          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
          onClick={() => toggleUpdateModalOpen()}
          type="button"
        >
          Back
        </button>

        <button
          className="btn btn--rounded btn--primary flex-1 ml-2"
          type="button"
          onClick={() => createCarePlanUpdate()}
        >
          Continue
        </button>
      </div>
    </SmallModal>
  );

  /**
   * Render the primary layout (if a care plan exists already).
   */
  const layout = () => {
    const carePlan = currentCarePlans.find((currentCarePlan) => currentCarePlan.id === activeCarePlan);
    const config = carePlan ? carePlanConfigs[carePlan.config_digest] : null;
    return (
      <div className="flex">
        {createModalOpen && createWarningModal()}
        {updateModalOpen && updateWarningModal()}
        <CarePlanList
          carePlans={currentCarePlans}
          createCarePlan={createCarePlan}
          activeCarePlan={activeCarePlan}
          setActiveCarePlan={setActiveCarePlan}
          toggleCreateModalOpen={toggleCreateModalOpen}
          toggleUpdateModalOpen={toggleUpdateModalOpen}
        />
        {carePlan && (
          <CarePlanView
            carePlan={carePlan}
            config={config}
            member={member}
            userId={userId}
            userIsProvider={userIsProvider}
            authenticityToken={authenticityToken}
            signButtonFlag={signButtonFlag}
            uploadButtonFlag={uploadButtonFlag}
            cosignerFlag={cosignerFlag}
          />
        )}
      </div>
    );
  };

  return (
    <section className="text-black container">
      {!activeCarePlan && createInitialCarePlan()}
      {activeCarePlan && layout()}
    </section>
  );
}

CarePlans.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  carePlans: PropTypes.array,
  member: PropTypes.shape({
    external_id: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    isDP2: PropTypes.bool,
    mrn: PropTypes.string,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  userIsProvider: PropTypes.bool.isRequired,
  signButtonFlag: PropTypes.bool.isRequired,
  uploadButtonFlag: PropTypes.bool.isRequired,
  cosignerFlag: PropTypes.bool.isRequired,
};

CarePlans.defaultProps = {
  carePlans: [],
};

export default CarePlans;
