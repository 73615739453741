import React from 'react';
import PropTypes from 'prop-types';

function Bar(props) {
  const fillColor = props.isFilled ? 'bg-blue-800' : 'bg-gray-300';

  return <div className={`${fillColor} rounded-sm w-10 h-3 my-auto mr-1 flex-grow`} />;
}

Bar.propTypes = {
  isFilled: PropTypes.bool,
};

Bar.defaultProps = {
  isFilled: false,
};

export default Bar;
