import PropTypes from 'prop-types';

// COMPONENTS
import GenericModal from '../../shared/GenericModal';

function ErrorModal(props) {
  const back = () => {
    props.onBack();
  };

  return (
    <GenericModal isOpen={props.isOpen} headerText="Error" primaryButtonLabel="Back" primaryButtonClick={() => back()}>
      <div className="m-4">
        <div>{props.errorMessage}</div>
      </div>
    </GenericModal>
  );
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorModal;
