import React from 'react';
import PropTypes from 'prop-types';
import NoteReadModeSingleQuestion from './NoteReadModeSingleQuestion';
import NoteReadModeAdditionalInfo from './NoteReadModeAdditionalInfo';

function NoteReadMode({ note, isFullWidthSet, selectedFormAnswerId }) {
  if (!note) return <></>;
  return (
    <div>
      {note.questions?.map((question, index) => (
        <NoteReadModeSingleQuestion
          // eslint-disable-next-line react/no-array-index-key
          key={`${question.question}-${index}`}
          question={question.question}
          answer={question.answer}
          isFullWidthSet={isFullWidthSet}
          type={question.type}
          isFormAnswerSelected={question.formAnswerId === selectedFormAnswerId}
        />
      ))}

      <div className="mt-4">
        <NoteReadModeAdditionalInfo signer={note.signer} coSigners={note.coSigners} addendums={note.addendums} />
      </div>
    </div>
  );
}

NoteReadMode.propTypes = {
  isFullWidthSet: PropTypes.bool.isRequired,
  note: PropTypes.shape({
    formAnswerId: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(NoteReadModeSingleQuestion.propTypes),
    signer: NoteReadModeAdditionalInfo.propTypes.signer,
    coSigners: NoteReadModeAdditionalInfo.propTypes.coSigners,
    addendums: NoteReadModeAdditionalInfo.propTypes.addendums,
  }),
  selectedFormAnswerId: PropTypes.string,
};

NoteReadMode.defaultProps = {
  selectedFormAnswerId: null,
};

NoteReadMode.defaultProps = {
  note: undefined,
};

export default NoteReadMode;
