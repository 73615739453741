// recordId: the overall record the photos are attached to
// id: attachment record id
const pregnancyTestRecord = (pregnancyRecord) => ({
  showPregnancySection: pregnancyRecord?.show_pregnancy,
  pregnancyRecordId: pregnancyRecord?.record?.id,
  pregnancyId: pregnancyRecord?.attachment_record?.id,
  pregnancyTestDate: pregnancyRecord?.attachment_record?.created_at,
  pregnancyTestUrl: pregnancyRecord?.url,
});

export default pregnancyTestRecord;
