import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import GenericModal from '../../shared/GenericModal';

// HELPERS
import { warningTextStyle } from './styles';
import ShipmentSummary from './ShipmentSummary';

function ConfirmationModal(props) {
  const confirm = () => {
    props.onConfirm();
  };
  const back = () => {
    props.onBack();
  };

  const [sum, setSum] = useState({});
  useEffect(() => {
    setSum(props.summary);
  }, [props.summary]);

  return (
    <GenericModal
      isOpen={props.isOpen}
      headerText="Are you sure?"
      primaryButtonLabel="Confirm"
      secondaryButtonLabel="Back"
      primaryButtonClick={() => confirm()}
      secondaryButtonClick={() => back()}
      primaryButtonLoading={props.confirmLabelLoading}
      primaryButtonDisabled={props.confirmLabelLoading}
    >
      <div className="m-4">
        <div>You are confirming the purchase of a shipping label with the following information.</div>
        <div style={warningTextStyle}>This action cannot be undone.</div>
      </div>

      <div className="m-4">
        <ShipmentSummary show header="SHIP FROM" data={sum.shipFrom} onEditClick={props.onShowAddressSection} />
        <ShipmentSummary show header="DELIVER TO" data={sum.deliverTo} onEditClick={props.onShowAddressSection} />

        <ShipmentSummary
          show
          header="PACKAGE DETAILS"
          data={sum.packageDetails}
          onEditClick={props.onShowContentsSection}
          members={props?.members}
        />
        <ShipmentSummary show header="SERVICE" data={sum.service} onEditClick={props.onShowContentsSection} />
      </div>
    </GenericModal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  summary: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onShowAddressSection: PropTypes.func.isRequired,
  onShowContentsSection: PropTypes.func.isRequired,
  confirmLabelLoading: PropTypes.bool.isRequired,
  members: PropTypes.array,
};

ConfirmationModal.defaultProps = {
  members: null,
};

export default ConfirmationModal;
