import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddMemberConfirmationModal from './AddMemberConfirmationModal';
import AddMemberModal from '../../../AddMemberModal';
import useHttp from '../../../shared/hooks/use-http';
import { OptionsPropTypes } from '../shared/types';

function AddMemberToAppointment({ authenticityToken, onClose, onContinue, onError, selectedAppointment, reasons }) {
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const { isLoading, sendRequest } = useHttp();

  const handleClose = () => {
    setSelectedMember(null);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const response = await sendRequest(`/staff/appointment_occurrences/${selectedAppointment.id}/add_member`, {
        method: 'POST',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: {
          appointment_occurrence: {
            member_id: selectedMember.id,
            reason_id: selectedReason?.value,
          },
        },
      });

      onContinue(response);
    } catch (e) {
      onError(e);
    }

    handleClose();
  };

  if (!selectedAppointment) return null;

  if (!selectedMember) {
    return (
      <AddMemberModal
        isOpen
        appointment={selectedAppointment}
        type="Appointment"
        onMemberChange={setSelectedMember}
        onClose={handleClose}
        onReasonChange={setSelectedReason}
        reasons={reasons}
      />
    );
  }

  return (
    <AddMemberConfirmationModal
      isOpen
      appointment={selectedAppointment}
      member={selectedMember}
      onConfirm={handleSubmit}
      onCancel={() => setSelectedMember(null)}
      isLoading={isLoading}
      reason={selectedReason}
    />
  );
}

AddMemberToAppointment.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  selectedAppointment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    appointmentType: PropTypes.string.isRequired,
    scheduledAt: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    providers: PropTypes.string.isRequired,
  }),
  reasons: OptionsPropTypes.isRequired,
};

AddMemberToAppointment.defaultProps = {
  selectedAppointment: null,
};

export default AddMemberToAppointment;
