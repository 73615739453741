import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import InputWrapper from './InputWrapper';
import { selectStyles, ClearIndicator, DropdownIndicator, theme } from './SelectCommons';

// eslint-disable-next-line react/display-name
const SelectInput = forwardRef(
  (
    {
      name,
      value,
      onChange,
      placeholder,
      label,
      options,
      isMulti,
      error,
      showError,
      required,
      isVertical,
      isClearable,
      wrapperClassNames,
      disabled,
      usePortal,
      classes,
    },
    ref
  ) => (
    <InputWrapper
      label={label}
      name={name}
      required={required}
      error={showError && typeof error === 'string' && error}
      isVertical={isVertical}
      wrapperClassNames={wrapperClassNames}
    >
      <Select
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isMulti={isMulti}
        isSearchable
        options={options}
        styles={selectStyles(error, disabled)}
        theme={theme}
        isDisabled={disabled}
        isClearable={isClearable}
        components={{ DropdownIndicator, ClearIndicator }}
        menuPortalTarget={usePortal ? document.body : null}
        className={classes}
      />
    </InputWrapper>
  )
);

const valuePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
});

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([valuePropType, PropTypes.arrayOf(valuePropType)]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showError: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  isClearable: PropTypes.bool,
  wrapperClassNames: PropTypes.string,
  disabled: PropTypes.bool,
  usePortal: PropTypes.bool,
  classes: PropTypes.string,
};

SelectInput.defaultProps = {
  placeholder: null,
  isMulti: false,
  value: null,
  error: null,
  showError: true,
  required: false,
  isVertical: true,
  label: null,
  wrapperClassNames: '',
  disabled: false,
  usePortal: false,
  classes: null,
  isClearable: false,
};

export default SelectInput;
