import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import AttendanceDrugScreeningInformation from './AttendanceDrugScreeningInformation';

function AttendanceDrugScreenings({ uds, fent }) {
  const flexClass = 'flex-1';

  return (
    <div className={`${flexClass}`}>
      <AttendanceDrugScreeningInformation label="UDS" text={uds} isWarning={uds === 'Not Submitted'} />

      <AttendanceDrugScreeningInformation label="FENT" text={fent} isWarning={fent === 'Not Submitted'} />
    </div>
  );
}

AttendanceDrugScreenings.propTypes = {
  uds: PropTypes.string,
  fent: PropTypes.string,
};

AttendanceDrugScreenings.defaultProps = {
  uds: '',
  fent: '',
};

export default AttendanceDrugScreenings;
