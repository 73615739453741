import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from './Button';

function ButtonRow(props) {
  const secondaryButtonClass = 'flex-1 mr-2';
  const primaryButtonClass = 'flex-1 ml-2';

  return (
    <div className="flex mt-4">
      <Button show={props.showSecondary} classes={secondaryButtonClass} isSecondary onClick={props.onSecondaryClick}>
        {props.secondaryLabel}
      </Button>

      <Button
        show={props.showPrimary}
        classes={primaryButtonClass}
        isPrimary
        onClick={props.onPrimaryClick}
        isLoading={props.primaryButtonLoading}
        disabled={props.primaryButtonDisabled}
      >
        {props.primaryLabel}
      </Button>
    </div>
  );
}

ButtonRow.propTypes = {
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  showPrimary: PropTypes.bool,
  showSecondary: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  primaryButtonLoading: PropTypes.bool,
  primaryButtonDisabled: PropTypes.bool,
};

ButtonRow.defaultProps = {
  primaryLabel: 'Continue',
  secondaryLabel: 'Back',
  showPrimary: true,
  showSecondary: true,
  primaryButtonLoading: false,
  primaryButtonDisabled: false,
};

export default ButtonRow;
