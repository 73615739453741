import React from 'react';
import PropTypes from 'prop-types';

function SmallModal({ children }) {
  return (
    <div className="fixed inset-0 overflow-y-auto z-20">
      <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
        <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
          <div className="p-6 w-full whitespace-normal z-30">{children}</div>
        </div>
        <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
      </div>
    </div>
  );
}

SmallModal.propTypes = {
  children: PropTypes.node,
};

SmallModal.defaultProps = {
  children: [],
};

export default SmallModal;
