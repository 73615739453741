import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import 'rc-picker/assets/index.css';

import HealthieForm from '../documentation/HealthieForm';

import Button from '../shared/Button';
import useHttp from '../shared/hooks/use-http';
import { FormAnswerPropType } from '../documentation/helpers/types';
import PDFFile from '../shared/PDFFile';
import useConvertObjKeysToCamelCase from '../shared/hooks/use-convert-keys-to-camelCase';
import ErrorMessage from '../shared_dashboard/ErrorMessage';

const testID = 'outboundsMain';

const OUTBOUND_STATUS = {
  pending: 'pending',
  finished: 'finished',
  cancelled: 'cancelled',
  open: 'open',
};

function Main({ authenticityToken, outbound_queue_id, outbound_form, outbound_document_url, outbound_status }) {
  const [successfulResponse, setSuccessfulResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [noteUpdating, setNoteUpdating] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(!!outbound_document_url);
  const { isLoading: isUpdateChartingNoteLoading, sendRequest: updateChartingNote } = useHttp();
  const { isLoading: isOutboundLoading, sendRequest: signOutbound } = useHttp();
  const outboundCancelled = outbound_status === OUTBOUND_STATUS.cancelled;

  const acknowledgeOutbound = async () => {
    try {
      await signOutbound(`/outbound_queues/${outbound_queue_id}/acknowledge_outbound`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });
      window.ReactNativeWebView?.postMessage('outboundCompleted');
      setSuccessfulResponse(true);
    } catch {
      setErrorResponse(true);
    }
  };

  const handleClick = () => {
    if (outbound_form?.data?.formAnswerGroup?.id !== undefined) return;
    acknowledgeOutbound();
  };

  const handleHealthieFormUpdate = useCallback(
    async (noteId, data) => {
      const noteAnswers = outbound_form?.data?.formAnswerGroup?.form_answers;
      const newNoteAnswers = noteAnswers.map(({ custom_module, id, label }) => ({
        answer: data[custom_module.id],
        custom_module_id: custom_module.id,
        id,
        label,
      }));

      await updateChartingNote(`/staff/documentation/charting_notes`, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          charting_note_ids: [noteId],
          form_answers: [newNoteAnswers],
          outbound_queue_id,
        },
      });
    },
    [updateChartingNote, authenticityToken, outbound_form, outbound_queue_id]
  );

  const formAnswers = useConvertObjKeysToCamelCase(outbound_form?.data?.formAnswerGroup?.form_answers);

  return (
    <div data-testid={testID} className="h-screen w-full flex justify-center items-center">
      <div
        className={`text-black border rounded overflow-scroll ${outbound_form?.data?.formAnswerGroup?.id === undefined ? 'bg-white' : 'bg-blue-100'}`}
        style={{ maxHeight: '90%', maxWidth: '90%' }}
      >
        {!successfulResponse && !outboundCancelled && (
          <>
            {outbound_document_url && (
              <div className="px-5 py-8 bg-white">
                <PDFFile onLoadFinish={() => setIsPdfLoading(false)} file={outbound_document_url} />
              </div>
            )}
            {outbound_form && (
              <div className="px-5 py-8 bg-blue-100">
                <h3>{outbound_form.data.formAnswerGroup.name}</h3>
                <HealthieForm
                  formId={outbound_form.data.formAnswerGroup.id}
                  formAnswers={formAnswers}
                  onUpdate={handleHealthieFormUpdate}
                  disabled={isUpdateChartingNoteLoading || isOutboundLoading}
                  setIsNoteUpdating={setNoteUpdating}
                  onSubmit={acknowledgeOutbound}
                />
              </div>
            )}
            {errorResponse && (
              <div className="px-5 pb-8">
                <ErrorMessage
                  text="You are not authorized to perform this action. If you have any questions please contact support."
                  subTextA=""
                />
              </div>
            )}
            <div className="pl-5 pb-8">
              <Button
                form={outbound_form?.data?.formAnswerGroup?.id}
                isLoading={isUpdateChartingNoteLoading || isOutboundLoading || noteUpdating}
                type="submit"
                aria-label="Submit"
                isTertiary
                disabled={isPdfLoading}
                onClick={handleClick}
              >
                Submit
              </Button>
            </div>
          </>
        )}
        {successfulResponse && <h3 className="px-5 py-8">Thank you!</h3>}
        {outboundCancelled && <h3 className="px-5 py-8">Document not found</h3>}
      </div>
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  outbound_queue_id: PropTypes.number.isRequired,
  outbound_document_url: PropTypes.string,
  outbound_status: PropTypes.oneOf(Object.values(OUTBOUND_STATUS)),
  outbound_form: PropTypes.shape({
    data: PropTypes.shape({
      formAnswerGroup: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        form_answers: PropTypes.arrayOf(FormAnswerPropType).isRequired,
      }),
    }),
  }),
};

Main.defaultProps = {
  outbound_document_url: undefined,
  outbound_form: undefined,
  outbound_status: OUTBOUND_STATUS.pending,
};

export default Main;
