import React from 'react';
import PropTypes from 'prop-types';

import { HiOutlineTrash } from 'react-icons/hi';

function InactiveStatusIcon({ testId }) {
  return (
    <div className="status-icon inactive-status-icon" data-testid={testId}>
      <HiOutlineTrash />
    </div>
  );
}

InactiveStatusIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default InactiveStatusIcon;
