import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import ShipFrom from './ShipFrom';
import DeliverTo from './DeliverTo';
import NextButton from '../shared/NextButton';

// HOOKS
import useCachedShipmentData from '../hooks/use-cached-shipment-data';

// HELPERS
import { errorsExist, isEmptyObject } from '../../../helpers/AssortedHelpers';

function ShipmentAddresses(props) {
  // CACHED DATA
  const { labelAddress, fetchCachedShipmentData } = useCachedShipmentData(props?.selectedLabel?.labelName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => fetchCachedShipmentData(props?.selectedLabel?.labelName),
    [props?.selectedLabel?.labelName, props.updateCache]
  );

  // SET DATA
  const [address, setAddress] = useState({});
  useEffect(() => setAddress(labelAddress), [labelAddress]);

  // SHOW CONTAINER
  const [toShow, setToShow] = useState(true);
  useEffect(() => setToShow(!labelAddress?.isComplete), [labelAddress]);
  // ERROR HANDLING
  const requiredText = 'This field is required';
  const [formErrors, setFormErrors] = useState({});
  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateObj = (obj, key) => {
    if (isEmptyObject(obj)) {
      setError(key, requiredText);
    } else {
      setError(key, null);
    }
  };

  const validateData = () => {
    validateObj(address?.selectedStaff, 'selectedStaff');
    validateObj(address?.location, 'location');
    validateObj(address?.staffEmail, 'staffEmail');
    validateObj(address?.staffPhone, 'staffPhone');

    validateObj(address?.members, 'members');
    validateObj(address?.memberEmail, 'memberEmail');
    validateObj(address?.memberPhone, 'memberPhone');
    validateObj(address?.memberLocation, 'memberLocation');

    if (address?.memberLocation?.label === 'Other') {
      validateObj(address?.memberLocation?.address_information?.street1, 'street1');
      validateObj(address?.memberLocation?.address_information?.city, 'city');
      validateObj(address?.memberLocation?.address_information?.state, 'state');
      validateObj(address?.memberLocation?.address_information?.zip, 'zip');
    }
  };

  // SET CACHING DATA
  const setData = (data) => {
    setAddress({ ...data });
    window.localStorage.setItem(`shipment-addresses-${props.selectedLabel?.labelName}`, JSON.stringify(data));
  };

  const setRawData = (data) => {
    setAddress({ ...data });
  };

  return (
    <>
      {toShow && (
        <>
          <ShipFrom
            locations={props.locations}
            labelAddress={address}
            formErrors={formErrors}
            onSetData={(data) => setData(data)}
            onSetRawData={(data) => setRawData(data)}
          />

          <DeliverTo labelAddress={address} formErrors={formErrors} onSetData={(data) => setData(data)} />

          <NextButton
            classes="w-full"
            text="NEXT: ORDER DETAILS"
            onClick={() => {
              validateData();

              if (!errorsExist(formErrors)) {
                address.isComplete = true;
                setData(address);
                setToShow(false);
                document.getElementById('pageHeader').scrollIntoView();
                props.onSetData(address);
              }
            }}
          />
        </>
      )}
    </>
  );
}

ShipmentAddresses.propTypes = {
  locations: PropTypes.array.isRequired,
  selectedLabel: PropTypes.object.isRequired,
  updateCache: PropTypes.bool.isRequired,
  onSetData: PropTypes.func.isRequired,
};

export default ShipmentAddresses;
