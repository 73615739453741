// REACT IMPORTS
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

// GROUP RELATED IMPORTS
import axios from 'axios';
import GroupTabData from './GroupTabData';
import GroupTableHeaderData from './GroupTableHeaderData';

// TABLE IMPORTS
import Tabs from '../../Tabs';
import Table from '../../Table';
import Filters from '../../Filters';

// SERVICE IMPORTS
import useFetchData from './fetch-group-data';
import useFilterData from './fetch-group-filter-data';
import setParams from './set-params';

// COMPONENTS
import Button from '../../shared/Button';
import WarningModal from '../../WarningModal';
import CreateModal from '../../CreateModal';

function Main(props) {
  // CREATE MODAL DATA
  const [displayModal, setDisplayModal] = useState(false);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [newGroupData, setNewGroupData] = useState({});
  const [modalFlash, setModalFlash] = useState([]);

  // TABS DATA
  const [activeTab, setTab] = useState(GroupTabData[0]);
  const [tabData, setTabData] = useState([]);

  // FILTER DATA
  const {
    statesData,
    facilitiesData,
    staffData,
    daysData,
    officeManagerData,
    modalityData,
    empanelmentGroupTypeData,
    empanelmentSecondaryGroupTypeData,
  } = useFilterData();
  const [currentStatesFilters, setCurrentStatesFilters] = useState([]);
  const [currentFacilitiesFilters, setCurrentFacilitiesFilters] = useState([]);
  const [currentOfficeManagerFilters, setCurrentOfficeManagerFilters] = useState([]);
  const [currentCounselorFilters, setCurrentCounselorFilters] = useState([]);
  const [currentProviderFilters, setCurrentProviderFilters] = useState([]);
  const [currentDaysFilters, setCurrentDaysFilters] = useState([]);
  const [currentGroupTypeFilters, setCurrentGroupTypeFilters] = useState([]);
  const [currentSecondaryGroupTypeFilters, setCurrentSecondaryGroupTypeFilters] = useState([]);

  // TABLE DATA
  const { active, inactive, pending, isLoaded } = useFetchData();
  const groupTableHeaders = GroupTableHeaderData(props.secondaryGroupTypeFeatureFlag);

  const [loaded, setLoaded] = useState(false);
  const [_currentPage, setCurrentPage] = useState(0);
  const [activeData, setActiveData] = useState({});
  const [inactiveData, setInactiveData] = useState({});
  const [pendingData, setPendingData] = useState({});

  const [isSortingGroupName, setIsSortingGroupName] = useState('day_of_week asc');
  const [isSortingStatus, setIsSortingStatus] = useState('status desc');
  const [isSortingOfficeManagerName, setIsSortingOfficeManagerName] = useState('office_manager asc');
  const [isSortingCounselorName, setIsSortingCounselorName] = useState('counselor asc');
  const [isSortingProviderName, setIsSortingProviderName] = useState('provider asc');

  useMemo(() => {
    setTabData(active);

    setActiveData(active);
    setInactiveData(inactive);
    setPendingData(pending);

    setLoaded(isLoaded);
  }, [active, inactive, pending, isLoaded]);

  // FUNCTIONS
  const setTimezone = (data) => {
    const updatedData = data;
    // if no timezone was chosen in form, we will use the browser time zone settings
    if (!('offset' in updatedData.timezone)) {
      const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'shortGeneric' }).split(' ')[2];
      const browserOffset = new Date().getTimezoneOffset() / -60;
      const value = new Date().toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ').slice(2).join(' ');
      updatedData.timezone = {
        offset: browserOffset,
        abbrev: zone,
        value,
      };
    }
    return updatedData;
  };

  const setCurrentData = (updatedData) => {
    if (activeTab.key === 'archived') {
      setInactiveData(updatedData);
    } else if (activeTab.key === 'pending') {
      setPendingData(updatedData);
    } else {
      setActiveData(updatedData);
    }
  };

  const tabChange = async (tab) => {
    if (tab.key === 'archived') {
      setTabData(inactiveData);
    } else if (tab.key === 'pending') {
      setTabData(pendingData);
    } else {
      setTabData(activeData);
    }

    setTab(tab);
  };

  const handleContinue = (data) => {
    const updatedData = setTimezone(data);
    setNewGroupData(updatedData);
    setDisplayWarningModal(true);
  };

  const fetchData = async (pageNumber) => {
    const isArchived = activeTab.key === 'archived';
    const isPending = activeTab.key === 'pending';
    const isApproved = activeTab.key === 'active';

    const updatedParams = setParams(
      pageNumber,
      isArchived,
      isPending,
      isApproved,
      currentStatesFilters,
      currentFacilitiesFilters,
      currentOfficeManagerFilters,
      currentCounselorFilters,
      currentProviderFilters,
      currentDaysFilters,
      currentGroupTypeFilters,
      currentSecondaryGroupTypeFilters
    );

    setCurrentPage(pageNumber);

    try {
      const { data: response } = await axios.get('/staff/empanelment_groups', {
        params: updatedParams,
      });

      if (isArchived) {
        response.json_data = inactiveData.json_data.concat(response.json_data);
        setCurrentData(response);
        setTabData(response);
      } else if (isPending) {
        response.json_data = pendingData.json_data.concat(response.json_data);

        setCurrentData(response);
        setTabData(response);
      } else {
        response.json_data = activeData.json_data.concat(response.json_data);

        setCurrentData(response);
        setTabData(response);
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchFilteredData = async (type, selectedFilters) => {
    setLoaded(false);
    const isArchived = activeTab.key === 'archived';
    const isPending = activeTab.key === 'pending';
    const isApproved = activeTab.key === 'active';

    const statesFilters = type === 'states' ? selectedFilters : currentStatesFilters;
    const facilitiesFilters = type === 'facilities' ? selectedFilters : currentFacilitiesFilters;
    const officeManagerFilters = type === 'office_manager' ? selectedFilters : currentOfficeManagerFilters;
    const counselorFilters = type === 'counselor' ? selectedFilters : currentCounselorFilters;
    const providerFilters = type === 'provider' ? selectedFilters : currentProviderFilters;
    const daysFilters = type === 'days' ? selectedFilters : currentDaysFilters;
    const groupTypeFilters = type === 'group_type' ? selectedFilters : currentGroupTypeFilters;
    const secondaryGroupTypeFilters =
      type === 'secondary_group_type' ? selectedFilters : currentSecondaryGroupTypeFilters;

    const updatedParams = setParams(
      0,
      isArchived,
      isPending,
      isApproved,
      statesFilters,
      facilitiesFilters,
      officeManagerFilters,
      counselorFilters,
      providerFilters,
      daysFilters,
      groupTypeFilters,
      secondaryGroupTypeFilters
    );

    try {
      const { data: response } = await axios.get('/staff/empanelment_groups', {
        params: updatedParams,
      });

      setCurrentData(response);
      setTabData(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
    setLoaded(true);
  };

  const headerButtonClick = async (actionName) => {
    // Sorting
    const isArchived = activeTab.key === 'archived';
    const isPending = activeTab.key === 'pending';
    const isApproved = activeTab.key === 'active';

    let nameSortType = isSortingCounselorName === 'counselor desc' ? 'counselor asc' : 'counselor desc';

    if (actionName === 'filter_group_name') {
      nameSortType = isSortingGroupName === 'day_of_week desc' ? 'day_of_week asc' : 'day_of_week desc';
      setIsSortingGroupName(nameSortType);
    }

    if (actionName === 'filter_status') {
      nameSortType = isSortingStatus === 'status desc' ? 'status asc' : 'status desc';
      setIsSortingStatus(nameSortType);
    }

    if (actionName === 'filter_office_manager_name') {
      nameSortType =
        isSortingOfficeManagerName === 'office_manager desc' ? 'office_manager asc' : 'office_manager desc';
      setIsSortingOfficeManagerName(nameSortType);
    }

    if (actionName === 'filter_counselor_name') {
      nameSortType = isSortingCounselorName === 'counselor desc' ? 'counselor asc' : 'counselor desc';
      setIsSortingCounselorName(nameSortType);
    }

    if (actionName === 'filter_provider_name') {
      nameSortType = isSortingProviderName === 'provider desc' ? 'provider asc' : 'provider desc';
      setIsSortingProviderName(nameSortType);
    }

    const updatedParams = setParams(
      0,
      isArchived,
      isPending,
      isApproved,
      currentStatesFilters,
      currentFacilitiesFilters,
      currentOfficeManagerFilters,
      currentCounselorFilters,
      currentProviderFilters,
      currentDaysFilters,
      currentGroupTypeFilters,
      currentSecondaryGroupTypeFilters,
      nameSortType
    );

    try {
      const { data: response } = await axios.get('/staff/empanelment_groups', {
        params: updatedParams,
      });

      setTabData(response);
      setCurrentData(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const handleModalButtonClick = () => {
    setDisplayModal(true);
  };

  const handleCreate = async () => {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const params = {
      is_approved: newGroupData.status === 'Active',
      day: newGroupData.dayOfWeek,
      time: newGroupData.startTime,
      time_zone: newGroupData.timezone.value,
      location_id: newGroupData.facility.value,
      counselor_id: newGroupData.counselor.value,
      provider_id: newGroupData.provider.value,
      office_manager_id: newGroupData.officeManager.value,
      empanelment_group_modality_id: newGroupData.modality.value,
      empanelment_group_type_id: newGroupData.groupType.value,
      empanelment_secondary_group_type_ids: Array.isArray(newGroupData.secondaryGroupTypes)
        ? newGroupData.secondaryGroupTypes?.map((type) => type?.value)
        : null,
    };

    axios
      .post('/staff/empanelment_groups', params)
      .then(() => {
        setDisplayWarningModal(false);
        setDisplayModal(false);
        window.location.reload();
      })
      .catch((error) => {
        setDisplayWarningModal(false);
        if (error.response) {
          setModalFlash(error.response.data);
          window.Sentry.captureException(error.response.data);
        } else {
          setModalFlash(error.message);
        }
      });
  };

  const testID = 'groupMainEmp';

  return (
    <div data-testid={testID}>
      <h2 data-testid={`title-${testID}`} className="font-body font-semibold mt-2 mb-6">
        Define Care Teams
      </h2>

      <p style={{ maxWidth: '60%' }}>
        Empanelment helps assign and validate members with their groups, counselors, providers, offices, and appointment
        times. Use the filters below to find the members you are looking to validate.
      </p>

      <div className="react-filters flex">
        {/* EPIC NOTE: OM EPIC FEATURE FLAG */}

        <Filters
          label="Office Managers"
          type="office_manager"
          data={officeManagerData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentOfficeManagerFilters}
          testID={`OMs-${testID}`}
        />

        <Filters
          label="Counselors"
          type="counselor"
          data={staffData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentCounselorFilters}
          testID={`counselors-${testID}`}
        />

        <Filters
          label="Providers"
          type="provider"
          data={staffData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentProviderFilters}
          testID={`providers-${testID}`}
        />
      </div>

      <div className="react-filters flex">
        <Filters
          label="States"
          type="states"
          data={statesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentStatesFilters}
          testID={`states-${testID}`}
        />

        <Filters
          label="Groups Meeting Facilities"
          type="facilities"
          data={facilitiesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentFacilitiesFilters}
          testID={`facilities-${testID}`}
        />

        <Filters
          label="Days of the Week"
          type="days"
          data={daysData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentDaysFilters}
          testID={`days-${testID}`}
        />
      </div>

      {props.secondaryGroupTypeFeatureFlag && (
        <div className="react-filters flex">
          <Filters
            label="Clinical Model"
            type="group_type"
            data={empanelmentGroupTypeData}
            onFilter={fetchFilteredData}
            stateChanger={setCurrentGroupTypeFilters}
          />

          <Filters
            label="Other Information"
            type="secondary_group_type"
            data={empanelmentSecondaryGroupTypeData}
            onFilter={fetchFilteredData}
            stateChanger={setCurrentSecondaryGroupTypeFilters}
          />
        </div>
      )}

      <div className="flex justify-end z-10 -mb-9">
        <Button onClick={handleModalButtonClick} classes="btn--primary">
          Create Care Team
        </Button>
      </div>

      <Tabs activeTab={activeTab} tabs={GroupTabData} onChange={tabChange} testID={testID} />

      {loaded ? (
        <div id="empanelment_groups">
          <Table
            activeTab={activeTab.key}
            authenticityToken={props.authenticityToken}
            secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
            currentPage={tabData.current_page}
            tableType="groupsTable"
            tableColumnHeaders={groupTableHeaders}
            tableRows={tabData.json_data}
            pageChange={fetchData}
            headerButtonClick={headerButtonClick}
            isLastPage={tabData.is_last_page}
            totalCountRecords={tabData.total_count}
            editModalFilters={{
              statesFilters: statesData,
              facilitiesFilters: facilitiesData,
              staffFilters: staffData,
              officeManagerFilters: officeManagerData,
              modalityFilters: modalityData,
              empanelmentGroupTypeFilters: empanelmentGroupTypeData,
              empanelmentSecondaryGroupTypeData,
            }}
            updateRows={(_updateType, newRow) => {
              const updatedRows = activeData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
              activeData.json_data = updatedRows;

              const updatedArchiveRows = inactiveData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
              inactiveData.json_data = updatedArchiveRows;

              const updatedPendingRows = pendingData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
              pendingData.json_data = updatedPendingRows;

              if (!inactiveData.json_data.filter((r) => r.id === newRow.id).length > 0) {
                inactiveData.json_data.push(newRow);
              }

              setActiveData(activeData);
              setInactiveData(inactiveData);
              setPendingData(pendingData);

              tabChange(activeTab);
            }}
            testID={testID}
          />
        </div>
      ) : (
        <div data-testid={`noData-${testID}`} className="no-data-loader">
          <CircularProgress />
        </div>
      )}
      {/* EDIT MODAL */}
      <CreateModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={displayModal}
        daysFilters={daysData}
        statesFilters={statesData}
        facilitiesFilters={facilitiesData}
        staffFilters={staffData}
        officeManagerFilters={officeManagerData}
        modalityFilters={modalityData}
        empanelmentGroupTypeFilters={empanelmentGroupTypeData}
        empanelmentSecondaryGroupTypeData={empanelmentSecondaryGroupTypeData}
        onContinue={handleContinue}
        flashMessage={modalFlash}
        onCancel={() => {
          setDisplayModal(false);
          setModalFlash([]);
        }}
        testID={`editEmpanlement-${testID}`}
      />

      {/* POST-EDIT WARNING MODAL */}
      <WarningModal
        isOpen={displayWarningModal}
        confirmData={newGroupData}
        onConfirm={handleCreate}
        onClose={() => {
          setDisplayWarningModal(false);
          setModalFlash([]);
        }}
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        testID={`editEmpanlement-${testID}`}
      />
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
};

export default Main;
