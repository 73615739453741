import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// HELPERS
import { rightArrow, tableRowStyle } from './constants/CustomStyle';
import { mdyyyyFromDate, daysOverdue } from '../../helpers/DateFormatter';

// COMPONENTS
import TaskIcon from './TaskIcon';
import ExpandView from './ExpandView';
import TaskTableCell from './TaskTableCell';
import TaskTableMemberCell from './TaskTableMemberCell';
import TaskTableActionsCell from './TaskTableActionsCell';
import DefaultCell from '../shared_dashboard/DefaultCell';
import EmpanelmentCell from '../shared_dashboard/EmpanelmentCell';
import StatusTagCell from '../shared/StatusTagCell';

function TaskTableRow(props) {
  const { taskPriorityFlag } = props.featureFlags || {};

  const [rowData, setRowData] = useState(props?.data);
  const [expandViewOpen, setExpandViewOpen] = useState(props.hideColumns);

  useEffect(() => {
    if (!props.isSelected) {
      setExpandViewOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected]);

  function renderStatus(status, dueDate) {
    const days = daysOverdue(dueDate);
    const isInactive = status === 'INACTIVE';

    if (isInactive) {
      return null;
    }
    return days;
  }

  return (
    <>
      <tr
        className={`${props.hideColumns ? 'cursor-pointer' : ''}`}
        data-task-id={rowData?.id}
        style={tableRowStyle(props.isSelected)}
        onClick={() => {
          if (expandViewOpen) {
            return;
          }
          if (!props.hideColumns) {
            return;
          }

          setExpandViewOpen(true);
          props.onExpandOpen(true, rowData);
        }}
      >
        {/* TASK ICON */}
        <TaskIcon lastAction={rowData?.last_action} />

        {/* Priority Cell */}
        {taskPriorityFlag && (
          <StatusTagCell
            show={rowData?.priority === 'High'}
            label={rowData?.priority}
            isMedium
            isErrorFilled={rowData?.priority === 'High'}
          />
        )}

        {/* Task Cell */}
        <TaskTableCell
          type={rowData?.task_type}
          description={rowData?.description}
          lastAction={rowData?.last_action}
          hideTaskDetails={props.hideColumns}
        />

        {/* Member Cell */}
        <TaskTableMemberCell url={rowData?.member_url} name={rowData?.member_name} mrn={rowData?.member_mrn} />

        {/* Empaneled Cell */}
        <EmpanelmentCell
          show={!props.hideColumns}
          hasEmpanelment={rowData?.empanelment_id}
          dateTime={rowData?.empanelment_date_time}
          locationName={rowData?.empanelment_location}
        />

        {/* Due Date Cell */}
        <DefaultCell
          preloadedData={mdyyyyFromDate(rowData?.due_date)}
          childText={renderStatus(rowData?.status, rowData?.due_date)}
          customClassName="text-red-500"
        />

        {/* Assignee Cell */}
        <DefaultCell
          preloadedData={rowData?.staff_name || 'Unassigned'}
          customParentClassName={!rowData?.staff_name ? 'text-gray-700 italic' : ''}
        />

        {/* Actions Cell */}
        <TaskTableActionsCell
          show={!props.hideColumns}
          authenticityToken={props?.authenticityToken}
          data={rowData}
          staff={props?.staff}
          onActionSubmit={(row, isReassigned) => {
            setRowData(row);
            props.onRowUpdate(isReassigned);
          }}
        />

        {!props.hideColumns && (
          <td>
            <ArrowForwardIosIcon
              className="m-2 cursor-pointer"
              style={rightArrow}
              onClick={() => {
                setExpandViewOpen(true);
                props.onExpandOpen(true, rowData);
              }}
            />
          </td>
        )}
      </tr>

      {/* TOGGLE TO OPEN */}
      <ExpandView
        isOpen={expandViewOpen}
        authenticityToken={props?.authenticityToken}
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        data={rowData}
        staff={props?.staff}
        onActionChange={(row, isReassigned) => {
          setRowData(row);
          props.onRowUpdate(isReassigned);

          if (isReassigned) {
            setExpandViewOpen(false);
            props.onExpandOpen(false, {});
          }
        }}
        onCloseModal={() => {
          setExpandViewOpen(false);
          props.onExpandOpen(false, {});
        }}
        howToResolveUIFeatureFlag={props.howToResolveUIFeatureFlag}
      />
    </>
  );
}

TaskTableRow.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  hideColumns: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  onRowUpdate: PropTypes.func.isRequired,
  onExpandOpen: PropTypes.func.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  howToResolveUIFeatureFlag: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

TaskTableRow.defaultProps = {
  isSelected: false,
};

export default TaskTableRow;
