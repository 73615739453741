import React from 'react';
import PropTypes from 'prop-types';

import { BsPinAngleFill } from 'react-icons/bs';

function ProgramExpectationIcon({ testId }) {
  return (
    <div className="program-expectation-icon goal-icon" data-testid={testId}>
      <BsPinAngleFill />
    </div>
  );
}

ProgramExpectationIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default ProgramExpectationIcon;
