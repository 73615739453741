import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../shared/Header';
import Contents from '../shared/Contents';

function PackageContent(props) {
  const renderMemberPickups = () => {
    if (!props?.members) {
      return <></>;
    }

    return props?.members.map((member) => (
      <div key={member?.value}>
        <div style={{ fontWeight: 700, fontSize: '18px', marginBottom: '20px' }}>{member?.label}</div>

        <Contents
          classes="flex-wrap gap-y-5"
          filterStyle={{ width: '29%' }}
          cacheKey={`${props.labelName}-${member?.value}-shipment-contents`}
          key={member?.value}
          memberId={member?.value}
        />
      </div>
    ));
  };

  return (
    <>
      <Header text="Package Contents" withBottomMargin withNoUnderline />
      {renderMemberPickups()}

      <hr className="w-full my-10" />
    </>
  );
}

PackageContent.propTypes = {
  labelName: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
};

export default PackageContent;
