import PropTypes from 'prop-types';
import { ColoredLabelPropTypes } from './ColoredLabel';

// eslint-disable-next-line import/prefer-default-export
export const AppointmentAttendancePropTypes = {
  dateTime: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  attendanceStatus: ColoredLabelPropTypes.isRequired,
  screenings: PropTypes.shape({
    fent: PropTypes.shape(ColoredLabelPropTypes),
    uds: PropTypes.shape({
      bup: PropTypes.shape(ColoredLabelPropTypes),
      otherSubstances: PropTypes.arrayOf(PropTypes.shape(ColoredLabelPropTypes)),
    }),
  }).isRequired,
};
