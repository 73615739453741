import { useEffect, useState } from 'react';
import useHttp from './use-http';

const useDosespotUiUrl = ({ memberId, shouldFetch } = {}) => {
  const { isLoading, sendRequest, error, resetError } = useHttp();
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (!shouldFetch || !memberId || currentData) return;

    const fetchData = async () => {
      try {
        const response = await sendRequest(`/staff/members/${memberId}/dosespot_ui_url`);

        setCurrentData(response?.url);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };

    fetchData();
  }, [shouldFetch, memberId, currentData, sendRequest]);

  return {
    isLoading,
    error,
    url: currentData,
    resetError,
  };
};

export default useDosespotUiUrl;
