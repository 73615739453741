import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import InputField from '../../shared/InputField';
import InlineFormError from './InlineFormError';
import DatePicker from '../../shared/DatePicker';
import CheckBox from '../../shared/CheckBox';
import Button from '../../shared/Button';

function InlineGoalCreateForm(props) {
  const { goal, goalLabel, shortTermGoal, warning, memberId, updateCallback, testId } = props;

  const [title, setTitle] = useState(goal?.title);
  const [dueDate, setDueDate] = useState(goal?.due_date);
  const [pinned, setPinned] = useState(goal?.pinned);
  const [errorMessage, setErrorMessage] = useState('');
  const [dueDateError, setDueDateError] = useState('');

  useEffect(() => {
    const setDueDateWithDefault = (defaultDate) => {
      if (defaultDate) {
        setDueDate(defaultDate);
        return;
      }
      const date = new Date();
      const numWeeks = shortTermGoal ? 1 : 10;
      date.setDate(date.getDate() + numWeeks * 7);
      setDueDate(date);
    };

    setTitle(goal?.title);
    setDueDateWithDefault(goal?.due_date);
    setPinned(goal?.pinned);
  }, [goal, shortTermGoal]);

  useEffect(() => {
    setDueDateError('');

    if (goal?.created_at) {
      if (new Date(dueDate) < new Date(goal.created_at)) {
        setDueDateError('Due date cannot be before creation date');
      }
    } else if (new Date(dueDate) < new Date()) {
      setDueDateError('Due date cannot be in the past');
    }
  }, [goal, dueDate]);

  const alertClass = () => {
    if (goal?.title) {
      return null;
    }
    return 'inline-form-alert';
  };

  const dateLabel = () => {
    if (shortTermGoal) {
      return 'Due Date';
    }
    return 'Check-in Date';
  };

  const formatData = () => {
    const goalData = {
      title,
      due_date: dueDate,
      member_id: memberId,
      pinned: !!pinned,
    };

    return { goal: goalData, short_term_goal: shortTermGoal };
  };

  const formInvalid = () => !title || !!dueDateError;

  const submitForm = () => {
    if (formInvalid()) {
      return;
    }

    const url = `/staff/members/${memberId}/goals`;

    axios.post(url, formatData()).then((response) => {
      if (response.status === 200) {
        setErrorMessage('');
        updateCallback();
      } else {
        setErrorMessage(response.data);
      }
    });
  };

  return (
    <div className={`inline-goal-form ${alertClass()}`} data-testid={testId}>
      {!!errorMessage && <InlineFormError errorMessage={errorMessage} />}
      <div className="goal-form-row" data-testid={`row-1-${testId}`}>
        <InputField value={title} label={goalLabel} onChange={setTitle} required errorMessage={warning} />
        <Button classes="inline-form-button" onClick={submitForm}>
          SAVE GOAL
        </Button>
      </div>
      <div className="goal-form-row" data-testid={`row-2-${testId}`}>
        <DatePicker value={dueDate} label={dateLabel()} onChange={setDueDate} errorMessage={dueDateError} />
        {shortTermGoal && <CheckBox checked={pinned} label="This is an RSA" onChange={setPinned} />}
      </div>
    </div>
  );
}

InlineGoalCreateForm.propTypes = {
  goal: PropTypes.object,
  goalLabel: PropTypes.string.isRequired,
  shortTermGoal: PropTypes.bool,
  warning: PropTypes.string,
  updateCallback: PropTypes.func,
  memberId: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
};

InlineGoalCreateForm.defaultProps = {
  goal: {
    title: '',
    dueDate: null,
    pinned: false,
  },
  shortTermGoal: false,
  warning: '',
  updateCallback: () => {},
};

export default InlineGoalCreateForm;
