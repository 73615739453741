import { useEffect, useState } from 'react';
import axios from 'axios';

let ajaxRequest = null;

const useDailyActionLists = () => {
  const [dailyTasksResponse, setDailyTasks] = useState([]);
  const [staff, setStaff] = useState([]);

  const fetchDailyActionLists = (pageNumber = 1, filters = {}) => {
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    ajaxRequest = axios.CancelToken.source();

    const params = { page: pageNumber, filters };

    return axios
      .get('/staff/daily_tasks/fetch_tasks', { params, cancelToken: ajaxRequest.token })
      .then((response) => {
        setDailyTasks(response?.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          window.Sentry.captureException(error);
        }
      });
  };

  const fetchStaff = async () => {
    try {
      const { data: response } = await axios.get('/users/staff_filters');
      setStaff(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  return {
    dailyTasksResponse,
    staff,
    fetchDailyActionLists,
  };
};

export default useDailyActionLists;
