import React from 'react';
import { PropTypes } from 'prop-types';

import GoalsSubheader from './GoalsSubHeader';
import HistoricalGoal from './HistoricalGoal';

function GoalHistoryView({ memberId, goals, updateCallback, testId }) {
  return (
    <div className="goal-history" data-testid={`history-${testId}`}>
      <GoalsSubheader text="Goal History" classes="history-subheader" testId={`history-subheader-${testId}`} />
      {goals.map((goal) => (
        <HistoricalGoal
          key={goal.id}
          goal={goal}
          memberId={memberId}
          updateCallback={updateCallback}
          testId={`historical-goal-${goal.id}-${testId}`}
        />
      ))}
    </div>
  );
}

GoalHistoryView.propTypes = {
  memberId: PropTypes.number.isRequired,
  goals: PropTypes.object.isRequired,
  updateCallback: PropTypes.func,
  testId: PropTypes.string.isRequired,
};

GoalHistoryView.defaultProps = {
  updateCallback: () => {},
};

export default GoalHistoryView;
