import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';

function SortableTableHeader({ onClick, sortKey, children }) {
  const handleClick = () => {
    onClick(sortKey);
  };

  return (
    <TableCell>
      <button type="button" onClick={handleClick}>
        {children}
      </button>
    </TableCell>
  );
}

SortableTableHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortKey: PropTypes.func.isRequired,
  children: PropTypes.node,
};

SortableTableHeader.defaultProps = {
  children: null,
};

export default SortableTableHeader;
