import React from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F8B8D',
    },
  },
});

function CompletedTodayContainer(props) {
  return (
    <div className="my-2">
      <div className="text-3xl font-bold" style={{ letterSpacing: '.4rem' }}>
        {props?.completed}/{props?.total}
      </div>

      <ThemeProvider theme={theme}>
        <LinearProgress
          variant="determinate"
          value={props.progress}
          sx={{
            borderRadius: '4px',
            height: '12px',
            backgroundColor: '#E0E3E5',
            marginTop: '0.5rem',
          }}
        />
      </ThemeProvider>
    </div>
  );
}

CompletedTodayContainer.propTypes = {
  completed: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  progress: PropTypes.string.isRequired,
};

export default CompletedTodayContainer;
