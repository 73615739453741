/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// createTheme, ThemeProvider: MUI's reccomended method for custom styling
const theme = createTheme({
  palette: {
    primary: {
      main: '#237786',
      dark: '#237786',
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '38px',
          background: '#ffffff',
          color: '#868686',
        },
      },
    },
  },
});

// sx prop workaround for adding selected/hover stylings

function MuiDatePicker(props) {
  const { value, label, onChange, format } = props;

  const minDate = props.minDate ? dayjs(props.minDate) : null;
  const initialDefault = minDate || dayjs(new Date());
  const initialValue = value ? dayjs(value) : initialDefault;

  const maxDate = props.maxDate ? dayjs(props.maxDate) : null;

  useEffect(() => {
    if (props.minDate) {
      onChange(props.minDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = React.useState(false);

  return (
    <div className={`react-inline ${props.error && 'danger'}`} style={props.style}>
      <div className="filter-label" style={props?.isFormLabel ? { color: 'black', fontWeight: 700 } : props.labelStyle}>
        {label}
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <DatePicker
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            disableRipple
            value={initialValue}
            onChange={(newValue) => onChange(newValue)}
            sx={{
              width: '100%',

              '& .MuiOutlinedInput-root': {
                borderColor: props.error ? 'red' : '#b3b3b3',

                '&.Mui-focused fieldset': {
                  border: '2px solid  #237786',
                  outlineOffset: '1px',
                  outline: '1px solid #237786',

                  '& legend': {
                    display: 'none',
                  },
                },
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#b3b3b3',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#237786',
              },
            }}
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
              },
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

MuiDatePicker.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isFormLabel: PropTypes.bool,
  labelStyle: PropTypes.object,
  error: PropTypes.string,
  style: PropTypes.object,
  format: PropTypes.string,
};

MuiDatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  value: new Date(),
  label: '',
  labelStyle: null,
  isFormLabel: false,
  error: null,
  style: {},
  format: null,
};

export default MuiDatePicker;
