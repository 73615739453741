import React from 'react';
import PropTypes from 'prop-types';

function ErrorMessage(props) {
  return (
    <div className={props.classes}>
      <div className="relative overflow-visible">
        <div className="bg-white border-gray-400 shadow-md rounded-lg border overflow-auto">
          <div className="py-5 px-3 text-center">
            <p className="text-gray-700 text-center mb-0">{props.text}</p>
            <p className="text-gray-700 text-center mb-0">{props.subTextA}</p>
            <ol style={{ listStyleType: 'circle' }} className="text-gray-700 text-left text-sm italic ml-20">
              {props.listItems.map((item) => (
                // eslint-disable-next-line react/jsx-key
                <li>{item}</li>
              ))}
            </ol>
            <p className="text-gray-700 text-center mb-0">{props.subTextB}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  text: PropTypes.string,
  subTextA: PropTypes.string,
  subTextB: PropTypes.string,
  listItems: PropTypes.array,
  classes: PropTypes.string,
};

ErrorMessage.defaultProps = {
  text: 'Nothing to show here',
  subTextA: 'Try filtering by a different value',
  subTextB: '',
  classes: '',
  listItems: [],
};

export default ErrorMessage;
