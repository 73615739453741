import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Tooltip from '../shared/Tooltip';

function ProgressTodayContainer(props) {
  function renderTooltip() {
    if (!props.showTooltip) {
      return <></>;
    }
    return <Tooltip text={props.tooltipText} style={{ justifyContent: 'left', marginLeft: '7px' }} />;
  }

  return (
    <div className="flex">
      <div className="text-base font-semibold">{props.label}</div>
      <div style={{ width: 0 }}>{renderTooltip()}</div>
    </div>
  );
}

ProgressTodayContainer.propTypes = {
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

ProgressTodayContainer.defaultProps = {
  showTooltip: false,
  tooltipText: '',
};

export default ProgressTodayContainer;
