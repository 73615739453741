import { getSupportedTimeZoneCity } from '../../../../helpers/TimezoneHelper';
import { FILTER_TYPES } from './constants';

const setRequestParams = (pageNumber, filters, sort) => {
  const state_ids = filters[FILTER_TYPES.state]?.map(({ value }) => value);
  const location_ids = filters[FILTER_TYPES.facility]?.map(({ value }) => value);
  const appointment_type_ids = filters[FILTER_TYPES.appointmentType]?.map(({ value }) => value);
  const days = filters[FILTER_TYPES.dayOfWeek]?.map(({ value }) => value);
  const availability_ids = filters[FILTER_TYPES.availability]?.map(({ value }) => value);
  const provider_ids = filters[FILTER_TYPES.provider]?.map(({ value }) => value);
  const member = filters[FILTER_TYPES.member]?.length > 0 ? filters[FILTER_TYPES.member] : null;
  const time = {
    from_time: filters[FILTER_TYPES.fromTime]?.format?.('hh:mm A'),
    to_time: filters[FILTER_TYPES.toTime]?.format?.('hh:mm A'),
  };
  const dates = {
    from_date: filters[FILTER_TYPES.fromDate]?.format?.('YYYY-MM-DD'),
    to_date: filters[FILTER_TYPES.toDate]?.format?.('YYYY-MM-DD'),
  };
  const sortDirection = sort?.desc ? 'desc' : 'asc';
  const sortedBy = sort ? `${sort.id} ${sortDirection}` : null;

  return {
    page: pageNumber,
    days: !days || days.includes('*') ? null : days,
    appointment_types: !appointment_type_ids || appointment_type_ids.includes('*') ? null : appointment_type_ids,
    state_ids: !state_ids || state_ids.includes('*') ? null : state_ids,
    location_ids: !location_ids || location_ids.includes('*') ? null : location_ids,
    provider_ids: !provider_ids || provider_ids?.includes('*') ? null : provider_ids,
    search_member_text: member,
    availability: !availability_ids || availability_ids.includes('*') ? null : availability_ids,
    dates,
    time,
    time_zone: getSupportedTimeZoneCity(new Date()),
    sorted_by: sortedBy,
  };
};

export default setRequestParams;
