import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import Header from '../shared/Header';
import RiskIcon from '../shared/RiskIcon';
import CompleteIcon from '../shared/CompleteIcon';

// DISPLAYS EITHER SUCCESS OR ERROR STATE OF UPLOAD

const successText = 'Successfully uploaded document';
const successDescription = "This document will be sent to the UDS folder within the member's documents in eCW.";

const errorText = 'Something went wrong';
const errorDescription = 'The document upload failed. Please try again.';

function UploadStatusContainer(props) {
  const [headerText, setHeaderText] = useState('');
  const [descriptionText, setDescriptionText] = useState('');

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show);

    if (props.isError) {
      setHeaderText(errorText);
      setDescriptionText(errorDescription);
    } else {
      setHeaderText(successText);
      setDescriptionText(successDescription);
    }
  }, [props.isError, props.show]);

  return (
    <>
      {show && (
        <div
          style={{
            border: props.isError ? '2px solid #CD5334' : '2px solid #237786',
            borderRadius: '20px',
            width: '100%',
            padding: '25px',
          }}
        >
          <div className="flex">
            <div className="flex" style={{ marginRight: '20px', alignItems: 'center' }}>
              {props.isError ? <RiskIcon /> : <CompleteIcon />}
            </div>

            <div className="flex-1">
              <Header text={headerText} style={{ fontSize: '20px', marginBottom: '20px' }} class="hover:none" />

              <div>{descriptionText}</div>
            </div>

            <div className="flex" style={{ alignItems: 'center', marginRight: '10px' }}>
              <CloseIcon onClick={() => setShow(false)} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

UploadStatusContainer.propTypes = {
  isError: PropTypes.bool,
  show: PropTypes.bool,
};

UploadStatusContainer.defaultProps = {
  isError: false,
  show: false,
};

export default UploadStatusContainer;
