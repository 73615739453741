import React from 'react';
import PropTypes from 'prop-types';

import { MdStar } from 'react-icons/md';

function ShortTermGoalIcon({ testId }) {
  return (
    <div className="short-term-goal-icon goal-icon" data-testid={testId}>
      <MdStar />
    </div>
  );
}

ShortTermGoalIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default ShortTermGoalIcon;
