import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  const [daysData, setDays] = useState({});
  const [empanelmentClinicalModelData, setEmpanelmentClinicalModel] = useState();
  const [facilitiesData, setFacilities] = useState({});
  const [modalityData, setModalityData] = useState({});
  const [officeManagerData, setOfficeManager] = useState({});
  const [staffData, setStaff] = useState({});
  const [statesData, setStates] = useState({});
  const [hoursData, setHours] = useState({});
  const [empanelmentSecondaryGroupTypeData, setEmpanelmentSecondaryGroupType] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const { data: response } = await axios.get(
          '/staff/empanelment_groups/fetch_filters?eligible_staff_members_only=true'
        );
        const {
          days,
          states,
          staff,
          locations,
          office_manager,
          modality,
          empanelment_clinical_model,
          hours,
          empanelment_secondary_group_type,
        } = response;

        setDays(days);
        setEmpanelmentClinicalModel(empanelment_clinical_model);
        setFacilities(locations);
        setModalityData(modality);
        setOfficeManager(office_manager);
        setStaff(staff);
        setStates(states);
        setHours(hours);
        setEmpanelmentSecondaryGroupType(empanelment_secondary_group_type);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchFilters();
  }, []);

  return {
    daysData,
    empanelmentClinicalModelData,
    facilitiesData,
    modalityData,
    officeManagerData,
    staffData,
    statesData,
    hoursData,
    empanelmentSecondaryGroupTypeData,
  };
};

export default useFetchData;
