/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import IconRender from './IconRender';

function IconTextCell(props) {
  const divClasses = props.isInline ? 'flex' : '';
  const subtextClasses = props.isInline ? 'm-auto ml-2' : '';

  const successClass = props.isSuccess ? 'text-positive-700 font-bold' : '';
  const warningClass = props.isWarning ? 'text-warning-500 font-bold' : '';
  const errorClass = props.isError ? 'text-error-700 font-bold' : '';

  function renderIcon() {
    if (props.withoutIcon) {
      return <></>;
    }
    return (
      <IconRender
        showCheckIcon={props.showCheckIcon || props.isSuccess}
        showTriangleIcon={props.isWarning}
        showExclamationIcon={props.isError}
      />
    );
  }

  function renderText() {
    if (props.link) {
      return (
        <a
          className={`font-bold my-1 underline hover:no-underline cursor-pointer ${warningClass} ${errorClass}`}
          href={props.link}
        >
          {props.text}
        </a>
      );
    }

    return <div className={`whitespace-nowrap ${successClass} ${warningClass} ${errorClass}`}>{props.text}</div>;
  }

  function renderSubtext() {
    if (props.subtext) {
      return <div className={`text-sm text-gray-700 whitespace-nowrap ${subtextClasses}`}>{props?.subtext}</div>;
    }
    return <></>;
  }

  return (
    <td>
      <div className="m-4">
        <div className="flex items-center">
          {renderIcon()}

          <div className={`ml-1 ${divClasses}`}>
            {renderText()}

            {renderSubtext()}
          </div>
        </div>
      </div>
    </td>
  );
}

IconTextCell.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  withoutIcon: PropTypes.bool,
  showCheckIcon: PropTypes.bool,
  isInline: PropTypes.bool,
  link: PropTypes.string,

  // STATUS
  isSuccess: PropTypes.bool,
  isWarning: PropTypes.bool,
  isError: PropTypes.bool,
};

IconTextCell.defaultProps = {
  withoutIcon: false,
  showCheckIcon: false,
  isInline: false,
  subtext: null,
  link: null,

  // STATUS
  isSuccess: false,
  isWarning: false,
  isError: false,
};

export default IconTextCell;
