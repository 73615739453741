import { useEffect, useState } from 'react';

// PACKAGES
import axios from 'axios';
import moment from 'moment/moment';

// HELPERS
import { getTimeZone } from '../../../helpers/TimezoneHelper';

const dayOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const preferenceOptions = [
  { value: null, label: 'All' },
  { value: 'pickup', label: 'Pick Up' },
  { value: 'ship', label: 'Ship' },
];

const weekRangeOptions = [
  { value: null, label: 'All' },
  { value: '0..4', label: '< 4 weeks' },
  { value: '4..12', label: '4 - 12 weeks' },
  { value: '12..24', label: '12 - 24 weeks' },
  { value: '24..48', label: '24 - 48 weeks' },
  { value: '48..99999999', label: '> 48 weeks' },
];

const useUdsSupplyTaskFilters = () => {
  const [staff, setStaffFilters] = useState([]);
  const [locations, setLocationFilters] = useState([]);
  const [states, setStateFilters] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);

  const fetchStaffFilters = async () => {
    try {
      const { data: response } = await axios.get('/users/staff_filters');
      setStaffFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchLocations = async () => {
    try {
      const { data: response } = await axios.get('/locations/fetch_filters');
      setLocationFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStates = async () => {
    try {
      const { data: response } = await axios.get('/staff/states/filters_only_with_locations');
      setStateFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchTimeOptions = () => {
    const currentTime = moment();
    const timezoneLabel = getTimeZone(currentTime);

    const hours = [];

    hours.push({ value: '*', label: 'All Times' });

    for (let hour = 0; hour < 24; hour += 1) {
      hours.push({
        value: `${moment({ hour, minute: 0 }).format('HH:mm')} ${moment({ hour, minute: 30 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 0 }).format('h:mm A')} ${timezoneLabel}`,
      });

      hours.push({
        value: `${moment({ hour, minute: 30 }).format('HH:mm')} ${moment({ hour: hour + 1, minute: 0 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 30 }).format('h:mm A')} ${timezoneLabel}`,
      });
    }

    setTimeOptions(hours);
  };

  useEffect(() => {
    fetchStaffFilters();
    fetchLocations();
    fetchStates();
    fetchTimeOptions();
  }, []);

  return {
    staff,
    locations,
    states,
    dayOptions,
    timeOptions,
    preferenceOptions,
    weekRangeOptions,
  };
};

export default useUdsSupplyTaskFilters;
