import React from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import VersionStatus from '../../../app_version/VersionStatus';
import { mdyyyy } from '../../../../helpers/DateFormatter';

function AppInfoRow({ appInfo }) {
  return (
    <DetailsRow label="App Info">
      {appInfo?.version ? (
        <VersionStatus
          status={appInfo?.isLatest ? 'green' : 'red'}
          version={appInfo?.version}
          ota={mdyyyy(appInfo?.date)}
          showInline
        />
      ) : (
        <span className="italic">No App Version detected</span>
      )}
    </DetailsRow>
  );
}

AppInfoRow.propTypes = {
  appInfo: PropTypes.shape({
    version: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isLatest: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AppInfoRow;
