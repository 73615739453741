import React from 'react';
import PropTypes from 'prop-types';

function DetailsRow({ children, label, valueClassNames }) {
  return (
    <div className="px-6 py-5 bg-gray-50 flex items-center flex-col md:flex-row gap-1">
      <div className="flex-1 font-medium text-gray-700">{label}</div>
      <div className={`flex-2 text-gray-900 ${valueClassNames}`}>{children}</div>
    </div>
  );
}

DetailsRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  valueClassNames: PropTypes.string,
};

DetailsRow.defaultProps = {
  valueClassNames: '',
};

export default DetailsRow;
