import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../shared/Tooltip';

// HELPERS
import { addressSubtextStyle } from './styles';

function InputCheckbox(props) {
  function renderTooltip() {
    if (props.showTooltip) {
      return <Tooltip text={props.tooltipText} style={{ justifyContent: 'left', marginLeft: '7px' }} />;
    }
    return <></>;
  }

  return (
    <>
      {props.show && (
        <div className="flex ml-auto" style={{ marginTop: '9px' }}>
          <label className="my-auto" style={addressSubtextStyle}>
            <input className="my-auto mr-2" type="checkbox" checked={props.isChecked} onChange={props.onChange} />
            {props.text}
          </label>
          {renderTooltip()}
        </div>
      )}
    </>
  );
}

InputCheckbox.propTypes = {
  show: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

InputCheckbox.defaultProps = {
  show: true,
  showTooltip: false,
  tooltipText: '',
};

export default InputCheckbox;
