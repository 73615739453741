import React from 'react';
import PropTypes from 'prop-types';

function AccountCreatedText(props) {
  return (
    <>
      {props.show && (
        <div className="ml-1">
          <div className="whitespace-nowrap">Account Created</div>
          <div className="text-sm text-gray-700 whitespace-nowrap">{props?.dateText}</div>
        </div>
      )}
    </>
  );
}

AccountCreatedText.propTypes = {
  show: PropTypes.bool.isRequired,
  dateText: PropTypes.string.isRequired,
};

export default AccountCreatedText;
