import React from 'react';
import PropTypes from 'prop-types';

function ConfirmationModalList({ listItems }) {
  return (
    <ul className="list-disc px-5">
      {listItems.map(({ label, value }) => {
        if (!label || !value) {
          return null;
        }

        return (
          <li key={label}>
            <strong>{`${label}: `}</strong>
            {value}
          </li>
        );
      })}
    </ul>
  );
}

ConfirmationModalList.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ConfirmationModalList;
