import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = (availabilityFilters) => {
  const [homeGroup, setHomeGroup] = useState({});
  const [makeupGroup, setMakeupGroup] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const availability = availabilityFilters.length ? availabilityFilters.map(({ value }) => value) : null;

  useEffect(() => {
    const fetchHomeGroup = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_groups', {
          params: {
            page: 1,
            is_home_group: true,
            availability,
          },
        });

        setHomeGroup(response);
        setIsLoaded(true);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchMakeupGroup = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_groups', {
          params: {
            page: 1,
            is_makeup_group: true,
          },
        });

        setMakeupGroup(response);
        setIsLoaded(true);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchHomeGroup();
    fetchMakeupGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    homeGroup,
    makeupGroup,
    isLoaded,
  };
};

export default useFetchData;
