import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import GroupsModal from './GroupsModal';
import MemberGroupDetails from './MemberGroupDetails';
import MemberAppointmentDetails from './MemberAppointmentDetails';
import MemberSummary from './MemberSummary';
import SelectInput from './shared/SelectInput';
import { OptionsPropTypes } from './empanelment/appointments/shared/types';
import EmpanelmentMemberSearchFilter from './shared/EmpanelmentMemberSearchFilter';

const MRN_ERROR = 'Please select a valid member';

const memberSchema = object({
  member: object().required(),
});

function AddMemberModal({ isOpen, onClose, onMemberChange, type, group, appointment, reasons, onReasonChange }) {
  const [currentMember, setCurrentMember] = useState();
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      member: '',
      reason: null,
    },
    resolver: yupResolver(memberSchema),
  });
  const formValues = watch();

  const header = `Add Member to ${type}`;
  const subheader = `Selected ${type}`;

  const handleClose = () => {
    reset();
    onClose();
    setCurrentMember(null);
  };

  const handleGoBack = () => setCurrentMember(null);

  const handleContinue = () => {
    onMemberChange({
      id: currentMember.id,
      name: currentMember.name,
      membersEmpanelmentGroupId: currentMember.members_empanelment_group_id,
    });
    onReasonChange(formValues.reason);
    reset();
    setCurrentMember(null);
  };

  const onSubmit = async ({ member }) => {
    const { id, members_empanelment_group_id, empanelment_column, date_of_birth, phone_number, default_location } =
      member.value;

    const memberGroup = empanelment_column.empanelment_date_time && {
      date: empanelment_column.empanelment_date_time,
      location: empanelment_column.empanelment_location_name,
      counselor: empanelment_column.empanelment_counselor_name,
      provider: empanelment_column.empanelment_provider_name,
      officeManager: empanelment_column.empanelment_office_manager_name,
      groupType: empanelment_column.empanelment_group_type,
    };

    setCurrentMember({
      id,
      name: member.label,
      members_empanelment_group_id,
      group: memberGroup,
      dateOfBirth: date_of_birth,
      phoneNumber: phone_number,
      defaultLocation: default_location,
    });
  };

  const footerButtons = [
    {
      label: 'Back',
      onClick: currentMember ? handleGoBack : handleClose,
      isSecondary: true,
    },
    {
      type: currentMember ? 'button' : 'submit',
      form: currentMember ? undefined : 'add-member-form',
      label: 'Continue',
      onClick: currentMember && handleContinue,
      isPrimary: true,
    },
  ];

  return (
    <GroupsModal isOpen={isOpen} header={header} onClose={handleClose} footerButtons={footerButtons}>
      <div className="px-6 py-4">
        {group && (
          <MemberGroupDetails
            title={subheader}
            date={group?.date_time}
            location={group?.location}
            counselor={group?.counselor_name}
          />
        )}
        {appointment && (
          <MemberAppointmentDetails
            title={subheader}
            appointmentType={appointment?.appointmentType}
            scheduledAt={appointment?.scheduledAt}
            location={appointment?.location}
            duration={appointment?.duration}
          />
        )}
      </div>
      <div className="bg-white border border-gray-400 rounded-lg">
        {currentMember ? (
          <MemberSummary member={currentMember} type={type} showExtraDetails={type === 'Appointment'} />
        ) : (
          <form
            id="add-member-form"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col divide-y divide-gray-300"
          >
            <div className="px-6 py-4">
              <Controller
                name="member"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <EmpanelmentMemberSearchFilter
                    value={value}
                    error={error && MRN_ERROR}
                    label="Member*"
                    onSelect={onChange}
                  />
                )}
              />
            </div>
            {appointment?.isIndividual && (
              <div className="px-6 py-4">
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <SelectInput
                      {...field}
                      label="Reason"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Select reason"
                      options={reasons}
                    />
                  )}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </GroupsModal>
  );
}

AddMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMemberChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  group: PropTypes.shape({
    date_time: PropTypes.string,
    location: PropTypes.string,
    counselor_name: PropTypes.string,
  }),
  appointment: PropTypes.shape({
    appointmentType: PropTypes.string.isRequired,
    scheduledAt: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    isIndividual: PropTypes.bool.isRequired,
  }),
  reasons: OptionsPropTypes.isRequired,
  onReasonChange: PropTypes.func,
};

AddMemberModal.defaultProps = {
  group: null,
  appointment: null,
  onReasonChange: () => {},
};

export default AddMemberModal;
