/* eslint-disable no-shadow */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { GoPersonAdd } from 'react-icons/go';

import DateCell from './DateCell';
import GeneralCell from './GeneralCell';
import { setGroupsData } from './empanelment/management/set-groups-data';
import StickyColumnCell from './shared/StickyColumnCell';

const containerClasses = 'flex justify-start gap-5 items-center';

function ManagementGroupsTableCell(props) {
  const testID = `groupsTableCell-${props.testID}`;
  const {
    id,
    active_members,
    available_seats,
    clinical_model,
    counselor_name,
    date_time,
    distance,
    empaneled_members,
    group_labels,
    location,
    limit,
    max_capacity,
    makeup_type,
    modality_name,
    next_counselor_group,
    next_group_occurence,
    next_provider_group,
    office_manager_name,
    provider_name,
    row,
    scheduled_members,
  } = useMemo(() => setGroupsData(props.row), [props.row]);
  const isHomeGroup = props.activeTab === 'home_group';

  const handleDeleteButtonClick = () =>
    props.onDeleteButtonClick({
      id,
      date_time,
      location,
      next_group_occurence,
      makeup_type,
      clinical_model,
      group_labels,
      modality_name,
      counselor_name,
      provider_name,
      office_manager_name,
      next_counselor_group,
      next_provider_group,
      limit,
      max_capacity,
      scheduled_members,
    });

  const handleAddMemberClick = () =>
    props.onAddButtonClick({
      id,
      date_time,
      counselor_name,
      location,
      next_counselor_group,
      next_provider_group,
      clinical_model,
      group_labels,
      modality_name,
      provider_name,
      office_manager_name,
      limit,
      max_capacity,
    });

  const actionButtons = () => {
    if (props.enableActionsColumn) {
      return (
        <StickyColumnCell containerElement={props.containerElement}>
          <div className="flex justify-around -m-5 p-2.5" data-testid={`action-buttons-${testID}`}>
            <button
              type="button"
              className="table-action-button"
              data-testid={`editActionBtn-${testID}`}
              onClick={() => props.onClickEditRow(row)}
            >
              <FiEdit />
            </button>
            {isHomeGroup && (
              <button
                type="button"
                className="table-action-button"
                data-testid={`addMemberActionBtn-${testID}`}
                onClick={handleAddMemberClick}
                disabled={active_members >= max_capacity}
              >
                <GoPersonAdd className="stroke-05" />
              </button>
            )}
            <button
              data-testid={`deleteActionBtn-${testID}`}
              type="button"
              className="table-action-button"
              disabled={isHomeGroup && !!empaneled_members}
              onClick={handleDeleteButtonClick}
            >
              <HiOutlineTrash className="text-error-700" />
            </button>
          </div>
        </StickyColumnCell>
      );
    }

    return null;
  };

  if (isHomeGroup) {
    return (
      <>
        <StickyColumnCell
          containerElement={props.containerElement}
          extraSpacing={false}
          containerClasses={containerClasses}
          shadowDirection="right"
        >
          <DateCell
            dateTime={date_time}
            location={location}
            distance={distance}
            showFullDate={false}
            testID={testID}
            isTimeZone
          />
        </StickyColumnCell>
        <GeneralCell testID={`ncg-${testID}`} data={next_counselor_group} />
        <GeneralCell testID={`npg-${testID}`} data={next_provider_group} />
        <GeneralCell
          testID={`cm-${testID}`}
          data={clinical_model}
          subText={group_labels}
          classes="w-200px"
          containerClasses="w-60 whitespace-pre-wrap"
        />
        <GeneralCell testID={`modality-${testID}`} data={modality_name} />
        <GeneralCell testID={`counselor-${testID}`} data={counselor_name} />
        <GeneralCell testID={`provider-${testID}`} data={provider_name} />
        <GeneralCell testID={`OM-${testID}`} data={office_manager_name} />
        <GeneralCell testID={`empaneled-members-${testID}`} data={empaneled_members} />
        <GeneralCell testID={`active-members-${testID}`} data={active_members} />
        <GeneralCell testID={`available-seats-${testID}`} data={available_seats} />
        <GeneralCell testID={`limit-${testID}`} data={limit} />
        {actionButtons()}
      </>
    );
  }

  return (
    <>
      <StickyColumnCell
        containerElement={props.containerElement}
        extraSpacing={false}
        containerClasses={containerClasses}
        shadowDirection="right"
      >
        <DateCell dateTime={date_time} location={location} showFullDate={false} testID={testID} isTimeZone />
      </StickyColumnCell>

      <GeneralCell testID={`ngo-${testID}`} data={next_group_occurence} />
      <GeneralCell testID={`makeup-type-${testID}`} data={clinical_model} />
      <GeneralCell testID={`modality-${testID}`} data={modality_name} />
      <GeneralCell testID={`counselor-${testID}`} data={counselor_name} />
      <GeneralCell testID={`provider-${testID}`} data={provider_name} />
      <GeneralCell testID={`OM-${testID}`} data={office_manager_name} />
      <GeneralCell testID={`scheduled-members-${testID}`} data={scheduled_members} />
      {actionButtons()}
    </>
  );
}

ManagementGroupsTableCell.propTypes = {
  activeTab: PropTypes.string.isRequired,
  enableActionsColumn: PropTypes.bool.isRequired,
  onClickEditRow: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
  row: {
    id: PropTypes.number.isRequired,
    active_members: PropTypes.number,
    available_seats: PropTypes.number,
    clinical_model: PropTypes.string,
    empaneled_members: PropTypes.number,
    limit: PropTypes.number,
    max_capacity: PropTypes.number,
    next_counselor_group: PropTypes.string,
    next_provider_group: PropTypes.string,
    next_group_occurence: PropTypes.string,
    makeup_type: PropTypes.string,
    empanelment_group: {
      date_time: PropTypes.string.isRequired,
      modality_name: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    },
    counselor: {
      counselor_name: PropTypes.string.isRequired,
    },
    provider: {
      provider_name: PropTypes.string.isRequired,
    },
    office_manager: {
      office_manager_name: PropTypes.string.isRequired,
    },
    scheduled_members: PropTypes.number,
  }.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

ManagementGroupsTableCell.defaultProps = {
  containerElement: null,
};

export default ManagementGroupsTableCell;
