import { useEffect, useState } from 'react';

const useCachedLabelData = () => {
  const [cachedLabels, setCachedLabels] = useState(null);
  const [cachedLabelIds, setCachedLabelIds] = useState({});

  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const fetchCachedLabels = async () => {
    const cachedLabel = parseData('shipment-labels');
    setCachedLabels(cachedLabel);

    return cachedLabel;
  };

  const fetchCachedLabelIds = async () => {
    const labelIds = parseData('shipment-label-ids') || {};
    setCachedLabelIds(labelIds);

    return labelIds;
  };

  useEffect(() => {
    fetchCachedLabels();
    fetchCachedLabelIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cachedLabels,
    cachedLabelIds,
    fetchCachedLabels,
    fetchCachedLabelIds,
  };
};

export default useCachedLabelData;
