import React, { useState } from 'react';
import PropTypes from 'prop-types';

function InformationIcon(props) {
  const [isHovering, setIsHovering] = useState(false);

  const iconFill = isHovering ? '#18535d' : 'none';
  const circleFill = isHovering ? '#18535d' : '#237786';
  const pathFill = isHovering ? 'white' : '#237786';

  function onHover(value) {
    setIsHovering(value);
    props.onHover(value);
  }

  return (
    <div
      className="group cursor-pointer relative"
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill={iconFill}
        xmlns="http://www.w3.org/2000/svg"
        className="text-teal-800 group-hover:text-white transition-colors"
      >
        <circle
          cx="9.5"
          cy="9.5"
          r="8.25"
          stroke={circleFill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.5 12.8V9.5" stroke={pathFill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.49996 6.04087V6.19953"
          stroke={pathFill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

InformationIcon.propTypes = {
  onHover: PropTypes.func.isRequired,
};

export default InformationIcon;
