import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import RenderHTML from '../RenderHTML';
import { useMemberSearchContext } from './useMemberSearchContext';
import { generateLabel } from './helpers';

function EmpanelmentMemberSearchFilterOption(props) {
  const { query } = useMemberSearchContext();

  return (
    <components.Option {...props}>
      <RenderHTML html={generateLabel(props.data.value, query)} />
    </components.Option>
  );
}

EmpanelmentMemberSearchFilterOption.propTypes = {
  hasValue: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  isRtl: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object()).isRequired,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    options: PropTypes.array,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    styles: PropTypes.object,
    components: PropTypes.object,
    isLoading: PropTypes.bool,
    inputValue: PropTypes.string,
    menuIsOpen: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.object.isRequired,
  innerProps: PropTypes.shape({
    id: PropTypes.string,
    tabIndex: PropTypes.number,
  }).isRequired,
  innerRef: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default EmpanelmentMemberSearchFilterOption;
