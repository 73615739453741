import { useState } from 'react';
import axios from 'axios';

// EXAMPLE PATH: '/staff/daily_tasks/fetch_tasks'
const useResponse = (path = '') => {
  const [response, setResponse] = useState([]);

  const fetchResponse = (pathToController = path, pageNumber = 1, filters = {}) => {
    const params = { page: pageNumber, filters };

    return axios
      .get(pathToController, { params })
      .then((json_response) => {
        setResponse(json_response?.data);

        return json_response?.data;
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  return {
    response,
    fetchResponse,
  };
};

export default useResponse;
