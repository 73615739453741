import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { shortGenericTimeZoneName, formatDayOfWeek, formatTime } from './DateFormatHelper';

function WarningModal(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [confirmData, setConfirmData] = useState(props.isOpen);
  const testID = `warningModal-${props.testID}`;

  const [secondaryGroupTypes, setSecondaryGroupTypes] = useState('');

  const timezone = () => {
    if (confirmData.timezone) {
      return shortGenericTimeZoneName(confirmData.timezone.abbrev);
    }
    return null;
  };

  const time = props.confirmData.dayOfWeek
    ? `${props.confirmData.dayOfWeek}, ${props.confirmData.startTime} (${timezone()})`
    : `${formatDayOfWeek(props.confirmData.time)}, ${formatTime(props.confirmData.time)}`;

  useEffect(() => {
    setIsOpen(props.isOpen);
    setConfirmData(props.confirmData);
  }, [props.isOpen, props.confirmData]);

  useEffect(() => {
    const types = props.confirmData?.secondaryGroupTypes;
    const secondaryTypes = Array.isArray(types) ? types?.map((type) => type?.label).join(', ') : null;
    setSecondaryGroupTypes(secondaryTypes);
  }, [props.confirmData.secondaryGroupTypes]);

  return (
    <>
      {isOpen && (
        <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Are you sure?</h2>
                <p>You are confirming the following information about the following care team:</p>

                {/* <li> Day/Time: Tue. 1:30 PM EST </li> */}
                <li data-testid={`meetingTime-${testID}`}>Group Meeting Time: {time}</li>

                <li data-testid={`facilityLabel-${testID}`}>Group Meeting Facility: {confirmData?.facility?.label}</li>

                <>
                  <li data-testid={`modalityLabel-${testID}`}>Modality: {confirmData?.modality?.label}</li>

                  {props.secondaryGroupTypeFeatureFlag ? (
                    <>
                      <li data-testid={`groupTypeLabel-${testID}`}>Clinical Model: {confirmData?.groupType?.label}</li>
                      <li data-testid={`secondaryGroupTypeLabel-${testID}`}>
                        Other Information: {secondaryGroupTypes}
                      </li>
                    </>
                  ) : (
                    <li data-testid={`groupTypeLabel-${testID}`}>Group Type: {confirmData?.groupType?.label}</li>
                  )}

                  <li data-testid={`OMLabel-${testID}`}>Office Manager: {confirmData?.officeManager?.label}</li>
                </>

                <li data-testid={`counselorLabel-${testID}`}>Counselor: {confirmData?.counselor?.label}</li>

                <li data-testid={`providerLabel-${testID}`}>Provider: {confirmData?.provider?.label}</li>

                <div data-testid={`btns-${testID}`} className="flex mt-4">
                  <button
                    data-testid={`backBtn-${testID}`}
                    className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                    onClick={props.onClose}
                    type="button"
                  >
                    Back
                  </button>

                  <button
                    data-testid={`continueBtn-${testID}`}
                    className="btn btn--rounded btn--primary flex-1 ml-2"
                    type="button"
                    onClick={props.onConfirm}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

WarningModal.propTypes = {
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,

  isOpen: PropTypes.bool.isRequired,
  confirmData: PropTypes.object.isRequired,
  testID: PropTypes.string.isRequired,

  // functions
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

WarningModal.defaultProps = {
  // isMulti: true,
};

export default WarningModal;
