import React from 'react';
import PropTypes from 'prop-types';

import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import { mdyyyy } from '../../helpers/DateFormatter';

export default function MemberInfoPanel({ memberData }) {
  const parseDate = (date) => {
    if (!date) return 'No appointment scheduled';
    const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(date);
    return `${dateTimeWithOffset.format('ddd, M/D/YYYY HH:mm')} ${timeZone.abbrev}`;
  };

  return (
    <>
      <div>
        <div className="font-bold mb-2.5">Date of birth</div>
        <div>{memberData.date_of_birth ? mdyyyy(memberData.date_of_birth) : '-'}</div>
      </div>
      <div>
        <div className="font-bold mb-2.5">Phone number</div>
        <div>{memberData.phone_number || '-'}</div>
      </div>
      <div className="grow">
        <div className="font-bold mb-2.5">Current Home Group</div>
        <div className="flex items-center">
          <div>{parseDate(memberData.group_appointment_column?.group_date_time)}</div>
          <div className="ml-2 text-sm bg-gray-300 py-1 px-2 rounded text-gray-900">
            {memberData.group_appointment_column?.location_name}
          </div>
        </div>
        <div>{`Counselor: ${memberData.group_appointment_column?.counselor_name}`}</div>
        <div>{`Provider: ${memberData.group_appointment_column?.provider_name}`}</div>
        <div>{`Office Manager: ${memberData.empanelment_column?.empanelment_office_manager_name}`}</div>
      </div>
    </>
  );
}

MemberInfoPanel.propTypes = {
  memberData: PropTypes.shape({
    date_of_birth: PropTypes.string,
    phone_number: PropTypes.string,
    group_appointment_column: PropTypes.shape({
      group_date_time: PropTypes.string,
      location_name: PropTypes.string,
      counselor_name: PropTypes.string,
      provider_name: PropTypes.string,
    }),
    empanelment_column: PropTypes.shape({
      empanelment_office_manager_name: PropTypes.string,
    }),
  }).isRequired,
};
