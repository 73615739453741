import React from 'react';
import PropTypes from 'prop-types';

function FormCell(props) {
  return (
    <div className="flex-grow">
      <div className="font-bold mb-2">{props.label}</div>
      <p className="mb-0">{props.text}</p>
    </div>
  );
}

FormCell.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default FormCell;
