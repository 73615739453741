import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import EditableTextRowValue from '../EditableTextRowValue';

const SOCIAL_SECURITY_NUMBER_REGEX = /^\d{9}$|^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;

const autoformatSSNInput = (ssn) => {
  if (!ssn || typeof ssn !== 'string' || ssn?.length !== 9 || !/^\d+$/.test(ssn)) {
    return ssn;
  }

  return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
};

function SocialSecurityNumberRow({ socialSecurityNumber, isLoadingRequest, handleMemberEdit }) {
  const [textValue, setTextValue] = useState(socialSecurityNumber ?? '');
  const [isEditing, setIsEditing] = useState(false);

  const validationError =
    textValue && textValue.match(SOCIAL_SECURITY_NUMBER_REGEX) === null ? 'Invalid Social Security Number format' : '';

  return (
    <DetailsRow label="Social Security Number">
      <EditableTextRowValue
        name="social-security-number"
        value={textValue}
        setValue={setTextValue}
        isLoading={isLoadingRequest}
        onSave={() => handleMemberEdit({ social_security_number: textValue || null }, setIsEditing)}
        onBlur={() => {
          setTextValue((value) => autoformatSSNInput(value));
        }}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        error={validationError}
        hideValue
        customHideButtonValue="number"
        customHideMessage="***-**-****"
      />
    </DetailsRow>
  );
}

SocialSecurityNumberRow.propTypes = {
  socialSecurityNumber: PropTypes.string.isRequired,
  isLoadingRequest: PropTypes.bool,
  handleMemberEdit: PropTypes.func.isRequired,
};

SocialSecurityNumberRow.defaultProps = {
  isLoadingRequest: false,
};

export default SocialSecurityNumberRow;
