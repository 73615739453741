import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CircularProgress } from '@mui/material';
import { FaChevronRight as BreadcrumbsIcon } from 'react-icons/fa6';
import MemberInformation from '../../../member_summary/MemberInformation';
import OneMonthStats from '../../../member_summary/OneMonthStats';
import GroupInformation from '../../../member_summary/GroupInformation';
import Notes from '../../../member_summary/Notes';
import Toasts from '../../../shared/Toasts';
import ChartingNotes from '../../member_encounter_history/ChartingNotes';
import MemberDetailsBox from '../../../member_summary/MemberDetailsBox';
import HistoricalDetailsSection from '../../../member_summary/HistoricalDetailsSection';
import useToasts from '../../../shared/hooks/use-toasts';
import { convertObjKeysToCamelCase, openLinkInNewTab } from '../../../../helpers/utils';
import Button from '../../../shared/Button';
import Table from '../../../Table';
import { TABLE_HEADERS } from '../list/IndividualAppointments';
import useAppointmentMember from './useAppointmentMember';
import AttendanceTracker from './AttendanceTracker';
import AppointmentActions from './AppointmentActions';
import MedicalSection from '../../../member_summary/MedicalSection';
import { formatLocationLabel } from '../shared/helpers';
import {
  MemberLocationsSnakeCasePropTypes,
  IndividualAppointmentSnakeCasePropTypes,
  OptionsPropTypes,
} from '../shared/types';
import { CANCELLED_APPOINTMENT_STATUS, PENDING_ATTENDANCE_STATUS } from '../shared/constants';
import useChartingNoteExternalIds from '../../../documentation/useChartingNoteExternalIds';
import { AppointmentsURLsController } from '../list/useAppointmentsURLs';
import { SmartPhrasesPropType } from '../../../documentation/helpers/types';

const testID = 'individualAppointmentDetailsMain';
function Main({
  appointmentFinderUrl,
  canUserManageAppointment,
  canUserManageOtherAppointments,
  canUserManageChartingNotes,
  memberSummaryUrl,
  authenticityToken,
  states,
  appointment: appointmentSnakeCase,
  reasons,
  appointmentReasons,
  memberLocations,
  smartPhrases,
}) {
  const [appointment, setAppointment] = useState(convertObjKeysToCamelCase(appointmentSnakeCase));

  const parsedLocations = Object.entries(memberLocations ?? {}).map(([key, loc]) => ({
    value: loc.id,
    label: formatLocationLabel(key, loc.name),
    fullAddress: loc.full_address,
  }));

  const { toasts, addToast, removeToast } = useToasts();

  const [isFullWidth, setIsFullWidth] = useState(false);
  const toggleFullWidth = useCallback(() => setIsFullWidth((prevState) => !prevState), []);

  const { member, error, isLoading } = useAppointmentMember(appointment?.member?.id);
  const { chartingNoteExternalIds, isLoading: isExternalIdsLoading } = useChartingNoteExternalIds({
    appointmentOccurrenceId: appointment?.appointmentId,
  });

  const {
    groupInformation,
    memberSummaries: {
      clinical,
      medical,
      demographics,
      historicalDetails,
      historicalStats,
      monthToDate,
      healthieProfile,
    } = {},
    ...memberData
  } = member || {};

  const shouldDisplayMemberDetails = medical || clinical || demographics;
  const shouldDisplayHistoricalDetails = historicalStats || historicalDetails;
  const healthieProfileNote = get(healthieProfile, 'componentData.note', '');

  if (!appointmentSnakeCase) {
    return (
      <div data-testid={testID} className="mt-24 text-black">
        <p className="text-center">Appointment not found.</p>
      </div>
    );
  }

  const isAppointmentAttendancePending = appointment?.attendance?.status === PENDING_ATTENDANCE_STATUS.value;
  const isAppointmentCancelled = appointment.status === CANCELLED_APPOINTMENT_STATUS;
  const canJoinMeeting = !!appointment.videoMeetingHostUrl;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center py-5 justify-between border-b border-gray-400">
          <div className="flex items-center gap-2">
            <a href={appointmentFinderUrl} target="_top" className="font-bold text-3xl underline text-teal-700">
              Appointment Finder
            </a>
            <BreadcrumbsIcon className="text-gray-600" />
            <span className="font-bold text-3xl">Individual Appointment</span>
          </div>
          {canUserManageOtherAppointments && (
            <div className="flex items-center gap-2">
              <AppointmentActions
                appointment={appointment}
                appointmentReasons={appointmentReasons}
                authenticityToken={authenticityToken}
                updateAppointment={setAppointment}
                addToast={addToast}
                isDisabled={isAppointmentCancelled}
                appointmentFinderUrl={appointmentFinderUrl}
                canUserManageAppointment={canUserManageAppointment}
              />
              <Button
                isPrimary
                onClick={() => openLinkInNewTab(appointment.videoMeetingHostUrl)}
                disabled={isAppointmentCancelled || !canJoinMeeting}
              >
                Join meeting
              </Button>
            </div>
          )}
        </div>

        <Toasts toasts={toasts} removeToast={removeToast} className="my-6" />

        <div className="flex flex-col gap-6 py-5">
          <div className="flex flex-col gap-2">
            <span className="text-2xl font-bold">Appointment Details</span>
            <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
              <AppointmentsURLsController
                memberSummaryUrl={memberSummaryUrl}
                appointmentFinderUrl={appointmentFinderUrl}
              >
                <Table
                  tableType="individualAppointments"
                  testID="individualAppointments"
                  showShadow={false}
                  enableActionsColumn={false}
                  hideLoadMoreButton
                  showPageNumbers={false}
                  showBottomMargin={false}
                  tableRows={[appointment]}
                  tableColumnHeaders={TABLE_HEADERS}
                />
              </AppointmentsURLsController>
            </div>
          </div>
          {!isAppointmentCancelled && (
            <div className="flex flex-col gap-2 mb-6">
              <span className="text-2xl font-bold">Attendance</span>
              <AttendanceTracker
                authenticityToken={authenticityToken}
                addToast={addToast}
                appointment={appointment}
                locations={parsedLocations}
                reasons={reasons}
                setAppointment={setAppointment}
                canUserManageAppointment={canUserManageAppointment}
              />
            </div>
          )}
        </div>

        {isLoading && !isAppointmentCancelled && (
          <div className="flex justify-center items-center h-64">
            <CircularProgress style={{ width: '2.5rem', height: '2.5rem', color: 'var(--color-teal-700)' }} />
          </div>
        )}

        {!!error && !isAppointmentCancelled && (
          <div className="flex justify-center items-center h-64">
            <span className="font-semibold text-center text-error-700">
              There was an error when fetching member summary
            </span>
          </div>
        )}

        {!isLoading && !error && member && !isAppointmentCancelled && (
          <div className="bg-white p-5">
            <div className="flex flex-col md:flex-row justify-between gap-10">
              <div className="flex-1">
                <MemberInformation member={memberData} statuses={get(healthieProfile, 'componentData.tags', [])} />
              </div>
              {monthToDate && (
                <div className="flex-1">
                  <OneMonthStats monthData={monthToDate.componentData} />
                </div>
              )}
            </div>

            <div className="flex flex-col md:flex-row justify-between my-8 gap-10">
              {groupInformation && (
                <div className="flex-1">
                  <GroupInformation {...groupInformation} />
                </div>
              )}
              {healthieProfileNote && (
                <div className="flex-1 md:w-1/2">{healthieProfileNote && <Notes notes={healthieProfileNote} />}</div>
              )}
            </div>

            <div className="h-full flex flex-row justify-between items-start flex-wrap">
              {isExternalIdsLoading ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <ChartingNotes
                  toggleFullWidth={toggleFullWidth}
                  isFullWidth={isFullWidth}
                  member={member}
                  authenticityToken={authenticityToken}
                  selectedDocumentationIds={chartingNoteExternalIds || []}
                  appointmentOccurrenceId={appointment?.appointmentId}
                  states={states}
                  hideActions={!canUserManageChartingNotes}
                  isTemplateSelectorDisabled={isAppointmentAttendancePending || !canUserManageChartingNotes}
                  isEditingDisabled={!canUserManageChartingNotes}
                  smartPhrases={smartPhrases}
                />
              )}
              {isFullWidth && (
                <div className="w-6/12">
                  {shouldDisplayMemberDetails && (
                    <>
                      {medical && <MedicalSection medical={medical} member={memberData} />}
                      {clinical && <MemberDetailsBox {...clinical} />}
                      {demographics && <MemberDetailsBox {...demographics} />}
                    </>
                  )}
                </div>
              )}
              {(shouldDisplayMemberDetails || shouldDisplayHistoricalDetails) && (
                <div className="flex flex-col w-6/12 p-2">
                  {shouldDisplayMemberDetails && !isFullWidth && (
                    <div className="flex-1 flex flex-col gap-3">
                      {medical && <MedicalSection medical={medical} member={memberData} />}
                      {clinical && <MemberDetailsBox {...clinical} />}
                      {demographics && <MemberDetailsBox {...demographics} />}
                    </div>
                  )}
                  {shouldDisplayHistoricalDetails && (
                    <div className="flex-1">
                      <HistoricalDetailsSection
                        historicalStatsProps={{
                          data: historicalStats?.componentData,
                          lastSyncedDate: historicalStats?.updatedAt,
                        }}
                        attendanceWeeksProps={{
                          data: historicalDetails?.componentData,
                          lastSyncedDate: historicalDetails?.updatedAt,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canUserManageAppointment: PropTypes.bool.isRequired,
  canUserManageOtherAppointments: PropTypes.bool.isRequired,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  memberSummaryUrl: PropTypes.string.isRequired,
  states: PropTypes.objectOf(PropTypes.string),
  appointment: IndividualAppointmentSnakeCasePropTypes,
  memberLocations: MemberLocationsSnakeCasePropTypes.isRequired,
  reasons: OptionsPropTypes.isRequired,
  appointmentReasons: OptionsPropTypes.isRequired,
  statuses: OptionsPropTypes.isRequired,
  smartPhrases: SmartPhrasesPropType,
};

Main.defaultProps = {
  appointment: null,
  states: null,
  smartPhrases: null,
};

export default Main;
