import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';
import MemberSummary from '../member_summary/Main';
import useHttp from '../../shared/hooks/use-http';
import Button from '../../shared/Button';
import Toast from '../../shared/Toast';
import DeleteNote from '../../documentation/DeleteNote';
import LockAndSign from './LockAndSign';
import { getDateTime } from './helpers';
import useChartingNote from './useChartingNote';
import ChartingNotesTemplateSelector from '../../documentation/ChartingNotesTemplateSelector';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import ChartingNotesHeader from '../../documentation/ChartingNotesHeader';
import AddAddendum from './AddAddendum';
import CoSign from './CoSign';
import useAddDocumentation from './useAddDocumentation';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';
import ChartingNote from './ChartingNote';

const CREATE_CHARTING_NOTE_DEFAULT_ERROR = "Couldn't create charting note. Try again later.";

function ChartingNotes({
  toggleFullWidth,
  isFullWidth,
  member,
  authenticityToken,
  selectedDocumentationIds,
  prescriptionTaskId,
  appointmentOccurrenceId,
  states,
  onSelectedNotesChanged,
  isTemplateSelectorDisabled,
  hideActions,
  smartPhrases,
  isEditingDisabled,
}) {
  const { id: memberId, firstName, lastName, mrn } = member || {};

  const {
    selectedNotes,
    setSelectedNotes,
    isCoSignModalOpen,
    setIsCoSignModalOpen,
    isAddAddendumModalOpen,
    setIsAddAddendumModalOpen,
    handleChartingNoteCoSign,
    handleChartingNoteCoSignClick,
    handleAddAddendumClick,
    handleChartingNoteAddAddendum,
    handleChartingNoteLockAndSign,
    handleChartingNoteDelete,
    lockedAndSignedChartingNotes,
    hasPermissionToEditSomeChartingNotes,
  } = useAddDocumentation({
    onSelectedNotesChanged,
  });

  const [isNoteUpdating, setIsNoteUpdating] = useState(false);
  const [isLockAndSignModalOpen, setIsLockAndSignModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState();
  const {
    chartingNotes,
    isLoading: isChartingNoteLoading,
    error,
    clearError,
  } = useChartingNote(selectedDocumentationIds);
  const {
    isLoading: isCreateChartingNoteLoading,
    sendRequest: createChartingNote,
    error: createChartingNoteError,
    resetError: createChartingNoteResetError,
  } = useHttp();
  const { isLoading: isUpdateChartingNoteLoading, sendRequest: updateChartingNote } = useHttp();
  const memberIds = useMemo(() => [memberId], [memberId]);

  useEffect(() => {
    if (chartingNotes) {
      setSelectedNotes(convertObjKeysToCamelCase(chartingNotes));
    }
  }, [chartingNotes, setSelectedNotes]);

  const latestNoteDate =
    selectedNotes.length && getDateTime(Math.max(...selectedNotes.map((note) => new Date(note.updatedAt))));

  const getSelectedNoteAnswer = useCallback(
    (noteId, answerId) =>
      selectedNotes
        .find((selectedNote) => selectedNote.id === noteId)
        ?.formAnswers.find((selectedNoteAnswer) => selectedNoteAnswer.id === answerId),
    [selectedNotes]
  );

  const getUpdatedNoteAnswersWithExtensions = useCallback(
    (updatedNote) =>
      updatedNote?.formAnswers?.map((answer) => {
        const selectedNoteAnswer = getSelectedNoteAnswer(updatedNote.id, answer.id);

        return {
          ...answer,
          customModule: selectedNoteAnswer?.customModule,
          extensions: selectedNoteAnswer?.extensions,
          isEdited: selectedNoteAnswer?.isEdited || selectedNoteAnswer?.answer !== answer.answer,
          lastChartingNoteCreatedAt: selectedNoteAnswer?.lastChartingNoteCreatedAt,
        };
      }),
    [getSelectedNoteAnswer]
  );

  const handleTemplateChange = useCallback(
    async (response) => {
      const note = convertObjKeysToCamelCase(response[0].data?.createFormAnswerGroup?.form_answer_group);
      setSelectedNotes((prevState) => [note, ...prevState]);
    },
    [setSelectedNotes]
  );

  const handleHealthieFormUpdate = useCallback(
    async (noteId, data) => {
      const noteAnswers = selectedNotes.find((note) => note.id === noteId)?.formAnswers;
      const newNoteAnswers = noteAnswers.map(({ customModule, id, label }) => ({
        answer: data[customModule.id],
        custom_module_id: customModule.id,
        id,
        label,
      }));

      const response = await updateChartingNote('/staff/documentation/charting_notes', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          charting_note_ids: [noteId],
          form_answers: [newNoteAnswers],
        },
      });
      const newNote = convertObjKeysToCamelCase(response?.[0]?.data?.updateFormAnswerGroup?.formAnswerGroup);
      const updatedNote = {
        ...newNote,
        formAnswers: getUpdatedNoteAnswersWithExtensions(newNote),
      };
      setSelectedNotes((prevState) => prevState.map((note) => (note.id === updatedNote.id ? updatedNote : note)));
    },
    [selectedNotes, updateChartingNote, authenticityToken, getUpdatedNoteAnswersWithExtensions, setSelectedNotes]
  );

  const handleChartingNoteSubmit = (note) => {
    setCurrentNote(note);
    setIsLockAndSignModalOpen(true);
  };

  const getAccordionActions = (noteId, name) => {
    const disabled = isNoteUpdating || isUpdateChartingNoteLoading || isEditingDisabled;

    return (
      <>
        <DeleteNote
          authenticityToken={authenticityToken}
          noteIds={[noteId]}
          onSuccess={handleChartingNoteDelete}
          disabled={disabled}
          templateName={name}
        />
        <Button isPrimary isPrimaryMarginBottom={false} type="submit" form={noteId} disabled={disabled}>
          Lock & Sign
        </Button>
      </>
    );
  };

  const renderNotes = () => {
    if (selectedNotes.length)
      return selectedNotes.map((note) => (
        <ChartingNote
          key={note.id}
          note={note}
          getAccordionActions={getAccordionActions}
          handleChartingNoteSubmit={handleChartingNoteSubmit}
          handleHealthieFormUpdate={handleHealthieFormUpdate}
          setIsNoteUpdating={setIsNoteUpdating}
          isUpdateChartingNoteLoading={isUpdateChartingNoteLoading}
          states={states}
          smartPhrases={smartPhrases}
          isFullWidth={isFullWidth}
          isEditingDisabled={isEditingDisabled}
        />
      ));

    if (error) return <Toast classes="mt-4" header={error} type="error" onClose={clearError} hasCloseButton={false} />;

    if (selectedDocumentationIds && isChartingNoteLoading)
      return (
        <div className="text-center border border-gray-400 shadow-md rounded-lg py-12">
          <CircularProgress />
        </div>
      );

    return (
      <div className="text-center border border-gray-400 shadow-md rounded-lg py-12">
        <div className="font-semibold text-gray-700">No Templates Selected</div>
        <div className="text-gray-700">Select templates above to get started</div>
      </div>
    );
  };

  return (
    <div className={classNames('bg-white pb-4 mt-4', { 'sticky top-0 w-6/12': !isFullWidth, 'w-full': isFullWidth })}>
      {currentNote && (
        <LockAndSign
          authenticityToken={authenticityToken}
          chartingNoteIds={[currentNote.id]}
          members={[
            {
              id: memberId,
              firstName,
              lastName,
              mrn,
            },
          ]}
          templateName={currentNote.name}
          onSuccess={handleChartingNoteLockAndSign}
          isModalOpen={isLockAndSignModalOpen}
          closeModal={() => setIsLockAndSignModalOpen(false)}
        />
      )}
      <AddAddendum
        authenticityToken={authenticityToken}
        onSuccess={handleChartingNoteAddAddendum}
        isModalOpen={isAddAddendumModalOpen}
        closeModal={() => setIsAddAddendumModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
        members={[
          {
            value: memberId,
            label: `${firstName} ${lastName}`,
          },
        ]}
      />
      <CoSign
        authenticityToken={authenticityToken}
        onSuccess={handleChartingNoteCoSign}
        isModalOpen={isCoSignModalOpen}
        closeModal={() => setIsCoSignModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
      />
      <ChartingNotesHeader
        latestNoteDate={latestNoteDate}
        onAddAddendumClick={handleAddAddendumClick}
        onCoSignClick={handleChartingNoteCoSignClick}
        disabled={!lockedAndSignedChartingNotes.length}
        isFullWidth={isFullWidth}
        toggleFullWidth={toggleFullWidth}
        hideActionButtons={!hasPermissionToEditSomeChartingNotes || hideActions}
      />
      <hr />
      <div className="mt-4">
        <div>
          <ChartingNotesTemplateSelector
            authenticityToken={authenticityToken}
            memberIds={memberIds}
            createChartingNote={createChartingNote}
            onTemplateChange={handleTemplateChange}
            prescriptionTaskId={prescriptionTaskId}
            isLoading={isCreateChartingNoteLoading}
            isDisabled={isTemplateSelectorDisabled || hideActions}
            appointmentOccurrenceId={appointmentOccurrenceId}
          />
          {createChartingNoteError && (
            <Toast
              classes="mt-4"
              header={createChartingNoteError.parsedMessage ?? CREATE_CHARTING_NOTE_DEFAULT_ERROR}
              type="error"
              onClose={createChartingNoteResetError}
            />
          )}
          <div className="relative mt-6">
            {isCreateChartingNoteLoading && (
              <div className="absolute w-full h-full bg-white bg-opacity-50 no-data-loader">
                <CircularProgress />
              </div>
            )}
            {renderNotes()}
          </div>
        </div>
      </div>
    </div>
  );
}

ChartingNotes.propTypes = {
  toggleFullWidth: PropTypes.func.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    ...MemberSummary.propTypes.member,
    episodeOfCares: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        discharge_reason: PropTypes.string,
        member_id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  selectedDocumentationIds: PropTypes.arrayOf(PropTypes.string),
  onSelectedNotesChanged: PropTypes.func,
  prescriptionTaskId: PropTypes.number,
  states: PropTypes.objectOf(PropTypes.string),
  isTemplateSelectorDisabled: PropTypes.bool,
  appointmentOccurrenceId: PropTypes.number,
  hideActions: PropTypes.bool,
  smartPhrases: SmartPhrasesPropType,
  isEditingDisabled: PropTypes.bool,
};

ChartingNotes.defaultProps = {
  selectedDocumentationIds: [],
  prescriptionTaskId: null,
  appointmentOccurrenceId: null,
  states: null,
  onSelectedNotesChanged: null,
  isTemplateSelectorDisabled: false,
  hideActions: false,
  smartPhrases: null,
  isEditingDisabled: false,
};

export default ChartingNotes;
