import React, { useState } from 'react';
import PropTypes from 'prop-types';

// PACKAGES
import { Box } from '@mui/material';
import axios from 'axios';

// COMPONENTS
import Header from '../shared/Header';
import Button from '../shared/Button';
import PageContainer from '../shared/PageContainer';
import VirtualizedFilter from '../VirtualizedFilter';
import UploadComponent from '../shared/UploadComponent';
import DatePickerContainer from '../shared/DatePickerContainer';
import UploadStatusContainer from './UploadStatusContainer';

const documentTypes = [{ value: 'external_toxicology_result', label: 'External Toxicology Result' }];

const dropdownStyle = { marginBottom: '30px' };
const labelStyle = { color: '#171219' };

function UploadMemberDocuments(props) {
  // DOCUMENT INFORMATION
  const [documentType, setDocumentType] = useState(documentTypes[0]);
  const [dateOfCollection, setDateOfCollection] = useState(new Date());
  const [dateReported, setDateReported] = useState(new Date());
  const [ecwFolder] = useState({ label: 'UDS', value: 'uds' });
  const [pdf, setPdf] = useState(null);

  // UPLOAD STATUS
  const [showStatus, setShowStatus] = useState(false);
  const [pdfErrorState, setPdfErrorState] = useState(false);

  const upload = () => {
    if (!pdf) {
      setShowStatus(true);
      setPdfErrorState(true);
      return;
    }

    const fileData = new FormData();
    fileData.append('pdf', pdf);
    fileData.append('member_id', props.memberId);

    fileData.append('document_type', documentType?.value);
    fileData.append('date_of_collection', dateOfCollection);
    fileData.append('date_reported', dateReported);
    fileData.append('ecw_folder', ecwFolder?.value);

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    axios
      .put(`/staff/members/${props.memberId}/urine_drug_screenings/manual_upload_confirmatory_pdf`, fileData)
      .then(() => {
        setShowStatus(true);
        setPdfErrorState(false);
      })
      .catch((error) => {
        setShowStatus(true);
        setPdfErrorState(true);
        window.Sentry.captureException(error);
      });
  };

  return (
    <>
      <UploadStatusContainer show={showStatus} isError={pdfErrorState} />

      <PageContainer style={{ fontFamily: 'Inter !important' }}>
        <Header text="Upload Document" class="flex items-center flex-1" />

        <PageContainer
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            padding: '30px',
            minHeight: '200px',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {/* LEFT SIDE OF PAGE - FILTERS */}
            <div style={{ flex: 1 }}>
              <VirtualizedFilter
                label="Document Type*"
                value={documentType}
                options={documentTypes}
                stateChanger={setDocumentType}
                style={dropdownStyle}
                labelStyle={labelStyle}
              />

              <DatePickerContainer
                label="Date of Collection*"
                value={dateOfCollection}
                onChange={setDateOfCollection}
                style={dropdownStyle}
                labelStyle={labelStyle}
              />

              <DatePickerContainer
                label="Date Reported*"
                value={dateReported}
                onChange={setDateReported}
                style={dropdownStyle}
                labelStyle={labelStyle}
              />

              <VirtualizedFilter
                disable
                label="eCW Folder"
                style={dropdownStyle}
                labelStyle={labelStyle}
                options={documentTypes}
                value={ecwFolder}
              />
            </div>

            {/* RIGHT SIDE OF PAGE - UPLOAD FILE UI */}
            <UploadComponent pdfErrorState={pdfErrorState} onChange={setPdf} />
          </Box>

          <Box className="justify-center">
            <Button isPrimary style={{ margin: '0 auto', display: 'block', width: '300px' }} onClick={upload}>
              Upload
            </Button>
          </Box>
        </PageContainer>
      </PageContainer>
    </>
  );
}

UploadMemberDocuments.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
};

export default UploadMemberDocuments;
