import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InviteHistoryInfo from './InviteHistoryInfo';
import InviteHistoryMessage from './InviteHistoryMessage';

function InviteHistorySmsItem(props) {
  // DOCUMENT DATA STRUCT ex. (replace with SMS data struct)
  // // => [{:email_last_sent=>Wed, 11 Jan 2023 17:08:18.898476000 UTC +00:00, :email_error=>nil, :type=>:email, :email=>"marco@durgan.io"}]

  return (
    <div className="flex justify-between">
      {props.history?.pending ? (
        <InviteHistoryMessage
          primaryMessage="SMS Scheduled"
          secondaryMessage={props.history?.phone_number}
          colorClassName="bg-warning-700"
        />
      ) : (
        <>
          {props.history?.sms_error ? (
            <InviteHistoryMessage
              primaryMessage="SMS Error"
              secondaryMessage={props.history?.sms_error}
              colorClassName="bg-error-700"
            />
          ) : (
            <InviteHistoryMessage
              primaryMessage={props.history?.sms_status}
              secondaryMessage={`${props.history?.sms_status} Successfully`}
              colorClassName="bg-positive-700"
            />
          )}

          <InviteHistoryInfo
            last_sent={props.history?.sms_last_sent}
            information_method={props.history?.phone_number}
          />
        </>
      )}
    </div>
  );
}

InviteHistorySmsItem.propTypes = {
  history: PropTypes.object.isRequired,
};

export default InviteHistorySmsItem;
