import React from 'react';
import PropTypes from 'prop-types';

// ICONS
import { PiFlagBold } from 'react-icons/pi';
import ReassignIcon from '../shared/ReassignIcon';
import QuestionIcon from '../shared/QuestionIcon';

function TaskIcon(props) {
  function renderIcon() {
    if (props.lastAction === 'QUESTION') {
      return <QuestionIcon />;
    }
    if (props.lastAction === 'REASSIGNED') {
      return <ReassignIcon />;
    }
    if (props.lastAction === 'FLAGGED') {
      return <PiFlagBold className="ml-1 h-5 w-5" />;
    }

    return <></>;
  }
  return (
    <td>
      <div className="flex">
        <div className="flex items-center ml-1 p-2">{renderIcon()}</div>
      </div>
    </td>
  );
}

TaskIcon.propTypes = {
  lastAction: PropTypes.string,
};

TaskIcon.defaultProps = {
  lastAction: '',
};

export default TaskIcon;
