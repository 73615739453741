import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import GroupsModal from '../GroupsModal';
import Table from '../Table';
import useHttp from '../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../helpers/utils';
import { getUserTimeZoneAbbrev } from '../../helpers/TimezoneHelper';
import { mdyyyy } from '../../helpers/DateFormatter';

const TABLE_HEADERS = [
  {
    name: 'Prescriber',
    showColumn: true,
    isClickable: true,
    actionName: 'prescriber',
  },
  {
    name: 'Has Seen Member',
    showColumn: true,
    isClickable: true,
    actionName: 'hasSeenMember',
  },
  {
    name: `Next Available Time (${getUserTimeZoneAbbrev()})`,
    showColumn: true,
  },
];

const SORT_OPTIONS = {
  prescriber: 'provider_last_name',
  hasSeenMember: 'date_seen',
};

function PrescriberLookupModal({ onClose, isOpen, member }) {
  const [prescribers, setPrescribers] = useState();
  const [sortBy, setSortBy] = useState({ id: 'hasSeenMember', desc: true });
  const [page, setPage] = useState(1);

  const { isLoading, sendRequest } = useHttp();

  const footerButtons = [
    {
      label: 'Close',
      isPrimary: true,
      onClick: onClose,
    },
  ];

  const handleSort = (headerName) => setSortBy((prevState) => ({ id: headerName, desc: !prevState.desc }));

  const fetchPrescribers = useCallback(async () => {
    if (!isOpen) return;

    try {
      const response = await sendRequest(`/staff/members/${member.id}/provider_licensures`, {
        params: {
          'filterrific[sorted_by]': `${SORT_OPTIONS[sortBy.id]}_${sortBy.desc ? 'desc' : 'asc'}`,
          page,
        },
      });
      const parsedResponse = convertObjKeysToCamelCase(response);
      setPrescribers(parsedResponse);
    } catch (e) {
      window.Sentry?.captureException(e);
    }
  }, [sendRequest, member, sortBy, page, isOpen]);

  useEffect(() => {
    fetchPrescribers();
  }, [fetchPrescribers]);

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Prescription Decision Support"
      footerButtons={footerButtons}
      size="medium"
      testID="prescriber-lookup-modal"
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <p className="mb-0.5">
            {member.firstName && member.lastName && (
              <>
                <span className="font-semibold mr-1">Member(s):</span>
                {`${member.firstName} ${member.lastName}`}
              </>
            )}
          </p>
          <p className="mb-0.5">
            <span className="font-semibold mr-1">MRN:</span>
            {member.mrn}
          </p>
          <p className="mb-2">
            <span className="font-semibold mr-1">DOB:</span>
            {mdyyyy(member.dob)}
          </p>
          <div className="border border-gray-300 shadow rounded-lg overflow-hidden">
            <Table
              tableType="prescriberLookup"
              showShadow={false}
              enableActionsColumn={false}
              hideLoadMoreButton
              showPageNumbers={false}
              showBottomMargin={false}
              stickyHeader
              minHeight="auto"
              currentSort={sortBy}
              tableRows={prescribers?.prescribers}
              tableColumnHeaders={TABLE_HEADERS}
              headerButtonClick={handleSort}
              paginationVariant="numbered"
              currentPage={parseInt(prescribers?.page, 10) || 1}
              pageSize={15}
              totalCountRecords={prescribers?.totalCount}
              pageChange={setPage}
            />
          </div>
        </div>
      )}
    </GroupsModal>
  );
}

PrescriberLookupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mrn: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrescriberLookupModal;
