import React, { useState } from 'react';
import propTypes from 'prop-types';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import classNames from 'classnames';
import GeneralCell from '../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';

function EmpanelmentCell({ testID, empanelment }) {
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  if (!empanelment) {
    return <GeneralCell classes="mt-2 mb-2 'text-gray-700 italic" testID={`empanelment-${testID}`} data="No group" />;
  }

  const { dateTime, location, counselor, provider, modality, clinicalModel, secondaryGroupTypes, officeManager } =
    empanelment;
  const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(dateTime);
  const time = `${dateTimeWithOffset.format('ddd, h:mm A')} ${timeZone.abbrev}`;

  const counselorName = [counselor?.firstName, counselor?.lastName].filter((e) => e).join(' ');
  const providerName = [provider?.firstName, provider?.lastName].filter((e) => e).join(' ');

  const DetailsBtnIcon = showMoreDetails ? HiChevronUp : HiChevronDown;
  const toggleDetails = () => setShowMoreDetails((prev) => !prev);

  return (
    <GeneralCell
      classes="pt-4 pr-3 pb-6 text-base"
      testID={`empanelment-${testID}`}
      data={
        <dl className="flex flex-col gap-2">
          <button
            onClick={toggleDetails}
            type="button"
            className="font-medium text-teal-700 border-0 flex items-center uppercase"
          >
            <span>show more details</span>
            <DetailsBtnIcon className="text-2xl" />
          </button>
          {showMoreDetails && (
            <>
              <div>
                <dt className="inline font-bold">Modality: </dt>
                <dd className={classNames('inline', { 'text-gray-700 italic': !secondaryGroupTypes.length })}>
                  {modality ?? 'Not provided'}
                </dd>
              </div>
              <div>
                <dt className="inline font-bold">Clinical Model: </dt>
                <dd className={classNames('inline', { 'text-gray-700 italic': !secondaryGroupTypes.length })}>
                  {clinicalModel ?? 'Not provided'}
                </dd>
              </div>
              <div>
                <dt className="inline font-bold">Other information: </dt>
                <dd className={classNames('inline', { 'text-gray-700 italic': !secondaryGroupTypes.length })}>
                  {secondaryGroupTypes?.length ? secondaryGroupTypes.join(', ') : 'None provided'}
                </dd>
              </div>
            </>
          )}
          <div>
            <dt className="inline font-bold">Office Manager: </dt>
            <dd className="inline">{officeManager}</dd>
          </div>
          <div>
            <dt className="inline font-bold">Counselor: </dt>
            <dd className={classNames('inline', { 'text-gray-700 italic': !counselorName.length })}>
              {counselorName?.length ? counselorName : 'Not provided'}
            </dd>
          </div>
          <div>
            <dt className="inline font-bold">Provider: </dt>
            <dd className={classNames('inline', { 'text-gray-700 italic': !providerName.length })}>
              {providerName?.length ? providerName : 'Not provided'}
            </dd>
          </div>
          <span>{`${time}, ${location}`}</span>
        </dl>
      }
    />
  );
}

EmpanelmentCell.propTypes = {
  testID: propTypes.string.isRequired,
  empanelment: propTypes.shape({
    id: propTypes.number.isRequired,
    dateTime: propTypes.string.isRequired,
    location: propTypes.string.isRequired,
    counselor: propTypes.shape({
      id: propTypes.number.isRequired,
      firstName: propTypes.string.isRequired,
      lastName: propTypes.string.isRequired,
    }),
    provider: propTypes.shape({
      id: propTypes.number.isRequired,
      firstName: propTypes.string.isRequired,
      lastName: propTypes.string.isRequired,
    }),
    modality: propTypes.string,
    clinicalModel: propTypes.string,
    secondaryGroupTypes: propTypes.arrayOf(propTypes.string),
    officeManager: propTypes.string,
  }),
};

EmpanelmentCell.defaultProps = {
  empanelment: null,
};

export default EmpanelmentCell;
