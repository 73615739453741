/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// COMPONENTS
import IconRender from './IconRender';
import InviteAccountLinks from './account_status_cell_helpers/InviteAccountLinks';

// COMPONENTS - Text Cells (Dynamically Render Static Text, based on passed params)
import AccountCreatedText from './account_status_cell_helpers/AccountCreatedText';

// HOOKS
import useData from './hooks/use-data';

function AccountStatusCell(props) {
  const { isLoaded, data } = useData(props);
  const [inviteLinkText, setInviteLinkText] = useState('');

  useEffect(() => {
    let text = '';

    if (data?.invitation_error) {
      text = 'Call Member';
    } else if (data?.invite_pending) {
      text = 'Re-Send Email';
    } else if (data?.invited) {
      text = 'Re-Send Invite';
    } else {
      text = 'Invite';
    }

    setInviteLinkText(text);
  }, [data.invitation_error, data.invite_pending, data.invited]);

  return (
    <td>
      <div className="m-4">
        {isLoaded ? (
          <div className="flex items-center">
            <div>
              <IconRender showCheckIcon={data?.has_account} />
            </div>

            {/* STATIC TEXT RESULTS */}
            {data?.has_account && <AccountCreatedText show dateText={data?.account_created_time_ago} />}

            {/* DYNAMIC LINK RESULTS */}
            <InviteAccountLinks
              authenticityToken={props.authenticityToken}
              show={!data?.has_account}
              inviteText={inviteLinkText}
              memberId={props.id}
              data={data}
            />
          </div>
        ) : (
          <Skeleton variant="text" width={150} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

AccountStatusCell.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default AccountStatusCell;
