import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TAG_STATUS } from '../shared/StatusTag';

function ColoredLabel({ label, type, isBackground }) {
  const labelClassNames = {
    [TAG_STATUS.ERROR]: classNames('text-error-700', { 'bg-error-100': isBackground }),
    [TAG_STATUS.WARNING]: classNames('text-warning-500', { 'bg-warning-100': isBackground }),
    [TAG_STATUS.SUCCESS]: classNames('text-positive-700', { 'bg-positive-100': isBackground }),
    [TAG_STATUS.PURPLE]: classNames('text-purple-700', { 'bg-purple-100': isBackground }),
  };

  return (
    <span
      className={classNames({
        [labelClassNames[type]]: labelClassNames[type],
        'font-semibold': labelClassNames[type],
        'px-2 py-0.5 rounded-md': isBackground,
      })}
    >
      {label}
    </span>
  );
}

export const ColoredLabelPropTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TAG_STATUS)),
  isBackground: PropTypes.bool,
};

ColoredLabel.propTypes = ColoredLabelPropTypes;

ColoredLabel.defaultProps = {
  type: TAG_STATUS.STANDARD,
  isBackground: false,
};

export default ColoredLabel;
