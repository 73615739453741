import React from 'react';
import PropTypes from 'prop-types';

import ShortTermGoalIcon from './ShortTermGoalIcon';
import GoalDetails from './GoalDetails';
import GoalActionsButton from './GoalActionsButton';
import RsaLockIcon from './RsaLockIcon';

function ShortTermGoal({ goal, updateCallback, editCallback, testId }) {
  const goalIcon = () => {
    if (goal?.pinned) {
      return <RsaLockIcon testId={`rsa-icon-${testId}`} />;
    }
    return <ShortTermGoalIcon testId={`icon-${testId}`} />;
  };

  return (
    <div className="short-term-goal-container goal-container" data-testid={`container-${testId}`}>
      <h4 className="goal-type" data-testid={`type-${testId}`}>
        SHORT-TERM GOAL
      </h4>
      <div className="goal-row" data-testid={`row-${testId}`}>
        {goalIcon()}
        <GoalDetails goal={goal} testId={`details-${testId}`} shortTermGoal />
        <GoalActionsButton
          goalId={goal.id}
          testId={`actions-btn-${testId}`}
          updateCallback={updateCallback}
          editCallback={editCallback}
        />
      </div>
    </div>
  );
}

ShortTermGoal.propTypes = {
  goal: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
  updateCallback: PropTypes.func,
  editCallback: PropTypes.func,
};

ShortTermGoal.defaultProps = {
  updateCallback: () => {},
  editCallback: () => {},
};

export default ShortTermGoal;
