/**
 * @file DashboardFilterHelpers
 *
 * Helper functions to manipulate filter inputs based on dynamic functionality.
 */

import { isArray } from 'lodash';

/**
 * @function removeAllSelection()
 *
 * @variable AllSelection - T/F if object exists in array
 * example: { value: '*', label: 'All Staff' }
 *
 * We want to remove this from the selected values, if a value with an actual ID has been input
 * example: [ { value: '*', label: 'All Staff' }, { value: 111, label: 'Staff Member Name' } ]
 *  should return ->
 *        [ { value: 111, label: 'Staff Member Name' } ]
 * This is because we want to filter dropdown to only show the new selected value(s), and disregard 'All Staff', etc, selection
 */
function removeAllSelection(defaultFilter, selectedFilters) {
  if (!Array.isArray(selectedFilters)) {
    return selectedFilters;
  }
  if (!selectedFilters || selectedFilters?.length < 1) {
    return [defaultFilter];
  }

  const foundFindAllSelection = selectedFilters?.findIndex((data) => data?.value === '*');

  if (foundFindAllSelection !== -1) {
    selectedFilters?.splice(foundFindAllSelection, 1);
  }

  return selectedFilters;
}

/**
 * @function parseIdsFromArray()
 *
 * We want to only pass in an array of IDs to the backend.
 * Use this function to return only IDs
 *
 * @param params - [ { value: 24234, label: 'Staff Member Name 2' }, { value: 111, label: 'Staff Member Name' } ]
 *
 * example:
 * @return [ 111, 24234, 12 ]
 */
function parseIdsFromArray(params) {
  if (Array.isArray(params)) {
    return params?.map((param) => param?.value);
  }

  return params?.value;
}

function parseLabelsFromArray(params) {
  if (!params) {
    return '';
  }
  if (!isArray(params)) {
    return '';
  }

  const arrayOfValues = params?.map((param) => param?.label);
  return arrayOfValues?.join(', ');
}

function parseValue(param) {
  return param?.value;
}

function isEmptyFilters(params) {
  return Object.values(params).every((v) => v === undefined);
}

function removedUndefinedFromHash(params) {
  // eslint-disable-next-line no-param-reassign
  Object.keys(params).forEach((key) => (params[key] === undefined ? delete params[key] : {}));
}

function hasArrayValues(params) {
  if (params === undefined) {
    return false;
  }
  return params && params?.length > 0;
}

function hasValue(param) {
  if (param === undefined) {
    return false;
  }
  if (!param) {
    return false;
  }

  return true;
}

// eslint-disable-next-line import/prefer-default-export
export {
  removeAllSelection,
  parseIdsFromArray,
  parseLabelsFromArray,
  parseValue,
  isEmptyFilters,
  removedUndefinedFromHash,
  hasArrayValues,
  hasValue,
};
