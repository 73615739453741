import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import StatusTagCell from '../shared/StatusTagCell';

function HighPriorityTodayContainer(props) {
  return (
    <div className="flex my-3">
      <div className="text-4xl text-error-700 font-bold">{props?.count}</div>

      <StatusTagCell cellClasses="flex items-center justify-center ml-2" show isMedium label="High" isErrorFilled />
    </div>
  );
}

HighPriorityTodayContainer.propTypes = {
  count: PropTypes.string.isRequired,
};

export default HighPriorityTodayContainer;
