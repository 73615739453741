import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';
import { ENCOUNTERS_TYPES, CARE_PLAN_STATUSES } from './constants';
import { getDocumentsNames } from './helpers';

const useFilterData = (memberTimeline) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await sendRequest('/staff/appointment_occurrences/fetch_filters');
        const {
          locations,
          states,
          appointment_types,
          days,
          availability,
          providers,
          appointment_statuses,
          prescription_task_statuses,
          outbound_queue_statuses,
        } = response;

        setFilterData((prev) => ({
          ...prev,
          statesData: states,
          facilitiesData: locations,
          staffData: providers,
          daysData: days,
          availabilityData: availability,
          appointmentTypesData: appointment_types,
          typesData: ENCOUNTERS_TYPES,
          statusData: [
            ...appointment_statuses,
            ...prescription_task_statuses,
            ...outbound_queue_statuses,
            ...CARE_PLAN_STATUSES,
          ],
        }));
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchFilters();
  }, [sendRequest]);

  useEffect(() => {
    setFilterData((prev) => ({
      ...prev,
      documentNamesData: getDocumentsNames(memberTimeline),
    }));
  }, [memberTimeline]);

  return { ...filterData, isLoading, error };
};

export default useFilterData;
