/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// REACT Modal: https://mui.com/material-ui/react-modal/
// Follow above link for more documentation on below packages

import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import DrugScreeningRecords from './DrugScreeningRecords';
import FlexBox from '../shared/FlexBox';
import customStyles from '../../helpers/PhotosModalStyle';
import ImageBox from '../shared/ImageBox';

import useFetchData from './hooks/use-fetch-data';
import useDefaultUrl from './hooks/use-default-url';

/**
 * PhotosModal
 *
 * Displays the request new photos modal for members from the weekly groups
 * page. The modal will display UDS, pregnancy, and fentanyl test information
 * and photos.
 *
 * Staff members will be able to interact with the modal to view photos and
 * request new photos.
 */

function PhotosModal(props) {
  const { udsRecord, pregnancyRecord, fentanylRecord } = useFetchData(props);

  const { defaultUrl } = useDefaultUrl(props, udsRecord, pregnancyRecord, fentanylRecord);

  const [url, setUrl] = useState('');

  const setSelectedUrl = (selectedUlr) => setUrl(selectedUlr);

  useEffect(() => {
    setSelectedUrl(defaultUrl);
  }, [defaultUrl]);

  const handleClose = () => props.handleClose();

  return (
    <>
      {/* React Modal with Checkboxes on left and Image on right */}
      <Modal open hideBackdrop onClose={handleClose} sx={customStyles.modalBackgroundStyle}>
        {/* DIV contains backdrop style */}
        <div style={customStyles.modalStyle}>
          {/* HEADER Box Wrapper  */}
          <Box sx={customStyles.headerBoxStyle}>
            <FlexBox sx={customStyles.headerItemStyle}>
              <Typography
                sx={customStyles.headerStyle}
                className="request-photos-modal-header"
                variant="h6"
                component="h2"
              >
                {props.memberName}
              </Typography>
            </FlexBox>

            <FlexBox>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
            </FlexBox>
          </Box>

          {/* BODY Box Wrapper  */}
          <Box sx={customStyles.flexBoxStyle}>
            {/* LEFT SIDE: Checkbox List Items */}
            <FlexBox sx={customStyles.checkBoxListStyle}>
              <nav aria-label="secondary">
                <DrugScreeningRecords
                  udsRecord={udsRecord}
                  pregnancyRecord={pregnancyRecord}
                  fentanylRecord={fentanylRecord}
                  setSelectedUrl={setSelectedUrl}
                  testView={props.testView}
                />
              </nav>
            </FlexBox>

            {/* RIGHT SIDE: Drug Screening Image */}
            <FlexBox sx={customStyles.imageBoxStyle}>
              <ImageBox alt="testing_image" style={customStyles.imageStyle} imageSrc={url} />
            </FlexBox>
          </Box>
        </div>
      </Modal>
    </>
  );
}

PhotosModal.propTypes = {
  handleClose: PropTypes.func.isRequired,

  authenticityToken: PropTypes.string.isRequired,
  memberId: PropTypes.number.isRequired,
  memberName: PropTypes.string.isRequired,
  testIds: PropTypes.object.isRequired,
  testView: PropTypes.string.isRequired,
};

export default PhotosModal;
