import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import AttendanceWeekInformation from './AttendanceWeekInformation';

function AttendanceWeekContainer({ data }) {
  return (
    <div>
      {data?.map((weekData, _index) => (
        <AttendanceWeekInformation key={weekData?.id} data={weekData} />
      ))}
    </div>
  );
}

AttendanceWeekContainer.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AttendanceWeekContainer;
