import React from 'react';
import PropTypes from 'prop-types';

function AttendanceLabel({ label, secondaryLabel, classes }) {
  const labelClass = 'font-semibold text-sm';

  function renderSecondaryLabel() {
    if (!secondaryLabel) {
      return <></>;
    }
    return <div className="text-xs ml-1 my-auto">({secondaryLabel})</div>;
  }

  return (
    <div className={`flex mb-1 ${classes}`}>
      <div className={labelClass}>{label}</div>

      {renderSecondaryLabel()}
    </div>
  );
}

AttendanceLabel.propTypes = {
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  classes: PropTypes.string,
};

AttendanceLabel.defaultProps = {
  secondaryLabel: null,
  classes: null,
};

export default AttendanceLabel;
