import React from 'react';
import PropTypes from 'prop-types';

function ListItem(props) {
  return (
    <p className="m-0 mb-1">
      <span className="font-semibold">{props?.label}</span>
      {': '}
      {props?.text}
    </p>
  );
}

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ListItem;
