/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

const headers = (currentSort = null) => [
  {
    name: 'Member',
    accessorKey: 'member',
    isClickable: true,
    direction: 'asc',
    isSorting: currentSort?.includes('member'),
  },
  {
    name: 'Tx (Weeks)',
    accessorKey: 'weeks',
    isClickable: true,
    direction: 'asc',
    isSorting: currentSort?.includes('weeks'),
  },
  {
    name: 'Empaneled Day/Time',
    accessorKey: 'empanelment',
    direction: 'asc',
    isClickable: true,
    isSorting: currentSort?.includes('empanelment'),
  },
  {
    name: 'Preferences',
    accessorKey: 'preferences',
    direction: 'desc',
    isClickable: true,
    isSorting: currentSort?.includes('preferences'),
  },
  {
    name: 'Latest Supply',
    accessorKey: 'latest_supply',
    direction: 'asc',
    isClickable: true,
    isSorting: currentSort?.includes('latest_supply'),
  },
  {
    name: 'Tracking',
    accessorKey: 'tracking',
    direction: 'asc',
    isClickable: true,
    isSorting: currentSort?.includes('tracking'),
  },
];

const useTableHeaders = (featureFlagEdit) => {
  const [tableHeaders, setTableHeaders] = useState(headers);

  const updateHeaders = async (currentSort = null) => {
    const updatedHeaders = headers(currentSort, featureFlagEdit);
    setTableHeaders(updatedHeaders);
  };

  useEffect(() => {
    updateHeaders();
  }, [featureFlagEdit]);

  return { tableHeaders, updateHeaders };
};

export default useTableHeaders;
