export const getReferrerParams = (url) => {
  try {
    const mainUrl = url ?? window.location.href;
    const referrerUrl = decodeURIComponent(mainUrl.match(/referrer_url=(.*)/)?.[1]);

    if (referrerUrl) {
      const parsedUrl = new URL(referrerUrl);
      const params = new Proxy(new URLSearchParams(parsedUrl.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      return params;
    }
  } catch {
    return {};
  }

  return {};
};

export const appendReferrerParams = (url, params) => {
  if (!url) return null;

  const preparedParams = new URLSearchParams(params);

  if (url.includes('referrer_url')) {
    if (/referrer_url=(.*)\?/.test(url)) return `${url}&${preparedParams.toString()}`;
    return `${url}?${preparedParams.toString()}`;
  }

  const { hostname, protocol } = window.location;

  if (url.includes('?')) return `${url}&referrer_url=${protocol}//${hostname}?${preparedParams.toString()}`;

  return `${url}?referrer_url=${protocol}//${hostname}?${preparedParams.toString()}`;
};
