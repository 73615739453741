import React from 'react';
import { PropTypes } from 'prop-types';

import CompletedStatusIcon from './CompletedStatusIcon';
import InactiveStatusIcon from './InactiveStatusIcon';

function GoalTimeline({ status, testId }) {
  const goalStatusIcon = () => {
    if (status === 'completed') {
      return <CompletedStatusIcon testId={`completed-icon-${testId}`} />;
    }
    return <InactiveStatusIcon testId={`inactive-icon-${testId}`} />;
  };

  return (
    <div className="goal-timeline" data-testid={testId}>
      {goalStatusIcon()}
      <div className="vertical-line" data-testid={`line-${testId}`} />
    </div>
  );
}

GoalTimeline.propTypes = {
  status: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

export default GoalTimeline;
