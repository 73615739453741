import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import PageHeader from '../shared/PageHeader';
import UdsSuppliesTable from './UdsSuppliesTable';

// PAGE COMPONENTS
import PickupPage from './pickups/PickupPage';
import ShipmentPage from './shipments/ShipmentPage';

// HOOKS
import useUdsTimestamp from './hooks/use-uds-supplies-last-job-timestamp';
import useSharedFilters from '../shared/hooks/use-shared-filters';

function UdsSuppliesDashboard(props) {
  const { lastJobTimestamp } = useUdsTimestamp();

  // FETCH DATA
  const { locationsWithAddresses, fetchLocationsWithAddresses } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchLocationsWithAddresses();
  }, []);

  const [showShipmentPage, setShowShipmentPage] = useState(false);
  const [showOfficePickupPage, setShowOfficePickupPage] = useState(false);

  const renderTable = () => {
    if (showOfficePickupPage || showShipmentPage) {
      return <></>;
    }
    return (
      <>
        <PageHeader text="Screening Supplies" subtext={lastJobTimestamp} />
        <UdsSuppliesTable
          locations={locationsWithAddresses}
          authenticityToken={props.authenticityToken}
          featureFlagEdit={props.featureFlagEdit}
          setShowPickupPage={() => {
            setShowOfficePickupPage(true);
          }}
          setShowShipPage={() => {
            setShowShipmentPage(true);
          }}
        />
      </>
    );
  };

  const renderShowOfficePickupPage = () => {
    if (!showOfficePickupPage) {
      return <></>;
    }
    return (
      <PickupPage
        locations={locationsWithAddresses}
        authenticityToken={props.authenticityToken}
        setShowPickupPage={() => {
          setShowOfficePickupPage(false);
        }}
      />
    );
  };

  const renderShowShipmentPage = () => {
    if (!showShipmentPage) {
      return <></>;
    }
    return (
      <ShipmentPage
        locations={locationsWithAddresses}
        authenticityToken={props.authenticityToken}
        setShowShipPage={() => {
          setShowShipmentPage(false);
        }}
        isRegionalDirector={props.isRegionalDirector}
      />
    );
  };

  return (
    <div className="mb-10">
      {renderTable()}

      {renderShowOfficePickupPage()}
      {renderShowShipmentPage()}
    </div>
  );
}

UdsSuppliesDashboard.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  featureFlagEdit: PropTypes.bool.isRequired,
  isRegionalDirector: PropTypes.bool.isRequired,
};

export default UdsSuppliesDashboard;
