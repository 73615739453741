import { useEffect, useState } from 'react';

// HELPERS
import { mdyyyy } from '../../../helpers/DateFormatter';

const useUdsTimestamp = () => {
  const [lastJobTimestamp, setLastJobTimestamp] = useState(null);

  const fetchLastJobTimestamp = async () => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const formattedTimestamp = `Last synced with eCW 10:30PM ET - ${mdyyyy(yesterday)}`;

    setLastJobTimestamp(formattedTimestamp);
  };

  useEffect(() => {
    fetchLastJobTimestamp();
  }, []);

  return {
    lastJobTimestamp,
  };
};

export default useUdsTimestamp;
