import React from 'react';
import PropTypes from 'prop-types';

import ProgramExpectationIcon from './ProgramExpectationIcon';

function ProgramExpectationGoal({ testId }) {
  return (
    <div className="program-expectation-goal-container goal-container" data-testid={`${testId}`}>
      <h4 className="goal-type" data-testid={`type-${testId}`}>
        PROGRAM EXPECTATION
      </h4>
      <div className="goal-row" data-testid={`row-${testId}`}>
        <ProgramExpectationIcon testId={`icon-${testId}`} />
        <p className="program-expectation-goal goal-title-large" data-testid={`description-${testId}`}>
          Come to group 4 times this month and submit my screenings on-time
        </p>
      </div>
    </div>
  );
}

ProgramExpectationGoal.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default ProgramExpectationGoal;
