import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../../../GroupsModal';
import { formatWithDuration } from '../../../../helpers/TimezoneHelper';
import { EVERY_STATE_TYPE_APPOINTMENT } from './constants';
import { OptionPropType } from '../shared/types';
import { mdyyyy } from '../../../../helpers/DateFormatter';

function AddMemberConfirmationModal({ isOpen, appointment, member, onCancel, onConfirm, isLoading, reason }) {
  const facilityName =
    appointment?.appointmentType === EVERY_STATE_TYPE_APPOINTMENT
      ? 'All locations'
      : appointment?.locations?.map((loc) => loc.name).join(', ');

  const appointmentDetails = appointment
    ? [
        {
          name: 'Date',
          value: mdyyyy(appointment.scheduledAt),
        },
        {
          name: 'Time',
          value: formatWithDuration(appointment.scheduledAt, appointment.duration),
        },
        {
          name: 'Type',
          value: appointment.appointmentType,
        },
        {
          name: 'Facility',
          value: facilityName,
        },
        {
          name: 'Staff Members',
          value: appointment.providers,
        },
      ]
    : [];

  if (appointment?.isIndividual) {
    appointmentDetails.push({
      name: 'Reason',
      value: reason?.label,
    });
  }

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onCancel,
    },
    {
      label: 'Confirm',
      isPrimary: true,
      onClick: onConfirm,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Are you sure?"
      footerButtons={footerButtons}
      size="small"
      testID="add-member-confirmation-modal"
      isLoading={isLoading}
    >
      <p className="mb-0">
        Clicking "confirm" will add
        <span className="font-bold">{` ${member.name} `}</span>
        to the following appointment.
        <span className="font-semibold text-error-700"> This action cannot be undone.</span>
      </p>
      <ul>
        {appointmentDetails.map(({ name, value }) => (
          <li key={name}>
            <span className="font-bold">{`${name}: `}</span>
            {value || 'N/A'}
          </li>
        ))}
      </ul>
    </GroupsModal>
  );
}

AddMemberConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  appointment: PropTypes.shape({
    appointmentType: PropTypes.string.isRequired,
    scheduledAt: PropTypes.string.isRequired,
    locations: PropTypes.array.isRequired,
    duration: PropTypes.number.isRequired,
    providers: PropTypes.string.isRequired,
    isIndividual: PropTypes.bool.isRequired,
  }),
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  reason: OptionPropType,
};

AddMemberConfirmationModal.defaultProps = {
  appointment: null,
  isLoading: false,
  reason: null,
};

export default AddMemberConfirmationModal;
