import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from '../../member_summary/InfoCard';
import PhotoIDRow from './rows/PhotoIDRow';
import { FacilityPropTypes, LanguagePropTypes, MemberPropTypes } from './types';
import PrimaryLanguageRow from './rows/PrimaryLanguageRow';
import DefaultFacilityRow from './rows/DefaultFacilityRow';
import SocialSecurityNumberRow from './rows/SocialSecurityNumberRow';
import useHttp from '../../shared/hooks/use-http';
import useToasts from '../../shared/hooks/use-toasts';
import Toasts from '../../shared/Toasts';
import BoolDetailsRow from './BoolDetailsRow';

function PersonalInformation({
  data: {
    socialSecurityNumber,
    isDeceased,
    primaryLanguage,
    languageInterpretiveServices,
    defaultFacility,
    photo,
  } = {},
  allFacilities,
  allLanguages,
  member,
  authenticityToken,
}) {
  const { sendRequest, isLoading, error } = useHttp();
  const { toasts, removeToast, addToast } = useToasts();

  const handleMemberEdit = async (fieldsToEdit, setIsEditing) => {
    try {
      const response = await sendRequest(`/staff/members/${member?.id}`, {
        method: 'PATCH',
        data: {
          member: fieldsToEdit,
        },
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
      });

      if (error) {
        addToast({ type: 'error', header: response?.error });
      }
    } catch (e) {
      addToast({ type: 'error', header: e?.message ?? 'Something went wrong' });
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <>
      <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" />

      <InfoCard
        heading="Personal Information"
        classNames="bg-white border-0 shadow-sm"
        childrenClassNames="divide-y divide-gray-300"
      >
        <SocialSecurityNumberRow
          socialSecurityNumber={socialSecurityNumber}
          handleMemberEdit={handleMemberEdit}
          isLoadingRequest={isLoading}
        />
        <BoolDetailsRow
          initialValue={{ value: isDeceased, label: isDeceased ? 'Yes' : 'No' }}
          label="Is Deceased"
          name="is-deceased"
          isLoadingRequest={isLoading}
          handleMemberEdit={handleMemberEdit}
          editKey="is_deceased"
        />
        <PrimaryLanguageRow
          primaryLanguage={primaryLanguage}
          allLanguages={allLanguages}
          handleMemberEdit={handleMemberEdit}
          isLoadingRequest={isLoading}
        />
        <BoolDetailsRow
          initialValue={{
            value: languageInterpretiveServices,
            label: languageInterpretiveServices ? 'Yes' : 'No',
          }}
          label="Language (Interpretive Services)"
          name="language-interpretive-services"
          isLoadingRequest={isLoading}
          handleMemberEdit={handleMemberEdit}
          editKey="is_interpretive_service_needed"
        />
        <DefaultFacilityRow
          defaultFacility={defaultFacility}
          allFacilities={allFacilities}
          handleMemberEdit={handleMemberEdit}
          isLoadingRequest={isLoading}
        />
        <PhotoIDRow photo={photo} member={member} />
      </InfoCard>
    </>
  );
}

PersonalInformation.propTypes = {
  member: MemberPropTypes.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.shape({
    socialSecurityNumber: PropTypes.string.isRequired,
    isDeceased: PropTypes.bool.isRequired,
    primaryLanguage: LanguagePropTypes.isRequired,
    languageInterpretiveServices: PropTypes.bool.isRequired,
    defaultFacility: FacilityPropTypes,
    photo: PropTypes.shape({
      datetime: PropTypes.string.isRequired,
      frontUrl: PropTypes.string.isRequired,
      backUrl: PropTypes.string,
    }).isRequired,
  }),
  allLanguages: PropTypes.arrayOf(LanguagePropTypes),
  allFacilities: PropTypes.arrayOf(FacilityPropTypes),
};

PersonalInformation.defaultProps = {
  data: {
    socialSecurityNumber: '',
    isDeceased: false,
    primaryLanguage: {},
    languageInterpretiveServices: false,
    defaultFacility: {},
    photo: {},
  },
  allLanguages: [],
  allFacilities: [],
};

export default PersonalInformation;
