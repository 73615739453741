const setParams = (
  pageNumber,
  isEmpaneled,
  isPending,
  isApproved,
  hasCurrentWeeklyGroup = null,
  statesFilters = [],
  locationFilters = [],
  officeManagerFilters = [],
  counselorFilters = [],
  providerFilters = [],
  daysFilters = [],
  nameSortType = 'name asc'
) => {
  const state_ids = statesFilters.map((state) => state.value);
  const location_ids = locationFilters.map((facility) => facility.value);
  const office_manager_ids = officeManagerFilters.map((om) => om.value);
  const counselor_ids = counselorFilters.map((counselor) => counselor.value);
  const provider_ids = providerFilters.map((provider) => provider.value);
  const days = daysFilters.map((day) => day.value);

  const params = {
    page: pageNumber,
    is_empaneled: isEmpaneled,
    not_empaneled: isPending,
    is_flagged: isApproved,
    has_current_weekly_group: hasCurrentWeeklyGroup,
    state_ids: state_ids.includes('*') ? null : state_ids,
    location_ids: location_ids.includes('*') ? null : location_ids,
    office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
    counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
    provider_ids: provider_ids.includes('*') ? null : provider_ids,
    days: days.includes('*') ? null : days,
    sort_name: nameSortType,
  };

  return params;
};

export default setParams;
