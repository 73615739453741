import React from 'react';
import PropTypes from 'prop-types';

import { HiOutlinePencil } from 'react-icons/hi';

function EditActionIcon({ testId }) {
  return (
    <div className="action-icon edit-action-icon" data-testid={testId}>
      <HiOutlinePencil />
    </div>
  );
}

EditActionIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default EditActionIcon;
