import React from 'react';
import PropTypes from 'prop-types';

function MonthDetailedStats({ month }) {
  const presentWeeks = month.weeks?.filter((week) => week);
  const monthLabel = month.monthLabel.length <= 3 ? month.monthLabel : month.monthLabel.slice(0, 3);

  return (
    <div className="flex gap-3 items-center" key={monthLabel}>
      <span className="text-xs font-semibold w-6">{monthLabel}</span>
      <div className="flex flex-grow gap-1">
        {month.weeks?.map((week, index) => {
          const fillColor = week ? 'bg-blue-800' : 'bg-gray-300';
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${monthLabel}-${index}`} className={`${fillColor} rounded-sm h-2.5 flex-grow`} />
          );
        })}
      </div>
      <span className="text-xs font-semibold w-6 text-center">{`${presentWeeks?.length}/${month?.weeks?.length}`}</span>
    </div>
  );
}

MonthDetailedStats.propTypes = {
  month: PropTypes.shape({
    monthLabel: PropTypes.string.isRequired,
    weeks: PropTypes.arrayOf(PropTypes.bool).isRequired,
  }).isRequired,
};

export default MonthDetailedStats;
