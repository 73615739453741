/**
 * DOCUMENTATION: https://groupsrecovertogether.atlassian.net/wiki/spaces/DP/pages/1097826320/Staff+Dashboard+Refactor+Technical+Documentation
 *
 * For more details on the rendering of the Staff Dashboard, click the above link.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from '../shared/Button';
import PageHeader from '../shared/PageHeader';
import Filters from '../shared/DashboardFilters';
import WeeklyGroupsDashboard from './WeeklyGroupsDashboard';
import TodaysAppointmentsDashboard from './TodaysAppointmentsDashboard';
import FutureAppointmentsDashboard from './FutureAppointmentsDashboard';
import TimezoneSetterMessage from '../shared_dashboard/TimezoneSetterMessage';

// HELPERS
import { parseIdsFromArray } from '../../helpers/DashboardFilterHelpers';

// HOOKS
import useFilters from './hooks/use-filters';

function StaffDashboard(props) {
  /** Filter Logic */
  const { accountStatuses, appointmentTypes, statesFilters, staffFilters } = useFilters();
  const [filterApplyClicked, setFilterApplyClicked] = useState(false);

  /** Filter States */
  const [accountStatus, setAccountStatus] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);
  const [state, setState] = useState(null);
  const [staff, setStaff] = useState([props.defaultStaffUser]);
  const [sendUpdate, setSendUpdate] = useState(false);

  /**
   * @function useEffect
   *
   * Reacts stateChange of @var sendUpdate
   * This triggers the set @var filterApplyClicked.
   *
   * @var filterApplyClicked trigger backend call within the @function useEffect of sub-dashboard
   * Example: see app/javascript/components/dashboard/WeeklyGroupsDashboard.js
   */
  useEffect(() => {
    if (!sendUpdate) {
      return;
    }

    setFilterApplyClicked({
      with_account: parseIdsFromArray(accountStatus),
      appointment_type: parseIdsFromArray(appointmentType),
      state: parseIdsFromArray(state),
      staff: parseIdsFromArray(staff),
    });

    setSendUpdate(false);
  }, [sendUpdate, accountStatus, appointmentType, staff, state]);

  /**
   * @function onFilterApply
   *
   * Reacts to onClick of 'Apply' button.
   * The function sets send update. This triggers the @function useEffect to set @var filterApplyClicked.
   *
   * @var filterApplyClicked trigger backend call within the @function useEffect of sub-dashboard
   * Example: see app/javascript/components/dashboard/WeeklyGroupsDashboard.js
   */
  const onFilterApply = () => {
    setSendUpdate(true);
  };

  /**
   * @function onFilterClear
   *
   * Reacts to onClick of 'Clear' button.
   * The function updates (resets) the states of all the filters to their first value.
   *
   * Example of @param resettedFilterValues.accountStatus: [ value: '*', label: 'All Staff' ]
   *
   * When @function filterApplyClicked, the data will update.
   */
  const onFilterClear = () => {
    const resettedFilterValues = {
      accountStatus: accountStatuses[0],
      appointmentType: [appointmentTypes[0]],
      state: [statesFilters[0]],
      staff: [staffFilters[0]],
    };

    setAccountStatus(resettedFilterValues.accountStatus);
    setAppointmentType(resettedFilterValues.appointmentType);
    setState(resettedFilterValues.state);
    setStaff(resettedFilterValues.staff);
  };

  return (
    <section data-controller="user-timezone invites">
      <TimezoneSetterMessage type="timezoneSetter" />

      <PageHeader text="Dashboard" />

      <div className="flex flex-col lg:flex-row lg:items-end gap-2 mb-6">
        {/* ACCOUNT STATUS */}
        <Filters
          isMulti={false}
          type="account_status"
          label="Account Status"
          data={accountStatuses}
          defaultValue={accountStatus || accountStatuses[0]}
          stateChanger={setAccountStatus}
        />

        {/* APPOINTMENT TYPE */}
        <Filters
          isMulti
          type="appointment_type"
          label="Appointment Type"
          data={appointmentTypes}
          defaultValue={appointmentType || appointmentTypes[0]}
          stateChanger={setAppointmentType}
          removeArrowIndicator
        />

        {/* STATE */}
        <Filters
          isMulti
          type="states"
          label="States"
          data={statesFilters}
          defaultValue={state || statesFilters[0]}
          stateChanger={setState}
          removeArrowIndicator
        />

        {/* STAFF */}
        <Filters
          isMulti
          type="staff"
          label="Staff Members"
          data={staffFilters}
          defaultValue={staff || staffFilters[0]}
          stateChanger={setStaff}
          removeArrowIndicator
        />

        <div className="flex flex-row lg:justify-end gap-2">
          <Button classes="btn btn--secondary btn--sm mr-2" onClick={onFilterApply}>
            Apply
          </Button>

          <Button classes="btn btn--tertiary btn--sm" onClick={onFilterClear}>
            Clear
          </Button>
        </div>
      </div>

      <WeeklyGroupsDashboard appliedFilters={filterApplyClicked} />

      <TodaysAppointmentsDashboard authenticityToken={props.authenticityToken} appliedFilters={filterApplyClicked} />

      <FutureAppointmentsDashboard authenticityToken={props.authenticityToken} appliedFilters={filterApplyClicked} />
    </section>
  );
}

StaffDashboard.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  defaultStaffUser: PropTypes.string,
};

StaffDashboard.defaultProps = {
  defaultStaffUser: null,
};

export default StaffDashboard;
