import React from 'react';
import PropTypes from 'prop-types';

import GoalEditButton from './GoalEditButton';
import { formatShortDateTime } from '../../DateFormatHelper';

function HistoricalGoalDetails({ goal, testId }) {
  return (
    <div className="historical-goal-details" data-testid={testId}>
      <div className="goal-details-container" data-testid={`container-${testId}`}>
        <p className="goal-title-large" data-testid={`title-${testId}`}>
          {goal.title}
        </p>
        <p data-testid={`created-time-${testId}`}>
          {`Created ${formatShortDateTime(goal.created_at)}
          by ${goal.created_by}`}
        </p>
      </div>
      <GoalEditButton testId={`edit-${testId}`} />
    </div>
  );
}

HistoricalGoalDetails.propTypes = {
  goal: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
};

export default HistoricalGoalDetails;
