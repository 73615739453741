import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import axios from 'axios';
import LatestVisitCell from './LatestVisitCell';
import NextAvailableTimeCell from './NextAvailableTimeCell';
import SortableTableHeader from './SortableTableHeader';

function PrescriptionSupportTable({ memberId, testId, schedulesFlag, timeZone }) {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    axios
      .get(`/staff/members/${memberId}/prescribers`)
      .then((response) => {
        setData(response.data?.prescribers);
        setIsLoaded(true);
      })
      .catch((error) => {
        window.Sentry.captureException(error.response.data);
      });
  }, [memberId]);

  const sortPrescribers = (column, direction) => {
    setData(
      data.sort((a, b) => {
        if (a[column] === b[column]) {
          return 0;
        }

        if (a[column] === null) {
          return 1;
        }

        if (b[column] === null) {
          return -1;
        }

        if (direction === 'asc') {
          return a[column] < b[column] ? -1 : 1;
        }

        return a[column] < b[column] ? 1 : -1;
      })
    );
  };

  const handleSort = (column) => {
    let direction = sortDirection === 'asc' ? 'desc' : 'asc';
    if (column === sortColumn) {
      setSortDirection(direction);
    } else {
      direction = 'asc';
      setSortColumn(column);
      setSortDirection(direction);
    }
    sortPrescribers(column, direction);
  };

  if (data?.length) {
    return (
      <TableContainer className="modal-table-container" data-testid={`${testId}-table-container`} component={Paper}>
        <Table stickyHeader data-testid={`${testId}-table`}>
          <TableHead>
            <TableRow>
              <SortableTableHeader onClick={handleSort} sortKey="provider_last_name">
                <strong>Prescriber</strong>
                <UnfoldMoreIcon />
              </SortableTableHeader>
              <SortableTableHeader onClick={handleSort} sortKey="date_seen">
                <strong>Has Seen Member</strong>
                <UnfoldMoreIcon />
              </SortableTableHeader>
              {schedulesFlag && (
                <SortableTableHeader>
                  <strong>Next Available Time ({timeZone})</strong>
                </SortableTableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{`${row.provider_first_name} ${row.provider_last_name}`}</TableCell>
                <TableCell>
                  <LatestVisitCell visitDate={row.date_seen} visitType={row.appointment_type} />
                </TableCell>
                {schedulesFlag && (
                  <TableCell>
                    <NextAvailableTimeCell
                      todaysSchedule={row.todays_schedule}
                      nextDaysSchedule={row.next_days_schedule}
                      jobType={row.job_type}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  if (isLoaded) {
    return (
      <TableContainer component={Paper}>
        <div className="no-content-table">
          <h3>There are no eligible prescribers</h3>
          <p>This member needs a provider visit</p>
        </div>
      </TableContainer>
    );
  }
  return <div />;
}

PrescriptionSupportTable.propTypes = {
  memberId: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
  schedulesFlag: PropTypes.bool.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default PrescriptionSupportTable;
