import React from 'react';
import Table from '../../../Table';
import { GroupAppointmentCamelCasePropTypes } from '../shared/types';

const TABLE_HEADERS = [
  {
    name: 'Name',
    showColumn: true,
  },
  {
    key: 'carePlanSignatures',
    name: (
      <div className="flex flex-col gap-1 -my-4">
        <span>Care Plan Signatures</span>
        <div className="grid grid-cols-2 gap-1">
          <span className="text-sm text-center">Member</span>
          <span className="text-sm text-center">Staff</span>
        </div>
      </div>
    ),
    showColumn: true,
    classes: 'w-5',
  },
  {
    name: 'Health Check-in',
    showColumn: true,
  },
  {
    name: 'UDS Status',
    showColumn: true,
  },
  {
    name: 'UDS',
    showColumn: true,
  },
  {
    name: 'Fentanyl',
    showColumn: true,
  },
  {
    name: 'Pregnancy',
    showColumn: true,
  },
  {
    name: 'DOB',
    showColumn: true,
  },
  {
    name: 'Phone',
    showColumn: true,
  },
];

function SnapshotTab({ appointment }) {
  return (
    <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
      <Table
        tableType="membersSnapshot"
        testID="membersSnapshot"
        showShadow={false}
        enableActionsColumn={false}
        hideLoadMoreButton
        showPageNumbers={false}
        showBottomMargin={false}
        tableRows={appointment.members}
        tableColumnHeaders={TABLE_HEADERS}
      />
    </div>
  );
}

SnapshotTab.propTypes = {
  appointment: GroupAppointmentCamelCasePropTypes.isRequired,
};

export default SnapshotTab;
