import { array, object, string } from 'yup';

const REQUIRED_TEXT = 'This field is required';
const DROPDOWN_ITEM_SCHEMA = object({ value: string(), label: string() });
const DROPDOWN_SCHEMA = array(DROPDOWN_ITEM_SCHEMA).min(1, REQUIRED_TEXT);

export const FORM_ID = 'add-edit-staff-member-facility-form';
export const DEFAULT_OPTION_VALUE = 'Default';
export const DEFAULT_OPTION = { value: DEFAULT_OPTION_VALUE, label: DEFAULT_OPTION_VALUE };
export const SCHEMA = object({
  user: DROPDOWN_ITEM_SCHEMA.required(REQUIRED_TEXT),
  locations: DROPDOWN_SCHEMA,
  appointmentTypes: DROPDOWN_SCHEMA,
});

export const getDefaultSingleValue = (value) => ({
  value: value?.id || value,
  label: value?.fullName || value?.name || value,
});

export const getDefaultValue = (values) => {
  if (!values) return null;

  return Array.isArray(values) ? values?.map(getDefaultSingleValue) : getDefaultSingleValue(values);
};

export const getRequestData = (data) => ({
  appointment_types: data.appointmentTypes.map(({ value }) => value),
  location_ids: data.locations.map(({ value }) => value),
  staff_member_id: data.user.value,
});

export const findDefaultOption = (appointmentTypes) =>
  appointmentTypes?.find(({ value }) => value === DEFAULT_OPTION_VALUE);
