import { getUserTimeZoneAbbrev } from '../../../../helpers/TimezoneHelper';

export const TAB_IDS = {
  PANEL_SUMMARY: 0,
  SNAPSHOT: 1,
  ATTENDANCE: 2,
};

export const PageTabs = [
  { id: TAB_IDS.PANEL_SUMMARY, name: 'Panel Summary' },
  { id: TAB_IDS.SNAPSHOT, name: 'Snapshot' },
  { id: TAB_IDS.ATTENDANCE, name: 'Attendance' },
];

export const userTimezone = getUserTimeZoneAbbrev();

export const AttendanceTableHeaders = [
  {
    name: 'Member',
    showColumn: true,
  },
  {
    name: 'Status',
    showColumn: true,
  },
  {
    name: 'Location',
    showColumn: true,
  },
];
