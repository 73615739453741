// eslint-disable-next-line import/prefer-default-export
export const TABLE_HEADERS = [
  {
    name: 'Name',
    showColumn: true,
  },
];

export const TABLE_HEADERS_WITH_ACTIONS = [
  ...TABLE_HEADERS,
  {
    name: 'Action',
    showColumn: true,
    actionColumn: true,
  },
];
