import React from 'react';
import PropTypes from 'prop-types';
import StatusTagsContainer from '../shared/StatusTagsContainer';
import { mdyyyy } from '../../helpers/DateFormatter';

function MemberInformation({ member, statuses }) {
  const memberName = `${member?.firstName} ${member?.lastName}`;

  return (
    <div className="flex gap-5 items-center">
      {member?.avatar && (
        <img src={member.avatar} alt={`${memberName} avatar`} className="rounded-full w-20 h-20 object-cover" />
      )}
      <div className="flex flex-col gap-3">
        <div className="text-3xl text-teal-700 underline font-bold">{memberName}</div>
        <div className="flex items-center gap-1">
          {member?.mrn && <div className="text-gray-600 font-semibold">{member.mrn}</div>}
          {member?.status && (
            <>
              <span className="mx-1 text-gray-200">•</span>
              <span className="text-gray-600 text-xs bg-gray-200 py-1 px-2 rounded font-semibold">{member.status}</span>
              <span className="text-gray-600 text-xs">In this status from {mdyyyy(member.currentStatusFrom)}</span>
            </>
          )}
        </div>
        {statuses && statuses?.length > 0 && <StatusTagsContainer statuses={statuses} fullHeightTags={false} />}
      </div>
    </div>
  );
}

MemberInformation.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    mrn: PropTypes.string,
    avatar: PropTypes.string,
    status: PropTypes.string,
    currentStatusFrom: PropTypes.string,
  }).isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
};

MemberInformation.defaultProps = {
  statuses: [],
};

export default MemberInformation;
