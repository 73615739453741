import React from 'react';
import PropTypes from 'prop-types';
import useSanitizeHtml from './hooks/use-sanitize-html';

function RenderHTML({ html, testId, classNames, allowedAttributes }) {
  const sanitizedHtml = useSanitizeHtml(html, allowedAttributes);

  return (
    <div
      data-testid={testId}
      className={classNames}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
}

RenderHTML.propTypes = {
  html: PropTypes.string.isRequired,
  testId: PropTypes.string,
  classNames: PropTypes.string,
  allowedAttributes: PropTypes.arrayOf(PropTypes.string),
};

RenderHTML.defaultProps = {
  testId: undefined,
  classNames: undefined,
  allowedAttributes: [],
};

export default RenderHTML;
