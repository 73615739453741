import React from 'react';
import PropTypes from 'prop-types';

function InformationContainer(props) {
  const bgClass = props?.isBgBlue ? 'bg-blue-100' : '';
  const paddingClass = props?.addPadding ? 'p-2' : '';

  return (
    <div
      className={`${bgClass} ${paddingClass} m-2 border border-gray-300 rounded-sm ${props.classes}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

InformationContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string.isRequired,

  // OPTIONAL
  isBgBlue: PropTypes.bool,
  addPadding: PropTypes.bool,
  style: PropTypes.object,
};

InformationContainer.defaultProps = {
  isBgBlue: false,
  addPadding: false,
  style: {},
};

export default InformationContainer;
