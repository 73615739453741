import React from 'react';
import PropTypes from 'prop-types';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';

function LastSynced({ dateTime, wasEdited, person }) {
  const [convertedDateTime, timeZone] = convertToSupportedTimeZone(dateTime);
  const personText = person ? ` by ${person.firstName} ${person.lastName}` : '';

  return (
    <p className="text-sm text-gray-700 mb-0">
      Last
      {wasEdited ? ' edited' : ' synchronized'} {convertedDateTime.format('M/D/YY, h:mm A')} {timeZone.abbrev}
      {personText}
    </p>
  );
}

LastSynced.propTypes = {
  dateTime: PropTypes.oneOf(PropTypes.string, PropTypes.instanceOf(Date)).isRequired,
  wasEdited: PropTypes.bool,
  person: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
};

LastSynced.defaultProps = {
  wasEdited: false,
  person: null,
};

export default LastSynced;
