import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function MembersErrorModal(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const testID = `membersErrorModal-${props.testID}`;

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      {isOpen && (
        <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              <div data-testid={`errorMsg-${testID}`} className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Wait!</h2>

                {props.errorMessage}

                <div className="flex mt-4">
                  <button
                    data-testid={`backBtn-${testID}`}
                    className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                    onClick={props.onClose}
                    type="button"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

MembersErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
};

export default MembersErrorModal;
