import moment from 'moment';
import { getCurrentDateTimeByTimezone } from '../helpers/TimezoneHelper';

export const isEmptyObject = (obj) => !obj?.value;

export const isNotPastGroupDateSchema = ([timezone], schema) =>
  schema.test(
    'is-not-past-date',
    'Must not be past date',
    (value) => !getCurrentDateTimeByTimezone().isAfter(moment(value).tz(timezone?.value, true), 'day')
  );
