import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import GroupsTableCell from './GroupsTableCell';
import ManagementGroupsTableCell from './ManagementGroupsTableCell';
import MembersTableCell from './MembersTableCell';
import PanelTableCell from './PanelTableCell';
import AppointmentsTableCell from './empanelment/appointments/list/AppointmentsTableCell';
import GroupAppointmentsTableRow from './empanelment/appointments/list/GroupAppointmentsTableRow';
import IndividualAppointmentsTableRow from './empanelment/appointments/list/IndividualAppointmentsTableRow';
import MembersListTableCell from './empanelment/members_list/MembersListTableCell';
import FormExtensionsTableRow from './documentation/form_extensions/FormExtensionsTableRow';
import MemberEncounterTableCell from './empanelment/member_encounter_history/MemberEncounterTableCell';
import PrescriptionQueueTableRow from './prescriptions/list/PrescriptionQueueTableRow';
import MemberSnapshotRow from './empanelment/appointments/group_details/MemberSnapshotRow';
import MemberAttendanceRow from './empanelment/appointments/group_details/MemberAttendanceRow';
import PrescriberLookupRow from './prescriber_lookup/PrescriberLookupRow';
import StaffMemberFacilitiesTableRow from './empanelment/appointments/list/StaffMemberFacilitiesTableRow';

function TableRow(props) {
  const [row, setRow] = useState(props.row);
  const testID = `tableRow-${row.id}`;

  useMemo(() => {
    setRow(props.row);
  }, [props.row]);

  if (props.tableType === 'managementGroupsTable') {
    return (
      <tr>
        <ManagementGroupsTableCell
          stickyActionColumn={props.stickyActionColumn}
          enableActionsColumn={props.enableActionsColumn}
          testID={testID}
          activeTab={props.activeTab}
          authenticityToken={props.authenticityToken}
          row={row}
          editModalFilters={props.editModalFilters}
          updateRows={props.updateRows}
          onClickEditRow={props.onClickEditRow}
          onDeleteButtonClick={props.onDeleteButtonClick}
          onAddButtonClick={props.onAddButtonClick}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'groupsTable') {
    return (
      <tr>
        <GroupsTableCell
          testID={testID}
          activeTab={props.activeTab}
          authenticityToken={props.authenticityToken}
          secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
          row={row}
          editModalFilters={props.editModalFilters}
          updateRows={props.updateRows}
        />
      </tr>
    );
  }

  if (props.tableType === 'membersTable') {
    return (
      <tr>
        <MembersTableCell
          activeTab={props.activeTab}
          authenticityToken={props.authenticityToken}
          secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
          row={row}
          editModalFilters={props.editModalFilters}
          updateRows={props.updateRows}
          testID={testID}
        />
      </tr>
    );
  }

  if (props.tableType === 'panelTable') {
    return (
      <tr>
        <PanelTableCell
          activeTab={props.activeTab}
          authenticityToken={props.authenticityToken}
          secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
          row={row}
          updateRows={props.updateRows}
          testID={testID}
        />
      </tr>
    );
  }

  if (props.tableType === 'appointmentsTable') {
    return (
      <tr>
        <AppointmentsTableCell
          row={row}
          onAddButtonClick={props.onAddButtonClick}
          testID={testID}
          enableActionsColumn={props.enableActionsColumn}
        />
      </tr>
    );
  }

  if (props.tableType === 'healthieFormExtensionsTable') {
    return (
      <tr>
        <FormExtensionsTableRow
          row={row}
          onEditButtonClick={props.onClickEditRow}
          testID={testID}
          enableActionsColumn={props.enableActionsColumn}
        />
      </tr>
    );
  }

  if (props.tableType === 'memberEncounter') {
    return (
      <tr className="h-20">
        <MemberEncounterTableCell
          row={row}
          testID={testID}
          onClickEdit={props.onClickEditRow}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'membersTableDP2') {
    return (
      <tr
        style={
          props.selected
            ? {
                backgroundColor: 'var(--color-yellow-100)',
              }
            : undefined
        }
      >
        <MembersListTableCell
          row={row}
          testID={testID}
          onRowCheckboxChange={props.onRowCheckboxChange}
          selected={props.selected}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'prescriptionQueueTable') {
    return (
      <tr
        style={
          props.selected
            ? {
                backgroundColor: 'var(--color-yellow-100)',
              }
            : undefined
        }
      >
        <PrescriptionQueueTableRow
          row={row}
          testID={testID}
          onRowCheckboxChange={props.onRowCheckboxChange}
          selected={props.selected}
          enableActionsColumn={props.enableActionsColumn}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'groupAppointments') {
    return (
      <tr>
        <GroupAppointmentsTableRow
          row={row}
          testID={testID}
          enableActionsColumn={props.enableActionsColumn}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'individualAppointments') {
    return (
      <tr>
        <IndividualAppointmentsTableRow
          row={row}
          testID={testID}
          enableActionsColumn={props.enableActionsColumn}
          containerElement={props.containerElement}
        />
      </tr>
    );
  }

  if (props.tableType === 'membersSnapshot') {
    return (
      <tr>
        <MemberSnapshotRow row={row} testID={testID} />
      </tr>
    );
  }

  if (props.tableType === 'membersAttendanceGroupAppointmentTable') {
    return (
      <tr>
        <MemberAttendanceRow
          row={row}
          testID={testID}
          selected={props.selected}
          onRowCheckboxChange={props.onRowCheckboxChange}
        />
      </tr>
    );
  }

  if (props.tableType === 'prescriberLookup') {
    return (
      <tr>
        <PrescriberLookupRow row={row} />
      </tr>
    );
  }

  if (props.tableType === 'staffMemberFacilitiesTable') {
    return (
      <tr>
        <StaffMemberFacilitiesTableRow
          row={row}
          onEditButtonClick={props.onClickEditRow}
          onDeleteButtonClick={props.onDeleteButtonClick}
        />
      </tr>
    );
  }
}

TableRow.propTypes = {
  activeTab: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  onClickEditRow: PropTypes.func,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  stickyActionColumn: PropTypes.bool,
  enableActionsColumn: PropTypes.bool,
  selected: PropTypes.bool,
  containerElement: PropTypes.instanceOf(HTMLElement),

  row: PropTypes.object.isRequired,
  tableType: PropTypes.string,

  // edit data
  editModalFilters: PropTypes.object,

  // Functions
  updateRows: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  onRowCheckboxChange: PropTypes.func,
};

TableRow.defaultProps = {
  stickyActionColumn: false,
  enableActionsColumn: true,
  selected: false,
  containerElement: null,
  tableType: '',
  editModalFilters: {},
  onClickEditRow: () => {},
  onDeleteButtonClick: () => {},
  onAddButtonClick: () => {},
  onRowCheckboxChange: () => {},
};

export default TableRow;
