import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';
import Input from './Input';

// eslint-disable-next-line react/display-name
const CheckboxGroup = forwardRef(
  ({ label, name, value, onBlur, onChange, error, disabled, required, isVertical, options }, ref) => {
    const handleInputChange = (selectedOption) => {
      if (!value) {
        onChange([selectedOption]);
        return;
      }

      const newValue =
        value && value.includes(selectedOption)
          ? value.filter((option) => option !== selectedOption)
          : [...value, selectedOption];

      onChange(newValue.length ? newValue : null);
    };

    if (!options?.length) return null;

    return (
      <>
        <InputWrapper
          label={label}
          name={`${name}-${options?.[0]}`}
          required={required}
          error={error}
          isVertical={isVertical}
        >
          {options?.map((option) => (
            <div key={option} className="py-2">
              <Input
                ref={ref}
                name={`${name}-${option}`}
                value={option}
                onChange={() => handleInputChange(option)}
                onBlur={onBlur}
                checked={value?.includes(option)}
                type="checkbox"
                required={required}
                option={option}
                disabled={disabled}
              />
            </div>
          ))}
        </InputWrapper>
        <input data-testid="input-hidden" type="hidden" name={name} value={value || []} />
      </>
    );
  }
);

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  isVertical: true,
  options: null,
  error: null,
  value: null,
};

export default CheckboxGroup;
