import { useState, useMemo, useCallback } from 'react';
import useHttp from '../../../shared/hooks/use-http';
import { ATTENDANCE_STATUSES, ATTENDED_ATTENDANCE_STATUS, DEFAULT_ATTENDANCE_STATUS } from '../shared/constants';
import {
  prepareMemberAttendanceData,
  getDefaultStatus,
  shouldRequireExtraColumns,
  shouldDisableLocation,
} from './helpers';

const useAttendanceTracker = ({ appointment, addToast, authenticityToken }) => {
  const { sendRequest, isLoading } = useHttp();
  const [formState, setFormState] = useState(() =>
    Object.entries(appointment.members).reduce(
      (acc, [_, member]) => ({
        ...acc,
        [member.id]: prepareMemberAttendanceData(member, {
          statuses: ATTENDANCE_STATUSES,
          defaultStatus: DEFAULT_ATTENDANCE_STATUS,
        }),
      }),
      {}
    )
  );

  const getMemberAttendance = useCallback((memberId) => formState[memberId], [formState]);

  const setField = useCallback(
    (memberId, field, value) => {
      const isStatusField = field === 'status';
      const statusAttended = isStatusField && value?.value === ATTENDED_ATTENDANCE_STATUS.value;
      const shouldClearLocation = isStatusField && shouldDisableLocation(value);

      if (statusAttended) {
        setFormState((prevState) => ({
          ...prevState,
          [memberId]: {
            ...prevState[memberId],
            [field]: value,
            isLocationFocusable: !prevState[memberId].location,
          },
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [memberId]: {
            ...prevState[memberId],
            location: shouldClearLocation ? null : prevState[memberId].location,
            [field]: value,
            isLocationFocusable: false,
          },
        }));
      }
    },
    [setFormState]
  );

  const patchForm = useCallback(
    (data) => {
      setFormState((prevState) => {
        const isLocationFocusableMemberId = data.find(
          (item) => item.status === ATTENDED_ATTENDANCE_STATUS.value && !prevState[item.id].location
        )?.id;

        data.forEach(({ id, ...rest }) => {
          if (!prevState[id]) return;

          // eslint-disable-next-line no-param-reassign
          prevState[id] = { ...prevState[id] };

          if (rest.status) {
            // eslint-disable-next-line no-param-reassign
            prevState[id].status = getDefaultStatus(
              { status: rest.status },
              {
                statuses: ATTENDANCE_STATUSES,
                defaultStatus: prevState[id].status,
              }
            );
          }

          // eslint-disable-next-line no-param-reassign
          prevState[id].isLocationFocusable = isLocationFocusableMemberId === id;
        });

        return { ...prevState };
      });
    },
    [setFormState]
  );

  const getChangeHandler = useCallback((memberId, field) => (value) => setField(memberId, field, value), [setField]);

  const handleSave = useCallback(async () => {
    const attendanceData = Object.entries(formState).map(([id, { status, location }]) => {
      const isCustomLocation = location?.wasCreated;
      const areExtraColumnsRequired = shouldRequireExtraColumns(status);

      return {
        member_id: id,
        status: status?.value,
        custom_location: areExtraColumnsRequired && isCustomLocation ? location?.value : null,
        location_id: areExtraColumnsRequired && !isCustomLocation ? location?.value : null,
      };
    });

    try {
      await sendRequest(`/staff/appointment_occurrences/${appointment?.id}/update_appointment_occurrence_members`, {
        method: 'PATCH',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: {
          appointment_occurrence_members: attendanceData,
        },
      });
      addToast({
        header: 'Attendance successfully updated',
        type: 'success',
      });
    } catch (e) {
      addToast({
        header: 'Something went wrong',
        message: e?.parsedMessage ?? 'There was an error while updating the attendance. Please try again.',
        type: 'error',
      });
      window.Sentry?.captureException(e);
      throw e;
    }
  }, [formState, sendRequest, appointment, authenticityToken, addToast]);

  return useMemo(
    () => ({
      formState,
      getMemberAttendance,
      setField,
      patchForm,
      getChangeHandler,
      handleSave,
      isLoading,
    }),
    [formState, getMemberAttendance, setField, patchForm, getChangeHandler, handleSave, isLoading]
  );
};

export default useAttendanceTracker;
