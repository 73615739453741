import React from 'react';

// HOOKS
import useCurrentUser from './hooks/use-current-user';

function TimezoneSetterMessage(props) {
  const { currentUserId } = useCurrentUser(props);

  return (
    <div className="flashes" data-user-timezone-target="userTimeZoneIdentifier" data-id={currentUserId}>
      <div className="flash">
        <img
          alt="alt"
          className="w-6 h-6 align-middle inline-flex mb-1 mr-1"
          src="https://epicstaff-dashboard-reactdp-2229.grtpreview.net/assets/icon__objective-complete-80b97e00562a5089a56b2f7f90e4833271d78c6bc45747577656d411d9854e74.png"
        />
        <p className="text-700 font-bold inline-flex mb-0 leading-loose">Your timezone has been set.</p>
      </div>
    </div>
  );
}

export default TimezoneSetterMessage;
