import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import CircularProgress from '@mui/material/CircularProgress';
import Toasts from '../../shared/Toasts';
import useToasts from '../../shared/hooks/use-toasts';
import Button from '../../shared/Button';
import usePrescriptions from './usePrescriptions';
import useFilters from '../../shared/hooks/use-filters';
import { DEFAULT_FILTERS, FILTER_TYPES, TABLE_HEADERS } from './constants';
import useFilterData from './useFilterData';
import useSelect from '../../shared/hooks/use-select';
import AppliedFilters from '../../shared/AppliedFilters';
import PrescriptionTasksFilters from './PrescriptionTasksFilters';
import FilterPill from '../../FilterPill';
import Table from '../../Table';
import ReassignPrescriptions from '../shared/reassign/ReassignPrescriptions';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import EditTaskModal from '../shared/EditTaskModal';
import { formatMemberName, generatePrescriptionDetailsUrl } from './helpers';
import { useSuccessParamToast } from './useSuccessParamToast';
import AddPrescriptionTask from '../shared/AddPrescriptionTask';
import useHttp from '../../shared/hooks/use-http';
import { PrescriptionsQueueController } from './usePrescriptionsQueueContext';

const testID = 'prescriptionTasksMain';
function Main({
  authenticityToken,
  currentUser,
  prescriptionDetailsUrl,
  canManageRxQueue,
  canEditLockedRx,
  isOtherPendingPrescription,
}) {
  const { toasts, addToast, removeToast } = useToasts();
  const { sendRequest, isLoading: addTaskLoading } = useHttp();

  useSuccessParamToast(addToast);

  const { filters, setFilter, filtersArray } = useFilters({
    ...DEFAULT_FILTERS,
    [FILTER_TYPES.assignee]: [currentUser],
  });

  const {
    statusesData,
    assigneesData,
    locationsData,
    providersData,
    reasonsData,
    statesData,
    dueDatesData,
    isLoading: isFilterDataLoading,
  } = useFilterData();

  const [showFilters, setShowFilters] = useState(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;

  const { isLoading, currentPage, currentData, fetchData, reloadData, handleSort, sortedBy } =
    usePrescriptions(filters);

  const [isReassigning, setIsReassigning] = useState(false);
  const [singleReassignRow, setSingleReassignRow] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);

  const { selected, setSelected, toggle: toggleRowSelection, clearSelection } = useSelect();
  const selectedTasksIds = selected.map((task) => task.id);

  const handleReassignSuccess = (response) => {
    if (response?.success) {
      addToast({ type: 'success', header: 'Successfully reassigned' });
    } else {
      const memberNames = response?.prescription_tasks?.map((task) => formatMemberName(task?.member)).join(', ');
      addToast({
        type: 'error',
        header: 'Something went wrong',
        message: `${memberNames || 'Some members'} could not be reassigned`,
      });
    }

    clearSelection();
    reloadData();
  };

  const handleReassignClose = () => {
    setSingleReassignRow(null);
    setIsReassigning(false);
  };

  const handleReassignRow = useCallback((row) => {
    setSingleReassignRow(row);
    setIsReassigning(true);
  }, []);

  const handleEditSuccess = (response) => {
    if (response?.errors) {
      addToast({ type: 'error', header: 'Something went wrong' });
    }
    reloadData();
    setRowToEdit(null);
  };

  const handleEditClose = () => {
    setRowToEdit(null);
  };

  const handleEditRow = useCallback((row) => {
    setRowToEdit(row);
  }, []);

  const prescriptionsTasks = useMemo(
    () =>
      convertObjKeysToCamelCase(currentData?.json_data)?.map((task) => ({
        ...task,
        detailsUrl: generatePrescriptionDetailsUrl(prescriptionDetailsUrl, task.id),
        onReassignRow: handleReassignRow,
        onEditRow: handleEditRow,
        canManageRxQueue,
      })),
    [currentData?.json_data, prescriptionDetailsUrl, handleReassignRow, handleEditRow, canManageRxQueue]
  );

  const allMembersSelected = selected?.length === prescriptionsTasks?.length;
  const handleCheckboxChange = useCallback(
    (checked) => {
      setSelected(checked ? prescriptionsTasks : []);
    },
    [prescriptionsTasks, setSelected]
  );

  const onAddTaskSuccess = () => {
    reloadData();
    addToast({ type: 'success', header: `Task successfully added to the queue` });
  };

  const onAddTaskFailure = () => {
    addToast({
      type: 'error',
      header: 'Something went wrong',
      message: `Task could not be added at this time. Please try again.`,
    });
  };

  const onAddTask = async (data) => {
    try {
      await sendRequest(`/staff/prescription_tasks`, {
        method: 'POST',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data,
      });
      onAddTaskSuccess();
    } catch (error) {
      onAddTaskFailure();
    } finally {
      setIsAddTaskModalOpen(false);
    }
  };

  return (
    <>
      <ReassignPrescriptions
        authenticityToken={authenticityToken}
        prescriptionTasks={singleReassignRow ? [singleReassignRow] : selected}
        assigneesList={assigneesData}
        onSuccess={handleReassignSuccess}
        isModalOpen={isReassigning}
        closeModal={handleReassignClose}
      />
      {rowToEdit && (
        <EditTaskModal
          isOpen
          onClose={handleEditClose}
          memberName={formatMemberName(rowToEdit.member)}
          prescriptionTaskDetails={rowToEdit}
          authenticityToken={authenticityToken}
          prescriptionReasons={reasonsData}
          onSuccess={handleEditSuccess}
          isOtherPendingPrescription={isOtherPendingPrescription}
        />
      )}
      <div data-testid={testID} className="mt-3 flex flex-col gap-6">
        <div>
          <div className="border-b border-gray-400 pb-2 flex justify-between flex-row items-end">
            <div>
              <h2 data-testid={`title-${testID}`} className="font-body font-semibold mb-1">
                Prescription Queue
              </h2>
            </div>
            <Button
              data-testid={`toggle-filters-button-${testID}`}
              onClick={() => setShowFilters((value) => !value)}
              isPrimary
              classes="max-h-12"
            >
              <div className="flex items-center">
                Apply Filters
                <FilterBtnIcon className="text-2xl ml-2" />
              </div>
            </Button>
          </div>
          <AppliedFilters currentlyAppliedFilters={filtersArray} addTopMargin={false} />
        </div>

        {showFilters && !isFilterDataLoading && (
          <PrescriptionTasksFilters
            currentlyAppliedFilters={filters}
            setFilter={setFilter}
            testID={testID}
            statusesData={statusesData}
            assigneesData={assigneesData}
            locationsData={locationsData}
            providersData={providersData}
            reasonsData={reasonsData}
            statesData={statesData}
            dueDatesData={dueDatesData}
          />
        )}

        <AddPrescriptionTask
          authenticityToken={authenticityToken}
          isModalOpen={isAddTaskModalOpen}
          onClose={() => setIsAddTaskModalOpen(false)}
          prescriptionReasons={reasonsData}
          onAddTask={onAddTask}
          isLoading={addTaskLoading}
        />

        <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" />

        <div className="flex items-center justify-between gap-2">
          {selected?.length ? (
            <FilterPill onRemove={clearSelection} label={`${selected?.length} selected`} />
          ) : (
            <span className="text-gray-600 font-semibold">0 selected</span>
          )}
          {canManageRxQueue && (
            <div className="flex items-center gap-2.5">
              <Button isPrimary disabled={!selected?.length} onClick={() => setIsReassigning(true)}>
                Reassign
              </Button>
              <Button isPrimary onClick={() => setIsAddTaskModalOpen(true)}>
                Add task
              </Button>
            </div>
          )}
        </div>

        <div>
          {isLoading && !currentData?.json_data ? (
            <div data-testid={`noData-${testID}`} className="no-data-loader">
              <CircularProgress />
            </div>
          ) : (
            <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
              <PrescriptionsQueueController canEditLockedRx={canEditLockedRx}>
                <Table
                  tableType="prescriptionQueueTable"
                  showShadow={false}
                  enableActionsColumn
                  stickyActionColumn
                  stickyHeader
                  enableHidingShadowInStickyColumn
                  enableCheckboxColumn
                  onCheckboxChange={handleCheckboxChange}
                  checkboxAllSelected={allMembersSelected}
                  onRowCheckboxChange={toggleRowSelection}
                  displayPaginationStartingElementIndex={false}
                  selectedKeys={selectedTasksIds}
                  currentPage={currentPage}
                  tableColumnHeaders={TABLE_HEADERS}
                  tableRows={prescriptionsTasks}
                  pageChange={fetchData}
                  isLastPage={currentData.is_last_page}
                  totalCountRecords={currentData.total_count}
                  headerButtonClick={handleSort}
                  currentSort={sortedBy}
                  loading={isLoading}
                  testID={`table-${testID}`}
                />
              </PrescriptionsQueueController>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canManageRxQueue: PropTypes.bool,
  prescriptionDetailsUrl: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  canEditLockedRx: PropTypes.bool,
  isOtherPendingPrescription: PropTypes.bool,
};

Main.defaultProps = {
  canManageRxQueue: false,
  canEditLockedRx: false,
  isOtherPendingPrescription: false,
};

export default Main;
