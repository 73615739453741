import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import Button from '../../../shared/Button';
import PhotoAttachments from '../PhotoAttachments';
import { MemberPropTypes } from '../types';
import socketConsumer from '../../../../channels/consumer';

function InsuranceCardRow({ member, insuranceCard }) {
  const [wasInsuranceRequested, setWasInsuranceRequested] = useState(false);
  const [dateWithOffset, dateTimeZone] = convertToSupportedTimeZone(insuranceCard?.datetime);
  const formattedDate = `Uploaded on ${dateWithOffset.format('MMMM D, YYYY h:mm A')} ${dateTimeZone.abbrev}`;

  const requestInsurance = async () => {
    if (wasInsuranceRequested || !member?.id) return;

    socketConsumer?.subscriptions?.subscriptions[0]?.perform('received', {
      data: { memberId: String(member.id), document: 'insurance' },
    });
    setWasInsuranceRequested(true);
  };

  const photos = [];

  if (insuranceCard?.frontUrl) {
    photos.push({
      label: 'Insurance - Front',
      src: insuranceCard.frontUrl,
      alt: `${member?.firstName} ${member?.lastName} - Insurance card front`,
    });
  }

  if (insuranceCard?.backUrl) {
    photos.push({
      label: 'Insurance - Back',
      src: insuranceCard.backUrl,
      alt: `${member?.firstName} ${member?.lastName} - Insurance card back`,
    });
  }

  return (
    <DetailsRow label="Insurance Card">
      {insuranceCard ? (
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2 flex-col md:flex-row">
            <span>{insuranceCard?.datetime ? formattedDate : 'Upload date not found'}</span>
            <Button onClick={requestInsurance} disabled={wasInsuranceRequested}>
              {wasInsuranceRequested ? 'Insurance requested' : 'Request insurance'}
            </Button>
          </div>
          <PhotoAttachments photos={photos} />
        </div>
      ) : (
        <div className="flex items-center justify-between gap-2 flex-col md:flex-row">
          <span>No insurance card found</span>
          <Button onClick={requestInsurance} disabled={wasInsuranceRequested}>
            {wasInsuranceRequested ? 'Insurance requested' : 'Request insurance'}
          </Button>
        </div>
      )}
    </DetailsRow>
  );
}

InsuranceCardRow.propTypes = {
  member: MemberPropTypes.isRequired,
  insuranceCard: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    frontUrl: PropTypes.string.isRequired,
    backUrl: PropTypes.string,
  }).isRequired,
};

export default InsuranceCardRow;
