const getParsedRows = (value) =>
  JSON.parse(value)
    ?.slice(1)
    ?.map((row, rowIndex) => ({
      id: rowIndex,
      items: row.map((rowItem, i) => {
        const parsedRowItem = JSON.parse(rowItem);
        return {
          id: i,
          ...parsedRowItem,
        };
      }),
    }));

export const getParsedValue = (value) => {
  try {
    return {
      headers: JSON.parse(value)[0]?.map((header) => JSON.parse(header)?.value),
      rows: getParsedRows(value),
    };
  } catch (e) {
    return null;
  }
};

export const getStringifiedRows = (rows) =>
  rows.map((row) =>
    row?.items?.map((rowItem) =>
      JSON.stringify({
        value: rowItem.value,
        type: rowItem.type,
      })
    )
  );
