/* eslint-disable object-curly-newline, react/jsx-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';

// COMPONENTS
import Filters from '../../Filters';
import Button from '../../shared/Button';
import Header from '../../shared/Header';
import PageHeader from '../../shared/PageHeader';
import MuiDatePicker from '../../shared/MuiDatePicker';
import PageContainer from '../../shared/PageContainer';
import EmptyTableMessage from '../../shared_dashboard/EmptyTableMessage';

// UDS COMPONENTS
import NextButton from '../shared/NextButton';
import CancelModal from '../shared/CancelModal';
import InputCheckbox from '../shared/InputCheckbox';
import MemberPickupContainer from './MemberPickupContainer';
import MemberSearchFilter from '../shared/MemberSearchFilter';

// HOOKS
import useDefaultPickups from '../hooks/use-default-pickups';
import useCachedPickupData from '../hooks/use-cached-pickup-data';
import useClearCachedPickupData from '../hooks/use-clear-cached-pickup-data';

// HELPERS
import { errorsExist, isEmptyObject } from '../../../helpers/AssortedHelpers';
import { labelStyle, cancelButtonStyle, addressSubtextStyle, containerStyle } from '../shared/styles';

function PickupPage(props) {
  const { pickup } = useCachedPickupData();
  const { defaultPickup } = useDefaultPickups();
  const { clearCachedPickupData } = useClearCachedPickupData();

  // DATA
  const [selectedLocation, setSelectedLocation] = useState(pickup?.location);
  const [useDef, setUseDef] = useState(pickup?.isUpdateDefaultFacility);

  const [selectedMembers, setSelectedMembers] = useState(pickup?.members);
  const [pickupQuantities, setPickupQuantities] = useState({});

  const setCachedPickupData = () => {
    // IF CACHED DATA, USE CACHED DATA
    if (!pickup?.location) {
      return;
    }
    setSelectedLocation(pickup?.location);
  };

  const setDefaultPickupData = () => {
    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW PICKUP LOAD
    if (pickup?.location) {
      return;
    }
    if (!defaultPickup?.use_default_location) {
      return;
    }

    setSelectedLocation(defaultPickup?.default_location);

    pickup.location = defaultPickup?.default_location;
    window.localStorage.setItem('pickup', JSON.stringify(pickup));
  };

  // SET DATA
  useEffect(() => {
    setSelectedMembers(pickup?.members);
  }, [pickup?.members]);
  useEffect(() => {
    setUseDef(pickup?.isUpdateDefaultFacility);

    // IF CACHED DATA, USE CACHED DATA
    setCachedPickupData();

    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW PICKUP LOAD
    setDefaultPickupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pickup?.location,
    pickup?.isUpdateDefaultFacility,
    defaultPickup?.default_location,
    defaultPickup?.use_default_location,
  ]);

  // HELPERS
  const requiredText = 'This field is required';
  const [formErrors, setFormErrors] = useState({});
  const [showWarningModal, setShowWarningModal] = useState(false);

  // COMPONENT RENDERING
  const renderEmptyTableMessage = () => {
    if ((pickup?.members && pickup?.members?.length > 0) || selectedMembers?.length > 0) {
      return <></>;
    }
    return (
      <EmptyTableMessage
        text="No Members Selected"
        subText="Select a member via the “Members(s) Picking Up” dropdown above"
      />
    );
  };

  const renderMembersSelectPickup = () => {
    if (!pickup?.members) {
      return <></>;
    }
    return pickup?.members.map((member) => (
      <MemberPickupContainer
        member={member?.label}
        id={member?.value}
        pickupQuantities={pickupQuantities}
        setPickupQuantities={setPickupQuantities}
      />
    ));
  };

  // ERROR FUNCTIONS
  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateObj = (obj, key) => {
    if (isEmptyObject(obj)) {
      setError(key, requiredText);
    } else {
      setError(key, null);
    }
  };

  const validateData = () => {
    validateObj(pickup?.members, 'members');
    validateObj(pickup?.location, 'location');
    validateObj(pickup?.date, 'date');
  };

  // BUTTON FUNCTIONS
  const cancelButtonClick = () => {
    setShowWarningModal(true);
    window?.Matomo?.getAsyncTracker()?.trackEvent(
      'uds-screening-supplies',
      'open-cancel-pickup-warning-modal-pickup-form'
    );
  };

  const onBackClick = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'cancel-clear-pickup-form');
    setShowWarningModal(false);
  };

  const onContinueCancelClick = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'confirm-clear-pickup-form');
    clearCachedPickupData();
    props.setShowPickupPage();
  };

  const onSubmit = () => {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const params = {
      member_ids: pickup.members.map((x) => x.value),
      facility_id: pickup.location.value,
      date: pickup.date,
      is_update_default_facility: pickup.isUpdateDefaultFacility,
      pickup_quantities: pickupQuantities,
    };

    axios
      .post('/staff/uds_supply/create_pickup', { ...params })
      .then((_response) => {
        clearCachedPickupData(pickup?.members);
        props.setShowPickupPage();
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  return (
    <>
      <PageHeader text="New Office Pickup" breadcrumbText="Screening Supplies" breadcrumbLink="uds_supply">
        <Button onClick={cancelButtonClick} style={cancelButtonStyle}>
          Cancel Pickup
        </Button>
      </PageHeader>

      <PageContainer style={containerStyle}>
        <Header text="Pickup Details" withBottomMargin withNoUnderline />

        {/* MEMBERS PICKING UP */}
        <MemberSearchFilter
          members={pickup?.members}
          formErrors={formErrors}
          label="Member(s) Picking Up*"
          onSelect={(selected) => {
            pickup.members = selected;
            setSelectedMembers(selected);
            window.localStorage.setItem('pickup', JSON.stringify(pickup));
          }}
        />

        <div className="flex" style={{ marginTop: '25px' }}>
          <div className="flex-1 mr-2">
            {/* ADDRESS */}
            <Filters
              label="Address*"
              data={props.locations}
              placeholder="Search Locations"
              value={selectedLocation}
              stateChanger={(selected) => {
                setSelectedLocation(selected);

                pickup.location = selected;
                window.localStorage.setItem('pickup', JSON.stringify(pickup));

                setUseDef(false);
              }}
              labelStyle={labelStyle}
              isMulti={false}
              removeAllSelection
              error={formErrors.location}
            />

            <div className="flex">
              <div style={{ ...addressSubtextStyle, marginTop: '9px' }}>{selectedLocation?.address}</div>

              <InputCheckbox
                show
                isChecked={useDef}
                text="Make this my default facility"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  defaultPickup.use_default_location = isChecked;

                  if (isChecked) {
                    defaultPickup.default_location = selectedLocation;
                  }
                  window.localStorage.setItem('default-uds-location-data', JSON.stringify(defaultPickup));
                }}
              />
            </div>
          </div>

          <div className="flex-1 mr-2">
            {/* PICKUP DATE */}
            <MuiDatePicker
              label="Pickup Date*"
              value={pickup?.date}
              onChange={(date) => {
                pickup.date = date;
                window.localStorage.setItem('pickup', JSON.stringify(pickup));
              }}
              labelStyle={labelStyle}
              error={formErrors.date}
            />
          </div>
        </div>

        <hr className="w-full my-10" />

        <Header text="Supplies" withBottomMargin withNoUnderline />

        {renderEmptyTableMessage()}
        {renderMembersSelectPickup()}

        <NextButton
          classes="ml-auto w-72"
          text="SAVE PICKUP"
          onClick={() => {
            validateData();
            if (!errorsExist(formErrors)) {
              onSubmit();
            }
          }}
        />
      </PageContainer>

      <CancelModal
        isOpen={showWarningModal}
        mainText="You are confirming to cancel this pickup and lose your saved progress."
        onBack={onBackClick}
        onConfirm={onContinueCancelClick}
      />
    </>
  );
}

PickupPage.propTypes = {
  setShowPickupPage: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
};

export default PickupPage;
