import React from 'react';
import PropTypes from 'prop-types';

/**
 *  RadioRutton Component
 * @param {{label: string, checked: boolean, onChange: function}} props
 * @returns {React.ReactElement} Radio Button.
 */
function RadioButton({ label, checked, onChange }) {
  return (
    <div className="radio-button">
      <label>
        <input type="radio" value={label} onChange={onChange} checked={checked} className="mr-2" />
        {label}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;
