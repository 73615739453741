import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import BarViewContainer from './BarViewContainer';
import InformationContainer from './InformationContainer';

function ThreeMonthBarViewContainer(props) {
  const { attendance, uds } = props?.data || {};

  return (
    <InformationContainer classes="flex mt-0 mb-0">
      <BarViewContainer
        classes="border-gray-300 border-r"
        label="Attendance"
        secondaryLabel="last 3 months"
        data={attendance}
      />

      <BarViewContainer label="UDS" secondaryLabel="last 3 months" data={uds} />
    </InformationContainer>
  );
}

ThreeMonthBarViewContainer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThreeMonthBarViewContainer;
