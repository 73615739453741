import React from 'react';
import PropTypes from 'prop-types';

function RiskIcon(props) {
  return (
    <svg
      className={props.className}
      style={props.style}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.9998" cy="16.5" r="13.3333" fill="#CD5334" />
      <path d="M16 9.83337L16 18.5" stroke="white" strokeWidth="2" />
      <path
        d="M16.8332 22.5C16.8332 22.9603 16.4601 23.3334 15.9998 23.3334C15.5396 23.3334 15.1665 22.9603 15.1665 22.5C15.1665 22.0398 15.5396 21.6667 15.9998 21.6667C16.4601 21.6667 16.8332 22.0398 16.8332 22.5Z"
        fill="#CD5334"
        stroke="white"
      />
    </svg>
  );
}

RiskIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
};

RiskIcon.defaultProps = {
  className: '',
  style: {},
};

export default RiskIcon;
