import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NoteReadModeAnswer from './NoteReadModeAnswer';

function NoteReadModeSingleQuestion({ question, answer, isFullWidthSet, type, isFormAnswerSelected }) {
  const questionRef = useRef();

  useEffect(() => {
    questionRef.current?.scrollIntoView();
  }, []);

  const dynamicQuestionRef = isFormAnswerSelected ? { ref: questionRef } : {};

  return (
    <div
      {...dynamicQuestionRef}
      data-testid="note-read-mode-single-question"
      className={classNames('grid py-4 border-b border-grey-300', {
        'lg:grid-cols-2': isFullWidthSet,
        'lg:gap-4': isFullWidthSet,
        'sm:grid-cols-1': !isFullWidthSet,
        'sm:gap-2': !isFullWidthSet,
      })}
    >
      <div className="font-semibold" data-testid="note-read-mode-single-question-question-text">
        {question}
      </div>
      <NoteReadModeAnswer answer={answer} type={type} />
    </div>
  );
}

NoteReadModeSingleQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isFullWidthSet: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  isFormAnswerSelected: PropTypes.bool.isRequired,
};

export default NoteReadModeSingleQuestion;
