import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InformationHeader from './InformationHeader';
import TextRowContainer from '../shared/TextRowContainer';
import InformationContainer from './InformationContainer';

function MedicalInformation(props) {
  const {
    rx,
    dosage,
    rxType,
    rxRec,
    rxRecDetails,
    medList,
    pharmacy,
    lastProviderVisit,
    lastPillCount,
    lastFentanyl,
    lastUds,
  } = props?.data || {};

  const [openStatus, setOpenStatus] = useState(true);

  function renderOpenContent() {
    if (openStatus) {
      return (
        <>
          <TextRowContainer label="Rx Status" text={rx?.text} subtext={rx?.subtext} />
          <TextRowContainer label="Dosage" text={dosage} />
          <TextRowContainer label="Rx Type" text={rxType} />
          <TextRowContainer label="Rx Rec" text={rxRec} />
          <TextRowContainer label="Rx Rec Details" text={rxRecDetails} />
          <TextRowContainer label="Med List" text={medList?.join(', ')} />
          <TextRowContainer label="Pharmacy" text={pharmacy} />
          <TextRowContainer
            label="Last Provider Visit"
            text={lastProviderVisit?.text}
            subtext={lastProviderVisit?.subtext}
          />
          <TextRowContainer label="Last Pill Count" text={lastPillCount?.text} subtext={lastPillCount?.subtext} />
          <TextRowContainer label="Last Fentanyl" text={lastFentanyl?.text} subtext={lastFentanyl?.subtext} />
          <TextRowContainer label="Last UDS" text={lastUds?.text} subtext={lastUds?.subtext} hasBorder={false} />
        </>
      );
    }
    return <></>;
  }

  return (
    <InformationContainer isBgBlue addPadding>
      <InformationHeader label="Medical" isOpen={openStatus} setOpenStatus={setOpenStatus} />
      {renderOpenContent()}
    </InformationContainer>
  );
}

MedicalInformation.propTypes = {
  data: PropTypes.string.isRequired,
};

export default MedicalInformation;
