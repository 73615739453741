import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ReassignIcon(props) {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      className={props.className}
      style={props.style}
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {props.showHover && isHover && <rect x="-1" y="-1" width="32" height="32" rx="20" fill="#E6F3F4" />}
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66455 0.160156V30.1602H31.6646V0.160156H1.66455Z"
        fill={props.color}
        fillOpacity="0.338428"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91504 1.86177V28.9585H30.0118V1.86177H2.91504Z"
        fill={props.color}
        fillOpacity="0.338428"
      />
      <path
        d="M19.4604 23.5389V21.8328C19.4604 19.9483 17.9164 18.4206 16.0117 18.4206H9.97651C8.07186 18.4206 6.52783 19.9483 6.52783 21.8328V23.5389"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12.9941"
        cy="11.5962"
        rx="3.44868"
        ry="3.41219"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4634 4.57184V19.0235H30.915V4.57184H16.4634Z"
        fill={props.color}
        fillOpacity="0.338428"
      />
      <path
        d="M23.0269 10.6834L25.2849 8.42535L27.543 10.6834"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3174 15.6512H23.4787C24.4763 15.6512 25.2851 14.8424 25.2851 13.8447V8.42535"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ReassignIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  color: PropTypes.string,
  showHover: PropTypes.bool,
  onClick: PropTypes.func,
};

ReassignIcon.defaultProps = {
  className: '',
  style: {},
  color: 'black',
  showHover: false,
  onClick: () => {},
};

export default ReassignIcon;
