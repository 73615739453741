import React from 'react';
import PropTypes from 'prop-types';

function InputLabel({ label, htmlFor, required }) {
  return (
    label && (
      <label data-testid="label" htmlFor={htmlFor} className="flex text-base font-semibold flex-1">
        {required && (
          <span data-testid="label-required" className="text-error-700 mr-2">
            *
          </span>
        )}
        {label}
      </label>
    )
  );
}

InputLabel.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
};

InputLabel.defaultProps = {
  label: null,
  htmlFor: null,
  required: false,
};

export default InputLabel;
