/* eslint-disable no-shadow */
// REACT IMPORTS
import React, { _useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// TABLE IMPORTS
import axios from 'axios';
import { MdOutlineThumbUpAlt, MdThumbUpAlt, MdOutlineFlag, MdFlag } from 'react-icons/md';
import GeneralCell from './GeneralCell';
import GroupCell from './GroupCell';
import GroupMeetingCell from './GroupMeetingCell';
import ReviewWarning from './ReviewWarningModal';

// ICONS

// SERVICES
import { setStaffData } from './empanelment/staff/services/set-staff-data';
import StatusCell from './StatusCell';

function PanelTableCell(props) {
  // SET ROW DATA
  const [rowData, setRow] = useState({});
  const testID = `panelTableCell-${props.testID}`;

  // SET MODAL DATA
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

  // FLAG - Toggle
  const [isFlagged, setIsFlagged] = useState(false);

  const {
    row,
    members_empanelment_group_id,
    is_approved,
    is_flagged,
    member_name,
    mrn,
    date_time,
    location,
    weekly_group_id,
    is_added,
    is_removed,
    status,
    empanelment_date_time,
    empanelment_location_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    ecw_active,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_office_manager_name,
    empanelment_secondary_group_types,
  } = setStaffData(props);

  useMemo(() => {
    setRow({
      row,
      members_empanelment_group_id,
      is_approved,
      is_flagged,
      member_name,
      mrn,
      date_time,
      location,
      weekly_group_id,
      is_added,
      is_removed,
      status,
      empanelment_date_time,
      empanelment_location_name,
      empanelment_provider_name,
      empanelment_counselor_name,
      ecw_active,
      empanelment_group_modality,
      empanelment_group_type,
      empanelment_office_manager_name,
      empanelment_secondary_group_types,
    });

    setIsFlagged(is_flagged);
  }, [
    row,
    members_empanelment_group_id,
    is_approved,
    is_flagged,
    member_name,
    mrn,
    date_time,
    location,
    weekly_group_id,
    is_added,
    is_removed,
    status,
    empanelment_date_time,
    empanelment_location_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    ecw_active,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_office_manager_name,
    empanelment_secondary_group_types,
  ]);

  const flag = () => {
    // toggle flag value
    const flagValue = !rowData.is_flagged;

    const members_empanelment_group_id = rowData?.members_empanelment_group_id;

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    axios
      .put('/staff/empanelment_staff/update', { id: members_empanelment_group_id, is_flagged: flagValue })
      .then((response) => {
        const rowData = setStaffData(response.data);

        setIsFlagged(flagValue);
        props.updateRows('flag', rowData.row);
      });
  };

  const approve = async () => {
    const members_empanelment_group_id = rowData?.members_empanelment_group_id;

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    axios
      .put('/staff/empanelment_staff/update', { id: members_empanelment_group_id, is_approved: true })
      .then((response) => {
        const rowData = setStaffData(response.data);

        props.updateRows('approve', rowData.row);
      });

    setIsOpenWarningModal(true);
  };

  return (
    <>
      <GeneralCell testID={`memberName-${testID}`} data={rowData.member_name} subText={rowData.mrn} />

      <GroupMeetingCell
        dateTime={rowData.date_time}
        location={rowData.location}
        weeklyGroupID={rowData.weekly_group_id}
        testID={testID}
      />

      <GroupCell
        testID={testID}
        isEmpanelment
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        dateTime={rowData.empanelment_date_time}
        location={rowData.empanelment_location_name}
        provider={rowData.empanelment_provider_name}
        counselor={rowData.empanelment_counselor_name}
        officeManager={rowData.empanelment_office_manager_name}
        groupType={rowData.empanelment_group_type}
        modality={rowData.empanelment_group_modality}
        secondaryGroupType={rowData.empanelment_secondary_group_types}
      />

      <StatusCell testID={testID} status={rowData.status} ecwActive={rowData.ecw_active} />

      {/* ACTION BUTTONS */}
      <td data-testid={`actionBtns-${testID}`} className="shadow-inner ring-1 ring-black ring-opacity-5">
        <div className="flex justify-around p-5 empanelment-buttons">
          {/* APPROVE ACTION */}
          {rowData.is_approved ? (
            <button data-testid={`approvedIcon-${testID}`} type="button" className="empanelment-button thumbs-up">
              <MdThumbUpAlt />
            </button>
          ) : (
            <button data-testid={`approveActionBtn-${testID}`} type="button" className="empanelment-button thumbs-up">
              <MdOutlineThumbUpAlt onClick={approve} />
            </button>
          )}

          {/* FLAG ACTION */}
          {isFlagged ? (
            <button data-testid={`unflagActionBtn-${testID}`} type="button" className="empanelment-button flag">
              <MdFlag onClick={flag} />
            </button>
          ) : (
            <button data-testid={`flagActionBtn-${testID}`} type="button" className="empanelment-button flag">
              <MdOutlineFlag onClick={flag} />
            </button>
          )}
        </div>
      </td>

      {/* WARNING MODAL for CONFIRM EMPANELMENT */}
      <ReviewWarning
        testID={`confirmEmp-${testID}`}
        isOpen={isOpenWarningModal}
        onClose={() => {
          setIsOpenWarningModal(false);
        }}
      />
    </>
  );
}

PanelTableCell.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,

  // functions
  updateRows: PropTypes.func.isRequired,
};

export default PanelTableCell;
