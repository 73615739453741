import React from 'react';
import PropTypes from 'prop-types';

import { HiOutlineTrash } from 'react-icons/hi';

function RemoveActionIcon({ testId }) {
  return (
    <div className="action-icon remove-action-icon" data-testid={testId}>
      <HiOutlineTrash />
    </div>
  );
}

RemoveActionIcon.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default RemoveActionIcon;
