import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// COMPONENTS
import Filters from '../../Filters';
import TextArea from '../../shared/TextArea';
import MuiTimePicker from '../../shared/TimePicker';
import InputCheckbox from '../shared/InputCheckbox';
import MuiDatePicker from '../../shared/MuiDatePicker';

// HELPERS
import { labelStyle, addressSubtextStyle } from '../shared/styles';

// HOOKS
import useDefaultShipmentPickups from '../hooks/use-default-shipment-pickups';

function PickupMethod(props) {
  const { defaultPickup } = useDefaultShipmentPickups();

  const { pickupDate, location, readyTime, latestTime, pickupInstructions, saveForFutureShipments } =
    props.labelMethod || {};

  // SET DATA
  const [method, setMethod] = useState(props.labelMethod);
  const [selectedLocation, setSelectedLocation] = useState({});
  useEffect(() => setMethod(props.labelMethod), [props.labelMethod]);

  const setCachedPickupData = () => {
    // IF CACHED DATA, USE CACHED DATA
    if (!location) {
      return;
    }
    setSelectedLocation(location);
  };

  const setDefaultPickupData = () => {
    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW PICKUP LOAD
    if (location) {
      return;
    }
    if (!defaultPickup?.use_default_location) {
      return;
    }

    setSelectedLocation(defaultPickup?.default_location);

    method.location = defaultPickup?.default_location;
    props.onSetData(method);
  };

  useEffect(() => {
    // IF CACHED DATA, USE CACHED DATA
    setCachedPickupData();

    // IF NOT CACHED DATA AND USE DEFAULT SELECTED, USE DEFAULTS
    // THIS WILL OCCUR ON NEW PICKUP LOAD
    setDefaultPickupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, defaultPickup?.default_location, defaultPickup?.use_default_location]);

  const today = dayjs().toDate();
  const tomorrow = dayjs().add(1, 'day').toDate();

  return (
    <>
      <div>Same or next day only</div>
      <div className="flex" style={{ marginBottom: '20px' }}>
        <MuiDatePicker
          minDate={today}
          maxDate={tomorrow}
          label="Pickup Date*"
          value={pickupDate}
          onChange={(selected) => {
            method.pickupDate = selected;
            props.onSetData(method);
          }}
          labelStyle={labelStyle}
          error={props.formErrors.pickupDate}
        />

        <MuiTimePicker
          label="Ready Time*"
          value={readyTime}
          onChange={(selected) => {
            method.readyTime = selected;
            props.onSetData(method);
          }}
          labelStyle={labelStyle}
          error={props.formErrors.readyTime}
        />

        <MuiTimePicker
          label="Latest Time Available*"
          value={latestTime}
          onChange={(selected) => {
            method.latestTime = selected;
            props.onSetData(method);
          }}
          labelStyle={labelStyle}
          error={props.formErrors.latestTime}
        />
      </div>

      <TextArea
        label="Pickup Instructions"
        value={pickupInstructions}
        onChange={(value) => {
          method.pickupInstructions = value;
          props.onSetData(method);
        }}
        labelStyle={labelStyle}
      />

      <Filters
        style={{ marginTop: '20px' }}
        label="Pickup Address*"
        data={props.locations}
        placeholder="Search Locations"
        value={selectedLocation}
        stateChanger={(selected) => {
          method.location = selected;
          setSelectedLocation(selected);

          props.onSetData(method);
        }}
        labelStyle={labelStyle}
        isMulti={false}
        removeAllSelection
        error={props.formErrors.location}
      />

      <div className="flex">
        <div style={{ ...addressSubtextStyle, marginTop: '9px' }}>{selectedLocation?.address}</div>

        <InputCheckbox
          show={selectedLocation}
          isChecked={saveForFutureShipments}
          text="Save this selection for future shipments"
          onChange={(e) => {
            const isChecked = e.target.checked;
            defaultPickup.use_default_location = isChecked;

            if (isChecked) {
              defaultPickup.default_location = selectedLocation;
            }
            window.localStorage.setItem('default-uds-shipment-pickup-data', JSON.stringify(defaultPickup));
          }}
        />
      </div>
    </>
  );
}

PickupMethod.propTypes = {
  labelMethod: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSetData: PropTypes.func.isRequired,
};

export default PickupMethod;
