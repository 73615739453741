import HelloSign from 'hellosign-embedded';

const signEmbeddedSignature = (signatureData) => {
  const client = new HelloSign();
  const signUrl = signatureData.sign_url;
  const clientID = signatureData.client_id;

  client.open(signUrl, {
    clientId: clientID,
    skipDomainVerification: true,
  });

  client.on('sign', (_data) => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  });

  client.on('decline', (_data) => {
    this.hideProgress();
  });

  client.on('reassign', (_data) => {
    this.hideProgress();
  });

  client.on('cancel', (_data) => {
    this.hideProgress();
  });

  client.on('error', ({ code, signatureId }) => {
    this.showError(code, signatureId);
  });
};

export default signEmbeddedSignature;
