import React, { useState, useEffect, useRef } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import CheckBox from './shared/CheckBox';
import StickyColumnCell from './shared/StickyColumnCell';
import TableRow from './TableRow';
import TableLoadMorePagination from './TableLoadMorePagination';
import TableNumberedPagination from './TableNumberedPagination';

function Table(props) {
  const [rows, setRows] = useState(props.tableRows);
  const containerRef = useRef(null);

  // Pagination Information
  const [page, setPage] = React.useState(1);
  const [hasNone, setHasNoData] = React.useState(true);
  const [internalLoading, setInternalDebounce] = useState(false);
  const isLoading = props.loading || internalLoading;
  const testID = `${props.tableType}-${props.testID}`;

  useEffect(() => {
    setRows(props.tableRows);
    setPage(props.currentPage);

    if (props.tableRows.length === 0 && page === 1) {
      setHasNoData(true);
    } else {
      setHasNoData(false);
    }
  }, [props.currentPage, props.tableRows, page]);

  const handleChangePage = () => {
    setInternalDebounce(true);
    const newPage = page + 1;

    setPage(newPage);
    props.pageChange(newPage);
    setTimeout(() => {
      setInternalDebounce(false);
    }, 500);
  };

  const getChevronIcon = () => (props.currentSort.desc ? <BsChevronDown /> : <BsChevronUp />);

  const getPagination = () => {
    switch (props.paginationVariant) {
      case 'loadMore':
        return (
          <TableLoadMorePagination
            testID={testID}
            tableRows={props.tableRows}
            isLastPage={props.isLastPage}
            totalCountRecords={props.totalCountRecords}
            displayPaginationStartingElementIndex={props.displayPaginationStartingElementIndex}
            hideLoadMoreButton={props.hideLoadMoreButton}
            showPageNumbers={props.showPageNumbers}
            handleChangePage={handleChangePage}
            loading={isLoading}
          />
        );
      case 'numbered':
        return (
          <TableNumberedPagination
            currentPage={props.currentPage}
            pageSize={props.pageSize}
            pageChange={props.pageChange}
            totalPages={Math.ceil(props.totalCountRecords / props.pageSize)}
            totalRecordsCount={props.totalCountRecords}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative" ref={containerRef}>
      {props.loading && props.paginationVariant === 'numbered' && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex items-center justify-center z-10">
          <CircularProgress size={32} className="text-gray-700" />
        </div>
      )}
      {hasNone ? (
        <div data-testid={`noData-${testID}`} className="no-data-loader">
          Nothing for you to see here!
        </div>
      ) : (
        <div>
          <div
            data-testid={testID}
            className={classNames({ 'react-empanelment-table': props.showBottomMargin, 'shadow-md': props.showShadow })}
          >
            <div className="relative overflow-auto">
              <div id="filterrific_results shadow-md">
                <div className="overflow-hidden">
                  <div className="relative overflow-auto">
                    <div
                      id="scrollableDiv"
                      style={
                        props.stickyHeader
                          ? { maxHeight: props.maxHeight || '70vh', minHeight: props.minHeight || '400px' }
                          : undefined
                      }
                    >
                      <table className="text-black w-full bg-white rounded-lg whitespace-nowrap">
                        {/* RENDER TABLE HEADERS */}
                        <thead
                          className={classNames('text-left', {
                            'sticky top-0 left-0 z-10 shadow-md': props.stickyHeader,
                            'border-b-2': !props.stickyHeader,
                          })}
                        >
                          <tr data-testid={`header-${testID}`}>
                            {props.enableCheckboxColumn && (
                              <th className="bg-white pl-3">
                                <CheckBox onChange={props.onCheckboxChange} checked={props.checkboxAllSelected} />
                              </th>
                            )}
                            {props.tableColumnHeaders.map(
                              (header) =>
                                header.showColumn &&
                                ((header.actionColumn || header.isColumnSticky) &&
                                props.enableHidingShadowInStickyColumn ? (
                                  <StickyColumnCell
                                    containerElement={containerRef.current}
                                    enableColumn={props.enableActionsColumn}
                                    key={header.key || header.name}
                                    isHeader
                                    shadowDirection={header.isColumnSticky ? 'right' : 'left'}
                                  >
                                    <p data-testid={`headerName-${testID}`}>{header.name}</p>
                                  </StickyColumnCell>
                                ) : (
                                  <th
                                    className={classNames(
                                      'bg-white',
                                      {
                                        'sticky-table-element sticky-table-element--shadow-left':
                                          header.actionColumn && props.stickyActionColumn,
                                        [props.actionColumnClasses]: header.actionColumn,
                                      },
                                      header.classes
                                    )}
                                    key={header.key || header.name}
                                  >
                                    {header.isClickable ? (
                                      <button
                                        data-testid={`${header.name}HeaderBtn-${testID}`}
                                        className={classNames('m-4 flex flex-row items-center gap-4', {
                                          'header-button': !props.currentSort,
                                          'font-semibold': props.currentSort,
                                        })}
                                        type="button"
                                        onClick={() => {
                                          props.headerButtonClick(header.actionName);
                                        }}
                                      >
                                        {header.name}
                                        {props.currentSort &&
                                          props.currentSort.id === header.actionName &&
                                          getChevronIcon()}
                                      </button>
                                    ) : (
                                      <div className="m-4 font-semibold">
                                        <p data-testid={`headerName-${testID}`} className="filterrific_sort_column">
                                          {header.name}
                                        </p>
                                      </div>
                                    )}
                                  </th>
                                ))
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {/* RENDER TABLE ROWS */}
                          {rows.map((row, _index) => (
                            <TableRow
                              testID={row.id}
                              activeTab={props.activeTab}
                              enableActionsColumn={props.enableActionsColumn}
                              authenticityToken={props.authenticityToken}
                              secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
                              key={row.unique_id ?? row.id}
                              row={row}
                              stickyActionColumn={props.stickyActionColumn}
                              tableType={props.tableType}
                              onClickEditRow={props.onClickEditRow}
                              updateRows={(updateType, newRow) => {
                                const updatedRows = rows.map((r) => (r.id !== newRow.id ? r : newRow));

                                setRows(updatedRows);
                                props.updateRows(updateType, newRow);
                              }}
                              editModalFilters={props.editModalFilters}
                              onDeleteButtonClick={props.onDeleteButtonClick}
                              onAddButtonClick={props.onAddButtonClick}
                              onRowCheckboxChange={props.onRowCheckboxChange}
                              selected={props.selectedKeys?.includes(row.id)}
                              containerElement={containerRef.current}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getPagination()}
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  activeTab: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  stickyHeader: PropTypes.bool,
  stickyActionColumn: PropTypes.bool,
  showShadow: PropTypes.bool,
  enableHidingShadowInStickyColumn: PropTypes.bool,
  enableActionsColumn: PropTypes.bool,
  actionColumnClasses: PropTypes.string,
  currentSort: PropTypes.object({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,

  // checkbox data
  enableCheckboxColumn: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onRowCheckboxChange: PropTypes.func,
  checkboxAllSelected: PropTypes.bool,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),

  // table data
  testID: PropTypes.string.isRequired,
  isLastPage: PropTypes.bool,
  tableColumnHeaders: PropTypes.array.isRequired,
  tableRows: PropTypes.array,
  tableType: PropTypes.string,

  // pagination data
  paginationVariant: PropTypes.oneOf(['loadMore', 'numbered', null]),
  currentPage: PropTypes.number.isRequired,
  pageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  totalCountRecords: PropTypes.number,
  displayPaginationStartingElementIndex: PropTypes.bool,
  hideLoadMoreButton: PropTypes.bool,
  showPageNumbers: PropTypes.bool,
  showBottomMargin: PropTypes.bool,
  loading: PropTypes.bool,

  // edit data
  editModalFilters: PropTypes.object,

  // functions
  onClickEditRow: PropTypes.func,
  updateRows: PropTypes.func.isRequired,
  headerButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
  onAddButtonClick: PropTypes.func,
};

Table.defaultProps = {
  stickyHeader: false,
  stickyActionColumn: false,
  enableActionsColumn: true,
  actionColumnClasses: '',
  enableCheckboxColumn: false,
  onCheckboxChange: undefined,
  checkboxAllSelected: false,
  onRowCheckboxChange: undefined,
  selectedKeys: [],
  showShadow: true,
  enableHidingShadowInStickyColumn: false,
  tableRows: [],
  tableType: '',
  editModalFilters: {},
  isLastPage: false,
  totalCountRecords: 0,
  displayPaginationStartingElementIndex: true,
  showPageNumbers: true,
  showBottomMargin: true,
  hideLoadMoreButton: false,
  currentSort: null,
  paginationVariant: 'loadMore',
  pageSize: 10,
  loading: false,
  minHeight: null,
  maxHeight: null,

  // functions
  headerButtonClick: () => {},
  onClickEditRow: () => {},
  onDeleteButtonClick: () => {},
  onAddButtonClick: () => {},
};

export default Table;
