import { useEffect, useMemo, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';

const useAddDocumentationMembers = (memberIds) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [requestResponse, setRequestResponse] = useState(null);

  useEffect(() => {
    if (memberIds?.length) {
      const getAddDocumentationMembers = async () => {
        try {
          const response = await sendRequest('/staff/members/add_documentation', {
            params: {
              member_ids: memberIds,
            },
          });
          setRequestResponse(response);
        } catch (e) {
          window.Sentry?.captureException(e);
        }
      };
      getAddDocumentationMembers();
    }
  }, [sendRequest, memberIds]);

  const members = useMemo(() => requestResponse, [requestResponse]);

  return {
    members,
    isLoading,
    error,
  };
};

export default useAddDocumentationMembers;
