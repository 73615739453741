import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { IoClose } from 'react-icons/io5';

function FilterPill(props) {
  return (
    <div className="border rounded-3xl border-yellow-400 bg-yellow-100 flex flex-row justify-between items-center">
      <div className="font-semibold border-r border-yellow-400 px-5 py-1">
        {props.filterType ? `${startCase(props.filterType)}: ` : ''}
        {props.label}
      </div>
      <IoClose onClick={props.onRemove} className="text-2xl font-bold m-3 cursor-pointer" />
    </div>
  );
}

FilterPill.propTypes = {
  filterType: PropTypes.string,
  label: PropTypes.string.isRequired,

  onRemove: PropTypes.func.isRequired,
};

FilterPill.defaultProps = {
  filterType: null,
};

export default FilterPill;
