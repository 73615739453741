import React from 'react';
import PropTypes from 'prop-types';

function PrescriptionsIframe({ src }) {
  if (!src) {
    return (
      <div className="border border-gray-400 w-full text-center p-6">
        DoseSpot is not available.
        <br />
        Make sure all the required information are provided in the client's Healthie profile.
      </div>
    );
  }

  return <iframe src={src} title="Dosespot" width="100%" height="562" className="border border-gray-400" />;
}

PrescriptionsIframe.propTypes = {
  src: PropTypes.string,
};

PrescriptionsIframe.defaultProps = {
  src: null,
};

export default PrescriptionsIframe;
