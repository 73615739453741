import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  const [active, setActiveData] = useState({});
  const [inactive, setInactiveData] = useState({});
  const [pending, setPendingData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchActive = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_groups', {
          params: {
            page: 0,
            is_archived: false,
            is_approved: true,
          },
        });

        setActiveData(response);
        setIsLoaded(true);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchPending = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_groups', {
          params: {
            page: 0,
            is_pending: true,
          },
        });

        setPendingData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchInactive = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_groups', {
          params: {
            page: 0,
            is_archived: true,
          },
        });

        setInactiveData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchActive();
    fetchPending();
    fetchInactive();
  }, []);

  return {
    active,
    inactive,
    pending,
    isLoaded,
  };
};

export default useFetchData;
