import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import { MemberPropTypes } from '../types';
import PhotoAttachments from '../PhotoAttachments';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import socketConsumer from '../../../../channels/consumer';
import Button from '../../../shared/Button';

function PhotoIDRow({ photo, member }) {
  const [wasPhotoIdRequested, setWasPhotoIdRequested] = useState(false);
  const [dateWithOffset, dateTimeZone] = convertToSupportedTimeZone(photo?.datetime);
  const formattedDate = `Uploaded on ${dateWithOffset.format('MMMM D, YYYY h:mm A')} ${dateTimeZone.abbrev}`;

  const requestPhoto = async () => {
    if (wasPhotoIdRequested || !member?.id) return;

    socketConsumer?.subscriptions?.subscriptions[0]?.perform('received', {
      data: { memberId: String(member.id), document: 'photo_id' },
    });
    setWasPhotoIdRequested(true);
  };

  const photos = [];

  if (photo?.frontUrl) {
    photos.push({
      label: 'Front',
      src: photo.frontUrl,
      alt: `${member?.firstName} ${member?.lastName} - Photo ID front`,
    });
  }

  if (photo?.backUrl) {
    photos.push({
      label: 'Back',
      src: photo.backUrl,
      alt: `${member?.firstName} ${member?.lastName} - Photo ID back`,
    });
  }

  return (
    <DetailsRow label="Photo ID">
      {photo ? (
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2 flex-col md:flex-row">
            <span>{photo?.datetime ? formattedDate : 'Upload date not found'}</span>
            <Button onClick={requestPhoto} disabled={wasPhotoIdRequested}>
              {wasPhotoIdRequested ? 'Photo ID requested' : 'Request photo ID'}
            </Button>
          </div>
          <PhotoAttachments photos={photos} />
        </div>
      ) : (
        <div className="flex items-center justify-between gap-2 flex-col md:flex-row">
          <span>No Photo ID found</span>
          <Button onClick={requestPhoto} disabled={wasPhotoIdRequested}>
            {wasPhotoIdRequested ? 'Photo ID requested' : 'Request photo ID'}
          </Button>
        </div>
      )}
    </DetailsRow>
  );
}

PhotoIDRow.propTypes = {
  member: MemberPropTypes.isRequired,
  photo: PropTypes.shape({
    datetime: PropTypes.string.isRequired,
    frontUrl: PropTypes.string.isRequired,
    backUrl: PropTypes.string,
  }).isRequired,
};

export default PhotoIDRow;
