import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../../shared/hooks/use-http';
import GroupsModal from '../../../GroupsModal';
import { GroupMemberCamelCasePropTypes } from '../shared/types';
import CheckBox from '../../../shared/CheckBox';

const WAIT_TIME_IN_SECONDS = 3;

function RemoveMembersConfirmationModal({
  isOpen,
  onClose,
  redirectToFinder,
  updateAppointment,
  appointmentId,
  members,
  authenticityToken,
  addToast: addExternalToast,
}) {
  const { sendRequest, isLoading } = useHttp();
  const [didRemoveAllSuccessfully, setDidRemoveAllSuccessfully] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);

  const handleCheckBoxChange = (memberId) => {
    setSelectedMemberIds((prevSelectedMemberIds) => {
      if (prevSelectedMemberIds.includes(memberId)) {
        return prevSelectedMemberIds.filter((id) => id !== memberId);
      }

      return [...prevSelectedMemberIds, memberId];
    });
  };

  const timerRef = useRef(null);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  const onRemoveAllMembersSuccess = () => {
    setDidRemoveAllSuccessfully(true);

    timerRef.current = setTimeout(() => {
      redirectToFinder();
    }, WAIT_TIME_IN_SECONDS * 1000);
  };

  const onRemoveSomeMembersSuccess = () => {
    addExternalToast({
      header: 'Selected members were successfully removed from the appointment.',
      type: 'success',
    });
    updateAppointment((prev) => ({
      ...prev,
      members: prev.members.filter(({ id }) => !selectedMemberIds.includes(id)),
    }));
    setSelectedMemberIds([]);
    onClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setDidRemoveAllSuccessfully(false);

    const data = selectedMemberIds.map((memberId) => ({
      member_id: memberId,
      status: 'deleted',
    }));

    try {
      await sendRequest(`/staff/appointment_occurrences/${appointmentId}/update_appointment_occurrence_members`, {
        method: 'PATCH',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: {
          appointment_occurrence_members: data,
        },
      });

      const didRemoveAllMembers = selectedMemberIds.length === members.length;

      if (didRemoveAllMembers) {
        onRemoveAllMembersSuccess();
      } else {
        onRemoveSomeMembersSuccess();
      }
    } catch (err) {
      addExternalToast({
        header: err.parsedMessage ?? 'Something went wrong. Try again later.',
        type: 'error',
        isError: true,
      });
      window.Sentry.captureException(err);
      onClose();
    }
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Remove from Appointment"
      footerButtons={[
        {
          label: 'No, go back',
          isSecondary: true,
          onClick: onClose,
          disabled: didRemoveAllSuccessfully,
        },
        {
          label: !didRemoveAllSuccessfully ? 'Yes, remove' : 'Redirect now',
          isPrimary: true,
          onClick: !didRemoveAllSuccessfully ? onSubmit : redirectToFinder,
          isLoading,
          disabled: !selectedMemberIds.length,
        },
      ]}
      size="medium"
      isLoading={isLoading}
    >
      <div className="bg-white rounded-md shadow-md">
        {!didRemoveAllSuccessfully ? (
          <div className="flex flex-col divide-y divide-gray-300">
            <span className="px-6 py-4">
              Choose members to remove from this appointment from the list below. This action cannot be undone.
            </span>
            <div className="flex flex-col gap-2 px-6 py-4">
              {members.map(({ firstName, lastName, id }) => (
                <CheckBox
                  checked={selectedMemberIds.includes(id)}
                  onChange={() => handleCheckBoxChange(id)}
                  key={id}
                  id={id}
                  label={`${firstName} ${lastName}`}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="px-6 py-4 flex flex-col">
            <span className="text-green-600 font-semibold">
              All members were successfully removed from the appointment.
            </span>
            <span>You will be redirected to appointment finder in {WAIT_TIME_IN_SECONDS} seconds...</span>
          </div>
        )}
      </div>
    </GroupsModal>
  );
}

RemoveMembersConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  redirectToFinder: PropTypes.func.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  appointmentId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(GroupMemberCamelCasePropTypes).isRequired,
  addToast: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default RemoveMembersConfirmationModal;
