import { useState, useCallback } from 'react';

const useSelect = () => {
  const [selected, setSelected] = useState([]);

  const toggle = useCallback(
    (item) => {
      setSelected((currentItems) => {
        if (typeof item === 'object') {
          if (currentItems.some((i) => i.id === item.id)) {
            return currentItems.filter((i) => i?.id !== item?.id);
          }
          return [...currentItems, item];
        }

        if (currentItems.includes(item)) {
          return currentItems.filter((i) => i !== item);
        }
        return [...currentItems, item];
      });
    },
    [setSelected]
  );

  const clearSelection = useCallback(() => setSelected([]), [setSelected]);

  return {
    selected,
    setSelected,
    toggle,
    clearSelection,
  };
};

export default useSelect;
