/* eslint-disable import/prefer-default-export */

export const statusData = [
  {
    label: 'Ready for Review',
    value: 'ready_for_review',
  },
  {
    label: 'Rx Sent',
    value: 'rx_sent',
  },
  {
    label: 'Rx Not Sent',
    value: 'rx_not_sent',
  },
  {
    label: 'Rx Cancelled',
    value: 'rx_cancelled',
  },
  {
    label: 'Rx Staging Error',
    value: 'rx_staging_error',
  },
  {
    label: 'Rx Failed to Send',
    value: 'rx_failed_to_send',
  },
];
