import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Tabs(props) {
  const [tabs, setTabs] = useState(props.tabs);
  const testID = `tabs-${props.testID}`;
  // QA request: show active/inactive states in testID when applicable
  const tabStateTestID = (activeTabId, tabId, tabName) =>
    activeTabId === tabId
      ? `activeTab-${tabName.replace(/\s+/g, '')}Btn-${testID}`
      : `${tabName.replace(/\s+/g, '')}Btn-${testID}`;

  const tabsClassName = classNames('flex items-center gap-10 border-blue-300', {
    'border-b mb-6': props.isBorder,
  });

  const getTabClassName = (tabId) => {
    const isActive = props.activeTab.id === tabId;
    return classNames('flex nav__link pb-4 tab', {
      'text-black font-semibold border-b-2 border-black tab--active': isActive,
      'text-gray-800 hover:text-black': !isActive,
    });
  };

  useEffect(() => {
    setTabs(props.tabs);
  }, [props.tabs, props.tabs?.count]);

  return (
    <div data-testid={testID} id="tabs">
      <nav className={tabsClassName}>
        {tabs.map((tab) => (
          <button
            className={getTabClassName(tab.id)}
            key={tab.id}
            onClick={() => props.onChange(tab)}
            type="button"
            data-testid={tabStateTestID(props.activeTab.id, tab.id, tab.name)}
          >
            {tab.name}

            {tab?.count && <div className="bg-blue-300 rounded-full ml-1.5 tab-count">{tab.count}</div>}
          </button>
        ))}
      </nav>
    </div>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  testID: PropTypes.string.isRequired,
  isBorder: PropTypes.string,
};

Tabs.defaultProps = {
  isBorder: true,
};

export default Tabs;
