import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import useDosespotUiUrl from './shared/hooks/use-dosespot-ui-url';
import GroupsModal from './GroupsModal';
import Toast from './shared/Toast';

function DosespotModal({ memberId, onClose, isOpen }) {
  const { url, isLoading, error, resetError } = useDosespotUiUrl({ memberId, shouldFetch: isOpen });

  const footerButtons = [
    {
      label: 'Close',
      isPrimary: true,
      onClick: onClose,
    },
  ];

  return (
    <GroupsModal isOpen={isOpen} header="View Rx" footerButtons={footerButtons} size="large" testID="dosespot-modal">
      <div className="flex items-center justify-center" style={{ height: 'min(calc(100vh - 300px), 800px)' }}>
        {error && (
          <Toast
            id={error}
            header="Something went wrong"
            message={error}
            type="error"
            onClose={resetError}
            classes="self-start"
          />
        )}
        {isLoading && <CircularProgress style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-black)' }} />}
        {!!url && <iframe title="Dosespot" src={url} className="w-full h-full" />}
      </div>
    </GroupsModal>
  );
}

DosespotModal.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DosespotModal;
