import React from 'react';
import PropTypes from 'prop-types';

function InfoRow(props) {
  const { label, value } = props;
  return (
    <div>
      <span className="mr-1.5 font-semibold">{label}</span>
      <span data-testid={props['data-testid']}>{value}</span>
    </div>
  );
}
InfoRow.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

InfoRow.defaultProps = {
  'data-testid': undefined,
};

export default InfoRow;
