import { useState, useCallback } from 'react';
import axios from 'axios';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (url, { method, headers, data, params } = { method: 'GET' }) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios({
        url,
        method,
        headers,
        data,
        params,
      });

      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);

      err.parsedMessage = err.response?.data?.message;
      const errors = err.response?.data?.errors;

      if (Array.isArray(errors) && errors.length > 0) {
        err.parsedMessage = errors.join('\n');
      }

      setError(err.parsedMessage ?? 'Something went wrong.');

      throw err;
    }
  }, []);

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
    resetError,
  };
};

export default useHttp;
