import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GeneralCell from './GeneralCell';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT } from '../helpers/DateFormatter';

const MEMBER_STATUS = {
  PENDING: 'pending',
  ATTENDED: 'attended',
  RESCHEDULED: 'rescheduled',
  NO_SHOW: 'no-show',
  CANCELLED: 'cancelled',
};

const APPOINTMENT_OCCURENCE_STATUS = {
  OCCURRED: 'occurred',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no-show',
  RESCHEDULED: 're-scheduled',
};

export const formatMemberStatus = (status) => {
  switch (status) {
    case MEMBER_STATUS.PENDING: {
      return {
        status: 'Pending',
      };
    }

    case MEMBER_STATUS.ATTENDED: {
      return {
        status: 'Attended',
      };
    }

    case MEMBER_STATUS.RESCHEDULED: {
      return {
        status: 'Rescheduled',
      };
    }

    case MEMBER_STATUS.NO_SHOW: {
      return {
        status: 'No-Show',
        color: 'text-error-700',
        weight: 'font-semibold',
      };
    }

    case MEMBER_STATUS.CANCELLED: {
      return {
        status: 'Cancelled',
      };
    }

    default: {
      return {
        status,
      };
    }
  }
};

function AppointmentOccurrenceCell({ testID, appointmentOccurrence }) {
  if (!appointmentOccurrence) {
    return <GeneralCell testID={`appointment-${testID}`} classes="mt-2 mb-2" data="Ad hoc" />;
  }

  const { scheduledAt, appointmentType, memberStatus, locations } = appointmentOccurrence || {};

  const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(scheduledAt);
  const day = dateTimeWithOffset.format('ddd, M/D/YY');
  const time = `${dateTimeWithOffset.format(DEFAULT_TIME_FORMAT)} ${timeZone.abbrev}`;

  const formattedMemberStatus = formatMemberStatus(memberStatus);

  return (
    <GeneralCell
      classes="pt-4 pr-3 pb-6"
      testID={`appointment-${testID}`}
      data={
        <div className="flex flex-col gap-1">
          {/* TODO: Replace span with link to appointment when appointment details page is done */}
          <span className="font-semibold text-teal-700 underline">{day}</span>
          <div className="flex gap-2 items-center flex-wrap" style={{ maxWidth: '192px' }}>
            <span className="text-sm text-gray-700">{time}</span>
            {locations.map((location) => (
              <span key={location.id} className="py-1 px-1.5 bg-gray-300 rounded-md text-sm text-gray-900">
                {location.name}
              </span>
            ))}
          </div>
          <span>{appointmentType}</span>
          <span>
            Status:{' '}
            <span className={classNames(formattedMemberStatus.color, formattedMemberStatus.weight)}>
              {formattedMemberStatus.status || 'Unknown'}
            </span>
          </span>
        </div>
      }
    />
  );
}

AppointmentOccurrenceCell.propTypes = {
  testID: PropTypes.string.isRequired,
  appointmentOccurrence: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scheduledAt: PropTypes.string.isRequired,
    appointmentType: PropTypes.string.isRequired,
    location: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    memberStatus: PropTypes.oneOf(Object.values(MEMBER_STATUS)),
    status: PropTypes.oneOf(Object.values(APPOINTMENT_OCCURENCE_STATUS)),
  }).isRequired,
};

export default AppointmentOccurrenceCell;
