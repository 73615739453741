import { useState, useCallback } from 'react';

const useToasts = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((toast) => {
    const newToast = { id: Date.now(), ...toast };
    setToasts((prevState) => [newToast, ...prevState]);
  }, []);

  const removeToast = useCallback((id) => setToasts((prevState) => prevState.filter((toast) => toast.id !== id)), []);

  return {
    toasts,
    addToast,
    removeToast,
  };
};

export default useToasts;
