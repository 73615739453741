import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// import Select from 'react-select';
import { AiFillWarning } from 'react-icons/ai';

function ReviewWarning(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const testID = `reviewWarningModal-${props.testID}`;

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      {isOpen && (
        <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              <div className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Review this Members Chart</h2>

                <div data-testid={`iconMessage-${testID}`} className="flex">
                  <AiFillWarning className="empanelment-button warning-icon" />
                  <p>Now that this member has been added to your care, be sure to review this member’s chart in eCW.</p>
                </div>

                <div className="flex mt-4">
                  <button
                    className="btn btn--rounded btn--primary flex-1 ml-2"
                    type="button"
                    onClick={props.onClose}
                    data-testid={`closeBtn-${testID}`}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </div>
  );
}

ReviewWarning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  testID: PropTypes.string.isRequired,

  // functions
  onClose: PropTypes.func.isRequired,
};

export default ReviewWarning;
