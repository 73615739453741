/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#237786',
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '38px',
          background: '#ffffff',
          color: '#868686',
        },
      },
    },
  },
});

function MuiTimePicker(props) {
  const { value, label, onChange } = props;

  const [open, setOpen] = React.useState(false);

  const initialValue = () => (value ? dayjs(value) : dayjs(new Date().getTime));

  return (
    <div className={`react-inline ${props.error && 'danger'}`}>
      <div className="filter-label" style={props?.isFormLabel ? { color: 'black', fontWeight: 700 } : props.labelStyle}>
        {label}
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <TimePicker
            value={initialValue()}
            onChange={(newValue) => onChange(newValue)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderColor: '#b3b3b3',

                '&.Mui-focused fieldset': {
                  border: '2px solid  #237786',
                  outlineOffset: '1px',
                  outline: '1px solid #237786',

                  '& legend': {
                    display: 'none',
                  },
                },
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#b3b3b3',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#237786',
              },
            }}
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
              },
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

MuiTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  isFormLabel: PropTypes.bool,
  labelStyle: PropTypes.object,
};

MuiTimePicker.defaultProps = {
  value: new Date().getTime(),
  label: '',
  error: null,
  labelStyle: null,
  isFormLabel: false,
};

export default MuiTimePicker;
