import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultValues = {
  query: '',
};

const MemberSearchContext = createContext(defaultValues);

export function MemberSearchProvider({ query, children }) {
  const memoizedValue = useMemo(() => ({ query }), [query]);

  return <MemberSearchContext.Provider value={memoizedValue}>{children}</MemberSearchContext.Provider>;
}

MemberSearchProvider.propTypes = {
  query: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useMemberSearchContext = () => useContext(MemberSearchContext);
