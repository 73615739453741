import React from 'react';
import PropTypes from 'prop-types';

// IMPORT
import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';

// HELPER
import { mmmddTimeFormat } from '../../../helpers/DateFormatter';

function InviteHistoryInfo(props) {
  const phoneOrEmail = parsePhoneNumberFromString(props.information_method)
    ? parsePhoneNumber(props.information_method, 'US').formatNational()
    : props.information_method;

  return (
    <div className="text-right">
      {props.last_sent && <p className="text-gray-700 font-normal text-sm"> {mmmddTimeFormat(props.last_sent)} </p>}

      <p className="text-gray-700 font-normal text-sm">
        {' '}
        {/* ###-###-#### or email@email.com */}
        {phoneOrEmail}{' '}
      </p>
    </div>
  );
}

InviteHistoryInfo.propTypes = {
  last_sent: PropTypes.string.isRequired,
  information_method: PropTypes.string.isRequired,
};

export default InviteHistoryInfo;
