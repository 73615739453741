import React, { useState } from 'react';
import propTypes from 'prop-types';
import { MdOutlineAddBox } from 'react-icons/md';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import GeneralCell from '../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { useMemberURLs } from './useMemberURLs';

function EmpanelmentCell({ testID, empanelment }) {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const { groupsManagementUrl } = useMemberURLs();

  if (!empanelment?.empanelmentGroupId) {
    return (
      <GeneralCell
        classes="mt-2 mb-2"
        testID={`empanelment-${testID}`}
        data={
          <a href={groupsManagementUrl} target="_top" className="flex gap-2 items-center uppercase">
            <MdOutlineAddBox />
            <span>assign empanelment</span>
          </a>
        }
      />
    );
  }

  const { empanelmentCounselorName, empanelmentProviderName, empanelmentDateTime, empanelmentLocationName } =
    empanelment;
  const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(empanelmentDateTime);
  const time = `${dateTimeWithOffset.format('ddd, h:mm A')} ${timeZone.abbrev}`;

  const DetailsBtnIcon = showMoreDetails ? HiChevronUp : HiChevronDown;
  const toggleDetails = () => setShowMoreDetails((prev) => !prev);

  return (
    <GeneralCell
      classes="pt-4 pr-3 pb-6"
      testID={`empanelment-${testID}`}
      data={
        <dl className="flex flex-col gap-2">
          <button
            onClick={toggleDetails}
            type="button"
            className="font-medium text-teal-700 border-0 flex items-center uppercase"
          >
            <span>show more details</span>
            <DetailsBtnIcon className="text-2xl" />
          </button>
          {showMoreDetails && (
            <>
              <div>
                <dt className="inline font-bold">Modality: </dt>
                <dd className="inline">{empanelment.empanelmentGroupModality}</dd>
              </div>
              <div>
                <dt className="inline font-bold">Clinical Model: </dt>
                <dd className="inline">{empanelment.empanelmentGroupType}</dd>
              </div>
              <div>
                <dt className="inline font-bold">Other information: </dt>
                <dd className="inline">{empanelment.empanelmentSecondaryGroupTypes.join(', ')}</dd>
              </div>
              <div>
                <dt className="inline font-bold">Office Manager: </dt>
                <dd className="inline">{empanelment.empanelmentOfficeManagerName}</dd>
              </div>
            </>
          )}
          <div>
            <dt className="inline font-bold">Counselor: </dt>
            <dd className="inline">{empanelmentCounselorName}</dd>
          </div>
          <div>
            <dt className="inline font-bold">Provider: </dt>
            <dd className="inline">{empanelmentProviderName}</dd>
          </div>
          <span>{`${time}, ${empanelmentLocationName}`}</span>
        </dl>
      }
    />
  );
}

EmpanelmentCell.propTypes = {
  testID: propTypes.string.isRequired,
  empanelment: propTypes.shape({
    empanelmentGroupId: propTypes.number,
    empanelmentDateTime: propTypes.string,
    empanelmentLocationId: propTypes.number,
    empanelmentLocationName: propTypes.string,
    empanelmentOfficeManagerId: propTypes.number,
    empanelmentOfficeManagerName: propTypes.string,
    empanelmentProviderId: propTypes.number,
    empanelmentProviderName: propTypes.string,
    empanelmentCounselorId: propTypes.number,
    empanelmentCounselorName: propTypes.string,
    empanelmentGroupModality: propTypes.string,
    empanelmentGroupType: propTypes.string,
    empanelmentSecondaryGroupTypes: propTypes.arrayOf(propTypes.string),
  }).isRequired,
};

export default EmpanelmentCell;
