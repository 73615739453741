/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// HOOKS
import useData from './hooks/use-data';

function LocationTag(props) {
  const { isLoaded, data } = useData(props);

  return (
    <>
      {isLoaded && (
        <span className="bg-gray-300 py-1 px-2 rounded text-gray-900 mr-1 ml-1 text-sm">
          {props.preloadedData || data}
        </span>
      )}
    </>
  );
}

LocationTag.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,

  preloadedData: PropTypes.string,
};

LocationTag.defaultProps = {
  preloadedData: null,
};

export default LocationTag;
