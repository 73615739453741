import React from 'react';
import PropTypes from 'prop-types';

function FiltersColumn({ children }) {
  return <div className="p-4 flex-1 flex flex-col gap-6">{children}</div>;
}

FiltersColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FiltersColumn;
