import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// ICONS
// import fedex from './fedex.png';

// HELPERS
import { packageOptionStyles } from './styles';

function PackageOptions(props) {
  // OPTIONS
  const [opts, setOpts] = useState(props.options);

  // RATES
  const [rate, setRate] = useState({});
  const [rateLabel, setRateLabel] = useState('');
  useEffect(() => setRateLabel(rate?.label), [rate]);

  // SET DEFAULTS ON LOAD OF OPTIONS
  useEffect(() => {
    setOpts(props.options);

    if (props.options.length > 0) {
      const firstOpt = props.options[0];
      firstOpt.isChecked = true;

      setRate(firstOpt);
      setOpts([...props.options]);

      props.handleChange(firstOpt?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  return (
    <FormControl sx={{ width: '100%', marginTop: '20px' }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={rateLabel}
        onChange={(e) => {
          props.handleChange(e.target.value);

          // eslint-disable-next-line no-param-reassign
          opts.map((o) => {
            o.isChecked = false;
            return o;
          });

          const selected = opts.find((element) => element?.label === e.target.value);
          selected.isChecked = true;

          setOpts([...opts]);
          setRate(selected);
        }}
      >
        {opts?.map((option) => (
          <div
            key={option?.label}
            className="flex rounded first:rounded-b-none"
            style={{
              ...packageOptionStyles,
              height: '80px',
              width: '100%',
              backgroundColor: option.isChecked && '#E7F3F4',
              borderColor: option.isChecked && '#237786',
            }}
          >
            <div className="flex-1 my-auto">
              <FormControlLabel
                value={option.label}
                control={<Radio />}
                label={
                  <div className="flex">
                    {/* {renderImage(option.logo)} */}
                    <div style={{ marginLeft: '10px' }}>{option.label}</div>
                  </div>
                }
              />
            </div>

            <div className="my-auto">
              <div className="font-bold">Groups - FedEx One Rate</div>

              <div>{option.subText}</div>
            </div>
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

PackageOptions.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PackageOptions;
