import React, { _useEffect, _useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

/* REACT Flexbox: https://mui.com/system/flexbox/ */

function FlexBox(props) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

FlexBox.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  // eslint-disable-next-line react/require-default-props
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default FlexBox;
