import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import EditableSelectRowValue from '../EditableSelectRowValue';
import { FacilityPropTypes } from '../types';

function DefaultFacilityRow({ defaultFacility, allFacilities, isLoadingRequest, handleMemberEdit }) {
  const [selectedValue, setSelectedValue] = useState({
    value: defaultFacility?.id,
    label: defaultFacility?.label,
  });
  const [isEditing, setIsEditing] = useState(false);

  const convertedFacilities = useMemo(
    () =>
      allFacilities.map((facility) => ({
        value: facility?.id,
        label: facility?.label,
      })),
    [allFacilities]
  );

  return (
    <DetailsRow label="Default Facility">
      <EditableSelectRowValue
        name="default-facility"
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        isLoading={isLoadingRequest}
        onSave={() => handleMemberEdit({ default_facility_id: selectedValue?.value }, setIsEditing)}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        valuesList={convertedFacilities}
      />
    </DetailsRow>
  );
}

DefaultFacilityRow.propTypes = {
  defaultFacility: FacilityPropTypes.isRequired,
  allFacilities: PropTypes.arrayOf(FacilityPropTypes),
  isLoadingRequest: PropTypes.bool,
  handleMemberEdit: PropTypes.func.isRequired,
};

DefaultFacilityRow.defaultProps = {
  allFacilities: [],
  isLoadingRequest: false,
};

export default DefaultFacilityRow;
