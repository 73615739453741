import { useState } from 'react';

// PACKAGES
import axios from 'axios';
import moment from 'moment/moment';

import { getTimeZone } from '../../../helpers/TimezoneHelper';

// PUT USE EFFECT IN PARENT COMPONENT TO CALL WHICHEVER FILTERS YOU NEED
// EXAMPLE USE_EFFECT
// useEffect(() => {
//   fetchStaff();
//   fetchLocations();
// }, []);

const dayOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const useSharedFilters = () => {
  const [staff, setStaffFilters] = useState([]);
  const [locations, setLocationFilters] = useState([]);
  const [locationsWithAddresses, setLocationsWithAddresses] = useState([]);
  const [members, setMemberFilters] = useState([]);
  const [states, setStateFilters] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [numericalOptions, setNumberOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);

  const fetchStaff = async (addUnassigned = true) => {
    try {
      const { data: response } = await axios.get('/users/staff_filters');
      if (Array.isArray(response)) {
        if (addUnassigned) {
          response?.unshift({ value: 'unassigned', label: 'Unassigned' });
        }

        setStaffFilters(response);
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchLocations = async () => {
    try {
      const { data: response } = await axios.get('/locations/fetch_filters');
      setLocationFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchLocationsWithAddresses = async () => {
    try {
      const { data: response } = await axios.get('/locations/fetch_filters_with_addresses');
      setLocationsWithAddresses(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchMembers = async () => {
    try {
      const { data: response } = await axios.get('/staff/members/fetch_filters');
      setMemberFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchStates = async () => {
    try {
      const { data: response } = await axios.get('/staff/states/filters_only_with_locations');
      setStateFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchAllStates = () => {
    const listOfStates = [
      'AL',
      'AK',
      'AS',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FM',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MH',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'MP',
      'OH',
      'OK',
      'OR',
      'PW',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ];
    const formattedStates = [];

    listOfStates.forEach((state) => {
      formattedStates.push({
        label: state,
        value: state,
      });
    });

    setAllStates(formattedStates);
  };

  const fetchTimeOptions = () => {
    const currentTime = moment();
    const timezoneLabel = getTimeZone(currentTime);

    const hours = [];

    for (let hour = 0; hour < 24; hour += 1) {
      hours.push({
        value: `${moment({ hour, minute: 0 }).format('HH:mm')} ${moment({ hour, minute: 30 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 0 }).format('h:mm A')} ${timezoneLabel}`,
      });

      hours.push({
        value: `${moment({ hour, minute: 30 }).format('HH:mm')} ${moment({ hour: hour + 1, minute: 0 }).format('HH:mm')}`,
        label: `${moment({ hour, minute: 30 }).format('h:mm A')} ${timezoneLabel}`,
      });
    }

    setTimeOptions(hours);
  };

  const fetchNumericalOptions = () => {
    const numberOptions = [];

    for (let number = 0; number <= 100; number += 1) {
      numberOptions.push({ value: number, label: number });
    }

    setNumberOptions(numberOptions);
  };

  const fetchPackageDimensions = async () => {
    try {
      const { data: response } = await axios.get('/staff/uds_supply/package_dimensions');
      setPackageOptions(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  return {
    dayOptions,
    staff,
    locations,
    locationsWithAddresses,
    members,
    states,
    allStates,
    timeOptions,
    numericalOptions,
    packageOptions,
    fetchStaff,
    fetchLocations,
    fetchMembers,
    fetchStates,
    fetchAllStates,
    fetchTimeOptions,
    fetchNumericalOptions,
    fetchLocationsWithAddresses,
    fetchPackageDimensions,
  };
};

export default useSharedFilters;
