import PropTypes from 'prop-types';

export const personPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const RX_STATUS = {
  READY_FOR_REVIEW: 'ready_for_review',
  RX_SENT: 'rx_sent',
  RX_NOT_SENT: 'rx_not_sent',
  RX_CANCELLED: 'rx_cancelled',
  RX_STAGING_ERROR: 'rx_staging_error',
  RX_FAILED_TO_SEND: 'rx_failed_to_send',
};
