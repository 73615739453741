/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import AttendanceLabel from './AttendanceLabel';
import MonthBar from '../shared/MonthBar';

function BarViewContainer(props) {
  return (
    <div className={`flex-1 ${props.classes}`}>
      <div className="m-2">
        <AttendanceLabel label={props.label} secondaryLabel={props.secondaryLabel} />

        {props.data?.map((month) => (
          <MonthBar data={month} />
        ))}
      </div>
    </div>
  );
}

BarViewContainer.propTypes = {
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
};

BarViewContainer.defaultProps = {
  classes: '',
};

export default BarViewContainer;
