import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import ActionsMenu from './ActionsMenu';
import CompleteActionIcon from './CompleteActionIcon';
import RemoveActionIcon from './RemoveActionIcon';
import EditActionIcon from './EditActionIcon';

function GoalActionsMenu({ goalId, updateCallback, editCallback, testId }) {
  const handleComplete = () => {
    const url = `/staff/goals/${goalId}/complete`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        updateCallback();
      }
    });
  };

  const handleRemove = () => {
    const url = `/staff/goals/${goalId}/deactivate`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        updateCallback();
      }
    });
  };

  return (
    <ActionsMenu testId={testId}>
      <button className="goal-action-button" type="button" data-testid={`complete-${testId}`} onClick={handleComplete}>
        <CompleteActionIcon testId={`complete-icon-${testId}`} />
        Mark Complete
      </button>
      <button className="goal-action-button" type="button" data-testid={`remove-${testId}`} onClick={handleRemove}>
        <RemoveActionIcon testId={`remove-icon-${testId}`} />
        Remove
      </button>
      <button className="goal-action-button" type="button" data-testid={`edit-${testId}`} onClick={editCallback}>
        <EditActionIcon testId={`edit-icon-${testId}`} />
        Keep & Edit
      </button>
    </ActionsMenu>
  );
}

GoalActionsMenu.propTypes = {
  goalId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  editCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

export default GoalActionsMenu;
