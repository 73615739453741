import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  const [statesData, setStates] = useState({});
  const [facilitiesData, setFacilities] = useState({});
  const [staffData, setStaff] = useState({});
  const [daysData, setDays] = useState({});
  const [currentDefaultStaffData, setCurrentDefaultStaff] = useState(null);
  const [filterLoaded, setFilterLoaded] = useState(false);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_staff/fetch_filters');

        const { states, staff, locations, days, default_staff_filter } = response;

        setStates(states);
        setFacilities(locations);
        setStaff(staff);
        setDays(days);
        setCurrentDefaultStaff(default_staff_filter);
        setFilterLoaded(true);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchFilters();
  }, []);

  return {
    statesData,
    facilitiesData,
    staffData,
    daysData,
    currentDefaultStaffData,
    filterLoaded,
  };
};

export default useFetchData;
