import React from 'react';
import PropTypes from 'prop-types';
import MemberInfo, { memberSummaryPropType } from './MemberInfo';

function MembersList({ members }) {
  return (
    <div className="flex flex-col gap-5 mb-6">
      {members.map((member) => (
        <MemberInfo key={member.id} member={member} />
      ))}
    </div>
  );
}

MembersList.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      mrn: PropTypes.string.isRequired,
      dob: PropTypes.string.isRequired,
      memberSummaries: PropTypes.shape({
        clinical: memberSummaryPropType,
        demographics: memberSummaryPropType,
        healthieProfile: memberSummaryPropType,
        historicalDetails: memberSummaryPropType,
        historicalStats: memberSummaryPropType,
        medical: memberSummaryPropType,
        monthToDate: memberSummaryPropType,
      }),
      groupInformation: PropTypes.shape({
        clinicalModel: PropTypes.string,
        counselorName: PropTypes.string,
        dateTime: PropTypes.string,
        groupType: PropTypes.string,
        id: PropTypes.number,
        location: PropTypes.string,
        modality: PropTypes.string,
        officeManagerName: PropTypes.string,
        providerName: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default MembersList;
