import React from 'react';
import PropTypes from 'prop-types';
import { ACCOUNT_ACTIVATION_STATUS } from '../../member_details/rows/AccountActivationRow';
import GeneralCell from '../../../GeneralCell';
import { getDateDistance } from './helpers';

function MemberAccountCell({ testID, account }) {
  const { status, date } = account;

  const getStatusText = () => {
    switch (status) {
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_CREATED:
        return 'Account Created';
      case ACCOUNT_ACTIVATION_STATUS.INVITATION_SENT:
        return 'Invitation Sent';
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_NOT_CREATED:
        return 'Account Not Created';
      default:
        return null;
    }
  };

  return (
    <GeneralCell
      testID={testID}
      data={
        <div className="flex flex-col gap-1">
          <span className="text-base">{getStatusText()}</span>
          {!!date && <span className="text-sm text-gray-700">{getDateDistance(date)}</span>}
        </div>
      }
    />
  );
}

MemberAccountCell.propTypes = {
  testID: PropTypes.string.isRequired,
  account: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(ACCOUNT_ACTIVATION_STATUS)).isRequired,
    date: PropTypes.string,
  }).isRequired,
};

export default MemberAccountCell;
