import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InviteHistoryInfo from './InviteHistoryInfo';
import InviteHistoryMessage from './InviteHistoryMessage';

function InviteHistoryEmailItem(props) {
  // DOCUMENT DATA STRUCT ex.
  // // => [{:email_last_sent=>Wed, 11 Jan 2023 17:08:18.898476000 UTC +00:00, :email_error=>nil, :type=>:email, :email=>"marco@durgan.io"}]

  return (
    <div className="flex justify-between">
      {props.history?.email_error ? (
        <InviteHistoryMessage
          primaryMessage="Email Error"
          secondaryMessage={props.history?.email_error}
          colorClassName="bg-error-700"
        />
      ) : (
        <InviteHistoryMessage
          primaryMessage="Email Sent"
          secondaryMessage="Sent Successfully"
          colorClassName="bg-positive-700"
        />
      )}

      <InviteHistoryInfo last_sent={props.history?.email_last_sent} information_method={props.history?.email} />
    </div>
  );
}

InviteHistoryEmailItem.propTypes = {
  history: PropTypes.object.isRequired,
};

export default InviteHistoryEmailItem;
