/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect, useRef } from 'react';
import useHttp from '../../../shared/hooks/use-http';
import setRequestParams from './set-request-params';
import { FILTER_TYPES } from './constants';
import { convertObjKeysToCamelCase } from '../../../../helpers/utils';

const useIndividualAppointments = (filters) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState({});
  const [sortedBy, setSortedBy] = useState({ id: 'scheduled_at', desc: false });
  const functionRef = useRef(() => {});

  const fetchData = async (pageNumber = 1) => {
    setCurrentPage(pageNumber);
    const { availability: _, ...updatedParams } = setRequestParams(pageNumber, filters, sortedBy);

    try {
      const response = await sendRequest('/staff/appointment_occurrences/individual_appointments', {
        params: updatedParams,
      });

      setCurrentData(convertObjKeysToCamelCase(response));
    } catch (e) {
      window.Sentry?.captureException(e);
    }
  };

  useEffect(() => {
    functionRef.current = fetchData;
  }, [fetchData]);

  useEffect(() => {
    setCurrentData({});
    functionRef.current(1);
  }, [
    setCurrentData,
    filters[FILTER_TYPES.appointmentType],
    filters[FILTER_TYPES.dayOfWeek],
    filters[FILTER_TYPES.state],
    filters[FILTER_TYPES.timezone],
    filters[FILTER_TYPES.facility],
    filters[FILTER_TYPES.availability],
    filters[FILTER_TYPES.provider],
    filters[FILTER_TYPES.member],
    filters[FILTER_TYPES.fromTime],
    filters[FILTER_TYPES.toTime],
    filters[FILTER_TYPES.fromDate],
    filters[FILTER_TYPES.toDate],
    sortedBy,
  ]);

  const decreaseAvailableSeats = (id) => {
    setCurrentData((prevData) => {
      const newData = { ...prevData };
      newData.json_data = [...prevData.json_data];
      const index = newData.json_data.findIndex((data) => data.id === id);
      newData.json_data[index] = {
        ...newData.json_data[index],
        capacity: newData.json_data[index].capacity - 1,
      };

      return newData;
    });
  };

  const handleSort = useCallback(
    (id) => {
      setSortedBy((prevSort) => ({ id, desc: prevSort && prevSort.id === id ? !prevSort.desc : true }));
    },
    [setSortedBy]
  );

  return {
    isLoading,
    error,
    currentPage,
    currentData,
    decreaseAvailableSeats,
    fetchData,
    handleSort,
    sortedBy,
  };
};

export default useIndividualAppointments;
