import React, { useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { PiCrosshairSimpleBold } from 'react-icons/pi';

function TableHeaderCell(props) {
  const className = props.isClickable ? 'cursor-pointer' : '';
  const [direction, setSortDirection] = useState(props.direction);

  const handleClick = () => {
    if (!props.isClickable) {
      return;
    }

    const sortDirection = direction === 'asc' ? 'desc' : 'asc';
    setSortDirection(sortDirection);

    props.onHeaderClick(props.accessorKey, sortDirection);
  };

  return (
    <>
      {props.show && (
        <th className={`bg-white ${className} ${props.classes}`} onClick={handleClick}>
          {props.icon && <PiCrosshairSimpleBold className="mt-4 ml-4 h-5 w-5" />}

          <p className="m-4 font-body font-semibold" style={props.style}>
            {props.text}
            {props.isSorting && <UnfoldMoreIcon />}
          </p>
        </th>
      )}
    </>
  );
}

TableHeaderCell.propTypes = {
  show: PropTypes.bool,
  classes: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  accessorKey: PropTypes.string,
  direction: PropTypes.string,
  text: PropTypes.string.isRequired,
  isClickable: PropTypes.bool,
  isSorting: PropTypes.bool,
  onHeaderClick: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  show: true,
  classes: '',
  style: {},
  icon: null,
  accessorKey: '',
  direction: 'asc',
  isClickable: false,
  isSorting: false,
  onHeaderClick: () => {},
};

export default TableHeaderCell;
