/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';

// COMPONENTS
import InviteHistoryModalItem from './InviteHistoryModalItem';

// HOOKS
import useData from '../hooks/use-data';

function InviteAccountStatusHistoriesModal(props) {
  const { isLoaded, data } = useData(props);

  return (
    <div className="fixed z-20 inset-0">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-20 relative">
        <div className="main-edit-modal-empanelment inline-block align-bottom bg-white rounded-lg text-left shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="w-full whitespace-normal max-h-96 overflow-y-scroll">
            <div className="flex justify-around items-center py-4 px-6">
              <div className="text-left text-black w-full text-2xl font-bold font-body" id="modal-headline">
                Account Invitations
              </div>

              <CloseIcon style={{ cursor: 'pointer' }} onClick={() => props.onClose()} />
            </div>

            <hr className="w-full" />

            {isLoaded ? (
              <div className="w-full px-6">
                <ul>
                  {data?.invite_history?.map((inviteData) => (
                    <InviteHistoryModalItem key={inviteData?.id} history={inviteData} />
                  ))}
                </ul>
              </div>
            ) : (
              <div className="w-full px-6">
                <Skeleton variant="rectangular" height={60} sx={{ margin: '10px' }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-10 bg-teal-700 opacity-80" />
    </div>
  );
}

InviteAccountStatusHistoriesModal.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteAccountStatusHistoriesModal;
