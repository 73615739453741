import React from 'react';
import PropTypes from 'prop-types';

function AttendanceDate({ date, time }) {
  const flexClass = 'flex-1';

  return (
    <div className={`${flexClass}`}>
      <div>{date}</div>
      <div className="text-gray-700">{time}</div>
    </div>
  );
}

AttendanceDate.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
};

AttendanceDate.defaultProps = {
  date: '',
  time: '',
};

export default AttendanceDate;
