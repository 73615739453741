/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TbTrash } from 'react-icons/tb';
import classNames from 'classnames';
import useHttp from '../shared/hooks/use-http';
import DeleteNoteConfirmationModal from './DeleteNoteConfirmationModal';
import DocumentationActionPerformedSummaryModal from '../DocumentationActionPerformedSummaryModal';
import { ERROR_SUFFIX } from './helpers/constants';

function DeleteNote({ authenticityToken, noteIds, templateName, disabled, onSuccess }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState();
  const [deleteStatus, setDeleteStatus] = useState();
  const { sendRequest, isLoading } = useHttp();
  const isButtonDisabled = isLoading || disabled;

  const groupNotesErrorMessage = noteIds?.length > 1 ? ' for all members' : '';
  const errorMessage = `Note has not been successfully deleted${groupNotesErrorMessage}. ${ERROR_SUFFIX}`;

  const handleDelete = async () => {
    try {
      const response = await sendRequest('/staff/documentation/charting_notes', {
        method: 'DELETE',
        params: {
          charting_note_ids: noteIds,
        },
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });

      const deletedNoteIds = response?.successfully_processed;

      if (deletedNoteIds?.length !== noteIds.length) {
        setDeleteStatus({
          message: errorMessage,
          isError: true,
          deletedNoteIds,
        });
      } else {
        setDeleteStatus({
          message: 'Note has been successfully deleted',
          deletedNoteIds,
        });
      }
    } catch (err) {
      setDeleteStatus({
        message: errorMessage,
        isError: true,
      });
    }

    setIsDeleteModalOpen(false);
  };

  const handleCloseStatusModal = () => {
    if (onSuccess) onSuccess(deleteStatus.deletedNoteIds || []);
    setDeleteStatus(null);
  };

  const deleteStatusContent = [{ label: 'Template', value: templateName, type: 'text' }];

  return (
    <>
      <button type="button" disabled={isButtonDisabled} onClick={() => setIsDeleteModalOpen(true)}>
        <TbTrash
          data-testid="trash-icon"
          className={classNames('w-6 h-6', {
            'text-error-700': !isButtonDisabled,
            'text-gray-500': isButtonDisabled,
          })}
        />
      </button>
      <DeleteNoteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        templateName={templateName}
        isLoading={isLoading}
      />
      <DocumentationActionPerformedSummaryModal
        status={deleteStatus?.isError ? 'error' : 'success'}
        header="Delete Note"
        message={deleteStatus?.message}
        isOpen={!!deleteStatus}
        setIsOpen={handleCloseStatusModal}
        content={deleteStatusContent}
      />
    </>
  );
}

DeleteNote.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  noteIds: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
};

DeleteNote.defaultProps = {
  disabled: false,
  onSuccess: undefined,
};

export default DeleteNote;
