import { useCallback, useEffect, useState } from 'react';
import { convertObjKeysToCamelCase } from '../../../../helpers/utils';
import useHttp from '../../../shared/hooks/use-http';

const useAppointmentMember = (memberId) => {
  const { sendRequest, error, isLoading } = useHttp();
  const [member, setMember] = useState(false);

  const fetchMember = useCallback(async () => {
    if (!memberId) return;
    const response = await sendRequest(`/staff/members/${memberId}/appointment_member`);
    setMember(convertObjKeysToCamelCase(response?.member, ['variables']) || {});
  }, [memberId, sendRequest]);

  useEffect(fetchMember, [fetchMember]);

  return { member, error, isLoading };
};

export default useAppointmentMember;
