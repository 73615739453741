import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InformationHeader from './InformationHeader';
import InformationContainer from './InformationContainer';

import TextRowContainer from '../shared/TextRowContainer';

function ClinicalInformation(props) {
  const { specialPopProblem, specialPopOther, phaseOfCare, engagementRec, rss, carePlan, openTasks, healthCheckin } =
    props?.data || {};

  const [openStatus, setOpenStatus] = useState(false);

  function renderOpenContent() {
    if (openStatus) {
      return (
        <>
          <TextRowContainer
            label="Special Population"
            secondaryLabel="Problem List"
            text={specialPopProblem?.text}
            subtext={specialPopProblem?.subtext}
            isNewlineSubtext
          />
          <TextRowContainer
            label="Special Population"
            secondaryLabel="Other"
            text={specialPopOther?.text}
            subtext={specialPopOther?.subtext}
            isNewlineSubtext
          />
          <TextRowContainer label="Phase of Care" text={phaseOfCare} />
          <TextRowContainer label="Engagement Rec" text={engagementRec?.text} status={engagementRec?.status} />
          <TextRowContainer label="RSS" text={rss} />
          <TextRowContainer label="Care Plan" text={carePlan?.text} link={carePlan?.link} subtext={carePlan?.subtext} />
          <TextRowContainer label="Open Tasks" text={openTasks?.text} link={openTasks?.link} />
          <TextRowContainer
            label="Health Check-in"
            text={healthCheckin?.text}
            link={healthCheckin?.link}
            hasBorder={false}
          />
        </>
      );
    }
    return <></>;
  }

  return (
    <InformationContainer isBgBlue addPadding>
      <InformationHeader label="Clinical" isOpen={openStatus} setOpenStatus={setOpenStatus} />
      {renderOpenContent()}
    </InformationContainer>
  );
}

ClinicalInformation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClinicalInformation;
