import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineExclamationCircle, AiOutlineWarning } from 'react-icons/ai';
import GeneralCell from '../../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import { HEALTH_CHECKIN_STATUS_CLINICALLY_CONCERNING, HEALTH_CHECKIN_STATUS_RISKY } from './constants';
import { DEFAULT_DATE_FORMAT } from '../../../../helpers/DateFormatter';

function HealthCheckinCell({ healthCheckIn, testID }) {
  if (!healthCheckIn) return <GeneralCell testID={testID} classes="mb-2 flex items-center" data="N/A" />;

  const { status, datetime, link } = healthCheckIn;
  const [dateTimeWithOffset] = convertToSupportedTimeZone(datetime);
  const formattedDate = dateTimeWithOffset.format(DEFAULT_DATE_FORMAT);

  const getSubtext = () => {
    if (status === HEALTH_CHECKIN_STATUS_CLINICALLY_CONCERNING)
      return (
        <div className="text-error-700 flex flex-row gap-1 items-center text-sm font-semibold">
          <AiOutlineExclamationCircle className="h-5 w-5" />
          <span>Clinically Concerning</span>
        </div>
      );
    if (status === HEALTH_CHECKIN_STATUS_RISKY)
      return (
        <div className="text-warning-500 flex flex-row gap-1 items-center text-sm font-semibold">
          <AiOutlineWarning className="h-5 w-5" />
          <span>Risky</span>
        </div>
      );

    return null;
  };

  return (
    <GeneralCell
      testID={testID}
      data={
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-teal-700 underline">
            <a href={link} target="_blank" rel="noreferrer">
              {formattedDate}
            </a>
          </span>
          {getSubtext()}
        </div>
      }
    />
  );
}

HealthCheckinCell.propTypes = {
  testID: PropTypes.string,
  healthCheckIn: PropTypes.shape({
    status: PropTypes.string,
    datetime: PropTypes.string,
    link: PropTypes.string,
  }),
};

HealthCheckinCell.defaultProps = {
  testID: null,
  healthCheckIn: null,
};

export default HealthCheckinCell;
