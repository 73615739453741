import React from 'react';
import PropTypes from 'prop-types';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaRegCircleXmark } from 'react-icons/fa6';

function StatusIndicator({ status }) {
  if (status) return <FaRegCheckCircle className="text-positive-700 h-5 w-5" />;
  return <FaRegCircleXmark className="text-error-700 h-5 w-5" />;
}

StatusIndicator.propTypes = {
  status: PropTypes.bool.isRequired,
};

export default StatusIndicator;
