import React from 'react';
import PropTypes from 'prop-types';

import ProgramExpectationIcon from './ProgramExpectationIcon';

function RsaLockBadge({ testId }) {
  return (
    <div className="goal-badge rsa-lock-badge" data-testid={testId}>
      <ProgramExpectationIcon testId={`icon-${testId}`} />
      <p data-testid={`text-${testId}`}>
        <strong>RSA</strong>
      </p>
    </div>
  );
}

RsaLockBadge.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default RsaLockBadge;
