import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaLocationDot } from 'react-icons/fa6';
// SERVICES
import { formatTime, formatDate } from './DateFormatHelper';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';

function DateCell(props) {
  const testID = `dateCell-${props.testID}`;
  const [date, setDate] = useState(null);
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    setDate(props.dateTime);
    setLocation(props.location);
    setDistance(props.distance);
  }, [props.dateTime, props.location, props.distance]);

  const [dateTimeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(props.dateTime);
  const day = props.isTimeZone ? `${dateTimeWithOffset.format('dddd')}s` : formatDate(date, props.showFullDate);
  const time = props.isTimeZone
    ? `${dateTimeWithOffset.format('LT')} ${supportedTimeZone.abbrev}`
    : formatTime(props.dateTime);

  return (
    <td>
      <div data-testid={testID} className="flex flex-wrap items-end leading-tight p-2.5 gap-2">
        <div>
          <p data-testid={`day-${testID}`} className="my-1">
            <time>{day}</time>
          </p>
          <div className="flex items-center">
            <div data-testid={`dateTime-${testID}`} className="text-sm text-gray-700">
              <time>{time}</time>
            </div>
            <div
              data-testid={`location-${testID}`}
              className="ml-2 text-sm bg-gray-300 py-1 px-2 rounded text-gray-900"
            >
              {location}
            </div>
          </div>
        </div>
        {distance && (
          <div data-testid={`distance-${testID}`} className="flex text-purple-700 items-center font-bold text-xs">
            <FaLocationDot className="text-purple-700 mr-1" />
            {`${parseFloat(distance).toFixed(1)} mi`}
          </div>
        )}
      </div>
    </td>
  );
}

DateCell.propTypes = {
  testID: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  distance: PropTypes.string,
  showFullDate: PropTypes.bool,
  isTimeZone: PropTypes.bool,
};

DateCell.defaultProps = {
  showFullDate: false,
  distance: null,
  isTimeZone: false,
};

export default DateCell;
