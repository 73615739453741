function showUdsSubSection(udsSubRecord) {
  return udsSubRecord?.attachment_record?.id != null;
}

// recordId: the overall record the photos are attached to
// id: attachment record id
const udsTestRecord = (udsRecord) => ({
  showUdsSection: udsRecord?.show_uds_section,
  udsRecordId: udsRecord?.record?.id,
  udsFront: {
    showSubsection: showUdsSubSection(udsRecord?.front),
    id: udsRecord?.front?.attachment_record?.id,
    date: udsRecord?.front?.attachment_record?.created_at,
    url: udsRecord?.front?.url,
  },
  udsBack: {
    showSubsection: showUdsSubSection(udsRecord?.back),
    id: udsRecord?.back?.attachment_record?.id,
    date: udsRecord?.back?.attachment_record?.created_at,
    url: udsRecord?.back?.url,
  },
  udsLeft: {
    showSubsection: showUdsSubSection(udsRecord?.left),
    id: udsRecord?.left?.attachment_record?.id,
    date: udsRecord?.left?.attachment_record?.created_at,
    url: udsRecord?.left?.url,
  },
  udsRight: {
    showSubsection: showUdsSubSection(udsRecord?.right),
    id: udsRecord?.right?.attachment_record?.id,
    date: udsRecord?.right?.attachment_record?.created_at,
    url: udsRecord?.right?.url,
  },
});

export default udsTestRecord;
