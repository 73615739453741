import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Shows a list of care plans, and when they were created.
 */
function CarePlanList({ activeCarePlan, carePlans, setActiveCarePlan, toggleCreateModalOpen, toggleUpdateModalOpen }) {
  /**
   * When a user clicks on a care plan, show the care plan in the view panel.
   *
   * @param {number} id - the care plan to show
   */
  const setCarePlan = (id) => {
    setActiveCarePlan(id);
  };

  /**
   * Render a view for creating a new care plan.
   */
  const newCarePlan = () => (
    <React.Fragment key="list-new">
      <button className="py-4 w-full border-b border-gray-300" onClick={() => toggleCreateModalOpen()} type="button">
        <div className="flex text-center">
          <div className="w-4/5 flex-col px-3 text-black overflow-hidden w-full items-center justify-center">
            <div>
              <span className="text-base font-bold text-teal-700 uppercase">{'\u002B'} Start initial care plan</span>
            </div>
          </div>
        </div>
      </button>
    </React.Fragment>
  );

  return (
    <div className="w-1/5" style={{ width: '22%' }}>
      <div className="sticky top-5" style={{ maxHeight: '625px', overflow: 'auto' }}>
        <React.Fragment key="list-update">
          <div className=" w-full border-b border-gray-300">
            <button
              className="btn btn--rounded btn--primary flex-1 mb-2 w-full"
              onClick={() => toggleUpdateModalOpen()}
              type="button"
            >
              Start update
            </button>
          </div>
        </React.Fragment>
        {carePlans?.map((carePlan) => (
          <React.Fragment key={`list-${carePlan.id}`}>
            <button
              className="py-4 w-full border-b border-gray-300"
              onClick={() => setCarePlan(carePlan.id)}
              type="button"
            >
              <div className="flex text-left">
                <div className="w-4/5 flex-col px-3 text-black overflow-hidden w-full items-center justify-center">
                  <div>
                    <span className="text-base font-bold text-black-700">
                      {carePlan.is_update ? 'Care Plan Update' : 'Initial Care Plan'}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-light text-gray-700">{moment(carePlan.created_at).format('L')}</span>
                  </div>
                </div>
                <div className="w-1/5 py-1 flex items-center justify-center">
                  {carePlan.id === activeCarePlan && '\u276F'}
                </div>
              </div>
            </button>
          </React.Fragment>
        ))}

        {newCarePlan()}
      </div>
    </div>
  );
}

CarePlanList.propTypes = {
  activeCarePlan: PropTypes.number,
  carePlans: PropTypes.array,
  setActiveCarePlan: PropTypes.func.isRequired,
  toggleCreateModalOpen: PropTypes.func.isRequired,
  toggleUpdateModalOpen: PropTypes.func.isRequired,
};

CarePlanList.defaultProps = {
  activeCarePlan: null,
  carePlans: [],
};

export default CarePlanList;
