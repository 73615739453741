import React from 'react';
import PropTypes from 'prop-types';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';

function GroupInformation({
  groupType,
  modality,
  clinicalModel,
  location,
  dateTime,
  otherInformation,
  officeManagerName,
  counselorName,
  providerName,
}) {
  const [dateTimeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(dateTime);
  const time = `${dateTimeWithOffset.format('ddd, h:mm A')} ${supportedTimeZone.abbrev}`;

  return (
    <div className="p-5 border border-gray-300 rounded-sm">
      <div className="text-gray-700 text-sm uppercase">{`${groupType} group`}</div>
      <div className="flex items-center mb-2 mt-1">
        {!!dateTime && <time>{time}</time>}
        {!!location && <div className="ml-2 text-sm bg-gray-300 py-1 px-2 rounded text-gray-900">{location}</div>}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <span>
          <strong>Modality: </strong>
          {modality}
        </span>
        <span>
          <strong>Office Manager: </strong>
          {officeManagerName}
        </span>
        <span>
          <strong>Clinical Model: </strong>
          {clinicalModel}
        </span>
        <span>
          <strong>Counselor: </strong>
          {counselorName}
        </span>
        <span>
          <strong>Other Information: </strong>
          {otherInformation.length > 0 ? otherInformation.join(', ') : '-'}
        </span>
        <span>
          <strong>Provider: </strong>
          {providerName}
        </span>
      </div>
    </div>
  );
}

GroupInformation.propTypes = {
  groupType: PropTypes.oneOf(['home', 'makeup']).isRequired,
  modality: PropTypes.string,
  clinicalModel: PropTypes.string,
  location: PropTypes.string,
  dateTime: PropTypes.string,
  otherInformation: PropTypes.arrayOf(PropTypes.string),
  officeManagerName: PropTypes.string,
  counselorName: PropTypes.string,
  providerName: PropTypes.string,
};

GroupInformation.defaultProps = {
  modality: '-',
  clinicalModel: '-',
  location: undefined,
  dateTime: undefined,
  otherInformation: [],
  officeManagerName: '-',
  counselorName: '-',
  providerName: '-',
};

export default GroupInformation;
