import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { MemberNotesFormAnswerGroupPropType, MemberPropType } from './helpers/types';
import { convertToSpecificFormat } from '../../helpers/TimezoneHelper';
import AddendumDetails from './AddendumDetails';
import { formatStaffMember } from './helpers';

function MembersSignersAddendumsRow({ formAnswerGroup, members }) {
  const { lockedAt, lockedBy, lockedNoteIds, coSigners, addendums } = formAnswerGroup;

  const sectionsToDisplay = {
    signer: lockedAt,
    coSigners: !isEmpty(coSigners),
    addendums: !isEmpty(addendums),
  };

  const [isAddendumDetailsOpen, setIsAddendumDetailsOpen] = useState(false);
  const [addendumDetails, setAddendumDetails] = useState({
    member: undefined,
    createdAt: undefined,
    createdBy: undefined,
    content: undefined,
  });

  const isChartingNoteLocked = (chartingNoteId) => lockedNoteIds?.includes(chartingNoteId) || false;

  const displaySignerInfo = ({ healthieMrn }) => {
    const chartingNoteId = formAnswerGroup?.ids[healthieMrn];

    if (isChartingNoteLocked(chartingNoteId)) {
      const date = convertToSpecificFormat(lockedAt?.[healthieMrn]);

      return (
        <div className="overflow-hidden">
          <div className="mb-1">{formatStaffMember(lockedBy?.name || '-', lockedBy?.qualifications)}</div>
          <div className="text-gray-700">{date || '-'}</div>
        </div>
      );
    }

    return null;
  };

  const displayCoSignersInfo = (healthieMrn) => {
    if (!coSigners?.length) return null;

    const coSignersAssignedToMember = coSigners?.filter((coSigner) => {
      const chartingNoteId = formAnswerGroup?.ids[healthieMrn];
      return coSigner.coSignedFor?.includes(chartingNoteId);
    });

    if (coSignersAssignedToMember?.length) {
      return coSignersAssignedToMember.map((coSigner) => {
        const date = convertToSpecificFormat(coSigner?.signedAt);
        return (
          <div key={coSigner.signedAt} className="mb-4">
            <div className="mb-1">{formatStaffMember(coSigner?.signedBy || '-', coSigner?.qualifications)}</div>
            <div className="text-gray-700">{date || '-'}</div>
          </div>
        );
      });
    }

    return null;
  };

  const displayAddendums = addendums?.map((addendum) => {
    const date = convertToSpecificFormat(addendum.createdAt);
    return (
      <React.Fragment key={addendum.createdAt}>
        <tr className="divide-x divide-y divide-gray-300">
          <td className="bg-blue-100 p-4 font-semibold sticky left-0 h-full border-t border-gray-300">Author</td>
          {members?.map((member) => {
            const chartingNoteId = formAnswerGroup?.ids[member?.healthieMrn];

            return (
              <td key={member.id} className="p-4 bg-blue-100">
                <div className="flex gap-1 items-center">
                  <span className="overflow-hidden">
                    {addendum.addedFor?.includes(chartingNoteId) ? (
                      <>
                        <div className="mb-1">
                          {formatStaffMember(addendum?.createdBy || '-', addendum?.qualifications)}
                        </div>
                        <div className="text-gray-700">{date || '-'}</div>
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              </td>
            );
          })}
        </tr>
        <tr className="divide-x divide-y divide-gray-300">
          <td className="bg-blue-100 p-4 font-semibold sticky left-0 h-full border-t border-gray-300">Contents</td>
          {members?.map((member) => {
            const chartingNoteId = formAnswerGroup?.ids[member?.healthieMrn];
            const isAddendumAssignedToNote = addendum.addedFor?.includes(chartingNoteId);

            const openModalTrigger = () => {
              setAddendumDetails({
                member: `${member.firstName} ${member.lastName}`,
                createdAt: date,
                createdBy: addendum.createdBy,
                content: addendum.content,
              });
              setIsAddendumDetailsOpen(true);
            };

            const cellProps = {
              onClick: isAddendumAssignedToNote ? openModalTrigger : undefined,
              onKeyDown: isAddendumAssignedToNote ? openModalTrigger : undefined,
              role: isAddendumAssignedToNote ? 'button' : undefined,
              tabIndex: isAddendumAssignedToNote ? '0' : undefined,
            };

            return (
              <td key={member.id} className="p-4 bg-blue-100" {...cellProps}>
                <div className="flex gap-1 items-center">
                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {isAddendumAssignedToNote ? addendum.content : '-'}
                  </span>
                </div>
              </td>
            );
          })}
        </tr>
      </React.Fragment>
    );
  });

  return (
    <>
      <AddendumDetails isOpen={isAddendumDetailsOpen} setIsOpen={setIsAddendumDetailsOpen} {...addendumDetails} />

      {(sectionsToDisplay.signer || sectionsToDisplay.coSigners) && (
        <>
          <tr className="divide-y divide-gray-300">
            <td className="bg-blue-100 p-4 flex gap-1 font-bold text-lg sticky left-0 h-full">Signers</td>
            {members?.map((member) => (
              <td key={member.id} className="p-4 bg-blue-100">
                <div className="flex gap-1 items-center">
                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap" />
                </div>
              </td>
            ))}
          </tr>
          {sectionsToDisplay.signer && (
            <tr className="divide-x divide-y divide-gray-300">
              <td className="bg-blue-100 p-4 font-semibold sticky left-0 h-full border-t border-gray-300">Signer</td>
              {members?.map((member) => (
                <td key={member.id} className="p-4 bg-blue-100">
                  <div className="flex gap-1 items-center">
                    {displaySignerInfo({ healthieMrn: member?.healthieMrn })}
                  </div>
                </td>
              ))}
            </tr>
          )}

          {sectionsToDisplay.coSigners && (
            <tr className="divide-x divide-y divide-gray-300">
              <td className="bg-blue-100 p-4 font-semibold sticky left-0 h-full border-t border-gray-300 align-top">
                Co-Signer
              </td>
              {members?.map((member) => (
                <td key={member.id} className="p-4 bg-blue-100 align-top">
                  <div className="flex gap-1 items-start">
                    <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {displayCoSignersInfo(member?.healthieMrn)}
                    </span>
                  </div>
                </td>
              ))}
            </tr>
          )}
        </>
      )}

      {sectionsToDisplay.addendums && (
        <>
          <tr className="divide-y divide-gray-300">
            <td className="bg-blue-100 p-4 flex gap-1 font-bold text-lg sticky left-0 h-full">Addendums</td>
            {members?.map((member) => (
              <td key={member.id} className="p-4 bg-blue-100">
                <div className="flex gap-1 items-center">
                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap" />
                </div>
              </td>
            ))}
          </tr>
          {displayAddendums}
        </>
      )}
    </>
  );
}

MembersSignersAddendumsRow.propTypes = {
  formAnswerGroup: MemberNotesFormAnswerGroupPropType.isRequired,
  members: PropTypes.arrayOf(MemberPropType),
};

MembersSignersAddendumsRow.defaultProps = {
  members: [],
};

export default MembersSignersAddendumsRow;
