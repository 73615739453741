import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function VersionStatus(props) {
  const [currentStatus, setCurrentStatus] = useState(false);

  useEffect(() => {
    const formattedStatus = props?.status?.toLowerCase();

    setCurrentStatus({
      isGreen: formattedStatus === 'green',
      isYellow: formattedStatus === 'yellow',
      isRed: formattedStatus === 'red' || !formattedStatus,
    });
  }, [props?.status]);

  const icon = () => {
    if (currentStatus.isGreen) {
      return <CheckCircleOutlineIcon className="react-status-icon green-icon" />;
    }
    if (currentStatus.isYellow) {
      return <WarningAmberIcon className="react-status-icon yellow-icon" />;
    }
    if (currentStatus.isRed) {
      return <ErrorOutlineIcon className="react-status-icon red-icon" />;
    }

    return null;
  };

  const statusClass = () => {
    if (currentStatus.isGreen) {
      return 'text-positive-700';
    }
    if (currentStatus.isYellow) {
      return 'text-yellow-700';
    }
    if (currentStatus.isRed) {
      return 'text-error-700';
    }

    return null;
  };

  const statusText = () => {
    if (currentStatus.isGreen) {
      return 'Latest Version';
    }
    if (currentStatus.isYellow) {
      return 'Update Recommended';
    }
    if (currentStatus.isRed) {
      return 'Update Required';
    }

    return null;
  };

  return (
    <div className="flex items-center">
      {icon()}

      {props.showInline ? (
        <div className="ml-1 flex items-center">
          <div className={`whitespace-nowrap font-bold ${statusClass()}`}>{statusText()}</div>
          <div className="ml-2 text-sm text-gray-700 whitespace-nowrap">({props.version})</div>
          {!!props.ota && <div className="ml-2 text-sm text-gray-700 whitespace-nowrap">({props.ota})</div>}
        </div>
      ) : (
        <div className="ml-1">
          <div className={`whitespace-nowrap font-bold ${statusClass()}`}>{statusText()}</div>
          <div className="text-sm text-gray-700 whitespace-nowrap">{props.version}</div>
          {!!props.ota && <div className="text-sm text-gray-700 whitespace-nowrap">{props.ota}</div>}
        </div>
      )}
    </div>
  );
}

VersionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  ota: PropTypes.string,
  showInline: PropTypes.bool.isRequired,
};

VersionStatus.defaultProps = {
  ota: '',
};

export default VersionStatus;
