import { useEffect, useState } from 'react';

const useDefaultShipments = () => {
  // EXAMPLE: defaults
  // {
  //   location: { label: "location name", value: 1 },
  //   staff: { label: "Staff name", value: 1 },
  //   staff_phone: ,
  //   staff_email: ,
  //   use_default: true,
  // }
  const [defaultShipment, setDefaults] = useState({});

  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const fetchDefault = async () => {
    setDefaults(parseData('default-uds-default-shipment-data') || {});
  };

  useEffect(() => {
    fetchDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    defaultShipment,
    fetchDefault,
  };
};

export default useDefaultShipments;
