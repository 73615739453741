const useClearCachedPickupData = () => {
  const clearCachedPickupData = async (members) => {
    window.localStorage.removeItem('pickup');

    members?.forEach((member) => {
      window.localStorage.removeItem(`${member?.label}-${member?.value}-uds`);
      window.localStorage.removeItem(`${member?.label}-${member?.value}-fentanyl`);
      window.localStorage.removeItem(`${member?.label}-${member?.value}-pregnancy`);
      window.localStorage.removeItem(`${member?.label}-${member?.value}-oral`);
      window.localStorage.removeItem(`${member?.label}-${member?.value}-envelopes`);
      window.localStorage.removeItem(`${member?.label}-${member?.value}-notes`);
    });
  };

  return {
    clearCachedPickupData,
  };
};

export default useClearCachedPickupData;
