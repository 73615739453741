import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useIntersectionObserver from './hooks/use-intersection-observer';

const CELL_CLASSES =
  'w-min sticky-table-element sticky-table-element-with-observer shadow-inner ring-1 ring-black ring-opacity-5';

function StickyColumnCell({
  containerElement,
  containerClasses,
  enableColumn,
  isHeader,
  children,
  extraSpacing,
  shadowDirection,
}) {
  const { ref } = useIntersectionObserver(
    (e) => {
      const isFullyVisible = e.intersectionRatio === 1;

      if (isFullyVisible) {
        e.target.parentElement.classList.remove('sticky-table-element-pinned');
      } else {
        e.target.parentElement.classList.add('sticky-table-element-pinned');
      }
    },
    { threshold: 1, root: containerElement }
  );

  if (!enableColumn) return null;

  const classesWithShadow = classNames(CELL_CLASSES, `sticky-table-element--shadow-${shadowDirection}`);

  const containerClassNames =
    containerClasses || classNames('flex justify-around gap-5 items-center', { 'p-5': extraSpacing });

  if (isHeader)
    return (
      <th className={classesWithShadow}>
        <div ref={ref}>
          <div className="m-4 font-semibold">{children}</div>
        </div>
      </th>
    );

  return (
    <td className={classesWithShadow}>
      <div className={containerClassNames} ref={ref}>
        {children}
      </div>
    </td>
  );
}

StickyColumnCell.propTypes = {
  containerElement: PropTypes.instanceOf(HTMLElement).isRequired,
  containerClasses: PropTypes.string,
  enableColumn: PropTypes.bool,
  isHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  extraSpacing: PropTypes.bool,
  shadowDirection: PropTypes.oneOf(['left', 'right']),
};

StickyColumnCell.defaultProps = {
  containerClasses: '',
  enableColumn: true,
  isHeader: false,
  extraSpacing: true,
  shadowDirection: 'left',
};

export default StickyColumnCell;
