import { useEffect, useState } from 'react';
import axios from 'axios';

const useUdsSupplies = () => {
  const [udsSupplies, setUdsSupplies] = useState([]);

  const fetchUdsSupplies = async (pageNumber = 1, filters = {}) => {
    const params = { page: pageNumber, filters };

    return axios
      .get('/staff/uds_supply/fetch_uds_supplies', { params })
      .then((response) => {
        setUdsSupplies(response?.data);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchUdsSupplies();
  }, []);

  return {
    udsSupplies,
    fetchUdsSupplies,
  };
};

export default useUdsSupplies;
