import React from 'react';
import PropTypes from 'prop-types';
import GeneralCell from '../../../GeneralCell';
import HealthCheckinCell from '../shared/HealthCheckinCell';
import StatusIndicator from '../shared/StatusIndicator';
import { GroupMemberCamelCasePropTypes } from '../shared/types';
import { mdyyyy } from '../../../../helpers/DateFormatter';
import MemberCell from '../../../MemberCell';
import UdsStatusCell from '../../../UdsStatusCell';
import PhotosPreviewCell from '../../../PhotosPreviewCell';
import { parsePhoneString } from '../../../../helpers/AssortedHelpers';

function MemberSnapshotRow({ testID, row }) {
  const { carePlanSignatures, healthCheckIn, uds, udsRecord, fentanylTest, pregnancyTest, dateOfBirth, phoneNumber } =
    row || {};

  const udsRecordPhotos = udsRecord?.photoSources?.map((src) => ({ src, alt: 'UDS Record' }));
  const fentanylTestPhotos = fentanylTest?.photoSources?.map((src) => ({ src, alt: 'Fentanyl Test' }));
  const pregnancyTestPhotos = pregnancyTest?.photoSources?.map((src) => ({ src, alt: 'Pregnancy Test' }));

  return (
    <>
      <MemberCell testID={`member-${testID}`} member={row} classes="py-4" />
      <GeneralCell
        testID={`member-signature-${testID}`}
        classes="mt-4 mb-4"
        data={
          <div className="grid grid-cols-2 gap-1">
            <GeneralCell
              testID={`member-signature-${testID}`}
              classes="mt-4 mb-4 flex justify-center"
              data={<StatusIndicator status={carePlanSignatures.member} />}
            />
            <GeneralCell
              testID={`staff-signature-${testID}`}
              classes="mt-4 mb-4 flex justify-center"
              data={<StatusIndicator status={carePlanSignatures.staff} />}
            />
          </div>
        }
      />
      <HealthCheckinCell testID={`health-check-in-${testID}`} healthCheckIn={healthCheckIn} />
      <UdsStatusCell testID={`uds-status-${testID}`} uds={uds} />
      <PhotosPreviewCell testID={`uds-${testID}`} photos={udsRecordPhotos} label="UDS Preview" />
      <PhotosPreviewCell testID={`fentanyl-${testID}`} photos={fentanylTestPhotos} label="Fentanyl Preview" />
      <PhotosPreviewCell testID={`pregnancy-${testID}`} photos={pregnancyTestPhotos} label="Pregnancy Preview" />
      <GeneralCell testID={`dob-${testID}`} data={mdyyyy(dateOfBirth)} />
      <GeneralCell testID={`phone-${testID}`} data={parsePhoneString(phoneNumber)} />
    </>
  );
}

MemberSnapshotRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: GroupMemberCamelCasePropTypes.isRequired,
};

export default MemberSnapshotRow;
