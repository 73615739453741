import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { labelStyle } from './styles';

function ShipmentInput(props) {
  const [inputVal, setValue] = useState(props.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.stateChanger(event.target.value);
  };

  return (
    <div className={props.classes} style={props.style}>
      <div className="filter-label" style={labelStyle}>
        {props.label}
      </div>

      <input
        className={`react-text-area w-full p-2 border-gray-500 border-solid border ${props.error && 'danger'}`}
        style={{ borderRadius: '4px', borderColor: props.error && 'red', height: '37px' }}
        value={inputVal}
        type={props.isFloat ? 'number' : 'text'}
        step={props.isFloat ? '.01' : ''}
        onChange={handleChange}
      />

      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

ShipmentInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  stateChanger: PropTypes.func.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
  style: PropTypes.object,
  isFloat: PropTypes.bool,
  error: PropTypes.string,
};

ShipmentInput.defaultProps = {
  classes: '',
  style: {},
  isFloat: false,
  error: null,
};

export default ShipmentInput;
