/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

const useData = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    if (props.preloadedData || !props.id) {
      setIsLoaded(true);
      return;
    }

    try {
      const params = { id: props.id };
      const { data: response } = await axios.get(props.endpoint, { params });

      setData(response);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    isLoaded,
    data,
  };
};

useData.propTypes = {
  id: PropTypes.string.isRequired,
  preloadedData: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
};

useData.defaultProps = {
  preloadedData: null,
};

export default useData;
