import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { labelStyle } from '../uds_supplies_dashboard/shared/styles';

function PhoneInput(props) {
  const [inputVal, setValue] = useState('');

  const formatPhone = (value, length) => {
    // REMOVE +1
    let strippedVal = value;
    if (length > 11 && value.slice(0, 2) === '+1') {
      strippedVal = value.slice(2);
    }

    let formattedNumber;

    // Filter non numbers
    const filtered_input = strippedVal.replace(/[^0-9.]+/g, '');

    // Set area code with parenthesis around it
    const areaCode = () => `(${filtered_input.slice(0, 3)})`;

    // Set formatting for first six digits
    const firstSix = () => `${areaCode()} ${filtered_input.slice(3, 6)}`;

    // Dynamic trail as user types
    const trailer = (start) => `${filtered_input.slice(start, filtered_input.length)}`;

    if (length <= 3) {
      // First 3 digits
      formattedNumber = filtered_input;
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(')', '')}`;
    } else if (length > 5 && length <= 9) {
      // Before dash
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`;
    }

    return formattedNumber;
  };

  const formatPhoneNumber = (e) => {
    const { value } = e.target;
    const { length } = value;

    const formattedNumber = formatPhone(value, length);

    setValue(formattedNumber);
    props.stateChanger(formattedNumber);
  };

  useEffect(() => {
    if (props.value) {
      const formattedNumber = formatPhone(props.value, props.value?.length);
      setValue(formattedNumber);
    } else {
      setValue(props.value || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div className={props.classes} style={props.style}>
      <div className="filter-label" style={labelStyle}>
        {props.label}
      </div>

      <input
        className={`react-text-area w-full p-2 border-gray-500 border-solid border ${props.error && 'danger'}`}
        style={{ borderRadius: '4px', borderColor: props.error && 'red' }}
        value={inputVal}
        maxLength={14}
        onChange={(val) => {
          formatPhoneNumber(val);
        }}
      />

      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  stateChanger: PropTypes.func.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.string,
};

PhoneInput.defaultProps = {
  classes: '',
  style: {},
  error: null,
};

export default PhoneInput;
