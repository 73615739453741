import PropTypes from 'prop-types';

export const MemberPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  dob: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const FacilityPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const LanguagePropTypes = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});
