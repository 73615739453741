import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { TimelineURLsController } from './useTimelineURLs';
import EncountersTab from './EncountersTab';
import AddDocumentation from './AddDocumentation';
import { getReferrerParams } from '../../../helpers/ReferrerParams';
import { DEFAULT_PAGE, PAGES } from './constants';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import SessionRelatedGroupDocumentation from './SessionRelatedGroupDocumentation';
import { transformTimelineItems } from './helpers';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

function MemberEncounterHistory({
  appointmentFinderUrl,
  canUserManageChartingNotes,
  encounterHistoryUrl,
  prescriptionDetailsUrl,
  carePlansUrl,
  authenticityToken,
  member,
  memberTimeline,
  states,
  smartPhrases,
}) {
  const { json_params } = getReferrerParams();
  const params = json_params ? convertObjKeysToCamelCase(JSON.parse(json_params)) : {};

  const getInitialCurrentPage = () => {
    switch (true) {
      case !isEmpty(params?.sessionIdentifier):
        return PAGES.ADD_GROUP_DOCUMENTATION;
      case !isEmpty(params?.selectedDocumentationIds):
        return PAGES.ADD_DOCUMENTATION;
      default:
        return DEFAULT_PAGE;
    }
  };

  const [currentPage, setCurrentPage] = useState(getInitialCurrentPage());

  const memberTimelineData = transformTimelineItems(memberTimeline, { memberId: member.id }).map((timelineItem) => ({
    ...timelineItem,
    staff: isString(timelineItem.staff) ? [timelineItem.staff] : timelineItem.staff,
  }));

  return (
    <TimelineURLsController
      appointmentFinderUrl={appointmentFinderUrl}
      encounterHistoryUrl={encounterHistoryUrl}
      prescriptionDetailsUrl={prescriptionDetailsUrl}
      carePlansUrl={carePlansUrl}
    >
      {currentPage === PAGES.TIMELINE && (
        <EncountersTab
          memberTimeline={memberTimelineData}
          canUserManageChartingNotes={canUserManageChartingNotes}
          onAddDocumentationClick={() => setCurrentPage(PAGES.ADD_DOCUMENTATION)}
        />
      )}
      {currentPage === PAGES.ADD_DOCUMENTATION && (
        <AddDocumentation
          authenticityToken={authenticityToken}
          member={member}
          selectedDocumentationIds={params?.selectedDocumentationIds || []}
          type={params?.type}
          states={states}
          smartPhrases={smartPhrases}
          canUserManageChartingNotes={canUserManageChartingNotes}
        />
      )}
      {currentPage === PAGES.ADD_GROUP_DOCUMENTATION && (
        <SessionRelatedGroupDocumentation
          authenticityToken={authenticityToken}
          states={states}
          sessionIdentifier={params?.sessionIdentifier}
          smartPhrases={smartPhrases}
          canUserManageChartingNotes={canUserManageChartingNotes}
        />
      )}
    </TimelineURLsController>
  );
}

MemberEncounterHistory.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  encounterHistoryUrl: PropTypes.string.isRequired,
  prescriptionDetailsUrl: PropTypes.string.isRequired,
  carePlansUrl: PropTypes.string.isRequired,
  member: AddDocumentation.propTypes.member.isRequired,
  memberTimeline: PropTypes.arrayOf(
    PropTypes.shape({
      chartingNotes: PropTypes.arrayOf(
        PropTypes.shape({
          external_id: PropTypes.string,
          is_locked: PropTypes.bool,
          name: PropTypes.string,
          type: PropTypes.string,
        })
      ),
      eventAt: PropTypes.string,
      id: PropTypes.any,
      locations: PropTypes.any,
      reason: PropTypes.any,
      staff: PropTypes.arrayOf(PropTypes.string),
      subtype: PropTypes.any,
      type: PropTypes.string,
      external_id: PropTypes.string,
      status: PropTypes.string,
      is_individual_appointment: PropTypes.bool,
      appointment_occurrence_member_id: PropTypes.number,
    })
  ).isRequired,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  smartPhrases: SmartPhrasesPropType,
};

MemberEncounterHistory.defaultProps = {
  smartPhrases: null,
};

export default MemberEncounterHistory;
