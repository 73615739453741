import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowForward as DetailsIcon } from 'react-icons/io';
import StickyColumnCell from './StickyColumnCell';

function ActionColumnLink({ containerElement, enableActionsColumn, href, testID }) {
  return (
    <StickyColumnCell containerElement={containerElement} enableColumn={enableActionsColumn}>
      <a
        href={href}
        target="_top"
        title="Details"
        className="w-9 h-9 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 cursor-pointer ml-auto"
        rel="noreferrer"
        data-testid={testID}
      >
        <DetailsIcon className="w-6 h-6 text-white" />
      </a>
    </StickyColumnCell>
  );
}

ActionColumnLink.propTypes = {
  containerElement: PropTypes.instanceOf(HTMLElement).isRequired,
  href: PropTypes.string.isRequired,
  enableActionsColumn: PropTypes.bool,
  testID: PropTypes.string,
};

ActionColumnLink.defaultProps = {
  enableActionsColumn: true,
  testID: '',
};

export default ActionColumnLink;
