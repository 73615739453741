import React from 'react';
import PropTypes from 'prop-types';
import { PiArrowSquareOutBold } from 'react-icons/pi';
import classNames from 'classnames';
import { TAG_STATUS } from '../shared/StatusTag';
import { triggerAlgorithm } from './helpers/memberDetailsAlgorithms';
import Tooltip from '../shared/Tooltip';

export const MemberDetailsComponentType = {
  MEDICAL: 'MEDICAL',
  CLINICAL: 'CLINICAL',
  DEMOGRAPHICS: 'DEMOGRAPHICS',
};

const FONT_SIZE = {
  STANDARD: 'STANDARD',
  SMALL: 'SMALL',
};

const FONT_TYPE = {
  STANDARD: 'STANDARD',
  ITALICS: 'ITALICS',
};

const FONT_WEIGHT = {
  STANDARD: 'STANDARD',
  BOLD: 'BOLD',
};

const FONT_COLOR = TAG_STATUS;

function generateValueOptionsStyles(valueOptions) {
  const { size, fontType, fontWeight, color } = valueOptions || {};
  const classes = [];

  switch (size) {
    case FONT_SIZE.SMALL:
      classes.push('text-xs');
      break;
    case FONT_SIZE.STANDARD:
    default:
      break;
  }

  switch (fontType) {
    case FONT_TYPE.ITALICS:
      classes.push('italic');
      break;
    case FONT_TYPE.STANDARD:
    default:
      break;
  }

  switch (fontWeight) {
    case FONT_WEIGHT.BOLD:
      classes.push('font-bold');
      break;
    case FONT_WEIGHT.STANDARD:
    default:
      break;
  }

  switch (color) {
    case FONT_COLOR.SUCCESS:
      classes.push('text-positive-700');
      break;
    case FONT_COLOR.WARNING:
      classes.push('text-warning-500');
      break;
    case FONT_COLOR.ERROR:
      classes.push('text-error-700');
      break;
    case FONT_COLOR.STANDARD:
    default:
      break;
  }

  return classes.join(' ');
}

function transformValue(value) {
  if (!value && value !== 0) {
    return '';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }

  const { template, variables } = value || {};

  if (!template || !variables) {
    return '';
  }

  return template?.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
    const { algorithm, parameters } = variables[key];
    return triggerAlgorithm(algorithm, parameters);
  });
}

const defaultValueOptions = {
  size: FONT_SIZE.STANDARD,
  fontType: FONT_TYPE.STANDARD,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};

const defaultSubtextOptions = {
  size: FONT_SIZE.SMALL,
  fontType: FONT_TYPE.STANDARD,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};

const defaultSuffixOptions = {
  size: FONT_SIZE.STANDARD,
  fontType: FONT_TYPE.ITALICS,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};

function MemberDetailsRow({ label, value, valueOptions, subtext, suffixText, tooltip, url }) {
  return (
    <div className="flex flex-col md:flex-row gap-2 pb-2 border-b border-dotted border-gray-400 last:border-b-0 last:pb-0">
      <div className="w-1/5 min-w-100 flex-shrink-0">
        <span className="text-xs text-gray-700 font-medium uppercase">{label}</span>
      </div>

      <div className="flex-grow flex gap-1">
        {url && (
          <a target="_blank" href={url} rel="noreferrer">
            <PiArrowSquareOutBold className="w-5 h-5 mr-1" />
          </a>
        )}

        <div className="flex flex-col gap-1">
          <div className="flex gap-1 items-center">
            <span
              className={classNames(
                'whitespace-pre-wrap',
                generateValueOptionsStyles(valueOptions || defaultValueOptions)
              )}
            >
              {transformValue(value)}
            </span>
            {suffixText && (
              <span className={generateValueOptionsStyles(suffixText?.valueOptions || defaultSuffixOptions)}>
                {transformValue(suffixText?.value)}
              </span>
            )}
            {tooltip && <Tooltip text={tooltip} style={{ alignSelf: 'start', paddingTop: '2px' }} isSecondaryVariant />}
          </div>

          {subtext &&
            (Array.isArray(subtext) ? (
              subtext.map((text, i) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames(
                    'whitespace-pre-wrap',
                    generateValueOptionsStyles(text?.valueOptions || defaultSubtextOptions)
                  )}
                >
                  {transformValue(text?.value)}
                </span>
              ))
            ) : (
              <span
                className={classNames(
                  'whitespace-pre-wrap',
                  generateValueOptionsStyles(subtext?.valueOptions || defaultSubtextOptions)
                )}
              >
                {transformValue(subtext?.value)}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}

const valueOptionsPropTypes = PropTypes.shape({
  size: PropTypes.oneOf(Object.values(FONT_SIZE)),
  fontType: PropTypes.oneOf(Object.values(FONT_TYPE)),
  fontWeight: PropTypes.oneOf(Object.values(FONT_WEIGHT)),
  color: PropTypes.oneOf(Object.values(FONT_COLOR)),
});

const valuePropTypes = PropTypes.oneOf([
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    template: PropTypes.string.isRequired,
    variables: PropTypes.objectOf(
      PropTypes.shape({
        algorithm: PropTypes.string.isRequired,
        parameters: PropTypes.objectOf(PropTypes.any),
      })
    ),
  }),
]);

const textPropTypes = PropTypes.shape({
  value: valuePropTypes.isRequired,
  valueOptions: valueOptionsPropTypes,
});

export const MemberDetailsRowPropTypes = {
  label: PropTypes.string.isRequired,
  value: valuePropTypes.isRequired,
  valueOptions: valueOptionsPropTypes,
  subtext: PropTypes.oneOfType([textPropTypes, PropTypes.arrayOf(textPropTypes)]),
  suffixText: PropTypes.shape({
    value: valuePropTypes.isRequired,
    valueOptions: valueOptionsPropTypes,
  }),
  tooltip: PropTypes.string,
  url: PropTypes.string,
};

MemberDetailsRow.propTypes = MemberDetailsRowPropTypes;

MemberDetailsRow.defaultProps = {
  valueOptions: defaultValueOptions,
  subtext: {
    value: null,
    valueOptions: defaultSubtextOptions,
  },
  suffixText: {
    value: null,
    valueOptions: defaultSuffixOptions,
  },
  tooltip: null,
  url: null,
};

export default MemberDetailsRow;
