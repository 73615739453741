import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

/**
 *  Grouped radio buttons that work together as one select field
 * @param {{label: string, options: array, onChange: function, value: string}} props
 * @returns {React.ReactElement} Radio Select.
 */
function RadioSelect({ label, options, onChange, value }) {
  return (
    <div className="radio-select">
      <p className="font-bold mx-6 pt-5">{label}</p>
      <div className="flex mx-6 gap-10 pb-5">
        {options.map((option) => (
          <RadioButton key={option} label={option} onChange={onChange} checked={value === option} />
        ))}
      </div>
    </div>
  );
}

RadioSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RadioSelect.defaultProps = {
  value: null,
};

export default RadioSelect;
