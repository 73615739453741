import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import AttendanceDate from './AttendanceDate';
import AttendanceStatus from './AttendanceStatus';
import AttendanceDrugScreenings from './AttendanceDrugScreenings';

function AttendanceData(props) {
  const borderClass = props.showBottomBorder ? 'border-b' : null;

  const { dateTime, appointmentStatus, screenings } = props?.data || {};

  const { date, time } = dateTime || {};
  const { status, provider } = appointmentStatus || {};
  const { uds, fent } = screenings || {};

  return (
    <div className={`flex p-2 ${borderClass}`}>
      {/* WEEK AND TIME */}
      <AttendanceDate date={date} time={time} />

      {/* STATUS */}
      <AttendanceStatus status={status} provider={provider} />

      {/* DRUG SCREENINGS */}
      <AttendanceDrugScreenings uds={uds} fent={fent} />
    </div>
  );
}

AttendanceData.propTypes = {
  data: PropTypes.object.isRequired,
  showBottomBorder: PropTypes.bool,
};

AttendanceData.defaultProps = {
  showBottomBorder: false,
};

export default AttendanceData;
