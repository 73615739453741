const setMemberData = (props) => {
  const { row } = props;
  const {
    id,
    empanelment_group_id,
    members_empanelment_group_id,
    member_name,
    mrn,
    is_flagged,
    is_approved,
    is_empaneled,
    group_appointment_column,
    empanelment_column,
  } = row;

  const {
    counselor_name,
    provider_name,
    location_name,
    group_date_time,
    appointment_id,
    appointment_type,
    is_makeup_group,
  } = group_appointment_column;

  const {
    empanelment_date_time,
    empanelment_location_name,
    empanelment_office_manager_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_secondary_group_types,
  } = empanelment_column;

  const confirmData = {
    group_data: { location_name },
    empanelment_office_manager_name,
    counselor_name,
    provider_name,
    empanelment_group_modality,
    empanelment_group_type,
  };

  return {
    row,
    id,
    empanelment_group_id,
    members_empanelment_group_id,
    member_name,
    mrn,
    is_flagged,
    is_approved,
    is_empaneled,
    group_appointment_column,
    appointment_id,
    empanelment_column,
    counselor_name,
    provider_name,
    group_date_time,
    location_name,
    empanelment_date_time,
    empanelment_location_name,
    empanelment_office_manager_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_secondary_group_types,
    confirmData,
    appointment_type,
    is_makeup_group,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { setMemberData };
