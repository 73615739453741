import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toast from './Toast';

function Toasts({ toasts, removeToast, className, isSecondaryVariant }) {
  const successToasts = toasts.filter((toast) => toast.type === 'success');
  const otherToasts = toasts.filter((toast) => toast.type !== 'success');
  const toastsClassName = classNames('flex flex-wrap flex-column gap-3', className);

  const showToasts = (typeToasts) => (
    <div className={toastsClassName}>
      {typeToasts.map((toast, i) => (
        <Toast
          key={toast.id}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...toast}
          onClose={removeToast}
          isFirst={i === 0}
          isSecondaryVariant={isSecondaryVariant}
        />
      ))}
    </div>
  );

  if (isSecondaryVariant) {
    return toasts.length ? <div className="fixed top-4 left-0 w-full z-50 px-12">{showToasts(toasts)}</div> : null;
  }

  return (
    <>
      {successToasts.length ? <div className="fixed top-0 left-0 w-full z-50">{showToasts(successToasts)}</div> : null}
      {otherToasts.length ? showToasts(otherToasts) : null}
    </>
  );
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['success', 'error', 'warning']),
      onClose: PropTypes.func.isRequired,
    })
  ).isRequired,
  removeToast: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSecondaryVariant: PropTypes.bool,
};

Toasts.defaultProps = {
  className: '',
  isSecondaryVariant: false,
};

export default Toasts;
