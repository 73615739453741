/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';
import IconRender from '../shared_dashboard/IconRender';
import { isEmptyDataObject } from '../../helpers/AssortedHelpers';

const green_circle = ['out_for_delivery'];
const black_circle = [
  'information_received',
  'delivery_rescheduled',
  'delivery_scheduled',
  'package_accepted',
  'package_arrived',
  'package_departed',
  'package_forwarded',
  'package_processed',
  'package_processing',
  'other',
];

const warning_triangle = [
  'address_issue',
  'contact_carrier',
  'delayed',
  'delivery_attempted',
  'location_inaccessible',
  'notice_left',
  'package_damaged',
  'package_held',
  'pickup_available',
  'reschedule_delivery',
];
const warning_circle = [
  'return_to_sender',
  'package_unclaimed',
  'package_undeliverable',
  'package_disposed',
  'package_lost',
];

const formatStatus = (status) => {
  if (status === 'information_received') return 'Pre-Transit';
  const formattedStatus = status.split('_').join(' ');
  return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1);
};

function IconStatus(props) {
  const trackingObj = props.props;

  if (!trackingObj?.status) return <></>;
  if (green_circle.includes(trackingObj.status)) {
    return (
      <div className="flex text-positive-700 font-bold">
        <IconRender showCircleDashed />
        <span>{formatStatus(trackingObj.status)}</span>
      </div>
    );
  }
  if (black_circle.includes(trackingObj.status)) {
    return (
      <div className="flex font-bold">
        <IconRender showCircleDashed />
        <span>{formatStatus(trackingObj.status)}</span>
      </div>
    );
  }
  if (warning_triangle.includes(trackingObj.status)) {
    return (
      <div className="flex text-warning-500 font-bold">
        <IconRender showTriangleIcon />
        <span>{formatStatus(trackingObj.status)}</span>
      </div>
    );
  }
  if (warning_circle.includes(trackingObj.status)) {
    return (
      <div className="flex text-error-700 font-bold">
        <IconRender showExclamationIcon />
        <span>{formatStatus(trackingObj.status)}</span>
      </div>
    );
  }
  // This shows status delivered
  return (
    <div className="flex text-positive-700 font-bold">
      <IconRender showCheckIcon />
      <span>{formatStatus(trackingObj.status)}</span>
    </div>
  );
}

function LinkField(props) {
  const trackingObj = props.props;
  const date = new Date();
  const ninetyDaysAgo = date.setDate(date.getDate() - 90);
  const lastUpdatedDate = new Date(trackingObj.last_updated_at);

  if (ninetyDaysAgo > lastUpdatedDate) return null;
  if (trackingObj.status === 'information_received') {
    return (
      <a
        className="flex font-bold text-sm my-1 ml-6 hover:no-underline cursor-pointer"
        href={trackingObj.label_url}
        target="_blank"
        rel="noreferrer"
      >
        <IconRender showPrinter />
        <span className="text-sm font-bold" style={{ fontFamily: 'Brandon Grotesque' }}>
          PRINT LABEL
        </span>
      </a>
    );
  }
  return (
    <a
      className="my-1 ml-6 hover:no-underline cursor-pointer"
      href={trackingObj.tracking_url}
      target="_blank"
      rel="noreferrer"
    >
      <LaunchIcon sx={{ width: 15, height: 15, marginRight: '5px' }} />
      <span className="text-sm font-bold" style={{ fontFamily: 'Brandon Grotesque' }}>
        VIEW TRACKING
      </span>
    </a>
  );
}

function TrackingCell(props) {
  const trackingData = props.trackingObj;
  if (isEmptyDataObject(trackingData)) return <td />;
  return (
    <td>
      <div className="m-4" style={{ maxWidth: 230 }}>
        <IconStatus props={trackingData} />
        <div className="ml-6 text-gray-700 whitespace-pre-wrap">{trackingData.description}</div>
        <LinkField props={trackingData} />
      </div>
    </td>
  );
}

TrackingCell.propsTypes = {
  trackingObj: PropTypes.object,
};

TrackingCell.defaultProps = {
  trackingObj: {},
};

export default TrackingCell;
