import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// ICONS
import LaunchIcon from '@mui/icons-material/Launch';

// HELPERS
import { capitalizeFirst } from '../../helpers/AssortedHelpers';
import { mdyyyy } from '../../helpers/DateFormatter';

// COMPONENTS
import DetailsDiv from '../shared/DetailsDiv';
import ExpandButton from '../shared/ExpandButton';
import RenderHTML from '../shared/RenderHTML';
import ExpandViewTaskHistory from './ExpandViewTaskHistory';

function ExpandViewTaskDetails({ task, histories, howToResolveUIFeatureFlag }) {
  const [taskHistories, setTaskHistories] = useState(null);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    setTaskHistories(histories);
  }, [histories]);

  function renderFirstHistory(history, index) {
    if (index > 0) {
      return <></>;
    }
    return <ExpandViewTaskHistory key={history.id} history={history} />;
  }

  function renderRemainingHistories(history, index) {
    if (!showMore) {
      return <></>;
    }
    if (index === 0) {
      return <></>;
    }
    return <ExpandViewTaskHistory key={history.id} history={history} header="" />;
  }

  function trackLinkClick() {
    window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'job-aid-click', task?.helper_link);
  }

  return (
    <div>
      {/* TASK DETAILS */}
      <div className="flex">
        <DetailsDiv header="ASSIGNED" subText={mdyyyy(task?.assignment_date)} customClass="flex-1" />
        <DetailsDiv header="CREATED" subText={mdyyyy(task?.created_at)} customClass="flex-1" />
        <DetailsDiv
          header="STATUS"
          subText={capitalizeFirst(task?.last_action) || capitalizeFirst(task?.status)}
          customClass="flex-1"
        />
      </div>

      <div className="flex">
        <DetailsDiv header="TASK DETAILS" customClass="flex-1 pr-5">
          {task?.description && <RenderHTML html={task?.description} allowedAttributes={['target']} />}
        </DetailsDiv>

        {howToResolveUIFeatureFlag && (
          <DetailsDiv header="HOW TO RESOLVE" subText={task?.how_to_resolve_copy} customClass="flex-1">
            {task?.helper_link && (
              <a target="_blank" rel="noopener noreferrer" href={task?.helper_link} onClick={trackLinkClick}>
                <LaunchIcon sx={{ width: 20, height: 20, marginRight: '5px' }} />
                Job Aide
              </a>
            )}
          </DetailsDiv>
        )}

        {!howToResolveUIFeatureFlag && (
          <DetailsDiv header="TASK HELPER" customClass="flex-1">
            {task?.helper_link && (
              <a target="_blank" rel="noopener noreferrer" href={task?.helper_link} onClick={trackLinkClick}>
                <LaunchIcon sx={{ width: 20, height: 20, marginRight: '5px' }} />
                Job Aide
              </a>
            )}
          </DetailsDiv>
        )}
      </div>

      {/* TASK HISTORIES */}
      {taskHistories?.map((history, index) => (
        <div key={history.id}>{renderFirstHistory(history, index)}</div>
      ))}

      <ExpandButton
        show={taskHistories?.length > 1}
        text={`${histories?.length ? histories.length - 1 : 0} MORE UPDATES`}
        onShowMore={toggleShowMore}
      />

      {taskHistories?.map((history, index) => (
        <div key={history.id}>{renderRemainingHistories(history, index)}</div>
      ))}
    </div>
  );
}

ExpandViewTaskDetails.propTypes = {
  task: PropTypes.object.isRequired,
  histories: PropTypes.object.isRequired,
  howToResolveUIFeatureFlag: PropTypes.bool.isRequired,
};

export default ExpandViewTaskDetails;
