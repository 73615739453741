const setStaffData = (props) => {
  const { row } = props;
  const {
    members_empanelment_group_id,
    is_approved,
    is_flagged,
    member_name,
    mrn,
    group_data_column,
    status_column,
    empanelment_column,
    ecw_active,
  } = row;

  const { date_time, location, weekly_group_id } = group_data_column;
  const { is_added, is_removed, status } = status_column;

  const {
    empanelment_date_time,
    empanelment_location_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_office_manager_name,
    empanelment_secondary_group_types,
  } = empanelment_column;

  return {
    row,
    members_empanelment_group_id,
    is_approved,
    is_flagged,
    member_name,
    mrn,
    date_time,
    location,
    weekly_group_id,
    is_added,
    is_removed,
    status,
    empanelment_date_time,
    empanelment_location_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    ecw_active,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_office_manager_name,
    empanelment_secondary_group_types,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { setStaffData };
