import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { PiClockBold } from 'react-icons/pi';
import classNames from 'classnames';

function TimeInput({ name, value, onChange, placeholder, label, disabled }) {
  const inputIcon = (
    <div className="pl-1.5 pr-2.5 text-gray-700">
      <PiClockBold />
    </div>
  );

  return (
    <>
      <label className="block text-base font-semibold">
        {label}
        <TimePicker
          id={name}
          name={name}
          showSecond={false}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          inputReadOnly
          use12Hours
          inputIcon={inputIcon}
          allowEmpty={false}
          disabled={disabled}
          className={classNames('time-input', { 'mt-2': label })}
        />
      </label>
    </>
  );
}

TimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

TimeInput.defaultProps = {
  label: null,
  disabled: false,
};

export default TimeInput;
