const weeklyDashboardnames = [
  {
    name: 'Group Meeting',
    accessorKey: 'group_meeting_time',
  },
  {
    name: 'Type',
    accessorKey: 'type',
  },
  {
    name: 'Counselor',
    accessorKey: 'counselor',
  },
  {
    name: 'Provider',
    accessorKey: 'provider',
  },
  {
    name: 'Members',
    accessorKey: 'memberCount',
  },
  // {
  //   name: 'UDS',
  //   accessorKey: 'udsCount',
  // },
];

export default weeklyDashboardnames;
