import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import CustomPagination from '../shared_dashboard/CustomPagination';

function PDFFile({ file, onLoadFinish }) {
  const [totalPages, setTotalPages] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = (pdf) => {
    setError(null);
    setIsLoading(false);
    setTotalPages(pdf?.numPages ?? 0);
    setCurrentPageNumber(1);
    onLoadFinish();
  };

  const onDocumentLoadError = (loadError) => {
    setIsLoading(false);
    setError(loadError);
    onLoadFinish();
  };

  return (
    <div className="flex flex-col">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
        <Page pageNumber={currentPageNumber} />
      </Document>
      {!error && !isLoading && (
        <CustomPagination
          page={currentPageNumber}
          totalPages={totalPages}
          onPageChange={(_, page) => setCurrentPageNumber(page)}
        />
      )}
    </div>
  );
}

PDFFile.propTypes = {
  file: PropTypes.string.isRequired, // URL | Base64 string | Uint8Array
  onLoadFinish: PropTypes.func,
};

PDFFile.defaultProps = {
  onLoadFinish: () => {},
};

export default PDFFile;
