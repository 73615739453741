/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GroupsWizard from 'groups-wizard';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import SmallModal from '../shared/SmallModal';

import ManualUploadModal from './ManualUploadModal';
import ManualUploadWarningModal from './ManualUploadWarningModal';

import CosignWarningModal from './CosignWarningModal';

import useFilters from './hooks/use-filters';
import signEmbeddedSignature from './hooks/sign-embedded-signature';
import LockAndSign from '../empanelment/member_encounter_history/LockAndSign';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import CoSign from '../empanelment/member_encounter_history/CoSign';
import Button from '../shared/Button';
import ManualUploadModalDP2 from './ManualUploadModalDP2';

/**
 * Shows a care plan using the Groups Wizard.
 */
function CarePlanView({
  authenticityToken,
  carePlan,
  config,
  member,
  userId,
  userIsProvider,
  signButtonFlag,
  uploadButtonFlag,
  cosignerFlag,
}) {
  const { providerFilters } = useFilters();

  const [carePlanUploadData, setCarePlanUploadData] = useState({});
  const [cosignerSigned, setCosignerSigned] = useState(false);
  const [currentCarePlan, setCurrentCarePlan] = useState(carePlan);
  const [finishing, setFinishing] = useState(false);
  const [memberSigned, setMemberSigned] = useState(false);
  const [staffSigned, setStaffSigned] = useState(false);
  const [staffSignerName, setStaffSignerName] = useState('');
  const [staffCosignerName, setStaffCosignerName] = useState('');
  const [manualUploadModalOpen, setManualUploadModalOpen] = useState(false);
  const [manualUploadWarningModalOpen, setManualUploadWarningModalOpen] = useState(false);
  const [cosignWarningModalOpen, setCosignWarningModalOpen] = useState(false);
  const [showSignButton, setShowSignButton] = useState(false);
  const [showCosignButton, setShowCosignButton] = useState(false);
  const [pdfWarningModalOpen, setPDFWarningModalOpen] = useState(false);
  const [isSignModalOpenDP2, setIsSignModalOpenDP2] = useState(false);
  const [isCosignModalOpenDP2, setIsCosignModalOpenDP2] = useState(false);
  const [isUploadModalOpenDP2, setIsUploadModalOpenDP2] = useState(false);

  const findSignatureByRole = (role) => carePlan?.signature_request?.signatures.find((s) => s.signer_role === role);

  const findCosignerSignature = () => {
    let signature = carePlan?.signature_request?.signatures.find((s) => s.signer_role === 'Co-Signer');
    signature ||= carePlan?.cosigner_signature_request?.signatures.find((s) => s.signer_role === 'Co-Signer');

    return signature;
  };

  const whenMemberSigned = () => {
    const memberSignature = findSignatureByRole('Member');
    if (memberSignature && memberSignature.status === 'signed') {
      return moment(memberSignature.signed_at).format('llll');
    }
    return 'Not signed';
  };

  // if we start to support multiple staff signers this will need to be refactored
  const whenStaffSigned = () => {
    const staffSignature = findSignatureByRole('Counselor');
    if (staffSignature && staffSignature.status === 'signed') {
      return moment(staffSignature.signed_at).format('llll');
    }
    return 'Not signed';
  };

  const whenCosignerSigned = () => {
    const cosignerSignature = findCosignerSignature();
    if (cosignerSignature && cosignerSignature.status === 'signed') {
      return moment(cosignerSignature.signed_at).format('llll');
    }
    return 'Not signed';
  };

  const lookupStaffSignerName = () => {
    const name = findSignatureByRole('Counselor')?.signer_name || 'unassigned';
    return name;
  };

  const lookupCosignerName = () => {
    const name = findCosignerSignature()?.signer_name;
    return name;
  };

  /**
   * Determines whether the current staff user should be shown a button to
   * sign the current care plan.
   */
  const shouldSeeSignOption = () => {
    if (!signButtonFlag || member.isDP2) return false;

    // verify the current user is the assigned staff signer
    if (userId !== findSignatureByRole('Counselor')?.user_id) return false;

    // care plan signature request is already completed
    if (carePlan?.signature_request?.status === 'completed') return false;

    // staff has already signed
    // extremely unlikely for a signature request to be signed by staff and not completed,
    // but that could change so go ahead and check thoroughly now
    if (findSignatureByRole('Counselor')?.status === 'signed') return false;

    return true;
  };

  /**
   * Determines whether the current staff user should be shown a button to
   * co-sign the current care plan.
   */
  const shouldSeeCosignOption = () => {
    if (!cosignerFlag || member.isDP2) return false;

    // member hasn't signed yet
    if (findSignatureByRole('Member')?.status !== 'signed') return false;

    // staff hasn't signed yet
    if (findSignatureByRole('Counselor')?.status !== 'signed') return false;

    // care plan is already co-signed
    const cosignerSignature = findCosignerSignature();
    if (cosignerSignature?.status === 'signed') return false;

    // don't allow the original staff signer to also co-sign
    if (userId === findSignatureByRole('Counselor')?.user_id) return false;

    // TRUE if current user is the assigned co-signer
    if (userId === cosignerSignature?.user_id) return true;

    if (userIsProvider) return true;

    return false;
  };

  const getSigner = (signer) => {
    if (!signer) return null;

    const { signed_at, signed_by } = signer;

    if (!signed_at) return null;

    const [signDateTime] = convertToSupportedTimeZone(signed_at);

    return `${signed_by ? `${signed_by}, ` : ''}${signDateTime.format('ddd, MMM DD, YYYY h:mm A')}`;
  };

  const handleCarePlanSignSuccess = ({ userId: user_id, author, date }) => {
    setCurrentCarePlan((prevState) => ({
      ...prevState,
      signers_dp2: {
        ...prevState.signers_dp2,
        signer: {
          user_id,
          signed_at: date,
          signed_by: author,
        },
      },
    }));
  };

  const handleCarePlanCoSignSuccess = ({ userId: user_id, author, date }) => {
    const newCoSigner = {
      user_id,
      signed_at: date,
      signed_by: author,
    };

    setCurrentCarePlan((prevState) => ({
      ...prevState,
      signers_dp2: {
        ...prevState.signers_dp2,
        co_signers: prevState?.signers_dp2?.co_signers
          ? [...prevState.signers_dp2.co_signers, newCoSigner]
          : [newCoSigner],
      },
    }));
  };

  const handleUploadSuccess = (memberSignedAt) => {
    setCurrentCarePlan((prevState) => ({
      ...prevState,
      member_signed_at: memberSignedAt,
    }));
    setIsUploadModalOpenDP2(false);
  };

  useEffect(() => {
    setCurrentCarePlan(carePlan);
    setMemberSigned(whenMemberSigned);
    setStaffSigned(whenStaffSigned);
    setStaffSignerName(lookupStaffSignerName);
    setStaffCosignerName(lookupCosignerName);
    setCosignerSigned(whenCosignerSigned());
    setShowSignButton(shouldSeeSignOption());
    setShowCosignButton(shouldSeeCosignOption());
  }, [carePlan]);

  /**
   * Saves the given care plan data to the database.
   *
   * @param {Object} data - the care plan data to save.
   * @param {number} id - the member id.
   */
  const updateData = (data, id) => {
    const wizData = Buffer.from(JSON.stringify(data)).toString('base64');
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    axios.put(`/staff/members/${id}/care_plans/${currentCarePlan.id}/update`, { data: wizData });
  };

  /**
   * Finishes the given care plan.
   *
   * @param {Object} data - the care plan data to save.
   * @param {number} id - the member id.
   */
  const finish = (data, id) => {
    setFinishing(true);
    const wizData = Buffer.from(JSON.stringify(data)).toString('base64');
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    axios.post(`/staff/members/${id}/care_plans/${currentCarePlan.id}/finish`, { data: wizData }).then((response) => {
      setCurrentCarePlan(response.data);
      setFinishing(false);
      window.location.reload();
    });
  };

  /**
   * Download a pdf copy of this care plan.
   */
  const downloadPdf = () => {
    axios.get(`/staff/members/${member.id}/care_plans/${currentCarePlan.id}/pdf`).then((response) => {
      const data = Buffer.from(response.data, 'base64');
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `careplan-${member.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  /**
   * Validate file upload form data and save it in state.
   *
   * @param {Object} data - the data from the manual PDF upload form to save.
   */
  const handleManualUploadSave = (data) => {
    if (!data?.fileToUpload) {
      alert('A PDF file to upload is a required field.');
      return;
    }

    if (data?.provider === '' || !data?.provider) {
      alert('Staff Signer is a required field.');
      return;
    }

    if (data?.cosignerSignaturePresent) {
      if (data?.cosigner === '' || !data?.cosigner) {
        alert('Co-signer is a required field.');
        return;
      }

      if (data?.cosigner?.value === data?.provider?.value) {
        alert('Staff Signer and Co-signer must be different.');
        return;
      }
    }

    setCarePlanUploadData(data);
    setManualUploadWarningModalOpen(true);
  };

  /**
   * Go back from the warning/confirmation modal to the upload form modal.
   */
  const backToUploadForm = () => {
    setManualUploadWarningModalOpen(false);
    setManualUploadModalOpen(true);
  };

  /**
   * Assemble form data from React state.
   */
  const generateFormDataFromState = () => {
    const formData = new FormData();
    formData.append('care_plan_id', currentCarePlan?.id);
    formData.append('care_plan_pdf', carePlanUploadData.fileToUpload);
    formData.append('member_id', member.id);
    formData.append('member_signed_date', carePlanUploadData.memberSignatureDate);
    formData.append('staff_id', carePlanUploadData.provider?.value);
    formData.append('staff_signed_date', carePlanUploadData.providerSignatureDate);
    formData.append('cosigner_signature_present', carePlanUploadData.cosignerSignaturePresent);

    if (carePlanUploadData.cosignerSignaturePresent) {
      formData.append('cosigner_id', carePlanUploadData.cosigner?.value);
      formData.append('cosigner_signed_date', carePlanUploadData.cosignerSignatureDate);
    }

    return formData;
  };

  /**
   * Post file upload form data to the backend.
   */
  const handleManualUploadFormSubmit = () => {
    setFinishing(true);
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    const formData = generateFormDataFromState();

    axios
      .post(`/staff/members/${member.id}/care_plans/${currentCarePlan.id}/manual_upload`, formData)
      .then(() => {
        // refresh to fetch updated care plan and signature request data from the backend
        window.location.reload();
      })
      .catch((error) => {
        window?.Sentry?.captureException(error.response.data);
      })
      .then(() => {
        setManualUploadWarningModalOpen(false);
        setManualUploadModalOpen(false);
        setCarePlanUploadData({});
        setFinishing(false);
      });
  };

  const handleSignCarePlan = () => {
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    axios
      .post(`/staff/members/${member.id}/care_plans/${currentCarePlan.id}/sign`)
      .then((response) => {
        signEmbeddedSignature(response.data);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  const handleCosignCarePlan = () => {
    axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
    axios
      .post(`/staff/members/${member.id}/care_plans/${currentCarePlan.id}/assign_cosigner`)
      .then((response) => {
        setCosignWarningModalOpen(false);
        signEmbeddedSignature(response.data);
      })
      .catch((error) => {
        setCosignWarningModalOpen(false);
        window.Sentry.captureException(error);
      });
  };

  // Care Plan PDF download and close PDF Download Warning Modal
  const closeModalDownloadPdf = () => {
    downloadPdf();
    setPDFWarningModalOpen(false);
  };

  const onUploadClick = () => {
    if (member.isDP2) {
      setIsUploadModalOpenDP2(true);
      return;
    }

    setManualUploadModalOpen(true);
  };

  const signerDP2 = currentCarePlan?.signers_dp2?.signer;
  const cosignersDP2 = currentCarePlan?.signers_dp2?.co_signers;
  const isCurrentUserSigner = signerDP2?.user_id === userId;
  const isCarePlanSignable = member.isDP2 && userIsProvider && currentCarePlan?.member_signed_at;

  const isSignButtonVisibleDP2 = signButtonFlag && isCarePlanSignable && (!signerDP2 || isCurrentUserSigner);
  const isCoSignButtonVisibleDP2 = cosignerFlag && isCarePlanSignable && signerDP2 && !isCurrentUserSigner;
  const isCoSignButtonDisabledDP2 = cosignersDP2?.find((coSigner) => coSigner.user_id === userId);

  // PDF Signature Warning Modal
  const pdfWarningModal = () => (
    <SmallModal>
      <div>
        <h2 className="font-bold mb-4">This Care Plan copy does not contain signatures</h2>
        <p>Are you sure you want to download?</p>
        <button
          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
          onClick={() => setPDFWarningModalOpen(false)}
          type="button"
        >
          Back
        </button>

        <button
          className="btn btn--rounded btn--primary flex-1 ml-2"
          type="button"
          onClick={() => closeModalDownloadPdf()}
        >
          Continue
        </button>
      </div>
    </SmallModal>
  );

  /**
   * Check if the current care plan is ready for a manually signed PDF to be uploaded.
   * A care plan can be manually signed if it's finished and if its signature request
   * is not already signed.
   */
  const readyForManualUpload = () => {
    if (member.isDP2) return carePlan.finished && !currentCarePlan.member_signed_at;

    const notCosigned = findCosignerSignature()?.status !== 'signed';
    return carePlan?.finished && notCosigned;
  };

  /**
   * Render a view with some details about the care plan.
   */
  const details = () => (
    <div className="overflow-hidden mt-4 mb-12 bg-white shadow sm:rounded-lg">
      {pdfWarningModalOpen && pdfWarningModal()}

      <ManualUploadModal
        isOpen={manualUploadModalOpen}
        member={member}
        onCancel={() => setManualUploadModalOpen(false)}
        onContinue={handleManualUploadSave}
        providerFilters={providerFilters}
      />

      <ManualUploadWarningModal
        confirmData={carePlanUploadData}
        isOpen={manualUploadWarningModalOpen}
        member={member}
        onCancel={backToUploadForm}
        onContinue={handleManualUploadFormSubmit}
      />

      <CosignWarningModal
        isOpen={cosignWarningModalOpen}
        member={member}
        onCancel={() => setCosignWarningModalOpen(false)}
        onContinue={handleCosignCarePlan}
      />

      <div className="flex items-center px-4 py-5 sm:px-6">
        <h2 className="flex-1 font-body font-semibold leading-6 text-gray-900 text-xl">Details</h2>

        {showSignButton && (
          <button className="btn btn--rounded btn--primary mx-4" onClick={handleSignCarePlan} type="button">
            Sign Care Plan
          </button>
        )}

        {showCosignButton && (
          <button
            className="btn btn--rounded btn--primary mx-4"
            onClick={() => setCosignWarningModalOpen(true)}
            type="button"
          >
            Co-Sign Care Plan
          </button>
        )}

        {uploadButtonFlag && (
          <button
            className="btn btn--secondary"
            onClick={onUploadClick}
            type="button"
            disabled={!readyForManualUpload()}
          >
            Upload Signed Care Plan
          </button>
        )}
        {isSignButtonVisibleDP2 && (
          <Button isSecondary classes="ml-4" onClick={() => setIsSignModalOpenDP2(true)} disabled={isCurrentUserSigner}>
            Sign Care Plan
          </Button>
        )}
        {isCoSignButtonVisibleDP2 && (
          <Button
            isSecondary
            classes="ml-4"
            onClick={() => setIsCosignModalOpenDP2(true)}
            disabled={isCoSignButtonDisabledDP2}
          >
            Co-Sign Care Plan
          </Button>
        )}
      </div>
      <div>
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
            <dt className="text-base font-medium text-gray-700">Created</dt>
            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              {getSigner({ signed_at: currentCarePlan.created_at })}
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
            <dt className="text-base font-medium text-gray-700">Finished</dt>
            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              {currentCarePlan.finished ? getSigner({ signed_at: currentCarePlan.finished_timestamp }) : 'Not Finished'}
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
            <dt className="text-base font-medium text-gray-700">Member Signature</dt>
            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              {member.isDP2 ? getSigner({ signed_at: currentCarePlan.member_signed_at }) || 'Not signed' : memberSigned}
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
            <dt className="text-base font-medium text-gray-700">Staff Signer</dt>
            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              {member.isDP2 ? getSigner(currentCarePlan?.signers_dp2?.signer) || 'Not signed' : staffSignerName}
            </dd>
          </div>
          {!member.isDP2 && (
            <>
              <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                <dt className="text-base font-medium text-gray-700">Staff Signature</dt>
                <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{staffSigned}</dd>
              </div>
              {cosignerFlag && (
                <>
                  <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                    <dt className="text-base font-medium text-gray-700">Co-Signer</dt>
                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                      {!staffCosignerName ? <i>Unassigned</i> : staffCosignerName}
                    </dd>
                  </div>
                  <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                    <dt className="text-base font-medium text-gray-700">Co-Signer Signature</dt>
                    <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{cosignerSigned}</dd>
                  </div>
                </>
              )}
            </>
          )}
          {member.isDP2 && (
            <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
              <dt className="text-base font-medium text-gray-700">Co-Signer(s)</dt>
              <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                {cosignersDP2?.length
                  ? cosignersDP2.map((coSigner) => (
                      <p key={coSigner.user_id} className="mb-0">
                        {getSigner(coSigner)}
                      </p>
                    ))
                  : 'Not signed'}
              </dd>
            </div>
          )}
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
            <dt className="text-base font-medium text-gray-700">PDF</dt>
            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
              {carePlan?.signature_request?.status === 'completed' ? (
                <a
                  href={`/staff/members/${member.id}/care_plans/${currentCarePlan.id}/download`}
                  className="text-teal-700 underline"
                >
                  Download copy
                </a>
              ) : (
                <button onClick={() => setPDFWarningModalOpen(true)} type="button" className="text-teal-700 underline">
                  Download copy
                </button>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );

  /**
   * Render a GroupsWizard instance for this care plan.
   */

  const carePlanMode = currentCarePlan.is_update ? 'update' : 'initial';
  const mode = currentCarePlan.finished ? 'view' : carePlanMode;

  const wizard = () => (
    <>
      {details()}
      <div className="z-0">
        <GroupsWizard
          meta={{ mode }}
          config={config.config}
          copy={config.copy}
          lang="en"
          onUpdate={debounce((data) => updateData(data, member.id), 5000)}
          onSave={(data) => updateData(data, member.id)}
          onFinish={(data) => finish(data, member.id)}
          data={currentCarePlan.data}
          animated={false}
        />
      </div>
    </>
  );

  /**
   * A utility to show "loading" activity.
   */
  const finishingActivity = () => (
    <div className="flex items-center justify-center">
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span>Finishing...</span>
    </div>
  );

  return (
    <>
      <LockAndSign
        authenticityToken={authenticityToken}
        members={[{ id: member.id, name: member.name, mrn: member.mrn }]}
        onSuccess={handleCarePlanSignSuccess}
        isModalOpen={isSignModalOpenDP2}
        closeModal={() => setIsSignModalOpenDP2(false)}
        carePlanId={currentCarePlan?.id}
        isCarePlan
      />
      <CoSign
        authenticityToken={authenticityToken}
        onSuccess={handleCarePlanCoSignSuccess}
        isModalOpen={isCosignModalOpenDP2}
        closeModal={() => setIsCosignModalOpenDP2(false)}
        member={{ id: member.id, name: member.name, mrn: member.mrn }}
        carePlanId={currentCarePlan?.id}
        isCarePlan
      />
      <ManualUploadModalDP2
        isOpen={isUploadModalOpenDP2}
        onClose={() => setIsUploadModalOpenDP2(false)}
        onSuccess={handleUploadSuccess}
        member={{ id: member.id, name: member.name }}
        carePlanId={currentCarePlan.id}
        authenticityToken={authenticityToken}
        carePlanFinishedAt={moment(currentCarePlan.finished_timestamp)}
      />
      <div className="ml-12 w-4/5">
        {finishing && finishingActivity()}
        {currentCarePlan && config && !finishing && wizard()}
      </div>
    </>
  );
}

CarePlanView.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  carePlan: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  member: PropTypes.shape({
    external_id: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    isDP2: PropTypes.bool,
    mrn: PropTypes.string,
  }).isRequired,
  signButtonFlag: PropTypes.bool.isRequired,
  uploadButtonFlag: PropTypes.bool.isRequired,
  cosignerFlag: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userIsProvider: PropTypes.bool.isRequired,
};

export default CarePlanView;
