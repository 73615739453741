import { useEffect, useState } from 'react';

const headers = (expandViewOpen = false, currentSort = null, isClickable = true, featureFlags = {}) => [
  {
    icon: 'CrosshairIcon',
    show: true,
    isClickable,
    direction: 'asc',
    accessorKey: 'last_action',
    classes: currentSort?.includes('last_action') ? 'flex' : '',
    isSorting: currentSort?.includes('last_action'),
    style: { marginLeft: '0px', marginRight: '0px' },
  },
  {
    name: 'Priority',
    accessorKey: 'priority_rank',
    direction: 'asc',
    isClickable,
    show: featureFlags?.taskPriorityFlag,
    isSorting: currentSort?.includes('priority_rank'),
    style: { marginLeft: '0px' },
  },
  {
    name: 'Task',
    accessorKey: 'task_type',
    direction: 'asc',
    isClickable,
    classes: currentSort?.includes('task_type') ? 'flex' : '',
    show: true,
    isSorting: currentSort?.includes('task_type'),
    style: { marginLeft: '0px' },
  },
  {
    name: 'Member',
    accessorKey: 'member',
    direction: 'asc',
    isClickable,
    style: { margin: 'auto' },
    show: true,
    isSorting: currentSort?.includes('member'),
  },
  {
    name: 'Empaneled Group',
    accessorKey: 'empanelment',
    direction: 'desc',
    isClickable,
    show: !expandViewOpen,
    isSorting: currentSort?.includes('empanelment'),
  },
  {
    name: 'Due Date',
    accessorKey: 'due_date',
    direction: 'asc',
    isClickable,
    show: true,
    isSorting: currentSort?.includes('due_date'),
  },
  {
    name: 'Assignee',
    accessorKey: 'assignee',
    direction: 'asc',
    isClickable,
    show: true,
    isSorting: currentSort?.includes('assignee'),
  },
  {
    name: 'Actions',
    accessorKey: 'actions',
    direction: 'asc',
    isClickable: false,
    show: !expandViewOpen,
  },
];

const useTableHeaders = (featureFlags = {}) => {
  const [tableHeaders, setTableHeaders] = useState(headers);

  const updateHeaders = async (expandViewOpen = false, currentSort = null, isClickable = true) => {
    const updatedHeaders = headers(expandViewOpen, currentSort, isClickable, featureFlags);
    setTableHeaders(updatedHeaders);
  };

  useEffect(() => {
    updateHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tableHeaders, updateHeaders };
};

export default useTableHeaders;
