import React from 'react';
import PropTypes from 'prop-types';

/**
 *  ModalError Component
 * @param {{errors: array}} props
 * @returns {React.ReactElement} Modal Errors.
 */
function ModalError({ errors }) {
  return (
    <div className="modal-error-container">
      {errors?.map?.((error) => (
        <p className="modal-error text-error-700 font-bold text-md error my-2" key={error}>
          {error}
        </p>
      ))}
    </div>
  );
}

ModalError.propTypes = {
  errors: PropTypes.array,
};

ModalError.defaultProps = {
  errors: [],
};

export default ModalError;
