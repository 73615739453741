import { useEffect, useState } from 'react';

import udsTestRecord from '../constants/uds-record';
import pregnancyTestRecord from '../constants/pregnancy-test-record';
import fentanylTestRecord from '../constants/fentanyl-test-record';

const useDefaultTest = (props) => {
  // DEFAULT CHECKBOX is when a user open the modal, based on which link they click
  // it will pass in 'testView' and show that selection
  const [defaultTest, setDefaultTest] = useState('');

  // DEFAULT UDS Id is used for when a user click the 'UDS CUP' top selector, it will
  // auto select the top UDS image available (from F,B,L,R)
  const [defaultUdsId, setDefaultUdsId] = useState('');
  const [defaultUdsUrl, setDefaultUdsUrl] = useState('');

  const { udsBack, udsFront, udsLeft, udsRight } = udsTestRecord(props?.udsRecord);
  const { pregnancyId } = pregnancyTestRecord(props?.pregnancyRecord);
  const { fentanylId } = fentanylTestRecord(props?.fentanylRecord);

  useEffect(() => {
    // USE first id that is not null to set as default selected
    const defaultIdUds = udsBack.id || udsFront.id || udsLeft.id || udsRight.id;
    const defaultUrlUds = udsBack.url || udsFront.url || udsLeft.url || udsRight.url;

    setDefaultUdsId(defaultIdUds);
    setDefaultUdsUrl(defaultUrlUds);

    if (props.testView === 'uds') {
      setDefaultTest(defaultIdUds);
    } else if (props.testView === 'pregnancy') {
      setDefaultTest(pregnancyId);
    } else if (props.testView === 'fentanyl') {
      setDefaultTest(fentanylId);
    }
  }, [
    props.testView,
    udsBack.id,
    udsFront.id,
    udsLeft.id,
    udsRight.id,
    udsBack.url,
    udsFront.url,
    udsLeft.url,
    udsRight.url,
    pregnancyId,
    fentanylId,
  ]);

  return {
    defaultTest,
    defaultUdsId,
    defaultUdsUrl,
  };
};

export default useDefaultTest;
