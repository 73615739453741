import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ButtonRow from '../shared/ButtonRow';
import useVerify from './useVerify';
import Header from '../shared/Header';
import MuiDatePicker from '../shared/MuiDatePicker';
import TextRowDescription from '../shared/TextRowDescription';
import Toast from '../shared/Toast';
import { DOB_VERIFICATION_MESSAGE, DOB_HEADER } from './constants';
import { DEFAULT_DATE_FORMAT } from '../../helpers/DateFormatter';

const testID = 'externalAccessMain';

function Main({ authenticityToken, accessToken }) {
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const { handleSubmit, isLoading, error } = useVerify(dateOfBirth, authenticityToken, accessToken);

  const tokenError = error && error.includes('token');
  const generalError = error && !error.includes('token');

  return (
    <div data-testid={testID} className="text-black">
      {tokenError && <Toast header="Token expired" message={error} type="error" hasCloseButton={false} />}
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <Header withNoUnderline withBottomMargin text={DOB_HEADER} />
          <TextRowDescription text={DOB_VERIFICATION_MESSAGE} />
          <MuiDatePicker
            onChange={(newDate) => setDateOfBirth(dayjs(newDate).format('YYYY-MM-DD'))}
            value={dateOfBirth}
            isFormLabel
            style={{ marginTop: '12px' }}
            format={DEFAULT_DATE_FORMAT}
          />
          {generalError && <span className="font-semibold text-error-700 text-center">{error}</span>}
          <ButtonRow
            showPrimary
            primaryButtonDisabled={!dateOfBirth}
            showSecondary={false}
            primaryLabel="submit"
            onPrimaryClick={handleSubmit}
            primaryButtonLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default Main;
