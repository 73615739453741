import { useEffect, useState } from 'react';

const useDefaultShipmentPickups = () => {
  // EXAMPLE: defaults
  // {
  //   default_location: { label: "location name", value: 1 },
  //   use_default_location: true,
  // }
  const [defaultPickup, setDefaults] = useState({});

  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const fetchDefault = async () => {
    setDefaults(parseData('default-uds-shipment-pickup-data') || {});
  };

  useEffect(() => {
    fetchDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    defaultPickup,
    fetchDefault,
  };
};

export default useDefaultShipmentPickups;
