import React from 'react';
import PropTypes from 'prop-types';

import { mdyyyyUTC } from '../../helpers/DateFormatter';

function LatestVisitCell({ visitDate, visitType }) {
  const content = (date) => {
    if (date) {
      return (
        <div className="latest-visit">
          <p>{mdyyyyUTC(date)}</p>
          <p className="sub-text">{visitType}</p>
        </div>
      );
    }
    return <p>No</p>;
  };

  return content(visitDate);
}

LatestVisitCell.propTypes = {
  visitDate: PropTypes.date,
  visitType: PropTypes.string,
};

LatestVisitCell.defaultProps = {
  visitDate: null,
  visitType: null,
};

export default LatestVisitCell;
