import moment from 'moment';
import * as Yup from 'yup';

const addTaskValidationSchema = Yup.object()
  .shape({
    member: Yup.object().required('Member is required'),
    due_date: Yup.string()
      .required('Due date is required')
      .test('is-future', 'Due date must be in the future', (value) => {
        const now = moment().startOf('day').utc(true);
        const dueDate = moment.parseZone(value).startOf('day').utc(true).startOf('day');
        return dueDate.isSameOrAfter(now);
      }),
  })
  .required();

export default addTaskValidationSchema;
