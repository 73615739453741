const tableColumnHeaders = [
  {
    name: 'Name',
    showColumn: true,
    isClickable: false,
    actionName: 'filter_name',
  },
  {
    name: 'Upcoming Group Meeting',
    showColumn: true,
    isClickable: false,
    actionName: 'filter_group_meeting',
  },
  {
    name: 'Current Empanelment',
    showColumn: true,
  },
  {
    name: 'Actions',
    showColumn: true,
  },
];

export default tableColumnHeaders;
