import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from './DatePicker';

function DatePickerContainer(props) {
  return (
    <div className={`react-inline ${props.classes}`} style={props.style}>
      <div className="filter-label" style={props.labelStyle}>
        {props.label}
      </div>

      <DatePicker style={{ width: '100%' }} value={props.value} onChange={props.onChange} />
    </div>
  );
}

DatePickerContainer.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.instanceOf(Date),
  style: PropTypes.object,
  label: PropTypes.string,
  labelStyle: PropTypes.object,

  onChange: PropTypes.func.isRequired,
};

DatePickerContainer.defaultProps = {
  classes: {},
  style: {},
  labelStyle: {},
  label: '',
  value: new Date(),
};

export default DatePickerContainer;
