import { useEffect, useState } from 'react';
import axios from 'axios';

const useFilters = () => {
  const [providerFilters, setProviderFilters] = useState([]);

  const fetchProviderFilters = async () => {
    try {
      const { data: response } = await axios.get('/staff/providers/filters');
      setProviderFilters(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchProviderFilters();
  }, []);

  return {
    providerFilters,
  };
};

export default useFilters;
