import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GroupsModal from './GroupsModal';
import Input from './shared/Input';

const formatMember = (member) => {
  const name = member?.name || `${member?.firstName} ${member?.lastName}`;

  return `${name} (${member?.mrn})`;
};

function LockAndSignConfirmationModal({
  isOpen,
  onClose,
  templateName,
  members,
  onLockAndSign,
  isLockAndSignLoading,
  isCarePlan,
}) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => setIsChecked(false), [isOpen]);

  const header = isCarePlan ? 'Sign Care Plan' : 'Lock & Sign Note';

  return (
    <GroupsModal
      testID="lock-and-sign-confirmation-needed-modal"
      isOpen={isOpen}
      header={header}
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          type: 'button',
          label: `${!isCarePlan ? 'Lock And ' : ''}Sign`,
          isWarning: true,
          onClick: onLockAndSign,
          isLoading: isLockAndSignLoading,
          disabled: !isChecked,
        },
      ]}
      size="medium"
      isLoading={isLockAndSignLoading}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="py-6 px-4 border-b-2">
          <p className="mb-2 text-lg">
            You are confirming to <span className="font-bold">{!isCarePlan && 'lock and '}sign</span>&nbsp;
            {isCarePlan ? 'care plan' : 'the following templates'} for the following&nbsp;
            {members?.length > 1 ? 'members' : 'member'}.
          </p>
          <p className="m-0 text-error-700 font-semibold text-lg">This action cannot be undone.</p>
        </div>
        <div className="py-6 px-4 border-b">
          {!isCarePlan && (
            <>
              <p className="font-semibold">Template</p>
              <p data-testid="lock-and-sign-template-name" className="mt-2 mb-8">
                {templateName || '-'}
              </p>
            </>
          )}
          <div className="font-semibold">{members.length === 1 ? 'Member' : 'Members'}</div>
          <div className="mt-2">
            {members.length === 0 && <p>-</p>}
            {members.length === 1 && <div data-testid="lock-and-sign-member">{formatMember(members[0])}</div>}
            {members.length > 1 && (
              <ul data-testid="lock-and-sign-members-list" className="list-disc pl-5">
                {members.map((member) => (
                  <li data-testid={`lock-and-sign-member-${member.id}`} key={member.id}>
                    {formatMember(member)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="p-4">
          <Input
            type="checkbox"
            name="confirmation-checkbox"
            checked={isChecked}
            value={isChecked.toString()}
            onChange={(e) => setIsChecked(e.target.checked)}
            option="I understand this is a legal representation of my signature."
          />
        </div>
      </div>
    </GroupsModal>
  );
}

LockAndSignConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      mrn: PropTypes.string.isRequired,
    })
  ).isRequired,
  onLockAndSign: PropTypes.func.isRequired,
  isLockAndSignLoading: PropTypes.bool.isRequired,
  isCarePlan: PropTypes.bool,
};

LockAndSignConfirmationModal.defaultProps = {
  isCarePlan: false,
};

export default LockAndSignConfirmationModal;
