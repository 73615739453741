import React, { useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { debounce } from 'lodash';

// COMPONENTS
import Filters from '../../Filters';
import EmpanelmentMemberSearchFilterOption from './EmpanelmentMemberSearchFilterOption';
import { MemberSearchProvider } from './useMemberSearchContext';

// HELPERS
import { labelStyle } from '../../uds_supplies_dashboard/shared/styles';
import useHttp from '../hooks/use-http';
import { filterOption } from './helpers';

const DEFAULT_PLACEHOLDER = 'Search by first name, last name, or MRN';
const DEFAULT_LABEL = 'Member';

function EmpanelmentMemberSearchFilter({ testID, value, onSelect, error, placeholder, label, multiSelect }) {
  const { sendRequest, isLoading } = useHttp();
  const [members, setMembers] = useState([]);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');

  const searchMembers = async (query) => {
    setCurrentSearchQuery(query);

    try {
      const response = await sendRequest(`/staff/empanelment_members`, {
        method: 'GET',
        params: {
          page: 1,
          search_text: query,
        },
      });

      const parsedMembers = response?.json_data.map((member) => ({
        value: member,
        label: member.mrn ? `${member.member_name} (${member.mrn})` : member.member_name,
      }));
      setMembers(parsedMembers);
    } catch (e) {
      window.Sentry.captureException(e);
    }
  };

  return (
    <MemberSearchProvider query={currentSearchQuery}>
      <Filters
        isSearch
        labelStyle={labelStyle}
        label={label}
        placeholder={placeholder}
        data={members}
        value={multiSelect ? value ?? [] : [value]}
        error={error}
        stateChanger={(selected) => onSelect(multiSelect ? selected : selected.at(-1) ?? null)}
        onHandleSearch={debounce((query) => searchMembers(query), 100)}
        isLoading={isLoading}
        testID={testID}
        filterOption={filterOption}
        customOptionComponent={EmpanelmentMemberSearchFilterOption}
      />
    </MemberSearchProvider>
  );
}

EmpanelmentMemberSearchFilter.propTypes = {
  testID: PropTypes.string,
  value: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  multiSelect: PropTypes.bool,
};

EmpanelmentMemberSearchFilter.defaultProps = {
  testID: 'empanelment-member-search',
  error: null,
  placeholder: DEFAULT_PLACEHOLDER,
  label: DEFAULT_LABEL,
  multiSelect: false,
};

export default EmpanelmentMemberSearchFilter;
