import React from 'react';
import PropTypes from 'prop-types';

function Dashboard({ currentUser }) {
  const userName = [currentUser.first_name, currentUser.last_name].join(' ');

  return <div>Hello! {userName}</div>;
}

Dashboard.propTypes = {
  currentUser: {
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }.isRequired,
};

export default Dashboard;
