/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

const useCurrentUser = (props) => {
  const [currentUserId, setCurrentUserId] = useState('');

  const fetchUser = async () => {
    try {
      axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

      const { data: response } = await axios.get('/users/show');

      setCurrentUserId(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => fetchUser(), [props.authenticityToken]);

  return {
    currentUserId,
  };
};

useCurrentUser.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
};

export default useCurrentUser;
