const GroupTableHeaderData = (isGroupTypeFeatureFlagged) => {
  const groupTableHeaders = [
    {
      name: 'Group',
      showColumn: true,
      isClickable: true,
      actionName: 'filter_group_name',
    },
    {
      name: 'Status',
      showColumn: true,
      isClickable: true,
      actionName: 'filter_status',
    },
    {
      name: 'Modality',
      showColumn: true,
      isClickable: true,
    },
    {
      name: isGroupTypeFeatureFlagged ? 'Clinical Model' : 'Group Type',
      showColumn: true,
      isClickable: true,
    },
    {
      name: 'Office Manager',
      showColumn: true,
      isClickable: true,
    },
    {
      name: 'Counselor',
      showColumn: true,
      isClickable: true,
      actionName: 'filter_counselor_name',
    },
    {
      name: 'Provider',
      showColumn: true,
      isClickable: true,
      actionName: 'filter_provider_name',
    },
    {
      name: 'Members',
      showColumn: true,
      isClickable: false,
      sortKey: 'memberCount',
    },
    {
      name: 'Actions',
      showColumn: true,
      isClickable: false,
    },
  ];

  return groupTableHeaders;
};

export default GroupTableHeaderData;
