import React from 'react';
import PropTypes from 'prop-types';

function InfoCard({ children, heading, classNames, childrenClassNames, headingClassNames }) {
  return (
    <div className={`flex flex-col border border-gray-300 rounded-sm divide-y divide-gray-300 ${classNames}`}>
      {heading && (
        <div className="py-5 px-6">
          <span className={`font-bold text-lg ${headingClassNames}`}>{heading}</span>
        </div>
      )}
      <div className={childrenClassNames}>{children}</div>
    </div>
  );
}

InfoCard.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string,
  headingClassNames: PropTypes.string,
  childrenClassNames: PropTypes.string,
};

InfoCard.defaultProps = {
  heading: null,
  classNames: '',
  headingClassNames: '',
  childrenClassNames: '',
};

export default InfoCard;
