import React from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from 'react-icons/tb';
import Button from '../shared/Button';

function ChartingNotesHeader({
  latestNoteDate,
  onAddAddendumClick,
  onCoSignClick,
  disabled,
  hideActionButtons,
  isFullWidth,
  toggleFullWidth,
}) {
  return (
    <>
      <div data-testid="charting-notes-header" className="pt-4 pb-2 flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-body font-semibold">Notes</h1>
        </div>
        <div className="flex gap-2.5 flex-wrap">
          {!hideActionButtons && (
            <>
              <Button isSecondary type="button" onClick={onAddAddendumClick} disabled={disabled}>
                Add Addendum
              </Button>
              <Button isPrimary type="button" onClick={onCoSignClick} disabled={disabled}>
                Co-Sign Note
              </Button>
            </>
          )}

          {toggleFullWidth && (
            <button
              type="button"
              className="border-2 border-teal-800 rounded-full p-2 w-12 h-12 flex items-center"
              onClick={toggleFullWidth}
            >
              {isFullWidth ? (
                <TbArrowsDiagonalMinimize2
                  data-testid="arrows-diagonal-minimize-icon"
                  className="w-8 h-8 text-teal-800"
                />
              ) : (
                <TbArrowsDiagonal data-testid="arrows-diagonal-icon" className="w-8 h-8 text-teal-800" />
              )}
            </button>
          )}
        </div>
      </div>
      {latestNoteDate ? (
        <p data-testid="charting-notes-header-date-time" className="flex gap-1 items-center font-body italic mb-2.5">
          <IoIosCheckmarkCircleOutline className="w-4 h-4 text-gray-700" />
          Last saved: &nbsp;
          {latestNoteDate}
        </p>
      ) : null}
    </>
  );
}

ChartingNotesHeader.propTypes = {
  latestNoteDate: PropTypes.string,
  onAddAddendumClick: PropTypes.func.isRequired,
  onCoSignClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  toggleFullWidth: PropTypes.func,
};

ChartingNotesHeader.defaultProps = {
  latestNoteDate: null,
  disabled: false,
  hideActionButtons: true,
  isFullWidth: false,
  toggleFullWidth: null,
};

export default ChartingNotesHeader;
