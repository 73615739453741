import React from 'react';
import PropTypes from 'prop-types';

function ModalContainer(props) {
  if (!props.isOpen) return null;
  const largeModalCLass = props.isLargeModal ? 'sm:max-w-6xl' : 'sm:max-w-2xl';

  return (
    <div className="fixed inset-0 overflow-y-auto z-20">
      <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
        <div
          className={`main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle ${largeModalCLass} sm:my-8 sm:w-full text-left z-20`}
        >
          <div className="p-6 w-full whitespace-normal">{props.children}</div>
        </div>
        <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
      </div>
    </div>
  );
}

ModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  isLargeModal: PropTypes.bool,
};

ModalContainer.defaultProps = {
  isOpen: false,
  children: null,
  isLargeModal: false,
};

export default ModalContainer;
