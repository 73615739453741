import { useEffect, useRef } from 'react';

const useIntersectionObserver = (callback, { root, rootMargin, threshold }) => {
  const ref = useRef(null);
  const stableCallback = useRef(callback);

  useEffect(() => {
    stableCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const node = ref.current;

    const observer = new IntersectionObserver(([entry]) => stableCallback.current(entry), {
      root,
      rootMargin,
      threshold,
    });

    if (node) observer.observe(node);

    return () => {
      if (node) observer.unobserve(node);
      observer.disconnect();
    };
  }, [root, rootMargin, threshold, callback]);

  return { ref };
};

export default useIntersectionObserver;
