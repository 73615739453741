import React from 'react';
import PropTypes from 'prop-types';

function CompleteIcon(props) {
  return (
    <svg
      className={props.className}
      style={props.style}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5266_3611)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 28.5H14.5C8.70133 28.5 4 23.8 4 18V15C4 9.2 8.70133 4.5 14.5 4.5H17.5C23.2987 4.5 28 9.2 28 15V18C28 23.8 23.2987 28.5 17.5 28.5Z"
          fill="#0F8B8D"
        />
        <path
          d="M10.6665 16.5L14.6665 20.5L21.3332 12.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5266_3611">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

CompleteIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
};

CompleteIcon.defaultProps = {
  className: '',
  style: {},
};

export default CompleteIcon;
