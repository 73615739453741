import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { CircularProgress } from '@mui/material';

import InputWrapper from './InputWrapper';
import useHttp from './hooks/use-http';
import { selectStyles, ClearIndicator, DropdownIndicator, theme } from './SelectCommons';
import MemberInfoPanel from './MemberInfoPanel';

const MemberSearchSelect = forwardRef(
  ({ name, value, onChange, placeholder, label, error, required, wrapperClassNames, disabled }, ref) => {
    const [selectedUserData, setSelectedUserData] = useState(null);
    const { sendRequest: sendOptionsRequest } = useHttp();
    const { sendRequest: sendUserRequest, isLoading } = useHttp();

    const fetchUserData = async (memberId) => {
      const data = await sendUserRequest(`/staff/empanelment_members/show_member?id=${memberId}`);
      setSelectedUserData(data);
    };

    useEffect(() => {
      setSelectedUserData(null);
      if (value !== null) {
        fetchUserData(value.value);
      }
    }, [value]);

    const loadOptions = async (inputValue, callback) => {
      const data = await sendOptionsRequest(`/staff/empanelment_members?search_text=${inputValue}`);
      const options = data?.json_data?.map(({ member_name, mrn, id, email, phone_number }) => ({
        label: `${member_name} (${mrn}, ${email}, ${phone_number})`,
        value: id,
      }));
      if ((data?.total_count || 0) > 10) {
        options.push({
          label: `There are ${(data?.total_count || 0) - 10} more records. Provide more information to narrow down search`,
          value: 0,
        });
      }
      callback(options);
    };

    const debouncedLoadOptions = debounce(loadOptions, 400);

    return (
      <InputWrapper label={label} name={name} required={required} error={error} wrapperClassNames={wrapperClassNames}>
        <AsyncSelect
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          loadOptions={debouncedLoadOptions}
          isOptionDisabled={(option) => option.value === 0}
          defaultOptions
          styles={selectStyles(error, disabled)}
          theme={theme}
          isDisabled={disabled}
          components={{ DropdownIndicator, ClearIndicator }}
        />
        {(selectedUserData || isLoading) && (
          <div className="bg-blue-100 rounded-md px-6 py-4 gap-7 flex mt-1">
            {isLoading && <CircularProgress className="mx-auto" />}
            {selectedUserData && <MemberInfoPanel memberData={selectedUserData} />}
          </div>
        )}
      </InputWrapper>
    );
  }
);

MemberSearchSelect.displayName = 'MemberSearchSelect';

const valuePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

MemberSearchSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: valuePropType,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  wrapperClassNames: PropTypes.string,
};

MemberSearchSelect.defaultProps = {
  placeholder: null,
  value: null,
  error: null,
  required: false,
  disabled: false,
  label: null,
  wrapperClassNames: '',
};

export default MemberSearchSelect;
