import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useToasts from '../../../shared/hooks/use-toasts';
import Toasts from '../../../shared/Toasts';
import GroupsModal from '../../../GroupsModal';
import useHttp from '../../../shared/hooks/use-http';
import SelectInput from '../../../shared/SelectInput';
import { OptionsPropTypes } from '../shared/types';

function UpdateAppointmentReasonModal({
  isOpen,
  onClose,
  onSuccess,
  appointmentOccurrenceMemberId,
  appointmentReason,
  reasons,
  authenticityToken,
}) {
  const { toasts, addToast, removeToast } = useToasts();
  const { sendRequest, isLoading } = useHttp();
  const [reason, setReason] = useState(() =>
    appointmentReason ? reasons.find((r) => r.value === appointmentReason) : null
  );

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(`/staff/appointment_occurrences_members/${appointmentOccurrenceMemberId}`, {
        method: 'PATCH',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: { appointment_occurrence_member: { appointment_reason_id: reason?.value ?? null } },
      });

      onSuccess(reason?.label);
    } catch (err) {
      addToast({
        message: err.parsedMessage ?? 'Something went wrong. Try again later.',
        type: 'error',
        isError: true,
      });
    }
  };

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Save',
      isPrimary: true,
      type: 'submit',
      form: 'edit-appt-reason-form',
      isLoading,
    },
  ];

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Update Encounter Reason"
      footerButtons={footerButtons}
      size="small"
      isLoading={isLoading}
    >
      <Toasts toasts={toasts} removeToast={removeToast} />
      <form onSubmit={onSubmit} id="edit-appt-reason-form" className="bg-white rounded-lg shadow">
        <div className="flex flex-col gap-4 p-4">
          <div>
            <SelectInput
              label="Encounter Reason"
              name="reason"
              value={reason}
              onChange={setReason}
              placeholder="Select reason"
              options={reasons}
              isClearable
            />
          </div>
        </div>
      </form>
    </GroupsModal>
  );
}

UpdateAppointmentReasonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  appointmentOccurrenceMemberId: PropTypes.number.isRequired,
  appointmentReason: PropTypes.string,
  reasons: OptionsPropTypes.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

UpdateAppointmentReasonModal.defaultProps = {
  appointmentReason: null,
};

export default UpdateAppointmentReasonModal;
