import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Lightbox from 'react-awesome-lightbox';
import '../../stylesheets/image-box.css';

/**
 * IMAGE BOX
 *
 * Displays an image with option to click for fullscreen view
 *
 * @prop {string} alt - image title
 * @prop {object} style - custom image div styling (css). view app/javascript/helpers/PhotosModal.js for example
 * @prop {string} imageSrc - render image using link
 */

function ImageWithFullscreen(props) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleOpen = () => setIsFullScreen(true);
  const handleClose = () => setIsFullScreen(false);

  return (
    <>
      {isFullScreen ? (
        <Lightbox image={props.imageSrc} showTitle={false} allowRotate={false} onClose={handleClose} />
      ) : (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <img style={props.style} src={props.imageSrc} alt={props.alt} onClick={handleOpen} />
      )}
    </>
  );
}

ImageWithFullscreen.propTypes = {
  alt: PropTypes.string,
  style: PropTypes.object,
  imageSrc: PropTypes.string.isRequired,
};

ImageWithFullscreen.defaultProps = {
  style: { cursor: 'zoom-in' },
  alt: 'default_title',
};

export default ImageWithFullscreen;
