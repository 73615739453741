import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import DefaultCell from '../shared_dashboard/DefaultCell';
import DateLocationCell from '../shared_dashboard/DateLocationCell';

function WeeklyGroupsTableRow(props) {
  return (
    <tr>
      {/* GROUP MEETING CELL */}
      <DateLocationCell
        id={props.data?.id}
        locationId={props.data?.location_id}
        type="groupDateTime"
        endpoint="/staff/dashboard_v2/fetch_group_meeting_time"
      />

      {/* GROUP TYPE */}
      <DefaultCell id={props.data?.id} type="groupType" endpoint="/staff/dashboard_v2/fetch_appointment_type" />

      {/* COUNSELOR */}
      <DefaultCell id={props.data?.resource_id} type="counselor" endpoint="/staff/dashboard_v2/fetch_counselor" />

      {/* PROVIDER */}
      <DefaultCell id={props.data?.provider_id} type="provider" endpoint="/staff/dashboard_v2/fetch_provider" />

      {/* MEMBER COUNT */}
      <DefaultCell id={props.data?.id} type="memberCount" endpoint="/staff/dashboard_v2/fetch_member_count" />

      {/* UDS COUNT */}
      {/* <DefaultCell
        id={props.data?.id}
        type="udsCount"
        endpoint="/staff/dashboard_v2/fetch_uds_count"
      /> */}
    </tr>
  );
}

WeeklyGroupsTableRow.propTypes = {
  data: PropTypes.array.isRequired,
};

export default WeeklyGroupsTableRow;
