import { FILTER_TYPES } from './constants';

const setRequestParams = (pageNumber, filters, sort) => {
  const state_ids = filters[FILTER_TYPES.state].map(({ value }) => value);
  const location_ids = filters[FILTER_TYPES.facility].map(({ value }) => value);
  const days = filters[FILTER_TYPES.groupDay].map(({ value }) => value);
  const provider_ids = filters[FILTER_TYPES.provider]?.map(({ value }) => value);
  const counselor_ids = filters[FILTER_TYPES.counselor]?.map(({ value }) => value);
  const office_manager_ids = filters[FILTER_TYPES.officeManager]?.map(({ value }) => value);
  const tx = filters[FILTER_TYPES.tx].map(({ value }) => value);
  const payors = filters[FILTER_TYPES.payer].map(({ value }) => value);
  const dosages = filters[FILTER_TYPES.dosage].map(({ value }) => value);
  const statuses = filters[FILTER_TYPES.status].map(({ value }) => value);
  const sortDirection = sort?.desc ? 'desc' : 'asc';
  const sortedBy = sort ? `${sort.id} ${sortDirection}` : null;
  const searchText = filters[FILTER_TYPES.member]?.length > 0 ? filters[FILTER_TYPES.member] : null;

  return {
    mrn_type: 'healthie',
    page: pageNumber,
    days: days.includes('*') ? null : days,
    state_ids: state_ids.includes('*') ? null : state_ids,
    location_ids: location_ids.includes('*') ? null : location_ids,
    provider_ids: provider_ids?.includes('*') ? null : provider_ids,
    counselor_ids: counselor_ids?.includes('*') ? null : counselor_ids,
    office_manager_ids: office_manager_ids?.includes('*') ? null : office_manager_ids,
    tx: tx.includes('*') ? null : tx,
    payors: payors.includes('*') ? null : payors,
    dosages: dosages.includes('*') ? null : dosages,
    statuses: statuses.includes('*') ? null : statuses,
    search_text: searchText,
    sort_name: sortedBy,
  };
};
export default setRequestParams;
