const customStyles = {
  listItem: {
    fontFamily: 'Inter',
    fontWeight: 800,
  },
  listItemButton: {
    '&:hover': {
      backgroundColor: 'inherit',
      color: '#171219',
    },
    '&.Mui-selected': {
      borderRight: '2px solid black',
      backgroundColor: 'white',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'inherit',
    },
  },
  checkBox: {
    '&.Mui-checked': {
      color: '#237786',
    },
    '&.Mui-disabled': {
      color: '#C9CBCC',
    },
  },
  listItemText: {
    display: 'inline',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
  },
};

function dynamicMarginSubsection(isSubsection, hideCheckbox) {
  if (isSubsection) {
    return '80px';
  }
  if (hideCheckbox) {
    return '40px';
  }
  return null;
}

export { customStyles, dynamicMarginSubsection };
