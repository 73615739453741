import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Button from '../../shared/Button';
import GoalActionsMenu from './GoalActionsMenu';

function GoalActionsButton({ goalId, updateCallback, editCallback, testId }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const buttonIcon = () => (open ? <BiChevronUp /> : <BiChevronDown />);

  return (
    <div className="goal-actions" data-testid={testId}>
      <Button classes="goal-actions-button" onClick={handleOpen}>
        Actions
        {buttonIcon()}
      </Button>
      {open && (
        <GoalActionsMenu
          goalId={goalId}
          updateCallback={updateCallback}
          editCallback={editCallback}
          testId={`menu-${testId}`}
        />
      )}
    </div>
  );
}

GoalActionsButton.propTypes = {
  goalId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  editCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

export default GoalActionsButton;
