import React from 'react';
import PropTypes from 'prop-types';

function InputError({ error }) {
  return (
    error && (
      <p data-testid="input-error" className="mb-0 mt-2 text-sm text-error-800">
        {error}
      </p>
    )
  );
}

InputError.propTypes = {
  error: PropTypes.string,
};

InputError.defaultProps = {
  error: null,
};

export default InputError;
