// use-fetch-data.js
import { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * useFetchData
 *
 * helper used for fetching test record information
 *
 * @param {string} memberId - members id for requesting testing data
 * @param {object} testIds - testIds (UDS, pregnancy, and fentanyl) used for fetching test records
 *
 * @return {object} udsRecord, pregnancyRecord, fentanylRecord
 * object structure:
 *   pregnancyRecord: {
        record: { id: null, updated_at: null },
        attachment_record: { id: null, updated_at: null },
        url: 'test_photo_url',
      },
*    fentanylRecord: {
        record: { id: null, updated_at: null },
        attachment_record: { id: null, updated_at: null },
        url: 'test_photo_url',
      },
*    udsRecord: {
        record: { id: null, updated_at: null },
        front: {
          attachment_record: { id: null, updated_at: null },
          url: 'front_test_photo_url',
        },
        back: {
          attachment_record: { id: null, updated_at: null },
          url: 'back_test_photo_url',
        },
        left: {
          attachment_record: { id: null, updated_at: null },
          url: 'left_test_photo_url',
        },
        right: {
          attachment_record: { id: null, updated_at: null },
          url: 'right_test_photo_url',
        },
      },
 */

const useFetchData = (props) => {
  const { memberId } = props;
  const { udsId, pregnancyId, fentanylId } = props.testIds;

  const [udsRecord, setUdsRecord] = useState({});
  const [pregnancyRecord, setPregnancyRecord] = useState({});
  const [fentanylRecord, setFentanylRecord] = useState({});

  const fetchUds = async () => {
    try {
      if (!udsId) {
        return;
      }

      const params = { id: udsId, member_id: memberId };
      const { data: response } = await axios.get(`/urine_drug_screenings/${udsId}`, { params });

      setUdsRecord(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchPregnancy = async () => {
    try {
      if (!pregnancyId) {
        return;
      }

      const params = { id: pregnancyId, member_id: memberId };
      const { data: response } = await axios.get(`/pregnancy_tests/${pregnancyId}`, { params });

      setPregnancyRecord(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchFentanyl = async () => {
    try {
      if (!fentanylId) {
        return;
      }

      const params = { id: fentanylId, member_id: memberId };
      const { data: response } = await axios.get(`/fentanyl_tests/${fentanylId}`, { params });

      setFentanylRecord(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchUds();
    fetchPregnancy();
    fetchFentanyl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    udsRecord,
    pregnancyRecord,
    fentanylRecord,
  };
};

export default useFetchData;
