import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputWrapper from './InputWrapper';
import Input from './Input';

// eslint-disable-next-line react/display-name
const RadioGroup = forwardRef(
  (
    { label, name, value, onBlur, onChange, error, disabled, required, isVertical, options, isHorizontalRadio },
    ref
  ) => {
    if (!options?.length) return null;

    return (
      <InputWrapper
        label={label}
        name={`${name}-${options?.[0]}`}
        required={required}
        error={error}
        isVertical={isVertical}
        testId="radio-group"
      >
        <div
          className={classNames({
            'flex gap-4': isHorizontalRadio,
          })}
        >
          {options?.map((option) => (
            <div key={option} className="py-2">
              <Input
                id={`${name}-${option}`}
                ref={ref}
                name={name}
                type="radio"
                value={option}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
                checked={option === value}
                option={option}
                className={classNames('shared-input', { 'shared-input-error': !!error })}
              />
            </div>
          ))}
        </div>
      </InputWrapper>
    );
  }
);

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  isHorizontalRadio: PropTypes.bool,
};

RadioGroup.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  isVertical: true,
  options: null,
  error: null,
  isHorizontalRadio: false,
};

export default RadioGroup;
