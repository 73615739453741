import React from 'react';
import PropTypes from 'prop-types';

import ShortTermGoalIcon from './ShortTermGoalIcon';

function ShortTermGoalBadge({ testId }) {
  return (
    <div className="goal-badge short-term-goal-badge" data-testid={testId}>
      <ShortTermGoalIcon testId={`icon-${testId}`} />
      <p data-testid={`text-${testId}`}>
        <strong>SHORT-TERM GOAL</strong>
      </p>
    </div>
  );
}

ShortTermGoalBadge.propTypes = {
  testId: PropTypes.string.isRequired,
};

export default ShortTermGoalBadge;
