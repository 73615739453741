import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';

function FilterZipcode(props) {
  const ZIPCODE_REGEXP = /^\d{5}(-\d{4})?$/;
  const [isValidZipcode, setIsValidZipcode] = useState(true);
  const [zipcode, setZipcode] = useState('');
  const appliedZipcodeIsDifferent = props.currentlyAppliedZipcode !== zipcode;

  const clearBtnColors = props.currentlyAppliedZipcode ? ' text-error-700' : ' text-gray-400';
  const applyBtnColors =
    (!props.currentlyAppliedZipcode || appliedZipcodeIsDifferent) && zipcode ? ' text-teal-700' : ' text-gray-400';

  const handleZipcodeChange = (event) => {
    setZipcode(event.target.value);
  };

  const onApplyZipcode = () => {
    if (zipcode) {
      setIsValidZipcode(true);
      if (ZIPCODE_REGEXP.test(zipcode)) {
        props.onSubmit([{ value: zipcode, label: zipcode }]);
      } else {
        setIsValidZipcode(false);
      }
    }

    return null;
  };

  const onClearZipcode = () => {
    setIsValidZipcode(true);
    setZipcode('');
    props.onSubmit([]);
  };

  useEffect(() => {
    if (!props.currentlyAppliedZipcode) {
      setZipcode('');
    }
  }, [props.currentlyAppliedZipcode]);

  return (
    <div data-testid={props.testID}>
      <div className="flex items-end">
        <div className="flex flex-col flex-1">
          <label htmlFor="zipcode-field" className="filter-label">
            Zipcode
          </label>
          <input
            placeholder="Enter Zipcode"
            className="rounded h-38px text-gray-700 placeholder-gray-700 pl-3 border border-gray-500"
            autoComplete="off"
            id="zipcode-field"
            value={zipcode}
            onChange={handleZipcodeChange}
          />
        </div>
        <div className="flex flex-row ml-3">
          <button
            type="button"
            data-testid={`${props.testID}-clear-btn`}
            disabled={!props.currentlyAppliedZipcode}
            onClick={onClearZipcode}
          >
            <IoIosCloseCircleOutline className={`h-10 w-10${clearBtnColors}`} />
          </button>
          <button
            type="button"
            data-testid={`${props.testID}-apply-btn`}
            disabled={!appliedZipcodeIsDifferent || !zipcode}
            onClick={onApplyZipcode}
          >
            <IoIosCheckmarkCircleOutline className={`h-10 w-10${applyBtnColors}`} />
          </button>
        </div>
      </div>
      {!isValidZipcode && (
        <p className="mt-2 text-error-500 text-xs">Please enter a valid ZIP code in the format XXXXX or XXXXX-XXXX.</p>
      )}
    </div>
  );
}

FilterZipcode.propTypes = {
  currentlyAppliedZipcode: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
};

export default FilterZipcode;
