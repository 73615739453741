import useHttp from '../shared/hooks/use-http';

const useVerify = (dateOfBirth, authenticityToken, accessToken) => {
  const { sendRequest, isLoading, error } = useHttp();
  const handleSubmit = async () => {
    const navigationUrl = await sendRequest(`/external_access/verify/${accessToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken,
      },
      data: {
        dob: dateOfBirth,
      },
    });
    window.location.replace(navigationUrl);
  };

  return {
    handleSubmit,
    isLoading,
    error,
  };
};

export default useVerify;
