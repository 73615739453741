import consumer from '../../../channels/consumer';

const changesMap = {
  delivered(data) {
    return {
      label: `Invite Again (${data.sent_count})`,
      status: 'Delivered less than a minute ago',
    };
  },

  failed(_data) {
    return {
      status: 'Error',
    };
  },

  received(_data) {
    return {
      label: 'One moment...',
    };
  },

  rescheduled(_data) {
    return {
      label: 'Re-Send Email',
      status: 'SMS Scheduled',
    };
  },

  sent(data) {
    return {
      label: `Invite Again (${data.sent_count})`,
      status: 'Sent less than a minute ago',
    };
  },

  undelivered(_data) {
    return {
      status: 'Error',
    };
  },
};

const useInvitesChannel = consumer.subscriptions.create('InvitesChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(_data) {
    // Called when there's incoming data on the websocket for this channel
  },
});

export { useInvitesChannel, changesMap };
