import groupBy from 'lodash/groupBy';
import flattenDeep from 'lodash/flattenDeep';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../helpers/DateFormatter';

export const getFormAnswerGroup = (note) => note.data.createFormAnswerGroup.formAnswerGroup;

export const getNoteIds = (notes) =>
  notes.reduce((prevNote, note) => {
    if (!note) return null;

    return {
      ...prevNote,
      [note.user.id]: note.id,
    };
  }, {});

export const getMembersAnswers = (notes, customModuleId) =>
  notes.reduce((prevNote, note) => {
    if (!note) return null;

    return {
      ...prevNote,
      [note.user.id]: {
        ...note.formAnswers.find((answer) => answer.customModule.id === customModuleId),
        formId: note.id,
      },
    };
  }, {});

const findChartingNoteAnswerById = (chartingNoteAnswers, answerId) =>
  chartingNoteAnswers.find((answer) => answer.id === answerId);

const getUpdatedChartingNoteAnswer = (formAnswer, newNotes) => {
  const updatedAnswer = newNotes.reduce((prevNewNote, newNote) => {
    const currentNoteAnswer = formAnswer[newNote.user.id];
    const newNoteAnswer = findChartingNoteAnswerById(newNote.formAnswers, currentNoteAnswer.id);

    return {
      ...prevNewNote,
      [newNote?.user?.id]: {
        ...currentNoteAnswer,
        ...newNoteAnswer,
        customModule: currentNoteAnswer?.customModule,
        isEdited: currentNoteAnswer?.isEdited || currentNoteAnswer?.answer !== newNoteAnswer.answer,
        lastChartingNoteCreatedAt: currentNoteAnswer?.lastChartingNoteCreatedAt,
      },
    };
  }, {});

  return {
    ...formAnswer,
    ...updatedAnswer,
  };
};

export const getUpdatedChartingNoteAnswers = (selectedNote, newNotes) =>
  selectedNote.formAnswers.map((formAnswer) => getUpdatedChartingNoteAnswer(formAnswer, newNotes));

export const getDateTime = (dateTime) => {
  if (!dateTime) return null;

  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(dateTime);
  return `${timeWithOffset.format(DEFAULT_DATE_FORMAT)}, ${timeWithOffset.format(DEFAULT_TIME_FORMAT)} ${supportedTimeZone.abbrev}`;
};

export const getApplyToAllUpdatedNotes = (memberHealthieMrns, notesToUpdate, answers) =>
  memberHealthieMrns.map((healthieMrn) => ({
    formId: notesToUpdate.ids[healthieMrn],
    formAnswers: notesToUpdate.formAnswers.map((formAnswer) => {
      const memberNoteAnswer = formAnswer[healthieMrn];
      return {
        answer: answers[memberNoteAnswer.customModule.id] || memberNoteAnswer.answer,
        custom_module_id: memberNoteAnswer.customModule.id,
        id: memberNoteAnswer.id,
        label: memberNoteAnswer.label,
      };
    }),
  }));

export const getLockedNoteIds = (notes) => notes.filter((note) => note.lockedAt).map((note) => note.id);

export const getAddendums = (notes) => {
  if (!notes?.length) return [];

  const flattenAddendums = flattenDeep(
    notes.map((note) =>
      note.addendums ? note.addendums?.map((addendum) => ({ ...addendum, chartingNoteId: note.id })) : []
    )
  );

  const groupedAddendums = groupBy(flattenAddendums, 'content');
  const formattedAddendums = [];

  Object.values(groupedAddendums).forEach((addendums) => {
    formattedAddendums.push({
      ...addendums[0],
      addedFor: addendums.map((addendum) => addendum.chartingNoteId),
    });
  });

  return formattedAddendums;
};

export const getCoSigners = (notes) => {
  if (!notes?.length) return [];

  const flattenCoSigners = flattenDeep(
    notes.map((note) =>
      note.coSigners ? note.coSigners?.map((coSigner) => ({ ...coSigner, chartingNoteId: note.id })) : []
    )
  );

  const groupedCoSigners = groupBy(flattenCoSigners, 'signedBy');
  const formattedCoSigners = [];

  Object.values(groupedCoSigners).forEach((coSigners) => {
    formattedCoSigners.push({
      ...coSigners[0],
      coSignedFor: coSigners.map((coSigner) => coSigner.chartingNoteId),
    });
  });

  return formattedCoSigners;
};
