import React from 'react';
import PropTypes from 'prop-types';
import RenderHTML from '../../shared/RenderHTML';
import HipaaNotice from '../HipaaNotice';
import HealthieMatrixReadonly from '../HealthieMatrixReadonly';

function NoteReadModeAnswer({ answer, type }) {
  const answers = {
    read_only: <RenderHTML html={answer} />,
    textarea: <RenderHTML html={answer} />,
    hipaa: <HipaaNotice />,
    matrix: <HealthieMatrixReadonly value={answer} />,
    signature: answer === '-' ? answer : <img src={answer} alt="signature" />,
  };

  return answers[type] || answer;
}

NoteReadModeAnswer.propTypes = {
  answer: PropTypes.string,
  type: PropTypes.string,
};

NoteReadModeAnswer.defaultProps = {
  answer: null,
  type: null,
};

export default NoteReadModeAnswer;
