import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNamesPackage from 'classnames';
import { ThemeProvider } from '@mui/material/styles';
import { TextField, InputAdornment, createTheme } from '@mui/material';
import { Search } from '@mui/icons-material';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            minHeight: '40px',
            font: 'inherit',
            fontSize: '14px',
            borderRadius: '8px',
            color: '#666666',
            '& fieldset': {
              borderColor: '#e0e3e5',
              borderWidth: '2px',
            },
            '&:hover fieldset': {
              borderColor: '#e0e3e5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#237786',
            },
            '&.Mui-focused': {
              outline: '2px solid #237786',
              outlineOffset: '2px',
            },
            '& input::placeholder': {
              color: '#666666',
              opacity: '1',
            },
          },
        },
      },
    },
  },
});

function FilterTextField({ id, name, label, value, onChange, classNames, placeholder }) {
  const valueRef = useRef(null);
  const [valueText, setValueText] = useState('');

  const handleClearInput = useCallback(() => {
    valueRef.current.value = '';

    setValueText('');
    onChange();
  }, [onChange]);

  useEffect(() => {
    setValueText(value);
    if (value === null) {
      handleClearInput();
    }
  }, [value, handleClearInput]);

  const handleChangeConfirmation = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    const newTextValue = event.key === 'Enter' ? event.target.value : valueRef.current.value;

    setValueText(newTextValue);
    onChange(newTextValue);
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={name} className="filter-label">
          {label}
        </label>
      )}
      <ThemeProvider theme={theme}>
        <TextField
          name={name}
          id={id}
          fullWidth
          placeholder={placeholder}
          variant="outlined"
          size="small"
          color="info"
          className={classNamesPackage('bg-white h-7', classNames)}
          value={valueRef?.current?.value || valueText}
          inputRef={valueRef}
          onKeyDown={handleChangeConfirmation}
          onChange={(event) => setValueText(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className="-mr-1" />
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </div>
  );
}

FilterTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  classNames: PropTypes.string,
};

FilterTextField.defaultProps = {
  name: '',
  value: '',
  onChange: () => {},
  label: '',
  placeholder: '',
  classNames: '',
};

export default FilterTextField;
