import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from '../../shared/Button';

function NextButton(props) {
  return (
    <div className="flex mt-6">
      <Button
        isPrimary
        disabled={props.disabled}
        classes={props.classes}
        onClick={() => props.onClick()}
        isLoading={props.isLoading}
      >
        {props.text}
      </Button>
    </div>
  );
}

NextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

NextButton.defaultProps = {
  classes: '',
  disabled: false,
  isLoading: false,
};

export default NextButton;
