import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import last from 'lodash/last';
import GroupsModal from './GroupsModal';
import SelectInput from './shared/SelectInput';
import { FormAnswerGroupPropType } from './documentation/helpers/types';
import addAddendumFormValidationSchema from '../schemas/addAddendum';
import TextareaInput from './shared/TextareaInput';

function AddAddendumModal({ chartingNotes, members, isOpen, onClose, onAddAddendum, isAddAddendumLoading }) {
  const { control, watch, setValue, handleSubmit, register, reset } = useForm({
    defaultValues: {
      members: [],
      templates: [],
      addendum: '',
    },
    resolver: yupResolver(addAddendumFormValidationSchema),
  });

  const formValues = watch();
  const allOption = useMemo(() => ({ label: 'All members', value: 'all' }), []);
  const memberOptions = members.length > 1 ? [allOption, ...members] : members;
  const templateOptions = chartingNotes.map((template) => ({ value: template.id, label: template.name }));

  useEffect(() => {
    if (members.length === 1) {
      // Set dropdown's default value if there is only one member
      setValue('members', members);
    }

    if (members.length > 1) {
      // If there is more than one member set default value to 'All members'
      setValue('members', [allOption]);
    }

    if (chartingNotes.length === 1) {
      // Set dropdown's default value if there is only one charting note
      setValue('templates', [{ label: chartingNotes[0].name, value: chartingNotes[0].id }]);
    }
  }, [members, chartingNotes, setValue, allOption]);

  useEffect(() => {
    const moreThanOneMemberSelected = formValues.members?.length > 1;
    const isAllSelected = formValues.members?.some((member) => member.value === allOption.value);
    const isLastEqualToAll = last(formValues.members)?.value === allOption.value;

    if (moreThanOneMemberSelected && isLastEqualToAll) {
      setValue('members', [allOption]);
    } else if (moreThanOneMemberSelected && isAllSelected) {
      setValue(
        'members',
        formValues.members.filter((member) => member.value !== allOption.value)
      );
    }
  }, [allOption, chartingNotes, formValues.members, setValue]);

  const onSubmit = async (values) => {
    await onAddAddendum(values);
    reset();
  };

  return (
    <GroupsModal
      testID="add-addendum-modal"
      isOpen={isOpen}
      header="Add Addendum"
      subHeader="Select templates to add an addendum to."
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: () => {
            reset();
            onClose();
          },
        },
        {
          type: 'submit',
          label: 'Save',
          isPrimary: true,
          isLoading: isAddAddendumLoading,
          form: 'add-addendum-form',
        },
      ]}
      size="medium"
      isLoading={isAddAddendumLoading}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <form id="add-addendum-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4 border-b">
            <div data-testid="co-sign-author-name">
              <Controller
                name="members"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <SelectInput
                      {...field}
                      isMulti
                      label="Member(s)"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Select member(s)"
                      options={memberOptions}
                      register={{ ...register(field.name) }}
                      required
                    />
                    {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error?.message}</p>}
                  </>
                )}
              />
            </div>
            <div className="mt-2">
              <Controller
                name="templates"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <SelectInput
                      {...field}
                      isMulti
                      label="Templates"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Select templates"
                      options={templateOptions}
                      register={{ ...register(field.name) }}
                      required
                    />
                    {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error?.message}</p>}
                  </>
                )}
              />
            </div>
          </div>
          <div className="p-4">
            <Controller
              name="addendum"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextareaInput
                    {...field}
                    label="Addendum"
                    name={field.name}
                    rows="4"
                    value={field.value}
                    onChange={field.onChange}
                    register={{ ...register(field.name) }}
                    required
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error?.message}</p>}
                </>
              )}
            />
          </div>
        </form>
      </div>
    </GroupsModal>
  );
}

AddAddendumModal.propTypes = {
  chartingNotes: PropTypes.arrayOf(FormAnswerGroupPropType),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAddAddendumLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddAddendum: PropTypes.func.isRequired,
};

AddAddendumModal.defaultProps = {
  chartingNotes: [],
};

export default AddAddendumModal;
