import React from 'react';
import PropTypes from 'prop-types';

function Header(props) {
  const underlineClass = props?.withNoUnderline ? '' : 'hover:underline';
  const bottomMarign = props?.withBottomMargin ? 'mb-4' : '';

  function renderBody() {
    return (
      <div className={`flex-1 ${bottomMarign}`}>
        <h3
          className={`inline font-body font-semibold leading-2 ${props.class} ${underlineClass}`}
          style={props?.style}
        >
          {props.text}
        </h3>

        {props.subtext && <span className="text-sm font-light text-gray-700">{props.subtext}</span>}
      </div>
    );
  }

  function render() {
    if (props?.link) {
      return (
        <a href={props.link} target="_blank" rel="noreferrer">
          {renderBody()}
        </a>
      );
    }
    return <>{renderBody()}</>;
  }

  return <>{render()}</>;
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  class: PropTypes.string,
  style: PropTypes.object,
  withBottomMargin: PropTypes.bool,
  withNoUnderline: PropTypes.bool,
  link: PropTypes.string,
};

Header.defaultProps = {
  subtext: null,
  class: '',
  style: {},
  withBottomMargin: false,
  withNoUnderline: false,
  link: null,
};

export default Header;
