import React from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { dayAbbrevMonthAbbrevDate, hourMinTimezone } from '../../helpers/DateFormatter';

function TimeCell(props) {
  const { data } = props;

  return (
    <td>
      <div className="m-4 leading-tight">
        <p className="my-1">{dayAbbrevMonthAbbrevDate(data)}</p>
        <p className="text-sm text-gray-700 my-1">{hourMinTimezone(data)}</p>
      </div>
    </td>
  );
}

TimeCell.propTypes = {
  data: PropTypes.string.isRequired,
};

export default TimeCell;
