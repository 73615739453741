import { useEffect, useState } from 'react';

const useCachedPickupData = () => {
  const [pickup, setPickup] = useState({});

  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const fetchCachedPickupData = async () => {
    setPickup(parseData('pickup') || {});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchCachedPickupData();
  }, []);

  return { pickup };
};

export default useCachedPickupData;
