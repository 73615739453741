import React from 'react';
import PropTypes from 'prop-types';
import GeneralCell from './GeneralCell';
import StatusIndicator from './empanelment/appointments/shared/StatusIndicator';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';
import { DEFAULT_DATETIME_FORMAT } from '../helpers/DateFormatter';

function UdsStatusCell({ testID, uds: { status, datetime } }) {
  const [convertedDate, timezone] = convertToSupportedTimeZone(datetime);

  return (
    <GeneralCell
      testID={testID}
      data={
        <div className="flex items-center gap-3">
          <StatusIndicator status={status} />
          <div className="flex flex-col">
            <span>{status ? 'Submitted' : 'Not Submitted'}</span>
            {datetime && (
              <span className="text-sm text-gray-700">{`${convertedDate.format(DEFAULT_DATETIME_FORMAT)} ${timezone.abbrev}`}</span>
            )}
          </div>
        </div>
      }
    />
  );
}

UdsStatusCell.propTypes = {
  testID: PropTypes.string,
  uds: PropTypes.shape({
    status: PropTypes.bool,
    datetime: PropTypes.string,
  }).isRequired,
};

UdsStatusCell.defaultProps = {
  testID: 'udsStatusCell',
};

export default UdsStatusCell;
