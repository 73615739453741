import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DetailsRow from '../DetailsRow';
import Button from '../../../shared/Button';
import useHttp from '../../../shared/hooks/use-http';
import useToasts from '../../../shared/hooks/use-toasts';
import Toasts from '../../../shared/Toasts';
import { MemberPropTypes } from '../types';
import { mdyyyy } from '../../../../helpers/DateFormatter';

export const ACCOUNT_ACTIVATION_STATUS = {
  ACCOUNT_CREATED: 'account_created',
  INVITATION_SENT: 'invitation_sent',
  ACCOUNT_NOT_CREATED: 'account_not_created',
};

function AccountActivationRow({ accountActivation, member, authenticityToken }) {
  const { sendRequest, isLoading, error } = useHttp();
  const { toasts, removeToast, addToast } = useToasts();

  const sendInvite = async () => {
    try {
      const response = await sendRequest('/invites/send_invite', {
        method: 'POST',
        data: {
          post: {
            member_id: member?.id,
          },
        },
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
      });

      if (error) {
        addToast({ type: 'error', header: response?.error });
      } else {
        addToast({
          header: 'Invite sent successfully',
          type: 'success',
        });
      }
    } catch (e) {
      addToast({ type: 'error', header: e?.message ?? 'Something went wrong' });
    }
  };

  const renderIcon = () => {
    switch (accountActivation?.status) {
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_CREATED:
        return <CheckCircleOutlineIcon className="react-status-icon text-positive-700" />;
      case ACCOUNT_ACTIVATION_STATUS.INVITATION_SENT:
        return <PendingIcon className="react-status-icon text-blue-600" />;
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_NOT_CREATED:
        return <ErrorOutlineIcon className="react-status-icon text-gray-700" />;
      default:
        return null;
    }
  };

  const renderActionButton = () => {
    if (!accountActivation?.status || accountActivation?.status === ACCOUNT_ACTIVATION_STATUS.ACCOUNT_CREATED) {
      return null;
    }

    return (
      <Button classes="border-none -my-2" isLoading={isLoading} onClick={sendInvite}>
        {accountActivation.status === ACCOUNT_ACTIVATION_STATUS.INVITATION_SENT ? 'Re-send invite' : 'Invite'}
      </Button>
    );
  };

  const generateStatusClass = () => {
    switch (accountActivation?.status) {
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_CREATED:
        return 'text-positive-700';
      case ACCOUNT_ACTIVATION_STATUS.INVITATION_SENT:
        return 'text-blue-600';
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_NOT_CREATED:
        return 'text-gray-700';
      default:
        return null;
    }
  };

  const generateStatusText = () => {
    switch (accountActivation?.status) {
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_CREATED:
        return 'Account Created';
      case ACCOUNT_ACTIVATION_STATUS.INVITATION_SENT:
        return 'Invitation Sent';
      case ACCOUNT_ACTIVATION_STATUS.ACCOUNT_NOT_CREATED:
        return 'Account Not Created';
      default:
        return null;
    }
  };

  return (
    <>
      <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" />

      <DetailsRow label="Account Activation">
        {accountActivation ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {renderIcon()}
              <div className={`ml-1 whitespace-nowrap font-bold ${generateStatusClass()}`}>{generateStatusText()}</div>
              {accountActivation?.date && (
                <span className="ml-2 text-sm text-gray-700">{`(${mdyyyy(accountActivation.date)})`}</span>
              )}
            </div>

            {renderActionButton()}
          </div>
        ) : (
          <span className="italic">No Account Activation detected</span>
        )}
      </DetailsRow>
    </>
  );
}

AccountActivationRow.propTypes = {
  member: MemberPropTypes.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  accountActivation: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(ACCOUNT_ACTIVATION_STATUS)).isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccountActivationRow;
