import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';

// SHARED COMPONENTS
import Button from '../../shared/Button';
import Header from '../../shared/Header';
import PageHeader from '../../shared/PageHeader';
import PageContainer from '../../shared/PageContainer';
import ServerError from '../../shared/ServerError';

// PAGE COMPONENTS
import CancelModal from '../shared/CancelModal';
import ShipmentLabelSidebar from './ShipmentLabelSidebar';
import ShipmentAddresses from './ShipmentAddresses';
import ShipmentContents from './ShipmentContents';
import ConfirmationModal from '../shared/ConfirmationModal';
import ShipmentSummary from '../shared/ShipmentSummary';
import PickupShipmentModal from '../shared/PickupShipmentModal';
import PickupSummaryModal from '../shared/PickupSummaryModal';

// HOOKS
import useCachedLabelData from '../hooks/use-cached-label-data';
import useCachedSummaryData from '../hooks/use-cached-summary-data';
import useCachedShipmentData from '../hooks/use-cached-shipment-data';
import useClearCachedShipmentData from '../hooks/use-clear-cached-shipment-data';
import useFormatSaveShipmentsData from '../hooks/use-format-save-shipments-data';

// HELPERS
import { cancelButtonStyle, containerStyle, deleteLabelStyle } from '../shared/styles';

function ShipmentPage(props) {
  // CACHED LABELS
  const { cachedLabels, _cachedLabelIds, fetchCachedLabelIds } = useCachedLabelData();
  const { fetchSaveData } = useFormatSaveShipmentsData();

  // LABELS
  const [labels, setLabels] = useState([{ labelName: 1 }]);
  const [selectedLabel, setSelectedLabel] = useState(labels[0]);
  useEffect(() => {
    if (cachedLabels) {
      setLabels(cachedLabels);
      setSelectedLabel(cachedLabels[0]);
    }
  }, [cachedLabels]);

  // EDIT
  const [updateCache, setUpdateCache] = useState(false);

  // CACHED DATA
  const { fetchCachedSummaryData } = useCachedSummaryData();
  const { labelAddress, labelContents, labelCompleted, fetchCachedShipmentData } = useCachedShipmentData(
    selectedLabel?.labelName
  );

  // CONFIRMATION DATA
  const [confirmationSum, setConfirmationSum] = useState({});
  useEffect(() => {
    const summary = fetchCachedSummaryData(selectedLabel?.labelName, labelAddress, labelContents);
    setConfirmationSum({ ...summary });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelAddress, labelContents, labelCompleted, ...Object.values(selectedLabel)]);

  // HELPERS
  const [pickupSummary, setPickupSummary] = useState({});
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [showPickupSummary, setShowPickupSummary] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showWarningModalCancelOrder, setShowWarningModalCancelOrder] = useState(false);
  const [showWarningModalCancelLabel, setShowWarningModalCancelLabel] = useState(false);
  const [labelPurchaseError, setLabelPurchaseError] = useState(null);
  const [fedexPickupError, setFedexPickupError] = useState(null);
  const [fedexPickupErrorMessage, setFedexPickupErrorMessage] = useState(null);
  const [confirmLabelLoading, setConfirmLabelLoading] = useState(false);
  const [printLabelLoading, setPrintLabelLoading] = useState(false);

  // CACHING
  const { clearCachedShipmentData, clearCachedLabelData } = useClearCachedShipmentData();

  // ****** CANCEL ORDER ******
  const cancelOrderClick = () => {
    setShowWarningModalCancelOrder(true);
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'open-cancel-shipment-order-warning-modal');
  };

  const onBackClickOrder = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'cancel-clear-shipment-order-form');
    setShowWarningModalCancelOrder(false);
  };

  const onConfirmCancelOrder = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'confirm-clear-shipment-order-form');
    clearCachedShipmentData(labels);
    props.setShowShipPage();
  };

  // ****** CANCEL LABEL ******
  const cancelLabelClick = () => {
    setShowWarningModalCancelLabel(true);
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'open-cancel-shipment-label-warning-modal');
  };

  const onBackClickLabel = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'cancel-clear-shipment-label-form');
    setShowWarningModalCancelLabel(false);
  };

  const onConfirmCancelLabel = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'confirm-clear-shipment-label-form');

    const filtered = labels.filter((el) => el.labelName !== selectedLabel?.labelName);

    setLabels([...filtered]);
    clearCachedLabelData(selectedLabel);
    setSelectedLabel({ ...filtered[0] });
    setUpdateCache(!updateCache);

    window.localStorage.setItem('shipment-labels', JSON.stringify(filtered));

    setShowWarningModalCancelLabel(false);
  };

  // ****** CONFIRM LABEL ******
  const updateSummaryData = () => {
    fetchCachedShipmentData(selectedLabel?.labelName);

    const summary = fetchCachedSummaryData(selectedLabel?.labelName, labelAddress, labelContents);
    setConfirmationSum({ ...summary });
  };

  const onShowConfirmationModal = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'show-confirmation-modal-shipment-form');

    updateSummaryData();
    setShowConfirmationModal(true);
  };

  const onBackConfirmationClick = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'cancel-confirm-shipment-form');
    setShowConfirmationModal(false);
  };

  // ********* EDIT BUTTON LOGIC *******
  const onShowAddressSection = () => {
    setShowConfirmationModal(false);

    labelAddress.isComplete = false;
    window.localStorage.setItem(`shipment-addresses-${selectedLabel?.labelName}`, JSON.stringify(labelAddress));

    selectedLabel.labelAddress = labelAddress;
    setSelectedLabel({ ...selectedLabel });

    setUpdateCache(!updateCache);
  };

  const onShowContentsSection = () => {
    setShowConfirmationModal(false);

    labelContents.isComplete = false;
    window.localStorage.setItem(`shipment-contents-${selectedLabel?.labelName}`, JSON.stringify(labelContents));

    selectedLabel.labelContents = labelContents;
    setSelectedLabel({ ...selectedLabel });

    setUpdateCache(!updateCache);
  };

  // *******************************************
  // PURCHASE LABEL & CREATE UDS SUPPLY RECORDS
  // *******************************************
  const onConfirmLabel = async () => {
    setConfirmLabelLoading(true);
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'buy-label-shipment-form');

    const data = fetchSaveData(selectedLabel?.labelName, labelAddress, labelContents);
    axios
      .post('/staff/uds_supply/create_shipment', { ...data })
      .then((response) => {
        setShowConfirmationModal(false);
        setConfirmLabelLoading(false);
        labelContents.isComplete = true;

        window.localStorage.setItem(`shipment-contents-${selectedLabel?.labelName}`, JSON.stringify(labelContents));
        window.localStorage.setItem(`shipment-${selectedLabel?.labelName}-completed`, JSON.stringify(true));

        fetchCachedShipmentData(selectedLabel?.labelName);

        setUpdateCache(!updateCache);

        const labelId = response.data.label_id;

        fetchCachedLabelIds().then((result) => {
          const fetchedLabelIds = result;
          fetchedLabelIds[selectedLabel.labelName] = labelId;
          window.localStorage.setItem('shipment-label-ids', JSON.stringify(fetchedLabelIds));
        });
      })
      .catch((error) => {
        setLabelPurchaseError(error);
        setConfirmLabelLoading(false);
        setShowConfirmationModal(false);
        window.Sentry.captureException(error);
      });
  };

  // *****************************
  // PICKUP DROPOFF & FINALIZE FLOW
  // *****************************
  const onFinalizeLabel = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'open-pickup-dropoff-form');
    setShowPickupModal(true);
  };

  const onBackPickupModalClick = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'close-pickup-dropoff-form');
    setShowPickupModal(false);
  };

  const openPrintDialog = (url) => {
    // RETURN TO MAIN PAGE
    setShowPickupSummary(false);
    props.setShowShipPage();

    // OPEN PRINT LABEL DIALOG
    const newWindow = window.open(url, '_blank');
    newWindow.onload = () => {
      newWindow.print();
    };

    // CLEAR LABELS
    clearCachedShipmentData(labels);
  };

  const setPdf = async () => {
    const fetchedLabelIds = await fetchCachedLabelIds();

    const label_ids = Object.values(fetchedLabelIds);
    const params = { label_ids };

    axios
      .get('/staff/uds_supply/compile_pdf', { params })
      .then((response) => {
        const data = Buffer.from(response.data.pdf_data, 'base64');
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        openPrintDialog(url);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  const savePickupInformation = (method) => {
    setPrintLabelLoading(true);
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const { pickupDate, readyTime, latestTime, pickupInstructions, location } = method || {};

    const params = {
      pickup_date: pickupDate?.toString(),
      ready_time: readyTime?.$d?.toString(),
      latest_time: latestTime?.$d?.toString(),
      pickup_instructions: pickupInstructions,
      location_id: location?.value,
    };

    axios
      .post('/staff/fedex_pickups', { ...params })
      .then((_response) => {
        setShowPickupModal(false);
        setPickupSummary({ ...method });
        setShowPickupSummary(true);
        setPrintLabelLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.error === 'Has pickup scheduled') {
          setPickupSummary({ isPreviouslyScheduled: true, ...method });
          setShowPickupModal(false);
          setShowPickupSummary(true);
        }
        setFedexPickupError(true);
        setFedexPickupErrorMessage(error?.response?.data?.error);
        setPrintLabelLoading(false);
        window.Sentry.captureException(error);
      });
  };

  const onConfirmPickupModalClick = (method) => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('uds-screening-supplies', 'confirm-pickup-dropoff-form');

    if (method?.selectedMethod?.value === 'pickup') {
      // CREATE PICKUP RECORD
      savePickupInformation(method);
    } else {
      setShowPickupModal(false);
      // SELECTED "DROPOFF" OPTION
      setPdf();
    }
  };

  return (
    <>
      {labelPurchaseError !== null && (
        <ServerError error={labelPurchaseError.code} message={labelPurchaseError.message} />
      )}
      <PageHeader text="Ship New Supply" breadcrumbText="Screening Supplies" breadcrumbLink="uds_supply">
        {labels.length === 1 && !labelCompleted && (
          <Button onClick={cancelOrderClick} style={cancelButtonStyle}>
            Cancel Order
          </Button>
        )}
      </PageHeader>

      <div className="flex">
        <ShipmentLabelSidebar
          labels={labels}
          selectedLabel={selectedLabel}
          labelAddress={labelAddress}
          showNextButtons={labelCompleted}
          updateCache={updateCache}
          onAddLabel={(updatedLabels, currentLabel) => {
            setLabels([...updatedLabels]);
            setSelectedLabel({ ...currentLabel });

            setUpdateCache(!updateCache);

            window.localStorage.setItem('shipment-labels', JSON.stringify(updatedLabels));
          }}
          onSelectLabel={(label) => {
            setConfirmationSum({});
            setSelectedLabel({ ...label });

            setUpdateCache(!updateCache);
            updateSummaryData();
          }}
          onFinalizeLabel={onFinalizeLabel}
        />

        {/* LABEL INFORMATION */}
        <div className="flex-2">
          <PageContainer style={{ ...containerStyle, marginTop: 0 }}>
            {labels.length > 1 && !labelCompleted && (
              <button type="button" className="float-right" onClick={cancelLabelClick} style={deleteLabelStyle}>
                Delete Label
              </button>
            )}

            <ShipmentAddresses
              locations={props.locations}
              updateCache={updateCache}
              selectedLabel={selectedLabel}
              onSetData={(address) => {
                selectedLabel.labelAddress = address;
                setSelectedLabel({ ...selectedLabel });
                updateSummaryData();
              }}
            />

            <ShipmentContents
              updateCache={updateCache}
              selectedLabel={selectedLabel}
              onSetData={(contents) => {
                selectedLabel.labelContents = contents;
                setSelectedLabel({ ...selectedLabel });
                updateSummaryData();
              }}
              onPurchaseLabel={onShowConfirmationModal}
              isRegionalDirector={props.isRegionalDirector}
            />

            {/* SUMMARY */}
            {labelCompleted && <Header text="VIEW SUMMARY" withBottomMargin withNoUnderline />}

            <ShipmentSummary
              show={confirmationSum?.labelAddressIsComplete}
              showEdit={!labelCompleted}
              header="SHIP FROM"
              data={confirmationSum.shipFrom}
              onEditClick={() => onShowAddressSection()}
            />
            <ShipmentSummary
              show={confirmationSum?.labelAddressIsComplete}
              showEdit={!labelCompleted}
              header="DELIVER TO"
              data={confirmationSum.deliverTo}
              onEditClick={() => onShowAddressSection()}
            />

            <ShipmentSummary
              members={labelAddress?.members}
              show={labelCompleted}
              showEdit={!labelCompleted}
              header="PACKAGE DETAILS"
              data={confirmationSum.packageDetails}
              onEditClick={onShowContentsSection}
            />

            <ShipmentSummary
              show={labelCompleted}
              showEdit={!labelCompleted}
              header="SERVICE"
              data={confirmationSum.service}
              onEditClick={onShowContentsSection}
            />
          </PageContainer>
        </div>
      </div>

      {/* CANCEL ORDER */}
      <CancelModal
        isOpen={showWarningModalCancelOrder}
        mainText="You are confirming to cancel this order and lose your saved progress."
        onBack={onBackClickOrder}
        onConfirm={onConfirmCancelOrder}
      />

      {/* CANCEL LABEL */}
      <CancelModal
        isOpen={showWarningModalCancelLabel}
        mainText="You are confirming to cancel this order and lose your saved progress."
        onBack={onBackClickLabel}
        onConfirm={onConfirmCancelLabel}
      />

      {/* PURCHASE LABEL CONFIRMATION SUMMARY */}
      <ConfirmationModal
        isOpen={showConfirmationModal}
        summary={confirmationSum}
        onBack={onBackConfirmationClick}
        onConfirm={onConfirmLabel}
        onShowAddressSection={onShowAddressSection}
        onShowContentsSection={onShowContentsSection}
        confirmLabelLoading={confirmLabelLoading}
        members={labelAddress?.members}
      />

      {/* FINALIZE & PRINT BUTTON CLICK = OPENS PICKUP MODAL */}
      <PickupShipmentModal
        isOpen={showPickupModal}
        onBack={onBackPickupModalClick}
        onConfirm={onConfirmPickupModalClick}
        locations={props.locations}
        fedexPickupError={fedexPickupError}
        fedexPickupErrorMessage={fedexPickupErrorMessage}
        printLabelLoading={printLabelLoading}
      />

      <PickupSummaryModal isOpen={showPickupSummary} pickupSummary={pickupSummary} onConfirm={setPdf} />
    </>
  );
}

ShipmentPage.propTypes = {
  locations: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  setShowShipPage: PropTypes.func.isRequired,
  isRegionalDirector: PropTypes.bool.isRequired,
};

export default ShipmentPage;
