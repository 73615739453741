/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InformationContainer from './InformationContainer';
import AttendanceLabel from './AttendanceLabel';
import AttendanceData from './AttendanceData';

function AttendanceWeekInformation(props) {
  const { label, weeks } = props?.data || {};

  return (
    <InformationContainer>
      <AttendanceLabel classes="pt-2 pl-2" label={`Week of ${label}`} />

      {weeks?.map((week, index) => (
        <AttendanceData data={week} showBottomBorder={index < weeks.length - 1} />
      ))}
    </InformationContainer>
  );
}

AttendanceWeekInformation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AttendanceWeekInformation;
