import moment from 'moment';

export const getStatusColor = (status) => {
  if (!status) return 'text-gray-600';

  switch (status) {
    case 'no-show':
    case 'cancelled':
      return 'text-error-700';
    default:
      return '';
  }
};

export const getStatusLabel = (status) => {
  if (!status) return null;

  switch (status) {
    case 'no-show':
      return 'No-Show';
    case 'cancelled':
      return 'Cancelled';
    case 'attended':
      return 'Attended';
    case 'rescheduled':
      return 'Rescheduled';
    case 'pending':
      return 'Pending';
    case 'occurred':
      return 'Occurred';
    default:
      return status;
  }
};

export const getDateDistance = (date) => moment(date).fromNow();

export const formatLocationLabel = (locationKey, fallback) => {
  switch (locationKey) {
    case 'location':
      return 'Primary Address';
    case 'default_facility':
      return 'Default Location';
    case 'empanelment_group':
      return 'Empanelment Group Location';
    case 'uds_pickup_facility':
      return 'UDS Pickup Location';
    default:
      return fallback;
  }
};

export const filterOptionsByPrefix = (options, prefix) => options?.filter((option) => option.label.startsWith(prefix));
