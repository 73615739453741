import React, { Fragment } from 'react';
import { TbCalendarEvent } from 'react-icons/tb';
import { MdOutlineEditNote } from 'react-icons/md';
import moment from 'moment';
import ColoredLabel from './ColoredLabel';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT } from '../../helpers/DateFormatter';
import { AppointmentAttendancePropTypes } from './types';

const ICON_CLASS_NAME = 'w-6 h-6 text-teal-700';

const getLabel = ({ label, type, isBackground }) => (
  <ColoredLabel label={label} type={type} isBackground={isBackground} />
);

function AttendanceAppointment({ dateTime, attendanceStatus, provider, screenings }) {
  const [dateTimeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(dateTime);

  const formatDateTime = (format) =>
    screenings ? moment(dateTime).utcOffset(0, true).format(format) : dateTimeWithOffset.format(format);

  const uds = screenings && (
    <>
      UDS:&nbsp;
      {screenings.uds.otherSubstances && screenings.uds.bup ? (
        <>
          {screenings.uds.otherSubstances.map(({ label, type }) => (
            <Fragment key={label}>{getLabel({ label: `${label},`, type })}&nbsp;</Fragment>
          ))}
          Bup&nbsp;{getLabel(screenings?.uds?.bup)}
        </>
      ) : (
        getLabel(screenings?.uds)
      )}
    </>
  );
  const appointment = attendanceStatus && (
    <>Appointment:&nbsp;{getLabel({ ...attendanceStatus, isBackground: true })}</>
  );

  return (
    <div className="p-2 rounded-lg even:bg-blue-100">
      <time className="flex items-center gap-2 mb-1 text-gray-700" dateTime={formatDateTime()}>
        <span className="text-sm">{formatDateTime('M/D/YYYY')}</span>
        {!screenings && (
          <span className="text-xs">{`${dateTimeWithOffset.format(DEFAULT_TIME_FORMAT)} ${supportedTimeZone.abbrev}`}</span>
        )}
      </time>
      <div className="flex items-center gap-2">
        <div className="flex items-center justify-center rounded-lg p-1.5 bg-green-100">
          {screenings ? (
            <MdOutlineEditNote className={ICON_CLASS_NAME} />
          ) : (
            <TbCalendarEvent className={ICON_CLASS_NAME} />
          )}
        </div>
        <div className="flex-1 flex items-center flex-wrap">
          {uds}
          {appointment}
        </div>
        <div className="flex-1">{screenings ? <>FENT: {getLabel(screenings?.fent)}</> : provider}</div>
      </div>
    </div>
  );
}

AttendanceAppointment.propTypes = AppointmentAttendancePropTypes;

export default AttendanceAppointment;
