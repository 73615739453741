import React from 'react';
import PropTypes from 'prop-types';
import AccountInformation from './AccountInformation';
import InsuranceInformation from './InsuranceInformation';
import PersonalInformation from './PersonalInformation';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import { ACCOUNT_ACTIVATION_STATUS } from './rows/AccountActivationRow';
import { FacilityPropTypes, LanguagePropTypes } from './types';

function Main({
  authenticityToken,
  accountInformation,
  personalInformation,
  insurance,
  allFacilities,
  allLanguages,
  member,
}) {
  const testID = 'memberDetailsMainEmp';

  const transformedMember = convertObjKeysToCamelCase(member);

  const shouldRenderSections = accountInformation || personalInformation || insurance;

  return (
    <section data-testid={testID} className="flex flex-col text-black pb-12">
      {!shouldRenderSections && (
        <div className="mt-24 text-black">
          <p className="text-center">No member details available.</p>
        </div>
      )}

      {shouldRenderSections && (
        <div className="flex flex-col gap-9">
          {accountInformation && (
            <AccountInformation
              data={convertObjKeysToCamelCase(accountInformation)}
              member={transformedMember}
              authenticityToken={authenticityToken}
            />
          )}
          {personalInformation && (
            <PersonalInformation
              data={convertObjKeysToCamelCase(personalInformation)}
              member={transformedMember}
              authenticityToken={authenticityToken}
              allFacilities={allFacilities}
              allLanguages={allLanguages}
            />
          )}
          {insurance && <InsuranceInformation data={convertObjKeysToCamelCase(insurance)} member={transformedMember} />}
        </div>
      )}
    </section>
  );
}

Main.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  authenticityToken: PropTypes.string.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dob: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  allLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  allFacilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  accountInformation: PropTypes.shape({
    username: PropTypes.string.isRequired,
    device_info: PropTypes.string.isRequired,
    app_info: PropTypes.shape({
      version: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      is_latest: PropTypes.bool.isRequired,
    }).isRequired,
    last_login: PropTypes.string.isRequired,
    account_activation: PropTypes.shape({
      status: PropTypes.oneOf(Object.values(ACCOUNT_ACTIVATION_STATUS)).isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  }),
  personalInformation: PropTypes.shape({
    social_security_number: PropTypes.string.isRequired,
    is_deceased: PropTypes.bool.isRequired,
    primary_language: LanguagePropTypes.isRequired,
    language_interpretive_services: PropTypes.bool.isRequired,
    default_facility: FacilityPropTypes,
    photo: PropTypes.shape({
      datetime: PropTypes.string.isRequired,
      front_url: PropTypes.string.isRequired,
      back_url: PropTypes.string,
    }).isRequired,
  }),
  insurance: PropTypes.shape({
    primary_insurance: PropTypes.string.isRequired,
    primary_subscriber_id: PropTypes.string.isRequired,
    secondary_insurance: PropTypes.string.isRequired,
    secondary_subscriber_id: PropTypes.string.isRequired,
    insurance_card: PropTypes.shape({
      datetime: PropTypes.string.isRequired,
      front_url: PropTypes.string.isRequired,
      back_url: PropTypes.string,
    }).isRequired,
  }),
};

Main.defaultProps = {
  accountInformation: null,
  personalInformation: null,
  insurance: null,
};

export default Main;
