import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../shared/InputWrapper';
import Input from '../shared/Input';
import SimpleTable from '../shared/SimpleTable';
import { getParsedValue, getStringifiedRows } from './helpers/healthieMatrixHelpers';

const HealthieMatrixInput = forwardRef(
  ({ label, name, value, onBlur, onChange, error, disabled, required, isVertical }, ref) => {
    const parsedValue = getParsedValue(value);

    if (!parsedValue) return null;

    const handleInputChange = (e) => {
      const { name: inputName, value: inputValue, checked, type } = e.target;
      const [_, rowItemId, rowId] = inputName.split('-');

      const updatedRows = parsedValue.rows?.map((row) => ({
        ...row,
        items: row.items.map((rowItem) =>
          rowItem.id === parseInt(rowItemId, 10) && row.id === parseInt(rowId, 10)
            ? {
                ...rowItem,
                value: type === 'checkbox' ? checked : inputValue,
              }
            : rowItem
        ),
      }));

      onChange(JSON.stringify([JSON.parse(value)[0], ...getStringifiedRows(updatedRows)]));
    };

    return (
      <InputWrapper
        label={label}
        name={`${name}-1-0`}
        required={required}
        error={error}
        isVertical={isVertical}
        isOverflowAuto
        testId="healthie-matrix-input"
      >
        <SimpleTable headers={parsedValue.headers} tableClassName="table-fixed">
          {parsedValue.rows?.map((row) => (
            <tr key={row.id}>
              {row.items?.map((rowItem) => (
                <td key={rowItem.id} className="whitespace-normal">
                  {rowItem.id !== 0 ? (
                    <Input
                      ref={ref}
                      id={`${name}-${rowItem.id}-${row.id}`}
                      name={`${name}-${rowItem.id}-${row.id}`}
                      value={rowItem.type === 'checkbox' ? `${name}-${rowItem.id}-${row.id}` : rowItem.value}
                      onChange={handleInputChange}
                      onBlur={onBlur}
                      checked={rowItem.type === 'checkbox' && !!rowItem.value}
                      type={rowItem.type}
                      required={required}
                      disabled={disabled}
                    />
                  ) : (
                    rowItem.value
                  )}
                </td>
              ))}
            </tr>
          ))}
        </SimpleTable>
        <input name={name} type="hidden" value={value} />
      </InputWrapper>
    );
  }
);

HealthieMatrixInput.displayName = 'HealthieMatrixInput';

HealthieMatrixInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
};

HealthieMatrixInput.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  isVertical: true,
  error: null,
};

export default HealthieMatrixInput;
