/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// import { TbCircleCheck } from 'react-icons/tb';
// import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
// import { AiOutlineCloseCircle, AiOutlineExclamationCircle } from 'react-icons/ai';

import {
  PiXCircleBold,
  PiCheckCircleBold,
  PiWarningBold,
  PiWarningCircleBold,
  PiCircleDashedBold,
  PiPrinter,
} from 'react-icons/pi';

function IconRender(props) {
  const baseClass = 'w-5 h-5 mr-1';

  const successClass = 'text-positive-700';
  const warningClass = 'text-warning-500';
  const errorClass = 'text-error-700';

  function renderCheckOrXIcon() {
    if (props.showTriangleIcon || props.showExclamationIcon || props.showCircleDashed || props.showPrinter) {
      return <></>;
    }

    if (props.showCheckIcon) {
      return <PiCheckCircleBold className={`${baseClass} ${successClass}`} />;
    }
    return <PiXCircleBold className={`${baseClass} ${errorClass}`} />;
  }

  function renderOtherIcon() {
    if (props.showTriangleIcon) {
      return <PiWarningBold className={`${baseClass} ${warningClass}`} />;
    }
    if (props.showExclamationIcon) {
      return <PiWarningCircleBold className={`${baseClass} ${errorClass}`} />;
    }
    if (props.showCircleDashed) {
      return <PiCircleDashedBold className={`${baseClass}`} />;
    }
    if (props.showPrinter) {
      return <PiPrinter className={`${baseClass}`} />;
    }

    return <></>;
  }

  return (
    <>
      {renderCheckOrXIcon()}

      {renderOtherIcon()}
    </>
  );
}

IconRender.propTypes = {
  showCheckIcon: PropTypes.bool,

  // OTHER ICONS
  showTriangleIcon: PropTypes.bool,
  showExclamationIcon: PropTypes.bool,
  showCircleDashed: PropTypes.bool,
  showPrinter: PropTypes.bool,
};

IconRender.defaultProps = {
  showCheckIcon: null,

  // OTHER ICONS
  showTriangleIcon: false,
  showExclamationIcon: false,
  showCircleDashed: false,
  showPrinter: false,
};

export default IconRender;
