import React from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import CodeBlock from './CodeBlock';

function ComponentExampleBlock(props) {
  const centerClass = props?.center ? 'text-center' : '';
  const style = props?.styledBackground
    ? {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      }
    : null;

  return (
    <div className="flex-1 m-2">
      {props?.label && <h4 className="my-4">{props?.label}</h4>}

      {props.children && (
        <div className={`mb-2 ${centerClass}`} style={style}>
          {props.children}
        </div>
      )}

      <CodeBlock text={props.codeText} />
    </div>
  );
}

ComponentExampleBlock.propTypes = {
  children: PropTypes.node.isRequired,
  codeText: PropTypes.string.isRequired,
  label: PropTypes.string,
  center: PropTypes.bool,
  styledBackground: PropTypes.bool,
};

ComponentExampleBlock.defaultProps = {
  label: null,
  center: false,
  styledBackground: true,
};

export default ComponentExampleBlock;
