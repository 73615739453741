import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
import axios from 'axios';

function InviteAccountModal(props) {
  // FUNCTIONS
  const onSubmit = () => {
    if (!props.memberId) {
      return;
    }

    const params = {
      post: {
        authenticityToken: props.authenticityToken,
        member_id: props.memberId,
      },
    };

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    axios
      .post('/invites/send_invite', params)
      .then((_response) => {
        props.onSubmitSent(false);
      })
      .catch((error) => {
        props.onSubmitSent(false);
        window.Sentry.captureException(error);
      });
  };

  return (
    <div className="fixed inset-0 overflow-y-auto z-20">
      <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
        <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
          <div className="p-6 w-full whitespace-normal">
            <div className="text-left w-full text-3xl leading-6 font-bold font-body mb-6 mt-4 pb-3" id="modal-headline">
              Send Invite
            </div>
            <p>
              This will send <span className="font-bold"> </span> an invite link via both{' '}
              <span className="font-bold">SMS</span> and <span className="font-bold">e-mail</span>. They will then set
              up a username and password.
            </p>

            <p className="text-warning-700">
              Note: <span className="font-bold">emails</span> are sent out right away but{' '}
              <span className="font-bold">SMS</span> messages are only sent during business hours (8:00AM-8:59PM). If
              submitting after business hours, the <span className="font-bold">SMS</span>{' '}
              <span className="underline">will be delivered the following morning</span>.
            </p>

            <div className="flex mt-8">
              <button
                className="justify-center btn btn--rounded btn--tertiary flex-1 mr-2"
                onClick={props.onCancel}
                type="button"
              >
                Cancel
              </button>

              <button className="btn btn--rounded btn--primary flex-1 ml-2" type="button" onClick={() => onSubmit()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-10 bg-teal-700 opacity-80" />
    </div>
  );
}

InviteAccountModal.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  onSubmitSent: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InviteAccountModal;
