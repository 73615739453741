import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import StatusTag from './StatusTag';

function StatusTagCell(props) {
  return (
    <td className={props.cellClasses}>
      {props?.show && (
        <StatusTag
          classes={props.classes}
          label={props.label}
          isMedium={props.isMedium}
          isErrorFilled={props.isErrorFilled}
        />
      )}
    </td>
  );
}

StatusTagCell.propTypes = {
  label: PropTypes.string.isRequired,

  // OPTIONAL
  show: PropTypes.bool,
  classes: PropTypes.string,
  cellClasses: PropTypes.string,
  isMedium: PropTypes.bool,
  isErrorFilled: PropTypes.bool,
};

StatusTagCell.defaultProps = {
  show: false,
  classes: '',
  cellClasses: '',
  isMedium: false,
  isErrorFilled: false,
};

export default StatusTagCell;
