import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import GroupsModal from '../../GroupsModal';

import SelectInput from '../../shared/SelectInput';
import TextareaInput from '../../shared/TextareaInput';
import DateInput from '../../shared/DateInput';
import MemberSearchSelect from '../../shared/MemberSearchSelect';

function AddTaskModal({ isOpen, onClose, onConfirm, form, prescriptionReasons }) {
  const { control } = form;

  return (
    <GroupsModal
      size="medium"
      testID="add-task-modal"
      isOpen={isOpen}
      header="Add task"
      subHeader="*Required field"
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          label: 'Continue',
          isPrimary: true,
          onClick: onConfirm,
        },
      ]}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4 border-b">
          <div data-testid="member-search-select-input">
            <Controller
              name="member"
              control={control}
              render={({ field, fieldState }) => (
                <MemberSearchSelect
                  {...field}
                  label="Member"
                  placeholder="Search by name, MRN, e-mail or phone number..."
                  required
                  error={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="p-4 border-b">
          <Controller
            name="due_date"
            control={control}
            render={({ field, fieldState }) => (
              <DateInput
                {...field}
                label="Due date"
                placeholder="Select date"
                required
                minDate={moment()}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="p-4 border-b">
          <Controller
            name="reason"
            control={control}
            render={({ field, fieldState }) => (
              <SelectInput
                {...field}
                label="Reason"
                placeholder="Select reason"
                options={prescriptionReasons}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="p-4">
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState }) => (
              <TextareaInput {...field} label="Note" rows="4" error={fieldState.error?.message} />
            )}
          />
        </div>
      </div>
    </GroupsModal>
  );
}

const valuePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

AddTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  form: PropTypes.shape({
    control: PropTypes.any,
  }).isRequired,
  prescriptionReasons: PropTypes.arrayOf(valuePropType).isRequired,
};

export default AddTaskModal;
