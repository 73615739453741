import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import StaffTabData from './StaffTabData';
import StaffTableHeaderData from './StaffTableHeaderData';

import Tabs from '../../Tabs';
import Table from '../../Table';
import Filters from '../../Filters';

import useFilterData from './services/fetch-staff-filter-data';
import setParams from './services/set-params';

function Main(props) {
  const [activeTab, setTab] = useState(StaffTabData[0]);
  const [tabData, setTabData] = useState({});

  const { statesData, facilitiesData, staffData, daysData, currentDefaultStaffData } = useFilterData();
  const [currentStatesFilters, setCurrentStatesFilters] = useState([]);
  const [currentFacilitiesFilters, setCurrentFacilitiesFilters] = useState([]);
  const [currentStaffFilter, setCurrentStaffFilter] = useState([]);
  const [currentDaysFilters, setCurrentDaysFilters] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [_currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState({});
  const [allAddedData, setAllAddedData] = useState({});
  const [allRemovedData, setAllRemovedData] = useState({});

  const [isSortingName, setIsSortingName] = useState('name asc');

  useEffect(() => {
    async function fetchData() {
      try {
        await axios
          .get('/staff/empanelment_staff', {
            params: { page: 1 },
          })
          .then((response) => {
            setTabData(response.data);
            setAllData(response.data);
          });
      } catch (error) {
        window.Sentry.captureException(error);
      }
      setLoaded(true);
    }

    fetchData();
    setCurrentStaffFilter([currentDefaultStaffData]);
  }, [currentDefaultStaffData]);

  const isPageValid = (data, params) => {
    if (data.json_data.length === 0 && params.page !== 1) {
      return false;
    }
    return true;
  };

  // Fetches and updates state data according to currentPage, activeTab, and fetchParams
  const updateData = async (updatedParams) => {
    setLoaded(false);
    const { tab, ...params } = updatedParams;
    try {
      await axios
        .get('/staff/empanelment_staff', {
          params,
        })
        .then((response) => {
          const currentData = response.data;
          if (isPageValid(currentData, params) === false) {
            return;
          }
          if (tab === 'all') {
            setAllData(currentData);
          } else if (tab === 'added') {
            setAllAddedData(currentData);
          } else if (tab === 'removed') {
            setAllRemovedData(currentData);
          }
          setTabData(currentData);
        });
    } catch (error) {
      window.Sentry.captureException(error);
    }
    setLoaded(true);
  };

  // On tab change we fetch appropriate data for the new tab
  const tabChange = async (tab) => {
    setTab(tab);

    const updatedParams = setParams(
      1,
      tab.key,
      currentStatesFilters,
      currentFacilitiesFilters,
      currentStaffFilter,
      currentDaysFilters
    );

    updateData(updatedParams);
  };

  // Set current page number in state and fetch data
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);

    const updatedParams = setParams(
      pageNumber,
      activeTab.key,
      currentStatesFilters,
      currentFacilitiesFilters,
      currentStaffFilter,
      currentDaysFilters
    );

    const { tab, ...params } = updatedParams;

    try {
      await axios
        .get('/staff/empanelment_staff', {
          params,
        })
        .then((response) => {
          const currentData = response.data;

          if (isPageValid(currentData, params) === false) {
            return;
          }

          if (tab === 'all') {
            if (allData.json_data) {
              currentData.json_data = allData.json_data.concat(currentData.json_data);
            }

            setAllData(currentData);
          } else if (tab === 'added') {
            if (allAddedData.json_data) {
              currentData.json_data = allAddedData.json_data.concat(currentData.json_data);
            }

            setAllAddedData(currentData);
          } else if (tab === 'removed') {
            if (allRemovedData.json_data) {
              currentData.json_data = allRemovedData.json_data.concat(currentData.json_data);
            }

            setAllRemovedData(currentData);
          }

          setTabData(currentData);
        });
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const fetchFilteredData = async (type, selectedFilters) => {
    const statesFilters = type === 'states' ? selectedFilters : currentStatesFilters;
    const facilitiesFilters = type === 'facilities' ? selectedFilters : currentFacilitiesFilters;
    const staffFilter = type === 'staff' ? selectedFilters : currentStaffFilter;
    const daysFilters = type === 'days' ? selectedFilters : currentDaysFilters;

    const updatedParams = setParams(1, activeTab.key, statesFilters, facilitiesFilters, staffFilter, daysFilters);

    try {
      updateData(updatedParams);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const headerButtonClick = async (actionName) => {
    let nameSortType;

    // ********************
    // FILTER NAME
    // ********************

    if (actionName === 'filter_name') {
      nameSortType = isSortingName === 'name desc' ? 'name asc' : 'name desc';
      setIsSortingName(nameSortType);
    }

    const updatedParams = setParams(
      1,
      activeTab.key,
      currentStatesFilters,
      currentFacilitiesFilters,
      currentStaffFilter,
      currentDaysFilters,
      nameSortType
    );

    try {
      updateData(updatedParams);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  const updateRowAction = async (_updateType, newRow) => {
    const updatedAll = allData.json_data.map((r) => (r.id !== newRow.id ? r : newRow));
    allData.json_data = updatedAll;

    setAllData(allData);

    const updatedAdded = allAddedData.json_data?.map((r) => (r.id !== newRow.id ? r : newRow));
    allAddedData.json_data = updatedAdded;

    setAllAddedData(allAddedData);

    const updatedRemoved = allRemovedData.json_data?.map((r) => (r.id !== newRow.id ? r : newRow));
    allRemovedData.json_data = updatedRemoved;

    setAllRemovedData(allRemovedData);

    if (activeTab.key === 'all') {
      tabData.json_data = updatedAll;

      setTabData(tabData);
    } else if (activeTab.key === 'added') {
      tabData.json_data = updatedAdded;

      setTabData(tabData);
    } else if (activeTab.key === 'removed') {
      tabData.json_data = updatedRemoved;

      setTabData(tabData);
    }
  };

  const testID = 'staffMainEmp';

  return (
    <>
      <h2 data-testid={`title-${testID}`} className="font-body font-semibold mt-2 mb-6">
        View Panel
      </h2>

      <div className="react-filters flex">
        <Filters
          isMulti={false}
          label="Counselors & Providers"
          type="staff"
          data={staffData}
          defaultValue={currentDefaultStaffData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentStaffFilter}
          testID={`staff-${testID}`}
        />
        <Filters
          label="States"
          type="states"
          data={statesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentStatesFilters}
          testID={`states-${testID}`}
        />
      </div>
      <div className="react-filters flex">
        <Filters
          label="Groups Meeting Facilities"
          type="facilities"
          data={facilitiesData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentFacilitiesFilters}
          testID={`staff-${testID}`}
        />
        <Filters
          label="Days of the Week"
          type="days"
          data={daysData}
          onFilter={fetchFilteredData}
          stateChanger={setCurrentDaysFilters}
          testID={`days-${testID}`}
        />
      </div>

      <Tabs activeTab={activeTab} tabs={StaffTabData} onChange={tabChange} testID={testID} />

      {loaded ? (
        <div id="empanelment_groups">
          <Table
            activeTab={activeTab.key}
            secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
            currentPage={tabData.current_page}
            tableType="panelTable"
            tableColumnHeaders={StaffTableHeaderData}
            tableRows={tabData.json_data}
            authenticityToken={props.authenticityToken}
            pageChange={handlePageChange}
            updateRows={updateRowAction}
            headerButtonClick={headerButtonClick}
            isLastPage={tabData.is_last_page}
            totalCountRecords={tabData.total_count}
            testID={testID}
          />
        </div>
      ) : (
        <div data-testid={`noData-${testID}`} className="no-data-loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
};

export default Main;
