import React from 'react';
import PropTypes from 'prop-types';

export const TAG_STATUS = {
  STANDARD: 'STANDARD',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  PURPLE: 'PURPLE',
};

function StatusTag(props) {
  const baseClasses = 'rounded border divide-gray-300 px-1 mr-2 max-w-max';

  const mediumTagClasses = props?.isMedium ? 'text-sm mx-1' : '';
  const errorClassWithFill = props?.isErrorFilled ? 'border-transparent' : '';

  const successClass = props?.status === TAG_STATUS.SUCCESS ? 'text-positive-700' : '';
  const warningClass = props?.status === TAG_STATUS.WARNING ? 'text-warning-500' : '';
  const errorClass = props?.status === TAG_STATUS.ERROR ? 'text-error-700' : '';
  const heightClass = props?.fullHeight ? 'h-full' : '';

  const errorClassWithFillStyle = props?.isErrorFilled
    ? { backgroundColor: '#CD5334', color: 'white', height: 'max-content' }
    : {};

  return (
    <div
      className={`${baseClasses} ${successClass} ${warningClass} ${errorClass} ${props.classes} ${errorClassWithFill} ${heightClass}`}
      style={errorClassWithFillStyle}
    >
      <div className={`text-xs font-semibold ${mediumTagClasses}`}>{props.label}</div>
    </div>
  );
}

StatusTag.propTypes = {
  label: PropTypes.string.isRequired,

  // OPTIONAL
  status: PropTypes.string,
  classes: PropTypes.string,
  isMedium: PropTypes.bool,
  isErrorFilled: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

StatusTag.defaultProps = {
  status: null,
  classes: '',
  isMedium: false,
  isErrorFilled: false,
  fullHeight: true,
};

export default StatusTag;
