import moment from 'moment/moment';

export const FILTER_TYPES = {
  appointmentType: 'appointment_type',
  dayOfWeek: 'day_of_week',
  state: 'state',
  facility: 'facility',
  availability: 'availability',
  provider: 'provider',
  member: 'member',
  fromTime: 'from_time',
  toTime: 'to_time',
  fromDate: 'from_date',
  toDate: 'to_date',
};

export const AVAILABILITY_DATA = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'all', label: 'All' },
];

export const DEFAULT_FILTERS = {
  scheduled: {
    page: 1,
    [FILTER_TYPES.appointmentType]: [],
    [FILTER_TYPES.dayOfWeek]: [],
    [FILTER_TYPES.state]: [],
    [FILTER_TYPES.facility]: [],
    [FILTER_TYPES.provider]: [],
    [FILTER_TYPES.member]: null,
    [FILTER_TYPES.fromTime]: null,
    [FILTER_TYPES.toTime]: null,
    [FILTER_TYPES.fromDate]: moment(),
    [FILTER_TYPES.toDate]: moment().add(1, 'days'),
  },
  bookAppointment: {
    page: 1,
    [FILTER_TYPES.appointmentType]: [],
    [FILTER_TYPES.dayOfWeek]: [],
    [FILTER_TYPES.state]: [],
    [FILTER_TYPES.facility]: [],
    [FILTER_TYPES.availability]: [AVAILABILITY_DATA[0]],
    [FILTER_TYPES.provider]: [],
    [FILTER_TYPES.member]: null,
    [FILTER_TYPES.fromTime]: null,
    [FILTER_TYPES.toTime]: null,
    [FILTER_TYPES.fromDate]: moment(),
    [FILTER_TYPES.toDate]: null,
  },
  staffMemberFacilities: {
    page: 1,
    [FILTER_TYPES.provider]: [],
    [FILTER_TYPES.facility]: [],
    [FILTER_TYPES.state]: [],
    [FILTER_TYPES.appointmentType]: [],
  },
};

export const TABLE_HEADERS = (showActionsColumn = false) => [
  {
    name: 'Date',
    showColumn: true,
    isClickable: true,
    actionName: 'scheduled_at',
  },
  {
    name: 'Time',
    showColumn: true,
  },
  {
    name: 'Type',
    showColumn: true,
  },
  {
    name: 'Facility',
    showColumn: true,
  },
  {
    name: 'Staff Members',
    showColumn: true,
    isClickable: true,
    actionName: 'provider_names',
  },
  {
    name: 'Available seats',
    showColumn: true,
  },
  {
    name: 'Action',
    showColumn: showActionsColumn,
    actionColumn: true,
  },
];

export const EVERY_STATE_TYPE_APPOINTMENT = 'Orientation Group';

export const TAB_IDS = {
  SCHEDULED: 0,
  BOOK_APPOINTMENT: 1,
  STAFF_MEMBER_FACILITIES: 2,
};

export const PageTabs = [
  { id: TAB_IDS.SCHEDULED, name: 'Scheduled' },
  {
    id: TAB_IDS.BOOK_APPOINTMENT,
    name: 'Book Appointment',
  },
];
