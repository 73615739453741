/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputWrapper from './InputWrapper';

const Input = forwardRef(
  (
    {
      label,
      name,
      type,
      value,
      onBlur,
      onChange,
      placeholder,
      error,
      min,
      max,
      disabled,
      required,
      isVertical,
      option,
      checked,
      id,
      forbiddenValuePattern,
      accept,
    },
    ref
  ) => {
    const handleChangeWithValuePattern = (e) => {
      if (forbiddenValuePattern) {
        e.target.value = e.target.value.replaceAll(forbiddenValuePattern, '');
      }
      onChange(e);
    };

    return (
      <InputWrapper label={label} name={name} required={required} error={error} isVertical={isVertical}>
        <div className="flex items-center">
          <input
            data-testid="input"
            id={id || name}
            ref={ref}
            name={name}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={handleChangeWithValuePattern}
            placeholder={placeholder}
            min={min}
            max={max}
            disabled={disabled}
            checked={checked}
            accept={accept}
            className={classNames('shared-input', { 'shared-input-error': !!error })}
          />
          {option && (
            <span data-testid="option" className="ml-2">
              {option}
            </span>
          )}
        </div>
      </InputWrapper>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  option: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  forbiddenValuePattern: PropTypes.oneOf([PropTypes.instanceOf(RegExp), PropTypes.string]),
  accept: PropTypes.string,
};

Input.defaultProps = {
  label: null,
  placeholder: null,
  type: 'text',
  disabled: false,
  required: false,
  isVertical: true,
  option: null,
  checked: false,
  id: null,
  forbiddenValuePattern: null,
  accept: undefined,
};

export default Input;
