import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { CircularProgress } from '@mui/material';
import useChartingNoteExternalIds from '../../documentation/useChartingNoteExternalIds';
import useChartingNoteMemberIds from '../../documentation/useChartingNoteMemberIds';
import useAddDocumentationMembers from '../members_list/useAddDocumentationMembers';
import GroupChartingNotes from '../../documentation/GroupChartingNotes';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import Tabs from '../../Tabs';
import MembersList from '../../documentation/MembersList';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

const TABS = [
  {
    id: 1,
    name: 'Panel Summary',
  },
];

function SessionRelatedGroupDocumentation({
  authenticityToken,
  states,
  sessionIdentifier,
  smartPhrases,
  canUserManageChartingNotes,
}) {
  const { chartingNoteExternalIds } = useChartingNoteExternalIds({
    sessionIdentifier,
  });
  const { chartingNoteMemberIds } = useChartingNoteMemberIds({
    sessionIdentifier,
  });
  const { members, isLoading } = useAddDocumentationMembers(chartingNoteMemberIds);

  const groupNotesMembers = useMemo(
    () =>
      members?.map(({ id, mrn, healthie_mrn, first_name, last_name }) => ({
        id,
        mrn,
        healthieMrn: healthie_mrn,
        firstName: first_name,
        lastName: last_name,
      })),
    [members]
  );

  const membersList = convertObjKeysToCamelCase(members, ['variables'])?.map(
    ({ id, firstName, lastName, groupInformation, memberSummaries, mrn, dob }) => ({
      id,
      firstName,
      lastName,
      groupInformation,
      memberSummaries,
      mrn,
      dob,
    })
  );

  if (isLoading)
    return (
      <div className="flex justify-center">
        <CircularProgress />
      </div>
    );

  if (!isEmpty(members)) {
    return (
      <>
        <div className="mx-8">
          <h3 className="font-body font-bold mt-8 mb-6">{`Members (${members.length})`}</h3>
          <Tabs tabs={TABS} activeTab={TABS[0]} onChange={() => {}} />
          <div className="mt-5 mb-8">{membersList?.length && <MembersList members={membersList} />}</div>
        </div>
        <GroupChartingNotes
          authenticityToken={authenticityToken}
          members={groupNotesMembers}
          states={states}
          sessionIdentifier={sessionIdentifier}
          selectedChartingNoteIds={chartingNoteExternalIds.length ? chartingNoteExternalIds : undefined}
          smartPhrases={smartPhrases}
          canUserManageChartingNotes={canUserManageChartingNotes}
        />
      </>
    );
  }

  return null;
}

SessionRelatedGroupDocumentation.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
  smartPhrases: SmartPhrasesPropType,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
};

SessionRelatedGroupDocumentation.defaultProps = {
  smartPhrases: null,
};

export default SessionRelatedGroupDocumentation;
