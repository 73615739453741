import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  // set up initial tabs data
  const [all, setAllData] = useState({});
  const [notEmpaneled, setNotEmpaneledData] = useState({});
  const [empaneled, setEmpaneledData] = useState({});
  const [flagged, setFlaggedData] = useState({});

  // set loader
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', { params: { page: 1 } });

        setAllData(response);
        setIsLoaded(true);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchNotEmpaneled = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            not_empaneled: true,
          },
        });

        setNotEmpaneledData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchEmpaneled = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            is_empaneled: true,
          },
        });

        setEmpaneledData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    const fetchFlagged = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members', {
          params: {
            page: 1,
            is_flagged: true,
          },
        });

        setFlaggedData(response);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    // CALL ASYNC FUNCTIONS
    fetchAll();
    fetchNotEmpaneled();
    fetchEmpaneled();
    fetchFlagged();
  }, []);

  return {
    all,
    notEmpaneled,
    empaneled,
    flagged,
    isLoaded,
  };
};

export default useFetchData;
