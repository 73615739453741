import React from 'react';
import PropTypes from 'prop-types';
import { TiWarningOutline } from 'react-icons/ti';

function InputWarning({ warning }) {
  if (!warning) return null;

  return (
    <div className="text-warning-500 bg-warning-100 py-0.5 px-1 flex flex-row gap-1 center items-center rounded text-sm">
      <TiWarningOutline className="h-4 w-4" />
      {warning}
    </div>
  );
}

InputWarning.propTypes = {
  warning: PropTypes.string,
};

InputWarning.defaultProps = {
  warning: null,
};

export default InputWarning;
