import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from './GroupsModal';
import ConfirmationModalList from './ConfirmationModalList';

function UpsertConfirmationModal({ testID, isLoading, onContinue, onBack, values, mode }) {
  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onBack,
    },
    {
      label: 'Confirm',
      isPrimary: true,
      onClick: onContinue,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      data-testid={testID}
      isOpen
      header="Are you sure?"
      footerButtons={footerButtons}
      size="small"
      isLoading={isLoading}
    >
      <p>
        Clicking “confirm” will
        <strong>{` ${mode} all occurrences `}</strong>
        of this group on the schedule.{' '}
        <span className="font-semibold text-error-700">This action cannot be undone.</span>
      </p>
      <ConfirmationModalList listItems={values} />
    </GroupsModal>
  );
}

const valuesShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

UpsertConfirmationModal.propTypes = {
  testID: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit']),

  // functions
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape(valuesShape)).isRequired,
};

UpsertConfirmationModal.defaultProps = {
  isLoading: false,
  mode: 'create',
};

export default UpsertConfirmationModal;
