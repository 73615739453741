import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import DefaultCell from '../shared_dashboard/DefaultCell';
import TableFooter from '../shared_dashboard/TableFooter';

import { monthDateYearString } from '../../helpers/DateFormatter';

function AllBams({ allBams }) {
  const openPdf = (id) => {
    window.open(`/staff/bams/${id}/open_pdf`, '_blank');
  };

  const filename = (bamType) => {
    if (bamType === 'Brief Health Check In') {
      return 'Brief Health Check in.pdf';
    }
    return 'Health Check In.pdf';
  };

  const downloadPdf = (id, bamType) => {
    axios.get(`/staff/bams/${id}/download_pdf`).then((response) => {
      const data = Buffer.from(response.data, 'base64');
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename(bamType));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  if (allBams?.length) {
    return (
      <section>
        <div id="group_filterrific_results">
          <div className="relative overflow-visible">
            <div className="rounded-lg border border-gray-400 shadow-md overflow-auto">
              <table className="bg-white rounded-lg overflow-auto text-black w-full table-auto whitespace-nowrap">
                <thead className="text-left border-b-2">
                  <tr>
                    <th className="bg-white w-1/6">
                      <p className="m-4 font-body text-gray-700 font-semibold">Type</p>
                    </th>
                    <th className="bg-white w-4/6">
                      <p className="m-4 font-body text-gray-700 font-semibold">Date</p>
                    </th>
                    <th className="bg-white w-1/6">
                      <p className="m-4 font-body text-gray-700 font-semibold justify-end">Actions</p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allBams.map((bam) => (
                    <tr key={bam.id}>
                      <DefaultCell id={bam?.id} preloadedData={bam.formatted_type} />
                      <DefaultCell id={bam?.id} preloadedData={monthDateYearString(bam.created_at)} />
                      <td className="flex m-4">
                        <button type="button" className="object-center hover:text-teal-800">
                          <RemoveRedEyeIcon
                            className="text-teal-700 hover:text-teal-800"
                            onClick={() => openPdf(bam?.id)}
                            sx={{
                              fontSize: 30,
                            }}
                          />
                        </button>

                        <button type="button" className="object-center ml-6">
                          <DownloadIcon
                            className="text-teal-700 hover:text-teal-800"
                            onClick={() => downloadPdf(bam?.id, bam.formatted_type)}
                            sx={{
                              fontSize: 30,
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}

                  <TableFooter firstRangeValue="1" lastRangeValue={allBams.length} totalCount={allBams.length} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="no-content-table">
      <p>This member has not completed a Health Check In</p>
    </div>
  );
}

AllBams.propTypes = {
  allBams: PropTypes.array,
};

AllBams.defaultProps = {
  allBams: [],
};

export default AllBams;
