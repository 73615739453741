import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterPill from '../FilterPill';
import { formatDateRange, formatTimeRange } from '../../helpers/DateFormatter';

const FROM_DATE_TYPES = ['fromDate', 'from_date'];
const TO_DATE_TYPES = ['toDate', 'to_date'];
const FROM_TIME_TYPES = ['fromTime', 'from_time'];
const TO_TIME_TYPES = ['toTime', 'to_time'];

function AppliedFilters({ currentlyAppliedFilters, addTopMargin }) {
  const hasNonEmptyFilter = currentlyAppliedFilters.find((filter) => {
    const isObject =
      typeof filter.selectedFilters === 'object' &&
      filter.selectedFilters !== null &&
      !Array.isArray(filter.selectedFilters);

    return !!filter.selectedFilters?.length || isObject;
  });

  if (!hasNonEmptyFilter) {
    return null;
  }

  const getDateRangePill = () => {
    const fromDate = currentlyAppliedFilters.find((f) => FROM_DATE_TYPES.includes(f.type));
    const toDate = currentlyAppliedFilters.find((f) => TO_DATE_TYPES.includes(f.type));
    const datesLabel = formatDateRange(fromDate?.selectedFilters, toDate?.selectedFilters);

    if (!datesLabel) return null;

    const onRemove = () => {
      fromDate?.getRemoveFilterEventHandler(fromDate.selectedFilters)();
      toDate?.getRemoveFilterEventHandler(toDate.selectedFilters)();
    };

    return <FilterPill filterType="dateRange" onRemove={onRemove} label={datesLabel} />;
  };

  const getTimeRangePill = () => {
    const fromTime = currentlyAppliedFilters.find((f) => FROM_TIME_TYPES.includes(f.type));
    const toTime = currentlyAppliedFilters.find((f) => TO_TIME_TYPES.includes(f.type));
    const timeLabel = formatTimeRange(fromTime?.selectedFilters, toTime?.selectedFilters);

    if (!timeLabel) return null;

    const onRemove = () => {
      fromTime?.getRemoveFilterEventHandler(fromTime.selectedFilters)();
      toTime?.getRemoveFilterEventHandler(toTime.selectedFilters)();
    };

    return <FilterPill filterType="timeRange" onRemove={onRemove} label={timeLabel} />;
  };

  const renderFilterPill = (filter) => {
    if (!filter || !filter?.selectedFilters) return null;

    if (typeof filter.selectedFilters === 'string') {
      return (
        <FilterPill
          key={filter.selectedFilters}
          filterType={filter.type}
          onRemove={filter.getRemoveFilterEventHandler(filter.selectedFilters)}
          label={filter.selectedFilters}
        />
      );
    }

    return filter.selectedFilters?.map?.((selectedFilter) => (
      <FilterPill
        key={selectedFilter.value}
        filterType={filter.type}
        onRemove={filter.getRemoveFilterEventHandler(selectedFilter)}
        label={selectedFilter.label}
      />
    ));
  };

  return (
    <>
      <p className="font-semibold mt-4 mb-1" data-testid="filters-label">
        Filters Applied
      </p>
      <div className={classNames('flex flex-row flex-wrap gap-6', { 'mt-2': addTopMargin })}>
        {getDateRangePill()}
        {getTimeRangePill()}
        {currentlyAppliedFilters.map(renderFilterPill)}
      </div>
    </>
  );
}

AppliedFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.array.isRequired,
  addTopMargin: PropTypes.bool,
};

AppliedFilters.defaultProps = {
  addTopMargin: true,
};

export default AppliedFilters;
