import { useEffect, useState } from 'react';
import axios from 'axios';

const useWeeklyGroups = () => {
  const [weeklyGroups, setWeeklyGroups] = useState([]);

  // FOOTER LOGIC
  const [firstRangeValue, setFirst] = useState(1);
  const [lastRangeValue, setLast] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  // PAGE LOGIC
  const [totalPages, setTotalPages] = useState(1);

  const fetchWeeklyGroups = async (filters = {}) => {
    try {
      const params = { ...filters };

      const { data } = await axios.get('/staff/dashboard_v2/fetch_weekly_groups', { params });

      setWeeklyGroups(data?.response);

      // FOOTER LOGIC
      setFirst(data?.first_range_value);
      setLast(data?.last_range_value);
      setTotalCount(data?.total_count);

      // PAGE LOGIC
      setTotalPages(data?.total_pages);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchWeeklyGroups();
  }, []);

  return {
    weeklyGroups,
    firstRangeValue,
    lastRangeValue,
    totalCount,
    totalPages,
    fetchWeeklyGroups,
  };
};

export default useWeeklyGroups;
