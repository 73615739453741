import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table';
import useIndividualAppointments from './useIndividualAppointments';
import Tooltip from '../../../shared/Tooltip';

const HEALTH_CHECK_IN_TIME_TEXT = 'Within 2 Weeks';

export const TABLE_HEADERS = [
  {
    name: 'Member',
    showColumn: true,
  },
  {
    name: 'Time',
    showColumn: true,
  },
  {
    name: 'Type',
    showColumn: true,
  },
  {
    name: 'Status',
    showColumn: true,
  },
  {
    name: 'Staff',
    showColumn: true,
  },
  {
    name: 'Appointment Reason',
    showColumn: true,
  },
  {
    name: 'UDS',
    showColumn: true,
  },
  {
    name: 'Account',
    showColumn: true,
  },
  {
    name: 'App',
    showColumn: true,
  },
  {
    name: 'HIPAA',
    showColumn: true,
  },
  {
    name: 'Consent',
    showColumn: true,
  },
  {
    name: 'Admissions Packet',
    showColumn: true,
  },
  {
    name: 'TPO',
    showColumn: true,
  },
  {
    name: 'Financial Agreement',
    showColumn: true,
  },
  {
    name: 'ID',
    showColumn: true,
  },
  {
    name: 'Insurance',
    showColumn: true,
  },
  {
    key: 'health_check-in',
    name: (
      <div className="flex items-center">
        <span className="-mr-3">Health Check-in</span>
        <Tooltip text={HEALTH_CHECK_IN_TIME_TEXT} isSecondaryVariant />
      </div>
    ),
    showColumn: true,
  },
  {
    name: '',
    showColumn: true,
    isClickable: false,
    actionColumn: true,
  },
];

function IndividualAppointments({ filters }) {
  const { currentData, currentPage, fetchData, handleSort, sortedBy, isLoading } = useIndividualAppointments(filters);

  return (
    <div className="flex flex-col gap-4">
      <h1>Individual Appointments</h1>
      <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
        <Table
          tableType="individualAppointments"
          testID="individualAppointments"
          showShadow={false}
          enableActionsColumn
          paginationVariant="numbered"
          pageSize={10}
          currentPage={currentPage}
          tableRows={currentData.jsonData}
          pageChange={fetchData}
          isLastPage={currentData.isLastPage}
          headerButtonClick={handleSort}
          currentSort={sortedBy}
          totalCountRecords={currentData.totalCount}
          tableColumnHeaders={TABLE_HEADERS}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

IndividualAppointments.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default IndividualAppointments;
