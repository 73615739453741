const HOME_GROUP_HEADERS = (showActionsColumn = false) => [
  {
    name: 'Group',
    showColumn: true,
    isColumnSticky: true,
    isClickable: true,
    actionName: 'filter_group_name',
  },
  {
    name: 'Next Counselor Group',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Next Provider Group',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Clinical Model',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Modality',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Counselor',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Provider',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Office Manager',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Empaneled Members',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Active Members',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Available Seats',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Limit',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Actions',
    showColumn: showActionsColumn,
    isClickable: false,
    actionColumn: true,
  },
];

const MAKEUP_GROUP_HEADERS = (showActionsColumn = false) => [
  {
    name: 'Group',
    showColumn: true,
    isColumnSticky: true,
    isClickable: true,
    actionName: 'filter_group_name',
  },
  {
    name: 'Next Group Occurence',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Type',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Modality',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Counselor',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Provider',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Office Manager',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Scheduled Members',
    showColumn: true,
    isClickable: false,
  },
  {
    name: 'Actions',
    showColumn: showActionsColumn,
    isClickable: false,
    actionColumn: true,
  },
];

export default { HOME_GROUP_HEADERS, MAKEUP_GROUP_HEADERS };
