import React from 'react';
import PropTypes from 'prop-types';

import { PiArrowSquareOutBold } from 'react-icons/pi';

function TextRowDescription(props) {
  const flexClass = props?.isNewlineSubtext ? '' : 'flex';

  const fontClass = props?.status ? 'font-semibold' : null;
  const successClass = props?.status === 'SUCCESS' ? 'text-positive-700' : null;
  const warningClass = props?.status === 'WARNING' ? 'text-warning-500' : null;
  const errorClass = props?.status === 'ERROR' ? 'text-error-700' : null;

  function renderLink() {
    if (!props?.link) {
      return <></>;
    }
    return (
      <a href={props.link} target="_blank" rel="noreferrer">
        <PiArrowSquareOutBold className="w-5 h-5 mr-1" />
      </a>
    );
  }

  function renderSubtext() {
    if (!props?.subtext) {
      return <></>;
    }

    const marginClass = props?.isNewlineSubtext ? null : 'ml-1';

    return <div className={`text-xs italic my-auto ${marginClass}`}>({props?.subtext})</div>;
  }

  return (
    <div className={`${flexClass} flex-2 whitespace-pre-line`}>
      {renderLink()}
      <div className={`${fontClass} ${successClass} ${warningClass} ${errorClass}`}>{props.text}</div>
      {renderSubtext()}
    </div>
  );
}

TextRowDescription.propTypes = {
  text: PropTypes.string.isRequired,

  // OPTIONAL ATTRIBUTES
  subtext: PropTypes.string,
  link: PropTypes.string,
  status: PropTypes.string,
  isNewlineSubtext: PropTypes.bool,
};

TextRowDescription.defaultProps = {
  subtext: null,
  link: null,
  status: null,
  isNewlineSubtext: false,
};

export default TextRowDescription;
