import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import OneMonthStatsView from './OneMonthStatsView';
import StatusTagsContainer from '../shared/StatusTagsContainer';
import MemberSummaryHeaderNameIcon from './MemberSummaryHeaderNameIcon';

function MemberSummaryHeader(props) {
  const flexClasses = 'flex';

  return (
    <div className={flexClasses}>
      <div className="flex-1">
        <MemberSummaryHeaderNameIcon
          isOpen={props.isOpen}
          memberInfo={props.memberInfo}
          setOpenStatus={props.setOpenStatus}
        />
        <StatusTagsContainer statuses={props?.statuses} />
      </div>

      <div className="flex-1">
        <OneMonthStatsView data={props?.monthStats} />
      </div>
    </div>
  );
}

MemberSummaryHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  memberInfo: PropTypes.object.isRequired,

  statuses: PropTypes.array.isRequired,
  monthStats: PropTypes.object.isRequired,

  setOpenStatus: PropTypes.func.isRequired,
};

export default MemberSummaryHeader;
