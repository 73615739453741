/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// HOOKS
import useData from './hooks/use-data';

// COMPONENT
import IconRender from './IconRender';

function IconCell(props) {
  /**
   * @param data - return true or false in the case of IconCell
   * Triggers IconRender with MaterialIcon package
   */
  const { isLoaded, data } = useData(props);

  function renderIcon() {
    if (props.preloadedData) {
      return (
        <IconRender
          showCheckIcon={props.showCheckIcon}
          showTriangleIcon={props.isWarning}
          showExclamationIcon={props.isError}
        />
      );
    }
    return <IconRender showCheckIcon={data} />;
  }

  return (
    <td>
      <div className="m-4">
        {isLoaded ? (
          <>{props.children || renderIcon()}</>
        ) : (
          <Skeleton variant="text" width={50} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

IconCell.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  children: PropTypes.node,
  preloadedData: PropTypes.bool,
  showCheckIcon: PropTypes.bool,

  // STATUS
  isWarning: PropTypes.bool,
  isError: PropTypes.bool,
};

IconCell.defaultProps = {
  children: null,
  preloadedData: false,
  showCheckIcon: false,

  // STATUS
  isWarning: false,
  isError: false,
};

export default IconCell;
