import { useEffect, useState } from 'react';
import useHttp from '../shared/hooks/use-http';

const useChartingNoteExternalIds = ({ appointmentOccurrenceId, sessionIdentifier }) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [requestResponse, setRequestResponse] = useState({});

  const fetchChartingNoteExternalIds = async () => {
    try {
      const response = await sendRequest('/staff/documentation/charting_notes/external_ids', {
        method: 'GET',
        params: {
          appointment_occurrence_id: appointmentOccurrenceId,
          session_identifier: sessionIdentifier,
        },
      });

      setRequestResponse(response);
    } catch (e) {
      window.Sentry?.captureException(e);
    }
  };

  useEffect(() => {
    if (!appointmentOccurrenceId && !sessionIdentifier) return;

    fetchChartingNoteExternalIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest, appointmentOccurrenceId, sessionIdentifier]);

  return {
    chartingNoteExternalIds: requestResponse,
    isLoading,
    error,
    fetchChartingNoteExternalIds,
  };
};

export default useChartingNoteExternalIds;
