import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CheckBoxListItem from '../shared/CheckBoxListItem';

import useDefaultTest from './hooks/use-default-test-selected';

import udsTestRecord from './constants/uds-record';
import pregnancyTestRecord from './constants/pregnancy-test-record';
import fentanylTestRecord from './constants/fentanyl-test-record';

/**
 * DrugScreeningRecords
 *
 * @description Displays a set of CheckBoxListItem(s) - with UDS, pregnancy, and fentanyl test data.
 * Clicking one will activate - data will be passed back to PhotosModal to render new drug screen image
 */

function DrugScreeningRecords(props) {
  const { defaultTest, defaultUdsId, defaultUdsUrl } = useDefaultTest(props);

  const { showUdsSection, udsBack, udsFront, udsLeft, udsRight } = udsTestRecord(props?.udsRecord);

  // recordId: the overall record the photos are attached to
  // id: attachment record id
  const { showPregnancySection, pregnancyId, pregnancyTestDate, pregnancyTestUrl } = pregnancyTestRecord(
    props?.pregnancyRecord
  );

  // recordId: the overall record the photos are attached to
  // id: attachment record id
  const { showFentanylSection, fentanylId, fentanylTestDate, fentanylTestUrl } = fentanylTestRecord(
    props?.fentanylRecord
  );

  // selectedId:
  //
  // indicates which list item is highlighted (to display the photo in the right div in PhotosModal)
  // only ONE list item can be highlighted and display a photo at a time
  const [selectedId, setSelectedId] = useState(2);

  useEffect(() => {
    setSelectedId(defaultTest);
  }, [defaultTest]);

  const handleListItemClick = (_event, id, url) => {
    setSelectedId(id);
    props.setSelectedUrl(url);
  };

  return (
    <List>
      {/* UDS CHECKBOXES */}
      {showUdsSection && (
        <>
          <CheckBoxListItem
            title="UDS Cup"
            hideCheckbox
            onClick={(event) => handleListItemClick(event, defaultUdsId, defaultUdsUrl)}
          />

          <Divider light />

          {/* SUBLIST - UDS */}

          {udsBack.showSubsection && (
            <CheckBoxListItem
              title="Back"
              isSubsection
              subHeader={udsBack?.date}
              hideCheckbox
              isSelected={selectedId === udsBack.id}
              onClick={(event) => handleListItemClick(event, udsBack.id, udsBack.url)}
            />
          )}

          {udsFront.showSubsection && (
            <CheckBoxListItem
              title="Front"
              isSubsection
              subHeader={udsFront?.date}
              hideCheckbox
              isSelected={selectedId === udsFront.id}
              onClick={(event) => handleListItemClick(event, udsFront.id, udsFront.url)}
            />
          )}

          {udsLeft.showSubsection && (
            <CheckBoxListItem
              title="Left"
              isSubsection
              subHeader={udsLeft?.date}
              hideCheckbox
              isSelected={selectedId === udsLeft.id}
              onClick={(event) => handleListItemClick(event, udsLeft.id, udsLeft.url)}
            />
          )}

          {udsRight.showSubsection && (
            <CheckBoxListItem
              title="Right"
              isSubsection
              subHeader={udsRight?.date}
              hideCheckbox
              isSelected={selectedId === udsRight.id}
              onClick={(event) => handleListItemClick(event, udsRight.id, udsRight.url)}
            />
          )}
        </>
      )}

      {/* PREGNANCY CHECKBOXES */}
      {showPregnancySection && (
        <>
          <Divider light />

          <CheckBoxListItem
            title="Pregnancy Test"
            hideCheckbox
            subHeader={pregnancyTestDate}
            isSelected={selectedId === pregnancyId}
            onClick={(event) => handleListItemClick(event, pregnancyId, pregnancyTestUrl)}
          />
        </>
      )}

      {/* FENTANYL CHECKBOXES */}
      {showFentanylSection && (
        <>
          <Divider light />

          <CheckBoxListItem
            title="Fentanyl Test"
            hideCheckbox
            subHeader={fentanylTestDate}
            isSelected={selectedId === fentanylId}
            onClick={(event) => handleListItemClick(event, fentanylId, fentanylTestUrl)}
          />
        </>
      )}

      <Divider light />
    </List>
  );
}

DrugScreeningRecords.propTypes = {
  udsRecord: PropTypes.object,
  pregnancyRecord: PropTypes.object,
  fentanylRecord: PropTypes.object,

  // eslint-disable-next-line react/no-unused-prop-types
  testView: PropTypes.string.isRequired,

  setSelectedUrl: PropTypes.func.isRequired,
};

DrugScreeningRecords.defaultProps = {
  udsRecord: {
    record: { id: null, updated_at: null },
    front: {
      attachment_record: { id: null, updated_at: null },
      url: 'front_test_photo_url',
    },
    back: {
      attachment_record: { id: null, updated_at: null },
      url: 'back_test_photo_url',
    },
    left: {
      attachment_record: { id: null, updated_at: null },
      url: 'left_test_photo_url',
    },
    right: {
      attachment_record: { id: null, updated_at: null },
      url: 'right_test_photo_url',
    },
  },
  pregnancyRecord: {
    record: { id: null, updated_at: null },
    attachment_record: { id: null, updated_at: null },
    url: 'path_to_test_image',
  },
  fentanylRecord: {
    record: { id: null, updated_at: null },
    attachment_record: { id: null, updated_at: null },
    url: 'path_to_test_image',
  },
};

export default DrugScreeningRecords;
