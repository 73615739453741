import { getSupportedTimeZoneCity } from '../../../helpers/TimezoneHelper';
import { FILTER_TYPES } from './constants';

const setRequestParams = (pageNumber, filters, isHomeGroup, isMakeupGroup) => {
  const availability = filters[FILTER_TYPES.availability].map((availabilityItem) => availabilityItem.value);
  const counselor_ids = filters[FILTER_TYPES.counselor].map((counselor) => counselor.value);
  const days = filters[FILTER_TYPES.days].map((day) => day.value);
  const clinical_models = filters[FILTER_TYPES.clinicalModel].map((clinicalModel) => clinicalModel.value);
  const location_ids = filters[FILTER_TYPES.facility].map((facility) => facility.value);
  const modality_ids = filters[FILTER_TYPES.modality].map((modality) => modality.value);
  const office_manager_ids = filters[FILTER_TYPES.officeManager].map((om) => om.value);
  const provider_ids = filters[FILTER_TYPES.provider].map((provider) => provider.value);
  const state_ids = filters[FILTER_TYPES.state].map((state) => state.value);
  const hours = filters[FILTER_TYPES.time].map((time) => time.value);
  const zipcode = filters[FILTER_TYPES.zipcode].map((zipcodeItem) => zipcodeItem.value);

  return {
    page: pageNumber,
    is_home_group: isHomeGroup,
    is_makeup_group: isMakeupGroup,
    state_ids: state_ids.includes('*') ? null : state_ids,
    location_ids: location_ids.includes('*') ? null : location_ids,
    counselor_ids: counselor_ids.includes('*') ? null : counselor_ids,
    office_manager_ids: office_manager_ids.includes('*') ? null : office_manager_ids,
    provider_ids: provider_ids.includes('*') ? null : provider_ids,
    days: days.includes('*') ? null : days,
    time: hours.includes('*') ? null : hours,
    modality_ids: modality_ids.includes('*') ? null : modality_ids,
    clinical_models_ids: clinical_models.includes('*') ? null : clinical_models,
    availability,
    zipcode,
    time_zone: getSupportedTimeZoneCity(new Date()),
  };
};

export default setRequestParams;
