export const TABLE_HEADERS = [
  {
    name: 'Name',
    showColumn: true,
    isClickable: true,
    actionName: 'name',
  },
  {
    name: 'DOB',
    showColumn: true,
  },
  {
    name: 'Contact Info',
    showColumn: true,
  },
  {
    name: 'Tx (Weeks)',
    showColumn: true,
  },
  {
    name: 'Rx',
    showColumn: true,
  },
  {
    name: 'Payer',
    showColumn: true,
  },
  {
    name: 'Status',
    showColumn: true,
  },
  {
    name: 'Upcoming Group Meeting',
    showColumn: true,
  },
  {
    name: 'Empanelment',
    showColumn: true,
  },
  {
    name: '',
    key: 'actions',
    showColumn: true,
    isClickable: false,
    actionColumn: true,
  },
];

export const FILTER_TYPES = {
  state: 'state',
  facility: 'facility',
  groupDay: 'group_day',
  member: 'member',
  dosage: 'dosage',
  status: 'status',
  tx: 'tx',
  payer: 'payer',
  officeManager: 'office_manager',
  counselor: 'counselor',
  provider: 'provider',
};

export const DEFAULT_FILTERS = {
  [FILTER_TYPES.state]: [],
  [FILTER_TYPES.facility]: [],
  [FILTER_TYPES.groupDay]: [],
  [FILTER_TYPES.member]: null,
  [FILTER_TYPES.dosage]: [],
  [FILTER_TYPES.status]: [{ value: 'in_treatment', label: 'In Treatment' }],
  [FILTER_TYPES.tx]: [],
  [FILTER_TYPES.payer]: [],
  [FILTER_TYPES.officeManager]: [],
  [FILTER_TYPES.counselor]: [],
  [FILTER_TYPES.provider]: [],
};
