/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// HOOKS
import useData from './hooks/use-data';

function MemberCell(props) {
  const { isLoaded, data } = useData(props);
  const [preloadedInfo] = useState(props?.preloadedData);

  return (
    <td>
      <div className="m-4 leading-tight">
        {isLoaded ? (
          <div>
            <a
              className="font-bold my-1 underline hover:no-underline cursor-pointer"
              href={`/staff/members/${preloadedInfo?.id || data?.id}`}
            >
              {preloadedInfo?.full_name || data?.full_name}
            </a>
            <p className="text-sm font-light text-gray-700 my-1">{preloadedInfo?.external_id || data?.external_id}</p>
          </div>
        ) : (
          <Skeleton variant="text" width={150} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

MemberCell.propTypes = {
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  preloadedData: PropTypes.object,
};

MemberCell.defaultProps = {
  preloadedData: null,
};

export default MemberCell;
