import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import TableHeaderCell from './TableHeaderCell';

function TableHeader(props) {
  return (
    <thead className="text-left border-b-2">
      <tr>
        {props.headers.map((header) => (
          <TableHeaderCell
            key={header.accessorKey}
            show={header.show}
            icon={header.icon}
            style={header.style}
            classes={header.classes}
            isClickable={header.isClickable}
            accessorKey={header.accessorKey}
            direction={header?.direction}
            text={header.name}
            isSorting={header.isSorting}
            onHeaderClick={props.onHeaderClick}
          />
        ))}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  onHeaderClick: PropTypes.func,
};

TableHeader.defaultProps = {
  onHeaderClick: () => {},
};

export default TableHeader;
