import React from 'react';
import PropTypes from 'prop-types';

function MemberSummaryDetailsContainer(props) {
  return <div className={`bg-blue-100 border border-gray-200 rounded-sm ${props.classes}`}>{props.children}</div>;
}

MemberSummaryDetailsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string.isRequired,
};

export default MemberSummaryDetailsContainer;
