import React from 'react';
import PropTypes from 'prop-types';

function InlineFormError({ errorMessage }) {
  return <p className="warning">{errorMessage}</p>;
}

InlineFormError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default InlineFormError;
