import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const MemberURLsContext = createContext({
  memberSummaryUrl: '',
  groupsManagementUrl: '',
});

export function MemberURLsController({ memberSummaryUrl, groupsManagementUrl, children }) {
  const memoizedValue = useMemo(
    () => ({ memberSummaryUrl, groupsManagementUrl }),
    [memberSummaryUrl, groupsManagementUrl]
  );

  return <MemberURLsContext.Provider value={memoizedValue}>{children}</MemberURLsContext.Provider>;
}

MemberURLsController.propTypes = {
  memberSummaryUrl: PropTypes.string.isRequired,
  groupsManagementUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useMemberURLs = () => useContext(MemberURLsContext);
