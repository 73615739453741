import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { HiSearch } from 'react-icons/hi';
import Button from '../shared/Button';
import PrescriptionSupportModal from './PrescriptionSupportModal';

function PrescriptionSupportView({ data, member_id, schedules_flag, time_zone }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="prescription-support-container" data-testid="prescription-support">
      <div className="overflow-hidden mt-9 mb-12 border border-gray-400 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-body font-semibold leading-6 text-gray-900">Prescription</h3>
        </div>
        <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
          <dd>
            <Button classes="btn--tertiary icon-button" onClick={setIsModalOpen}>
              <HiSearch />
              PRESCRIBER LOOKUP
            </Button>
          </dd>
        </div>
      </div>
      {isModalOpen && (
        <PrescriptionSupportModal
          onClose={closeModal}
          data={data}
          memberId={member_id}
          schedulesFlag={schedules_flag}
          timeZone={time_zone}
          testId="prescription-support"
        />
      )}
    </div>
  );
}

PrescriptionSupportView.propTypes = {
  data: PropTypes.shape({
    memberName: PropTypes.string.isRequired,
    pharmacy: PropTypes.string,
  }),
  member_id: PropTypes.number.isRequired,
  schedules_flag: PropTypes.bool.isRequired,
  time_zone: PropTypes.string.isRequired,
};

PrescriptionSupportView.defaultProps = {
  data: {
    pharmacy: null,
  },
};

export default PrescriptionSupportView;
