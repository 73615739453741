import React from 'react';
import PropTypes from 'prop-types';

function FormBody(props) {
  return (
    <form>
      <dl className="bg-white flex flex-col rounded-b-lg mt-4">{props.children}</dl>
    </form>
  );
}

FormBody.propTypes = {
  children: PropTypes.node,
};

FormBody.defaultProps = {
  children: null,
};

export default FormBody;
