import React from 'react';
import PropTypes from 'prop-types';

function Section({ label, children }) {
  return (
    <div className="border-b border-gray-300 px-6 py-4">
      <label className="flex flex-col gap-2">
        <span className="font-semibold text-base">{label}</span>
        {children}
      </label>
    </div>
  );
}

Section.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
