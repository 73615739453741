import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function MembersWarningModal(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [confirmData, setConfirmData] = useState(props.isOpen);

  const testID = `membersWarningModal-${props.testID}`;

  useEffect(() => {
    setIsOpen(props.isOpen);
    setConfirmData(props.confirmData);
  }, [props.isOpen, props.confirmData]);

  return (
    <>
      {isOpen && (
        <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              <div data-testid={`confirmInfo-${testID}`} className="p-6 w-full whitespace-normal">
                <h2 className="font-bold mb-4">Are you sure?</h2>

                <p className="mb-1">You are confirming the following information about:</p>

                <b>{`${props.memberName} (${props.mrn})`}</b>

                <li data-testid={`location-${testID}`} className="mt-3">
                  Group Meeting Facility: {confirmData?.group_data?.location_name}
                </li>

                <>
                  <li data-testid={`modality-${testID}`}>Modality: {confirmData?.modality}</li>

                  {props.secondaryGroupTypeFeatureFlag ? (
                    <>
                      <li data-testid={`groupType-${testID}`}>Clinical Model: {confirmData?.group_type}</li>
                      <li data-testid={`secondaryGroupType-${testID}`}>
                        Other Information: {confirmData?.secondary_group_types}
                      </li>
                    </>
                  ) : (
                    <li data-testid={`groupType-${testID}`}>Group Type: {confirmData?.group_type}</li>
                  )}

                  <li data-testid={`OM-${testID}`}>Office Manager: {confirmData?.office_manager_name}</li>
                </>

                <li data-testid={`counselor-${testID}`}>Counselor: {confirmData?.counselor_name}</li>

                <li data-testid={`provider-${testID}`}>Provider: {confirmData?.provider_name}</li>

                <div data-testid={`btns-${testID}`} className="flex mt-4">
                  <button
                    className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                    onClick={props.onClose}
                    type="button"
                    data-testid={`backBtn-${testID}`}
                  >
                    Back
                  </button>

                  <button
                    className="btn btn--rounded btn--primary flex-1 ml-2"
                    type="button"
                    onClick={props.onConfirm}
                    data-testid={`continueBtn-${testID}`}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

MembersWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool,

  confirmData: PropTypes.object.isRequired,
  memberName: PropTypes.string.isRequired,
  mrn: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
};

MembersWarningModal.defaultProps = {
  secondaryGroupTypeFeatureFlag: false,
};

export default MembersWarningModal;
