/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// HOOKS
import useData from './hooks/use-data';

// COMPONENTS
import DateLocationRender from './DateLocationRender';

function DateLocationCell(props) {
  const { isLoaded, data } = useData(props);

  return (
    <td>
      <div className="m-4 leading-tight">
        {isLoaded ? (
          <DateLocationRender id={props.id} locationId={props.locationId} data={data} />
        ) : (
          <Skeleton variant="text" width={150} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

DateLocationCell.propTypes = {
  id: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,

  preloadedData: PropTypes.string,
};

DateLocationCell.defaultProps = {
  preloadedData: null,
};

export default DateLocationCell;
