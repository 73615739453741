import { useEffect, useState } from 'react';

const useCachedShipmentData = (labelName) => {
  const [labelAddress, setLabelAddress] = useState({});
  const [labelContents, setLabelContents] = useState({});
  const [labelCompleted, setLabelCompleted] = useState(false);

  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const fetchCachedShipmentData = async (name) => {
    const add = parseData(`shipment-addresses-${name}`);
    const content = parseData(`shipment-contents-${name}`);
    const completed = parseData(`shipment-${name}-completed`);

    setLabelAddress(add || {});
    setLabelContents(content || {});
    setLabelCompleted(completed);

    return { add, content, completed };
  };

  const fetchCacheContentsData = (name, members) => {
    let uds = 0;
    let fent = 0;
    let preg = 0;
    let oral = 0;
    let env = 0;
    members?.forEach((member) => {
      let udsVal = parseData(`${name}-${member?.value}-shipment-contents-uds`)?.value;
      if (udsVal !== 0 && !udsVal) {
        udsVal = 6;
      }
      uds += udsVal;
      let fenVal = parseData(`${name}-${member?.value}-shipment-contents-fentanyl`)?.value;
      if (fenVal !== 0 && !fenVal) {
        fenVal = 2;
      }
      fent += fenVal;
      preg += parseData(`${name}-${member?.value}-shipment-contents-pregnancy`)?.value || 0;
      oral += parseData(`${name}-${member?.value}-shipment-contents-oral`)?.value || 0;
      env += parseData(`${name}-${member?.value}-shipment-contents-envelopes`)?.value || 0;
    });

    return {
      uds,
      fent,
      preg,
      oral,
      env,
    };
  };

  const fetchCacheContentsByMember = (name, member) => {
    let udsVal = parseData(`${name}-${member?.value}-shipment-contents-uds`)?.value;
    if (udsVal !== 0 && !udsVal) {
      udsVal = 6;
    }
    const uds = udsVal;
    let fenVal = parseData(`${name}-${member?.value}-shipment-contents-fentanyl`)?.value;
    if (fenVal !== 0 && !fenVal) {
      fenVal = 2;
    }
    const fent = fenVal;

    const preg = parseData(`${name}-${member?.value}-shipment-contents-pregnancy`)?.value || 0;
    const oral = parseData(`${name}-${member?.value}-shipment-contents-oral`)?.value || 0;
    const env = parseData(`${name}-${member?.value}-shipment-contents-envelopes`)?.value || 0;

    return {
      uds,
      fent,
      preg,
      oral,
      env,
    };
  };

  useEffect(() => {
    fetchCachedShipmentData(labelName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelName]);

  return {
    labelAddress,
    labelContents,
    labelCompleted,
    fetchCachedShipmentData,
    fetchCacheContentsData,
    fetchCacheContentsByMember,
  };
};

export default useCachedShipmentData;
