import * as Yup from 'yup';

const coSignChartingNoteFormValidationSchema = Yup.object()
  .shape({
    author: Yup.object()
      .shape({ label: Yup.string().required(), value: Yup.string().required() })
      .required('Author is required'),
    templates: Yup.array()
      .of(Yup.object().shape({ label: Yup.string().required(), value: Yup.string().required() }))
      .min(1, 'At least one template must be selected')
      .required('Templates are required'),
  })
  .required();

export default coSignChartingNoteFormValidationSchema;
