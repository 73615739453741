import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditableSelectRowValue from './EditableSelectRowValue';
import DetailsRow from './DetailsRow';

const VALUES_LIST = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

function BoolDetailsRow({ initialValue, label, name, isLoadingRequest, handleMemberEdit, editKey }) {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <DetailsRow label={label}>
      <EditableSelectRowValue
        name={name}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        isLoading={isLoadingRequest}
        onSave={() => handleMemberEdit({ [editKey]: selectedValue?.value }, setIsEditing)}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        valuesList={VALUES_LIST}
      />
    </DetailsRow>
  );
}

BoolDetailsRow.propTypes = {
  initialValue: PropTypes.shape({
    value: PropTypes.bool,
    label: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isLoadingRequest: PropTypes.bool.isRequired,
  handleMemberEdit: PropTypes.func.isRequired,
  editKey: PropTypes.string.isRequired,
};

export default BoolDetailsRow;
