import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../shared/Button';
import SelectInput from '../../../shared/SelectInput';
import SelectAndTextInput from '../../../shared/SelectAndTextInput';
import { ATTENDANCE_STATUSES, ATTENDED_ATTENDANCE_STATUS, MISSING_LOCATION_MESSAGE } from '../shared/constants';
import useAttendanceTracker from './useAttendanceTracker';
import { IndividualAppointmentCamelCasePropTypes, OptionsPropTypes } from '../shared/types';

function AttendanceTracker({
  appointment,
  setAppointment,
  reasons,
  locations,
  addToast,
  authenticityToken,
  canUserManageAppointment,
}) {
  const {
    columns,
    status,
    setStatus,
    reason,
    setReason,
    reasonsMap,
    isReasonRequired,
    location,
    setLocation,
    isLocationAllowed,
    isReasonColumnVisible,
    handleSave,
    isSaveDisabled,
    disabledMessage,
    isLoading,
  } = useAttendanceTracker({
    appointment,
    setAppointment,
    addToast,
    reasons,
    locations,
    authenticityToken,
    canUserManageAppointment,
  });
  const locationRef = useRef();
  const gridColumnsStyle = `repeat(${isReasonColumnVisible ? '3' : '2'}, 1fr) 100px`;
  const locationWarning = isLocationAllowed && !location ? MISSING_LOCATION_MESSAGE : null;

  useEffect(() => {
    if (status?.value === ATTENDED_ATTENDANCE_STATUS.value && !location?.value) locationRef.current.focus();
  }, [status, location]);

  return (
    <div className="border border-gray-400 shadow-md rounded-lg bg-white flex flex-col">
      <div
        className="px-3 py-4 grid items-center border-b-2 border-gray-300 gap-4"
        style={{ gridTemplateColumns: gridColumnsStyle }}
      >
        {columns.map(
          ({ label, isHidden, isRequired }) =>
            isHidden || (
              <div className="font-semibold flex" key={label}>
                <span>{label}</span>
                {isRequired && <span className="text-sm text-error-500">*</span>}
              </div>
            )
        )}
        <span />
      </div>
      <div className="px-3 py-4 grid items-start gap-4" style={{ gridTemplateColumns: gridColumnsStyle }}>
        <SelectInput
          name="status"
          value={status}
          onChange={setStatus}
          options={ATTENDANCE_STATUSES}
          placeholder="Select status"
          disabled={!canUserManageAppointment}
        />
        {isReasonColumnVisible && (
          <SelectInput
            name="reason"
            value={isReasonRequired ? reason : null}
            onChange={setReason}
            options={reasonsMap[status?.value] || reasons}
            placeholder="Select reason"
            disabled={!canUserManageAppointment}
          />
        )}
        <div className="flex flex-col gap-2">
          <SelectAndTextInput
            ref={locationRef}
            name="location"
            value={isLocationAllowed ? location : null}
            onChange={setLocation}
            options={locations}
            placeholder="Select location"
            disabled={!isLocationAllowed || !canUserManageAppointment}
            warning={locationWarning}
            isOtherOptionEnabled
          />
          {isLocationAllowed && !location?.wasCreated && location?.fullAddress && (
            <span className="text-sm text-gray-700">{location.fullAddress}</span>
          )}
        </div>
        <div className="flex justify-center">
          <Button
            isSecondary
            onClick={handleSave}
            disabled={isSaveDisabled}
            disabledMessage={disabledMessage}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

AttendanceTracker.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  addToast: PropTypes.func.isRequired,
  appointment: IndividualAppointmentCamelCasePropTypes.isRequired,
  reasons: OptionsPropTypes.isRequired,
  locations: OptionsPropTypes.isRequired,
  setAppointment: PropTypes.func.isRequired,
  canUserManageAppointment: PropTypes.bool.isRequired,
};

export default AttendanceTracker;
