import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import GeneralCell from '../../GeneralCell';
import { formatWithTimezone } from '../../../helpers/TimezoneHelper';

function GroupMeetingCell({ testID, groupAppointment }) {
  if (!groupAppointment?.appointmentId) {
    return (
      <GeneralCell
        testID={`upcoming-group-meeting-${testID}`}
        data="None scheduled"
        classes="text-gray-700 italic mt-2 mb-2"
      />
    );
  }

  const formattedDate = moment(groupAppointment.groupDateTime).format('dddd MMM. D, YYYY');
  const formattedTime = formatWithTimezone(groupAppointment.groupDateTime);

  return (
    <GeneralCell
      testID={`upcoming-group-meeting-${testID}`}
      classes="pt-4 pb-6"
      data={
        <div className="flex flex-col gap-2">
          {groupAppointment.isMakeupGroup && (
            <div className="flex items-center gap-2 text-gray-700 border border-gray-300 self-start px-2 rounded-xl">
              <HiOutlineSwitchHorizontal />
              <span>Makeup Group</span>
            </div>
          )}
          <span className="text-teal-700 font-semibold underline">{formattedDate}</span>
          <div className="flex flex-row gap-2 items-center">
            <span className="text-gray-700 text-sm">{formattedTime}</span>
            <div
              data-testid={`location-${testID}`}
              className="ml-2 text-sm bg-gray-300 py-1 px-2 rounded text-gray-900"
            >
              {groupAppointment.locationName}
            </div>
          </div>
        </div>
      }
    />
  );
}

GroupMeetingCell.propTypes = {
  testID: propTypes.string.isRequired,
  groupAppointment: propTypes.shape({
    appointmentId: propTypes.number,
    appointmentType: propTypes.string,
    isMakeupGroup: propTypes.bool,
    groupDateTime: propTypes.string,
    locationId: propTypes.number,
    locationName: propTypes.string,
    providerId: propTypes.number,
    providerName: propTypes.string,
    counselorId: propTypes.number,
    counselorName: propTypes.string,
  }).isRequired,
};

export default GroupMeetingCell;
