// REACT IMPORTS
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// TABLE IMPORTS
import axios from 'axios';
import MembersEditModal from '../../MembersEditModal';
import MembersWarningModal from '../../MembersWarningModal';

// SERVICE IMPORTS
import useFetchData from './fetch-member-show-data';
import { formatDateTime } from '../../DateFormatHelper';

function Main(props) {
  // TABLE DATA
  const { groups, member } = useFetchData(props.id);
  const [memberData, setMemberData] = useState({});
  const [activeText, setActiveText] = useState('Not Empaneled');
  // const { editModalFilters } = useGroupData();

  // SET MODAL DATA
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [modalFilters, setModalFilters] = useState([]);

  const testID = 'membersPageMainEmp';

  useEffect(() => {
    setMemberData(member);
    setModalFilters(groups);
    const text = member.ecw_active ? 'Not Empaneled' : 'Inactive';
    setActiveText(text);
  }, [groups, member]);

  const edit = () => {
    const updatedParams = {
      is_edit: true,
      id: member?.members_empanelment_group_id,
      member_id: member.id,
      empanelment_id: editedData?.group_data?.id,
    };

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    axios.put('/staff/empanelment_members/update', updatedParams).then((response) => {
      setMemberData(response?.data?.row);
    });

    setIsOpenWarningModal(false);
    setIsOpenEditModal(false);
  };

  return (
    <>
      {!memberData.is_empaneled ? (
        <div
          data-testid={`notEmpaneledView-${testID}`}
          className="overflow-hidden mt-9 mb-12 bg-white shadow sm:rounded-lg"
        >
          <div className="px-4 py-5 sm:px-6 sm:grid sm:grid-cols-3">
            <dt>
              <h3 className="text-lg font-body font-semibold text-gray-900 sm:col-span-3">Empanelment </h3>
            </dt>
            <dd className="flex text-base text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow font-bold text-error-700">{activeText}</span>
              <span className="flex-shrink-0 ml-4">
                <button
                  type="button"
                  className="btn btn--tertiary btn--sm -my-2"
                  onClick={setIsOpenEditModal}
                  disabled={!memberData.ecw_active}
                  data-testid={`addEmpanelmentBtn-${testID}`}
                >
                  Add
                </button>
              </span>
            </dd>
          </div>
        </div>
      ) : (
        <div
          data-testid={`empaneledView-${testID}`}
          className="overflow-hidden mt-9 mb-12 bg-white shadow sm:rounded-lg"
        >
          <div className="px-4 py-5 sm:px-6 sm:grid sm:grid-cols-3">
            <dt>
              <h3 className="text-lg font-body font-semibold text-gray-900 sm:col-span-3">Empanelment </h3>
            </dt>
            <dd>
              <span className="font-bold text-positive-700">Empaneled </span>
              {/* TO-DO: Implement historical data later - using created_at for members empanelment group timestamp */}
              {/* <span className="mt-1 text-base text-gray-700 sm:mt-0 sm:col-span-2">
                <time dateTime="2022-05-16T18:28:40Z" data-local="time" data-format="on %B %d, %Y at %I:%M %p" title="May 16, 2022 at 2:28pm EDT" data-localized="" aria-label="on May 16, 2022 at 02:28 PM">on May 16, 2022 at 02:28 PM</time>
              </span> */}
            </dd>
          </div>
          <div>
            <dl>
              <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                <dt data-testid={`groupEmp-${testID}`} className="text-base font-medium text-gray-700">
                  Group Empanelment
                </dt>
                <dd
                  data-testid={`groupDateTimeLocation-${testID}`}
                  className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  <time>{formatDateTime(memberData?.empanelment_column?.empanelment_date_time)}</time>{' '}
                  {memberData?.empanelment_column?.empanelment_location_name}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                <dt className="text-base font-medium text-gray-700">Counselor</dt>
                <dd
                  data-testid={`groupCounselor-${testID}`}
                  className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {memberData?.empanelment_column?.empanelment_counselor_name}
                </dd>
              </div>
              <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-300">
                <dt className="text-base font-medium text-gray-700">Provider</dt>
                <dd
                  data-testid={`groupProvider-${testID}`}
                  className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {memberData?.empanelment_column?.empanelment_provider_name}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}

      {/* EDIT MODAL */}
      <MembersEditModal
        isOpen={isOpenEditModal}
        currentGroup={member}
        empanelmentGroups={modalFilters}
        onContinue={(data) => {
          setEditedData(data);
          setIsOpenWarningModal(true);
        }}
        testID={testID}
      />

      {/* WARNING MODAL for EDIT */}
      <MembersWarningModal
        isOpen={isOpenWarningModal}
        confirmData={editedData}
        onConfirm={edit}
        onClose={() => {
          setIsOpenWarningModal(false);
          setIsOpenEditModal(false);
        }}
        testID={testID}
      />
    </>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Main;
