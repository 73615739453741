import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Select from 'react-select';

// HELPERS
import { customStyles, theme } from '../../helpers/FilterStyles';
import { removeAllSelection } from '../../helpers/DashboardFilterHelpers';

function DashboardFilters(props) {
  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOption] = useState(props.data[0]);

  useMemo(() => {
    setFilters(props.data);

    if (props.placeholder) setSelectedOption(null);
    if (props.defaultValue) setSelectedOption(props.defaultValue);
  }, [props.data, props.defaultValue, props.placeholder]);

  const filter = (selectedFilters) => {
    const updatedSelectedFilters = removeAllSelection(props.data[0], selectedFilters);

    setSelectedOption(updatedSelectedFilters);

    props.stateChanger(updatedSelectedFilters);
    props.onFilter(props.type, updatedSelectedFilters);
  };

  return (
    <div className="flex-auto relative inline-block">
      {filters.length > 0 && (
        <div>
          <div data-testid={`label-${props.testID}`} className="filter-label">
            {props.label}
          </div>

          <Select
            placeholder={props.placeholder ? props.placeholder : 'Search'}
            value={selectedOptions}
            isMulti={props.isMulti}
            options={filters}
            className="basic-multi-select"
            onChange={filter}
            styles={customStyles}
            theme={theme}
            components={
              props.removeArrowIndicator ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}
            }
          />
        </div>
      )}
      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

DashboardFilters.propTypes = {
  testID: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  removeArrowIndicator: PropTypes.bool,

  // functions
  stateChanger: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
};

DashboardFilters.defaultProps = {
  /** data {array}
   * @param data {array} array of objects that contain filter data
   *
   * element in array
   * @param dataElement.value The value of the filter object (i.e. ID of staff member)
   * @param dataElement.label The label shown for the filter object
   *
   * example: [
   *   { value: '*', label: 'All Staff' },
   *   { value: 1, label: 'Example Staff'},
   *   { value: 2, label: 'SecondExample StaffTwo' }
   * ]
   */
  data: [],
  defaultValue: null,
  isMulti: true,
  placeholder: null,
  error: null,
  removeArrowIndicator: false,
  onFilter: () => {},
};

export default DashboardFilters;
