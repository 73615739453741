import { useEffect, useState } from 'react';

import udsTestRecord from '../constants/uds-record';

const useDefaultUrl = (props, udsRecord, pregnancyRecord, fentanylRecord) => {
  const [defaultUrl, setDefaultUrl] = useState('');

  const { udsBack, udsFront, udsLeft, udsRight } = udsTestRecord(udsRecord);

  useEffect(() => {
    if (props.testView === 'uds') {
      // USE first id that is not null to set as default selected
      const defaultUdsUrl = udsBack.url || udsFront.url || udsLeft.url || udsRight.url;

      setDefaultUrl(defaultUdsUrl);
    } else if (props.testView === 'pregnancy') {
      setDefaultUrl(pregnancyRecord?.url);
    } else if (props.testView === 'fentanyl') {
      setDefaultUrl(fentanylRecord?.url);
    }
  }, [props.testView, udsBack.url, udsFront.url, udsLeft.url, udsRight.url, pregnancyRecord?.url, fentanylRecord?.url]);

  return {
    defaultUrl,
  };
};

export default useDefaultUrl;
