import React from 'react';
import PropTypes from 'prop-types';
import MembersNotesGroup from './MembersNotesGroup';
import { MemberNotesFormAnswerGroupPropType, MemberPropType } from './helpers/types';

function MembersNotesGrid({
  formAnswerGroups,
  members,
  authenticityToken,
  onChartingNotesDelete,
  onRowClick,
  isEditingDisabled,
  onChartingNoteLockAndSign,
  onApplyAnswerToAll,
}) {
  return (
    <>
      <div className="always-visible-scrollbar overflow-x-scroll max-h-screen-80">
        <table className="w-full table-fixed h-full">
          <tbody className="divide-y divide-gray-300">
            {formAnswerGroups.length ? (
              formAnswerGroups.map((formAnswerGroup) => (
                <MembersNotesGroup
                  key={formAnswerGroup?.id}
                  formAnswerGroup={formAnswerGroup}
                  members={members}
                  onRowClick={onRowClick}
                  authenticityToken={authenticityToken}
                  onChartingNotesDelete={onChartingNotesDelete}
                  onChartingNoteLockAndSign={onChartingNoteLockAndSign}
                  onApplyAnswerToAll={onApplyAnswerToAll}
                  isEditingDisabled={isEditingDisabled}
                />
              ))
            ) : (
              <MembersNotesGroup members={members} />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

MembersNotesGrid.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  formAnswerGroups: PropTypes.arrayOf(MemberNotesFormAnswerGroupPropType),
  members: PropTypes.arrayOf(MemberPropType),
  onChartingNotesDelete: PropTypes.func.isRequired,
  onChartingNoteLockAndSign: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onApplyAnswerToAll: PropTypes.func.isRequired,
  isEditingDisabled: PropTypes.bool,
};

MembersNotesGrid.defaultProps = {
  formAnswerGroups: [],
  members: [],
  isEditingDisabled: false,
};

export default MembersNotesGrid;
