import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import TableHeader from '../shared_dashboard/TableHeader';
import TableFooter from '../shared_dashboard/TableFooter';
import WeeklyGroupsTableRow from './WeeklyGroupsTableRow';
import CustomPagination from '../shared_dashboard/CustomPagination';

function WeeklyGroupsTable(props) {
  const handleChangePage = (_event, newPage) => {
    props.onPageChange(newPage);
  };

  return (
    <section>
      <div id="group_filterrific_results">
        <div className="relative overflow-visible">
          <div className="rounded-lg border border-gray-400 shadow-md overflow-auto">
            <table className="bg-white rounded-lg overflow-auto text-black w-full table-auto whitespace-nowrap">
              <TableHeader headers={props.headers} />

              <tbody>
                {props.response.map((data) => (
                  <WeeklyGroupsTableRow key={data.id} data={data} />
                ))}

                <TableFooter
                  firstRangeValue={props.firstRangeValue}
                  lastRangeValue={props.lastRangeValue}
                  totalCount={props.totalCount}
                />
              </tbody>
            </table>
          </div>

          <CustomPagination
            show={props.totalPages > 1}
            page={props.page}
            totalPages={props.totalPages}
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </section>
  );
}

WeeklyGroupsTable.propTypes = {
  headers: PropTypes.array.isRequired,
  response: PropTypes.array.isRequired,

  // FOOTER
  firstRangeValue: PropTypes.number,
  lastRangeValue: PropTypes.number,
  totalCount: PropTypes.number,

  // PAGE LOGIC
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
};

WeeklyGroupsTable.defaultProps = {
  // FOOTER
  firstRangeValue: 1,
  lastRangeValue: 1,
  totalCount: 1,

  // PAGE LOGIC
  page: 1,
  totalPages: 1,
  onPageChange: () => {},
};

export default WeeklyGroupsTable;
