import React from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import SimpleTable from '../shared/SimpleTable';
import { getParsedValue } from './helpers/healthieMatrixHelpers';

function HealthieMatrixReadonly({ label, value }) {
  const parsedValue = getParsedValue(value);

  if (!parsedValue) return '-';

  return (
    <div data-testid="healthie-matrix-readonly">
      {label && (
        <p data-testid="healthie-matrix-readonly-label" className="text-base font-semibold">
          {label}
        </p>
      )}
      <SimpleTable headers={parsedValue.headers}>
        {parsedValue.rows?.map((row) => (
          <tr key={row.id}>
            {row.items?.map((rowItem) => (
              <td key={rowItem.id}>
                {rowItem.type === 'checkbox' && rowItem.value ? (
                  <div data-testid="healthie-matrix-readonly-checkmark-icon">
                    <IoIosCheckmarkCircleOutline className="w-4 h-4 text-gray-700" />
                  </div>
                ) : (
                  rowItem.value
                )}
              </td>
            ))}
          </tr>
        ))}
      </SimpleTable>
    </div>
  );
}

HealthieMatrixReadonly.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
};

HealthieMatrixReadonly.defaultProps = {
  label: null,
};

export default HealthieMatrixReadonly;
