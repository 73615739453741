/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function TableFooter(props) {
  return (
    <tr className="rounded-bl-lg rounded-br-lg">
      <td colSpan="12">
        <p className="m-4 text-center text-gray-700">
          Showing{' '}
          <b>
            {' '}
            {props.firstRangeValue} - {props.lastRangeValue}{' '}
          </b>{' '}
          of <b> {props.totalCount} </b>
        </p>
      </td>
    </tr>
  );
}

TableFooter.propTypes = {
  firstRangeValue: PropTypes.number,
  lastRangeValue: PropTypes.number,
  totalCount: PropTypes.number,
};

TableFooter.defaultProps = {
  firstRangeValue: 1,
  lastRangeValue: 1,
  totalCount: 1,
};

export default TableFooter;
