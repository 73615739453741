const useClearCachedShipmentData = () => {
  const parseData = (itemKey) => {
    const cachedData = window.localStorage.getItem(itemKey);
    const parsedData = JSON.parse(cachedData);

    return parsedData;
  };

  const clearCachedLabelData = async (label) => {
    const labelName = label?.labelName;

    const add = parseData(`shipment-addresses-${labelName}`);

    add?.members?.forEach((member) => {
      window.localStorage.removeItem(`${labelName}-${member?.value}-shipment-contents-uds`);
      window.localStorage.removeItem(`${labelName}-${member?.value}-shipment-contents-fentanyl`);
      window.localStorage.removeItem(`${labelName}-${member?.value}-shipment-contents-pregnancy`);
      window.localStorage.removeItem(`${labelName}-${member?.value}-shipment-contents-oral`);
      window.localStorage.removeItem(`${labelName}-${member?.value}-shipment-contents-envelopes`);
    });

    window.localStorage.removeItem(`shipment-addresses-${labelName}`);
    window.localStorage.removeItem(`shipment-contents-${labelName}`);
    window.localStorage.removeItem(`shipment-${labelName}-completed`);
  };

  const clearCachedShipmentData = async (labels) => {
    labels.forEach((label) => {
      clearCachedLabelData(label);
    });

    window.localStorage.removeItem('shipment-labels');
    window.localStorage.removeItem('shipment-label-ids');
  };

  return {
    clearCachedShipmentData,
    clearCachedLabelData,
  };
};

export default useClearCachedShipmentData;
