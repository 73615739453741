import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function GeneralCell(props) {
  const { data, WrapperElement } = props;
  const testID = `generalCell-${props.testID}`;

  return (
    <td className={props.containerClasses}>
      <WrapperElement data-testid={testID} className={classnames('mx-4 mt-2 mb-0', props.classes)}>
        {data}
      </WrapperElement>
      {props.subText && <p className="text-sm font-light text-gray-700 mx-4 mt-0.5">{props.subText}</p>}
    </td>
  );
}

GeneralCell.propTypes = {
  classes: PropTypes.string,
  containerClasses: PropTypes.string,
  testID: PropTypes.string.isRequired,
  data: PropTypes.oneOf([
    // string or children
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  subText: PropTypes.string,
  WrapperElement: PropTypes.oneOf(['p', 'div', 'span']),
};

GeneralCell.defaultProps = {
  classes: '',
  containerClasses: '',
  subText: '',
  WrapperElement: 'p',
};

export default GeneralCell;
