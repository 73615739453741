import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function TableLoadMorePagination(props) {
  return (
    <nav className="react-pagination" role="navigation" aria-label="pager">
      <div data-testid={`pageNav-${props.testID}`} className="pagination-block">
        {/* PAGE NUMBERS */}
        {props.showPageNumbers && (
          <div className="page-number-label">
            Showing
            {props.displayPaginationStartingElementIndex && (
              <>
                <b className="page-number-text">1</b>
                <b className="page-number-text">-</b>
              </>
            )}
            <b data-testid={`rowCount-pageNav-${props.testID}`} className="page-number-text">
              {props.tableRows.length}
            </b>
            of
            <b data-testid={`totalCount-pageNav-${props.testID}`} className="page-number-text">
              {props.totalCountRecords}
            </b>
          </div>
        )}

        {/* LOAD MORE - PAGINATION BUTTON */}
        {props.hideLoadMoreButton || (
          <button
            data-testid={`loadMoreBtn-${props.testID}`}
            className="btn btn--secondary btn--sm w-full block mt-6 next-button"
            onClick={props.handleChangePage}
            disabled={props.isLastPage || props.loading}
            type="button"
          >
            {props.loading && <CircularProgress size={16} sx={{ marginRight: '10px', marginLeft: '-20px' }} />}
            Load More
          </button>
        )}
      </div>
    </nav>
  );
}

TableLoadMorePagination.propTypes = {
  testID: PropTypes.string.isRequired,
  tableRows: PropTypes.array.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  totalCountRecords: PropTypes.number.isRequired,
  displayPaginationStartingElementIndex: PropTypes.bool.isRequired,
  hideLoadMoreButton: PropTypes.bool.isRequired,
  showPageNumbers: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TableLoadMorePagination;
