import { useEffect, useState } from 'react';
import axios from 'axios';

const useExpandData = (isOpen, data) => {
  const [memberGroup, setMemberGroup] = useState(null);
  const [memberEmpanelment, setMemberEmpanelment] = useState(null);
  const [memberTasks, setMemberTasks] = useState(null);

  const fetchMemberGroup = async () => {
    if (memberGroup) {
      return;
    }

    try {
      const params = { member_id: data?.member_id };
      const { data: response } = await axios.get('/staff/daily_tasks/fetch_member_group', { params });
      setMemberGroup(response);
    } catch (error) {
      window?.Sentry?.captureException(error);
    }
  };

  const fetchMemberEmpanelment = async () => {
    if (memberEmpanelment) {
      return;
    }

    try {
      const params = { empanelment_id: data?.empanelment_id };
      const { data: response } = await axios.get('/staff/daily_tasks/fetch_member_empanelment', { params });
      setMemberEmpanelment(response);
    } catch (error) {
      window?.Sentry?.captureException(error);
    }
  };

  const fetchMemberTasks = async () => {
    try {
      const params = { task_id: data?.id, empanelment_id: data?.empanelment_id, member_id: data?.member_id };
      const { data: response } = await axios.get('/staff/daily_tasks/fetch_member_tasks', { params });
      setMemberTasks(response);
    } catch (error) {
      window?.Sentry?.captureException(error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    fetchMemberGroup();
    fetchMemberEmpanelment();
    fetchMemberTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, memberGroup]);

  return {
    memberGroup,
    memberEmpanelment,
    memberTasks,
    fetchMemberTasks,
  };
};

export default useExpandData;
