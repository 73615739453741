import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../../../GroupsModal';
import useHttp from '../../../shared/hooks/use-http';
import useToasts from '../../../shared/hooks/use-toasts';
import Toasts from '../../../shared/Toasts';

function DeleteFacilityConfirmationModal({ isOpen, onClose, onSave, staffMemberFacility, authenticityToken }) {
  const { isLoading, sendRequest } = useHttp();
  const { toasts, addToast, removeToast } = useToasts();

  const handleDelete = async () => {
    try {
      await sendRequest(`/staff/staff_member_facilities/${staffMemberFacility.id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });

      addToast({ header: 'Record has been successfully deleted.' });
      onClose();
      await onSave();
    } catch (error) {
      window.Sentry.captureException(error);
      addToast({
        header: 'Something went wrong. Record has not been deleted.',
        type: 'error',
      });
      onClose();
    }
  };

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Delete',
      isWarning: true,
      onClick: handleDelete,
      isLoading,
    },
  ];

  return (
    <>
      <Toasts toasts={toasts} removeToast={removeToast} isSecondaryVariant />
      <GroupsModal
        testID="delete-facility-confirmation-modal"
        isOpen={isOpen}
        header="Delete Record"
        footerButtons={footerButtons}
        isLoading={isLoading}
      >
        <div className="bg-white rounded-lg shadow-sm">
          <div className="px-6 py-4 text-lg border-b-2 border-gray-300">
            <p>Are you sure you want to delete this facility? This action cannot be undone.</p>
          </div>
          <div className="px-6 py-4">
            <p className="mb-2">
              <span className="text-base font-semibold mr-1">Staff:</span>
              {staffMemberFacility?.staffMember.fullName}
            </p>
            <p className="mb-2">
              <span className="text-base font-semibold mr-1">Facility:</span>
              {staffMemberFacility?.locations.map((location) => location.name).join(', ')}
            </p>
            <p>
              <span className="text-base font-semibold mr-1">Appointment Type:</span>
              {staffMemberFacility?.appointmentTypes.join(', ')}
            </p>
          </div>
        </div>
      </GroupsModal>
    </>
  );
}

DeleteFacilityConfirmationModal.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  staffMemberFacility: PropTypes.shape({
    id: PropTypes.number,
    staffMember: PropTypes.shape({ id: PropTypes.number, fullName: PropTypes.string }),
    locations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    appointmentTypes: PropTypes.arrayOf(PropTypes.string),
  }),
};

DeleteFacilityConfirmationModal.defaultProps = {
  isOpen: false,
  staffMemberFacility: null,
};

export default DeleteFacilityConfirmationModal;
