/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import PhotosModal from './PhotosModal';

function ScreeningLinkColumn(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="items-center text-center">
      <Link
        href="#"
        sx={{ justifyContent: 'center', width: '80%', color: '#237786' }}
        className="m-4 flex-row flex underline text-center items-center"
        onClick={handleOpen}
      >
        <ZoomInIcon />
        View
      </Link>

      {open && (
        <PhotosModal
          open={open}
          handleClose={handleClose}
          memberName={props.memberName}
          memberId={props.memberId}
          testView={props.testView}
          testIds={props.testIds}
          authenticityToken={props.authenticityToken}
        />
      )}
    </div>
  );
}

ScreeningLinkColumn.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  memberId: PropTypes.number.isRequired,
  memberName: PropTypes.string.isRequired,
  testView: PropTypes.string.isRequired,
  testIds: PropTypes.object.isRequired,
};

export default ScreeningLinkColumn;
