import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../GroupsModal';

function ApplyAnswerToAllModal({ isOpen, onClose, onContinue, isLoading }) {
  return (
    <GroupsModal
      testID="apply-answer-to-all-modal"
      isOpen={isOpen}
      header="Apply answer to the group"
      footerButtons={[
        {
          label: 'Cancel',
          isSecondary: true,
          onClick: onClose,
          disabled: isLoading,
        },
        {
          label: 'Continue',
          isPrimary: true,
          onClick: onContinue,
          isLoading,
        },
      ]}
    >
      <div className="bg-white rounded-lg shadow-sm">
        <div className="px-6 py-4">
          You are about to apply this answer to the entire group for this question. This action will replace other
          answers. Do you want to continue?
        </div>
      </div>
    </GroupsModal>
  );
}

ApplyAnswerToAllModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ApplyAnswerToAllModal.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default ApplyAnswerToAllModal;
