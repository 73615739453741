const tabs = [
  {
    id: 1,
    name: 'All Members',
    key: 'all',
  },
  {
    id: 2,
    name: 'Added to Care',
    key: 'added',
  },
  {
    id: 3,
    name: 'Left Care',
    key: 'removed',
  },
];

export default tabs;
