import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import StatusTag from './StatusTag';
import NumeratorDenominator from './NumeratorDenominator';

function NumeratorDenominatorContainer(props) {
  const { label, status } = props.statusTag || {};
  const numDemonStatus = props.noStatus ? null : status;

  return (
    <div className={`p-2 ${props.classes}`}>
      <div className="font-semibold text-xs mb-1">{props.label}</div>

      <div className="flex">
        <NumeratorDenominator numerator={props.numerator} denominator={props.denominator} status={numDemonStatus} />
        <StatusTag label={label} status={status} classes="mt-auto mb-1 ml-2" />
      </div>
    </div>
  );
}

NumeratorDenominatorContainer.propTypes = {
  label: PropTypes.string.isRequired,
  numerator: PropTypes.number.isRequired,
  denominator: PropTypes.number.isRequired,
  statusTag: PropTypes.object.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
  noStatus: PropTypes.bool,
};

NumeratorDenominatorContainer.defaultProps = {
  classes: '',
  noStatus: false,
};

export default NumeratorDenominatorContainer;
