/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';

// COMPONENTS
import Filters from '../../Filters';
import Header from '../../shared/Header';
import TextArea from '../../shared/TextArea';
import PhoneInput from '../../shared/PhoneInput';
import PageContainer from '../../shared/PageContainer';

// UDS COMPONENTS
import InputCheckbox from '../shared/InputCheckbox';
import ShipmentInput from '../shared/ShipmentInput';
import MemberSearchFilter from '../shared/MemberSearchFilter';

// HELPERS
import { labelStyle, addressSubtextStyle, customFieldContainer } from '../shared/styles';

// HOOKS
import useSharedFilters from '../../shared/hooks/use-shared-filters';

function DeliverTo(props) {
  const { allStates, fetchAllStates } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchAllStates(), []);

  // CACHED DATA
  const { members, memberEmail, memberPhone, memberLocation, notes, _toEmailMemberNotifications } =
    props.labelAddress || {};
  const { address_information, stateSelectedFilter } = memberLocation || {};
  const { company, street1, street2, city, _state, zip } = address_information || {};

  // SET DATA
  const [address, setAddress] = useState({});
  useEffect(() => setAddress(props.labelAddress), [props.labelAddress]);

  // FETCH MEMBER ADDRESSES
  const [membersAddresses, setMembersAddresses] = useState([{ label: 'Other', value: '*' }]);
  const [memberSelectedLocation, setMemberSelectedLocation] = useState(memberLocation);
  useEffect(() => {
    setMemberSelectedLocation(memberLocation);
  }, [memberLocation]);

  const [sendEmailNotifications, setSendEmailNotifications] = useState(false);
  useEffect(
    () => setSendEmailNotifications(props.labelAddress?.toEmailMemberNotifications),
    [props.labelAddress?.toEmailMemberNotifications]
  );

  const getMemberAddresses = (selectedMembers) => {
    const params = { member_ids: selectedMembers?.map((m) => m.value) };

    axios
      .get('/staff/members/fetch_member_addresses', { params })
      .then((response) => {
        setMembersAddresses(response.data);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  // FETCH MEMBER INFO
  const [memberEm, setMemberEm] = useState('');
  useEffect(() => setMemberEm(memberEmail), [memberEmail]);

  const [memberPh, setMemberPh] = useState('');
  useEffect(() => setMemberPh(memberPhone), [memberPhone]);

  const fetchMemberInfo = async (memberList) => {
    if (memberList.length > 0) {
      const firstMemberId = memberList[0]?.value;

      const params = { id: firstMemberId };
      axios
        .get('/staff/members/get_member_info', { params })
        .then((response) => {
          const data = response?.data;

          setMemberEm(data?.email);
          setMemberPh(data?.phone);

          address.memberEmail = data?.email;
          address.memberPhone = data?.phone;
          address.toEmailMemberNotifications = true;

          props.onSetData(address);
        })
        .catch((error) => {
          window.Sentry.captureException(error);
        });
    }
  };

  // FETCH MEMBER ADDRESSES ON LOAD FROM CACHING
  useEffect(() => {
    if (members) {
      getMemberAddresses(members);
    }
  }, [members]);

  return (
    <>
      <Header text="Deliver To" withBottomMargin withNoUnderline />

      {/* MEMBERS */}
      <MemberSearchFilter
        members={members}
        label="Member(s)*"
        formErrors={props.formErrors}
        onSelect={(selected) => {
          address.members = selected;

          getMemberAddresses(selected);
          fetchMemberInfo(selected);
          setSendEmailNotifications(true);

          props.onSetData(address);
        }}
        error={props.formErrors.members}
      />

      <div style={{ ...addressSubtextStyle, marginTop: '9px', marginBottom: '20px' }}>
        For multi-member shipments, only the first member’s name will appear on the label.
      </div>

      <Filters
        style={{ marginBottom: memberSelectedLocation?.value === '*' ? '0px' : '20px' }}
        label="Address*"
        data={membersAddresses}
        placeholder="Search Addresses"
        value={memberSelectedLocation}
        stateChanger={(selected) => {
          address.memberLocation = selected;
          setMemberSelectedLocation(selected);

          props.onSetData(address);
        }}
        labelStyle={labelStyle}
        isMulti={false}
        error={props.formErrors.memberLocation}
      />

      {memberSelectedLocation?.value === '*' && (
        // ************* ******************* ************* //
        // ************* CUSTOM ADDRESS FORM ************* //
        // ************* ******************* ************* //
        <PageContainer style={customFieldContainer}>
          <ShipmentInput
            classes="flex-1"
            style={{ marginBottom: '20px' }}
            label="Company"
            value={company}
            stateChanger={(selected) => {
              if (address.memberLocation?.address_information) {
                address.memberLocation.address_information.company = selected;
              } else {
                address.memberLocation.address_information = {};
                address.memberLocation.address_information.company = selected;
              }
              props.onSetData(address);
            }}
          />

          <div className="flex mb-4">
            <ShipmentInput
              classes="flex-1"
              label="Address 1*"
              value={street1}
              stateChanger={(selected) => {
                if (address.memberLocation?.address_information) {
                  address.memberLocation.address_information.street1 = selected;
                } else {
                  address.memberLocation.address_information = {};
                  address.memberLocation.address_information.street1 = selected;
                }
                props.onSetData(address);
              }}
              error={props.formErrors.street1}
            />

            <ShipmentInput
              classes="flex-1"
              style={{ marginLeft: '20px' }}
              label="Address 2"
              value={street2}
              stateChanger={(selected) => {
                if (address.memberLocation?.address_information) {
                  address.memberLocation.address_information.street2 = selected;
                } else {
                  address.memberLocation.address_information = {};
                  address.memberLocation.address_information.street2 = selected;
                }
                props.onSetData(address);
              }}
            />
          </div>

          <div className="flex mb-4">
            <ShipmentInput
              classes="flex-1"
              label="City*"
              value={city}
              stateChanger={(selected) => {
                if (address.memberLocation?.address_information) {
                  address.memberLocation.address_information.city = selected;
                } else {
                  address.memberLocation.address_information = {};
                  address.memberLocation.address_information.city = selected;
                }
                props.onSetData(address);
              }}
              error={props.formErrors.city}
            />

            <Filters
              label="State*"
              data={allStates}
              value={stateSelectedFilter}
              stateChanger={(selected) => {
                if (address.memberLocation?.address_information) {
                  address.memberLocation.address_information.state = selected?.value;
                  address.memberLocation.stateSelectedFilter = selected;
                } else {
                  address.memberLocation.address_information = {};
                  address.memberLocation.address_information.state = selected?.value;
                  address.memberLocation.stateSelectedFilter = selected;
                }
                props.onSetData(address);
              }}
              isMulti={false}
              classes="flex-1"
              labelStyle={labelStyle}
              style={{ marginRight: '0px', marginLeft: '20px' }}
              error={props.formErrors.state}
            />

            <ShipmentInput
              classes="flex-1"
              style={{ marginLeft: '20px' }}
              label="Zip*"
              value={zip}
              stateChanger={(selected) => {
                if (address.memberLocation?.address_information) {
                  address.memberLocation.address_information.zip = selected;
                } else {
                  address.memberLocation.address_information = {};
                  address.memberLocation.address_information.zip = selected;
                }
                props.onSetData(address);
              }}
              error={props.formErrors.zip}
            />
          </div>
        </PageContainer>
        // ************* ******************* ************* //
        // ************* CUSTOM ADDRESS FORM ************* //
        // ************* ******************* ************* //
      )}

      <div className="flex" style={{ marginBottom: '20px' }}>
        {/* MEMBER EMAIL */}
        <ShipmentInput
          classes="flex-1"
          style={{ marginRight: '20px' }}
          label="Member Email Address*"
          value={memberEm}
          stateChanger={(selected) => {
            address.memberEmail = selected;
            props.onSetData(address);
          }}
          error={props.formErrors.memberEmail}
        />

        {/* MEMBER PHONE */}
        <PhoneInput
          classes="flex-1"
          label="Member Phone Number*"
          value={memberPh}
          stateChanger={(selected) => {
            address.memberPhone = selected;
            props.onSetData(address);
          }}
          error={props.formErrors.memberPhone}
        />
      </div>

      <InputCheckbox
        show
        isChecked={sendEmailNotifications}
        text="This member consents to receive shipment email notifications."
        onChange={(e) => {
          address.toEmailMemberNotifications = e.target.checked;

          setSendEmailNotifications(e.target.checked);
          props.onSetData(address);
        }}
        showTooltip
        tooltipText="They will receive one email with the tracking link and a second with the final delivery status."
      />

      <TextArea
        label="Notes"
        subtext="This note will only be seen in the Digital Platform. All clinical notes must go into eCW."
        value={notes}
        labelStyle={{ ...labelStyle, marginTop: '20px' }}
        onChange={(value) => {
          address.notes = value;
          props.onSetData(address);
        }}
      />
    </>
  );
}

DeliverTo.propTypes = {
  labelAddress: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSetData: PropTypes.func.isRequired,
};

export default DeliverTo;
