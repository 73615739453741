import React from 'react';
import PropTypes from 'prop-types';
import { BiLockAlt, BiLockOpenAlt } from 'react-icons/bi';
import GeneralCell from '../../GeneralCell';

export function NotesCell({ testID, notes }) {
  return (
    <GeneralCell
      testID={testID}
      WrapperElement="div"
      data={
        <ol className="flex flex-col gap-1.5">
          {notes.map((note) => (
            <li key={note.externalId} className="flex flex-row items-center gap-1">
              {note.isLocked ? (
                <BiLockAlt className="text-gray-500 w-6 h-6" />
              ) : (
                <BiLockOpenAlt className="text-teal-700 w-6 h-6" />
              )}
              <span>{note.name}</span>
            </li>
          ))}
        </ol>
      }
    />
  );
}

export const noteType = PropTypes.shape({
  externalId: PropTypes.string,
  isLocked: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
});

NotesCell.propTypes = {
  testID: PropTypes.string,
  notes: PropTypes.arrayOf(noteType),
};

NotesCell.defaultProps = {
  testID: '',
  notes: [],
};

export default NotesCell;
