import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AttendanceAppointment from './AttendanceAppointment';
import { AppointmentAttendancePropTypes } from './types';

function AttendanceWeek({ weekDate, appointments }) {
  const date = moment(weekDate).format('M/D/YYYY');

  return (
    <div className="border border-gray-300 rounded">
      <h4 className="font-body text-sm font-semibold px-4 py-2 bg-blue-100">{`Week of ${date}`}</h4>
      <div className="flex flex-col gap-2 p-2">
        {!appointments?.length ? (
          <p className="text-gray-700 px-3 py-1">No appointments scheduled</p>
        ) : (
          appointments.map(({ dateTime, attendanceStatus, provider, screenings }, i) => (
            <AttendanceAppointment
              // eslint-disable-next-line
              key={i}
              dateTime={dateTime}
              attendanceStatus={attendanceStatus}
              provider={provider}
              screenings={screenings}
            />
          ))
        )}
      </div>
    </div>
  );
}

AttendanceWeek.propTypes = {
  weekDate: PropTypes.string.isRequired,
  appointments: PropTypes.arrayOf(PropTypes.shape(AppointmentAttendancePropTypes).isRequired).isRequired,
};

export default AttendanceWeek;
