const tabs = [
  {
    id: 1,
    name: 'All Members',
    key: 'allMembers',
  },
  {
    id: 2,
    name: 'Not Empaneled',
    key: 'notEmpaneled',
  },
  {
    id: 3,
    name: 'Empaneled',
    key: 'isEmpaneled',
  },
  {
    id: 4,
    name: 'Flagged',
    key: 'isFlagged',
  },
];

export default tabs;
