/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { statusData } from './constants';

export const prepareEditTaskModalDefaultValues = (prescriptionTaskDetails, prescriptionReasons) => {
  const currentPrescriptionReason = prescriptionReasons.find(
    (reason) => reason.label === prescriptionTaskDetails.reason
  );

  return {
    dueDate: prescriptionTaskDetails.dueDate && moment(prescriptionTaskDetails.dueDate).utc(),
    status: {
      value: prescriptionTaskDetails.status,
      label:
        statusData.find((status) => status.value === prescriptionTaskDetails.status)?.label ??
        prescriptionTaskDetails.status,
    },
    reason: currentPrescriptionReason,
    note: prescriptionTaskDetails.note,
  };
};
