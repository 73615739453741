import { useEffect, useState } from 'react';
import useHttp from '../../../shared/hooks/use-http';

const useFetchData = () => {
  const { isLoading, sendRequest, error } = useHttp();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await sendRequest('/staff/appointment_occurrences/fetch_filters');
        const { locations, states, appointment_types, days, availability, providers } = response;

        setFilterData({
          statesData: states,
          facilitiesData: locations,
          providersData: providers,
          daysData: days,
          availabilityData: availability,
          appointmentTypesData: appointment_types,
        });
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchFilters();
  }, [sendRequest]);

  return { ...filterData, isLoading, error };
};

export default useFetchData;
