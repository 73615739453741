import React from 'react';
import PropTypes from 'prop-types';

function PageContainer(props) {
  return (
    <section>
      <div id="group_filterrific_results" className="mt-5" style={props.style}>
        <div className="relative overflow-visible" style={props.subStyle}>
          {props.children}
        </div>
      </div>
    </section>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  subStyle: PropTypes.object,
};

PageContainer.defaultProps = {
  children: null,
  style: {},
  subStyle: {},
};

export default PageContainer;
