import React from 'react';
import PropTypes from 'prop-types';

// ICONS
import CloseIcon from '@mui/icons-material/Close';

// COMPONENTS
import Button from '../shared/Button';

// HELPERS
import { verticalLineStyle, buttonStyle } from './constants/CustomStyle';
import { parseLabelsFromArray, hasArrayValues } from '../../helpers/DashboardFilterHelpers';

function TaskFilterButton(props) {
  const buttonClass = 'flex mr-2 my-3';
  const closeIconClass = 'ml-2 mx-auto float-right flex cursor-pointer';

  const hasValues = () => {
    if (props.isSingleValue) {
      return props?.data != null || props?.data?.label !== undefined;
    }
    return hasArrayValues(props.data);
  };
  const parseValues = () => {
    if (props.isSingleValue) {
      return props?.data?.label || props?.data;
    }
    return parseLabelsFromArray(props.data);
  };
  const trackFilterClick = () => {
    window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'filter-deselected', props?.label);
  };

  return (
    <Button show={hasValues()} isPrimary style={buttonStyle} classes={buttonClass} testID={props.testID}>
      {`${props.label}: ${parseValues()}`}
      <div style={verticalLineStyle} />
      <CloseIcon
        onClick={() => {
          props.onClear();
          trackFilterClick();
        }}
        className={closeIconClass}
      />
    </Button>
  );
}

TaskFilterButton.propTypes = {
  data: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isSingleValue: PropTypes.bool,
  onClear: PropTypes.func,
  testID: PropTypes.string,
};

TaskFilterButton.defaultProps = {
  isSingleValue: false,
  onClear: () => {},
  testID: undefined,
};

export default TaskFilterButton;
