import React, { useState } from 'react';
import PropTypes from 'prop-types';

function CosignWarningModal({ isOpen, member, onCancel, onContinue }) {
  const [loading, setLoading] = useState(false);

  const handleContinue = () => {
    setLoading(true);
    onContinue();
  };

  const handleCancel = () => {
    setLoading(false);
    onCancel();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
            <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-lg sm:my-8 sm:w-full text-left z-20">
              {loading ? (
                <div className="p-6 w-full whitespace-normal">
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="p-6 w-full whitespace-normal">
                  <h2 className="font-bold mb-4">Are you sure?</h2>
                  <p>
                    You are confirming to co-sign the following Care Plan for
                    <strong>{` ${member.name} (${member.external_id})`}</strong>
                  </p>

                  <div className="flex mt-4">
                    <button
                      className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                      onClick={handleCancel}
                      type="button"
                    >
                      Back
                    </button>

                    <button
                      className="btn btn--rounded btn--primary flex-1 ml-2"
                      type="button"
                      onClick={handleContinue}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
          </div>
        </div>
      )}
    </>
  );
}

CosignWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    external_id: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CosignWarningModal.defaultProps = {};

export default CosignWarningModal;
