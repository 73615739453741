// recordId: the overall record the photos are attached to
// id: attachment record id
const fentanylTestRecord = (fentanylRecord) => ({
  showFentanylSection: fentanylRecord?.show_fentanyl,
  fentanylRecordId: fentanylRecord?.record?.id,
  fentanylId: fentanylRecord?.attachment_record?.id,
  fentanylTestDate: fentanylRecord?.attachment_record?.created_at,
  fentanylTestUrl: fentanylRecord?.url,
});

export default fentanylTestRecord;
