import React from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../../shared/hooks/use-http';
import GroupsModal from '../../../GroupsModal';

function AddSelfAsZoomHostConfirmationModal({ isOpen, onClose, appointmentId, authenticityToken, addToast }) {
  const { sendRequest, isLoading } = useHttp();

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(`/staff/appointment_occurrences/${appointmentId}/add_alternative_meeting_host`, {
        method: 'POST',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
      });

      addToast({
        header: 'You have been added as Zoom host',
        type: 'success',
      });
      onClose();
    } catch (err) {
      addToast({
        header: err.parsedMessage ?? 'Something went wrong. Try again later.',
        type: 'error',
        isError: true,
      });
      window.Sentry.captureException(err);
      onClose();
    }
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Add Self as Zoom Host"
      footerButtons={[
        {
          label: 'No, go back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          label: 'Yes, add',
          isPrimary: true,
          onClick: onSubmit,
          isLoading,
        },
      ]}
      size="medium"
      isLoading={isLoading}
    >
      <div className="bg-white px-6 py-4 rounded-md shadow-md">
        <span>Are you sure you want to add self as Zoom host?</span>
      </div>
    </GroupsModal>
  );
}

AddSelfAsZoomHostConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  appointmentId: PropTypes.number.isRequired,
  addToast: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default AddSelfAsZoomHostConfirmationModal;
