import * as Yup from 'yup';
import moment from 'moment-timezone';
import { isEmptyObject, isNotPastGroupDateSchema } from './helpers';
import { CLINICAL_MODEL_LABELS } from '../helpers/constants';
import { areStringsEqual } from '../helpers/utils';
import { getCurrentDateTimeByTimezone } from '../helpers/TimezoneHelper';

const requiredText = 'This field is required';

const GROUPS_DAY_DIFFERENCE = {
  standardCareModel: {
    days: [-21, -14, -7, 7, 14, 21],
    label: '7, 14 or 21',
  },
  stepdown: {
    days: [-14, 14],
    label: '14',
  },
  weeklyProviderGroup: {
    days: [0],
    label: '0',
  },
};

const getClinicalModelLabel = (label) =>
  label
    ?.split(' ')
    .map((item, i) => (i === 0 ? item.toLowerCase() : item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()))
    .join('');

const getRequiredGroupsDayDifference = (label) => {
  const clinicalModelLabel = getClinicalModelLabel(label);
  return GROUPS_DAY_DIFFERENCE[clinicalModelLabel] || GROUPS_DAY_DIFFERENCE.standardCareModel;
};

export default Yup.object().shape({
  facility: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  counselor: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  provider: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  officeManager: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  dayOfWeek: Yup.string().required(requiredText),
  startTime: Yup.object()
    .required(requiredText)
    .when(
      ['timezone', 'counselorGroupOccurrence', 'providerGroupOccurrence', 'clinicalModel'],
      ([timezone, counselorGroupOccurrence, providerGroupOccurrence, clinicalModel], schema) => {
        const isWeeklyProviderGroup = areStringsEqual(
          clinicalModel?.label,
          CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP
        );
        const currentDateTimeByTimezone = getCurrentDateTimeByTimezone(timezone?.value);

        const skipValidation =
          !timezone?.value ||
          !providerGroupOccurrence ||
          (!counselorGroupOccurrence && !isWeeklyProviderGroup) ||
          (moment(providerGroupOccurrence)?.isAfter(currentDateTimeByTimezone, 'day') &&
            moment(counselorGroupOccurrence)?.isAfter(currentDateTimeByTimezone, 'day') &&
            !isWeeklyProviderGroup) ||
          (moment(providerGroupOccurrence)?.isAfter(currentDateTimeByTimezone, 'day') && isWeeklyProviderGroup);

        if (skipValidation) return schema;

        return schema.test('is-not-past-time', 'Must not be past time', (value) => {
          const startTimeValue = moment(value).tz(timezone?.value, true).set({
            year: currentDateTimeByTimezone.year(),
            month: currentDateTimeByTimezone.month(),
            date: currentDateTimeByTimezone.date(),
          });

          return !currentDateTimeByTimezone.isAfter(startTimeValue, 'minute');
        });
      }
    ),
  clinicalModel: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  counselorGroupOccurrence: Yup.date()
    .when('clinicalModel', ([clinicalModel], schema) => {
      if (areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP)) {
        return schema;
      }
      return schema.required(requiredText);
    })
    .when(['dayOfWeek', 'clinicalModel'], ([dayOfWeek, clinicalModel], schema) => {
      if (!dayOfWeek) {
        return schema;
      }
      if (areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP)) {
        return schema;
      }
      return schema.test('is-same-day', 'Must be the same day as specified in day of week field', (value) => {
        const dayOfWeekValue = moment(value).day(dayOfWeek).startOf('day');
        const valueDate = moment(value).startOf('day');
        return dayOfWeekValue.isSame(valueDate);
      });
    })
    .when(['providerGroupOccurrence', 'clinicalModel'], ([providerGroupOccurrence, clinicalModel], schema) => {
      if (!providerGroupOccurrence || !clinicalModel) {
        return schema;
      }
      if (areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP)) {
        return schema;
      }
      // This case is handled by the providerGroupOccurrence field validation
      if (areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.STEPDOWN)) {
        return schema;
      }

      const requiredGroupsDayDifference = getRequiredGroupsDayDifference(clinicalModel.label);

      return schema.test(
        'is-difference-in-days-correct',
        `Difference between counselor and provider groups must equal ${requiredGroupsDayDifference.label} days`,
        (value) => {
          const normalizedValue = moment(value).startOf('day');
          const normalizedProviderGroupOccurance = moment(providerGroupOccurrence).startOf('day');

          const differenceInDays = moment(normalizedValue).diff(moment(normalizedProviderGroupOccurance), 'days');

          return requiredGroupsDayDifference.days.includes(differenceInDays);
        }
      );
    })
    .when('timezone', isNotPastGroupDateSchema),
  providerGroupOccurrence: Yup.date()
    .required(requiredText)
    .when('dayOfWeek', ([dayOfWeek], schema) => {
      if (!dayOfWeek) {
        return schema;
      }
      return schema.test('is-same-day', 'Must be the same day as specified in day of week', (value) => {
        const dayOfWeekValue = moment(value).day(dayOfWeek).startOf('day');
        const valueDate = moment(value).startOf('day');
        return dayOfWeekValue.isSame(valueDate);
      });
    })
    .when('timezone', isNotPastGroupDateSchema)
    .test(
      'stepdown-groups-interval',
      'In Stepdown Clinical Model group occurance should be every other week',
      (value, { parent }) => {
        const { counselorGroupOccurrence, clinicalModel } = parent;
        const isStepdown = areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.STEPDOWN);

        if (!counselorGroupOccurrence || !clinicalModel || !value || !isStepdown) {
          return true;
        }

        const normalizedValue = moment(value).startOf('day');
        const normalizedCounselorGroupOccurrence = moment(counselorGroupOccurrence).startOf('day');

        const differenceInDays = moment(normalizedValue).diff(moment(normalizedCounselorGroupOccurrence), 'days');

        return GROUPS_DAY_DIFFERENCE.stepdown.days.includes(differenceInDays);
      }
    ),
  timezone: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  modality: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  secondaryGroupTypes: Yup.array(),
});
