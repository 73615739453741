import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import TimezoneSelect from 'react-timezone-select';
import Filters from './Filters';
import RadioSelect from './shared/RadioSelect';
import DayPicker from './shared/DayPicker';
import ModalError from './shared/ModalError';

// HELPER IMPORTS
import { customStyles, theme } from '../helpers/FilterStyles';

function CreateModal(props) {
  const format = 'h:mm a';
  const testID = `createModal-${props.testID}`;

  const defaultGroupType =
    Array.isArray(props.empanelmentGroupTypeFilters) &&
    props.empanelmentGroupTypeFilters.find((options) => options.label === 'Standard Care Model');

  // SET EDITED DATA
  const [status, setStatus] = useState('Active');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [startTime, setStartTime] = useState('');
  const [timezone, setTimezone] = useState({});
  const [facility, setFacility] = useState({});
  const [officeManager, setOfficeManager] = useState({});
  const [modality, setModality] = useState({});
  const [groupType, setGroupType] = useState({});
  const [secondaryGroupTypes, setSecondaryGroupTypes] = useState({});
  const [counselor, setCounselor] = useState({});
  const [provider, setProvider] = useState({});

  useEffect(() => {
    setGroupType(defaultGroupType);
  }, [setGroupType, defaultGroupType]);

  // VALIDATIONS
  // This is all not meant to be reusable but will need to be refactored. Validations
  // should be done in their individual pages, not on a modal.
  const requiredText = 'This field is required';
  const requiredActiveText = 'This field is required when status is "Active"';

  const [errorsVisible, setErrorsVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const isEmptyObject = (obj) => {
    if (obj === undefined) {
      return true;
    }
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return true;
    }
    return obj.value === null;
  };

  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateMeetingFacility = () => {
    if (isEmptyObject(facility)) {
      setError('facility', requiredText);
    } else {
      setError('facility', null);
    }
  };

  const validateCounselor = () => {
    if (isEmptyObject(counselor)) {
      setError('counselor', requiredText);
    } else {
      setError('counselor', null);
    }
  };

  const validateProvider = () => {
    if (isEmptyObject(provider)) {
      setError('provider', requiredText);
    } else {
      setError('provider', null);
    }
  };

  const validateOfficeManager = () => {
    if (status === 'Active' && isEmptyObject(officeManager)) {
      setError('officeManager', requiredActiveText);
    } else {
      setError('officeManager', null);
    }
  };

  const validateDay = () => {
    if (dayOfWeek === '') {
      setError('dayOfWeek', requiredText);
    } else {
      setError('dayOfWeek', null);
    }
  };

  const validateTime = () => {
    if (startTime === '') {
      setError('startTime', requiredText);
    } else {
      setError('startTime', null);
    }
  };

  const validateTimezone = () => {
    if (isEmptyObject(timezone)) {
      setError('timezone', requiredText);
    } else {
      setError('timezone', null);
    }
  };

  const validateModality = () => {
    if (status === 'Active' && isEmptyObject(modality)) {
      setError('modality', requiredActiveText);
    } else {
      setError('modality', null);
    }
  };

  const validateGroupType = () => {
    if (status === 'Active' && isEmptyObject(groupType)) {
      setError('groupType', requiredActiveText);
    } else {
      setError('groupType', null);
    }
  };

  // @validateSecondaryGroupType HELPER FUNCTION
  // CONCAT error string of related selected but not allowed values
  const typeNamesConcatenated = (typeNames) => {
    const namesOfNotAllowed = typeNames.filter(
      (type) => type === 'Women Only' || type === 'Pregnancy' || type === 'Men Only' || type === 'LGBTQ+'
    );
    const last = namesOfNotAllowed.pop();

    return `${namesOfNotAllowed.join(', ')} and ${last}`;
  };

  const validateSecondaryGroupType = (types) => {
    // DO NOT ALLOW FOR WOMEN ONLY + PREG GROUPS TO BE SELECTED AT THE SAME TIME AS MEN ONLY GROUP
    const typeNames = types.map((type) => type.label);

    const isPregnancy = typeNames?.includes('Pregnancy');
    const isLGBTSelected = typeNames?.includes('LGBTQ+');

    const isWomanSelected = typeNames?.includes('Women Only');
    const isMenSelected = typeNames?.includes('Men Only');

    if ((isPregnancy || isWomanSelected) && isMenSelected) {
      setError('empanelmentSecondaryGroupTypeData', `${typeNamesConcatenated(typeNames)} cannot be applied together`);
    } else if ((isWomanSelected || isMenSelected) && isLGBTSelected) {
      setError('empanelmentSecondaryGroupTypeData', `${typeNamesConcatenated(typeNames)} cannot be applied together`);
    } else {
      setError('empanelmentSecondaryGroupTypeData', null);
    }
  };

  const validateData = () => {
    validateMeetingFacility();
    validateCounselor();
    validateProvider();
    validateDay();
    validateTime();
    validateTimezone();
    validateOfficeManager();
    validateModality();
    validateGroupType();
  };

  useEffect(() => {
    validateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, counselor, provider, officeManager, dayOfWeek, startTime, timezone, modality, groupType, status]);

  // CALLBACkS

  // When clicking back the form itself would be empty but state was
  // being held in the modal, so the confirm modal would not always match
  // the form... This needs a larger rework as part of a form refactor.
  const clearState = () => {
    setStatus('Active');
    setDayOfWeek('');
    setStartTime('');
    setTimezone({});
    setFacility({});
    setOfficeManager({});
    setModality({});
    setGroupType({});
    setSecondaryGroupTypes({});
    setCounselor({});
    setProvider({});
    setErrorsVisible(false);
  };

  const handleSelect = (event) => {
    setStatus(event.target.value);
  };

  const errorsExist = () => !Object.values(formErrors).every((v) => v === null);

  const handleClick = () => {
    setErrorsVisible(true);

    const time = startTime && startTime.format(format);

    if (!errorsExist()) {
      // // Modals need a better error handling method than this.
      // // Work for the refactor.
      props.onContinue({
        status,
        dayOfWeek,
        startTime: time,
        timezone,
        facility,
        counselor,
        provider,
        officeManager,
        modality,
        groupType,
        secondaryGroupTypes,
      });
    }
  };

  const handleCancel = () => {
    props.onCancel();
    clearState();
    setGroupType(defaultGroupType);
  };

  return (
    <>
      {props.secondaryGroupTypeFeatureFlag ? (
        <>
          {props.isOpen && (
            <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
              <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
                <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-6xl sm:my-8 sm:w-full text-left z-20">
                  <div className="p-6 w-full whitespace-normal">
                    {/* HEADER */}
                    <h2 className="font-bold mb-4">Create Care Team</h2>

                    <ModalError errors={props.flashMessage} />

                    <p>*Required field</p>
                    <form>
                      <dl className="bg-white flex flex-col rounded-b-lg">
                        <RadioSelect
                          label="Status*"
                          options={['Active', 'Pending']}
                          onChange={handleSelect}
                          value={status}
                        />

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Day of the Week*</dt>
                            <DayPicker
                              defaultValue={null}
                              onChange={setDayOfWeek}
                              error={errorsVisible && formErrors.dayOfWeek}
                            />
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Start Time*</dt>
                            <div
                              className={`mx-6 pb-5 time-picker-container ${formErrors.startTime && errorsVisible && 'danger'}`}
                            >
                              <TimePicker
                                showSecond={false}
                                defaultValue={startTime}
                                placeholder="--:-- --"
                                className="xxx"
                                onChange={setStartTime}
                                use12Hours
                                inputReadOnly
                              />
                              {formErrors.startTime && errorsVisible && (
                                <p className="text-red-500 text-xs italic">{formErrors.startTime}</p>
                              )}
                            </div>
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Time Zone*</dt>

                            <div className={`mx-6 pb-5 ${formErrors.timezone && errorsVisible && 'danger'}`}>
                              <TimezoneSelect
                                value={timezone}
                                onChange={setTimezone}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select"
                                styles={customStyles}
                                theme={theme}
                                timezones={{
                                  'US/Eastern': 'US/Eastern',
                                  'US/Central': 'US/Central',
                                  'US/Mountain': 'US/Mountain',
                                  'US/Arizona': 'US/Arizona',
                                  'US/Pacific': 'US/Pacific',
                                  'US/Hawaii': 'US/Hawaii',
                                }}
                              />
                              {formErrors.timezone && errorsVisible && (
                                <p className="text-red-500 text-xs italic">{formErrors.timezone}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            {/* GROUP MEETING FACILITY FILTERS */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility*</dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="facilities"
                                data={props.facilitiesFilters}
                                stateChanger={setFacility}
                                placeholder="Select"
                                error={errorsVisible && formErrors.facility}
                                data-testid={`facilities-${testID}`}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">
                              Modality
                              {status === 'Active' && '*'}
                            </dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="modalities"
                                data={props.modalityFilters}
                                stateChanger={setModality}
                                placeholder="Select"
                                error={errorsVisible && formErrors.modality}
                                data-testid={`modalities-${testID}`}
                              />
                            </dd>
                          </div>
                        </div>

                        <hr className="w-full" />

                        {/* MODALITY AND GROUP PHASE FILTERS */}
                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">
                              Clinical Model
                              {status === 'Active' && '*'}
                            </dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                defaultValue={defaultGroupType}
                                isMulti={false}
                                removeAllSelection
                                type="groupTypes"
                                data={props.empanelmentGroupTypeFilters}
                                stateChanger={setGroupType}
                                error={errorsVisible && formErrors.groupType}
                                data-testid={`groupTypes-${testID}`}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Other Information</dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                isMulti
                                removeAllSelection
                                type="secondaryGroupTypes"
                                data={props.empanelmentSecondaryGroupTypeData}
                                stateChanger={(newTypes) => {
                                  setSecondaryGroupTypes(newTypes);
                                  validateSecondaryGroupType(newTypes);
                                }}
                                placeholder="Select"
                                data-testid={`secondary-group-types-${testID}`}
                                error={formErrors.empanelmentSecondaryGroupTypeData}
                              />
                            </dd>
                          </div>
                        </div>

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            {/* OFFICE MANAGER FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">
                              Office Manager
                              {status === 'Active' && '*'}
                            </dt>

                            <dd className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="office_managers"
                                data={props.officeManagerFilters}
                                stateChanger={setOfficeManager}
                                placeholder="Select"
                                error={errorsVisible && formErrors.officeManager}
                                data-testid={`officeManagers-${testID}`}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            {/* COUNSELOR FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Counselor*</dt>

                            <dd className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="facilities"
                                data={props.staffFilters}
                                stateChanger={setCounselor}
                                placeholder="Select"
                                error={errorsVisible && formErrors.counselor}
                                data-testid={`counselors-${testID}`}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            {/* PROVIDER FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Provider*</dt>

                            <dd className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                defaultValue={false}
                                type="facilities"
                                data={props.staffFilters}
                                stateChanger={setProvider}
                                placeholder="Select"
                                error={errorsVisible && formErrors.provider}
                                data-testid={`providers-${testID}`}
                              />
                            </dd>
                          </div>
                        </div>
                      </dl>

                      {/* BUTTONS */}
                      <div className="flex mt-4">
                        <button
                          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                          onClick={handleCancel}
                          type="button"
                          data-testid={`backBtn-${testID}`}
                        >
                          Back
                        </button>

                        <button
                          className="btn btn--rounded btn--primary flex-1 ml-2"
                          type="button"
                          onClick={handleClick}
                          data-testid={`continueBtn-${testID}`}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {props.isOpen && (
            <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
              <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
                <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-2xl sm:my-8 sm:w-full text-left z-20">
                  <div className="p-6 w-full whitespace-normal">
                    {/* HEADER */}
                    <h2 className="font-bold mb-4">Create Care Team</h2>

                    <ModalError errors={props.flashMessage} />

                    <p>*Required field</p>
                    <form>
                      <dl className="bg-white flex flex-col rounded-b-lg">
                        <RadioSelect
                          label="Status*"
                          options={['Active', 'Pending']}
                          onChange={handleSelect}
                          value={status}
                        />

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Day of the Week*</dt>
                            <DayPicker
                              defaultValue={null}
                              onChange={setDayOfWeek}
                              error={errorsVisible && formErrors.dayOfWeek}
                            />
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Start Time*</dt>
                            <div
                              className={`mx-6 pb-5 time-picker-container ${formErrors.startTime && errorsVisible && 'danger'}`}
                            >
                              <TimePicker
                                showSecond={false}
                                defaultValue={startTime}
                                placeholder="--:-- --"
                                className="xxx"
                                onChange={setStartTime}
                                use12Hours
                                inputReadOnly
                              />
                              {formErrors.startTime && errorsVisible && (
                                <p className="text-red-500 text-xs italic">{formErrors.startTime}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <hr className="w-full" />

                        <dt className="font-bold mx-6 pb-2 pt-5">Time Zone*</dt>

                        <dd className={`mx-6 pb-5 ${formErrors.timezone && errorsVisible && 'danger'}`}>
                          <TimezoneSelect
                            value={timezone}
                            onChange={setTimezone}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select"
                            styles={customStyles}
                            theme={theme}
                            timezones={{
                              'US/Eastern': 'US/Eastern',
                              'US/Central': 'US/Central',
                              'US/Mountain': 'US/Mountain',
                              'US/Arizona': 'US/Arizona',
                              'US/Pacific': 'US/Pacific',
                              'US/Hawaii': 'US/Hawaii',
                            }}
                          />
                          {formErrors.timezone && errorsVisible && (
                            <p className="text-red-500 text-xs italic">{formErrors.timezone}</p>
                          )}
                        </dd>

                        <hr className="w-full" />

                        {/* GROUP MEETING FACILITY FILTERS */}
                        <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility*</dt>

                        <dd className="mx-6 pb-5">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            type="facilities"
                            data={props.facilitiesFilters}
                            stateChanger={setFacility}
                            placeholder="Select"
                            error={errorsVisible && formErrors.facility}
                            data-testid={`facilities-${testID}`}
                          />
                        </dd>

                        <hr className="w-full" />

                        {/* MODALITY AND GROUP PHASE FILTERS */}
                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">
                              Modality
                              {status === 'Active' && '*'}
                            </dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="modalities"
                                data={props.modalityFilters}
                                stateChanger={setModality}
                                placeholder="Select"
                                error={errorsVisible && formErrors.modality}
                                data-testid={`modalities-${testID}`}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">
                              Group Type
                              {status === 'Active' && '*'}
                            </dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                defaultValue={defaultGroupType}
                                isMulti={false}
                                removeAllSelection
                                type="groupTypes"
                                data={props.empanelmentGroupTypeFilters}
                                stateChanger={setGroupType}
                                error={errorsVisible && formErrors.groupType}
                                data-testid={`groupTypes-${testID}`}
                              />
                            </dd>
                          </div>
                        </div>

                        <hr className="w-full" />

                        {/* OFFICE MANAGER FILTER */}
                        <dt className="font-bold mx-6 pb-2 pt-5">
                          Office Manager
                          {status === 'Active' && '*'}
                        </dt>

                        <dd className="mx-6 pb-3">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            type="office_managers"
                            data={props.officeManagerFilters}
                            stateChanger={setOfficeManager}
                            placeholder="Select"
                            error={errorsVisible && formErrors.officeManager}
                            data-testid={`officeManagers-${testID}`}
                          />
                        </dd>

                        <hr className="w-full" />

                        {/* COUNSELOR FILTER */}
                        <dt className="font-bold mx-6 pb-2 pt-5">Counselor*</dt>

                        <dd className="mx-6 pb-3">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            type="facilities"
                            data={props.staffFilters}
                            stateChanger={setCounselor}
                            placeholder="Select"
                            error={errorsVisible && formErrors.counselor}
                            data-testid={`counselors-${testID}`}
                          />
                        </dd>

                        <hr className="w-full" />

                        {/* PROVIDER FILTER */}
                        <dt className="font-bold mx-6 pb-2 pt-5">Provider*</dt>

                        <dd className="mx-6 pb-3">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            defaultValue={false}
                            type="facilities"
                            data={props.staffFilters}
                            stateChanger={setProvider}
                            placeholder="Select"
                            error={errorsVisible && formErrors.provider}
                            data-testid={`providers-${testID}`}
                          />
                        </dd>
                      </dl>

                      {/* BUTTONS */}
                      <div className="flex mt-4">
                        <button
                          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                          onClick={handleCancel}
                          type="button"
                          data-testid={`backBtn-${testID}`}
                        >
                          Back
                        </button>

                        <button
                          className="btn btn--rounded btn--primary flex-1 ml-2"
                          type="button"
                          onClick={handleClick}
                          data-testid={`continueBtn-${testID}`}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  testID: PropTypes.string.isRequired,

  // filters
  // statesFilters: PropTypes.array.isRequired,
  facilitiesFilters: PropTypes.array.isRequired,
  staffFilters: PropTypes.array.isRequired,
  officeManagerFilters: PropTypes.array.isRequired,
  modalityFilters: PropTypes.array.isRequired,
  empanelmentGroupTypeFilters: PropTypes.array.isRequired,
  empanelmentSecondaryGroupTypeData: PropTypes.array.isRequired,
  flashMessage: PropTypes.array,

  // functions
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

CreateModal.defaultProps = {
  onCancel: () => {},
  flashMessage: [],
};

export default CreateModal;
