import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Input from '../shared/Input';
import SelectInput from '../shared/SelectInput';
import SignatureInput from '../shared/SignatureInput';
import RichTextInput from '../shared/RichtextInput';
import CheckboxGroup from '../shared/CheckboxGroup';
import RadioGroup from '../shared/RadioGroup';
import RenderHTML from '../shared/RenderHTML';
import DateInput from '../shared/DateInput';
import HealthieAddressInput from './HealthieAddressInput';
import HealthieMatrixInput from './HealthieMatrixInput';
import HipaaNotice from './HipaaNotice';
import { SmartPhrasesPropType } from './helpers/types';
import './styles.css';

// eslint-disable-next-line react/display-name
const HealthieInput = forwardRef(
  (
    {
      name,
      value,
      onChange,
      onBlur,
      label,
      options,
      type,
      required,
      isVertical,
      error,
      disabled,
      isMulti,
      states,
      isProblemList,
      smartPhrases,
    },
    ref
  ) => {
    const sharedProps = {
      ref,
      name,
      value,
      onChange,
      onBlur,
      label,
      error,
      required,
      isVertical,
      disabled,
    };

    const components = {
      read_only: {
        component: RenderHTML,
        props: {
          html: options.join(''),
          classNames: 'healthie-input__read-only',
        },
      },
      text: {
        component: Input,
        props: sharedProps,
      },
      number: {
        component: Input,
        props: {
          ...sharedProps,
          type: 'number',
        },
      },
      date_picker: {
        component: DateInput,
        props: sharedProps,
      },
      date: {
        component: DateInput,
        props: sharedProps,
      },
      time: {
        component: DateInput,
        props: {
          ...sharedProps,
          picker: 'time',
        },
      },
      radio: {
        component: RadioGroup,
        props: {
          ...sharedProps,
          options,
        },
      },
      horizontal_radio: {
        component: RadioGroup,
        props: {
          ...sharedProps,
          options,
          isHorizontalRadio: true,
        },
      },
      checkbox: {
        component: CheckboxGroup,
        props: {
          ...sharedProps,
          options,
        },
      },
      dropdown: {
        component: SelectInput,
        props: {
          ...sharedProps,
          options: options.map((option) => {
            const optionLabel = isProblemList && option.tag ? `${option.tag} ${option.code}` : option;
            const optionValue =
              isProblemList && option.description ? `${optionLabel} ${option.description}` : optionLabel;
            return { label: optionLabel, value: optionValue };
          }),
          isMulti,
        },
      },
      signature: {
        component: SignatureInput,
        props: sharedProps,
      },
      textarea: {
        component: RichTextInput,
        props: {
          ...sharedProps,
          smartPhrases,
        },
      },
      phone: {
        component: Input,
        props: {
          ...sharedProps,
          type: 'tel',
        },
      },
      location: {
        component: HealthieAddressInput,
        props: {
          ...sharedProps,
          states,
        },
      },
      matrix: {
        component: HealthieMatrixInput,
        props: sharedProps,
      },
      hipaa: {
        component: HipaaNotice,
        props: {
          label,
          isVertical,
        },
      },
    };

    if (!components[type]) return null;

    const Component = components[type].component;
    return <Component {...components[type].props} />;
  }
);

const selectInputValue = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

HealthieInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    selectInputValue,
    PropTypes.arrayOf(selectInputValue),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  isVertical: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  states: PropTypes.objectOf(PropTypes.string),
  isProblemList: PropTypes.bool,
  smartPhrases: SmartPhrasesPropType,
};

HealthieInput.defaultProps = {
  isVertical: true,
  onBlur: null,
  error: null,
  disabled: false,
  isMulti: false,
  states: null,
  isProblemList: false,
  smartPhrases: null,
};

export default HealthieInput;
