export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : 'black',
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #237786' : '2px solid #e0e3e5',
    outlineOffset: state.isFocused ? '2px' : null,
    outline: state.isFocused ? '2px solid #237786' : null,
    borderRadius: '8px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#666666',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#f3f3f3',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#666666',
    '&:hover': {
      color: '#666666',
    },
    '& svg': {
      strokeWidth: '3',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#666666',
    '&:hover': {
      color: '#666666',
    },
    '& svg': {
      strokeWidth: '3',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: state?.hasValue ? 'block' : 'none',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 25,
  }),
};

export const theme = (parentTheme) => ({
  ...parentTheme,
  colors: {
    ...parentTheme.colors,
    primary75: '#237786',
    primary: '#237786',
    primary25: '#237786',
  },
});
