/* eslint-disable no-shadow */
// REACT IMPORTS
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import { FiEdit } from 'react-icons/fi';
import { MdCheck, MdOutlineFlag, MdFlag } from 'react-icons/md';

// TABLE IMPORTS
import axios from 'axios';
import GeneralCell from './GeneralCell';
import GroupCell from './GroupCell';
import MembersEditModal from './MembersEditModal';
import MembersWarningModal from './MembersWarningModal';
import MembersErrorModal from './MembersErrorModal';

// SERVICE IMPORTS
import { setMemberData } from './empanelment/member/set-member-data';

function MembersTableCell(props) {
  // SET ROW DATA
  const [rowData, setRow] = useState({});
  const testID = `membersTableCell-${props.testID}`;

  // SET MODAL DATA
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenWarningModalConfirm, setIsOpenWarningModalConfirm] = useState(false);
  const [editedData, setEditedData] = useState({});

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // FLAG - Toggle
  const [isFlagged, setIsFlagged] = useState(false);

  const {
    row,
    id,
    empanelment_group_id,
    members_empanelment_group_id,
    member_name,
    mrn,
    is_flagged,
    is_approved,
    is_empaneled,
    group_appointment_column,
    appointment_id,
    empanelment_column,
    counselor_name,
    provider_name,
    group_date_time,
    location_name,
    empanelment_date_time,
    empanelment_location_name,
    empanelment_office_manager_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_secondary_group_types,
    confirmData,
    appointment_type,
    is_makeup_group,
  } = setMemberData(props);

  useMemo(() => {
    setRow({
      row,
      id,
      empanelment_group_id,
      members_empanelment_group_id,
      member_name,
      mrn,
      is_flagged,
      is_approved,
      is_empaneled,
      group_appointment_column,
      appointment_id,
      empanelment_column,
      counselor_name,
      provider_name,
      group_date_time,
      location_name,
      empanelment_date_time,
      empanelment_location_name,
      empanelment_office_manager_name,
      empanelment_provider_name,
      empanelment_counselor_name,
      empanelment_group_modality,
      empanelment_group_type,
      empanelment_secondary_group_types,
      appointment_type,
      is_makeup_group,
    });

    setIsFlagged(is_flagged);
  }, [
    row,
    id,
    empanelment_group_id,
    members_empanelment_group_id,
    member_name,
    mrn,
    is_flagged,
    is_approved,
    is_empaneled,
    group_appointment_column,
    appointment_id,
    empanelment_column,
    counselor_name,
    provider_name,
    group_date_time,
    location_name,
    empanelment_date_time,
    empanelment_office_manager_name,
    empanelment_location_name,
    empanelment_provider_name,
    empanelment_counselor_name,
    empanelment_group_modality,
    empanelment_group_type,
    empanelment_secondary_group_types,
    appointment_type,
    is_makeup_group,
  ]);

  // FUNCTIONS

  const edit = () => {
    const updatedParams = {
      is_edit: true,
      id: rowData?.members_empanelment_group_id,
      member_id: rowData.id,
      empanelment_id: editedData?.group_data?.id,
    };

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    axios.put('/staff/empanelment_members/update', updatedParams).then((response) => {
      const rowData = setMemberData(response.data);

      props.updateRows('edit', rowData.row);
    });

    setIsOpenWarningModal(false);
    setIsOpenEditModal(false);
  };

  const addEmpanelment = async () => {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const updatedParams = {
      id: rowData.members_empanelment_group_id,
      member_id: rowData.id,
      appointment_id: rowData.group_appointment_column.appointment_id,
      empanelment_id: rowData.empanelment_column.empanelment_group_id,
      is_approved: true,
    };

    axios
      .put('/staff/empanelment_members/update', updatedParams)
      .then((response) => {
        const rowData = setMemberData(response.data);

        props.updateRows('approve', rowData.row);
      })
      .catch((error) => {
        setIsOpenWarningModalConfirm(false);
        const errorMsg = error?.response?.data?.error;
        setErrorMsg(errorMsg);

        setIsOpenErrorModal(true);
      });

    setIsOpenWarningModalConfirm(false);
  };

  const flag = async (rowData) => {
    // toggle flag value
    const flagValue = !rowData.is_flagged;

    const members_empanelment_groups_id = rowData.members_empanelment_group_id;
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    try {
      axios
        .put('/staff/empanelment_members/update', { id: members_empanelment_groups_id, is_flagged: flagValue })
        .then((response) => {
          const rowData = setMemberData(response.data);

          setIsFlagged(flagValue);
          props.updateRows('flag', rowData.row);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <GeneralCell testID={`memberName-${testID}`} data={rowData.member_name} subText={rowData.mrn} />

      <GroupCell
        isExpandable={false}
        isMakeupGroup={rowData.is_makeup_group}
        counselor={rowData.counselor_name}
        dateTime={rowData.group_date_time}
        location={rowData.location_name}
        provider={rowData.provider_name}
        testID={`upcomingGroup-${testID}`}
      />

      <GroupCell
        isEmpanelment
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        dateTime={rowData.empanelment_date_time}
        location={rowData.empanelment_location_name}
        provider={rowData.empanelment_provider_name}
        counselor={rowData.empanelment_counselor_name}
        officeManager={rowData.empanelment_office_manager_name}
        groupType={rowData.empanelment_group_type}
        secondaryGroupType={rowData.empanelment_secondary_group_types}
        modality={rowData.empanelment_group_modality}
        testID={`currentEmpGroup-${testID}`}
      />

      {/* ACTION BUTTONS */}
      <td data-testid={`actionBtns-${testID}`} className="shadow-inner ring-1 ring-black ring-opacity-5">
        <div className="flex justify-around p-5 empanelment-buttons">
          {/* APPROVE ACTION */}
          <button
            data-testid={`approveActionBtn-${testID}`}
            type="button"
            className="empanelment-button members-approve-button"
            disabled={is_empaneled || !appointment_id || is_makeup_group}
          >
            <MdCheck
              disabled={is_empaneled || !appointment_id || is_makeup_group}
              onClick={setIsOpenWarningModalConfirm}
            />
          </button>

          {/* EDIT ACTION */}
          <button data-testid={`editActionBtn-${testID}`} type="button" className="empanelment-button fi-edit-button">
            <FiEdit onClick={setIsOpenEditModal} />
          </button>

          {/* FLAG ACTION */}
          <button
            data-testid={`flagActionBtn-${testID}`}
            type="button"
            className="empanelment-button flag"
            disabled={!is_empaneled}
          >
            {isFlagged ? (
              <MdFlag onClick={() => flag(rowData)} />
            ) : (
              <MdOutlineFlag disabled={!is_empaneled} onClick={() => flag(rowData)} />
            )}
          </button>
        </div>
      </td>

      {/* EDIT MODAL */}
      <MembersEditModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenEditModal}
        currentGroup={rowData}
        empanelmentGroups={props.editModalFilters}
        onContinue={(data) => {
          setEditedData(data);
          setIsOpenWarningModal(true);
        }}
        testID={testID}
      />

      {/* WARNING MODAL for EDIT */}
      <MembersWarningModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenWarningModal}
        confirmData={editedData}
        onConfirm={edit}
        memberName={member_name}
        mrn={mrn}
        onClose={() => {
          setIsOpenWarningModal(false);
          setIsOpenEditModal(false);
        }}
        testID={`edit-${testID}`}
      />

      {/* WARNING MODAL for CONFIRM EMPANELMENT */}
      <MembersWarningModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenWarningModalConfirm}
        confirmData={confirmData}
        onConfirm={addEmpanelment}
        onClose={() => {
          setIsOpenWarningModalConfirm(false);
          setIsOpenEditModal(false);
        }}
        testID={`confirm-${testID}`}
      />

      {/* ERROR MODAL for ADD EMPANELMENT */}
      <MembersErrorModal
        isOpen={isOpenErrorModal}
        errorMessage={errorMsg}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
        testID={testID}
      />
    </>
  );
}

MembersTableCell.propTypes = {
  testID: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,

  editModalFilters: PropTypes.object.isRequired,

  // functions
  updateRows: PropTypes.func.isRequired,
};

export default MembersTableCell;
