import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import GenericModal from '../../shared/GenericModal';

// HELPERS
import { warningTextStyle } from './styles';

function CancelModal(props) {
  const confirm = () => {
    props.onConfirm();
  };
  const back = () => {
    props.onBack();
  };

  return (
    <GenericModal
      isOpen={props.isOpen}
      headerText="Are you sure?"
      primaryButtonLabel="Confirm"
      secondaryButtonLabel="Back"
      primaryButtonClick={() => confirm()}
      secondaryButtonClick={() => back()}
    >
      <div className="m-4">
        <div>{props.mainText}</div>
        <div style={warningTextStyle}>This action cannot be undone.</div>
      </div>
    </GenericModal>
  );
}

CancelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mainText: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CancelModal;
