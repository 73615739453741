import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import MedicalInformation from './MedicalInformation';
import ClinicalInformation from './ClinicalInformation';
import DemographicInformation from './DemographicInformation';
import AttendanceWeekContainer from './AttendanceWeekContainer';
import ThreeMonthBarViewContainer from './ThreeMonthBarViewContainer';
import MemberSummaryDetailsContainer from './MemberSummaryDetailsContainer';

function MemberSummaryDetails(props) {
  const flexClass = 'flex-1';

  const { threeMonthStats, medical, clinical, demographics, attendance } = props?.data || {};

  return (
    <div className="flex">
      {/* LEFT SIDE */}
      <MemberSummaryDetailsContainer classes={flexClass}>
        <MedicalInformation data={medical} />
        <ClinicalInformation data={clinical} />
        <DemographicInformation data={demographics} />
      </MemberSummaryDetailsContainer>

      {/* RIGHT SIDE */}
      <div className={flexClass}>
        {/* THREE MONTH VIEW */}
        <ThreeMonthBarViewContainer data={threeMonthStats} />

        {/* LOOP THRU WEEKS */}
        <AttendanceWeekContainer data={attendance} />
      </div>
    </div>
  );
}

MemberSummaryDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MemberSummaryDetails;
