import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CgZoomIn as ZoomIcon } from 'react-icons/cg';
import GeneralCell from './GeneralCell';
import PhotosCarouselModal from './PhotosCarouselModal';

function PhotosPreviewCell({ testID, photos, label }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  if (!photos) {
    return <GeneralCell testID={testID} data={null} />;
  }

  return (
    <GeneralCell
      testID={testID}
      data={
        <>
          <PhotosCarouselModal
            header={label}
            photos={photos}
            isOpen={isPreviewOpen}
            onClose={() => setIsPreviewOpen(false)}
          />
          <button
            type="button"
            className="flex items-center gap-0.5"
            onClick={() => setIsPreviewOpen(true)}
            data-testid="photo-button"
          >
            <ZoomIcon className="w-5 h-5 text-green-800" />
            <span className="text-green-800 underline">View</span>
          </button>
        </>
      }
    />
  );
}

PhotosPreviewCell.propTypes = {
  testID: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
};

PhotosPreviewCell.defaultProps = {
  testID: 'photos-status-cell',
  label: 'Photo Preview',
};

export default PhotosPreviewCell;
