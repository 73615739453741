import { useState } from 'react';
import uniq from 'lodash/uniq';

const useFilters = (defaultFilters) => {
  const [filters, setFilters] = useState(defaultFilters);

  const setFilter = (filterType, selectedElement) => {
    if (selectedElement === undefined) {
      return;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedElement,
    }));
  };

  const addFilter = (filterType, selectedElement) => {
    if (selectedElement === undefined) {
      return;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: uniq([...prevFilters[filterType], selectedElement]),
    }));
  };

  const removeFilter = (filterType, selectedElement) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]:
        selectedElement?.value === undefined
          ? null
          : prevFilters[filterType].filter((filter) => filter.value !== selectedElement.value),
    }));
  };

  const filtersArray = Object.entries(filters).map(([key, value]) => ({
    type: key,
    selectedFilters: value,
    getRemoveFilterEventHandler: (filterToRemove) => () => removeFilter(key, filterToRemove),
  }));

  return {
    filters,
    setFilter,
    addFilter,
    removeFilter,
    filtersArray,
  };
};

export default useFilters;
