import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

/**
 * Reassign a care plan signer.
 */
function ReassignCarePlanSigner({ providers }) {
  const [mrn, setMrn] = useState('');
  const [signer, setSigner] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (!mrn || !signer) {
      setLoading(false);
      setError(null);
      setInfo(null);
    }
  }, [mrn, signer]);

  /**
   * Look up the given id.
   */
  const lookupId = () => {
    if (
      window.confirm(
        `This will reassign this member's (${mrn}) care plan signer to ${signer}, are you sure you want to do this?`
      )
    ) {
      setLoading(true);
      setError(null);
      setInfo(null);
      if (mrn) {
        axios
          .get(`/staff/admin/reassign_care_plan_signer?mrn=${mrn}&signer=${signer}`)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              setInfo(response.data);
            } else {
              setError('Nothing was returned by the API, please talk to a developer about this ID.');
            }
          })
          .catch((e) => {
            setLoading(false);
            setError(e.response.data);
          });
      } else {
        setLoading(false);
      }
    }
  };

  /**
   * Handle input value change.
   */
  const handleChange = (event) => {
    setMrn(event.target.value);
    setError(null);
  };

  /**
   * Handle select value change.
   */
  const handleSelect = (event) => {
    setSigner(event.target.value);
    setError(null);
  };

  return (
    <div className="overflow-hidden mt-4 border border-gray-400 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5">
        <h2 className="text-lg font-body font-semibold leading-6 text-gray-900">
          Reassign a Care Plan Signature Request
        </h2>
      </div>
      <div>
        <ul>
          <div className="px-4 py-5 bg-gray-50 border-t border-gray-300">
            <div className="text-left">
              {!!info && <pre className="mb-6">{info}</pre>}
              {!info && (
                <div className="leading-5">
                  <p className="mb-6">
                    Enter the MRN, who to reassign the signature to, and this tool will reassign the member's latest
                    care plan signature request. The care plan that will be reassigned will be the most recently updated
                    one where the member has signed but staff has not.
                  </p>
                </div>
              )}
              {!!error && <p className="mb-6 text-error-700">{`Error: ${error}`}</p>}
              <input
                className="btn btn--form-input text-black w-full rounded-lg"
                type="number"
                value={mrn}
                onChange={handleChange}
              />
              <input
                list="provider-list"
                name="provider"
                id="provider"
                onChange={handleSelect}
                className="select mt-4"
              />
              <datalist id="provider-list">
                <option key="blank" value="" aria-label="blank" />
                {providers.map((provider) => (
                  <option key={provider} value={provider}>
                    {provider}
                  </option>
                ))}
              </datalist>
              <button
                disabled={!mrn || !signer}
                className="btn btn--warning block w-full mt-6"
                onClick={() => lookupId()}
                type="button"
              >
                {loading && (
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Loading...</span>
                  </div>
                )}
                {!loading && 'Reassign'}
              </button>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

ReassignCarePlanSigner.propTypes = {
  providers: PropTypes.array.isRequired,
};

export default ReassignCarePlanSigner;
