import React from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../../../GroupsModal';
import Toasts from '../../../shared/Toasts';
import SelectInput from '../../../shared/SelectInput';

const formatMember = (member) => `${member?.firstName} ${member?.lastName}`;

function ReassignPrescriptionsModal({
  prescriptionTasks,
  assigneesList,
  isOpen,
  onClose,
  onReassign,
  isReassignLoading,
  toasts,
  removeToast,
  selectedAssignee,
  setSelectedAssignee,
}) {
  const membersText = prescriptionTasks.map((task) => formatMember(task.member))?.join(', ');

  return (
    <GroupsModal
      testID="reassign-prescription-modal"
      isOpen={isOpen}
      header={prescriptionTasks?.length > 1 ? 'Reassign Tasks' : 'Reassign Task'}
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          label: 'Confirm',
          isPrimary: true,
          onClick: onReassign,
          isLoading: isReassignLoading,
          disabled: !selectedAssignee,
        },
      ]}
      size="small"
      isLoading={isReassignLoading}
    >
      <Toasts toasts={toasts} removeToast={removeToast} />
      <div className="bg-white rounded-lg shadow-sm">
        <div className="py-6 px-4 border-b">
          <p className="font-semibold">{prescriptionTasks?.length > 1 ? 'Members' : 'Member'}</p>
          <p>{membersText}</p>
        </div>
        <div className="py-6 px-4">
          <SelectInput
            label="New Assignee"
            name="assignee"
            options={assigneesList}
            placeholder="Select assignee"
            value={selectedAssignee}
            onChange={setSelectedAssignee}
          />
        </div>
      </div>
    </GroupsModal>
  );
}

ReassignPrescriptionsModal.propTypes = {
  prescriptionTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      member: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    })
  ).isRequired,
  assigneesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  isReassignLoading: PropTypes.bool.isRequired,
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      header: PropTypes.string,
    })
  ),
  removeToast: PropTypes.func,
  selectedAssignee: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setSelectedAssignee: PropTypes.func.isRequired,
};

ReassignPrescriptionsModal.defaultProps = {
  isOpen: false,
  toasts: [],
  removeToast: null,
  selectedAssignee: null,
};

export default ReassignPrescriptionsModal;
