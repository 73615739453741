import React from 'react';
import PropTypes from 'prop-types';
import HistoricalStats, { historicalStatsPropTypes } from './HistoricalStats';
import AttendanceWeeks, { attendanceWeeksPropTypes } from './AttendanceWeeks';

function HistoricalDetailsSection({ historicalStatsProps, attendanceWeeksProps }) {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-body font-bold text-lg">Attendance & Screenings</h3>
      {!historicalStatsProps?.data && !attendanceWeeksProps?.data && (
        <div className="text-center border border-gray-400 shadow-md rounded-lg py-12">
          <div className="font-semibold text-gray-700">No data to display</div>
        </div>
      )}
      {historicalStatsProps?.data && <HistoricalStats {...historicalStatsProps} />}
      {attendanceWeeksProps?.data && <AttendanceWeeks {...attendanceWeeksProps} />}
    </div>
  );
}

HistoricalDetailsSection.propTypes = {
  historicalStatsProps: PropTypes.shape(historicalStatsPropTypes),
  attendanceWeeksProps: PropTypes.shape(attendanceWeeksPropTypes),
};

HistoricalDetailsSection.defaultProps = {
  historicalStatsProps: null,
  attendanceWeeksProps: null,
};

export default HistoricalDetailsSection;
