/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// PACKAGES
// https://mui.com/material-ui/react-skeleton/
import Skeleton from '@mui/material/Skeleton';

// HOOKS
import useData from './hooks/use-data';

function DefaultCell(props) {
  const { isLoaded, data } = useData(props);

  function renderSubText() {
    if (!props.childText) {
      return <></>;
    }
    return <>{props.childText}</>;
  }

  return (
    <td>
      <div className={`m-4 ${props?.customParentClassName}`} style={props.customStyle}>
        {isLoaded ? (
          <>
            <p className="my-1 whitespace-pre-wrap">{props.preloadedData || data}</p>
            <p className="my-1 whitespace-pre-wrap">{props.lineTwo}</p>
            <p className={`${props?.customClassName} text-sm my-1 whitespace-pre-wrap`}>{renderSubText()}</p>
          </>
        ) : (
          <Skeleton variant="text" width={150} sx={{ fontSize: '16px' }} />
        )}
      </div>
    </td>
  );
}

DefaultCell.propTypes = {
  id: PropTypes.string,
  endpoint: PropTypes.string,
  preloadedData: PropTypes.string,
  lineTwo: PropTypes.string,
  childText: PropTypes.string,
  customParentClassName: PropTypes.string,
  customClassName: PropTypes.string,
  customStyle: PropTypes.object,
};

DefaultCell.defaultProps = {
  id: null,
  endpoint: '',
  preloadedData: null,
  lineTwo: null,
  childText: null,
  customParentClassName: null,
  customClassName: null,
  customStyle: {},
};

export default DefaultCell;
