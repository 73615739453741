import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InformationHeader from './InformationHeader';
import InformationContainer from './InformationContainer';

import TextRowContainer from '../shared/TextRowContainer';

function DemographicInformation(props) {
  const { mrn, dob, tx, preferredName, pronouns } = props?.data || {};

  const [openStatus, setOpenStatus] = useState(false);

  function renderOpenContent() {
    if (openStatus) {
      return (
        <>
          <TextRowContainer label="MRN" text={mrn} />
          <TextRowContainer label="DOB" text={dob} />
          <TextRowContainer label="TX (Weeks)" text={tx} />
          <TextRowContainer label="Preferred Name" text={preferredName} />
          <TextRowContainer label="Pronouns" text={pronouns} hasBorder={false} />
        </>
      );
    }
    return <></>;
  }

  return (
    <InformationContainer isBgBlue addPadding>
      <InformationHeader label="Demographics" isOpen={openStatus} setOpenStatus={setOpenStatus} />
      {renderOpenContent()}
    </InformationContainer>
  );
}

DemographicInformation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DemographicInformation;
