export const FILTER_TYPES = {
  availability: 'availability',
  counselor: 'counselor',
  days: 'days',
  facility: 'facilities',
  clinicalModel: 'clinical_model',
  modality: 'modality',
  officeManager: 'office_manager',
  provider: 'provider',
  state: 'state',
  time: 'time',
  zipcode: 'zipcode',
};

export const AVAILABILITY_DATA = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'all', label: 'All' },
];

export const DEFAULT_FILTERS = {
  [FILTER_TYPES.availability]: [AVAILABILITY_DATA[0]],
  [FILTER_TYPES.counselor]: [],
  [FILTER_TYPES.days]: [],
  [FILTER_TYPES.facility]: [],
  [FILTER_TYPES.clinicalModel]: [],
  [FILTER_TYPES.modality]: [],
  [FILTER_TYPES.officeManager]: [],
  [FILTER_TYPES.provider]: [],
  [FILTER_TYPES.state]: [],
  [FILTER_TYPES.time]: [],
  [FILTER_TYPES.zipcode]: [],
};
