/* eslint-disable no-shadow */
// REACT IMPORTS
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// ICON IMPORTS
import { FiEdit } from 'react-icons/fi';
import { MdCheck } from 'react-icons/md';
import { HiOutlineArchive, HiArchive } from 'react-icons/hi';

// TABLE IMPORTS
import axios from 'axios';
import DateCell from './DateCell';
import StatusCell from './StatusCell';
import GeneralCell from './GeneralCell';
import EditModal from './EditModal';
import WarningModal from './WarningModal';

// SERVICES
import { setGroupsData } from './empanelment/group/set-groups-data';

function GroupsTableCell(props) {
  // SET ROW DATA
  const [rowData, setRow] = useState({});
  const [isArchived, setIsArchived] = useState({});
  const testID = `groupsTableCell-${props.testID}`;

  // SET MODAL DATA
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenWarningModalConfirm, setIsOpenWarningModalConfirm] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [modalFlash, setModalFlash] = useState([]);

  const {
    row,
    status,
    modality_name,
    group_type,
    date_time,
    location,
    member_count,
    office_manager_id,
    office_manager_name,
    counselor_id,
    counselor_name,
    provider_id,
    provider_name,
    default_state,
    default_facility,
    default_office_manager,
    default_counselor,
    default_provider,
    default_modality,
    default_group_type,
    default_secondary_group_types,
    default_secondary_group_type_names,
  } = setGroupsData(props);

  useEffect(() => {
    setRow({
      row,
      status,
      modality_name,
      group_type,
      date_time,
      location,
      member_count,
      office_manager_id,
      office_manager_name,
      counselor_id,
      counselor_name,
      provider_id,
      provider_name,
      default_state,
      default_facility,
      default_office_manager,
      default_counselor,
      default_provider,
      default_modality,
      default_group_type,
      default_secondary_group_types,
      default_secondary_group_type_names,
    });

    setIsArchived(status === 'archived');
  }, [
    row,
    status,
    date_time,
    location,
    modality_name,
    group_type,
    member_count,
    office_manager_id,
    office_manager_name,
    counselor_id,
    counselor_name,
    provider_id,
    provider_name,
    default_state,
    default_facility,
    default_office_manager,
    default_counselor,
    default_provider,
    default_modality,
    default_group_type,
    default_secondary_group_types,
    default_secondary_group_type_names,
  ]);

  // FUNCTIONS
  const approve = async () => {
    const empanelment_id = rowData.row.id;

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const params = {
      id: empanelment_id,
      is_approved: 'true',
    };

    axios.put('/staff/empanelment_groups/update', params).then((response) => {
      const rowData = setGroupsData(response.data);

      setIsOpenWarningModalConfirm(false);

      props.updateRows('approve', rowData.row);
    });
  };

  const edit = async () => {
    const empanelment_id = rowData.row.id;

    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    const params = {
      id: empanelment_id,
      is_edit: 'true',
      day: editedData.dayOfWeek,
      time: editedData.startTime,
      time_zone: editedData.timezone.value,
      office_manager_id: editedData.officeManager.value,
      counselor_id: editedData.counselor.value,
      provider_id: editedData.provider.value,
      state_id: editedData.state.value,
      location_id: editedData.facility.value,
      empanelment_group_modality_id: editedData.modality.value,
      empanelment_group_type_id: editedData.groupType.value,
      empanelment_secondary_group_type_ids: Array.isArray(editedData.secondaryGroupTypes)
        ? editedData.secondaryGroupTypes?.map((type) => type?.value)
        : null,
    };

    axios
      .put('/staff/empanelment_groups/update', params)
      .then((response) => {
        const rowData = setGroupsData(response.data);

        setIsOpenWarningModal(false);
        setIsOpenEditModal(false);

        props.updateRows('edit', rowData.row);
      })
      .catch((error) => {
        setIsOpenWarningModal(false);
        if (error.response) {
          setModalFlash(error.response.data);
          window.Sentry.captureException(error.response.data);
        } else {
          setModalFlash(error.message);
        }
      });
  };

  const archive = async (rowData) => {
    // toggle isArchived status
    const toArchive = !(rowData.status === 'archived');

    const empanelment_groups_id = rowData.row.id;
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;

    try {
      axios
        .put('/staff/empanelment_groups/update', { id: empanelment_groups_id, is_archived: toArchive })
        .then((response) => {
          const rowData = setGroupsData(response.data);

          props.updateRows('archive', rowData.row);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const setTimezone = (data) => {
    const updatedData = data;
    // the default timezone is just a string. make it an object with an offset property
    if (typeof updatedData.timezone === 'string') {
      const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'shortGeneric' }).split(' ')[2];
      const browserOffset = new Date().getTimezoneOffset() / -60;
      const value = new Date().toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ').slice(2).join(' ');
      updatedData.timezone = {
        offset: browserOffset,
        abbrev: zone,
        value,
      };
    }
    return updatedData;
  };

  const handleContinue = (data) => {
    const updatedData = setTimezone(data);
    setEditedData(updatedData);
    setIsOpenWarningModal(true);
  };

  return (
    <>
      <DateCell dateTime={rowData.date_time} location={rowData.location} showFullDate={false} testID={testID} />

      <StatusCell testID={testID} status={rowData.status} />

      <>
        <GeneralCell testID={`modality-${testID}`} data={rowData.modality_name} />
        <GeneralCell
          testID={`groupType-${testID}`}
          data={rowData.group_type}
          subText={props.secondaryGroupTypeFeatureFlag ? rowData?.default_secondary_group_type_names?.join(', ') : ''}
        />
        <GeneralCell testID={`OM-${testID}`} data={rowData.office_manager_name} />
      </>

      <GeneralCell testID={`counselor-${testID}`} data={rowData.counselor_name} />
      <GeneralCell testID={`provider-${testID}`} data={rowData.provider_name} />
      <GeneralCell testID={`memberCount-${testID}`} data={rowData.member_count} />

      {/* ACTION BUTTONS */}
      <td className="shadow-inner ring-1 ring-black ring-opacity-5">
        <div className="flex justify-around p-5 empanelment-buttons" data-testid={`actionBtns-${testID}`}>
          {/* APPROVE ACTION */}
          <button
            type="button"
            className="empanelment-button members-approve-button"
            disabled={rowData.status === 'active' || status === 'archived'}
            data-testid={`approveActionBtn-${testID}`}
          >
            <MdCheck
              disabled={rowData.status === 'active' || status === 'archived'}
              onClick={setIsOpenWarningModalConfirm}
            />
          </button>

          {/* EDIT ACTION */}
          <button
            type="button"
            className="empanelment-button fi-edit-button"
            disabled={status === 'archived'}
            data-testid={`editActionBtn-${testID}`}
          >
            <FiEdit disabled={status === 'archived'} onClick={setIsOpenEditModal} />
          </button>

          {/* ARCHIVE ACTION */}
          {/* <button type="button" className="empanelment-button hi-outline-archive-button" disabled={rowData.member_count > 0}>
            <HiOutlineArchive disabled={rowData.member_count > 0 || status === 'archived'} onClick={() => archive(rowData)} />
          </button> */}

          <button
            type="button"
            className="empanelment-button hi-outline-archive-button"
            disabled={rowData.member_count > 0}
            data-testid={`archiveActionBtn-${testID}`}
          >
            {isArchived ? (
              <HiArchive disabled={rowData.member_count > 0} onClick={() => archive(rowData)} />
            ) : (
              <HiOutlineArchive
                disabled={rowData.member_count > 0 || status === 'archived'}
                onClick={() => archive(rowData)}
              />
            )}
          </button>
        </div>
      </td>

      {/* EDIT MODAL */}
      <EditModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenEditModal}
        statesFilters={props.editModalFilters.statesFilters}
        facilitiesFilters={props.editModalFilters.facilitiesFilters}
        staffFilters={props.editModalFilters.staffFilters}
        officeManagerFilters={props.editModalFilters.officeManagerFilters}
        modalityFilters={props.editModalFilters.modalityFilters}
        empanelmentGroupTypeFilters={props.editModalFilters.empanelmentGroupTypeFilters}
        empanelmentSecondaryGroupTypeData={props.editModalFilters.empanelmentSecondaryGroupTypeData}
        empanelmentGroup={rowData}
        onContinue={handleContinue}
        testID={testID}
        flashMessage={modalFlash}
      />

      {/* POST-EDIT WARNING MODAL */}
      <WarningModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenWarningModal}
        confirmData={editedData}
        onConfirm={edit}
        onClose={() => {
          setIsOpenWarningModal(false);
        }}
        testID={`postEdit-${testID}`}
      />

      {/* PRE-APPROVE WARNING MODAL */}
      <WarningModal
        secondaryGroupTypeFeatureFlag={props.secondaryGroupTypeFeatureFlag}
        isOpen={isOpenWarningModalConfirm}
        confirmData={{
          time: rowData.date_time,
          facility: rowData.default_facility,
          officeManager: rowData.default_office_manager,
          counselor: rowData.default_counselor,
          provider: rowData.default_provider,
          modality: rowData.default_modality,
          groupType: rowData.default_group_type,
          secondaryGroupTypes: rowData.default_secondary_group_type_names,
        }}
        onConfirm={approve}
        onClose={() => {
          setIsOpenWarningModalConfirm(false);
        }}
        testID={`preApprove-${testID}`}
      />
    </>
  );
}

GroupsTableCell.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,

  editModalFilters: PropTypes.object.isRequired,

  // functions
  updateRows: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
};

export default GroupsTableCell;
