import React from 'react';
import PropTypes from 'prop-types';
import LastSynced from './LastSynced';
import RenderHTML from '../shared/RenderHTML';

function Notes({ lastSynced, notes }) {
  return (
    <div>
      <h5 className="font-body font-bold">Notes</h5>
      <RenderHTML classNames="p-3.5 bg-yellow-100 min-h-[104px] mt-3.5 mb-2" html={notes} />
      {lastSynced && (
        <LastSynced
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...lastSynced}
        />
      )}
    </div>
  );
}

Notes.propTypes = {
  lastSynced: PropTypes.shape({
    dateTime: PropTypes.oneOf(PropTypes.string, PropTypes.instanceOf(Date)).isRequired,
    wasEdited: PropTypes.bool,
    person: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
  notes: PropTypes.string,
};

Notes.defaultProps = {
  lastSynced: undefined,
  notes: '-',
};

export default Notes;
