import React from 'react';
import PropTypes from 'prop-types';

function TextRowLabel(props) {
  const classes = 'flex-1 font-heading text-xs font-semibold my-auto';

  function renderSecondaryLabel() {
    if (!props.secondaryLabel) {
      return <></>;
    }
    return <div>({props.secondaryLabel?.toUpperCase()})</div>;
  }

  return (
    <div className={classes} style={{ color: '#666666' }}>
      {props.label?.toUpperCase()}
      {renderSecondaryLabel()}
    </div>
  );
}

TextRowLabel.propTypes = {
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
};

TextRowLabel.defaultProps = {
  secondaryLabel: null,
};

export default TextRowLabel;
