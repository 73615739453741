/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from 'axios';

// HELPERS
import { formatTimeLocalized, formatTodayOrDOW } from '../../DateFormatHelper';

// EXAMPLE PATH: '/staff/daily_tasks/last_job_timestamp'
const useJobLastTimestamp = (path) => {
  const [lastJobTimestamp, setLastJobTimestamp] = useState(null);

  const fetchLastJobTimestamp = async () => {
    try {
      const { data: response } = await axios.get(path);

      if (response) {
        const day = formatTodayOrDOW(response);
        const time = formatTimeLocalized(response);

        const formattedString = `Last updated ${day}, ${time}`;
        setLastJobTimestamp(formattedString);
      } else {
        setLastJobTimestamp('Unable to retrieve last import timestamp');
      }
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchLastJobTimestamp();
  }, []);

  return {
    lastJobTimestamp,
  };
};

export default useJobLastTimestamp;
