import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from './Header';
import ButtonRow from './ButtonRow';
import ModalContainer from './ModalContainer';
import FormBody from './FormBody';

function GenericModal({
  isOpen,
  headerText,
  children,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryButtonClick,
  secondaryButtonClick,
  showPrimary,
  showSecondary,
  isLargeModal,
  primaryButtonLoading,
  primaryButtonDisabled,
}) {
  if (!isOpen) return null;

  return (
    <ModalContainer isOpen={isOpen} isLargeModal={isLargeModal}>
      {/* Header */}
      <Header withNoUnderline withBottomMargin text={headerText} />

      {/* Body */}
      <FormBody>{children}</FormBody>

      {/* BUTTONS */}
      <ButtonRow
        showPrimary={showPrimary}
        showSecondary={showSecondary}
        primaryLabel={primaryButtonLabel}
        secondaryLabel={secondaryButtonLabel}
        onPrimaryClick={primaryButtonClick}
        onSecondaryClick={secondaryButtonClick}
        primaryButtonLoading={primaryButtonLoading}
        primaryButtonDisabled={primaryButtonDisabled}
      />
    </ModalContainer>
  );
}

GenericModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node,

  // BUTTONS
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  primaryButtonClick: PropTypes.func,
  secondaryButtonClick: PropTypes.func,
  showPrimary: PropTypes.bool,
  showSecondary: PropTypes.bool,
  isLargeModal: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
  primaryButtonDisabled: PropTypes.bool,
};

GenericModal.defaultProps = {
  children: null,

  // BUTTONS
  primaryButtonLabel: 'Continue',
  secondaryButtonLabel: 'Cancel',
  primaryButtonClick: () => {},
  secondaryButtonClick: () => {},
  showPrimary: true,
  showSecondary: true,
  isLargeModal: false,
  primaryButtonLoading: false,
  primaryButtonDisabled: false,
};

export default GenericModal;
