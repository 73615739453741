/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../shared/Header';
import EmptyTableMessage from '../shared_dashboard/EmptyTableMessage';
import AppointmentsTable from './AppointmentsTable';

// CONSTANTS
import appointmentsHeaders from './constants/appointments-dashboard-headers';

// HOOKS
import useTodaysAppointments from './hooks/use-todays-appointments';

function TodaysAppointmentsDashboard(props) {
  /** Dashboard Logic */
  const { todaysAppointments, firstRangeValue, lastRangeValue, totalCount, totalPages, fetchTodaysAppointments } =
    useTodaysAppointments();

  /** Pagination State */
  const [page, setPage] = useState(1);

  /**
   * @function useEffect
   *
   * When "Apply" clicked on StaffDashboard.js, send for updated data.
   * Triggered from function setFilterApplyClicked.
   * @param [ appliedFilters ]: { with_account: true, appointment_type: 'ICE', state: [], staff: [] } # object of filter IDs
   */
  useEffect(() => {
    if (!props.appliedFilters) {
      return;
    }

    const params = { ...props.appliedFilters, page: 1 };

    setPage(1);
    fetchTodaysAppointments(params);
  }, [props.appliedFilters]);

  /**
   * @function pageChange: When "pageChanged", send for updated data.
   */
  const pageChange = (newPage) => {
    const params = { ...props.appliedFilters, page: newPage };

    setPage(newPage);
    fetchTodaysAppointments(params);
  };

  return (
    <section>
      <details className="mb-6" open>
        <summary className="relative my-2 cursor-pointer flex items-center">
          <Header text="Today's Appointments" />
        </summary>

        {todaysAppointments?.length ? (
          <AppointmentsTable
            authenticityToken={props.authenticityToken}
            headers={appointmentsHeaders}
            response={todaysAppointments}
            firstRangeValue={firstRangeValue}
            lastRangeValue={lastRangeValue}
            totalCount={totalCount}
            page={page}
            totalPages={totalPages}
            onPageChange={pageChange}
          />
        ) : (
          <EmptyTableMessage
            text="You have no appointments"
            subText="Try filtering by a different staff member via the dropdown above"
          />
        )}
      </details>
    </section>
  );
}

TodaysAppointmentsDashboard.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  appliedFilters: PropTypes.object,
};

TodaysAppointmentsDashboard.defaultProps = {
  appliedFilters: {},
};

export default TodaysAppointmentsDashboard;
