const verticalLineStyle = { height: '200%', marginLeft: '10px', borderLeft: 'solid 1px #f2c054' };
const buttonStyle = {
  backgroundColor: '#fdf6ec',
  textTransform: 'none',
  fontFamily: 'inherit',
  display: 'flex',
};

const expandDiv = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  minHeight: '100%',
  backgroundColor: 'white',
};

const leftArrow = {
  position: 'fixed',
  marginLeft: '-35px',
  color: 'white',
  backgroundColor: '#404040',
  borderRadius: '40px',
  border: '2px solid white',
  padding: '5px',
  width: '35px',
  height: '35px',
  paddingLeft: '10px',
  marginTop: '25%',
};

const rightArrow = {
  color: 'white',
  backgroundColor: '#404040',
  borderRadius: '40px',
  padding: '5px',
  width: '30px',
  height: '30px',
};

const tableRowStyle = (isSelected) => ({
  height: '95px',
  backgroundColor: isSelected ? '#fdf6ec' : '',
});

export { verticalLineStyle, buttonStyle, expandDiv, leftArrow, rightArrow, tableRowStyle };
