import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import GroupsModal from '../../../GroupsModal';
import SelectInput from '../../../shared/SelectInput';
import { ATTENDANCE_STATUSES, ATTENDED_ATTENDANCE_STATUS } from '../shared/constants';
import { GroupAppointmentCamelCasePropTypes } from '../shared/types';

function BulkStatusModal({ appointment, selectedMembersIds, onClose, isOpen, onSuccess }) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setStatus(null);
  }, [isOpen]);

  const onSubmit = async () => {
    const members = appointment.members.filter((member) => selectedMembersIds.includes(member.id));

    const defaultStartDate = appointment.scheduledAt;
    const defaultEndDate = moment(appointment.scheduledAt).add(appointment.duration, 'minutes');

    const data = members.map((member) => {
      if (status?.value === ATTENDED_ATTENDANCE_STATUS.value) {
        const startDate = moment(defaultStartDate);
        const endDate = moment(defaultEndDate);

        startDate?.seconds(0);
        endDate?.seconds(0);

        return {
          id: member.id,
          status: status?.value,
          startDate,
          endDate,
        };
      }

      return {
        id: member.id,
        status: status?.value,
        startDate: null,
        endDate: null,
      };
    });

    onSuccess(data);
    onClose();
  };

  return (
    <GroupsModal
      testID="add-task-modal-confirmation"
      isOpen={isOpen}
      header="Set Status"
      subHeader="Set status for all the selected members."
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onClose,
        },
        {
          label: 'Confirm',
          isPrimary: true,
          onClick: onSubmit,
          type: 'submit',
        },
      ]}
      size="small"
    >
      <div className="flex flex-col bg-white py-4 px-6 border border-gray-300 rounded-lg">
        <span className="text-base font-semibold">Status</span>
        <SelectInput
          name="status"
          value={status}
          onChange={setStatus}
          options={ATTENDANCE_STATUSES}
          placeholder="Select status"
        />
      </div>
    </GroupsModal>
  );
}

BulkStatusModal.propTypes = {
  appointment: GroupAppointmentCamelCasePropTypes.isRequired,
  selectedMembersIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BulkStatusModal;
