import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../GroupsModal';
import Accordion from '../shared/Accordion';
import NoteReadMode from './note_read_mode_view/NoteReadMode';
import HealthieForm from './HealthieForm';
import { prepareNoteReadModeProps, getDateTime } from '../empanelment/member_encounter_history/helpers';
import { FormAnswerGroupPropType, SmartPhrasesPropType } from './helpers/types';

function EditGroupNotesModal({
  isOpen,
  onClose,
  notesToEdit,
  onChartingNoteUpdate,
  disabled,
  states,
  onApplyAnswerToAll,
  smartPhrases,
}) {
  const [isNoteUpdating, setIsNoteUpdating] = useState(false);
  const isButtonDisabled = disabled || isNoteUpdating;

  return (
    <GroupsModal
      isOpen={isOpen}
      header={notesToEdit?.memberName}
      footerButtons={[
        {
          label: isButtonDisabled ? 'Saving' : 'Close',
          isPrimary: true,
          onClick: onClose,
          disabled: isButtonDisabled,
          isLoading: isButtonDisabled,
        },
      ]}
      isScrollable={false}
      size="large"
    >
      <div className="overflow-y-auto">
        {notesToEdit?.notes?.map((note) => {
          const isOpenByDefault = !!note.formAnswers.find(
            (formAnswer) => formAnswer.id === notesToEdit.selectedFormAnswerId
          );

          return (
            <div key={note.id}>
              <Accordion
                title={note.name}
                description={`Added by ${note.filler?.name} on ${getDateTime(note.createdAt)}`}
                isOpenByDefault={isOpenByDefault}
              >
                {note.lockedAt && note.lockedNoteIds?.includes(note.id) ? (
                  <NoteReadMode
                    note={prepareNoteReadModeProps(note)}
                    isFullWidthSet
                    selectedFormAnswerId={notesToEdit.selectedFormAnswerId}
                  />
                ) : (
                  <HealthieForm
                    formId={note.id}
                    formAnswers={note.formAnswers}
                    onUpdate={onChartingNoteUpdate}
                    isNoteUpdating={isNoteUpdating}
                    setIsNoteUpdating={setIsNoteUpdating}
                    disabled={disabled}
                    states={states}
                    isVertical={false}
                    onApplyAnswerToAll={onApplyAnswerToAll}
                    smartPhrases={smartPhrases}
                    selectedFormAnswerId={notesToEdit.selectedFormAnswerId}
                  />
                )}
              </Accordion>
            </div>
          );
        })}
      </div>
    </GroupsModal>
  );
}

EditGroupNotesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  notesToEdit: PropTypes.arrayOf(FormAnswerGroupPropType).isRequired,
  onChartingNoteUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  onApplyAnswerToAll: PropTypes.func.isRequired,
  smartPhrases: SmartPhrasesPropType,
};

EditGroupNotesModal.defaultProps = {
  isOpen: false,
  disabled: false,
  smartPhrases: null,
};

export default EditGroupNotesModal;
