import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useConvertObjKeysToCamelCase from '../../shared/hooks/use-convert-keys-to-camelCase';
import Toasts from '../../shared/Toasts';
import useToasts from '../../shared/hooks/use-toasts';
import Header from './Header';
import TaskDetails from './TaskDetails';
import MemberOverview from './MemberOverview';
import PrescriptionsIframe from './PrescriptionsIframe';
import MemberDetails from './MemberDetails';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import { SelectedNotesController } from './useSelectedNotes';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

function Main({
  authenticityToken,
  canUserManageChartingNotes,
  prescriptionTaskDetails,
  memberDetails,
  doseSpotUiURL,
  memberURL,
  prescriptionQueueURL,
  prescriptionReasons,
  smartPhrases,
  canEditLockedRx,
  canViewRxQueue,
  canEditRxQueue,
  isOtherPendingPrescription,
}) {
  const { toasts, removeToast, addToast } = useToasts();
  const [prescriptionTaskDetailsParsed, setPrescriptionTaskDetails] = useState(
    convertObjKeysToCamelCase(prescriptionTaskDetails)
  );
  const memberDetailsParsed = useConvertObjKeysToCamelCase(memberDetails, ['variables']);

  return (
    <SelectedNotesController>
      <div className="bg-blue-100 flex flex-col gap-7 p-6">
        <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" />
        <Header
          memberName={`${memberDetailsParsed?.firstName} ${memberDetailsParsed?.lastName}`}
          memberMrn={memberDetailsParsed?.mrn}
          prescriptionQueueURL={prescriptionQueueURL}
          prescriptionTaskDetails={prescriptionTaskDetailsParsed}
          authenticityToken={authenticityToken}
          prescriptionReasons={prescriptionReasons}
          setPrescriptionTaskDetails={setPrescriptionTaskDetails}
          addToast={addToast}
          canEditLockedRx={canEditLockedRx}
          canViewRxQueue={canViewRxQueue}
          canEditRxQueue={canEditRxQueue}
          isOtherPendingPrescription={isOtherPendingPrescription}
        />
        <TaskDetails
          prescriptionTaskDetails={prescriptionTaskDetailsParsed}
          memberMrn={memberDetailsParsed?.mrn}
          memberURL={memberURL}
        />
        <div className="bg-white -mx-10 px-12 py-7">
          <MemberOverview memberData={memberDetailsParsed} />
          <PrescriptionsIframe src={doseSpotUiURL} />
          <MemberDetails
            prescriptionTaskId={prescriptionTaskDetailsParsed.id}
            memberData={memberDetailsParsed}
            authenticityToken={authenticityToken}
            chartingNotesIds={prescriptionTaskDetailsParsed.chartingNotesIds}
            smartPhrases={smartPhrases}
            canUserManageChartingNotes={canUserManageChartingNotes}
          />
        </div>
      </div>
    </SelectedNotesController>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  prescriptionTaskDetails: PropTypes.shape({
    id: PropTypes.number,
    due_date: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
    assignee: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    appointment_occurrence: PropTypes.shape({
      id: PropTypes.number,
      scheduled_at: PropTypes.string,
      appointment_type: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      status: PropTypes.string,
      member_status: PropTypes.string,
    }),
    charting_notes_ids: PropTypes.arrayOf(PropTypes.string),
    note: PropTypes.string,
    empanelment_group: PropTypes.string,
    member: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      summary_url: PropTypes.string,
    }),
  }).isRequired,
  memberDetails: PropTypes.object.isRequired,
  doseSpotUiURL: PropTypes.string,
  memberURL: PropTypes.string,
  prescriptionQueueURL: PropTypes.string,
  prescriptionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  smartPhrases: SmartPhrasesPropType,
  canEditLockedRx: PropTypes.bool,
  canViewRxQueue: PropTypes.bool,
  canEditRxQueue: PropTypes.bool,
  isOtherPendingPrescription: PropTypes.bool,
};

Main.defaultProps = {
  doseSpotUiURL: null,
  memberURL: null,
  prescriptionQueueURL: null,
  smartPhrases: null,
  canEditLockedRx: false,
  canViewRxQueue: false,
  canEditRxQueue: false,
  isOtherPendingPrescription: false,
};

export default Main;
