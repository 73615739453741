import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InviteAccountModal from './InviteAccountModal';
import InviteAccountStatusHistoriesModal from './InviteAccountStatusHistoriesModal';
import InviteLink from './InviteLink';

// HOOKS
import { useInvitesChannel, changesMap } from '../hooks/use-invites-channel';

function InviteAccountLinks(props) {
  const [updatedInviteText, setUpdatedInviteText] = useState({ label: null, status: null });
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  useEffect(() => {
    useInvitesChannel.received = (data) => {
      const updatedText = changesMap[data.status];
      const updatedObject = updatedText(data);

      setUpdatedInviteText(updatedObject);
    };
  });

  return (
    <>
      {props.show && (
        <>
          <div className="ml-1">
            {/* INVITE STATUS */}
            <InviteLink
              show
              text={updatedInviteText?.label || props.inviteText}
              onClick={() => setIsInviteModalOpen(true)}
            />

            {/* INVITE HISTORY */}
            <InviteLink
              className="text-sm"
              show={updatedInviteText?.status || props.data?.status_block?.length > 0}
              text={updatedInviteText?.status || props.data?.status_block}
              onClick={() => setIsStatusModalOpen(true)}
            />
          </div>

          {isInviteModalOpen && (
            <InviteAccountModal
              authenticityToken={props.authenticityToken}
              memberId={props.memberId}
              onSubmitSent={() => setIsInviteModalOpen(false)}
              onCancel={() => setIsInviteModalOpen(false)}
            />
          )}

          {isStatusModalOpen && (
            <InviteAccountStatusHistoriesModal
              id={props.data?.member_id}
              endpoint="/staff/dashboard_v2/fetch_account_invite_history"
              onClose={() => setIsStatusModalOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
}

InviteAccountLinks.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  inviteText: PropTypes.string,
  memberId: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};

InviteAccountLinks.defaultProps = {
  inviteText: 'Invite',
};

export default InviteAccountLinks;
