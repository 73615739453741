import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DetailsRow from '../DetailsRow';
import EditableSelectRowValue from '../EditableSelectRowValue';
import { LanguagePropTypes } from '../types';

function PrimaryLanguageRow({ primaryLanguage, allLanguages, isLoadingRequest, handleMemberEdit }) {
  const [selectedValue, setSelectedValue] = useState({
    value: primaryLanguage?.code,
    label: primaryLanguage?.name,
  });
  const [isEditing, setIsEditing] = useState(false);

  const convertedLanguages = useMemo(
    () =>
      allLanguages.map((language) => ({
        value: language?.code,
        label: language?.name,
      })),
    [allLanguages]
  );

  return (
    <DetailsRow label="Primary Language">
      <EditableSelectRowValue
        name="primary-language"
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        isLoading={isLoadingRequest}
        onSave={() => handleMemberEdit({ language: selectedValue?.value }, setIsEditing)}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        valuesList={convertedLanguages}
      />
    </DetailsRow>
  );
}

PrimaryLanguageRow.propTypes = {
  primaryLanguage: LanguagePropTypes.isRequired,
  allLanguages: PropTypes.arrayOf(LanguagePropTypes),
  isLoadingRequest: PropTypes.bool,
  handleMemberEdit: PropTypes.func.isRequired,
};

PrimaryLanguageRow.defaultProps = {
  allLanguages: [],
  isLoadingRequest: false,
};

export default PrimaryLanguageRow;
