import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import VersionStatus from './VersionStatus';

function AppVersionCell(props) {
  return (
    <div className="flex">
      <VersionStatus status={props.status} version={props.version} showInline={props.showInline} ota={props.ota} />
    </div>
  );
}

AppVersionCell.propTypes = {
  status: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  ota: PropTypes.string,
  showInline: PropTypes.bool,
};

AppVersionCell.defaultProps = {
  ota: '',
  showInline: false,
};

export default AppVersionCell;
