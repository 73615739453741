import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// ICONS
import { FiEdit } from 'react-icons/fi';

// COMPONENTS
import EmpanelmentCell from '../shared_dashboard/EmpanelmentCell';
import DefaultCell from '../shared_dashboard/DefaultCell';
import MemberCell from '../shared_dashboard/MemberCell';
import IconCell from '../shared_dashboard/IconCell';

// HELPERS
import SupplyCell from './SupplyCell';
import TrackingCell from './TrackingCell';
import EditPickupModal from './shared/EditPickupModal';

function UdsSupplyTableRow(props) {
  // ROW DATA
  const [rowData, setRowData] = useState(props?.data);
  useEffect(() => {
    setRowData(props?.data);
  }, [props?.data]);

  // PREFERENCES
  const [preference, setPreference] = useState(props?.data?.uds_supply_option);
  useEffect(() => {
    setPreference(props?.data?.uds_supply_option);
  }, [props?.data?.uds_supply_option]);

  const [showEditPickupModal, setShowEditPickupModal] = useState(false);

  const renderPreference = () => {
    if (preference === 'ship') {
      return 'Ship';
    }
    return 'Pickup';
  };

  return (
    <>
      <tr>
        {/* Member Cell */}
        <MemberCell
          preloadedData={{
            id: rowData?.member_id,
            full_name: rowData?.member_name,
            external_id: rowData?.member_mrn,
          }}
        />

        {/* Weeks Cell */}
        <DefaultCell preloadedData={rowData?.tx_weeks} />

        {/* Empaneled Cell */}
        <EmpanelmentCell
          hasEmpanelment={rowData?.empanelment_id}
          dateTime={rowData?.empanelment_date_time}
          locationName={rowData?.empanelment_location}
          showModality
          modality={rowData?.empanelment_secondary_types}
        />

        {/* Preferences Cell */}

        <IconCell>
          <div className="flex">
            {props.featureFlagEdit && (
              <div className="flex items-center">
                <FiEdit
                  className="empanelment-button fi-edit-button mt-4 cursor-pointer"
                  sx={{ width: '1.25rem' }}
                  onClick={() => {
                    props.onEditRow(rowData);
                  }}
                />
              </div>
            )}
            <div className="flex-col">
              <div>{renderPreference()}</div>
              {preference === 'pickup' && (
                <div className="text-sm text-gray-700 mt-1">{rowData?.uds_pickup_facility_name}</div>
              )}
            </div>
          </div>
        </IconCell>

        <SupplyCell
          isEditable
          showDate
          showNotGroupLabel
          shipmentObj={rowData?.latest_uds_supply}
          onSupplyEdit={() => {
            setShowEditPickupModal(true);
            window?.Matomo?.getAsyncTracker()?.trackEvent('edit-pickup-modal', 'open-modal');
          }}
          openSupplyHistoryModal={() => props.openSupplyHistoryModal(rowData)}
          featureFlagEdit={props.featureFlagEdit}
        />

        <TrackingCell trackingObj={rowData?.uds_shipment_update} />
        {showEditPickupModal && (
          <EditPickupModal
            isOpen={showEditPickupModal}
            rowData={rowData}
            locations={props.locations}
            onBack={() => {
              setShowEditPickupModal(false);
              window?.Matomo?.getAsyncTracker()?.trackEvent('edit-pickup-modal', 'cancel-edit');
            }}
            onConfirm={(editedData) => {
              setShowEditPickupModal(false);
              props.onEditPickupData(editedData);
              window?.Matomo?.getAsyncTracker()?.trackEvent('edit-pickup-modal', 'confirm-edit');
            }}
          />
        )}
      </tr>
    </>
  );
}

UdsSupplyTableRow.propTypes = {
  data: PropTypes.array.isRequired,
  onEditRow: PropTypes.func.isRequired,
  onEditPickupData: PropTypes.func.isRequired,
  featureFlagEdit: PropTypes.bool.isRequired,
  openSupplyHistoryModal: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
};

export default UdsSupplyTableRow;
