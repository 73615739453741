const labelStyle = { color: 'black', fontWeight: 500 };

const cancelButtonStyle = {
  border: 'none',
  backgroundColor: '#CD5334',
  color: 'white',
  height: '48px',
  lineHeight: 0,
  width: '223px',
};

const addressSubtextStyle = {
  color: '#666666',
  fontSize: '14px',
  fontWeight: 400,
};

const containerStyle = { backgroundColor: 'white', borderRadius: '24px', padding: '24px' };

const warningTextStyle = { fontWeight: 700, color: '#CD5334', marginTop: '15px' };

const activeLabelContainer = {
  backgroundColor: 'white',
  padding: '16px',
  borderWidth: '1px 0px 1px 0px',
  borderStyle: 'solid',
  borderColor: '#E0E3E5',
  marginRight: '30px',
  marginTop: 0,
};

const labelContainer = {
  padding: '16px',
  borderWidth: '1px 0px 1px 0px',
  borderStyle: 'solid',
  borderColor: '#E0E3E5',
  marginRight: '30px',
  marginTop: 0,
};

const packageOptionStyles = {
  height: '80px',
  width: '100%',
  border: 'solid 1px gray',
  padding: '20px',
};

const deleteLabelStyle = {
  color: '#CD5334',
};

const customFieldContainer = {
  backgroundColor: '#F5F8FA',
  padding: '20px',
  marginTop: '0px',
  marginBottom: '20px',
};

// eslint-disable-next-line import/prefer-default-export
export {
  labelStyle,
  cancelButtonStyle,
  addressSubtextStyle,
  containerStyle,
  warningTextStyle,
  activeLabelContainer,
  labelContainer,
  packageOptionStyles,
  deleteLabelStyle,
  customFieldContainer,
};
