import React from 'react';
import PropTypes from 'prop-types';

function AttendanceDrugScreeningInformation({ label, text, isWarning }) {
  const warningClass = isWarning ? 'text-warning-500 font-semibold' : '';

  return (
    <div className="flex">
      <div>{label}:</div>
      <div className={`${warningClass} ml-1`}>{text}</div>
    </div>
  );
}

AttendanceDrugScreeningInformation.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  isWarning: PropTypes.bool,
};

AttendanceDrugScreeningInformation.defaultProps = {
  label: '',
  text: '',
  isWarning: false,
};

export default AttendanceDrugScreeningInformation;
