import React from 'react';
import PropTypes from 'prop-types';

function DatePicker(props) {
  const { value, label, onChange, errorMessage, maxDate } = props;

  const formatForHtml = (dateObj) => dateObj.toISOString().split('T')[0];

  const initialValue = () => (value ? formatForHtml(value) : formatForHtml(new Date()));

  const setDate = (event) => {
    onChange(new Date(`${event.target.value}T12:00:00.000+04:00`));
  };

  const warningClass = () => {
    if (errorMessage) {
      return 'warning';
    }
    return '';
  };

  return (
    <div className="date-picker">
      {!!label && <label>{label}</label>}
      <input
        style={props.style}
        className={warningClass()}
        max={maxDate ? formatForHtml(maxDate) : null}
        onInput={setDate}
        type="date"
        value={initialValue()}
      />
      {!!errorMessage && <p className="warning">{errorMessage}</p>}
    </div>
  );
}

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  style: PropTypes.object,
};

DatePicker.defaultProps = {
  value: new Date(),
  label: '',
  errorMessage: '',
  maxDate: null,
  style: {},
};

export default DatePicker;
