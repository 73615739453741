import React from 'react';
import PropTypes from 'prop-types';
import Filters from '../Filters';

const dayOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

/**
 *  DayPicker Component
 * @param {{label: string, checked: boolean, onChange: function}} props
 * @returns {React.ReactElement} Returns a dropdown list of the days of
 * the week and sends the value to a callback.
 */
function DayPicker({ onChange, error, defaultValue, value, placeholder }) {
  const handleChange = (data) => {
    onChange(data.value);
  };

  return (
    <div className="day-picker">
      <dd className="mx-6 pb-5">
        <Filters
          defaultValue={defaultValue}
          value={value ? { value, label: value } : null}
          isMulti={false}
          removeAllSelection
          type="dayOfWeek"
          data={dayOptions}
          stateChanger={handleChange}
          placeholder={placeholder}
          error={error}
        />
      </dd>
    </div>
  );
}

DayPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  defaultValue: PropTypes.object,
  value: PropTypes.array,
  placeholder: PropTypes.string,
};

DayPicker.defaultProps = {
  error: null,
  defaultValue: null,
  value: null,
  placeholder: 'Select',
};

export default DayPicker;
