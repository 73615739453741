/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import LocationTag from './LocationTag';

// HELPERS
import { dayMonthDateYear, hourMinTimezone } from '../../helpers/DateFormatter';

function DateLocationRender(props) {
  return (
    <>
      <p className="my-1 mb-3">
        <a
          className="font-bold my-1 underline hover:no-underline cursor-pointer"
          href={`/staff/weekly_groups/${props.id}`}
        >
          {dayMonthDateYear(props.data)}
        </a>
      </p>

      <p className="my-1">
        <span className="text-sm text-gray-700">{hourMinTimezone(props.data)}</span>

        <LocationTag id={props.locationId} endpoint="/staff/dashboard_v2/fetch_location" />
      </p>
    </>
  );
}

DateLocationRender.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
};

export default DateLocationRender;
