import React from 'react';
import PropTypes from 'prop-types';
import NumeratorDenominatorContainer from '../shared/NumeratorDenominatorContainer';
import InfoCard from './InfoCard';
import { TAG_STATUS } from '../shared/StatusTag';

function OneMonthStats({ monthData }) {
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  return (
    <InfoCard>
      <div className="text-center font-semibold border-gray-300 border-b p-1 text-xs">
        {currentMonth} (this month to date)
      </div>

      <div className="flex divide-gray-300 divide-x">
        {!monthData || monthData?.length === 0 ? (
          <div className="flex-1">
            <p className="text-center">No data to display</p>
          </div>
        ) : (
          monthData.map((item) => (
            <div className="flex-1" key={item.label}>
              <NumeratorDenominatorContainer
                label={item.label}
                numerator={item.numerator}
                denominator={item.denominator}
                statusTag={item.statusTag}
                noStatus={item.noStatus}
              />
            </div>
          ))
        )}
      </div>
    </InfoCard>
  );
}

OneMonthStats.propTypes = {
  monthData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      numerator: PropTypes.number.isRequired,
      denominator: PropTypes.number.isRequired,
      statusTag: PropTypes.shape({
        label: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(TAG_STATUS)).isRequired,
      }),
      noStatus: PropTypes.bool,
    })
  ).isRequired,
};

export default OneMonthStats;
