import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import useHttp from '../../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import CoSignConfirmationModal from '../../CoSignConfirmationModal';
import { FormAnswerGroupPropType } from '../../documentation/helpers/types';
import DocumentationActionPerformedSummaryModal from '../../DocumentationActionPerformedSummaryModal';
import { ERROR_SUFFIX } from '../../documentation/helpers/constants';

function CoSign({
  authenticityToken,
  onSuccess,
  isModalOpen,
  closeModal,
  chartingNotes,
  isGroupNote,
  member,
  isCarePlan,
  carePlanId,
}) {
  const [requestResponse, setRequestResponse] = useState({});
  const [summaryModalPayload, setSummaryModalPayload] = useState({});
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const { sendRequest, isLoading } = useHttp();

  const prepareRequestPayload = (formValues) => {
    const isAllSelected = formValues.templates?.some((template) => template.value === 'all');
    const onlySelectedTemplateIds = formValues.templates.map((template) => template.value);
    const allTemplateIds = formValues.availableTemplatesToCoSign.map((template) => template.value);

    if (isGroupNote) {
      const allGroupTemplateIds = flattenDeep(
        allTemplateIds.map((templateId) => find(chartingNotes, ['id', templateId])?.lockedNoteIds || [])
      );

      const onlySelectedGroupTemplateIds = flattenDeep(
        onlySelectedTemplateIds.map((templateId) => find(chartingNotes, ['id', templateId])?.lockedNoteIds || [])
      );

      return {
        chartingNoteIds: isAllSelected ? allGroupTemplateIds : onlySelectedGroupTemplateIds,
      };
    }

    return {
      chartingNoteIds: isAllSelected ? allTemplateIds : onlySelectedTemplateIds,
    };
  };

  const coSign = async (values) => {
    const { chartingNoteIds } = !isCarePlan && prepareRequestPayload(values);
    const chartingNoteError =
      chartingNoteIds?.length > 1 ? 'Co-signing did not work for all members.' : 'Co-signing did not work.';
    const summaryModalErrorPayload = {
      status: 'error',
      message: `${isCarePlan ? 'Care Plan has not been successfully co-signed.' : chartingNoteError} ${ERROR_SUFFIX}`,
    };

    try {
      if (isCarePlan) {
        const response = await sendRequest(`/staff/members/${member.id}/care_plans/${carePlanId}/sign`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': authenticityToken,
          },
        });

        const { signedAt: date, signedBy: author, userId, qualifications } = convertObjKeysToCamelCase(response);
        setRequestResponse({ date, author, qualifications });

        setSummaryModalPayload({
          status: 'success',
          message: 'Care Plan has been successfully co-signed.',
        });

        if (onSuccess) onSuccess({ userId, date, author, qualifications });

        closeModal();
        setIsSummaryModalOpen(true);
        return;
      }

      const response = await sendRequest('/staff/documentation/charting_notes/co_sign', {
        method: 'POST',
        data: {
          charting_note_ids: chartingNoteIds,
        },
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });

      const { templates, author, requestProcessedAt, fullyCompleted, successfullyProcessed, qualifications } =
        convertObjKeysToCamelCase(response);

      setRequestResponse({
        templates,
        author,
        qualifications,
        fullyCompleted,
        successfullyProcessed,
        date: requestProcessedAt,
      });

      setSummaryModalPayload({
        status: 'success',
        message: 'Note has been successfully co-signed.',
      });

      if (!fullyCompleted) {
        setSummaryModalPayload(summaryModalErrorPayload);
      }

      if (onSuccess)
        onSuccess({
          chartingNoteIds: successfullyProcessed || [],
          signedAt: requestProcessedAt,
          signedBy: author,
          qualifications,
        });
      closeModal();
      setIsSummaryModalOpen(true);
    } catch (err) {
      setSummaryModalPayload(summaryModalErrorPayload);
      closeModal();
      setIsSummaryModalOpen(true);
    }
  };

  const summaryModalContent = [
    { label: 'Co-Signer', value: requestResponse.author, type: 'text' },
    { label: 'Date/Time', value: requestResponse.date, type: 'date' },
  ];

  return (
    <>
      <DocumentationActionPerformedSummaryModal
        isOpen={isSummaryModalOpen}
        setIsOpen={setIsSummaryModalOpen}
        header={`Co-Sign ${isCarePlan ? 'Care Plan' : 'Note'}`}
        message={summaryModalPayload.message}
        status={summaryModalPayload.status}
        content={summaryModalContent}
      />
      <CoSignConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onCoSign={coSign}
        isCoSignLoading={isLoading}
        chartingNotes={chartingNotes}
        member={member}
        isCarePlan={isCarePlan}
      />
    </>
  );
}

CoSign.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  chartingNotes: PropTypes.arrayOf(FormAnswerGroupPropType),
  isGroupNote: PropTypes.bool,
  member: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      mrn: PropTypes.string.isRequired,
    })
  ),
  isCarePlan: PropTypes.bool,
  carePlanId: PropTypes.number,
};

CoSign.defaultProps = {
  onSuccess: undefined,
  isModalOpen: false,
  chartingNotes: [],
  isGroupNote: false,
  member: null,
  isCarePlan: false,
  carePlanId: null,
};

export default CoSign;
