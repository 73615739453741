import React, { useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { PiMinusSquareBold, PiPlusSquareBold } from 'react-icons/pi';

// COMPONENTS
import Button from './Button';

function OpenCloseIcon(props) {
  const iconClasses = 'w-6 h-6 mr-2 my-auto';
  const [isOpen, setIsOpen] = useState(props.isOpen);

  function toggleIsOpen() {
    const updatedValue = !isOpen;

    setIsOpen(updatedValue);
    props.setOpenStatus(updatedValue);

    window?.Matomo?.getAsyncTracker()?.trackEvent('panel-summary', 'toggle-show-info', updatedValue);
  }

  function renderIcon() {
    if (isOpen) {
      return <PiMinusSquareBold className={iconClasses} />;
    }
    return <PiPlusSquareBold className={iconClasses} />;
  }

  return (
    <Button isOpen={props.isOpen} onClick={() => toggleIsOpen()} isDefaultButton={false} classes="no-underline">
      <div>{renderIcon()}</div>
    </Button>
  );
}

OpenCloseIcon.propTypes = {
  setOpenStatus: PropTypes.func.isRequired,

  isOpen: PropTypes.bool.isRequired,
};

export default OpenCloseIcon;
