/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TbExternalLink } from 'react-icons/tb';
import { IoSearch } from 'react-icons/io5';
import MemberDetailsBox from './MemberDetailsBox';
import { MemberDetailsRowPropTypes } from './MemberDetailsRow';
import DosespotModal from '../DosespotModal';
import PrescriberLookupModal from '../prescriber_lookup/PrescriberLookupModal';

function MedicalSection({ member, medical }) {
  const [showRxModal, setShowRxModal] = useState(false);
  const [showPrescriberLookupModal, setShowPrescriberLookupModal] = useState(false);

  return (
    <>
      <DosespotModal memberId={member.id} isOpen={showRxModal} onClose={() => setShowRxModal(false)} />
      <PrescriberLookupModal
        member={member}
        isOpen={showPrescriberLookupModal}
        onClose={() => setShowPrescriberLookupModal(false)}
      />
      <MemberDetailsBox
        {...medical}
        headerLinks={[
          {
            label: 'Prescriber lookup',
            icon: <IoSearch className="h-5 w-5" />,
            onClick: () => setShowPrescriberLookupModal(true),
          },
          {
            label: 'View Rx',
            icon: <TbExternalLink className="h-5 w-5" />,
            onClick: () => setShowRxModal(true),
          },
        ]}
      />
    </>
  );
}

MedicalSection.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mrn: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
  }).isRequired,
  medical: PropTypes.shape({
    componentType: PropTypes.oneOf(Object.values(MemberDetailsRowPropTypes)).isRequired,
    componentData: PropTypes.arrayOf(MemberDetailsRowPropTypes).isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default MedicalSection;
