import React from 'react';
import PropTypes from 'prop-types';

function CrosshairIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <circle
        cx="9.63184"
        cy="9.21509"
        r="8"
        stroke="#171219"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6319 9.21509H14.4319"
        stroke="#171219"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.83184 9.21509H1.63184H4.83184Z" fill="white" />
      <path
        d="M4.83184 9.21509H1.63184"
        stroke="#171219"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63184 4.41509V1.21509"
        stroke="#171219"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63184 17.2151V14.0151"
        stroke="#171219"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CrosshairIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
};

CrosshairIcon.defaultProps = {
  className: '',
  style: {},
};

export default CrosshairIcon;
