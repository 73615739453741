import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GeneralCell from '../../../GeneralCell';
import { convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_WITH_WEEKDAY_FORMAT,
} from '../../../../helpers/DateFormatter';
import { appendReferrerParams } from '../../../../helpers/ReferrerParams';
import ActionColumnLink from '../../../shared/ActionColumnLink';
import StatusIndicator from '../shared/StatusIndicator';
import HealthCheckinCell from '../shared/HealthCheckinCell';
import MemberAccountCell from '../shared/MemberAccountCell';
import { getStatusColor, getStatusLabel } from '../shared/helpers';
import { useAppointmentsURLs } from './useAppointmentsURLs';

function IndividualAppointmentsTableRow({ row, testID, enableActionsColumn, containerElement }) {
  const {
    member,
    scheduledAt,
    type,
    otherInformation,
    locations,
    attendance,
    providers,
    reason,
    uds,
    app,
    hipaa,
    consent,
    admissionsPacket,
    insurance,
    tpo,
    healthCheckIn,
    financialAgreement,
    idSubmitted,
  } = row ?? {};
  const uniqueLocations = [...new Set(locations)];
  const { status } = attendance ?? {};
  const { memberSummaryUrl, appointmentFinderUrl } = useAppointmentsURLs();
  const individualAppointmentDetailsUrl = appendReferrerParams(appointmentFinderUrl, {
    appointment_occurrence_member_id: row.id,
  });

  const [dateTimeWithOffset, timeZone] = convertToSupportedTimeZone(scheduledAt);
  const day = dateTimeWithOffset.format(DEFAULT_DATE_WITH_WEEKDAY_FORMAT);
  const time = `${dateTimeWithOffset.format(DEFAULT_TIME_FORMAT)} ${timeZone.abbrev}`;

  const formatDate = (datetime) => {
    // 03/05/2023 4:12 PM EDT
    const [date, tz] = convertToSupportedTimeZone(datetime);
    return `${date.format(DEFAULT_DATETIME_FORMAT)} ${tz.abbrev}`;
  };

  return (
    <>
      <GeneralCell
        testID={`member-${testID}`}
        data={
          <a href={memberSummaryUrl.replaceAll('{{user_id}}', member.healthieId)} target="_top">
            {member?.firstName} {member?.lastName}
          </a>
        }
        subText={member?.mrn}
      />
      <GeneralCell
        testID={`time-${testID}`}
        classes="mt-2 mb-2"
        data={
          scheduledAt ? (
            <div className="flex flex-col gap-1">
              <a className="font-semibold text-teal-700 underline" target="_top" href={individualAppointmentDetailsUrl}>
                {day}
              </a>
              <div className="flex gap-2 items-center flex-wrap" style={{ maxWidth: '192px' }}>
                <span className="text-sm text-gray-700">{time}</span>
                {uniqueLocations?.map((location) => (
                  <span key={location} className="py-1 px-1.5 bg-gray-300 rounded-md text-sm text-gray-900">
                    {location}
                  </span>
                ))}
              </div>
            </div>
          ) : null
        }
      />
      <GeneralCell testID={`type-${testID}`} data={type} subText={otherInformation?.join(', ')} />
      <GeneralCell
        testID={`status-${testID}`}
        data={getStatusLabel(status) ?? 'N/A'}
        classes={classNames(getStatusColor(status), { 'text-gray-700 italic': !status })}
      />
      <GeneralCell
        testID={`staff-${testID}`}
        classes={classNames({ 'text-gray-700 italic': !reason })}
        data={providers ?? 'N/A'}
      />
      <GeneralCell
        testID={`appointmentReason-${testID}`}
        classes={classNames({ 'text-gray-700 italic': !reason })}
        data={reason ?? 'N/A'}
      />
      <GeneralCell
        testID={`uds-${testID}`}
        data={
          uds ? (
            <div className="flex flex-row gap-5 items-center">
              <StatusIndicator status={uds.status} />
              <div className="flex flex-col gap-1">
                <span className="text-base">{uds.status ? 'Submitted' : 'Not Submitted'}</span>
                {!!uds.datetime && <span className="text-sm text-gray-700">{formatDate(uds.datetime)}</span>}
              </div>
            </div>
          ) : (
            'N/A'
          )
        }
      />
      <MemberAccountCell testID={`account-${testID}`} account={member.account} />
      <GeneralCell testID={`app-${testID}`} data={<StatusIndicator status={app} />} />
      <GeneralCell testID={`hipaa-${testID}`} data={<StatusIndicator status={hipaa} />} />
      <GeneralCell testID={`consent-${testID}`} data={<StatusIndicator status={consent} />} />
      <GeneralCell testID={`admissionsPacket-${testID}`} data={<StatusIndicator status={admissionsPacket} />} />
      <GeneralCell testID={`tpo-${testID}`} data={<StatusIndicator status={tpo} />} />
      <GeneralCell testID={`financialAgreement-${testID}`} data={<StatusIndicator status={financialAgreement} />} />
      <GeneralCell testID={`id-${testID}`} data={<StatusIndicator status={idSubmitted} />} />
      <GeneralCell testID={`insurance-${testID}`} data={<StatusIndicator status={insurance} />} />
      <HealthCheckinCell testID={`healthCheckIn-${testID}`} healthCheckIn={healthCheckIn} />
      <ActionColumnLink
        containerElement={containerElement}
        enableActionsColumn={enableActionsColumn}
        href={individualAppointmentDetailsUrl}
        testID={`details-url-${testID}`}
      />
    </>
  );
}

IndividualAppointmentsTableRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.shape({
    member: PropTypes.shape({
      createdAt: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      mrn: PropTypes.string,
      healthie_id: PropTypes.string,
      account: PropTypes.shape({
        status: PropTypes.string,
        date: PropTypes.string,
      }).isRequired,
    }),
    scheduledAt: PropTypes.string,
    type: PropTypes.string,
    otherInformation: PropTypes.arrayOf(PropTypes.string),
    locations: PropTypes.arrayOf(PropTypes.string),
    attendance: PropTypes.shape({
      status: PropTypes.string,
      reason: PropTypes.any,
      location: PropTypes.any,
      custom_location: PropTypes.any,
      start_date: PropTypes.any,
      end_date: PropTypes.any,
    }),
    staff: PropTypes.arrayOf(PropTypes.string),
    reason: PropTypes.string,
    uds: PropTypes.shape({
      status: PropTypes.bool,
      datetime: PropTypes.string,
    }),
    app: PropTypes.bool,
    hipaa: PropTypes.bool,
    consent: PropTypes.bool,
    admissionsPacket: PropTypes.bool,
    tpo: PropTypes.bool,
    financialAgreement: PropTypes.bool,
    idSubmitted: PropTypes.bool,
    id: PropTypes.number,
    insurance: PropTypes.bool,
    healthCheckIn: PropTypes.shape({
      status: PropTypes.bool,
      datetime: PropTypes.string,
    }),
  }).isRequired,
  enableActionsColumn: PropTypes.bool,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

IndividualAppointmentsTableRow.defaultProps = {
  enableActionsColumn: true,
  containerElement: null,
};

export default IndividualAppointmentsTableRow;
