import React, { useState } from 'react';
import PropTypes from 'prop-types';

function QuestionIcon(props) {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      className={props.className}
      style={props.style}
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props.onClick()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {props.showHover && isHover && <rect x="-1" y="-1" width="32" height="32" rx="20" fill="#E6F3F4" />}
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16455 0.160156V30.1602H31.1646V0.160156H1.16455Z"
        fill={props.color}
        fillOpacity="0.338428"
      />
      <path
        d="M15.0955 22.5081V22.838"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4341 10.4357C11.2667 8.06875 13.6852 6.64741 16.1582 7.07161C18.6313 7.4958 20.4378 9.64183 20.4341 12.151C20.4341 15.5815 15.2883 17.2968 15.2883 17.2968"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

QuestionIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  color: PropTypes.string,
  showHover: PropTypes.bool,
  onClick: PropTypes.func,
};

QuestionIcon.defaultProps = {
  className: '',
  style: {},
  color: 'black',
  showHover: false,
  onClick: () => {},
};

export default QuestionIcon;
