import * as Yup from 'yup';

const ObjectWithLabelValidation = Yup.object().shape({
  label: Yup.string().nullable(true),
  value: Yup.string().required('Value is required'),
});

const schedulesFormValidationSchema = Yup.object().shape({
  schedule: Yup.object().shape({
    user_id: ObjectWithLabelValidation.required('Staff is required'),
    location_id: ObjectWithLabelValidation.required('Location is required'),
    weekday: ObjectWithLabelValidation.required('Day of the week is required'),
    // eslint-disable-next-line consistent-return
    start_time: Yup.string()
      .required('Start time is required')
      .when('end_time', (endTime, schema) => {
        if (endTime) {
          return schema.test('is-before', 'Start time must be before end time', (value) => {
            if (!value) return true;
            return new Date(value) < new Date(endTime);
          });
        }

        return schema;
      }),
    end_time: Yup.string().required('End time is required'),
    working_location: ObjectWithLabelValidation.required('Working location is required'),
    time_zone: Yup.string().required('Time zone is required'),
  }),
});

export default schedulesFormValidationSchema;
