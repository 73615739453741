import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import WindowedSelect from 'react-windowed-select';

// HELPERS
import { customStyles, theme } from '../helpers/FilterStyles';

function VirtualizedFilter(props) {
  const [selectedOptions, setSelectedOption] = useState(props?.options[0]);

  useEffect(() => {
    setSelectedOption(props?.value);
  }, [props.value]);

  const filter = (selectedFilters) => {
    setSelectedOption(selectedFilters);

    props.stateChanger(selectedFilters);
  };

  return (
    <div className={`react-inline ${props.classes}`} style={props.style}>
      <div className="filter-label" style={props.labelStyle}>
        {props.label}
      </div>

      <WindowedSelect
        isDisabled={props.disable}
        isMulti={props.isMulti}
        placeholder={props.placeholder}
        value={props.value || selectedOptions}
        options={props.options}
        onChange={filter}
        theme={theme}
        styles={customStyles}
      />
    </div>
  );
}

VirtualizedFilter.propTypes = {
  disable: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.array,

  style: PropTypes.object,
  labelStyle: PropTypes.object,
  classes: PropTypes.string,

  // functions
  stateChanger: PropTypes.func,
};

VirtualizedFilter.defaultProps = {
  disable: false,
  isMulti: false,
  style: {},
  classes: '',
  placeholder: '',
  value: null,
  labelStyle: {},

  stateChanger: () => {},
};

export default VirtualizedFilter;
