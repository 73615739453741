import React from 'react';

// IMPORTS
import { PiArrowSquareOutBold } from 'react-icons/pi';

function FedexMethod() {
  return (
    <a className="flex font-bold" href="https://local.fedex.com/en-us" target="_blank" rel="noreferrer">
      <PiArrowSquareOutBold className="w-5 h-5 mr-1" />
      FIND A LOCATION
    </a>
  );
}

export default FedexMethod;
