import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';

function capitalizeFirst(string) {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function parsePhoneString(data) {
  if (!data) {
    return '';
  }
  return parsePhoneNumberFromString(data) ? parsePhoneNumber(data, 'US').formatNational() : data;
}

function isEmptyDataObject(data) {
  if (data) {
    return Object.values(data).every((v) => !v);
  }
  return true;
}

function isEmptyObject(obj) {
  if (obj === undefined) {
    return true;
  }
  if (!obj) {
    return true;
  }
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  return obj.value === null;
}

function errorsExist(formErrors) {
  return !Object.values(formErrors).every((v) => v === null);
}

// eslint-disable-next-line import/prefer-default-export
export { capitalizeFirst, parsePhoneString, isEmptyDataObject, isEmptyObject, errorsExist };
