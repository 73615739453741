import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function TextArea(props) {
  const [value, setValue] = useState(props.value);
  useEffect(() => setValue(props.value), [props.value]);

  function renderSubtext() {
    if (props.subtext) {
      return <div className="text-sm mb-2 text-gray-700">{props.subtext}</div>;
    }

    return <></>;
  }

  return (
    <div style={props.style}>
      <div className="font-bold" style={props.labelStyle}>
        {props.label}
      </div>

      <textarea
        className="react-text-area"
        type="text"
        name="name"
        style={{
          width: '100%',
          marginTop: '5px',
          borderRadius: '4px',
        }}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e.target.value);
        }}
      >
        {value}
      </textarea>

      {renderSubtext()}
    </div>
  );
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  label: PropTypes.string,
  subtext: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
};

TextArea.defaultProps = {
  label: 'Text Box',
  subtext: null,
  labelStyle: {},
  style: {},
};

export default TextArea;
