import React from 'react';
import PropTypes from 'prop-types';

function InviteLink(props) {
  return (
    <>
      {props.show && (
        <div>
          <button
            className={`${props.className} underline text-teal-700`}
            onClick={() => props.onClick(true)}
            type="button"
          >
            {props.text}
          </button>
        </div>
      )}
    </>
  );
}

InviteLink.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

InviteLink.defaultProps = { className: '' };

export default InviteLink;
