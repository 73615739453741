import React from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import { PiCheckCircleFill } from 'react-icons/pi';

// COMPONENTS
import ProgressTodayHeader from './ProgressTodayHeader';
import CompletedTodayContainer from './CompletedTodayContainer';
import HighPriorityTodayContainer from './HighPriorityTodayContainer';

function ProgressTodayContainer(props) {
  function renderHighPriorityContainer() {
    if (props.isCompletedContainer) {
      return <></>;
    }
    return <HighPriorityTodayContainer count={props?.data?.task_count} />;
  }

  function renderCompletedContainer() {
    if (!props.isCompletedContainer) {
      return <></>;
    }

    return (
      <CompletedTodayContainer
        completed={props?.data?.completed}
        total={props?.data?.total}
        progress={props?.data?.progress}
      />
    );
  }

  return (
    <div className="rounded-lg border divide-gray-300 bg-white mr-3 w-72 h-32 p-5 shadow-md">
      <div className="flex">
        <div className="flex-auto">
          <ProgressTodayHeader
            label={props.label}
            showTooltip={!props.isCompletedContainer}
            tooltipText="Please complete tasks in order of priority. AL Task Prioritization is decided by clinical logic that identifies the most critical and time-sensitive tasks as 'high priority'."
          />

          {renderHighPriorityContainer()}
          {renderCompletedContainer()}
        </div>

        {props.isCompletedContainer && (
          <div className="flex">
            <PiCheckCircleFill className="w-8 h-8" style={{ color: '#0F8B8D' }} />
          </div>
        )}
      </div>
    </div>
  );
}

ProgressTodayContainer.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isCompletedContainer: PropTypes.bool,
};

ProgressTodayContainer.defaultProps = {
  isCompletedContainer: false,
};

export default ProgressTodayContainer;
