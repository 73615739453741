const setParams = (
  pageNumber,
  tabParam,
  statesFilters = [],
  locationFilters = [],
  staffFilters = [],
  daysFilters = [],
  nameSortType = 'name asc'
) => {
  const state_ids = statesFilters.map((state) => state.value);
  const location_ids = locationFilters.map((facility) => facility.value);
  const staff_id = staffFilters.value;
  const days = daysFilters.map((day) => day.value);

  const params = {
    tab: tabParam,
    page: pageNumber,
    is_added: tabParam === 'added',
    is_removed: tabParam === 'removed',
    state_ids: state_ids.includes('*') ? null : state_ids,
    location_ids: location_ids.includes('*') ? null : location_ids,
    staff_id: staff_id === '*' ? 'any' : staff_id,
    days: days.includes('*') ? null : days,
    sort_name: nameSortType,
  };

  return params;
};

export default setParams;
