const customStyles = {
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    borderRadius: '8px',
    border: '2px solid #FFFFFF',
    paddingBottom: 0,
    p: 4,
    color: 'black',
    outline: 'none',
    opacity: '100%',
    maxHeight: '100%',
    overflow: 'scroll',
  },
  modalBackgroundStyle: { backgroundColor: 'rgba(19, 62, 70, 0.8) !important' },

  // HEADER
  headerBoxStyle: { display: 'flex', p: 1, height: '60px' },
  headerItemStyle: { flexGrow: 1, paddingBottom: 5 },
  headerStyle: { fontFamily: 'Inter', fontSize: 18, fontWeight: 800 },

  // FULL Modal - below header
  flexBoxStyle: {
    display: 'flex',
    p: 1,
    borderRadius: 1,
    marginLeft: 0,
    marginBottom: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    maxHeight: '80%',
  },

  // LEFT side of modal
  checkBoxListStyle: {
    flex: 1,
    marginTop: 0,
    paddingTop: 0,
    backgroundColor: '#F5F8FA',
    margin: 0,
    padding: 0,
    overflow: 'auto',
  },

  buttonStyle: {
    marginBottom: '20px',
    borderRadius: '30px',
    fontFamily: 'Brandon Grotesque',
    backgroundColor: '#F2C054',
    color: '#171219',
    boxShadow: 'none',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#edb021',
      boxShadow: 'none',
      color: '#171219',
    },
    '&:disabled': {
      backgroundColor: '#FBEED0',
    },
  },

  // RIGHT side of modal
  imageBoxStyle: { flex: 2 },
  imageStyle: { borderRadius: '4px', cursor: 'zoom-in' },
};

export default customStyles;
