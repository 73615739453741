import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  const [statesData, setStates] = useState({});
  const [facilitiesData, setFacilities] = useState({});
  const [officeManagerData, setOfficeManager] = useState({});
  const [staffData, setStaff] = useState({});
  const [empanelmentGroups, setEmpanelmentGroups] = useState({});
  const [daysData, setDays] = useState({});
  const [empanelmentGroupTypeData, setEmpanelmentGroupType] = useState({});
  const [empanelmentSecondaryGroupTypeData, setEmpanelmentSecondaryGroupTypeData] = useState({});

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const { data: response } = await axios.get('/staff/empanelment_members/fetch_filters');

        const {
          states,
          staff,
          locations,
          days,
          office_manager,
          empanelment_groups,
          empanelment_group_type,
          empanelment_secondary_group_type,
        } = response;

        setStates(states);
        setFacilities(locations);
        setStaff(staff);
        setDays(days);
        setOfficeManager(office_manager);
        setEmpanelmentGroups(empanelment_groups);
        setEmpanelmentGroupType(empanelment_group_type);
        setEmpanelmentSecondaryGroupTypeData(empanelment_secondary_group_type);
      } catch (error) {
        window.Sentry.captureException(error);
      }
    };

    fetchFilters();
  }, []);

  return {
    statesData,
    facilitiesData,
    staffData,
    daysData,
    officeManagerData,
    empanelmentGroups,
    empanelmentGroupTypeData,
    empanelmentSecondaryGroupTypeData,
  };
};

export default useFetchData;
