import React from 'react';
import PropTypes from 'prop-types';

function LiListItem(props) {
  return (
    <li>
      <span className="font-semibold">{props?.label}</span>
      {': '}
      {props?.text}
    </li>
  );
}

LiListItem.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LiListItem;
