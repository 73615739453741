/* eslint-disable import/prefer-default-export */
import { RX_STATUS } from '../list/types';

export const parseStatus = (status) => {
  if (status === RX_STATUS.READY_FOR_REVIEW) {
    return {
      label: 'Ready for Review',
      styles: 'text-positive-700 font-bold',
    };
  }

  if (status === RX_STATUS.RX_SENT) {
    return {
      label: 'Rx Sent',
      styles: 'text-positive-700',
    };
  }

  if (status === RX_STATUS.RX_NOT_SENT) {
    return {
      label: 'Rx Not Sent',
      styles: 'text-error-700',
    };
  }

  if (status === RX_STATUS.RX_CANCELLED) {
    return {
      label: 'Rx Cancelled',
      styles: 'text-error-700',
    };
  }

  return {
    label: status,
  };
};
