import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import axios from 'axios';
import Filters from '../../Filters';

// HOOKS
import useSharedFilters from '../../shared/hooks/use-shared-filters';

// HELPERS
import { labelStyle } from './styles';

// IMPORTS

function Contents(props) {
  // FETCH DATA
  const { numericalOptions, fetchNumericalOptions } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchNumericalOptions();
  }, []);

  // COUNTS
  const [udsCount, setUdsCount] = useState(null);
  const [fentanylCount, setFentanylCount] = useState(null);
  const [pregnancyCount, setPregnancyCount] = useState(null);
  const [oralCount, setOralCount] = useState(null);
  const [prepaidEnvelopes, setPrepaidEnvelopes] = useState(null);
  const [showPrepaidEnvelopes, setShowPrepaidEnvelopes] = useState(null);
  const [showOralSwabs, setShowOralSwabs] = useState(null);

  const findMemberInformation = () => {
    if (!props?.memberId) {
      return;
    }
    const params = { id: props?.memberId };
    axios
      .get('/staff/members/fetch_member_demographics_for_pickup', { params })
      .then((response) => {
        const state = response?.data?.empanelment_state;
        if (state === 'TN' || state === 'KY') {
          setShowPrepaidEnvelopes(true);
        }

        if (state === 'TN') {
          setShowOralSwabs(true);
        }
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    findMemberInformation();
  }, []);

  useEffect(() => {
    setUdsCount({ value: 6, label: 6 });
    setFentanylCount({ value: 2, label: 2 });

    const cachedUds = window.localStorage.getItem(`${props.cacheKey}-uds`);
    const parsedUds = JSON.parse(cachedUds);

    if (parsedUds) {
      setUdsCount(parsedUds);
    }

    const cachedFent = window.localStorage.getItem(`${props.cacheKey}-fentanyl`);
    const parsedFent = JSON.parse(cachedFent);

    if (parsedFent) {
      setFentanylCount(parsedFent);
    }

    const cachedPreg = window.localStorage.getItem(`${props.cacheKey}-pregnancy`);
    const parsedPreg = JSON.parse(cachedPreg);

    if (parsedPreg) {
      setPregnancyCount(parsedPreg);
    }

    const cachedOral = window.localStorage.getItem(`${props.cacheKey}-oral`);
    const parsedOral = JSON.parse(cachedOral);

    if (parsedOral) {
      setOralCount(parsedOral);
    }

    const cachedEnvelopes = window.localStorage.getItem(`${props.cacheKey}-envelopes`);
    const parsedEnvelopes = JSON.parse(cachedEnvelopes);

    if (parsedEnvelopes) {
      setPrepaidEnvelopes(parsedEnvelopes);
    }
  }, [props.cacheKey]);

  useEffect(() => {
    const newCounts = props.pickupQuantities;

    newCounts[props.memberId] = {
      uds_count: udsCount && udsCount.value,
      fentanyl_count: fentanylCount && fentanylCount?.value,
      preganancy_count: pregnancyCount && pregnancyCount?.value,
      oral_count: oralCount && oralCount?.value,
      prepaid_envelope_count: prepaidEnvelopes && prepaidEnvelopes?.value,
    };

    props.setPickupQuantities(newCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [udsCount, fentanylCount, pregnancyCount, oralCount, prepaidEnvelopes]);

  return (
    <div className={`flex mb-2 pb-2 ${props.classes}`}>
      <Filters
        label="UDS Cups"
        data={numericalOptions}
        value={udsCount}
        placeholder="-"
        stateChanger={(selected) => {
          setUdsCount(selected);
          window.localStorage.setItem(`${props.cacheKey}-uds`, JSON.stringify(selected));
        }}
        labelStyle={labelStyle}
        isMulti={false}
        style={props.filterStyle}
      />

      <Filters
        label="Fentanyl Strips"
        data={numericalOptions}
        value={fentanylCount}
        placeholder="-"
        stateChanger={(selected) => {
          setFentanylCount(selected);
          window.localStorage.setItem(`${props.cacheKey}-fentanyl`, JSON.stringify(selected));
        }}
        labelStyle={labelStyle}
        isMulti={false}
        style={props.filterStyle}
      />

      <Filters
        label="Pregnancy Tests"
        data={numericalOptions}
        value={pregnancyCount}
        placeholder="-"
        stateChanger={(selected) => {
          setPregnancyCount(selected);
          window.localStorage.setItem(`${props.cacheKey}-pregnancy`, JSON.stringify(selected));
        }}
        labelStyle={labelStyle}
        isMulti={false}
        style={props.filterStyle}
      />

      {showOralSwabs && (
        <Filters
          label="Oral Swabs"
          data={numericalOptions}
          value={oralCount}
          placeholder="-"
          stateChanger={(selected) => {
            setOralCount(selected);
            window.localStorage.setItem(`${props.cacheKey}-oral`, JSON.stringify(selected));
          }}
          labelStyle={labelStyle}
          isMulti={false}
          style={props.filterStyle}
        />
      )}

      {showPrepaidEnvelopes && (
        <Filters
          label="Pre-Paid Envelopes"
          data={numericalOptions}
          value={prepaidEnvelopes}
          placeholder="-"
          stateChanger={(selected) => {
            setPrepaidEnvelopes(selected);
            window.localStorage.setItem(`${props.cacheKey}-envelopes`, JSON.stringify(selected));
          }}
          labelStyle={labelStyle}
          isMulti={false}
          style={props.filterStyle}
        />
      )}
    </div>
  );
}

Contents.propTypes = {
  cacheKey: PropTypes.string.isRequired,

  // OPTIONAL
  classes: PropTypes.string,
  filterStyle: PropTypes.object,
  pickupQuantities: PropTypes.object,
  setPickupQuantities: PropTypes.func,
  memberId: PropTypes.number,
};

Contents.defaultProps = {
  classes: '',
  filterStyle: {},
  pickupQuantities: {},
  setPickupQuantities: () => {},
  memberId: null,
};

export default Contents;
