import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import TimezoneSelect from 'react-timezone-select';

import DayPicker from './shared/DayPicker';
import ModalError from './shared/ModalError';
import Filters from './Filters';

// HELPER IMPORTS
import {
  getTimeZone,
  formatTimeWithoutTimezone,
  formatDayOfWeek,
  formatTime,
  shortGenericTimeZoneName,
} from './DateFormatHelper';
import { customStyles, theme } from '../helpers/FilterStyles';

function EditModal(props) {
  // OPEN CLOSE MODAL LOGIC
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const toggleOpen = () => setIsOpen((value) => !value);

  const [dayDefault, setDayDefault] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [startTime, setStartTime] = useState(moment().minute(0));
  const [timezone, setTimezone] = useState({});

  // SET EDITED DATA
  const [state, setState] = useState({});
  const [facility, setFacility] = useState({});
  const [officeManager, setOfficeManager] = useState({});
  const [modality, setModality] = useState({});
  const [groupType, setGroupType] = useState({});
  const [secondaryGroupTypes, setSecondaryGroupTypes] = useState(null);
  const [counselor, setCounselor] = useState({});
  const [provider, setProvider] = useState({});
  const testID = `editModal-${props.testID}`;

  useEffect(() => {
    setIsOpen(props.isOpen);

    // set initial default value
    setOfficeManager(props.empanelmentGroup.default_office_manager);
    setState(props.empanelmentGroup.default_state);
    setFacility(props.empanelmentGroup.default_facility);
    setCounselor(props.empanelmentGroup.default_counselor);
    setProvider(props.empanelmentGroup.default_provider);
    setModality(props.empanelmentGroup.default_modality);
    setGroupType(props.empanelmentGroup.default_group_type);
    setSecondaryGroupTypes(props.empanelmentGroup.default_secondary_group_types);

    setDayDefault({
      value: formatDayOfWeek(props.empanelmentGroup.date_time),
      label: formatDayOfWeek(props.empanelmentGroup.date_time),
    });
    setDayOfWeek(formatDayOfWeek(props.empanelmentGroup.date_time));
    setStartTime(moment(props.empanelmentGroup.date_time));
  }, [
    props.isOpen,
    props.empanelmentGroup.default_office_manager,
    props.empanelmentGroup.default_counselor,
    props.empanelmentGroup.default_facility,
    props.empanelmentGroup.default_provider,
    props.empanelmentGroup.default_state,
    props.empanelmentGroup.default_modality,
    props.empanelmentGroup.default_group_type,
    props.empanelmentGroup.default_secondary_group_types,
    props.empanelmentGroup.date_time,
  ]);

  // VALIDATIONS
  // This is all not meant to be reusable but will need to be refactored. Validations
  // should be done in their individual pages, not on a modal.
  const requiredText = 'This field is required';

  const [formErrors, setFormErrors] = useState({});

  const isEmptyObject = (obj) => {
    if (obj === undefined) {
      return true;
    }
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return true;
    }
    return obj.value === null;
  };

  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateMeetingFacility = () => {
    if (isEmptyObject(facility)) {
      setError('facility', requiredText);
    } else {
      setError('facility', null);
    }
  };

  const validateCounselor = () => {
    if (isEmptyObject(counselor)) {
      setError('counselor', requiredText);
    } else {
      setError('counselor', null);
    }
  };

  const validateProvider = () => {
    if (isEmptyObject(provider)) {
      setError('provider', requiredText);
    } else {
      setError('provider', null);
    }
  };

  const validateOfficeManager = () => {
    if (isEmptyObject(officeManager)) {
      setError('officeManager', requiredText);
    } else {
      setError('officeManager', null);
    }
  };

  const validateDay = () => {
    if (dayOfWeek === '') {
      setError('dayOfWeek', requiredText);
    } else {
      setError('dayOfWeek', null);
    }
  };

  const validateTime = () => {
    if (startTime === '') {
      setError('startTime', requiredText);
    } else {
      setError('startTime', null);
    }
  };

  const validateTimezone = () => {
    if (isEmptyObject(timezone)) {
      setError('timezone', requiredText);
    } else {
      setError('timezone', null);
    }
  };

  const validateModality = () => {
    if (isEmptyObject(modality)) {
      setError('modality', requiredText);
    } else {
      setError('modality', null);
    }
  };

  const validateGroupType = () => {
    if (isEmptyObject(groupType)) {
      setError('groupType', requiredText);
    } else {
      setError('groupType', null);
    }
  };

  const validateData = () => {
    validateMeetingFacility();
    validateCounselor();
    validateProvider();
    validateDay();
    validateTime();
    validateTimezone();
    validateOfficeManager();
    validateModality();
    validateGroupType();
  };

  useEffect(() => {
    validateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, counselor, provider, officeManager, dayOfWeek, startTime, timezone, modality, groupType]);

  const errorsExist = () => !Object.values(formErrors).every((v) => v === null);

  // @validateSecondaryGroupType HELPER FUNCTION
  // CONCAT error string of related selected but not allowed values
  const typeNamesConcatenated = (typeNames) => {
    const namesOfNotAllowed = typeNames.filter(
      (type) => type === 'Women Only' || type === 'Pregnancy' || type === 'Men Only' || type === 'LGBTQ+'
    );
    const last = namesOfNotAllowed.pop();

    return `${namesOfNotAllowed.join(', ')} and ${last}`;
  };

  const validateSecondaryGroupType = (types) => {
    // DO NOT ALLOW FOR WOMEN ONLY + PREG GROUPS TO BE SELECTED AT THE SAME TIME AS MEN ONLY GROUP
    const typeNames = types.map((type) => type.label);

    const isPregnancy = typeNames?.includes('Pregnancy');
    const isLGBTSelected = typeNames?.includes('LGBTQ+');

    const isWomanSelected = typeNames?.includes('Women Only');
    const isMenSelected = typeNames?.includes('Men Only');

    if ((isPregnancy || isWomanSelected) && isMenSelected) {
      setError('empanelmentSecondaryGroupTypeData', `${typeNamesConcatenated(typeNames)} cannot be applied together`);
    } else if ((isWomanSelected || isMenSelected) && isLGBTSelected) {
      setError('empanelmentSecondaryGroupTypeData', `${typeNamesConcatenated(typeNames)} cannot be applied together`);
    } else {
      setError('empanelmentSecondaryGroupTypeData', null);
    }
  };

  const handleContinue = () => {
    if (!errorsExist()) {
      props.onContinue({
        dayOfWeek,
        startTime: startTime.format('LT'),
        timezone,
        state,
        facility,
        counselor,
        provider,
        officeManager,
        modality,
        groupType,
        secondaryGroupTypes,
        empanelment_time: props.empanelmentGroup.date_time,
      });
    }
  };

  return (
    <>
      {props.secondaryGroupTypeFeatureFlag ? (
        <>
          {isOpen && (
            <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
              <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
                <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-6xl sm:my-8 sm:w-full text-left z-20">
                  <div className="p-6 w-full whitespace-normal">
                    {/* HEADER */}
                    <h2 data-testid={`title-${testID}`} className="font-bold mb-4">
                      Edit Care Team
                    </h2>

                    <p>Edit the Group details below. This will be updated for any member empaneled to this Group.</p>

                    <ModalError errors={props.flashMessage} />

                    <h4
                      data-testid={`groupDateTime-${testID}`}
                      className="bg-white font-bold mb-1 px-6 py-3 rounded-t-lg"
                    >
                      {`${dayOfWeek} at ${formatTimeWithoutTimezone(startTime)} (${shortGenericTimeZoneName(timezone.abbrev) || getTimeZone(props.empanelmentGroup.date_time)}) ${facility.label}`}
                    </h4>

                    {/* FORM */}
                    <form>
                      <dl className="bg-white flex flex-col rounded-b-lg">
                        <>
                          {/* MEETING TIME LABEL */}
                          {/* <dt className="font-bold mx-6 pb-2 pt-5">
                      Meeting Time
                    </dt> */}

                          <div className="flex">
                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Day of the Week*</dt>
                              <DayPicker
                                placeholder={null}
                                defaultValue={dayDefault}
                                onChange={setDayOfWeek}
                                error={formErrors.dayOfWeek}
                              />
                            </div>

                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Start Time*</dt>
                              <div className={`mx-6 pb-5 time-picker-container ${formErrors.startTime && 'danger'}`}>
                                <TimePicker
                                  showSecond={false}
                                  defaultValue={startTime}
                                  onChange={setStartTime}
                                  use12Hours
                                  inputReadOnly
                                  allowEmpty={false}
                                />
                                {formErrors.startTime && (
                                  <p className="text-red-500 text-xs italic">{formErrors.startTime}</p>
                                )}
                              </div>
                            </div>

                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Time Zone</dt>

                              <dd className={`mx-6 pb-5 ${formErrors.timezone && 'danger'}`}>
                                <TimezoneSelect
                                  value={timezone}
                                  onChange={setTimezone}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  styles={customStyles}
                                  theme={theme}
                                  timezones={{
                                    'US/Eastern': 'US/Eastern',
                                    'US/Central': 'US/Central',
                                    'US/Mountain': 'US/Mountain',
                                    'US/Arizona': 'US/Arizona',
                                    'US/Pacific': 'US/Pacific',
                                    'US/Hawaii': 'US/Hawaii',
                                  }}
                                />
                                {formErrors.timezone && (
                                  <p className="text-red-500 text-xs italic">{formErrors.timezone}</p>
                                )}
                              </dd>
                            </div>
                          </div>

                          <hr className="w-full" />

                          <div className="flex">
                            <div className="flex-filters-empanelment">
                              {/* GROUP MEETING FACILITY FILTERS */}
                              <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility</dt>

                              <dd data-testid={`facilityFilter-${testID}`} className="mx-6 pb-5">
                                <Filters
                                  isMulti={false}
                                  removeAllSelection
                                  type="facilities"
                                  data={props.facilitiesFilters}
                                  defaultValue={props.empanelmentGroup.default_facility}
                                  stateChanger={setFacility}
                                  testID={`facility-${testID}`}
                                  error={formErrors.facility}
                                />
                              </dd>
                            </div>

                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Modality</dt>

                              <dd data-testid={`modailtyFilter-${testID}`} className="mx-6 pb-5">
                                <Filters
                                  isMulti={false}
                                  removeAllSelection
                                  type="facilities"
                                  data={props.modalityFilters}
                                  defaultValue={props.empanelmentGroup.default_modality}
                                  stateChanger={setModality}
                                  testID={`modailty-${testID}`}
                                  error={formErrors.modality}
                                />
                              </dd>
                            </div>
                          </div>
                        </>

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Clinical Model</dt>

                            <dd data-testid={`groupTypeFilter-${testID}`} className="mx-6 pb-5">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="facilities"
                                data={props.empanelmentGroupTypeFilters}
                                defaultValue={props.empanelmentGroup.default_group_type}
                                stateChanger={setGroupType}
                                testID={`groupType-${testID}`}
                                error={formErrors.groupType}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            <dt className="font-bold mx-6 pb-2 pt-5">Other Information</dt>

                            <dd className="mx-6 pb-5">
                              <Filters
                                isMulti
                                removeAllSelection
                                type="secondaryGroupTypes"
                                data={props.empanelmentSecondaryGroupTypeData}
                                value={secondaryGroupTypes || props.empanelmentGroup.default_secondary_group_types}
                                stateChanger={(newTypes) => {
                                  setSecondaryGroupTypes(newTypes);
                                  validateSecondaryGroupType(newTypes);
                                }}
                                placeholder="Select"
                                data-testid={`secondary-group-types-${testID}`}
                                error={formErrors.empanelmentSecondaryGroupTypeData}
                              />
                            </dd>
                          </div>
                        </div>

                        <hr className="w-full" />

                        <div className="flex">
                          <div className="flex-filters-empanelment">
                            {/* OFFICE MANAGER FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Office Manager</dt>

                            <dd data-testid={`officeManagerFilter-${testID}`} className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="office_managers"
                                data={props.officeManagerFilters}
                                defaultValue={props.empanelmentGroup.default_office_manager}
                                stateChanger={setOfficeManager}
                                testID={`officeManager-${testID}`}
                                error={formErrors.officeManager}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            {/* COUNSELOR FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Counselor</dt>

                            <dd data-testid={`counselorFilter-${testID}`} className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="facilities"
                                data={props.staffFilters}
                                defaultValue={props.empanelmentGroup.default_counselor}
                                stateChanger={setCounselor}
                                testID={`counselor-${testID}`}
                                error={formErrors.counselor}
                              />
                            </dd>
                          </div>

                          <div className="flex-filters-empanelment">
                            {/* PROVIDER FILTER */}
                            <dt className="font-bold mx-6 pb-2 pt-5">Provider</dt>

                            <dd data-testid={`providerFilter-${testID}`} className="mx-6 pb-3">
                              <Filters
                                isMulti={false}
                                removeAllSelection
                                type="facilities"
                                data={props.staffFilters}
                                defaultValue={props.empanelmentGroup.default_provider}
                                stateChanger={setProvider}
                                testID={`provider-${testID}`}
                                error={formErrors.provider}
                              />
                            </dd>
                          </div>
                        </div>

                        <hr className="w-full" />

                        <>
                          <hr className="w-full" />

                          <dt className="font-bold mx-6 pb-2 pt-5">Meeting Time</dt>
                          <dd className="mx-6 pb-5">
                            {`${formatDayOfWeek(props.empanelmentGroup.date_time)} at ${formatTime(props.empanelmentGroup.date_time)}`}
                          </dd>

                          <hr className="w-full" />

                          {/* GROUP MEETING FACILITY FILTERS */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility</dt>

                          <dd data-testid={`facilityFilter-${testID}`} className="mx-6 pb-5">
                            <Filters
                              isMulti={false}
                              removeAllSelection
                              type="facilities"
                              data={props.facilitiesFilters}
                              defaultValue={props.empanelmentGroup.default_facility}
                              stateChanger={setFacility}
                              testID={`facility-${testID}`}
                            />
                          </dd>
                        </>
                      </dl>

                      {/* BUTTONS */}
                      <div className="flex mt-4">
                        <button
                          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                          onClick={toggleOpen}
                          type="button"
                          data-testid={`backBtn-${testID}`}
                        >
                          Back
                        </button>

                        <button
                          className="btn btn--rounded btn--primary flex-1 ml-2"
                          type="button"
                          onClick={handleContinue}
                          data-testid={`continueBtn-${testID}`}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isOpen && (
            <div data-testid={testID} className="fixed inset-0 overflow-y-auto z-20">
              <div className="flex items-end justify-center min-h-screen pb-20 pt-4 px-4 relative sm:block sm:p-0 text-center z-20">
                <div className="main-edit-modal-empanelment align-bottom bg-blue-100 flex flex-col inline-block my-auto relative rounded-lg sm:align-middle sm:max-w-2xl sm:my-8 sm:w-full text-left z-20">
                  <div className="p-6 w-full whitespace-normal">
                    {/* HEADER */}
                    <h2 data-testid={`title-${testID}`} className="font-bold mb-4">
                      Edit Care Team
                    </h2>

                    <p>Edit the Group details below. This will be updated for any member empaneled to this Group.</p>

                    <ModalError errors={props.flashMessage} />

                    <h4
                      data-testid={`groupDateTime-${testID}`}
                      className="bg-white font-bold mb-1 px-6 py-3 rounded-t-lg"
                    >
                      {`${dayOfWeek} at ${formatTimeWithoutTimezone(startTime)} (${shortGenericTimeZoneName(timezone.abbrev) || getTimeZone(props.empanelmentGroup.date_time)}) ${facility.label}`}
                    </h4>

                    {/* FORM */}
                    <form>
                      <dl className="bg-white flex flex-col rounded-b-lg">
                        <>
                          {/* MEETING TIME LABEL */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Meeting Time</dt>

                          <div className="flex">
                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Day of the Week*</dt>
                              <DayPicker
                                placeholder={null}
                                defaultValue={dayDefault}
                                onChange={setDayOfWeek}
                                error={formErrors.dayOfWeek}
                              />
                            </div>

                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Start Time*</dt>
                              <div className={`mx-6 pb-5 time-picker-container ${formErrors.startTime && 'danger'}`}>
                                <TimePicker
                                  showSecond={false}
                                  defaultValue={startTime}
                                  onChange={setStartTime}
                                  use12Hours
                                  inputReadOnly
                                  allowEmpty={false}
                                />
                                {formErrors.startTime && (
                                  <p className="text-red-500 text-xs italic">{formErrors.startTime}</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <hr className="w-full" />

                          <dt className="font-bold mx-6 pb-2 pt-5">Time Zone</dt>

                          <dd className={`mx-6 pb-5 ${formErrors.timezone && 'danger'}`}>
                            <TimezoneSelect
                              value={timezone}
                              onChange={setTimezone}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={customStyles}
                              theme={theme}
                              timezones={{
                                'US/Eastern': 'US/Eastern',
                                'US/Central': 'US/Central',
                                'US/Mountain': 'US/Mountain',
                                'US/Arizona': 'US/Arizona',
                                'US/Pacific': 'US/Pacific',
                                'US/Hawaii': 'US/Hawaii',
                              }}
                            />
                            {formErrors.timezone && (
                              <p className="text-red-500 text-xs italic">{formErrors.timezone}</p>
                            )}
                          </dd>

                          <hr className="w-full" />

                          {/* GROUP MEETING FACILITY FILTERS */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility</dt>

                          <dd data-testid={`facilityFilter-${testID}`} className="mx-6 pb-5">
                            <Filters
                              isMulti={false}
                              removeAllSelection
                              type="facilities"
                              data={props.facilitiesFilters}
                              defaultValue={props.empanelmentGroup.default_facility}
                              stateChanger={setFacility}
                              testID={`facility-${testID}`}
                              error={formErrors.facility}
                            />
                          </dd>
                        </>

                        <hr className="w-full" />

                        {/* MODALITY AND GROUP PHASE FILTERS */}
                        <>
                          <div className="flex">
                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Modality</dt>

                              <dd data-testid={`modailtyFilter-${testID}`} className="mx-6 pb-5">
                                <Filters
                                  isMulti={false}
                                  removeAllSelection
                                  type="facilities"
                                  data={props.modalityFilters}
                                  defaultValue={props.empanelmentGroup.default_modality}
                                  stateChanger={setModality}
                                  testID={`modailty-${testID}`}
                                  error={formErrors.modality}
                                />
                              </dd>
                            </div>

                            <div className="flex-filters-empanelment">
                              <dt className="font-bold mx-6 pb-2 pt-5">Group Type</dt>

                              <dd data-testid={`groupTypeFilter-${testID}`} className="mx-6 pb-5">
                                <Filters
                                  isMulti={false}
                                  removeAllSelection
                                  type="facilities"
                                  data={props.empanelmentGroupTypeFilters}
                                  defaultValue={props.empanelmentGroup.default_group_type}
                                  stateChanger={setGroupType}
                                  testID={`groupType-${testID}`}
                                  error={formErrors.groupType}
                                />
                              </dd>
                            </div>
                          </div>

                          <hr className="w-full" />

                          {/* OFFICE MANAGER FILTER */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Office Manager</dt>

                          <dd data-testid={`officeManagerFilter-${testID}`} className="mx-6 pb-3">
                            <Filters
                              isMulti={false}
                              removeAllSelection
                              type="office_managers"
                              data={props.officeManagerFilters}
                              defaultValue={props.empanelmentGroup.default_office_manager}
                              stateChanger={setOfficeManager}
                              testID={`officeManager-${testID}`}
                              error={formErrors.officeManager}
                            />
                          </dd>
                        </>

                        <hr className="w-full" />

                        {/* COUNSELOR FILTER */}
                        <dt className="font-bold mx-6 pb-2 pt-5">Counselor</dt>

                        <dd data-testid={`counselorFilter-${testID}`} className="mx-6 pb-3">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            type="facilities"
                            data={props.staffFilters}
                            defaultValue={props.empanelmentGroup.default_counselor}
                            stateChanger={setCounselor}
                            testID={`counselor-${testID}`}
                            error={formErrors.counselor}
                          />
                        </dd>

                        <hr className="w-full" />

                        {/* PROVIDER FILTER */}
                        <dt className="font-bold mx-6 pb-2 pt-5">Provider</dt>

                        <dd data-testid={`providerFilter-${testID}`} className="mx-6 pb-3">
                          <Filters
                            isMulti={false}
                            removeAllSelection
                            type="facilities"
                            data={props.staffFilters}
                            defaultValue={props.empanelmentGroup.default_provider}
                            stateChanger={setProvider}
                            testID={`provider-${testID}`}
                            error={formErrors.provider}
                          />
                        </dd>

                        <>
                          <hr className="w-full" />

                          <dt className="font-bold mx-6 pb-2 pt-5">Meeting Time</dt>
                          <dd className="mx-6 pb-5">
                            {`${formatDayOfWeek(props.empanelmentGroup.date_time)} at ${formatTime(props.empanelmentGroup.date_time)}`}
                          </dd>

                          <hr className="w-full" />

                          {/* GROUP MEETING FACILITY FILTERS */}
                          <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility</dt>

                          <dd data-testid={`facilityFilter-${testID}`} className="mx-6 pb-5">
                            <Filters
                              isMulti={false}
                              removeAllSelection
                              type="facilities"
                              data={props.facilitiesFilters}
                              defaultValue={props.empanelmentGroup.default_facility}
                              stateChanger={setFacility}
                              testID={`facility-${testID}`}
                            />
                          </dd>
                        </>
                      </dl>

                      {/* BUTTONS */}
                      <div className="flex mt-4">
                        <button
                          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
                          onClick={toggleOpen}
                          type="button"
                          data-testid={`backBtn-${testID}`}
                        >
                          Back
                        </button>

                        <button
                          className="btn btn--rounded btn--primary flex-1 ml-2"
                          type="button"
                          onClick={handleContinue}
                          data-testid={`continueBtn-${testID}`}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bg-teal-700 fixed inset-0 opacity-80 z-10" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,

  // filters
  // statesFilters: PropTypes.array.isRequired,
  facilitiesFilters: PropTypes.array.isRequired,
  staffFilters: PropTypes.array.isRequired,
  officeManagerFilters: PropTypes.array.isRequired,
  modalityFilters: PropTypes.array.isRequired,
  empanelmentGroupTypeFilters: PropTypes.array.isRequired,
  empanelmentSecondaryGroupTypeData: PropTypes.array.isRequired,
  empanelmentGroup: PropTypes.object.isRequired,
  testID: PropTypes.string.isRequired,
  flashMessage: PropTypes.array,

  // functions
  onContinue: PropTypes.func.isRequired,
};

EditModal.defaultProps = {
  flashMessage: [],
};

export default EditModal;
