import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// COMPONENTS
import Header from '../../shared/Header';
import Filters from '../../Filters';
import NextButton from '../shared/NextButton';
import ShipmentInput from '../shared/ShipmentInput';
import MuiDatePicker from '../../shared/MuiDatePicker';

// HELPERS
import { labelStyle } from '../shared/styles';
import useSharedFilters from '../../shared/hooks/use-shared-filters';

function PackageDimensions(props) {
  // CACHED DATA
  const { date, packaging, length, width, height, distanceUnit, weight, massUnit } = props.labelContents || {};

  // SET DATA
  const [contents, setContents] = useState({});
  useEffect(() => setContents(props.labelContents), [props.labelContents]);

  // PACKAGE DATA
  const [packageSelected, setPackage] = useState(packaging);
  useEffect(() => {
    setPackage(packaging);
  }, [packaging]);

  const { packageOptions, fetchPackageDimensions } = useSharedFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchPackageDimensions();
  }, []);

  const renderCustom = () => {
    if (packageSelected?.value !== 'custom') {
      return <></>;
    }

    return (
      <div className="flex" style={{ marginBottom: '20px' }}>
        <ShipmentInput
          isFloat
          label="Length"
          value={length}
          stateChanger={(selected) => {
            contents.length = selected;
            props.onSetData(contents);
          }}
          error={props.formErrors.length}
        />

        <div
          style={{
            marginTop: '32px',
            marginBottom: 'auto',
            marginRight: '20px',
            marginLeft: '20px',
          }}
        >
          x
        </div>

        <ShipmentInput
          isFloat
          label="Width"
          value={width}
          stateChanger={(selected) => {
            contents.width = selected;
            props.onSetData(contents);
          }}
          error={props.formErrors.width}
        />

        <div
          style={{
            marginTop: '32px',
            marginBottom: 'auto',
            marginRight: '20px',
            marginLeft: '20px',
          }}
        >
          x
        </div>

        <ShipmentInput
          isFloat
          label="Height"
          value={height}
          stateChanger={(selected) => {
            contents.height = selected;
            props.onSetData(contents);
          }}
          error={props.formErrors.height}
        />

        <Filters
          classes="flex-1"
          style={{ marginBottom: '20px', marginTop: '24px', marginLeft: '20px' }}
          value={distanceUnit}
          data={[
            { label: 'in', value: 'in' },
            { label: 'cm', value: 'cm' },
          ]}
          stateChanger={(selected) => {
            contents.distanceUnit = selected;
            props.onSetData(contents);
          }}
          labelStyle={labelStyle}
          isMulti={false}
          error={props.formErrors.distanceUnit}
        />
      </div>
    );
  };
  return (
    <>
      <Header text="Package Dimensions" withBottomMargin withNoUnderline />
      <div style={{ marginBottom: '20px' }}>
        Rates are calculated based on package dimensions and weight. It's recommended to enter the correct weight and
        dimensions. If not, you may receive adjustment charges.
      </div>

      <Filters
        style={{ marginBottom: '20px' }}
        label="Packaging*"
        value={packageSelected}
        data={packageOptions}
        placeholder="Select Packaging"
        stateChanger={(selected) => {
          contents.packaging = selected;
          setPackage(selected);

          props.onSetData(contents);
        }}
        labelStyle={labelStyle}
        isMulti={false}
        removeAllSelection
        error={props.formErrors.packaging}
      />

      {renderCustom()}

      <div className="flex" style={{ marginBottom: '20px' }}>
        <ShipmentInput
          isFloat
          classes="flex-2"
          label="Package Weight*"
          value={weight}
          stateChanger={(selected) => {
            contents.weight = selected;
            props.onSetData(contents);
          }}
          error={props.formErrors.weight}
        />

        <Filters
          classes="flex-1"
          style={{
            marginBottom: '20px',
            marginTop: '22px',
            marginRight: '20px',
          }}
          value={massUnit}
          data={[
            { label: 'lb', value: 'lb' },
            { label: 'kg', value: 'kg' },
          ]}
          stateChanger={(selected) => {
            contents.massUnit = selected;
            props.onSetData(contents);
          }}
          labelStyle={labelStyle}
          isMulti={false}
          error={props.formErrors.massUnit}
        />

        <MuiDatePicker
          label="Ship Date*"
          minDate={dayjs().toDate()}
          value={date}
          onChange={(selected) => {
            contents.date = selected;
            props.onSetData(contents);
          }}
          labelStyle={labelStyle}
          style={{ maxWidth: '50%' }}
          error={props.formErrors.date}
        />
      </div>

      <NextButton
        classes="w-full flex-2"
        text="CALCULATE RATES"
        onClick={props.onShowRates}
        disabled={props.calculateRatesLoading}
        isLoading={props.calculateRatesLoading}
      />
      <hr className="w-full my-10" />
    </>
  );
}

PackageDimensions.propTypes = {
  labelContents: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSetData: PropTypes.func.isRequired,
  onShowRates: PropTypes.func.isRequired,
  calculateRatesLoading: PropTypes.bool.isRequired,
};

export default PackageDimensions;
