import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PiWarningCircleFill, PiXLight } from 'react-icons/pi';

function ServerError(props) {
  const [showError, setShowError] = useState(true);

  const styles = {
    redDiv: {
      background: '#FAEEEB',
      borderRadius: '10px',
      border: '1px solid #CD5334',
      marginBottom: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px',
    },
  };

  const errorBlock = () => (
    <div>
      <div style={styles.redDiv}>
        <div>
          <PiWarningCircleFill
            style={{
              paddingRight: '5px',
              color: '#CD5334',
            }}
            size="40px"
          />
        </div>
        <div>
          <p className="text-left font-bold">
            {props.error}
            {': '}
            {props.message}
          </p>
          <p className="text-left">
            We are sorry you are running into this issue. Please submit an IT ticket with the error above so we can
            resolve this.
          </p>
        </div>
        <button type="button" onClick={() => setShowError(false)}>
          <PiXLight size="25px" />
        </button>
      </div>
    </div>
  );

  return <div>{showError && errorBlock()}</div>;
}

ServerError.propTypes = {
  error: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ServerError.defaultProps = {
  message: '',
};

export default ServerError;
