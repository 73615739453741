import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import InviteHistoryEmailItem from './InviteHistoryEmailItem';
import InviteHistorySmsItem from './InviteHistorySmsItem';

function InviteHistoryModalItem(props) {
  return (
    <>
      <li className="my-4">
        {props.history?.type === 'email' ? (
          <InviteHistoryEmailItem history={props.history} />
        ) : (
          <InviteHistorySmsItem history={props.history} />
        )}
      </li>
      <hr className="w-full" />
    </>
  );
}

InviteHistoryModalItem.propTypes = {
  history: PropTypes.object.isRequired,
};

export default InviteHistoryModalItem;
