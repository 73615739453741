import { useEffect, useState } from 'react';
import useHttp from '../shared/hooks/use-http';

const useChartingNoteMemberIds = ({ appointmentOccurrenceId, sessionIdentifier }) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [requestResponse, setRequestResponse] = useState({});

  useEffect(() => {
    if (!appointmentOccurrenceId && !sessionIdentifier) return;

    const fetchChartingNoteMemberIds = async () => {
      try {
        const response = await sendRequest('/staff/documentation/charting_notes/member_ids', {
          method: 'GET',
          params: {
            appointment_occurrence_id: appointmentOccurrenceId,
            session_identifier: sessionIdentifier,
          },
        });

        setRequestResponse(response);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };
    fetchChartingNoteMemberIds();
  }, [sendRequest, appointmentOccurrenceId, sessionIdentifier]);

  return {
    chartingNoteMemberIds: requestResponse,
    isLoading,
    error,
  };
};

export default useChartingNoteMemberIds;
