import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatShortDateTime } from '../../DateFormatHelper';
import GoalTimeline from './GoalTimeline';
import HistoricalGoalDetails from './HistoricalGoalDetails';
import HistoricalGoalHeader from './HistoricalGoalHeader';

function HistoricalGoal({ _memberId, goal, _updateCallback, testId }) {
  const [editOpen, _setEditOpen] = useState(false);

  const goalComponent = () => {
    if (editOpen) {
      return <div>Being Edited</div>;
    }
    return <HistoricalGoalDetails goal={goal} testId={`details-${testId}`} />;
  };

  return (
    <div className="historical-goal" data-testid={testId}>
      <GoalTimeline status={goal.status} testId={`timeline-${testId}`} />
      <div className="historical-goal-container" data-testid={`container-${testId}`}>
        <HistoricalGoalHeader goal={goal} testId={`header-${testId}`} />
        <p className="goal-deactivated-time" data-testid={`timestamp-${testId}`}>
          {formatShortDateTime(goal.deactivated_at)}
        </p>
        {goalComponent()}
      </div>
    </div>
  );
}

HistoricalGoal.propTypes = {
  _memberId: PropTypes.number.isRequired,
  goal: PropTypes.object.isRequired,
  _updateCallback: PropTypes.func,
  testId: PropTypes.string.isRequired,
};

HistoricalGoal.defaultProps = {
  _updateCallback: () => {},
};

export default HistoricalGoal;
