import React from 'react';
import PropTypes from 'prop-types';

function ScheduleRow({ dayOfWeek, startTime, endTime, locations }) {
  const scheduleData = () => {
    if (startTime) {
      return (
        <div className="flex py-2">
          <p className="px-2 py-0 mb-0.5">{`${dayOfWeek}: `}</p>
          <div>
            <p className="py-0 mb-0.5">{`${startTime} - ${endTime}`}</p>
            <small>{locations}</small>
          </div>
        </div>
      );
    }
    return (
      <div className="flex py-2">
        <p className="px-2 py-0 mb-0.5">{`${dayOfWeek}: `}</p>
        <div>
          <p className="py-0 mb-0.5">Out of office</p>
        </div>
      </div>
    );
  };

  return scheduleData();
}

ScheduleRow.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  locations: PropTypes.string,
};

ScheduleRow.defaultProps = {
  startTime: '',
  endTime: '',
  locations: '',
};

export default ScheduleRow;
