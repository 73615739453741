import React, { useEffect, useCallback, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SignaturePad from 'react-signature-pad-wrapper';
import Button from './Button';
import InputWrapper from './InputWrapper';

// eslint-disable-next-line react/display-name
const SignatureInput = forwardRef(({ name, value, onChange, label, error, required, isVertical }, ref) => {
  const signatureRef = useRef(null);

  const getSignaturePad = useCallback(() => signatureRef?.current?.signaturePad, [signatureRef]);

  const handleClearSignature = () => {
    const signaturePad = getSignaturePad();
    signaturePad.clear();
    onChange('');
  };

  const handleSignatureChange = useCallback(() => {
    const signaturePad = getSignaturePad();
    const signature = signaturePad.toDataURL();
    onChange(signature);
  }, [getSignaturePad, onChange]);

  useEffect(() => {
    const signaturePad = getSignaturePad();

    if (!signaturePad) return null;

    signaturePad.addEventListener('endStroke', handleSignatureChange);

    return () => {
      signaturePad.removeEventListener('endStroke', handleSignatureChange);
    };
  }, [getSignaturePad, handleSignatureChange]);

  return (
    <InputWrapper label={label} name={name} required={required} error={error} isVertical={isVertical}>
      <Button isPrimary onClick={handleClearSignature} classes="float-right">
        Clear
      </Button>
      <SignaturePad
        ref={(e) => {
          signatureRef.current = e;
          if (ref) ref(e);
        }}
        height={200}
        canvasProps={{
          className: classNames('shared-input', {
            'shared-input-error': !!error,
          }),
        }}
      />
      <input data-testid="input-hidden" type="hidden" name={name} value={value} />
    </InputWrapper>
  );
});

SignatureInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
};

SignatureInput.defaultProps = {
  error: null,
  required: false,
  isVertical: true,
};

export default SignatureInput;
