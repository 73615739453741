import React from 'react';
import PropTypes from 'prop-types';

function ActionsMenu({ testId, children }) {
  return (
    <div className="actions-menu" data-testid={testId}>
      {children}
    </div>
  );
}

ActionsMenu.propTypes = {
  testId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ActionsMenu.defaultProps = {
  children: null,
};

export default ActionsMenu;
