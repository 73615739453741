import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import GeneralCell from '../../GeneralCell';
import SimpleTable from '../../shared/SimpleTable';

const tableHeaders = ['Rx Note'];
function RxNote({ note, testID }) {
  return (
    <SimpleTable headers={tableHeaders}>
      <tr className="whitespace-pre-wrap">
        <GeneralCell
          testID={`name-${testID}`}
          data={note || 'None provided'}
          classes={classNames('mb-2', { 'italic text-gray-700': !note })}
        />
      </tr>
    </SimpleTable>
  );
}

RxNote.propTypes = {
  note: propTypes.string,
  testID: propTypes.string,
};

RxNote.defaultProps = {
  note: null,
  testID: '',
};

export default RxNote;
