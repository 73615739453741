import GROUP_TABS_KEYS from './GroupTabKeys';

const tabs = [
  {
    id: 1,
    name: 'Home Groups',
    key: GROUP_TABS_KEYS.HOME_GROUP,
  },
  {
    id: 2,
    name: 'Makeup Groups',
    key: GROUP_TABS_KEYS.MAKEUP_GROUP,
  },
];

export default tabs;
