import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import 'rc-picker/assets/index.css';
import classNames from 'classnames';
import moment from 'moment';
import Toasts from '../../shared/Toasts';
import useToasts from '../../shared/hooks/use-toasts';
import DateInput from '../../shared/DateInput';
import useHttp from '../../shared/hooks/use-http';
import Filters from '../../Filters';
import Section from './Section';
import GroupsModal from '../../GroupsModal';
import { statusData } from './constants';
import { prepareEditTaskModalDefaultValues } from './helpers';
import { RX_STATUS } from '../list/types';
import PrescriptionsForReviewModal from './PrescriptionsForReviewModal';

function EditTaskModal({
  isOpen,
  onClose,
  memberName,
  prescriptionTaskDetails,
  authenticityToken,
  prescriptionReasons,
  onSuccess,
  isOtherPendingPrescription,
}) {
  const [isPrescriptionsForReviewModalOpen, setIsPrescriptionsForReviewModalOpen] = useState(false);

  const { toasts, addToast, removeToast } = useToasts();
  const { sendRequest, isLoading } = useHttp();
  const { handleSubmit, control, register, reset, getValues } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: prepareEditTaskModalDefaultValues(prescriptionTaskDetails, prescriptionReasons),
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: onClose,
    },
    {
      label: 'Save',
      isPrimary: true,
      type: 'submit',
      form: 'edit-task-form',
      isLoading,
    },
  ];

  const editPrescriptionTask = async (form) => {
    try {
      const response = await sendRequest(`/staff/prescription_tasks/${prescriptionTaskDetails.id}`, {
        method: 'PUT',
        headers: { common: { 'X-CSRF-Token': authenticityToken } },
        data: {
          due_date: form.dueDate,
          status: form.status?.value,
          prescription_reason_id: form.reason?.value,
          note: form.note,
        },
      });

      onSuccess(response);
    } catch (error) {
      addToast({
        header: 'Something went wrong',
        message: error?.parsedMessage ?? 'Task could not be edited at this time. Please try again.',
        type: 'error',
      });
    }
  };

  const onSubmit = async (form) => {
    if (form.status.value === RX_STATUS.RX_SENT && isOtherPendingPrescription) {
      setIsPrescriptionsForReviewModalOpen(true);
      return;
    }

    editPrescriptionTask(form);
  };

  const handlePrescriptionsForReviewConfirm = () => {
    const inputValues = getValues();
    editPrescriptionTask(inputValues);
  };

  return (
    <>
      <GroupsModal
        isOpen={isOpen && !isPrescriptionsForReviewModalOpen}
        header="Edit Task"
        subHeader="*Required field"
        footerButtons={footerButtons}
        size="small"
        isLoading={isLoading}
      >
        <Toasts toasts={toasts} removeToast={removeToast} />
        <form
          className="bg-white rounded-lg shadow"
          id="edit-task-form"
          data-testid="edit-task-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Section label="Member">
            <span>{memberName}</span>
          </Section>
          <Section label="Due Date*">
            <Controller
              name="dueDate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateInput
                    {...field}
                    placeholder="Select date"
                    required
                    minDate={moment()}
                    error={fieldState.error?.message}
                    className={classNames({
                      danger: fieldState.error,
                    })}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </Section>
          <Section label="Status*">
            <Controller
              name="status"
              control={control}
              isRequired
              render={({ field, fieldState }) => (
                <Filters
                  isMulti={false}
                  value={field.value}
                  data={statusData}
                  stateChanger={field.onChange}
                  placeholder="Select status"
                  error={fieldState.error?.message}
                />
              )}
            />
          </Section>
          <Section label="Reason">
            <Controller
              name="reason"
              control={control}
              render={({ field, fieldState }) => (
                <Filters
                  isMulti={false}
                  value={field.value}
                  data={prescriptionReasons}
                  stateChanger={field.onChange}
                  placeholder="Select reason"
                  error={fieldState.error?.message}
                />
              )}
            />
          </Section>
          <Section label="Note">
            <textarea
              type="text"
              className="w-full rounded-lg border border-gray-300"
              data-testid="note-textarea"
              {...register('note')}
            />
          </Section>
        </form>
      </GroupsModal>
      <PrescriptionsForReviewModal
        isOpen={isPrescriptionsForReviewModalOpen}
        onClose={onClose}
        onConfirm={handlePrescriptionsForReviewConfirm}
        isLoading={isLoading}
        memberName={memberName}
        memberMrn={prescriptionTaskDetails.member?.mrn}
      />
    </>
  );
}

EditTaskModal.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  memberName: PropTypes.string.isRequired,
  memberMrn: PropTypes.string.isRequired,
  prescriptionTaskDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    note: PropTypes.string,
    dueDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    reason: PropTypes.string,
    chartingNotesIds: PropTypes.arrayOf(PropTypes.string),
    member: PropTypes.shape({ mrn: PropTypes.string }),
  }).isRequired,
  prescriptionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSuccess: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  isOtherPendingPrescription: PropTypes.bool,
};

EditTaskModal.defaultProps = {
  isOtherPendingPrescription: false,
};

export default EditTaskModal;
