import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PiMinusSquareBold, PiPlusSquareBold } from 'react-icons/pi';
import classNames from 'classnames';

function Accordion({ actions, title, description, children, isOpenByDefault }) {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  return (
    <div className="border border-grey-300">
      <div className="p-5 bg-gray-100 border-b-4 border-grey-300 flex flex-row justify-between items-center">
        <div>
          <div data-testid="accordion-title" className="text-lg font-body font-semibold">
            {title}
          </div>
          {description && (
            <div data-testid="accordion-description" className="text-xs font-body italic">
              {description}
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center">
          <div>
            {actions && (
              <div data-testid="accordion-actions" className="flex flex-row gap-2 mr-2.5">
                {actions}
              </div>
            )}
          </div>
          <button
            data-testid="accordion-toggle"
            type="button"
            className="cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <PiMinusSquareBold className="w-5 h-5" /> : <PiPlusSquareBold className="w-5 h-5" />}
          </button>
        </div>
      </div>
      <div
        data-testid="accordion-body"
        className={classNames('p-5 bg-white', {
          hidden: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  description: PropTypes.string,
  isOpenByDefault: PropTypes.bool,
};

Accordion.defaultProps = {
  actions: undefined,
  description: undefined,
  isOpenByDefault: true,
};

export default Accordion;
