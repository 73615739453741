import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SimpleTable({ headers, children, tableClassName }) {
  return (
    <table
      className={classNames(
        'text-black w-full bg-white rounded-lg whitespace-nowrap shadow border border-gray-400 overflow-hidden border-separate',
        {
          [tableClassName]: !!tableClassName,
        }
      )}
    >
      <thead className="text-left border-b-2">
        <tr>
          {headers.map((header) => (
            <th className="p-4 font-semibold" key={header}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

SimpleTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  tableClassName: PropTypes.string,
};

SimpleTable.defaultProps = {
  tableClassName: null,
};

export default SimpleTable;
