import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from '../../member_summary/InfoCard';
import DetailsRow from './DetailsRow';
import InsuranceCardRow from './rows/InsuranceCardRow';
import { MemberPropTypes } from './types';

function InsuranceInformation({
  data: { primaryInsurance, primarySubscriberId, secondaryInsurance, secondarySubscriberId, insuranceCard } = {},
  member,
}) {
  return (
    <InfoCard
      heading="Insurance"
      classNames="bg-white border-0 shadow-sm"
      childrenClassNames="divide-y divide-gray-300"
    >
      <DetailsRow label="Primary Insurance">{primaryInsurance}</DetailsRow>
      <DetailsRow label="Primary Subscriber ID">{primarySubscriberId}</DetailsRow>
      <DetailsRow label="Secondary Insurance">{secondaryInsurance}</DetailsRow>
      <DetailsRow label="Secondary Subscriber ID">{secondarySubscriberId}</DetailsRow>
      <InsuranceCardRow insuranceCard={insuranceCard} member={member} />
    </InfoCard>
  );
}

InsuranceInformation.propTypes = {
  member: MemberPropTypes.isRequired,
  data: PropTypes.shape({
    primaryInsurance: PropTypes.string.isRequired,
    primarySubscriberId: PropTypes.string.isRequired,
    secondaryInsurance: PropTypes.string.isRequired,
    secondarySubscriberId: PropTypes.string.isRequired,
    insuranceCard: PropTypes.shape({
      datetime: PropTypes.string.isRequired,
      frontUrl: PropTypes.string.isRequired,
      backUrl: PropTypes.string,
    }).isRequired,
  }),
};

InsuranceInformation.defaultProps = {
  data: {
    primaryInsurance: '',
    primarySubscriberId: '',
    secondaryInsurance: '',
    secondarySubscriberId: '',
    insuranceCard: {},
  },
};

export default InsuranceInformation;
