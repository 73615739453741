import React from 'react';
import PropTypes from 'prop-types';
import { GoPersonAdd } from 'react-icons/go';
import { formatWithDuration, convertToSupportedTimeZone } from '../../../../helpers/TimezoneHelper';
import { EVERY_STATE_TYPE_APPOINTMENT } from './constants';

import GeneralCell from '../../../GeneralCell';
import { DEFAULT_DATE_WITH_WEEKDAY_FORMAT } from '../../../../helpers/DateFormatter';

function AppointmentsTableCell({ testID: testIDPostfix, row, onAddButtonClick, enableActionsColumn }) {
  const testID = `groupsTableCell-${testIDPostfix}`;
  const { capacity, location, locations, providers, scheduled_at, appointment_type, duration } = row;

  const [dateTimeWithOffset] = convertToSupportedTimeZone(scheduled_at);
  const date = dateTimeWithOffset.format(DEFAULT_DATE_WITH_WEEKDAY_FORMAT);

  const time = formatWithDuration(scheduled_at, duration);

  const getLocationLabel = () => {
    if (locations) return locations.map((loc) => loc.name).join(', ');
    if (location) return location.name;
    if (appointment_type === EVERY_STATE_TYPE_APPOINTMENT) return 'All locations';
    return 'N/A';
  };

  return (
    <>
      <GeneralCell testID={`date-${testID}`} data={date} containerClasses="py-5" />
      <GeneralCell testID={`time-${testID}`} data={time} />
      <GeneralCell testID={`type-${testID}`} data={appointment_type} />
      <GeneralCell
        classes="text-initial w-52 whitespace-pre-wrap"
        testID={`facility-${testID}`}
        data={getLocationLabel()}
      />
      <GeneralCell
        classes="text-initial w-52 whitespace-pre-wrap"
        testID={`staff-member-${testID}`}
        data={providers || 'N/A'}
      />
      <GeneralCell testID={`capacity-${testID}`} data={capacity} />
      {enableActionsColumn ? (
        <td className="flex ml-4 py-5" data-testid={`action-buttons-${testID}`}>
          <button
            type="button"
            className="table-action-button"
            data-testid={`addUserActionBtn-${testID}`}
            onClick={() => onAddButtonClick(row)}
            disabled={capacity <= 0}
          >
            <GoPersonAdd className="stroke-05" />
          </button>
        </td>
      ) : null}
    </>
  );
}

AppointmentsTableCell.propTypes = {
  testID: PropTypes.string.isRequired,
  row: {
    id: PropTypes.number.isRequired,
    appointment_type: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    counselor: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    duration: PropTypes.number.isRequired,
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    provider: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    scheduled_at: PropTypes.string.isRequired,
  }.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  enableActionsColumn: PropTypes.bool.isRequired,
};

export default AppointmentsTableCell;
