import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function CheckBox({ id, label, checked, onChange, testId }) {
  const updateValue = () => {
    onChange(!checked);
  };

  return (
    <div className="check-box">
      <input id={id} type="checkbox" checked={checked} onChange={updateValue} data-testid={testId} />
      {isEmpty(label) || (
        <label htmlFor={id}>
          <p>{label}</p>
        </label>
      )}
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

CheckBox.defaultProps = {
  id: undefined,
  label: undefined,
  testId: undefined,
};

export default CheckBox;
